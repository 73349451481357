import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { get as _get } from 'lodash'
import moment from 'moment-timezone'

import KlassForm from '../leevo_ui/KlassForm/KlassForm'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import Button from '../leevo_ui/Button/Button'
import { requestCreator } from '../../utilities/requests'
import useFacility from '../../utilities/hooks/useFacility'
import CardFormSection from '../leevo_ui/CardFormSection/CardFormSection'

function FacilitiesKlassesEdit() {
  const history = useHistory()
  const { facility, isLoading: isFacilityLoading } = useFacility()
  const [isLoading, setIsLoading] = useState(true)
  const [isDeleting, setIsDeleting] = useState(false)
  const [dates, setDates] = useState([])
  const [coaches, setCoaches] = useState([])
  const [supportedSkillLevels, setSupportedSkillLevels] = useState([])
  const [students, setStudents] = useState([])
  const [klassPackages, setKlassPackages] = useState([])
  const [maxStudents, setMaxStudents] = useState(null)
  const { klass_id: klassId } = useParams()
  const {
    get: getSkillLevels,
    cancel: cancelSkillLevelsRequests,
  } = requestCreator()
  const {
    get: getKlassPackages,
    cancel: cancelKlassPackagesRequests,
  } = requestCreator()
  const {
    get: getKlass,
    put: updateKlass,
    delete: deleteKlass,
    cancel: cancelKlassRequests,
  } = requestCreator()
  const { get: getCoaches, cancel: cancelCoachesRequests } = requestCreator()

  useEffect(() => {
    if (!isFacilityLoading) {
      let klass
      // FIXME: Can this all be done in one request by changing our serializer somehow?
      getKlass(`/api/facilities/${facility.id}/klasses/${klassId}`, {
        params: {
          include: 'klass_days,students,students.current_skill_level',
          fields: {
            klass:
              'maxStudents,dates,klass_packages,students,supported_skill_levels,coaches',
            student: 'fullName,currentSkillLevel',
            skillLevel: 'name',
            klassDay: 'startDatetime',
          },
        },
      })
        .then((retrievedKlass) => {
          klass = retrievedKlass
          setDates(
            klass.dates.map((date) => ({
              date: moment(date).tz(facility.timeZone),
              isTimeSet: true,
            }))
          )
          setStudents(
            klass.students.map((student) => ({ ...student, selected: true }))
          )
          setMaxStudents(klass.maxStudents)
        })
        .then(() =>
          getSkillLevels(`/api/facilities/${facility.id}/skill_levels`, {
            params: { fields: { skillLevel: 'name' } },
          })
        )
        .then((skillLevels) =>
          setSupportedSkillLevels(
            skillLevels.map((skillLevel) => ({
              ...skillLevel,
              label: skillLevel.name, // set a label for compatibility with multi-select searching
              value: skillLevel.id,
              selected: klass.supportedSkillLevels
                .map((s) => s.id)
                .includes(skillLevel.id),
            }))
          )
        )
        .then(() =>
          getKlassPackages(`/api/facilities/${facility.id}/class_packages`, {
            params: { fields: { klassPackage: 'title,students' } },
          })
        )
        .then((klassPackages) => {
          setKlassPackages(
            klassPackages.map((klassPackage) => ({
              ...klassPackage,
              value: klassPackage.id,
              selected: klass.klassPackages
                .map((s) => s.id)
                .includes(klassPackage.id),
            }))
          )
        })
        .then(() =>
          getCoaches(`/api/facilities/${facility.id}/coaches`, {
            params: {
              fields: { coach: 'fullName,email,cloudinaryPhotoPublicId' },
            },
          })
        )
        .then((coachList) =>
          setCoaches(
            coachList.map((coach) => ({
              ...coach,
              label: coach.fullName || `${coach.email} (pending)`, // set a label for compatibility with multi-select searching
              value: coach.id,
              selected: klass.coaches.map((c) => c.id).includes(coach.id),
            }))
          )
        )
        .then(() => setIsLoading(false))
        .catch((error) => {
          if (!axios.isCancel(error)) {
            history.push('/500')
            throw error
          }
        })
    }

    return () => {
      cancelSkillLevelsRequests()
      cancelKlassPackagesRequests()
      cancelCoachesRequests()
      cancelKlassRequests()
    }
  }, []) /* eslint-disable-line */ // FIXME

  function handleErrorResponse(error, defaultMessage) {
    if (!axios.isCancel(error)) {
      const message = _get(error, 'response.data.error') || defaultMessage
      history.replace({
        state: {
          notification: {
            message,
            color: 'red',
          },
        },
      })
    }
  }

  function onSubmit(values) {
    return updateKlass(`/api/facilities/${facility.id}/klasses/${klassId}`, {
      klass: {
        ...values,
        coaches: values.coaches.map((coach) => coach.id),
        supportedSkillLevels: values.supportedSkillLevels.map((sl) => sl.id),
        klassPackages: values.klassPackages
          .filter((klassPackage) => klassPackage.selected)
          .map((klassPackage) => klassPackage.id),
        dates: values.dates
          .filter(({ isTimeSet }) => isTimeSet)
          .map(({ date }) => date.toString()),
        students: values.students
          .filter((student) => student.selected)
          .map((student) => student.id),
      },
    })
      .then(() =>
        history.push({
          pathname: `/facilities/${facility.id}/classes`,
          state: {
            notification: {
              message: 'Class updated successfully!',
              color: 'green',
            },
          },
        })
      )
      .catch((error) =>
        handleErrorResponse(error, 'We were unable to save your changes.')
      )
  }

  function onDeleteKlass() {
    setIsDeleting(true)
    return deleteKlass(`/api/facilities/${facility.id}/klasses/${klassId}`)
      .then(() => {
        history.replace({
          pathname: `/facilities/${facility.id}/classes`,
          state: {
            notification: {
              message: 'Class deleted successfully!',
              color: 'green',
            },
          },
        })
      })
      .catch((error) => {
        handleErrorResponse(error, 'We were unable to delete this class.')
        setIsDeleting(false)
      })
  }

  return (
    <ApplicationLayout isContentLoading={isLoading}>
      <KlassForm
        dates={dates}
        coaches={coaches}
        students={students}
        klassPackages={klassPackages}
        supportedSkillLevels={supportedSkillLevels}
        maxStudents={maxStudents}
        onSubmit={onSubmit}
      />
      <CardFormSection
        title="Delete Class"
        showSubmit={false}
        description="Deleting this class will remove it from your calendar. Students in this class may need to be placed into another."
      >
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-3 sm:col-span-2">
            <p>This action cannot be undone.</p>
            <Button
              data-cy="delete"
              className="my-4"
              outline
              label="Delete this class"
              disabled={isDeleting}
              loading={isDeleting}
              fullWidth
              onClick={onDeleteKlass}
              color="red"
            />
          </div>
        </div>
      </CardFormSection>
    </ApplicationLayout>
  )
}

export default FacilitiesKlassesEdit
