import React from 'react'
import CreditCardManager from './CreditCardManager'

export default {
  title: 'Leevo UI/CreditCardManager',
  component: CreditCardManager,
  args: {
    className: 'm-4',
    brand: 'Visa',
    lastFour: '4742',
  },
}

export function creditCardManager(args) {
  return <CreditCardManager {...args} />
}
