import React from 'react'
import { Link } from 'react-router-dom'

const navigation = {
  internal: [
    { name: 'About', to: '/about' },
    { name: 'Pricing', to: '/pricing' },
    { name: 'Cookies', to: '/cookie-policy' },
    { name: 'Terms', to: '/terms' },
    { name: 'Disclaimer', to: '/disclaimer' },
    { name: 'Refunds', to: '/refunds' },
  ],
  external: [
    {
      name: 'DSAR Form',
      href: 'https://app.termly.io/notify/572cfd77-b3c3-4241-9dca-16acc37d2d61',
    },
    {
      name: 'Do Not Sell My Info',
      href: 'https://app.termly.io/notify/572cfd77-b3c3-4241-9dca-16acc37d2d61',
    },
  ],
}

function MarketingPageFooter() {
  return (
    <footer className="bg-white dark:bg-gray-800">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {navigation.internal.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <Link
                to={item.to}
                className="text-base text-gray-500 hover:text-gray-900 dark:text-gray-500 dark:hover:text-gray-300"
              >
                {item.name}
              </Link>
            </div>
          ))}
          {navigation.external.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <a
                href={item.href}
                className="text-base text-gray-500 hover:text-gray-900 dark:text-gray-500 dark:hover:text-gray-300"
              >
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <p className="mt-8 text-center text-base text-gray-400 dark:text-gray-600">
          &copy; {new Date().getFullYear()} Leevo, LLC. All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default MarketingPageFooter
