import React from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'

import SkillButton from '../../leevo_ui/SkillButton/SkillButton'

function Recommendation({ shortLabel, name, studentCount, status }) {
  return (
    <div className="flex items-center px-5 py-2" data-cy="recommendation">
      <SkillButton label={shortLabel} />
      <div className="ml-2">
        <p className="text-xs font-medium recommendation-name">{name}</p>
        <p className="text-xs font-normal">
          <span className="underline" data-cy="student-count">
            {pluralize('student', studentCount, true)}
          </span>{' '}
          {studentCount === 1 ? 'needs' : 'need'} to{' '}
          {status === 'unintroduced' ? 'be introduced to' : 'complete'} this
          skill
        </p>
      </div>
    </div>
  )
}

Recommendation.statuses = ['unintroduced', 'introduced']

Recommendation.propTypes = {
  shortLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  studentCount: PropTypes.number.isRequired,
  status: PropTypes.oneOf(Recommendation.statuses).isRequired,
}

export default Recommendation
