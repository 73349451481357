import React from 'react'
import PropTypes from 'prop-types'
import Transition from '../Transition/Transition'

function SlideInTransition({ show, children }) {
  return (
    <Transition
      show={show}
      shouldExit={false}
      enter="ease-out duration-300"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      className="transform transition"
    >
      {children}
    </Transition>
  )
}
SlideInTransition.defaultProps = {
  show: true,
}

SlideInTransition.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
}

export default SlideInTransition
