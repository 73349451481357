import React from 'react'
import TermsAndConditionsFeature from './TermsAndConditionsFeature'

export default {
  title: 'TermsAndConditionsFeature',
  component: TermsAndConditionsFeature,
  args: {
    className: 'm-4',
  },
}

export function termsAndConditionsFeature(args) {
  return <TermsAndConditionsFeature {...args} />
}
