import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { capitalize } from 'lodash'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import CircleButton from '../../leevo_ui/CircleButton/CircleButton'
import syncIcon from '../../../images/sync.svg'
import { requestCreator } from '../../../utilities/requests'

const { put: updateFacility, cancel: cancelFacilityRequests } = requestCreator()

function AdminFacilityRow({
  id,
  name,
  numberOfStudentsEnrolledThisMonth,
  lastTimeSkillMarked,
  lastDirectorSignInAt,
  lastKlassStartDatetime,
  nextKlassStartDatetime,
  lastContactedAt: originalLastContactedAt,
}) {
  const [lastContactedAt, setLastContactedAt] = useState(
    originalLastContactedAt
  )
  const [isUpdating, setIsUpdating] = useState(false)
  useEffect(() => cancelFacilityRequests, [])

  function formatDate(dateFromServer) {
    if (!dateFromServer) return 'Never'

    return capitalize(moment(dateFromServer).fromNow())
  }

  function updateContactedAt() {
    const isUpdatedRecently = moment().isBefore(
      moment(lastContactedAt).add(5, 'minutes')
    )
    // Undo the setting of the date if you click again w/i 5 mins. Allows for undo.
    const newLastContactedAt = isUpdatedRecently
      ? originalLastContactedAt
      : undefined

    setIsUpdating(true)
    updateFacility(`/api/facilities/${id}`, {
      facility: { lastContactedAt: moment(newLastContactedAt).toDate() },
    })
      .then((facility) => setLastContactedAt(facility.lastContactedAt))
      .finally(() => setIsUpdating(false))
  }

  return (
    <tr>
      <td className="text-right p-3 font-mono font-medium whitespace-nowrap">
        <Link
          className="text-purple-600 hover:text-purple-500"
          to={`/facilities/${id}/edit`}
          data-cy="edit"
        >
          {id}
        </Link>
      </td>
      <td className="p-3 whitespace-nowrap">
        <Link
          className="text-purple-600 hover:text-purple-500"
          to={`/facilities/${id}/edit`}
          data-cy="edit"
        >
          {name}
        </Link>
      </td>
      <td className="text-right font-mono font-light p-3 whitespace-nowrap">
        <Link
          className="text-purple-600 hover:text-purple-500"
          to={`/facilities/${id}/student_imports/new`}
        >
          {numberOfStudentsEnrolledThisMonth}
        </Link>
      </td>
      <td className="text-right font-light p-3 whitespace-nowrap">
        {formatDate(lastDirectorSignInAt)}
      </td>
      <td className="text-right font-light p-3 whitespace-nowrap">
        {formatDate(lastTimeSkillMarked)}
      </td>
      <td className="text-right font-light p-3 whitespace-nowrap">
        {formatDate(lastKlassStartDatetime)}
      </td>
      <td className="text-right font-light p-3 whitespace-nowrap">
        {formatDate(nextKlassStartDatetime)}
      </td>
      <td className="flex items-center justify-end font-light p-3 whitespace-nowrap w-64">
        <p>{formatDate(lastContactedAt)}</p>
        <CircleButton
          className={classNames({ 'm-2': true, 'animate-spin': isUpdating })}
          onClick={updateContactedAt}
          data-cy="update-contacted-at-button"
          size={6}
          color="white"
          svgSrc={syncIcon}
        />
      </td>
    </tr>
  )
}

AdminFacilityRow.defaultProps = {}

AdminFacilityRow.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  numberOfStudentsEnrolledThisMonth: PropTypes.number,
  lastTimeSkillMarked: PropTypes.string,
  lastDirectorSignInAt: PropTypes.string,
  lastKlassStartDatetime: PropTypes.string,
  nextKlassStartDatetime: PropTypes.string,
  lastContactedAt: PropTypes.string,
}

export default AdminFacilityRow
