import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { capitalize } from 'lodash'
import axios from 'axios'

import ProfileForm from '../leevo_ui/ProfileForm/ProfileForm'
import LoadingSpinner from '../leevo_ui/LoadingSpinner/LoadingSpinner'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import { requestCreator } from '../../utilities/requests'
import useAuth from '../../utilities/hooks/useAuth'

const {
  get: getUser,
  put: saveUser,
  cancel: cancelUserRequests,
} = requestCreator()

// FIXME: First field in the form should be auto-focused on render.
function ProfileFeature() {
  const history = useHistory()
  const [fields, setFields] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const auth = useAuth()

  useEffect(() => {
    getUser(`/api/users/${auth.currentUser.id}`)
      .then((data) => {
        setFields(data)
        setIsLoading(false)
      })
      .catch((error) => {
        if (!axios.isCancel(error)) throw error
      })

    return cancelUserRequests
  }, [auth.currentUser])

  function onSubmit(values) {
    let message = 'Changes saved!'
    return saveUser(`/api/users/${auth.currentUser.id}`, { user: values })
      .then(() =>
        history.replace({
          state: { notification: { message, color: 'green' } },
        })
      )
      .catch((err) => {
        message = Object.entries(err.response.data.errors).reduce(
          (fullString, error) =>
            (fullString += `${capitalize(error.join(' '))}. `),
          ''
        )
        message = message || 'We were unable to save your changes.'
        history.replace({
          state: { notification: { message, color: 'red' } },
        })
      })
  }

  return (
    <ApplicationLayout>
      {isLoading ? (
        <LoadingSpinner
          size="1/5"
          className="flex items-center justify-center my-10"
        />
      ) : (
        <ProfileForm {...fields} id={auth.currentUser.id} onSubmit={onSubmit} />
      )}
    </ApplicationLayout>
  )
}

export default ProfileFeature
