import React, { useState, useEffect } from 'react'
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom'
import axios from 'axios'
import mixpanel from '../../utilities/mixpanel'

import RegistrationDashboard from './RegistrationDashboard/RegistrationDashboard'
import CreateAccount from './CreateAccount/CreateAccount'
import AddStudents from './AddStudents/AddStudents'
import SelectKlasses from './SelectKlasses/SelectKlasses'
import Checkout from './Checkout/Checkout'
import PaymentConfirmation from './PaymentConfirmation/PaymentConfirmation'
import LoadingPage from '../leevo_ui/LoadingPage/LoadingPage'
import Button from '../leevo_ui/Button/Button'
import { requestCreator } from '../../utilities/requests'
import useFacility from '../../utilities/hooks/useFacility'
import useAuth from '../../utilities/hooks/useAuth'

const { get: getStudents, cancel: cancelStudentsRequest } = requestCreator()
const {
  post: createCheckoutSession,
  cancel: cancelCheckoutSessionRequest,
} = requestCreator()

function RegistrationFeature() {
  const {
    path,
    url,
    params: { facility_id: facilityId },
  } = useRouteMatch()
  const { facility, isLoading: isFacilityLoading } = useFacility()
  const history = useHistory()
  const { isSignedIn, currentUser, isLoading: isAuthLoading } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [lineItems, setLineItems] = useState(null)
  const [couponCodeAmount, setCouponCodeAmount] = useState(null)
  const [couponCode, setCouponCode] = useState('')
  const [disclosures, setDisclosures] = useState([])
  const [couponCodePromotionAmount, setCouponCodePromotionAmount] = useState(
    null
  )
  const [couponCodeError, setCouponCodeError] = useState('')
  const [total, setTotal] = useState(null)
  const [students, setStudents] = useState([])

  const initialSections = [
    {
      title: 'Create Your Account or Log In',
      nextStepAction: {
        onClick: () => history.push(`${url}/account`),
        label: 'Get Started',
      },
    },
    {
      title: 'Register Your Students',
      disabled: true,
      nextStepAction: {
        onClick: () => history.push(`${url}/students`),
        label: 'Add a Student',
      },
    },
    {
      title: 'Pick Your Classes',
      disabled: true,
      nextStepAction: {
        onClick: () => history.push(`${url}/classes`),
        label: 'Pick Classes',
      },
    },
    {
      title: 'Checkout',
      disabled: true,
      nextStepAction: {
        onClick: () => history.push(`${url}/checkout`),
        label: 'Finish Registration',
      },
    },
  ]
  const [sections, setSections] = useState(initialSections)

  useEffect(() => {
    if (isFacilityLoading || isAuthLoading) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
    // FIXME: I believe the dep of isAuthLoading was causing the registration login to be wonky
    /* eslint-disable-next-line */
  }, [isFacilityLoading])

  useEffect(() => {
    if (isSignedIn) {
      setIsLoading(true)
      getStudents(`/api/guardians/${currentUser.id}/students`, {
        params: { include: 'current_skill_level' },
      })
        .then((studentsList) => {
          if (studentsList && studentsList.length > 0) setStudents(studentsList)
          setIsLoading(false)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) throw error
        })

      completeAccountSection()
      openStudentsSection()

      return cancelStudentsRequest
    }
  }, [isSignedIn]) /* eslint-disable-line */ // FIXME

  useEffect(() => {
    setIsLoading(true)
    if (hasStudentsData()) {
      completeStudentsSection()
      if (hasSelectedKlassPackages()) {
        completeKlassesSection()
        createCheckoutSession(`/api/facilities/${facilityId}/checkouts/new`, {
          students,
          include: 'disclosures',
        }).then((checkoutData) => {
          setLineItems(checkoutData.lineItems)
          setDisclosures(checkoutData.disclosures)
          setCouponCodeAmount(checkoutData.couponCodeAmount)
          setCouponCodePromotionAmount(checkoutData.couponCodePromotionAmount)
          setTotal(checkoutData.total)
          openCheckoutSection()
          // FIXME: This causes a full screen loading screen while we wait.
          // Ideally, this should only put a loading spinner next to the
          // Checkout button.
          setIsLoading(false)
        })
      } else {
        resetKlassesSection()
        closeCheckoutSection()
        setIsLoading(false)
      }
    }
    history.push(url)

    return cancelCheckoutSessionRequest
  }, [students, facilityId]) /* eslint-disable-line */ // FIXME

  function handleCouponCodeChange({ couponCode }) {
    setCouponCode(couponCode)
    return createCheckoutSession(
      `/api/facilities/${facilityId}/checkouts/new`,
      { students, couponCode }
    ).then((checkoutData) => {
      if (checkoutData.couponCodeAmount) {
        setLineItems(checkoutData.lineItems)
        setCouponCodeAmount(checkoutData.couponCodeAmount)
        setCouponCodePromotionAmount(checkoutData.couponCodePromotionAmount)
        setTotal(checkoutData.total)
        setCouponCodeError('')
      } else {
        setCouponCodeError('Code is not valid')
      }
    })
  }

  function hasStudentsData() {
    return students && students.length > 0
  }

  function hasSelectedKlassPackages() {
    return !students.every(
      (student) =>
        student.selectedKlassPackages === undefined ||
        student.selectedKlassPackages.length === 0
    )
  }

  function completeAccountSection() {
    sections[0] = {
      ...sections[0],
      title: 'Create Your Account',
      completed: true,
      nextStepAction: null,
      headerAction: {
        label: 'Edit',
        onClick: () => history.push('/profile'),
      },
    }
    setSections([...sections])
  }

  function openStudentsSection() {
    sections[1] = {
      ...sections[1],
      disabled: false,
    }
    setSections([...sections])
  }

  function completeStudentsSection() {
    sections[1] = {
      ...sections[1],
      completed: true,
      nextStepAction: null,
      headerAction: {
        label: 'Add',
        onClick: () => history.push(`${url}/students`),
      },
      children: (
        <ul>
          {students.map((student) => (
            <li
              key={student.id}
              data-cy="student-list-item"
              className="flex justify-between mt-1 first:mt-0"
            >
              <p>{student.fullName}</p>
              <Button
                label="Edit"
                outline
                small
                onClick={() =>
                  history.push(
                    `/facilities/${facilityId}/students/${student.id}/edit`
                  )
                }
              />
            </li>
          ))}
        </ul>
      ),
    }
    setSections([...sections])
  }

  function closeCheckoutSection() {
    sections[3] = { ...initialSections[3] }
    setSections([...sections])
  }

  function completeKlassesSection() {
    sections[2] = {
      ...sections[2],
      completed: true,
      nextStepAction: null,
      headerAction: {
        label: 'Edit',
        onClick: () => history.push(`${url}/classes`),
      },
      children: (
        <ul>
          {students.map(
            (student) =>
              student.selectedKlassPackages &&
              student.selectedKlassPackages.map((selectedKlassPackage) => (
                <li
                  key={`${student.id}-${selectedKlassPackage.id}-klass`}
                  className="flex justify-between mt-1 first:mt-0"
                >
                  {`${student.fullName} - ${selectedKlassPackage.title}`}
                </li>
              ))
          )}
        </ul>
      ),
    }
    setSections([...sections])
  }

  function openKlassesSection() {
    sections[2] = {
      ...sections[2],
      disabled: false,
    }
    setSections([...sections])
  }

  function resetKlassesSection() {
    sections[2] = {
      ...initialSections[2],
      disabled: false,
    }
    setSections([...sections])
  }

  function openCheckoutSection() {
    sections[3] = {
      ...sections[3],
      disabled: false,
    }
    setSections([...sections])
  }

  return (
    <Switch>
      {isLoading && <LoadingPage />}
      <Route
        path={`${path}/payment_confirmation`}
        render={() => (
          <PaymentConfirmation
            lineItems={lineItems}
            total={total}
            couponCodeAmount={couponCodeAmount}
            couponCodePromotionAmount={couponCodePromotionAmount}
          />
        )}
      />
      <Route
        path={`${path}/checkout`}
        render={() => (
          <Checkout
            couponCode={couponCode}
            couponCodeAmount={couponCodeAmount}
            couponCodePromotionAmount={couponCodePromotionAmount}
            onCouponCodeChange={handleCouponCodeChange}
            couponCodeError={couponCodeError}
            disclosures={disclosures}
            lineItems={lineItems}
            total={total}
            students={students}
            onCompletedCheckout={() => {
              setIsLoading(true)
              getStudents(`/api/guardians/${currentUser.id}/students`)
                .then((studentsList) => {
                  setStudents(studentsList)
                  completeStudentsSection()
                  openKlassesSection()
                  closeCheckoutSection()
                  setIsLoading(false)
                  mixpanel.track('Completed Registration')
                  history.push(`${url}/payment_confirmation`)
                })
                .catch((error) => {
                  if (!axios.isCancel(error)) throw error
                })
            }}
          />
        )}
      />
      <Route
        path={`${path}/classes`}
        render={() => (
          <SelectKlasses
            studentsList={students}
            onKlassesSelected={(selected) => {
              setStudents(
                students.map((student) => {
                  const selectedKlassPackages =
                    selected[student.id] &&
                    selected[student.id].map(({ id, title }) => {
                      return { id, title }
                    })
                  return { ...student, selectedKlassPackages }
                })
              )
            }}
          />
        )}
      />
      <Route
        path={`${path}/students`}
        render={() => (
          <AddStudents
            onStudentAdded={(student) => setStudents([...students, student])}
          />
        )}
      />
      <Route path={`${path}/account`} render={() => <CreateAccount />} />
      <Route
        path={`${path}`}
        render={() => (
          <RegistrationDashboard
            sections={sections}
            title={`Register${facility.name && ` at ${facility.name}`}`}
          />
        )}
      />
    </Switch>
  )
}

export default RegistrationFeature
