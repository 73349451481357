import React from 'react'
import { mount } from 'enzyme'

import { MemoryRouter, Route } from 'react-router-dom'

import HandlingLTSRegistrations from './HandlingLTSRegistrations'

describe('HandlingLTSRegistrations', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/HandlingLTSRegistrations']}>
          <Route path="/HandlingLTSRegistrations">
            <HandlingLTSRegistrations />
          </Route>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
