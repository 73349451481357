import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button/Button'

function SkillSelector({
  skillCategories,
  selectedCategory,
  onCategorySelected,
}) {
  return (
    <div className="flex flex-wrap">
      {skillCategories.map((category) => (
        <Button
          className="rounded-full m-2 flex-shrink-0"
          small
          outline={category !== selectedCategory}
          onClick={() => onCategorySelected(category)}
          key={category}
          label={category || 'Uncategorized'}
        />
      ))}
    </div>
  )
}

SkillSelector.defaultProps = {
  skillCategories: [],
}

SkillSelector.propTypes = {
  skillCategories: PropTypes.arrayOf(PropTypes.string),
  selectedCategory: PropTypes.string,
  onCategorySelected: PropTypes.func.isRequired,
}

export default SkillSelector
