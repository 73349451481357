import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { MenuAlt2Icon } from '@heroicons/react/outline'

import ApplicationSidebar from './ApplicationSidebar/ApplicationSidebar'
import Notification from '../Notification/Notification'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
// FIXME: Remove if we don't poll on more features
// import FeatureInterestBanner from '../FeatureInterestBanner/FeatureInterestBanner'

function ApplicationLayout({ children, isContentLoading, ...rest }) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { state } = useLocation()
  const history = useHistory()

  return (
    <div
      className="relative flex h-screen overflow-hidden print:h-auto print:overflow-auto bg-white"
      {...rest}
    >
      <ApplicationSidebar
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      />
      <div className="flex flex-col flex-1 lg:max-w-[calc(100%-16rem)]">
        <div className="w-full max-w-4xl mx-auto lg:px-8 xl:px-0 lg:hidden">
          <div className="relative z-10 flex flex-shrink-0 h-16 bg-white border-b border-gray-200 print:hidden">
            <button
              type="button"
              className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
              onClick={() => setSidebarOpen(true)}
              data-cy="main-menu"
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="w-6 h-6" aria-hidden="true" />
            </button>

            {/* FIXME: Add back in when we support search or notifications */}
            {/* <div className="flex justify-between flex-1 px-4 lg:px-0"> */}
            {/* FIXME: Add back in when we support search? */}
            {/* <div className="flex flex-1">
                <form className="flex w-full lg:ml-0" action="#" method="GET">
                  <label htmlFor="mobile-search-field" className="sr-only">
                    Search
                  </label>
                  <label htmlFor="desktop-search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                      <SearchIcon
                        className="flex-shrink-0 w-5 h-5"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      name="mobile-search-field"
                      id="mobile-search-field"
                      className="w-full h-full py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 border-transparent focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:hidden"
                      placeholder="Search"
                      type="search"
                    />
                    <input
                      name="desktop-search-field"
                      id="desktop-search-field"
                      className="hidden w-full h-full py-2 pl-8 pr-3 text-sm text-gray-900 placeholder-gray-500 border-transparent focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:block"
                      placeholder="Search jobs, applicants, and more"
                      type="search"
                    />
                  </div>
                </form>
              </div> */}
            {/*
              FIXME: Add when we add notifications...? Or remove?
              <div className="flex items-center ml-4 lg:ml-6">
                <button
                  type="button"
                  className="p-1 text-gray-400 bg-white rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  <BellIcon className="w-6 h-6" aria-hidden="true" />
                  <span className="sr-only">View notifications</span>
                </button>
              </div> */}
            {/* </div> */}
          </div>
        </div>
        <main className="flex-1 pb-24 overflow-y-auto focus:outline-none">
          <Notification
            color={get(state, 'notification.color')}
            show={!!get(state, 'notification')}
            onDisappearTimeout={() => {
              history.push({ state: { notification: undefined } })
            }}
            className="w-full"
          >
            {get(state, 'notification.message')}
          </Notification>
          {isContentLoading && (
            <LoadingSpinner
              size="1/5"
              className="flex items-center justify-center h-full"
            />
          )}
          {!isContentLoading && children}
          {/* FIXME: Remove if we don't poll on more features */}
          {/* <FeatureInterestBanner /> */}
        </main>
      </div>
    </div>
  )
}

ApplicationLayout.defaultProps = {
  isContentLoading: false,
}

ApplicationLayout.propTypes = {
  children: PropTypes.node,
  isContentLoading: PropTypes.bool,
}

export default ApplicationLayout
