import React from 'react'
import { shallow } from 'enzyme'

import RegistrationDashboard from './RegistrationDashboard'
import RegistrationLayout from '../RegistrationLayout/RegistrationLayout'
import RegistrationDashboardSection from '../RegistrationDashboardSection/RegistrationDashboardSection'

describe('RegistrationDashboard', () => {
  let wrapper
  beforeEach(() => {
    wrapper = shallow(<RegistrationDashboard sections={[]} />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders inside a purple Registration Layout', () => {
    expect(wrapper.find(RegistrationLayout).props().headerColor).toBe('purple')
  })

  describe('title', () => {
    test('renders as `Next steps...` if not provided', () => {
      expect(wrapper.find(RegistrationLayout).props().title).toBe(
        'Next steps...'
      )
    })

    test('renders in the Registration Layout', () => {
      wrapper.setProps({ title: 'foo' })
      expect(wrapper.find(RegistrationLayout).props().title).toBe('foo')
    })
  })

  describe('sections', () => {
    test('are required', () => {
      function renderWrapper() {
        shallow(<RegistrationDashboard />)
      }
      expect(renderWrapper).toThrowError('`sections` is marked as required')
    })

    test('render', () => {
      wrapper.setProps({
        sections: [{ title: 'Section 1' }, { title: 'Section 2' }],
      })
      expect(wrapper.find(RegistrationDashboardSection).length).toBe(2)
    })
  })
})
