import React, { useState } from 'react'
import { text, number, boolean } from '@storybook/addon-knobs'

import KlassPackageListItem from './KlassPackageListItem'

export default {
  title: 'Registration Feature/KlassPackageList/KlassPackageListItem',
  component: KlassPackageListItem,
}

export function KlassPackageListItemStory() {
  const [selected, setSelected] = useState(new Set())
  const remainingKlassDays = number('Remaining classes', 4)
  const spotsLeft = number('spotsLeft', 2)
  const title = text('Title', 'Tuesday Classes')
  const description = text(
    'Description',
    'Classes are held on Tuesdays at 9:00 PM'
  )
  const priceInDollars = text('Price In Dollars', '$15.50')

  function onClick(id) {
    if (selected.has(id)) {
      selected.delete(id)
      setSelected(new Set(selected))
    } else {
      setSelected(new Set(selected.add(id)))
    }
  }

  return (
    <>
      <KlassPackageListItem
        id={1}
        title={title}
        description={description}
        remainingKlassDays={remainingKlassDays}
        spotsLeft={spotsLeft}
        priceInDollars={priceInDollars}
        selected={selected.has(1)}
        onClick={onClick}
      />
      <KlassPackageListItem
        id={2}
        title={title}
        description={description}
        remainingKlassDays={remainingKlassDays}
        spotsLeft={spotsLeft}
        priceInDollars={priceInDollars}
        selected={selected.has(2)}
        onClick={boolean('Clickable?', true) ? onClick : undefined}
      />
    </>
  )
}
