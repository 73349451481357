import React from 'react'
import htmlEntities from 'he'
import faker from 'faker'

import CoachHeader from './CoachHeader'

export default {
  title: 'Coach Feature/Components/CoachHeader',
  component: CoachHeader,
  args: {
    coachCount: 1,
    skillLevelsCount: 1,
    classStartDateTime: '2019-12-28T19:43:25-07:00',
  },
  argTypes: {
    coachCount: { control: { type: 'range', min: 0, max: 20 } },
    skillLevelsCount: { control: { type: 'range', min: 0, max: 20 } },
    classStartDateTime: { control: { type: 'date' } },
    coaches: { table: { disable: true } },
    skillLevels: { table: { disable: true } },
  },
}

export function coachHeader({ coachCount, skillLevelsCount, ...args }) {
  function coaches(numCoaches) {
    const coaches = []
    for (let i = 0; i < numCoaches; i++) {
      coaches.push({
        id: i + 1,
        preferredName: faker.name.firstName(),
        cloudinaryPhotoPublicId: 'qrz0yfenrunmvcb0epwa',
      })
    }
    return coaches
  }

  function levels(numLevels) {
    const levels = []
    for (let i = 0; i < numLevels; i++) {
      levels.push(
        htmlEntities.decode(
          `${faker.company.bsBuzz()} ${Math.floor(Math.random() * 10)}`
        )
      )
    }
    return levels
  }

  return (
    <CoachHeader
      {...args}
      coaches={coaches(coachCount)}
      skillLevels={levels(skillLevelsCount)}
    />
  )
}
