import React from 'react'
import { shallow } from 'enzyme'

import StudentCard from './StudentCard'
import SkillButton from '../../leevo_ui/SkillButton/SkillButton'

describe('StudentCard', () => {
  let wrapper

  beforeEach(() => {
    wrapper = shallow(
      <StudentCard
        id={1}
        fullName="John Doe"
        skills={[
          {
            id: '1',
            name: 'Foo',
            shortLabel: 'A',
            status: 'unintroduced',
            ordinal: 1,
          },
          {
            id: '2',
            name: 'Bar',
            shortLabel: 'B',
            status: 'introduced',
            ordinal: 2,
          },
          {
            id: '3',
            name: 'Baz',
            shortLabel: 'C',
            status: 'completed',
            ordinal: 3,
          },
        ]}
        currentSkillLevel="Foobar"
      />
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders currentSkillLevel', () => {
    expect(wrapper.html()).toContain('Foobar')
  })

  test('renders fullName', () => {
    expect(wrapper.html()).toContain('John Doe')
  })

  test('renders the correct number of skills', () => {
    expect(wrapper.find(SkillButton)).toHaveLength(3)
  })
})
