import React from 'react'
import CreditCardLogo from './CreditCardLogo'
import { shallow } from 'enzyme'

describe('CreditCardLogo', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(<CreditCardLogo />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
