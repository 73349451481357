import React from 'react'
import PropTypes from 'prop-types'

import SkillButton from '../SkillButton/SkillButton'

function SkillLegend({ className }) {
  return (
    <div className={className}>
      <div
        className="flex justify-center w-full py-2 space-x-1 text-xs bg-gray-200 xs:space-x-3"
        data-cy="skill-legend"
      >
        <p className="inline-block">
          <SkillButton size={3} />
          Not Introduced
        </p>
        <p className="inline-block">
          <SkillButton size={3} state="introduced" />
          Introduced
        </p>
        <p className="inline-block">
          <SkillButton size={3} state="completed" />
          Completed
        </p>
      </div>
    </div>
  )
}

SkillLegend.propTypes = {
  className: PropTypes.string,
}

export default SkillLegend
