import React from 'react'
import PropTypes from 'prop-types'
import { XIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

function AnnouncementBanner({ show, message, longMessage, ctaText, onClose }) {
  return (
    <div
      className={classNames(
        'sticky top-0 z-10 transition duration-200 ease-in-out',
        {
          'mt-0 translate-y-0': show,
          '-mt-24 md:-mt-12 -translate-y-full': !show,
        }
      )}
    >
      <div className="relative bg-purple-800 dark:bg-purple-800">
        <div className="px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="font-medium text-white">
              <span className="md:hidden">{message}</span>
              <span className="hidden md:inline">
                {longMessage || message}{' '}
              </span>{' '}
              {ctaText && (
                <Link
                  to="/support/handling_lts_registrations"
                  className="block sm:ml-2 sm:inline-block font-bold text-white dark:text-gray-300 underline hover:text-purple-200 dark:hover:text-purple-300"
                >
                  {ctaText} <span aria-hidden="true">&rarr;</span>
                </Link>
              )}
            </p>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:pt-1 sm:pr-2 sm:items-start">
            <button
              type="button"
              className="flex p-2 rounded-md hover:bg-purple-500 dark:hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-white"
              onClick={onClose}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon
                className="w-6 h-6 text-white dark:text-gray-300"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

AnnouncementBanner.defaultProps = {
  show: true,
  longMessage: '',
  message: '',
  ctaText: 'Learn more',
  onClose: () => {},
}

AnnouncementBanner.propTypes = {
  show: PropTypes.bool,
  longMessage: PropTypes.string,
  message: PropTypes.string,
  ctaText: PropTypes.string,
  onClose: PropTypes.func,
}

export default AnnouncementBanner
