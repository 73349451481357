export default {
  data: [
    {
      id: '9',
      type: 'skateRental',
      attributes: {
        size: 11,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3892',
            type: 'student',
          },
        },
      },
    },
    {
      id: '10',
      type: 'skateRental',
      attributes: {
        size: 17.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3895',
            type: 'student',
          },
        },
      },
    },
    {
      id: '11',
      type: 'skateRental',
      attributes: {
        size: 11.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3899',
            type: 'student',
          },
        },
      },
    },
    {
      id: '12',
      type: 'skateRental',
      attributes: {
        size: 13,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3903',
            type: 'student',
          },
        },
      },
    },
    {
      id: '13',
      type: 'skateRental',
      attributes: {
        size: 18.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3930',
            type: 'student',
          },
        },
      },
    },
    {
      id: '15',
      type: 'skateRental',
      attributes: {
        size: 19.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3932',
            type: 'student',
          },
        },
      },
    },
    {
      id: '16',
      type: 'skateRental',
      attributes: {
        size: 17,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3933',
            type: 'student',
          },
        },
      },
    },
    {
      id: '17',
      type: 'skateRental',
      attributes: {
        size: 19.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3934',
            type: 'student',
          },
        },
      },
    },
    {
      id: '18',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3935',
            type: 'student',
          },
        },
      },
    },
    {
      id: '19',
      type: 'skateRental',
      attributes: {
        size: 17,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3936',
            type: 'student',
          },
        },
      },
    },
    {
      id: '20',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3937',
            type: 'student',
          },
        },
      },
    },
    {
      id: '21',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3938',
            type: 'student',
          },
        },
      },
    },
    {
      id: '22',
      type: 'skateRental',
      attributes: {
        size: 18,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3939',
            type: 'student',
          },
        },
      },
    },
    {
      id: '23',
      type: 'skateRental',
      attributes: {
        size: 7,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3940',
            type: 'student',
          },
        },
      },
    },
    {
      id: '24',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3941',
            type: 'student',
          },
        },
      },
    },
    {
      id: '25',
      type: 'skateRental',
      attributes: {
        size: 19,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3942',
            type: 'student',
          },
        },
      },
    },
    {
      id: '26',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3943',
            type: 'student',
          },
        },
      },
    },
    {
      id: '27',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3944',
            type: 'student',
          },
        },
      },
    },
    {
      id: '28',
      type: 'skateRental',
      attributes: {
        size: 15.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3945',
            type: 'student',
          },
        },
      },
    },
    {
      id: '29',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3946',
            type: 'student',
          },
        },
      },
    },
    {
      id: '30',
      type: 'skateRental',
      attributes: {
        size: 7.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3947',
            type: 'student',
          },
        },
      },
    },
    {
      id: '31',
      type: 'skateRental',
      attributes: {
        size: 9.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3948',
            type: 'student',
          },
        },
      },
    },
    {
      id: '32',
      type: 'skateRental',
      attributes: {
        size: 11,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3949',
            type: 'student',
          },
        },
      },
    },
    {
      id: '33',
      type: 'skateRental',
      attributes: {
        size: 11,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3950',
            type: 'student',
          },
        },
      },
    },
    {
      id: '34',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3951',
            type: 'student',
          },
        },
      },
    },
    {
      id: '35',
      type: 'skateRental',
      attributes: {
        size: 12.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3952',
            type: 'student',
          },
        },
      },
    },
    {
      id: '36',
      type: 'skateRental',
      attributes: {
        size: 12,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3968',
            type: 'student',
          },
        },
      },
    },
    {
      id: '37',
      type: 'skateRental',
      attributes: {
        size: 12,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3969',
            type: 'student',
          },
        },
      },
    },
    {
      id: '39',
      type: 'skateRental',
      attributes: {
        size: 11,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3971',
            type: 'student',
          },
        },
      },
    },
    {
      id: '40',
      type: 'skateRental',
      attributes: {
        size: 13,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3972',
            type: 'student',
          },
        },
      },
    },
    {
      id: '42',
      type: 'skateRental',
      attributes: {
        size: 7,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3974',
            type: 'student',
          },
        },
      },
    },
    {
      id: '43',
      type: 'skateRental',
      attributes: {
        size: 15.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3975',
            type: 'student',
          },
        },
      },
    },
    {
      id: '44',
      type: 'skateRental',
      attributes: {
        size: 13.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3976',
            type: 'student',
          },
        },
      },
    },
    {
      id: '45',
      type: 'skateRental',
      attributes: {
        size: 11,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3977',
            type: 'student',
          },
        },
      },
    },
    {
      id: '46',
      type: 'skateRental',
      attributes: {
        size: 19,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3978',
            type: 'student',
          },
        },
      },
    },
    {
      id: '48',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3980',
            type: 'student',
          },
        },
      },
    },
    {
      id: '49',
      type: 'skateRental',
      attributes: {
        size: 8.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3981',
            type: 'student',
          },
        },
      },
    },
    {
      id: '50',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3982',
            type: 'student',
          },
        },
      },
    },
    {
      id: '51',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3983',
            type: 'student',
          },
        },
      },
    },
    {
      id: '52',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3984',
            type: 'student',
          },
        },
      },
    },
    {
      id: '53',
      type: 'skateRental',
      attributes: {
        size: 13,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3985',
            type: 'student',
          },
        },
      },
    },
    {
      id: '54',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3986',
            type: 'student',
          },
        },
      },
    },
    {
      id: '55',
      type: 'skateRental',
      attributes: {
        size: 11.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3987',
            type: 'student',
          },
        },
      },
    },
    {
      id: '56',
      type: 'skateRental',
      attributes: {
        size: 11.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3988',
            type: 'student',
          },
        },
      },
    },
    {
      id: '57',
      type: 'skateRental',
      attributes: {
        size: 13.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3989',
            type: 'student',
          },
        },
      },
    },
    {
      id: '59',
      type: 'skateRental',
      attributes: {
        size: 12.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3991',
            type: 'student',
          },
        },
      },
    },
    {
      id: '60',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '3992',
            type: 'student',
          },
        },
      },
    },
    {
      id: '61',
      type: 'skateRental',
      attributes: {
        size: 7,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4004',
            type: 'student',
          },
        },
      },
    },
    {
      id: '62',
      type: 'skateRental',
      attributes: {
        size: 11,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4005',
            type: 'student',
          },
        },
      },
    },
    {
      id: '63',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4006',
            type: 'student',
          },
        },
      },
    },
    {
      id: '64',
      type: 'skateRental',
      attributes: {
        size: 14.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4007',
            type: 'student',
          },
        },
      },
    },
    {
      id: '65',
      type: 'skateRental',
      attributes: {
        size: 6,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4008',
            type: 'student',
          },
        },
      },
    },
    {
      id: '66',
      type: 'skateRental',
      attributes: {
        size: 7.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4009',
            type: 'student',
          },
        },
      },
    },
    {
      id: '67',
      type: 'skateRental',
      attributes: {
        size: 19.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4010',
            type: 'student',
          },
        },
      },
    },
    {
      id: '68',
      type: 'skateRental',
      attributes: {
        size: 17.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4011',
            type: 'student',
          },
        },
      },
    },
    {
      id: '69',
      type: 'skateRental',
      attributes: {
        size: 16,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4012',
            type: 'student',
          },
        },
      },
    },
    {
      id: '70',
      type: 'skateRental',
      attributes: {
        size: 19,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4013',
            type: 'student',
          },
        },
      },
    },
    {
      id: '71',
      type: 'skateRental',
      attributes: {
        size: 14.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4014',
            type: 'student',
          },
        },
      },
    },
    {
      id: '72',
      type: 'skateRental',
      attributes: {
        size: 7.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4015',
            type: 'student',
          },
        },
      },
    },
    {
      id: '75',
      type: 'skateRental',
      attributes: {
        size: 6,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4018',
            type: 'student',
          },
        },
      },
    },
    {
      id: '76',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4019',
            type: 'student',
          },
        },
      },
    },
    {
      id: '77',
      type: 'skateRental',
      attributes: {
        size: 12,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4020',
            type: 'student',
          },
        },
      },
    },
    {
      id: '78',
      type: 'skateRental',
      attributes: {
        size: 13.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4021',
            type: 'student',
          },
        },
      },
    },
    {
      id: '79',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4022',
            type: 'student',
          },
        },
      },
    },
    {
      id: '80',
      type: 'skateRental',
      attributes: {
        size: 17,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4023',
            type: 'student',
          },
        },
      },
    },
    {
      id: '81',
      type: 'skateRental',
      attributes: {
        size: 6,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4024',
            type: 'student',
          },
        },
      },
    },
    {
      id: '82',
      type: 'skateRental',
      attributes: {
        size: 8.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4025',
            type: 'student',
          },
        },
      },
    },
    {
      id: '83',
      type: 'skateRental',
      attributes: {
        size: 12,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4026',
            type: 'student',
          },
        },
      },
    },
    {
      id: '84',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4043',
            type: 'student',
          },
        },
      },
    },
    {
      id: '85',
      type: 'skateRental',
      attributes: {
        size: 18,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4044',
            type: 'student',
          },
        },
      },
    },
    {
      id: '86',
      type: 'skateRental',
      attributes: {
        size: 19,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4045',
            type: 'student',
          },
        },
      },
    },
    {
      id: '87',
      type: 'skateRental',
      attributes: {
        size: 13,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4046',
            type: 'student',
          },
        },
      },
    },
    {
      id: '88',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4047',
            type: 'student',
          },
        },
      },
    },
    {
      id: '89',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4048',
            type: 'student',
          },
        },
      },
    },
    {
      id: '91',
      type: 'skateRental',
      attributes: {
        size: 8.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4050',
            type: 'student',
          },
        },
      },
    },
    {
      id: '92',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4051',
            type: 'student',
          },
        },
      },
    },
    {
      id: '93',
      type: 'skateRental',
      attributes: {
        size: 7.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4052',
            type: 'student',
          },
        },
      },
    },
    {
      id: '94',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4053',
            type: 'student',
          },
        },
      },
    },
    {
      id: '95',
      type: 'skateRental',
      attributes: {
        size: 17,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4054',
            type: 'student',
          },
        },
      },
    },
    {
      id: '96',
      type: 'skateRental',
      attributes: {
        size: 9.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4055',
            type: 'student',
          },
        },
      },
    },
    {
      id: '97',
      type: 'skateRental',
      attributes: {
        size: 15,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4056',
            type: 'student',
          },
        },
      },
    },
    {
      id: '98',
      type: 'skateRental',
      attributes: {
        size: 18.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4057',
            type: 'student',
          },
        },
      },
    },
    {
      id: '99',
      type: 'skateRental',
      attributes: {
        size: 15,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4058',
            type: 'student',
          },
        },
      },
    },
    {
      id: '100',
      type: 'skateRental',
      attributes: {
        size: 18.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4059',
            type: 'student',
          },
        },
      },
    },
    {
      id: '101',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4060',
            type: 'student',
          },
        },
      },
    },
    {
      id: '102',
      type: 'skateRental',
      attributes: {
        size: 18,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4061',
            type: 'student',
          },
        },
      },
    },
    {
      id: '103',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4062',
            type: 'student',
          },
        },
      },
    },
    {
      id: '104',
      type: 'skateRental',
      attributes: {
        size: 6,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4063',
            type: 'student',
          },
        },
      },
    },
    {
      id: '105',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4064',
            type: 'student',
          },
        },
      },
    },
    {
      id: '106',
      type: 'skateRental',
      attributes: {
        size: 11.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4082',
            type: 'student',
          },
        },
      },
    },
    {
      id: '107',
      type: 'skateRental',
      attributes: {
        size: 7.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4083',
            type: 'student',
          },
        },
      },
    },
    {
      id: '108',
      type: 'skateRental',
      attributes: {
        size: 17,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4084',
            type: 'student',
          },
        },
      },
    },
    {
      id: '109',
      type: 'skateRental',
      attributes: {
        size: 7,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4085',
            type: 'student',
          },
        },
      },
    },
    {
      id: '110',
      type: 'skateRental',
      attributes: {
        size: 16,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4086',
            type: 'student',
          },
        },
      },
    },
    {
      id: '111',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4087',
            type: 'student',
          },
        },
      },
    },
    {
      id: '112',
      type: 'skateRental',
      attributes: {
        size: 15,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4088',
            type: 'student',
          },
        },
      },
    },
    {
      id: '113',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4089',
            type: 'student',
          },
        },
      },
    },
    {
      id: '114',
      type: 'skateRental',
      attributes: {
        size: 14.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4090',
            type: 'student',
          },
        },
      },
    },
    {
      id: '115',
      type: 'skateRental',
      attributes: {
        size: 11.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4091',
            type: 'student',
          },
        },
      },
    },
    {
      id: '116',
      type: 'skateRental',
      attributes: {
        size: 18,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4092',
            type: 'student',
          },
        },
      },
    },
    {
      id: '117',
      type: 'skateRental',
      attributes: {
        size: 17,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4093',
            type: 'student',
          },
        },
      },
    },
    {
      id: '119',
      type: 'skateRental',
      attributes: {
        size: 12.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4095',
            type: 'student',
          },
        },
      },
    },
    {
      id: '120',
      type: 'skateRental',
      attributes: {
        size: 19,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4096',
            type: 'student',
          },
        },
      },
    },
    {
      id: '121',
      type: 'skateRental',
      attributes: {
        size: 9.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4097',
            type: 'student',
          },
        },
      },
    },
    {
      id: '122',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4098',
            type: 'student',
          },
        },
      },
    },
    {
      id: '123',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4099',
            type: 'student',
          },
        },
      },
    },
    {
      id: '124',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4100',
            type: 'student',
          },
        },
      },
    },
    {
      id: '125',
      type: 'skateRental',
      attributes: {
        size: 19.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4101',
            type: 'student',
          },
        },
      },
    },
    {
      id: '126',
      type: 'skateRental',
      attributes: {
        size: 12.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4102',
            type: 'student',
          },
        },
      },
    },
    {
      id: '127',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4103',
            type: 'student',
          },
        },
      },
    },
    {
      id: '128',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4104',
            type: 'student',
          },
        },
      },
    },
    {
      id: '129',
      type: 'skateRental',
      attributes: {
        size: 16,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4105',
            type: 'student',
          },
        },
      },
    },
    {
      id: '130',
      type: 'skateRental',
      attributes: {
        size: 11,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4123',
            type: 'student',
          },
        },
      },
    },
    {
      id: '131',
      type: 'skateRental',
      attributes: {
        size: 6,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4124',
            type: 'student',
          },
        },
      },
    },
    {
      id: '132',
      type: 'skateRental',
      attributes: {
        size: 7.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4125',
            type: 'student',
          },
        },
      },
    },
    {
      id: '133',
      type: 'skateRental',
      attributes: {
        size: 9.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4126',
            type: 'student',
          },
        },
      },
    },
    {
      id: '134',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4127',
            type: 'student',
          },
        },
      },
    },
    {
      id: '135',
      type: 'skateRental',
      attributes: {
        size: 7,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4128',
            type: 'student',
          },
        },
      },
    },
    {
      id: '136',
      type: 'skateRental',
      attributes: {
        size: 12.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4129',
            type: 'student',
          },
        },
      },
    },
    {
      id: '137',
      type: 'skateRental',
      attributes: {
        size: 14,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4130',
            type: 'student',
          },
        },
      },
    },
    {
      id: '139',
      type: 'skateRental',
      attributes: {
        size: 15.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4132',
            type: 'student',
          },
        },
      },
    },
    {
      id: '140',
      type: 'skateRental',
      attributes: {
        size: 7,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4133',
            type: 'student',
          },
        },
      },
    },
    {
      id: '141',
      type: 'skateRental',
      attributes: {
        size: 7.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4134',
            type: 'student',
          },
        },
      },
    },
    {
      id: '143',
      type: 'skateRental',
      attributes: {
        size: 9.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4136',
            type: 'student',
          },
        },
      },
    },
    {
      id: '144',
      type: 'skateRental',
      attributes: {
        size: 17.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4137',
            type: 'student',
          },
        },
      },
    },
    {
      id: '145',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4138',
            type: 'student',
          },
        },
      },
    },
    {
      id: '146',
      type: 'skateRental',
      attributes: {
        size: 16.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4139',
            type: 'student',
          },
        },
      },
    },
    {
      id: '147',
      type: 'skateRental',
      attributes: {
        size: 7,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4140',
            type: 'student',
          },
        },
      },
    },
    {
      id: '148',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4141',
            type: 'student',
          },
        },
      },
    },
    {
      id: '149',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4142',
            type: 'student',
          },
        },
      },
    },
    {
      id: '150',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4143',
            type: 'student',
          },
        },
      },
    },
    {
      id: '152',
      type: 'skateRental',
      attributes: {
        size: 14,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4145',
            type: 'student',
          },
        },
      },
    },
    {
      id: '153',
      type: 'skateRental',
      attributes: {
        size: 15,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4146',
            type: 'student',
          },
        },
      },
    },
    {
      id: '154',
      type: 'skateRental',
      attributes: {
        size: 8.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4162',
            type: 'student',
          },
        },
      },
    },
    {
      id: '155',
      type: 'skateRental',
      attributes: {
        size: 7,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4163',
            type: 'student',
          },
        },
      },
    },
    {
      id: '156',
      type: 'skateRental',
      attributes: {
        size: 18.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4164',
            type: 'student',
          },
        },
      },
    },
    {
      id: '157',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4165',
            type: 'student',
          },
        },
      },
    },
    {
      id: '159',
      type: 'skateRental',
      attributes: {
        size: 6,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4167',
            type: 'student',
          },
        },
      },
    },
    {
      id: '160',
      type: 'skateRental',
      attributes: {
        size: 14.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4168',
            type: 'student',
          },
        },
      },
    },
    {
      id: '162',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4170',
            type: 'student',
          },
        },
      },
    },
    {
      id: '163',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4171',
            type: 'student',
          },
        },
      },
    },
    {
      id: '164',
      type: 'skateRental',
      attributes: {
        size: 13.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4172',
            type: 'student',
          },
        },
      },
    },
    {
      id: '4',
      type: 'skateRental',
      attributes: {
        size: 11.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4173',
            type: 'student',
          },
        },
      },
    },
    {
      id: '165',
      type: 'skateRental',
      attributes: {
        size: 16.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4174',
            type: 'student',
          },
        },
      },
    },
    {
      id: '166',
      type: 'skateRental',
      attributes: {
        size: 6,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4175',
            type: 'student',
          },
        },
      },
    },
    {
      id: '167',
      type: 'skateRental',
      attributes: {
        size: 14,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4176',
            type: 'student',
          },
        },
      },
    },
    {
      id: '168',
      type: 'skateRental',
      attributes: {
        size: 8.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4177',
            type: 'student',
          },
        },
      },
    },
    {
      id: '169',
      type: 'skateRental',
      attributes: {
        size: 12.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4178',
            type: 'student',
          },
        },
      },
    },
    {
      id: '170',
      type: 'skateRental',
      attributes: {
        size: 8.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4179',
            type: 'student',
          },
        },
      },
    },
    {
      id: '171',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4180',
            type: 'student',
          },
        },
      },
    },
    {
      id: '172',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4181',
            type: 'student',
          },
        },
      },
    },
    {
      id: '173',
      type: 'skateRental',
      attributes: {
        size: 11.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4182',
            type: 'student',
          },
        },
      },
    },
    {
      id: '174',
      type: 'skateRental',
      attributes: {
        size: 6,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4183',
            type: 'student',
          },
        },
      },
    },
    {
      id: '176',
      type: 'skateRental',
      attributes: {
        size: 8.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4185',
            type: 'student',
          },
        },
      },
    },
    {
      id: '177',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4186',
            type: 'student',
          },
        },
      },
    },
    {
      id: '178',
      type: 'skateRental',
      attributes: {
        size: 17.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4187',
            type: 'student',
          },
        },
      },
    },
    {
      id: '179',
      type: 'skateRental',
      attributes: {
        size: 17,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4202',
            type: 'student',
          },
        },
      },
    },
    {
      id: '180',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4203',
            type: 'student',
          },
        },
      },
    },
    {
      id: '181',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4204',
            type: 'student',
          },
        },
      },
    },
    {
      id: '182',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4205',
            type: 'student',
          },
        },
      },
    },
    {
      id: '183',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4206',
            type: 'student',
          },
        },
      },
    },
    {
      id: '184',
      type: 'skateRental',
      attributes: {
        size: 7.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4207',
            type: 'student',
          },
        },
      },
    },
    {
      id: '185',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4208',
            type: 'student',
          },
        },
      },
    },
    {
      id: '187',
      type: 'skateRental',
      attributes: {
        size: 8.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4210',
            type: 'student',
          },
        },
      },
    },
    {
      id: '189',
      type: 'skateRental',
      attributes: {
        size: 16.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4212',
            type: 'student',
          },
        },
      },
    },
    {
      id: '190',
      type: 'skateRental',
      attributes: {
        size: 11,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4213',
            type: 'student',
          },
        },
      },
    },
    {
      id: '191',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4214',
            type: 'student',
          },
        },
      },
    },
    {
      id: '193',
      type: 'skateRental',
      attributes: {
        size: 14.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4216',
            type: 'student',
          },
        },
      },
    },
    {
      id: '194',
      type: 'skateRental',
      attributes: {
        size: 18.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4217',
            type: 'student',
          },
        },
      },
    },
    {
      id: '195',
      type: 'skateRental',
      attributes: {
        size: 15.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4218',
            type: 'student',
          },
        },
      },
    },
    {
      id: '196',
      type: 'skateRental',
      attributes: {
        size: 19,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4219',
            type: 'student',
          },
        },
      },
    },
    {
      id: '197',
      type: 'skateRental',
      attributes: {
        size: 14,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4220',
            type: 'student',
          },
        },
      },
    },
    {
      id: '198',
      type: 'skateRental',
      attributes: {
        size: 16.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4221',
            type: 'student',
          },
        },
      },
    },
    {
      id: '199',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4222',
            type: 'student',
          },
        },
      },
    },
    {
      id: '200',
      type: 'skateRental',
      attributes: {
        size: 7.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4223',
            type: 'student',
          },
        },
      },
    },
    {
      id: '201',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4224',
            type: 'student',
          },
        },
      },
    },
    {
      id: '202',
      type: 'skateRental',
      attributes: {
        size: 12.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4225',
            type: 'student',
          },
        },
      },
    },
    {
      id: '203',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4226',
            type: 'student',
          },
        },
      },
    },
    {
      id: '204',
      type: 'skateRental',
      attributes: {
        size: 18,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4244',
            type: 'student',
          },
        },
      },
    },
    {
      id: '205',
      type: 'skateRental',
      attributes: {
        size: 11,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4245',
            type: 'student',
          },
        },
      },
    },
    {
      id: '206',
      type: 'skateRental',
      attributes: {
        size: 13,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4246',
            type: 'student',
          },
        },
      },
    },
    {
      id: '208',
      type: 'skateRental',
      attributes: {
        size: 16,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4248',
            type: 'student',
          },
        },
      },
    },
    {
      id: '209',
      type: 'skateRental',
      attributes: {
        size: 16,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4249',
            type: 'student',
          },
        },
      },
    },
    {
      id: '210',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4250',
            type: 'student',
          },
        },
      },
    },
    {
      id: '211',
      type: 'skateRental',
      attributes: {
        size: 11,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4251',
            type: 'student',
          },
        },
      },
    },
    {
      id: '212',
      type: 'skateRental',
      attributes: {
        size: 16,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4252',
            type: 'student',
          },
        },
      },
    },
    {
      id: '213',
      type: 'skateRental',
      attributes: {
        size: 7.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4253',
            type: 'student',
          },
        },
      },
    },
    {
      id: '215',
      type: 'skateRental',
      attributes: {
        size: 8.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4255',
            type: 'student',
          },
        },
      },
    },
    {
      id: '216',
      type: 'skateRental',
      attributes: {
        size: 17.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4256',
            type: 'student',
          },
        },
      },
    },
    {
      id: '219',
      type: 'skateRental',
      attributes: {
        size: 19.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4259',
            type: 'student',
          },
        },
      },
    },
    {
      id: '220',
      type: 'skateRental',
      attributes: {
        size: 11.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4260',
            type: 'student',
          },
        },
      },
    },
    {
      id: '221',
      type: 'skateRental',
      attributes: {
        size: 16,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4261',
            type: 'student',
          },
        },
      },
    },
    {
      id: '222',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4262',
            type: 'student',
          },
        },
      },
    },
    {
      id: '223',
      type: 'skateRental',
      attributes: {
        size: 9.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4263',
            type: 'student',
          },
        },
      },
    },
    {
      id: '224',
      type: 'skateRental',
      attributes: {
        size: 13,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4264',
            type: 'student',
          },
        },
      },
    },
    {
      id: '225',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4265',
            type: 'student',
          },
        },
      },
    },
    {
      id: '226',
      type: 'skateRental',
      attributes: {
        size: 16,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4266',
            type: 'student',
          },
        },
      },
    },
    {
      id: '227',
      type: 'skateRental',
      attributes: {
        size: 16,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4267',
            type: 'student',
          },
        },
      },
    },
    {
      id: '229',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4269',
            type: 'student',
          },
        },
      },
    },
    {
      id: '231',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4288',
            type: 'student',
          },
        },
      },
    },
    {
      id: '232',
      type: 'skateRental',
      attributes: {
        size: 14.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4289',
            type: 'student',
          },
        },
      },
    },
    {
      id: '233',
      type: 'skateRental',
      attributes: {
        size: 19,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4290',
            type: 'student',
          },
        },
      },
    },
    {
      id: '234',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4291',
            type: 'student',
          },
        },
      },
    },
    {
      id: '235',
      type: 'skateRental',
      attributes: {
        size: 19,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4292',
            type: 'student',
          },
        },
      },
    },
    {
      id: '237',
      type: 'skateRental',
      attributes: {
        size: 17,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4294',
            type: 'student',
          },
        },
      },
    },
    {
      id: '238',
      type: 'skateRental',
      attributes: {
        size: 11,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4295',
            type: 'student',
          },
        },
      },
    },
    {
      id: '239',
      type: 'skateRental',
      attributes: {
        size: 13.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4296',
            type: 'student',
          },
        },
      },
    },
    {
      id: '240',
      type: 'skateRental',
      attributes: {
        size: 18.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4297',
            type: 'student',
          },
        },
      },
    },
    {
      id: '241',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4298',
            type: 'student',
          },
        },
      },
    },
    {
      id: '242',
      type: 'skateRental',
      attributes: {
        size: 15.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4299',
            type: 'student',
          },
        },
      },
    },
    {
      id: '243',
      type: 'skateRental',
      attributes: {
        size: 18,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4300',
            type: 'student',
          },
        },
      },
    },
    {
      id: '244',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4301',
            type: 'student',
          },
        },
      },
    },
    {
      id: '245',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4302',
            type: 'student',
          },
        },
      },
    },
    {
      id: '246',
      type: 'skateRental',
      attributes: {
        size: 13,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4303',
            type: 'student',
          },
        },
      },
    },
    {
      id: '247',
      type: 'skateRental',
      attributes: {
        size: 13.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4304',
            type: 'student',
          },
        },
      },
    },
    {
      id: '248',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4305',
            type: 'student',
          },
        },
      },
    },
    {
      id: '249',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4306',
            type: 'student',
          },
        },
      },
    },
    {
      id: '250',
      type: 'skateRental',
      attributes: {
        size: 11,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4307',
            type: 'student',
          },
        },
      },
    },
    {
      id: '251',
      type: 'skateRental',
      attributes: {
        size: 13,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4308',
            type: 'student',
          },
        },
      },
    },
    {
      id: '252',
      type: 'skateRental',
      attributes: {
        size: 7,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4309',
            type: 'student',
          },
        },
      },
    },
    {
      id: '253',
      type: 'skateRental',
      attributes: {
        size: 14.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4310',
            type: 'student',
          },
        },
      },
    },
    {
      id: '254',
      type: 'skateRental',
      attributes: {
        size: 11,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4311',
            type: 'student',
          },
        },
      },
    },
    {
      id: '255',
      type: 'skateRental',
      attributes: {
        size: 7,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4312',
            type: 'student',
          },
        },
      },
    },
    {
      id: '257',
      type: 'skateRental',
      attributes: {
        size: 12.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4325',
            type: 'student',
          },
        },
      },
    },
    {
      id: '258',
      type: 'skateRental',
      attributes: {
        size: 16.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4326',
            type: 'student',
          },
        },
      },
    },
    {
      id: '259',
      type: 'skateRental',
      attributes: {
        size: 7,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4327',
            type: 'student',
          },
        },
      },
    },
    {
      id: '260',
      type: 'skateRental',
      attributes: {
        size: 9.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4328',
            type: 'student',
          },
        },
      },
    },
    {
      id: '261',
      type: 'skateRental',
      attributes: {
        size: 11.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4329',
            type: 'student',
          },
        },
      },
    },
    {
      id: '262',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4330',
            type: 'student',
          },
        },
      },
    },
    {
      id: '264',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4332',
            type: 'student',
          },
        },
      },
    },
    {
      id: '265',
      type: 'skateRental',
      attributes: {
        size: 19,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4333',
            type: 'student',
          },
        },
      },
    },
    {
      id: '266',
      type: 'skateRental',
      attributes: {
        size: 14,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4334',
            type: 'student',
          },
        },
      },
    },
    {
      id: '267',
      type: 'skateRental',
      attributes: {
        size: 12.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4335',
            type: 'student',
          },
        },
      },
    },
    {
      id: '268',
      type: 'skateRental',
      attributes: {
        size: 16,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4336',
            type: 'student',
          },
        },
      },
    },
    {
      id: '269',
      type: 'skateRental',
      attributes: {
        size: 7.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4337',
            type: 'student',
          },
        },
      },
    },
    {
      id: '270',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4338',
            type: 'student',
          },
        },
      },
    },
    {
      id: '271',
      type: 'skateRental',
      attributes: {
        size: 19,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4339',
            type: 'student',
          },
        },
      },
    },
    {
      id: '273',
      type: 'skateRental',
      attributes: {
        size: 15.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4341',
            type: 'student',
          },
        },
      },
    },
    {
      id: '274',
      type: 'skateRental',
      attributes: {
        size: 11.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4342',
            type: 'student',
          },
        },
      },
    },
    {
      id: '275',
      type: 'skateRental',
      attributes: {
        size: 11.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4343',
            type: 'student',
          },
        },
      },
    },
    {
      id: '276',
      type: 'skateRental',
      attributes: {
        size: 18.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4344',
            type: 'student',
          },
        },
      },
    },
    {
      id: '277',
      type: 'skateRental',
      attributes: {
        size: 19.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4345',
            type: 'student',
          },
        },
      },
    },
    {
      id: '279',
      type: 'skateRental',
      attributes: {
        size: 17.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4347',
            type: 'student',
          },
        },
      },
    },
    {
      id: '280',
      type: 'skateRental',
      attributes: {
        size: 15,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4348',
            type: 'student',
          },
        },
      },
    },
    {
      id: '281',
      type: 'skateRental',
      attributes: {
        size: 6,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4352',
            type: 'student',
          },
        },
      },
    },
    {
      id: '282',
      type: 'skateRental',
      attributes: {
        size: 7,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4354',
            type: 'student',
          },
        },
      },
    },
    {
      id: '283',
      type: 'skateRental',
      attributes: {
        size: 13,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4356',
            type: 'student',
          },
        },
      },
    },
    {
      id: '284',
      type: 'skateRental',
      attributes: {
        size: 11,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4358',
            type: 'student',
          },
        },
      },
    },
    {
      id: '285',
      type: 'skateRental',
      attributes: {
        size: 19.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4363',
            type: 'student',
          },
        },
      },
    },
    {
      id: '286',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4367',
            type: 'student',
          },
        },
      },
    },
    {
      id: '287',
      type: 'skateRental',
      attributes: {
        size: 18,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4379',
            type: 'student',
          },
        },
      },
    },
    {
      id: '288',
      type: 'skateRental',
      attributes: {
        size: 7.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4389',
            type: 'student',
          },
        },
      },
    },
    {
      id: '289',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4401',
            type: 'student',
          },
        },
      },
    },
    {
      id: '290',
      type: 'skateRental',
      attributes: {
        size: 17.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4408',
            type: 'student',
          },
        },
      },
    },
    {
      id: '291',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4411',
            type: 'student',
          },
        },
      },
    },
    {
      id: '292',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4413',
            type: 'student',
          },
        },
      },
    },
    {
      id: '294',
      type: 'skateRental',
      attributes: {
        size: 6,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4425',
            type: 'student',
          },
        },
      },
    },
    {
      id: '295',
      type: 'skateRental',
      attributes: {
        size: 12,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4429',
            type: 'student',
          },
        },
      },
    },
    {
      id: '296',
      type: 'skateRental',
      attributes: {
        size: 18,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4431',
            type: 'student',
          },
        },
      },
    },
    {
      id: '297',
      type: 'skateRental',
      attributes: {
        size: 11.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4432',
            type: 'student',
          },
        },
      },
    },
    {
      id: '299',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4447',
            type: 'student',
          },
        },
      },
    },
    {
      id: '300',
      type: 'skateRental',
      attributes: {
        size: 12,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4451',
            type: 'student',
          },
        },
      },
    },
    {
      id: '302',
      type: 'skateRental',
      attributes: {
        size: 7.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4453',
            type: 'student',
          },
        },
      },
    },
    {
      id: '303',
      type: 'skateRental',
      attributes: {
        size: 8.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4466',
            type: 'student',
          },
        },
      },
    },
    {
      id: '304',
      type: 'skateRental',
      attributes: {
        size: 19.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4467',
            type: 'student',
          },
        },
      },
    },
    {
      id: '306',
      type: 'skateRental',
      attributes: {
        size: 11.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4472',
            type: 'student',
          },
        },
      },
    },
    {
      id: '308',
      type: 'skateRental',
      attributes: {
        size: 16,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4493',
            type: 'student',
          },
        },
      },
    },
    {
      id: '309',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4494',
            type: 'student',
          },
        },
      },
    },
    {
      id: '310',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4495',
            type: 'student',
          },
        },
      },
    },
    {
      id: '311',
      type: 'skateRental',
      attributes: {
        size: 12,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4496',
            type: 'student',
          },
        },
      },
    },
    {
      id: '312',
      type: 'skateRental',
      attributes: {
        size: 17,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4514',
            type: 'student',
          },
        },
      },
    },
    {
      id: '313',
      type: 'skateRental',
      attributes: {
        size: 9,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4531',
            type: 'student',
          },
        },
      },
    },
    {
      id: '315',
      type: 'skateRental',
      attributes: {
        size: 19.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4534',
            type: 'student',
          },
        },
      },
    },
    {
      id: '316',
      type: 'skateRental',
      attributes: {
        size: 15.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4535',
            type: 'student',
          },
        },
      },
    },
    {
      id: '317',
      type: 'skateRental',
      attributes: {
        size: 16.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4582',
            type: 'student',
          },
        },
      },
    },
    {
      id: '318',
      type: 'skateRental',
      attributes: {
        size: 18,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4634',
            type: 'student',
          },
        },
      },
    },
    {
      id: '319',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4636',
            type: 'student',
          },
        },
      },
    },
    {
      id: '320',
      type: 'skateRental',
      attributes: {
        size: 6,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4640',
            type: 'student',
          },
        },
      },
    },
    {
      id: '321',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4668',
            type: 'student',
          },
        },
      },
    },
    {
      id: '322',
      type: 'skateRental',
      attributes: {
        size: 8,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4713',
            type: 'student',
          },
        },
      },
    },
    {
      id: '323',
      type: 'skateRental',
      attributes: {
        size: 13.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4730',
            type: 'student',
          },
        },
      },
    },
    {
      id: '324',
      type: 'skateRental',
      attributes: {
        size: 15.5,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4731',
            type: 'student',
          },
        },
      },
    },
    {
      id: '325',
      type: 'skateRental',
      attributes: {
        size: 9.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4732',
            type: 'student',
          },
        },
      },
    },
    {
      id: '326',
      type: 'skateRental',
      attributes: {
        size: 18,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4734',
            type: 'student',
          },
        },
      },
    },
    {
      id: '327',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4748',
            type: 'student',
          },
        },
      },
    },
    {
      id: '328',
      type: 'skateRental',
      attributes: {
        size: 19.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4757',
            type: 'student',
          },
        },
      },
    },
    {
      id: '329',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4769',
            type: 'student',
          },
        },
      },
    },
    {
      id: '330',
      type: 'skateRental',
      attributes: {
        size: 12,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4772',
            type: 'student',
          },
        },
      },
    },
    {
      id: '331',
      type: 'skateRental',
      attributes: {
        size: 16.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4787',
            type: 'student',
          },
        },
      },
    },
    {
      id: '332',
      type: 'skateRental',
      attributes: {
        size: 18.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4791',
            type: 'student',
          },
        },
      },
    },
    {
      id: '333',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4792',
            type: 'student',
          },
        },
      },
    },
    {
      id: '334',
      type: 'skateRental',
      attributes: {
        size: 13,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4794',
            type: 'student',
          },
        },
      },
    },
    {
      id: '336',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4803',
            type: 'student',
          },
        },
      },
    },
    {
      id: '337',
      type: 'skateRental',
      attributes: {
        size: 19.5,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4817',
            type: 'student',
          },
        },
      },
    },
    {
      id: '338',
      type: 'skateRental',
      attributes: {
        size: 9.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4821',
            type: 'student',
          },
        },
      },
    },
    {
      id: '339',
      type: 'skateRental',
      attributes: {
        size: 6.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4830',
            type: 'student',
          },
        },
      },
    },
    {
      id: '340',
      type: 'skateRental',
      attributes: {
        size: 7.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4831',
            type: 'student',
          },
        },
      },
    },
    {
      id: '341',
      type: 'skateRental',
      attributes: {
        size: 13,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4846',
            type: 'student',
          },
        },
      },
    },
    {
      id: '342',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4847',
            type: 'student',
          },
        },
      },
    },
    {
      id: '343',
      type: 'skateRental',
      attributes: {
        size: 17,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4848',
            type: 'student',
          },
        },
      },
    },
    {
      id: '344',
      type: 'skateRental',
      attributes: {
        size: 18,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4849',
            type: 'student',
          },
        },
      },
    },
    {
      id: '345',
      type: 'skateRental',
      attributes: {
        size: 15.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4851',
            type: 'student',
          },
        },
      },
    },
    {
      id: '346',
      type: 'skateRental',
      attributes: {
        size: 10,
        skateType: 'youth_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4855',
            type: 'student',
          },
        },
      },
    },
    {
      id: '347',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'youth_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4856',
            type: 'student',
          },
        },
      },
    },
    {
      id: '348',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'men_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4860',
            type: 'student',
          },
        },
      },
    },
    {
      id: '349',
      type: 'skateRental',
      attributes: {
        size: 19,
        skateType: 'men_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4863',
            type: 'student',
          },
        },
      },
    },
    {
      id: '7',
      type: 'skateRental',
      attributes: {
        size: 9.5,
        skateType: 'women_figure',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4880',
            type: 'student',
          },
        },
      },
    },
    {
      id: '6',
      type: 'skateRental',
      attributes: {
        size: 10.5,
        skateType: 'women_hockey',
        active: true,
      },
      relationships: {
        student: {
          data: {
            id: '4882',
            type: 'student',
          },
        },
      },
    },
  ],
  included: [
    {
      id: '3892',
      type: 'student',
      attributes: {
        fullName: 'Hermoine Granger',
        preferredName: 'Hermoine',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2005-01-19T05:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36436',
              type: 'studentsSkill',
            },
            {
              id: '36437',
              type: 'studentsSkill',
            },
            {
              id: '36438',
              type: 'studentsSkill',
            },
            {
              id: '36439',
              type: 'studentsSkill',
            },
            {
              id: '36440',
              type: 'studentsSkill',
            },
            {
              id: '36441',
              type: 'studentsSkill',
            },
            {
              id: '36442',
              type: 'studentsSkill',
            },
            {
              id: '36443',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1255',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3886',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3895',
      type: 'student',
      attributes: {
        fullName: 'Sophia Shi',
        preferredName: 'Sophia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-04-19T04:00:00.000Z',
        ltsNumber: '1276041',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36458',
              type: 'studentsSkill',
            },
            {
              id: '36459',
              type: 'studentsSkill',
            },
            {
              id: '36460',
              type: 'studentsSkill',
            },
            {
              id: '36461',
              type: 'studentsSkill',
            },
            {
              id: '36462',
              type: 'studentsSkill',
            },
            {
              id: '36463',
              type: 'studentsSkill',
            },
            {
              id: '36464',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1257',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3894',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3899',
      type: 'student',
      attributes: {
        fullName: 'Sew Button',
        preferredName: 'Sew',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2013-05-01T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36473',
              type: 'studentsSkill',
            },
            {
              id: '36474',
              type: 'studentsSkill',
            },
            {
              id: '36475',
              type: 'studentsSkill',
            },
            {
              id: '36476',
              type: 'studentsSkill',
            },
            {
              id: '36477',
              type: 'studentsSkill',
            },
            {
              id: '36478',
              type: 'studentsSkill',
            },
            {
              id: '36479',
              type: 'studentsSkill',
            },
            {
              id: '36480',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1259',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3898',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3903',
      type: 'student',
      attributes: {
        fullName: 'Elaine Livingston',
        preferredName: 'Elaine',
        email: 'skatebinglts@gmail.com',
        cloudinaryPhotoPublicId: null,
        gender: 'unspecified',
        birthdate: null,
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37232',
              type: 'studentsSkill',
            },
            {
              id: '37233',
              type: 'studentsSkill',
            },
            {
              id: '37234',
              type: 'studentsSkill',
            },
            {
              id: '37235',
              type: 'studentsSkill',
            },
            {
              id: '37236',
              type: 'studentsSkill',
            },
            {
              id: '37237',
              type: 'studentsSkill',
            },
            {
              id: '37238',
              type: 'studentsSkill',
            },
            {
              id: '37239',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: null,
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4034',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3930',
      type: 'student',
      attributes: {
        fullName: 'Christine Camann',
        preferredName: 'Christine',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1954-02-11T00:00:00.000Z',
        ltsNumber: '8899446',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36648',
              type: 'studentsSkill',
            },
            {
              id: '36649',
              type: 'studentsSkill',
            },
            {
              id: '36650',
              type: 'studentsSkill',
            },
            {
              id: '36651',
              type: 'studentsSkill',
            },
            {
              id: '36652',
              type: 'studentsSkill',
            },
            {
              id: '36653',
              type: 'studentsSkill',
            },
            {
              id: '36654',
              type: 'studentsSkill',
            },
            {
              id: '36655',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1267',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '11',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3915',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3932',
      type: 'student',
      attributes: {
        fullName: 'Brianna Wang',
        preferredName: 'Brianna',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2014-08-23T00:00:00.000Z',
        ltsNumber: '1971768',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36663',
              type: 'studentsSkill',
            },
            {
              id: '36664',
              type: 'studentsSkill',
            },
            {
              id: '36665',
              type: 'studentsSkill',
            },
            {
              id: '36666',
              type: 'studentsSkill',
            },
            {
              id: '36667',
              type: 'studentsSkill',
            },
            {
              id: '36668',
              type: 'studentsSkill',
            },
            {
              id: '36669',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1269',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '9',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3917',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3933',
      type: 'student',
      attributes: {
        fullName: 'Yanfei Wang',
        preferredName: 'Yanfei',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1974-04-28T00:00:00.000Z',
        ltsNumber: '1975514',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36670',
              type: 'studentsSkill',
            },
            {
              id: '36671',
              type: 'studentsSkill',
            },
            {
              id: '36672',
              type: 'studentsSkill',
            },
            {
              id: '36673',
              type: 'studentsSkill',
            },
            {
              id: '36674',
              type: 'studentsSkill',
            },
            {
              id: '36675',
              type: 'studentsSkill',
            },
            {
              id: '36676',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1270',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3917',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3934',
      type: 'student',
      attributes: {
        fullName: 'Sophia Stevenson',
        preferredName: 'Sophia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-12-24T00:00:00.000Z',
        ltsNumber: '2029589',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36677',
              type: 'studentsSkill',
            },
            {
              id: '36678',
              type: 'studentsSkill',
            },
            {
              id: '36679',
              type: 'studentsSkill',
            },
            {
              id: '36680',
              type: 'studentsSkill',
            },
            {
              id: '36681',
              type: 'studentsSkill',
            },
            {
              id: '36682',
              type: 'studentsSkill',
            },
            {
              id: '36683',
              type: 'studentsSkill',
            },
            {
              id: '36684',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57393',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1271',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4364',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3935',
      type: 'student',
      attributes: {
        fullName: 'Valeriia Darovskikh',
        preferredName: 'Valeriia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1985-12-02T00:00:00.000Z',
        ltsNumber: '1984315',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36685',
              type: 'studentsSkill',
            },
            {
              id: '36686',
              type: 'studentsSkill',
            },
            {
              id: '36687',
              type: 'studentsSkill',
            },
            {
              id: '36688',
              type: 'studentsSkill',
            },
            {
              id: '36689',
              type: 'studentsSkill',
            },
            {
              id: '36690',
              type: 'studentsSkill',
            },
            {
              id: '36691',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1272',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3919',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3936',
      type: 'student',
      attributes: {
        fullName: 'Varvara Darovskikh',
        preferredName: 'Varvara',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2014-02-22T00:00:00.000Z',
        ltsNumber: '1969963',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36692',
              type: 'studentsSkill',
            },
            {
              id: '36693',
              type: 'studentsSkill',
            },
            {
              id: '36694',
              type: 'studentsSkill',
            },
            {
              id: '36695',
              type: 'studentsSkill',
            },
            {
              id: '36696',
              type: 'studentsSkill',
            },
            {
              id: '36697',
              type: 'studentsSkill',
            },
            {
              id: '36698',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1273',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3919',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3937',
      type: 'student',
      attributes: {
        fullName: 'Alexis Brink',
        preferredName: 'Alexis',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1996-04-14T00:00:00.000Z',
        ltsNumber: '1673047',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36699',
              type: 'studentsSkill',
            },
            {
              id: '36700',
              type: 'studentsSkill',
            },
            {
              id: '36701',
              type: 'studentsSkill',
            },
            {
              id: '36702',
              type: 'studentsSkill',
            },
            {
              id: '36703',
              type: 'studentsSkill',
            },
            {
              id: '36704',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1274',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '21',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3920',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3938',
      type: 'student',
      attributes: {
        fullName: 'Storey Newell',
        preferredName: 'Storey',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2010-05-14T00:00:00.000Z',
        ltsNumber: '1920073',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36705',
              type: 'studentsSkill',
            },
            {
              id: '36706',
              type: 'studentsSkill',
            },
            {
              id: '36707',
              type: 'studentsSkill',
            },
            {
              id: '36708',
              type: 'studentsSkill',
            },
            {
              id: '36709',
              type: 'studentsSkill',
            },
            {
              id: '36710',
              type: 'studentsSkill',
            },
            {
              id: '36711',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1275',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3921',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3939',
      type: 'student',
      attributes: {
        fullName: 'Bristol Ellis',
        preferredName: 'Bristol',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2015-02-02T00:00:00.000Z',
        ltsNumber: '1971738',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36712',
              type: 'studentsSkill',
            },
            {
              id: '36713',
              type: 'studentsSkill',
            },
            {
              id: '36714',
              type: 'studentsSkill',
            },
            {
              id: '36715',
              type: 'studentsSkill',
            },
            {
              id: '36716',
              type: 'studentsSkill',
            },
            {
              id: '36717',
              type: 'studentsSkill',
            },
            {
              id: '36718',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1276',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3922',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3940',
      type: 'student',
      attributes: {
        fullName: 'Piper Ellis',
        preferredName: 'Piper',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-08-09T00:00:00.000Z',
        ltsNumber: '1971737',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36719',
              type: 'studentsSkill',
            },
            {
              id: '36720',
              type: 'studentsSkill',
            },
            {
              id: '36721',
              type: 'studentsSkill',
            },
            {
              id: '36722',
              type: 'studentsSkill',
            },
            {
              id: '36723',
              type: 'studentsSkill',
            },
            {
              id: '36724',
              type: 'studentsSkill',
            },
            {
              id: '36725',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1277',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3922',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3941',
      type: 'student',
      attributes: {
        fullName: 'Connor Serbonich',
        preferredName: 'Connor',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2015-11-14T00:00:00.000Z',
        ltsNumber: '1970305',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36726',
              type: 'studentsSkill',
            },
            {
              id: '36727',
              type: 'studentsSkill',
            },
            {
              id: '36728',
              type: 'studentsSkill',
            },
            {
              id: '36729',
              type: 'studentsSkill',
            },
            {
              id: '36730',
              type: 'studentsSkill',
            },
            {
              id: '36731',
              type: 'studentsSkill',
            },
            {
              id: '36732',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1278',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3923',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3942',
      type: 'student',
      attributes: {
        fullName: 'Celia Honkala',
        preferredName: 'Celia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2015-03-28T00:00:00.000Z',
        ltsNumber: '1971736',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36733',
              type: 'studentsSkill',
            },
            {
              id: '36734',
              type: 'studentsSkill',
            },
            {
              id: '36735',
              type: 'studentsSkill',
            },
            {
              id: '36736',
              type: 'studentsSkill',
            },
            {
              id: '36737',
              type: 'studentsSkill',
            },
            {
              id: '36738',
              type: 'studentsSkill',
            },
            {
              id: '36739',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1279',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3924',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3943',
      type: 'student',
      attributes: {
        fullName: 'Willow Arnold',
        preferredName: 'Willow',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-10-04T00:00:00.000Z',
        ltsNumber: '1861920',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36740',
              type: 'studentsSkill',
            },
            {
              id: '36741',
              type: 'studentsSkill',
            },
            {
              id: '36742',
              type: 'studentsSkill',
            },
            {
              id: '36743',
              type: 'studentsSkill',
            },
            {
              id: '36744',
              type: 'studentsSkill',
            },
            {
              id: '36745',
              type: 'studentsSkill',
            },
            {
              id: '36746',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1280',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3925',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3944',
      type: 'student',
      attributes: {
        fullName: 'Monika Nowak',
        preferredName: 'Monika',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1993-06-29T00:00:00.000Z',
        ltsNumber: '1987430',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36747',
              type: 'studentsSkill',
            },
            {
              id: '36748',
              type: 'studentsSkill',
            },
            {
              id: '36749',
              type: 'studentsSkill',
            },
            {
              id: '36750',
              type: 'studentsSkill',
            },
            {
              id: '36751',
              type: 'studentsSkill',
            },
            {
              id: '36752',
              type: 'studentsSkill',
            },
            {
              id: '36753',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1281',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3925',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3945',
      type: 'student',
      attributes: {
        fullName: 'Anna Tsukroff',
        preferredName: 'Anna',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1988-06-10T00:00:00.000Z',
        ltsNumber: '1989024',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36754',
              type: 'studentsSkill',
            },
            {
              id: '36755',
              type: 'studentsSkill',
            },
            {
              id: '36756',
              type: 'studentsSkill',
            },
            {
              id: '36757',
              type: 'studentsSkill',
            },
            {
              id: '36758',
              type: 'studentsSkill',
            },
            {
              id: '36759',
              type: 'studentsSkill',
            },
            {
              id: '36760',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1282',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3925',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3946',
      type: 'student',
      attributes: {
        fullName: 'Caylee Serbonich',
        preferredName: 'Caylee',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-06-15T00:00:00.000Z',
        ltsNumber: '2024372',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36761',
              type: 'studentsSkill',
            },
            {
              id: '36762',
              type: 'studentsSkill',
            },
            {
              id: '36763',
              type: 'studentsSkill',
            },
            {
              id: '36764',
              type: 'studentsSkill',
            },
            {
              id: '36765',
              type: 'studentsSkill',
            },
            {
              id: '36766',
              type: 'studentsSkill',
            },
            {
              id: '36767',
              type: 'studentsSkill',
            },
            {
              id: '36768',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1283',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3926',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3947',
      type: 'student',
      attributes: {
        fullName: 'Derrick Serbonich',
        preferredName: 'Derrick',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1985-02-22T00:00:00.000Z',
        ltsNumber: '2032330',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36769',
              type: 'studentsSkill',
            },
            {
              id: '36770',
              type: 'studentsSkill',
            },
            {
              id: '36771',
              type: 'studentsSkill',
            },
            {
              id: '36772',
              type: 'studentsSkill',
            },
            {
              id: '36773',
              type: 'studentsSkill',
            },
            {
              id: '36774',
              type: 'studentsSkill',
            },
            {
              id: '36775',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1284',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3926',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3948',
      type: 'student',
      attributes: {
        fullName: 'Cloe Brier',
        preferredName: 'Cloe',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2011-03-15T00:00:00.000Z',
        ltsNumber: '1753779',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36776',
              type: 'studentsSkill',
            },
            {
              id: '36777',
              type: 'studentsSkill',
            },
            {
              id: '36778',
              type: 'studentsSkill',
            },
            {
              id: '36779',
              type: 'studentsSkill',
            },
            {
              id: '36780',
              type: 'studentsSkill',
            },
            {
              id: '36781',
              type: 'studentsSkill',
            },
            {
              id: '36782',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1285',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3927',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3949',
      type: 'student',
      attributes: {
        fullName: 'Aliana Fisher',
        preferredName: 'Aliana',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-06-03T00:00:00.000Z',
        ltsNumber: '1877095',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36783',
              type: 'studentsSkill',
            },
            {
              id: '36784',
              type: 'studentsSkill',
            },
            {
              id: '36785',
              type: 'studentsSkill',
            },
            {
              id: '36786',
              type: 'studentsSkill',
            },
            {
              id: '36787',
              type: 'studentsSkill',
            },
            {
              id: '36788',
              type: 'studentsSkill',
            },
            {
              id: '36789',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1286',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3928',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3950',
      type: 'student',
      attributes: {
        fullName: 'Wren Fisher',
        preferredName: 'Wren',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2016-07-17T00:00:00.000Z',
        ltsNumber: '1997813',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36790',
              type: 'studentsSkill',
            },
            {
              id: '36791',
              type: 'studentsSkill',
            },
            {
              id: '36792',
              type: 'studentsSkill',
            },
            {
              id: '36793',
              type: 'studentsSkill',
            },
            {
              id: '36794',
              type: 'studentsSkill',
            },
            {
              id: '36795',
              type: 'studentsSkill',
            },
            {
              id: '36796',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1287',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3928',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3951',
      type: 'student',
      attributes: {
        fullName: 'Aubrey Kozak',
        preferredName: 'Aubrey',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1981-06-22T00:00:00.000Z',
        ltsNumber: '1877096',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36797',
              type: 'studentsSkill',
            },
            {
              id: '36798',
              type: 'studentsSkill',
            },
            {
              id: '36799',
              type: 'studentsSkill',
            },
            {
              id: '36800',
              type: 'studentsSkill',
            },
            {
              id: '36801',
              type: 'studentsSkill',
            },
            {
              id: '36802',
              type: 'studentsSkill',
            },
            {
              id: '36803',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1288',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3928',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3952',
      type: 'student',
      attributes: {
        fullName: 'Amy Walsh',
        preferredName: 'Amy',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1975-06-07T00:00:00.000Z',
        ltsNumber: '8885944',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36804',
              type: 'studentsSkill',
            },
            {
              id: '36805',
              type: 'studentsSkill',
            },
            {
              id: '36806',
              type: 'studentsSkill',
            },
            {
              id: '36807',
              type: 'studentsSkill',
            },
            {
              id: '36808',
              type: 'studentsSkill',
            },
            {
              id: '36809',
              type: 'studentsSkill',
            },
            {
              id: '36810',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1289',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3929',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3968',
      type: 'student',
      attributes: {
        fullName: 'Elina Jiang',
        preferredName: 'Elina',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-08-31T00:00:00.000Z',
        ltsNumber: '1987439',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36811',
              type: 'studentsSkill',
            },
            {
              id: '36812',
              type: 'studentsSkill',
            },
            {
              id: '36813',
              type: 'studentsSkill',
            },
            {
              id: '36814',
              type: 'studentsSkill',
            },
            {
              id: '36815',
              type: 'studentsSkill',
            },
            {
              id: '36816',
              type: 'studentsSkill',
            },
            {
              id: '36817',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1290',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '5',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3953',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3969',
      type: 'student',
      attributes: {
        fullName: 'Chunhua Li',
        preferredName: 'Chunhua',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1971-01-31T00:00:00.000Z',
        ltsNumber: '2029575',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36818',
              type: 'studentsSkill',
            },
            {
              id: '36819',
              type: 'studentsSkill',
            },
            {
              id: '36820',
              type: 'studentsSkill',
            },
            {
              id: '36821',
              type: 'studentsSkill',
            },
            {
              id: '36822',
              type: 'studentsSkill',
            },
            {
              id: '36823',
              type: 'studentsSkill',
            },
            {
              id: '36824',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1291',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3954',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3971',
      type: 'student',
      attributes: {
        fullName: 'Jessica Zhang',
        preferredName: 'Jessica',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2004-04-15T00:00:00.000Z',
        ltsNumber: '1913701',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36831',
              type: 'studentsSkill',
            },
            {
              id: '36832',
              type: 'studentsSkill',
            },
            {
              id: '36833',
              type: 'studentsSkill',
            },
            {
              id: '36834',
              type: 'studentsSkill',
            },
            {
              id: '36835',
              type: 'studentsSkill',
            },
            {
              id: '36836',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1293',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '21',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3954',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3972',
      type: 'student',
      attributes: {
        fullName: 'Hugo Craver',
        preferredName: 'Hugo',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2010-02-10T00:00:00.000Z',
        ltsNumber: '1404334',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36837',
              type: 'studentsSkill',
            },
            {
              id: '36838',
              type: 'studentsSkill',
            },
            {
              id: '36839',
              type: 'studentsSkill',
            },
            {
              id: '36840',
              type: 'studentsSkill',
            },
            {
              id: '36841',
              type: 'studentsSkill',
            },
            {
              id: '36842',
              type: 'studentsSkill',
            },
            {
              id: '36843',
              type: 'studentsSkill',
            },
            {
              id: '36844',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1294',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '11',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3955',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3974',
      type: 'student',
      attributes: {
        fullName: 'Kaden Scelsi',
        preferredName: 'Kaden',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2014-08-13T00:00:00.000Z',
        ltsNumber: '1987398',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36851',
              type: 'studentsSkill',
            },
            {
              id: '36852',
              type: 'studentsSkill',
            },
            {
              id: '36853',
              type: 'studentsSkill',
            },
            {
              id: '36854',
              type: 'studentsSkill',
            },
            {
              id: '36855',
              type: 'studentsSkill',
            },
            {
              id: '36856',
              type: 'studentsSkill',
            },
            {
              id: '36857',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1296',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3956',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3975',
      type: 'student',
      attributes: {
        fullName: 'Cordelia Nowak',
        preferredName: 'Cordelia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1993-11-28T00:00:00.000Z',
        ltsNumber: '2004821',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36858',
              type: 'studentsSkill',
            },
            {
              id: '36859',
              type: 'studentsSkill',
            },
            {
              id: '36860',
              type: 'studentsSkill',
            },
            {
              id: '36861',
              type: 'studentsSkill',
            },
            {
              id: '36862',
              type: 'studentsSkill',
            },
            {
              id: '36863',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1297',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '20',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [],
        },
      },
    },
    {
      id: '3976',
      type: 'student',
      attributes: {
        fullName: 'Rebecca Cole',
        preferredName: 'Rebecca',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2004-03-04T00:00:00.000Z',
        ltsNumber: '8689976',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36864',
              type: 'studentsSkill',
            },
            {
              id: '36865',
              type: 'studentsSkill',
            },
            {
              id: '36866',
              type: 'studentsSkill',
            },
            {
              id: '36867',
              type: 'studentsSkill',
            },
            {
              id: '36868',
              type: 'studentsSkill',
            },
            {
              id: '36869',
              type: 'studentsSkill',
            },
            {
              id: '36870',
              type: 'studentsSkill',
            },
            {
              id: '36871',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1298',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3958',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3977',
      type: 'student',
      attributes: {
        fullName: 'Guinevere Cotten',
        preferredName: 'Guinevere',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1997-03-18T00:00:00.000Z',
        ltsNumber: '8825376',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36872',
              type: 'studentsSkill',
            },
            {
              id: '36873',
              type: 'studentsSkill',
            },
            {
              id: '36874',
              type: 'studentsSkill',
            },
            {
              id: '36875',
              type: 'studentsSkill',
            },
            {
              id: '36876',
              type: 'studentsSkill',
            },
            {
              id: '36877',
              type: 'studentsSkill',
            },
            {
              id: '36878',
              type: 'studentsSkill',
            },
            {
              id: '36879',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1299',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '11',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3959',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3978',
      type: 'student',
      attributes: {
        fullName: 'Abigail Grzelaczyk',
        preferredName: 'Abigail',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2008-09-19T00:00:00.000Z',
        ltsNumber: '2029593',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36880',
              type: 'studentsSkill',
            },
            {
              id: '36881',
              type: 'studentsSkill',
            },
            {
              id: '36882',
              type: 'studentsSkill',
            },
            {
              id: '36883',
              type: 'studentsSkill',
            },
            {
              id: '36884',
              type: 'studentsSkill',
            },
            {
              id: '36885',
              type: 'studentsSkill',
            },
            {
              id: '36886',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1300',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3960',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3980',
      type: 'student',
      attributes: {
        fullName: 'Lindsey Darling',
        preferredName: 'Lindsey',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2007-07-02T00:00:00.000Z',
        ltsNumber: '1987391',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36893',
              type: 'studentsSkill',
            },
            {
              id: '36894',
              type: 'studentsSkill',
            },
            {
              id: '36895',
              type: 'studentsSkill',
            },
            {
              id: '36896',
              type: 'studentsSkill',
            },
            {
              id: '36897',
              type: 'studentsSkill',
            },
            {
              id: '36898',
              type: 'studentsSkill',
            },
            {
              id: '36899',
              type: 'studentsSkill',
            },
            {
              id: '36900',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1302',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '6',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3961',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3981',
      type: 'student',
      attributes: {
        fullName: 'Alexandra Blaise',
        preferredName: 'Alexandra',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2008-04-17T00:00:00.000Z',
        ltsNumber: '8912527',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36901',
              type: 'studentsSkill',
            },
            {
              id: '36902',
              type: 'studentsSkill',
            },
            {
              id: '36903',
              type: 'studentsSkill',
            },
            {
              id: '36904',
              type: 'studentsSkill',
            },
            {
              id: '36905',
              type: 'studentsSkill',
            },
            {
              id: '36906',
              type: 'studentsSkill',
            },
            {
              id: '36907',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1303',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3962',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3982',
      type: 'student',
      attributes: {
        fullName: 'Amelia Caister Rivera',
        preferredName: 'Amelia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-01-23T00:00:00.000Z',
        ltsNumber: '1877087',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36908',
              type: 'studentsSkill',
            },
            {
              id: '36909',
              type: 'studentsSkill',
            },
            {
              id: '36910',
              type: 'studentsSkill',
            },
            {
              id: '36911',
              type: 'studentsSkill',
            },
            {
              id: '36912',
              type: 'studentsSkill',
            },
            {
              id: '36913',
              type: 'studentsSkill',
            },
            {
              id: '36914',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1304',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3963',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3983',
      type: 'student',
      attributes: {
        fullName: 'Dijana Caister Rivera',
        preferredName: 'Dijana',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1986-03-22T00:00:00.000Z',
        ltsNumber: '1987597',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36915',
              type: 'studentsSkill',
            },
            {
              id: '36916',
              type: 'studentsSkill',
            },
            {
              id: '36917',
              type: 'studentsSkill',
            },
            {
              id: '36918',
              type: 'studentsSkill',
            },
            {
              id: '36919',
              type: 'studentsSkill',
            },
            {
              id: '36920',
              type: 'studentsSkill',
            },
            {
              id: '36921',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1305',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3963',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3984',
      type: 'student',
      attributes: {
        fullName: 'Debbie Mcleod',
        preferredName: 'Debbie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1975-10-08T00:00:00.000Z',
        ltsNumber: '1673061',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36922',
              type: 'studentsSkill',
            },
            {
              id: '36923',
              type: 'studentsSkill',
            },
            {
              id: '36924',
              type: 'studentsSkill',
            },
            {
              id: '36925',
              type: 'studentsSkill',
            },
            {
              id: '36926',
              type: 'studentsSkill',
            },
            {
              id: '36927',
              type: 'studentsSkill',
            },
            {
              id: '36928',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1306',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3964',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3985',
      type: 'student',
      attributes: {
        fullName: 'Leland Pennington',
        preferredName: 'Leland',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2012-05-21T00:00:00.000Z',
        ltsNumber: '1673060',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36929',
              type: 'studentsSkill',
            },
            {
              id: '36930',
              type: 'studentsSkill',
            },
            {
              id: '36931',
              type: 'studentsSkill',
            },
            {
              id: '36932',
              type: 'studentsSkill',
            },
            {
              id: '36933',
              type: 'studentsSkill',
            },
            {
              id: '36934',
              type: 'studentsSkill',
            },
            {
              id: '36935',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1307',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3964',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3986',
      type: 'student',
      attributes: {
        fullName: 'Ezra Leeson Schatz',
        preferredName: 'Ezra',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2009-09-19T00:00:00.000Z',
        ltsNumber: '1700586',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36936',
              type: 'studentsSkill',
            },
            {
              id: '36937',
              type: 'studentsSkill',
            },
            {
              id: '36938',
              type: 'studentsSkill',
            },
            {
              id: '36939',
              type: 'studentsSkill',
            },
            {
              id: '36940',
              type: 'studentsSkill',
            },
            {
              id: '36941',
              type: 'studentsSkill',
            },
            {
              id: '36942',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1308',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3965',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3987',
      type: 'student',
      attributes: {
        fullName: 'Joe Schatz',
        preferredName: 'Joe',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1980-09-09T00:00:00.000Z',
        ltsNumber: '2006894',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36943',
              type: 'studentsSkill',
            },
            {
              id: '36944',
              type: 'studentsSkill',
            },
            {
              id: '36945',
              type: 'studentsSkill',
            },
            {
              id: '36946',
              type: 'studentsSkill',
            },
            {
              id: '36947',
              type: 'studentsSkill',
            },
            {
              id: '36948',
              type: 'studentsSkill',
            },
            {
              id: '36949',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1309',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3965',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3988',
      type: 'student',
      attributes: {
        fullName: 'Deedar Zebari',
        preferredName: 'Deedar',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2010-03-02T05:00:00.000Z',
        ltsNumber: '1803160',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36955',
              type: 'studentsSkill',
            },
            {
              id: '36956',
              type: 'studentsSkill',
            },
            {
              id: '36957',
              type: 'studentsSkill',
            },
            {
              id: '36954',
              type: 'studentsSkill',
            },
            {
              id: '36953',
              type: 'studentsSkill',
            },
            {
              id: '36951',
              type: 'studentsSkill',
            },
            {
              id: '36950',
              type: 'studentsSkill',
            },
            {
              id: '36952',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57542',
              type: 'klassDaysStudent',
            },
            {
              id: '57594',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1310',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3966',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3989',
      type: 'student',
      attributes: {
        fullName: 'Havrist Zebari',
        preferredName: 'Havrist',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1981-10-02T00:00:00.000Z',
        ltsNumber: '1971770',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36958',
              type: 'studentsSkill',
            },
            {
              id: '36959',
              type: 'studentsSkill',
            },
            {
              id: '36960',
              type: 'studentsSkill',
            },
            {
              id: '36961',
              type: 'studentsSkill',
            },
            {
              id: '36962',
              type: 'studentsSkill',
            },
            {
              id: '36963',
              type: 'studentsSkill',
            },
            {
              id: '36964',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1310',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3966',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3991',
      type: 'student',
      attributes: {
        fullName: 'Alicia Adams',
        preferredName: 'Alicia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-05-18T00:00:00.000Z',
        ltsNumber: '1658248',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36972',
              type: 'studentsSkill',
            },
            {
              id: '36973',
              type: 'studentsSkill',
            },
            {
              id: '36974',
              type: 'studentsSkill',
            },
            {
              id: '36975',
              type: 'studentsSkill',
            },
            {
              id: '36976',
              type: 'studentsSkill',
            },
            {
              id: '36977',
              type: 'studentsSkill',
            },
            {
              id: '36978',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1313',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3967',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '3992',
      type: 'student',
      attributes: {
        fullName: 'Derek Adams',
        preferredName: 'Derek',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1982-03-18T00:00:00.000Z',
        ltsNumber: '1983432',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36979',
              type: 'studentsSkill',
            },
            {
              id: '36980',
              type: 'studentsSkill',
            },
            {
              id: '36981',
              type: 'studentsSkill',
            },
            {
              id: '36982',
              type: 'studentsSkill',
            },
            {
              id: '36983',
              type: 'studentsSkill',
            },
            {
              id: '36984',
              type: 'studentsSkill',
            },
            {
              id: '36985',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1314',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3967',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4004',
      type: 'student',
      attributes: {
        fullName: 'Isabella Lee',
        preferredName: 'Isabella',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-12-28T00:00:00.000Z',
        ltsNumber: '1987445',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41847',
              type: 'studentsSkill',
            },
            {
              id: '41848',
              type: 'studentsSkill',
            },
            {
              id: '41849',
              type: 'studentsSkill',
            },
            {
              id: '41850',
              type: 'studentsSkill',
            },
            {
              id: '41845',
              type: 'studentsSkill',
            },
            {
              id: '41846',
              type: 'studentsSkill',
            },
            {
              id: '41851',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57155',
              type: 'klassDaysStudent',
            },
            {
              id: '57156',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1315',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3993',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4005',
      type: 'student',
      attributes: {
        fullName: 'Joseph Lee',
        preferredName: 'Joseph',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2010-11-30T00:00:00.000Z',
        ltsNumber: '1987442',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '36996',
              type: 'studentsSkill',
            },
            {
              id: '36998',
              type: 'studentsSkill',
            },
            {
              id: '36999',
              type: 'studentsSkill',
            },
            {
              id: '36993',
              type: 'studentsSkill',
            },
            {
              id: '36995',
              type: 'studentsSkill',
            },
            {
              id: '36994',
              type: 'studentsSkill',
            },
            {
              id: '36997',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57562',
              type: 'klassDaysStudent',
            },
            {
              id: '57615',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1316',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3993',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4006',
      type: 'student',
      attributes: {
        fullName: 'Yuhe Dai',
        preferredName: 'Yuhe',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2011-10-31T00:00:00.000Z',
        ltsNumber: '1969950',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37000',
              type: 'studentsSkill',
            },
            {
              id: '37001',
              type: 'studentsSkill',
            },
            {
              id: '37002',
              type: 'studentsSkill',
            },
            {
              id: '37003',
              type: 'studentsSkill',
            },
            {
              id: '37004',
              type: 'studentsSkill',
            },
            {
              id: '37005',
              type: 'studentsSkill',
            },
            {
              id: '37006',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1317',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3994',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4007',
      type: 'student',
      attributes: {
        fullName: 'James Strawn',
        preferredName: 'James',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1968-04-23T00:00:00.000Z',
        ltsNumber: '2021474',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37007',
              type: 'studentsSkill',
            },
            {
              id: '37008',
              type: 'studentsSkill',
            },
            {
              id: '37009',
              type: 'studentsSkill',
            },
            {
              id: '37010',
              type: 'studentsSkill',
            },
            {
              id: '37011',
              type: 'studentsSkill',
            },
            {
              id: '37012',
              type: 'studentsSkill',
            },
            {
              id: '37013',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1318',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3995',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4008',
      type: 'student',
      attributes: {
        fullName: 'Madison Strawn',
        preferredName: 'Madison',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2007-04-27T00:00:00.000Z',
        ltsNumber: '1803302',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37014',
              type: 'studentsSkill',
            },
            {
              id: '37015',
              type: 'studentsSkill',
            },
            {
              id: '37016',
              type: 'studentsSkill',
            },
            {
              id: '37017',
              type: 'studentsSkill',
            },
            {
              id: '37018',
              type: 'studentsSkill',
            },
            {
              id: '37019',
              type: 'studentsSkill',
            },
            {
              id: '37020',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1319',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '12',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3995',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4009',
      type: 'student',
      attributes: {
        fullName: 'Toby Wollin',
        preferredName: 'Toby',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1952-07-03T00:00:00.000Z',
        ltsNumber: '1254377',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37021',
              type: 'studentsSkill',
            },
            {
              id: '37022',
              type: 'studentsSkill',
            },
            {
              id: '37023',
              type: 'studentsSkill',
            },
            {
              id: '37024',
              type: 'studentsSkill',
            },
            {
              id: '37025',
              type: 'studentsSkill',
            },
            {
              id: '37026',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1320',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '22',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3996',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4010',
      type: 'student',
      attributes: {
        fullName: 'Davit Zalinyan',
        preferredName: 'Davit',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2015-11-30T00:00:00.000Z',
        ltsNumber: '2013441',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37027',
              type: 'studentsSkill',
            },
            {
              id: '37028',
              type: 'studentsSkill',
            },
            {
              id: '37029',
              type: 'studentsSkill',
            },
            {
              id: '37030',
              type: 'studentsSkill',
            },
            {
              id: '37031',
              type: 'studentsSkill',
            },
            {
              id: '37032',
              type: 'studentsSkill',
            },
            {
              id: '37033',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1321',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '9',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3997',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4011',
      type: 'student',
      attributes: {
        fullName: 'Heghine Zalinyan',
        preferredName: 'Heghine',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1974-10-11T00:00:00.000Z',
        ltsNumber: '2013444',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37034',
              type: 'studentsSkill',
            },
            {
              id: '37035',
              type: 'studentsSkill',
            },
            {
              id: '37036',
              type: 'studentsSkill',
            },
            {
              id: '37037',
              type: 'studentsSkill',
            },
            {
              id: '37038',
              type: 'studentsSkill',
            },
            {
              id: '37039',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1322',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '19',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3997',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4012',
      type: 'student',
      attributes: {
        fullName: 'Lilit Zalinyan',
        preferredName: 'Lilit',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2015-11-30T00:00:00.000Z',
        ltsNumber: '2013443',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37040',
              type: 'studentsSkill',
            },
            {
              id: '37041',
              type: 'studentsSkill',
            },
            {
              id: '37042',
              type: 'studentsSkill',
            },
            {
              id: '37043',
              type: 'studentsSkill',
            },
            {
              id: '37044',
              type: 'studentsSkill',
            },
            {
              id: '37045',
              type: 'studentsSkill',
            },
            {
              id: '37046',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1323',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '9',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3997',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4013',
      type: 'student',
      attributes: {
        fullName: 'Anna Shank',
        preferredName: 'Anna',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2011-02-20T00:00:00.000Z',
        ltsNumber: '1919978',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37047',
              type: 'studentsSkill',
            },
            {
              id: '37048',
              type: 'studentsSkill',
            },
            {
              id: '37049',
              type: 'studentsSkill',
            },
            {
              id: '37050',
              type: 'studentsSkill',
            },
            {
              id: '37051',
              type: 'studentsSkill',
            },
            {
              id: '37052',
              type: 'studentsSkill',
            },
            {
              id: '37053',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1324',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3998',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4014',
      type: 'student',
      attributes: {
        fullName: 'Avery Shank',
        preferredName: 'Avery',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-06-11T00:00:00.000Z',
        ltsNumber: '1919979',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37054',
              type: 'studentsSkill',
            },
            {
              id: '37055',
              type: 'studentsSkill',
            },
            {
              id: '37056',
              type: 'studentsSkill',
            },
            {
              id: '37057',
              type: 'studentsSkill',
            },
            {
              id: '37058',
              type: 'studentsSkill',
            },
            {
              id: '37059',
              type: 'studentsSkill',
            },
            {
              id: '37060',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1325',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3998',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4015',
      type: 'student',
      attributes: {
        fullName: 'Lori Shank',
        preferredName: 'Lori',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1973-11-26T00:00:00.000Z',
        ltsNumber: '1919980',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37061',
              type: 'studentsSkill',
            },
            {
              id: '37062',
              type: 'studentsSkill',
            },
            {
              id: '37063',
              type: 'studentsSkill',
            },
            {
              id: '37064',
              type: 'studentsSkill',
            },
            {
              id: '37065',
              type: 'studentsSkill',
            },
            {
              id: '37066',
              type: 'studentsSkill',
            },
            {
              id: '37067',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1326',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3998',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4018',
      type: 'student',
      attributes: {
        fullName: 'Jolina Gehris',
        preferredName: 'Jolina',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2014-09-08T00:00:00.000Z',
        ltsNumber: '1877079',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37082',
              type: 'studentsSkill',
            },
            {
              id: '37083',
              type: 'studentsSkill',
            },
            {
              id: '37084',
              type: 'studentsSkill',
            },
            {
              id: '37085',
              type: 'studentsSkill',
            },
            {
              id: '37086',
              type: 'studentsSkill',
            },
            {
              id: '37087',
              type: 'studentsSkill',
            },
            {
              id: '37088',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1329',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '9',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3999',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4019',
      type: 'student',
      attributes: {
        fullName: 'Pete Gehris',
        preferredName: 'Pete',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1962-05-23T00:00:00.000Z',
        ltsNumber: '1895140',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37089',
              type: 'studentsSkill',
            },
            {
              id: '37090',
              type: 'studentsSkill',
            },
            {
              id: '37091',
              type: 'studentsSkill',
            },
            {
              id: '37092',
              type: 'studentsSkill',
            },
            {
              id: '37093',
              type: 'studentsSkill',
            },
            {
              id: '37094',
              type: 'studentsSkill',
            },
            {
              id: '37095',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1330',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3999',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4020',
      type: 'student',
      attributes: {
        fullName: 'Hudson Tanner',
        preferredName: 'Hudson',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2009-08-17T00:00:00.000Z',
        ltsNumber: '1417913',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37096',
              type: 'studentsSkill',
            },
            {
              id: '37097',
              type: 'studentsSkill',
            },
            {
              id: '37098',
              type: 'studentsSkill',
            },
            {
              id: '37099',
              type: 'studentsSkill',
            },
            {
              id: '37100',
              type: 'studentsSkill',
            },
            {
              id: '37101',
              type: 'studentsSkill',
            },
            {
              id: '37102',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1331',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4000',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4021',
      type: 'student',
      attributes: {
        fullName: 'Cora Richtsmeier',
        preferredName: 'Cora',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2008-12-27T00:00:00.000Z',
        ltsNumber: '1998784',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37103',
              type: 'studentsSkill',
            },
            {
              id: '37104',
              type: 'studentsSkill',
            },
            {
              id: '37105',
              type: 'studentsSkill',
            },
            {
              id: '37106',
              type: 'studentsSkill',
            },
            {
              id: '37107',
              type: 'studentsSkill',
            },
            {
              id: '37108',
              type: 'studentsSkill',
            },
            {
              id: '37109',
              type: 'studentsSkill',
            },
            {
              id: '37110',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1332',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4001',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4022',
      type: 'student',
      attributes: {
        fullName: 'Jake Richtsmeier',
        preferredName: 'Jake',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1973-05-06T00:00:00.000Z',
        ltsNumber: '1998786',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37111',
              type: 'studentsSkill',
            },
            {
              id: '37112',
              type: 'studentsSkill',
            },
            {
              id: '37113',
              type: 'studentsSkill',
            },
            {
              id: '37114',
              type: 'studentsSkill',
            },
            {
              id: '37115',
              type: 'studentsSkill',
            },
            {
              id: '37116',
              type: 'studentsSkill',
            },
            {
              id: '37117',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1333',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4001',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4023',
      type: 'student',
      attributes: {
        fullName: 'Mira Richtsmeier',
        preferredName: 'Mira',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-08-04T00:00:00.000Z',
        ltsNumber: '1998785',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37118',
              type: 'studentsSkill',
            },
            {
              id: '37119',
              type: 'studentsSkill',
            },
            {
              id: '37120',
              type: 'studentsSkill',
            },
            {
              id: '37121',
              type: 'studentsSkill',
            },
            {
              id: '37122',
              type: 'studentsSkill',
            },
            {
              id: '37123',
              type: 'studentsSkill',
            },
            {
              id: '37124',
              type: 'studentsSkill',
            },
            {
              id: '37125',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1334',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4001',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4024',
      type: 'student',
      attributes: {
        fullName: 'Jessica Anderson',
        preferredName: 'Jessie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1984-09-30T04:00:00.000Z',
        ltsNumber: '1893705',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37126',
              type: 'studentsSkill',
            },
            {
              id: '37127',
              type: 'studentsSkill',
            },
            {
              id: '37128',
              type: 'studentsSkill',
            },
            {
              id: '37129',
              type: 'studentsSkill',
            },
            {
              id: '37130',
              type: 'studentsSkill',
            },
            {
              id: '37131',
              type: 'studentsSkill',
            },
            {
              id: '37132',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1541',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4002',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4025',
      type: 'student',
      attributes: {
        fullName: 'Rachel Anderson',
        preferredName: 'Rachel',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2009-08-25T00:00:00.000Z',
        ltsNumber: '1893703',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37133',
              type: 'studentsSkill',
            },
            {
              id: '37134',
              type: 'studentsSkill',
            },
            {
              id: '37135',
              type: 'studentsSkill',
            },
            {
              id: '37136',
              type: 'studentsSkill',
            },
            {
              id: '37137',
              type: 'studentsSkill',
            },
            {
              id: '37138',
              type: 'studentsSkill',
            },
            {
              id: '37139',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1336',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '5',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4002',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4026',
      type: 'student',
      attributes: {
        fullName: 'Jerusha Myla',
        preferredName: 'Jerusha',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2014-05-23T00:00:00.000Z',
        ltsNumber: '1987431',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37140',
              type: 'studentsSkill',
            },
            {
              id: '37141',
              type: 'studentsSkill',
            },
            {
              id: '37142',
              type: 'studentsSkill',
            },
            {
              id: '37143',
              type: 'studentsSkill',
            },
            {
              id: '37144',
              type: 'studentsSkill',
            },
            {
              id: '37145',
              type: 'studentsSkill',
            },
            {
              id: '37146',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1337',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4003',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4043',
      type: 'student',
      attributes: {
        fullName: 'Aurora Duell',
        preferredName: 'Aurora',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-11-16T00:00:00.000Z',
        ltsNumber: '2002893',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37147',
              type: 'studentsSkill',
            },
            {
              id: '37148',
              type: 'studentsSkill',
            },
            {
              id: '37149',
              type: 'studentsSkill',
            },
            {
              id: '37150',
              type: 'studentsSkill',
            },
            {
              id: '37151',
              type: 'studentsSkill',
            },
            {
              id: '37152',
              type: 'studentsSkill',
            },
            {
              id: '37153',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1338',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4027',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4044',
      type: 'student',
      attributes: {
        fullName: 'Lanora Duell',
        preferredName: 'Lanora',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1984-07-15T00:00:00.000Z',
        ltsNumber: '2015074',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37154',
              type: 'studentsSkill',
            },
            {
              id: '37155',
              type: 'studentsSkill',
            },
            {
              id: '37156',
              type: 'studentsSkill',
            },
            {
              id: '37157',
              type: 'studentsSkill',
            },
            {
              id: '37158',
              type: 'studentsSkill',
            },
            {
              id: '37159',
              type: 'studentsSkill',
            },
            {
              id: '37160',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1339',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4027',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4045',
      type: 'student',
      attributes: {
        fullName: 'Sylvia Duell',
        preferredName: 'Sylvia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2015-06-25T00:00:00.000Z',
        ltsNumber: '2002889',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37161',
              type: 'studentsSkill',
            },
            {
              id: '37162',
              type: 'studentsSkill',
            },
            {
              id: '37163',
              type: 'studentsSkill',
            },
            {
              id: '37164',
              type: 'studentsSkill',
            },
            {
              id: '37165',
              type: 'studentsSkill',
            },
            {
              id: '37166',
              type: 'studentsSkill',
            },
            {
              id: '37167',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1340',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4027',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4046',
      type: 'student',
      attributes: {
        fullName: 'Laura Ansbro',
        preferredName: 'Laura',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1976-05-29T00:00:00.000Z',
        ltsNumber: '1969945',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37168',
              type: 'studentsSkill',
            },
            {
              id: '37169',
              type: 'studentsSkill',
            },
            {
              id: '37170',
              type: 'studentsSkill',
            },
            {
              id: '37171',
              type: 'studentsSkill',
            },
            {
              id: '37172',
              type: 'studentsSkill',
            },
            {
              id: '37173',
              type: 'studentsSkill',
            },
            {
              id: '37174',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1341',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4028',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4047',
      type: 'student',
      attributes: {
        fullName: 'Morly Ansbro Brown',
        preferredName: 'Morly',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2014-10-05T00:00:00.000Z',
        ltsNumber: '1969944',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37175',
              type: 'studentsSkill',
            },
            {
              id: '37176',
              type: 'studentsSkill',
            },
            {
              id: '37177',
              type: 'studentsSkill',
            },
            {
              id: '37178',
              type: 'studentsSkill',
            },
            {
              id: '37179',
              type: 'studentsSkill',
            },
            {
              id: '37180',
              type: 'studentsSkill',
            },
            {
              id: '37181',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1342',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4028',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4048',
      type: 'student',
      attributes: {
        fullName: 'Berrak Yilmaz',
        preferredName: 'Berrak',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2009-05-31T00:00:00.000Z',
        ltsNumber: '1989035',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37182',
              type: 'studentsSkill',
            },
            {
              id: '37183',
              type: 'studentsSkill',
            },
            {
              id: '37184',
              type: 'studentsSkill',
            },
            {
              id: '37185',
              type: 'studentsSkill',
            },
            {
              id: '37186',
              type: 'studentsSkill',
            },
            {
              id: '37187',
              type: 'studentsSkill',
            },
            {
              id: '37188',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1343',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4029',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4050',
      type: 'student',
      attributes: {
        fullName: 'Lee Anne Bennett',
        preferredName: 'Lee Anne',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1979-09-24T00:00:00.000Z',
        ltsNumber: '1983464',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37197',
              type: 'studentsSkill',
            },
            {
              id: '37198',
              type: 'studentsSkill',
            },
            {
              id: '37199',
              type: 'studentsSkill',
            },
            {
              id: '37200',
              type: 'studentsSkill',
            },
            {
              id: '37201',
              type: 'studentsSkill',
            },
            {
              id: '37202',
              type: 'studentsSkill',
            },
            {
              id: '37203',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1345',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4031',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4051',
      type: 'student',
      attributes: {
        fullName: 'Emma Tong',
        preferredName: 'Emma',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2015-07-27T00:00:00.000Z',
        ltsNumber: '1970310',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41824',
              type: 'studentsSkill',
            },
            {
              id: '41825',
              type: 'studentsSkill',
            },
            {
              id: '41826',
              type: 'studentsSkill',
            },
            {
              id: '41828',
              type: 'studentsSkill',
            },
            {
              id: '41821',
              type: 'studentsSkill',
            },
            {
              id: '41822',
              type: 'studentsSkill',
            },
            {
              id: '41827',
              type: 'studentsSkill',
            },
            {
              id: '41823',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57152',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1346',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4032',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4052',
      type: 'student',
      attributes: {
        fullName: 'Kin Tong',
        preferredName: 'Kin',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1982-08-08T00:00:00.000Z',
        ltsNumber: '2128020',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37211',
              type: 'studentsSkill',
            },
            {
              id: '37212',
              type: 'studentsSkill',
            },
            {
              id: '37213',
              type: 'studentsSkill',
            },
            {
              id: '37214',
              type: 'studentsSkill',
            },
            {
              id: '37215',
              type: 'studentsSkill',
            },
            {
              id: '37216',
              type: 'studentsSkill',
            },
            {
              id: '37217',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56814',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1347',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4032',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4053',
      type: 'student',
      attributes: {
        fullName: 'Clara Swierk',
        preferredName: 'Clara',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-11-29T00:00:00.000Z',
        ltsNumber: '1913669',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37218',
              type: 'studentsSkill',
            },
            {
              id: '37219',
              type: 'studentsSkill',
            },
            {
              id: '37220',
              type: 'studentsSkill',
            },
            {
              id: '37221',
              type: 'studentsSkill',
            },
            {
              id: '37222',
              type: 'studentsSkill',
            },
            {
              id: '37223',
              type: 'studentsSkill',
            },
            {
              id: '37224',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1348',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4033',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4054',
      type: 'student',
      attributes: {
        fullName: 'Lindsey Swierk',
        preferredName: 'Lindsey',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1985-09-11T00:00:00.000Z',
        ltsNumber: '2027372',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37225',
              type: 'studentsSkill',
            },
            {
              id: '37226',
              type: 'studentsSkill',
            },
            {
              id: '37227',
              type: 'studentsSkill',
            },
            {
              id: '37228',
              type: 'studentsSkill',
            },
            {
              id: '37229',
              type: 'studentsSkill',
            },
            {
              id: '37230',
              type: 'studentsSkill',
            },
            {
              id: '37231',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1349',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4033',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4055',
      type: 'student',
      attributes: {
        fullName: 'Elizabeth Leidy',
        preferredName: 'Elizabeth',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1985-06-08T00:00:00.000Z',
        ltsNumber: '2029582',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37240',
              type: 'studentsSkill',
            },
            {
              id: '37241',
              type: 'studentsSkill',
            },
            {
              id: '37242',
              type: 'studentsSkill',
            },
            {
              id: '37243',
              type: 'studentsSkill',
            },
            {
              id: '37244',
              type: 'studentsSkill',
            },
            {
              id: '37245',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1350',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '19',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4035',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4056',
      type: 'student',
      attributes: {
        fullName: 'Audra Brooks',
        preferredName: 'Audra',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2014-11-22T05:00:00.000Z',
        ltsNumber: '1913672',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37246',
              type: 'studentsSkill',
            },
            {
              id: '37247',
              type: 'studentsSkill',
            },
            {
              id: '37248',
              type: 'studentsSkill',
            },
            {
              id: '37249',
              type: 'studentsSkill',
            },
            {
              id: '37250',
              type: 'studentsSkill',
            },
            {
              id: '37251',
              type: 'studentsSkill',
            },
            {
              id: '37252',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1351',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4036',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4057',
      type: 'student',
      attributes: {
        fullName: 'Willow Fedoris',
        preferredName: 'Willow',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-10-08T00:00:00.000Z',
        ltsNumber: '1975510',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37253',
              type: 'studentsSkill',
            },
            {
              id: '37254',
              type: 'studentsSkill',
            },
            {
              id: '37255',
              type: 'studentsSkill',
            },
            {
              id: '37256',
              type: 'studentsSkill',
            },
            {
              id: '37257',
              type: 'studentsSkill',
            },
            {
              id: '37258',
              type: 'studentsSkill',
            },
            {
              id: '37259',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1352',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4037',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4058',
      type: 'student',
      attributes: {
        fullName: 'Liam Thompson',
        preferredName: 'Liam',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2008-10-08T00:00:00.000Z',
        ltsNumber: '2006900',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37260',
              type: 'studentsSkill',
            },
            {
              id: '37261',
              type: 'studentsSkill',
            },
            {
              id: '37262',
              type: 'studentsSkill',
            },
            {
              id: '37263',
              type: 'studentsSkill',
            },
            {
              id: '37264',
              type: 'studentsSkill',
            },
            {
              id: '37265',
              type: 'studentsSkill',
            },
            {
              id: '37266',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1353',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4038',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4059',
      type: 'student',
      attributes: {
        fullName: 'Rocky Thompson',
        preferredName: 'Rocky',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2010-12-08T00:00:00.000Z',
        ltsNumber: '2006905',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37267',
              type: 'studentsSkill',
            },
            {
              id: '37268',
              type: 'studentsSkill',
            },
            {
              id: '37269',
              type: 'studentsSkill',
            },
            {
              id: '37270',
              type: 'studentsSkill',
            },
            {
              id: '37271',
              type: 'studentsSkill',
            },
            {
              id: '37272',
              type: 'studentsSkill',
            },
            {
              id: '37273',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1354',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4038',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4060',
      type: 'student',
      attributes: {
        fullName: 'Julia Clark',
        preferredName: 'Julia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2015-05-11T00:00:00.000Z',
        ltsNumber: '1983440',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37274',
              type: 'studentsSkill',
            },
            {
              id: '37275',
              type: 'studentsSkill',
            },
            {
              id: '37276',
              type: 'studentsSkill',
            },
            {
              id: '37277',
              type: 'studentsSkill',
            },
            {
              id: '37278',
              type: 'studentsSkill',
            },
            {
              id: '37279',
              type: 'studentsSkill',
            },
            {
              id: '37280',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1355',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4039',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4061',
      type: 'student',
      attributes: {
        fullName: 'Maureen Clark',
        preferredName: 'Maureen',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1983-07-22T00:00:00.000Z',
        ltsNumber: '1983441',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37281',
              type: 'studentsSkill',
            },
            {
              id: '37282',
              type: 'studentsSkill',
            },
            {
              id: '37283',
              type: 'studentsSkill',
            },
            {
              id: '37284',
              type: 'studentsSkill',
            },
            {
              id: '37285',
              type: 'studentsSkill',
            },
            {
              id: '37286',
              type: 'studentsSkill',
            },
            {
              id: '37287',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1356',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4039',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4062',
      type: 'student',
      attributes: {
        fullName: 'Peyton Mc Rae Cole',
        preferredName: 'Peyton',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2009-12-16T00:00:00.000Z',
        ltsNumber: '1920074',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37288',
              type: 'studentsSkill',
            },
            {
              id: '37289',
              type: 'studentsSkill',
            },
            {
              id: '37291',
              type: 'studentsSkill',
            },
            {
              id: '37293',
              type: 'studentsSkill',
            },
            {
              id: '37292',
              type: 'studentsSkill',
            },
            {
              id: '37294',
              type: 'studentsSkill',
            },
            {
              id: '37290',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57535',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1357',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '5',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4040',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4063',
      type: 'student',
      attributes: {
        fullName: 'Philip Gusman',
        preferredName: 'Philip',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1978-10-19T00:00:00.000Z',
        ltsNumber: '2029572',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37295',
              type: 'studentsSkill',
            },
            {
              id: '37296',
              type: 'studentsSkill',
            },
            {
              id: '37297',
              type: 'studentsSkill',
            },
            {
              id: '37298',
              type: 'studentsSkill',
            },
            {
              id: '37299',
              type: 'studentsSkill',
            },
            {
              id: '37300',
              type: 'studentsSkill',
            },
            {
              id: '37301',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1358',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4041',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4064',
      type: 'student',
      attributes: {
        fullName: 'Madyson Fedoris',
        preferredName: 'Madyson',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1993-05-25T04:00:00.000Z',
        ltsNumber: '1987424',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37302',
              type: 'studentsSkill',
            },
            {
              id: '37303',
              type: 'studentsSkill',
            },
            {
              id: '37304',
              type: 'studentsSkill',
            },
            {
              id: '37305',
              type: 'studentsSkill',
            },
            {
              id: '37306',
              type: 'studentsSkill',
            },
            {
              id: '37307',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1544',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '19',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4042',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4082',
      type: 'student',
      attributes: {
        fullName: 'Elizabeth Abbey',
        preferredName: 'Elizabeth',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2010-02-24T00:00:00.000Z',
        ltsNumber: '1106463',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37308',
              type: 'studentsSkill',
            },
            {
              id: '37309',
              type: 'studentsSkill',
            },
            {
              id: '37310',
              type: 'studentsSkill',
            },
            {
              id: '37311',
              type: 'studentsSkill',
            },
            {
              id: '37312',
              type: 'studentsSkill',
            },
            {
              id: '37313',
              type: 'studentsSkill',
            },
            {
              id: '37314',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57539',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1360',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4065',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4083',
      type: 'student',
      attributes: {
        fullName: 'Rita Maddi',
        preferredName: 'Rita',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1956-06-04T00:00:00.000Z',
        ltsNumber: '2013455',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37315',
              type: 'studentsSkill',
            },
            {
              id: '37316',
              type: 'studentsSkill',
            },
            {
              id: '37317',
              type: 'studentsSkill',
            },
            {
              id: '37318',
              type: 'studentsSkill',
            },
            {
              id: '37319',
              type: 'studentsSkill',
            },
            {
              id: '37320',
              type: 'studentsSkill',
            },
            {
              id: '37321',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1361',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4066',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4084',
      type: 'student',
      attributes: {
        fullName: 'Inaaya Sethi',
        preferredName: 'Inaaya',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2008-02-15T00:00:00.000Z',
        ltsNumber: '8826869',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37322',
              type: 'studentsSkill',
            },
            {
              id: '37323',
              type: 'studentsSkill',
            },
            {
              id: '37324',
              type: 'studentsSkill',
            },
            {
              id: '37325',
              type: 'studentsSkill',
            },
            {
              id: '37326',
              type: 'studentsSkill',
            },
            {
              id: '37327',
              type: 'studentsSkill',
            },
            {
              id: '37328',
              type: 'studentsSkill',
            },
            {
              id: '37329',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1362',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '11',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4067',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4085',
      type: 'student',
      attributes: {
        fullName: 'Charlie Beehm',
        preferredName: 'Charlie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2010-02-13T00:00:00.000Z',
        ltsNumber: '1278677',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37330',
              type: 'studentsSkill',
            },
            {
              id: '37331',
              type: 'studentsSkill',
            },
            {
              id: '37332',
              type: 'studentsSkill',
            },
            {
              id: '37333',
              type: 'studentsSkill',
            },
            {
              id: '37334',
              type: 'studentsSkill',
            },
            {
              id: '37335',
              type: 'studentsSkill',
            },
            {
              id: '37336',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1363',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4068',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4086',
      type: 'student',
      attributes: {
        fullName: 'Robert Beehm',
        preferredName: 'Robert',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1980-01-01T00:00:00.000Z',
        ltsNumber: '1278679',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37337',
              type: 'studentsSkill',
            },
            {
              id: '37338',
              type: 'studentsSkill',
            },
            {
              id: '37339',
              type: 'studentsSkill',
            },
            {
              id: '37340',
              type: 'studentsSkill',
            },
            {
              id: '37341',
              type: 'studentsSkill',
            },
            {
              id: '37342',
              type: 'studentsSkill',
            },
            {
              id: '37343',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1364',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4068',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4087',
      type: 'student',
      attributes: {
        fullName: 'Claudia Mead',
        preferredName: 'Claudia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2014-09-03T00:00:00.000Z',
        ltsNumber: '2004828',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41833',
              type: 'studentsSkill',
            },
            {
              id: '41834',
              type: 'studentsSkill',
            },
            {
              id: '41830',
              type: 'studentsSkill',
            },
            {
              id: '41835',
              type: 'studentsSkill',
            },
            {
              id: '41831',
              type: 'studentsSkill',
            },
            {
              id: '41836',
              type: 'studentsSkill',
            },
            {
              id: '41832',
              type: 'studentsSkill',
            },
            {
              id: '41829',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57153',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1365',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4069',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4088',
      type: 'student',
      attributes: {
        fullName: 'Ryan Mead',
        preferredName: 'Ryan',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1980-01-09T00:00:00.000Z',
        ltsNumber: '2004832',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37351',
              type: 'studentsSkill',
            },
            {
              id: '37352',
              type: 'studentsSkill',
            },
            {
              id: '37353',
              type: 'studentsSkill',
            },
            {
              id: '37354',
              type: 'studentsSkill',
            },
            {
              id: '37355',
              type: 'studentsSkill',
            },
            {
              id: '37356',
              type: 'studentsSkill',
            },
            {
              id: '37357',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1366',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4069',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4089',
      type: 'student',
      attributes: {
        fullName: 'Sahar Ahmed',
        preferredName: 'Sahar',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1997-09-23T00:00:00.000Z',
        ltsNumber: '2037674',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37358',
              type: 'studentsSkill',
            },
            {
              id: '37359',
              type: 'studentsSkill',
            },
            {
              id: '37360',
              type: 'studentsSkill',
            },
            {
              id: '37361',
              type: 'studentsSkill',
            },
            {
              id: '37362',
              type: 'studentsSkill',
            },
            {
              id: '37363',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1367',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '19',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4070',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4090',
      type: 'student',
      attributes: {
        fullName: 'Abigail Giblin',
        preferredName: 'Abigail',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2011-05-02T00:00:00.000Z',
        ltsNumber: '1877075',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37364',
              type: 'studentsSkill',
            },
            {
              id: '37365',
              type: 'studentsSkill',
            },
            {
              id: '37366',
              type: 'studentsSkill',
            },
            {
              id: '37367',
              type: 'studentsSkill',
            },
            {
              id: '37368',
              type: 'studentsSkill',
            },
            {
              id: '37369',
              type: 'studentsSkill',
            },
            {
              id: '37370',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1368',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4071',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4091',
      type: 'student',
      attributes: {
        fullName: 'Jason Giblin',
        preferredName: 'Jason',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1973-01-22T00:00:00.000Z',
        ltsNumber: '1688427',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37371',
              type: 'studentsSkill',
            },
            {
              id: '37372',
              type: 'studentsSkill',
            },
            {
              id: '37373',
              type: 'studentsSkill',
            },
            {
              id: '37374',
              type: 'studentsSkill',
            },
            {
              id: '37375',
              type: 'studentsSkill',
            },
            {
              id: '37376',
              type: 'studentsSkill',
            },
            {
              id: '37377',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1369',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4071',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4092',
      type: 'student',
      attributes: {
        fullName: 'Molly Giblin',
        preferredName: 'Molly',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2009-09-02T00:00:00.000Z',
        ltsNumber: '1649029',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37378',
              type: 'studentsSkill',
            },
            {
              id: '37379',
              type: 'studentsSkill',
            },
            {
              id: '37380',
              type: 'studentsSkill',
            },
            {
              id: '37381',
              type: 'studentsSkill',
            },
            {
              id: '37382',
              type: 'studentsSkill',
            },
            {
              id: '37383',
              type: 'studentsSkill',
            },
            {
              id: '37384',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1370',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4071',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4093',
      type: 'student',
      attributes: {
        fullName: 'Abel Papyan',
        preferredName: 'Abel',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2014-11-28T00:00:00.000Z',
        ltsNumber: '2004806',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37385',
              type: 'studentsSkill',
            },
            {
              id: '37386',
              type: 'studentsSkill',
            },
            {
              id: '37387',
              type: 'studentsSkill',
            },
            {
              id: '37388',
              type: 'studentsSkill',
            },
            {
              id: '37389',
              type: 'studentsSkill',
            },
            {
              id: '37390',
              type: 'studentsSkill',
            },
            {
              id: '37391',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1371',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '9',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4072',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4095',
      type: 'student',
      attributes: {
        fullName: 'Carley Gabriel',
        preferredName: 'Carley',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2007-11-15T00:00:00.000Z',
        ltsNumber: '1701274',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37401',
              type: 'studentsSkill',
            },
            {
              id: '37403',
              type: 'studentsSkill',
            },
            {
              id: '37405',
              type: 'studentsSkill',
            },
            {
              id: '37402',
              type: 'studentsSkill',
            },
            {
              id: '37404',
              type: 'studentsSkill',
            },
            {
              id: '37406',
              type: 'studentsSkill',
            },
            {
              id: '37400',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57536',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1373',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '5',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4074',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4096',
      type: 'student',
      attributes: {
        fullName: 'Andrea Segalini',
        preferredName: 'Andrea',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2012-09-03T00:00:00.000Z',
        ltsNumber: '1861944',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37407',
              type: 'studentsSkill',
            },
            {
              id: '37408',
              type: 'studentsSkill',
            },
            {
              id: '37409',
              type: 'studentsSkill',
            },
            {
              id: '37410',
              type: 'studentsSkill',
            },
            {
              id: '37411',
              type: 'studentsSkill',
            },
            {
              id: '37412',
              type: 'studentsSkill',
            },
            {
              id: '37413',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1374',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4075',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4097',
      type: 'student',
      attributes: {
        fullName: 'Elisa Segalini',
        preferredName: 'Elisa',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2010-04-29T00:00:00.000Z',
        ltsNumber: '1861943',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37414',
              type: 'studentsSkill',
            },
            {
              id: '37415',
              type: 'studentsSkill',
            },
            {
              id: '37416',
              type: 'studentsSkill',
            },
            {
              id: '37417',
              type: 'studentsSkill',
            },
            {
              id: '37418',
              type: 'studentsSkill',
            },
            {
              id: '37419',
              type: 'studentsSkill',
            },
            {
              id: '37420',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1375',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4075',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4098',
      type: 'student',
      attributes: {
        fullName: 'Sarah Sterns',
        preferredName: 'Sarah',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1982-11-05T00:00:00.000Z',
        ltsNumber: '1195671',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37421',
              type: 'studentsSkill',
            },
            {
              id: '37422',
              type: 'studentsSkill',
            },
            {
              id: '37423',
              type: 'studentsSkill',
            },
            {
              id: '37424',
              type: 'studentsSkill',
            },
            {
              id: '37425',
              type: 'studentsSkill',
            },
            {
              id: '37426',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1376',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '19',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4076',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4099',
      type: 'student',
      attributes: {
        fullName: 'Lenaura Starr',
        preferredName: 'Lenaura',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2011-06-21T00:00:00.000Z',
        ltsNumber: '1677823',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37427',
              type: 'studentsSkill',
            },
            {
              id: '37430',
              type: 'studentsSkill',
            },
            {
              id: '37432',
              type: 'studentsSkill',
            },
            {
              id: '37428',
              type: 'studentsSkill',
            },
            {
              id: '37429',
              type: 'studentsSkill',
            },
            {
              id: '37433',
              type: 'studentsSkill',
            },
            {
              id: '37431',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57573',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1377',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '5',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4077',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4100',
      type: 'student',
      attributes: {
        fullName: 'Skylar French',
        preferredName: 'Skylar',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2009-09-12T00:00:00.000Z',
        ltsNumber: '1971743',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37434',
              type: 'studentsSkill',
            },
            {
              id: '37435',
              type: 'studentsSkill',
            },
            {
              id: '37436',
              type: 'studentsSkill',
            },
            {
              id: '37437',
              type: 'studentsSkill',
            },
            {
              id: '37438',
              type: 'studentsSkill',
            },
            {
              id: '37439',
              type: 'studentsSkill',
            },
            {
              id: '37440',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1378',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4078',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4101',
      type: 'student',
      attributes: {
        fullName: 'Ty French',
        preferredName: 'Ty',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2006-04-19T00:00:00.000Z',
        ltsNumber: '1971741',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37441',
              type: 'studentsSkill',
            },
            {
              id: '37442',
              type: 'studentsSkill',
            },
            {
              id: '37443',
              type: 'studentsSkill',
            },
            {
              id: '37444',
              type: 'studentsSkill',
            },
            {
              id: '37445',
              type: 'studentsSkill',
            },
            {
              id: '37446',
              type: 'studentsSkill',
            },
            {
              id: '37447',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1379',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4078',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4102',
      type: 'student',
      attributes: {
        fullName: 'Shannon Clemons',
        preferredName: 'Shannon',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1987-05-23T00:00:00.000Z',
        ltsNumber: '1998787',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37448',
              type: 'studentsSkill',
            },
            {
              id: '37449',
              type: 'studentsSkill',
            },
            {
              id: '37450',
              type: 'studentsSkill',
            },
            {
              id: '37451',
              type: 'studentsSkill',
            },
            {
              id: '37452',
              type: 'studentsSkill',
            },
            {
              id: '37453',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1380',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '20',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4079',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4103',
      type: 'student',
      attributes: {
        fullName: 'Audrey Mileo',
        preferredName: 'Audrey',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2009-09-06T00:00:00.000Z',
        ltsNumber: '1645024',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37454',
              type: 'studentsSkill',
            },
            {
              id: '37455',
              type: 'studentsSkill',
            },
            {
              id: '37456',
              type: 'studentsSkill',
            },
            {
              id: '37457',
              type: 'studentsSkill',
            },
            {
              id: '37458',
              type: 'studentsSkill',
            },
            {
              id: '37459',
              type: 'studentsSkill',
            },
            {
              id: '37460',
              type: 'studentsSkill',
            },
            {
              id: '37461',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1381',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '11',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4080',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4104',
      type: 'student',
      attributes: {
        fullName: 'Meng Wang',
        preferredName: 'Meng',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1983-06-05T00:00:00.000Z',
        ltsNumber: '2000467',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37462',
              type: 'studentsSkill',
            },
            {
              id: '37463',
              type: 'studentsSkill',
            },
            {
              id: '37464',
              type: 'studentsSkill',
            },
            {
              id: '37465',
              type: 'studentsSkill',
            },
            {
              id: '37466',
              type: 'studentsSkill',
            },
            {
              id: '37467',
              type: 'studentsSkill',
            },
            {
              id: '37468',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1382',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4081',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4105',
      type: 'student',
      attributes: {
        fullName: 'Olivia Zhang',
        preferredName: 'Olivia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2015-08-23T00:00:00.000Z',
        ltsNumber: '1970315',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37469',
              type: 'studentsSkill',
            },
            {
              id: '37470',
              type: 'studentsSkill',
            },
            {
              id: '37471',
              type: 'studentsSkill',
            },
            {
              id: '37472',
              type: 'studentsSkill',
            },
            {
              id: '37473',
              type: 'studentsSkill',
            },
            {
              id: '37474',
              type: 'studentsSkill',
            },
            {
              id: '37475',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1383',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '9',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4081',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4123',
      type: 'student',
      attributes: {
        fullName: 'Trey Parker',
        preferredName: 'Trey',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2009-10-23T00:00:00.000Z',
        ltsNumber: '2024371',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37476',
              type: 'studentsSkill',
            },
            {
              id: '37477',
              type: 'studentsSkill',
            },
            {
              id: '37478',
              type: 'studentsSkill',
            },
            {
              id: '37479',
              type: 'studentsSkill',
            },
            {
              id: '37480',
              type: 'studentsSkill',
            },
            {
              id: '37481',
              type: 'studentsSkill',
            },
            {
              id: '37482',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1384',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4106',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4124',
      type: 'student',
      attributes: {
        fullName: 'Rishi Dang',
        preferredName: 'Rishi',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2015-05-25T00:00:00.000Z',
        ltsNumber: '1969952',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37483',
              type: 'studentsSkill',
            },
            {
              id: '37484',
              type: 'studentsSkill',
            },
            {
              id: '37485',
              type: 'studentsSkill',
            },
            {
              id: '37486',
              type: 'studentsSkill',
            },
            {
              id: '37487',
              type: 'studentsSkill',
            },
            {
              id: '37488',
              type: 'studentsSkill',
            },
            {
              id: '37489',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1385',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4107',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4125',
      type: 'student',
      attributes: {
        fullName: 'Utkarsh Dang',
        preferredName: 'Utkarsh',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1985-12-03T00:00:00.000Z',
        ltsNumber: '1971764',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37490',
              type: 'studentsSkill',
            },
            {
              id: '37491',
              type: 'studentsSkill',
            },
            {
              id: '37492',
              type: 'studentsSkill',
            },
            {
              id: '37493',
              type: 'studentsSkill',
            },
            {
              id: '37494',
              type: 'studentsSkill',
            },
            {
              id: '37495',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1386',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '19',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4107',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4126',
      type: 'student',
      attributes: {
        fullName: 'Cihan Uzmanoglu',
        preferredName: 'Cihan',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1982-04-28T04:00:00.000Z',
        ltsNumber: '1970313',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37496',
              type: 'studentsSkill',
            },
            {
              id: '37497',
              type: 'studentsSkill',
            },
            {
              id: '37498',
              type: 'studentsSkill',
            },
            {
              id: '37499',
              type: 'studentsSkill',
            },
            {
              id: '37500',
              type: 'studentsSkill',
            },
            {
              id: '37501',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1387',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '19',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4108',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4127',
      type: 'student',
      attributes: {
        fullName: 'Ipek Uzmanoglu',
        preferredName: 'Ipek',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2014-12-19T05:00:00.000Z',
        ltsNumber: '1970312',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42057',
              type: 'studentsSkill',
            },
            {
              id: '42058',
              type: 'studentsSkill',
            },
            {
              id: '42063',
              type: 'studentsSkill',
            },
            {
              id: '42061',
              type: 'studentsSkill',
            },
            {
              id: '42059',
              type: 'studentsSkill',
            },
            {
              id: '42060',
              type: 'studentsSkill',
            },
            {
              id: '42062',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57445',
              type: 'klassDaysStudent',
            },
            {
              id: '57446',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1387',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4108',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4128',
      type: 'student',
      attributes: {
        fullName: 'Melike Uzmanoglu',
        preferredName: 'Melike',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1989-01-27T05:00:00.000Z',
        ltsNumber: '2122176',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42325',
              type: 'studentsSkill',
            },
            {
              id: '42326',
              type: 'studentsSkill',
            },
            {
              id: '42327',
              type: 'studentsSkill',
            },
            {
              id: '42328',
              type: 'studentsSkill',
            },
            {
              id: '42329',
              type: 'studentsSkill',
            },
            {
              id: '42330',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57574',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1387',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '19',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4108',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4129',
      type: 'student',
      attributes: {
        fullName: 'Lillianna Masucci',
        preferredName: 'Lillianna',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2011-06-30T00:00:00.000Z',
        ltsNumber: '2025744',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37517',
              type: 'studentsSkill',
            },
            {
              id: '37518',
              type: 'studentsSkill',
            },
            {
              id: '37519',
              type: 'studentsSkill',
            },
            {
              id: '37520',
              type: 'studentsSkill',
            },
            {
              id: '37521',
              type: 'studentsSkill',
            },
            {
              id: '37522',
              type: 'studentsSkill',
            },
            {
              id: '37523',
              type: 'studentsSkill',
            },
            {
              id: '37524',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1390',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4109',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4130',
      type: 'student',
      attributes: {
        fullName: 'Kalen Smith',
        preferredName: 'Kalen',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2008-01-10T00:00:00.000Z',
        ltsNumber: '1919986',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37525',
              type: 'studentsSkill',
            },
            {
              id: '37526',
              type: 'studentsSkill',
            },
            {
              id: '37527',
              type: 'studentsSkill',
            },
            {
              id: '37528',
              type: 'studentsSkill',
            },
            {
              id: '37529',
              type: 'studentsSkill',
            },
            {
              id: '37530',
              type: 'studentsSkill',
            },
            {
              id: '37531',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1391',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4110',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4132',
      type: 'student',
      attributes: {
        fullName: 'Katherine Lu',
        preferredName: 'Katherine',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-07-13T00:00:00.000Z',
        ltsNumber: '1970302',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37539',
              type: 'studentsSkill',
            },
            {
              id: '37540',
              type: 'studentsSkill',
            },
            {
              id: '37541',
              type: 'studentsSkill',
            },
            {
              id: '37542',
              type: 'studentsSkill',
            },
            {
              id: '37543',
              type: 'studentsSkill',
            },
            {
              id: '37544',
              type: 'studentsSkill',
            },
            {
              id: '37545',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1393',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4112',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4133',
      type: 'student',
      attributes: {
        fullName: 'Zhibo Wang',
        preferredName: 'Zhibo',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1978-11-12T00:00:00.000Z',
        ltsNumber: '1987426',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37546',
              type: 'studentsSkill',
            },
            {
              id: '37547',
              type: 'studentsSkill',
            },
            {
              id: '37548',
              type: 'studentsSkill',
            },
            {
              id: '37549',
              type: 'studentsSkill',
            },
            {
              id: '37550',
              type: 'studentsSkill',
            },
            {
              id: '37551',
              type: 'studentsSkill',
            },
            {
              id: '37552',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1394',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4112',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4134',
      type: 'student',
      attributes: {
        fullName: 'Shunwen Wu',
        preferredName: 'Shunwen',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1980-01-01T00:00:00.000Z',
        ltsNumber: '1775212',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37553',
              type: 'studentsSkill',
            },
            {
              id: '37554',
              type: 'studentsSkill',
            },
            {
              id: '37555',
              type: 'studentsSkill',
            },
            {
              id: '37556',
              type: 'studentsSkill',
            },
            {
              id: '37557',
              type: 'studentsSkill',
            },
            {
              id: '37558',
              type: 'studentsSkill',
            },
            {
              id: '37559',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1395',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4113',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4136',
      type: 'student',
      attributes: {
        fullName: 'Brielle Zhang',
        preferredName: 'Brielle',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2015-04-14T00:00:00.000Z',
        ltsNumber: '1971587',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37567',
              type: 'studentsSkill',
            },
            {
              id: '37568',
              type: 'studentsSkill',
            },
            {
              id: '37569',
              type: 'studentsSkill',
            },
            {
              id: '37570',
              type: 'studentsSkill',
            },
            {
              id: '37571',
              type: 'studentsSkill',
            },
            {
              id: '37572',
              type: 'studentsSkill',
            },
            {
              id: '37573',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1397',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '9',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4113',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4137',
      type: 'student',
      attributes: {
        fullName: 'Gavin Kretzmer',
        preferredName: 'Gavin',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2008-09-05T00:00:00.000Z',
        ltsNumber: '1113367',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37574',
              type: 'studentsSkill',
            },
            {
              id: '37575',
              type: 'studentsSkill',
            },
            {
              id: '37576',
              type: 'studentsSkill',
            },
            {
              id: '37577',
              type: 'studentsSkill',
            },
            {
              id: '37578',
              type: 'studentsSkill',
            },
            {
              id: '37579',
              type: 'studentsSkill',
            },
            {
              id: '37580',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1398',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4114',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4138',
      type: 'student',
      attributes: {
        fullName: 'Ziqian Wang',
        preferredName: 'Ziqian',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2012-01-12T00:00:00.000Z',
        ltsNumber: '1970314',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37581',
              type: 'studentsSkill',
            },
            {
              id: '37582',
              type: 'studentsSkill',
            },
            {
              id: '37583',
              type: 'studentsSkill',
            },
            {
              id: '37584',
              type: 'studentsSkill',
            },
            {
              id: '37585',
              type: 'studentsSkill',
            },
            {
              id: '37586',
              type: 'studentsSkill',
            },
            {
              id: '37587',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1399',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4115',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4139',
      type: 'student',
      attributes: {
        fullName: 'Renee Chen',
        preferredName: 'Renee',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-04-27T00:00:00.000Z',
        ltsNumber: '1969948',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37588',
              type: 'studentsSkill',
            },
            {
              id: '37589',
              type: 'studentsSkill',
            },
            {
              id: '37590',
              type: 'studentsSkill',
            },
            {
              id: '37591',
              type: 'studentsSkill',
            },
            {
              id: '37592',
              type: 'studentsSkill',
            },
            {
              id: '37593',
              type: 'studentsSkill',
            },
            {
              id: '37594',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1400',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4116',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4140',
      type: 'student',
      attributes: {
        fullName: 'Wenxim Jia',
        preferredName: 'Wenxim',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2009-08-09T00:00:00.000Z',
        ltsNumber: '2029586',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37595',
              type: 'studentsSkill',
            },
            {
              id: '37596',
              type: 'studentsSkill',
            },
            {
              id: '37597',
              type: 'studentsSkill',
            },
            {
              id: '37598',
              type: 'studentsSkill',
            },
            {
              id: '37599',
              type: 'studentsSkill',
            },
            {
              id: '37600',
              type: 'studentsSkill',
            },
            {
              id: '37601',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1401',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4117',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4141',
      type: 'student',
      attributes: {
        fullName: 'Daniel Li',
        preferredName: 'Daniel',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2008-08-25T00:00:00.000Z',
        ltsNumber: '1971773',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37602',
              type: 'studentsSkill',
            },
            {
              id: '37603',
              type: 'studentsSkill',
            },
            {
              id: '37604',
              type: 'studentsSkill',
            },
            {
              id: '37605',
              type: 'studentsSkill',
            },
            {
              id: '37606',
              type: 'studentsSkill',
            },
            {
              id: '37607',
              type: 'studentsSkill',
            },
            {
              id: '37608',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1402',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4118',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4142',
      type: 'student',
      attributes: {
        fullName: 'Shiloh Li',
        preferredName: 'Shiloh',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-01-31T00:00:00.000Z',
        ltsNumber: '1971774',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37609',
              type: 'studentsSkill',
            },
            {
              id: '37610',
              type: 'studentsSkill',
            },
            {
              id: '37611',
              type: 'studentsSkill',
            },
            {
              id: '37612',
              type: 'studentsSkill',
            },
            {
              id: '37613',
              type: 'studentsSkill',
            },
            {
              id: '37614',
              type: 'studentsSkill',
            },
            {
              id: '37615',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1403',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4118',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4143',
      type: 'student',
      attributes: {
        fullName: 'Amelia Chiu',
        preferredName: 'Amelia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2007-10-14T00:00:00.000Z',
        ltsNumber: '8878617',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37616',
              type: 'studentsSkill',
            },
            {
              id: '37617',
              type: 'studentsSkill',
            },
            {
              id: '37618',
              type: 'studentsSkill',
            },
            {
              id: '37619',
              type: 'studentsSkill',
            },
            {
              id: '37620',
              type: 'studentsSkill',
            },
            {
              id: '37621',
              type: 'studentsSkill',
            },
            {
              id: '37622',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1404',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '5',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4119',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4145',
      type: 'student',
      attributes: {
        fullName: 'Sophia Makosiy',
        preferredName: 'Sophia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-05-23T00:00:00.000Z',
        ltsNumber: '1861937',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37633',
              type: 'studentsSkill',
            },
            {
              id: '37635',
              type: 'studentsSkill',
            },
            {
              id: '37637',
              type: 'studentsSkill',
            },
            {
              id: '37631',
              type: 'studentsSkill',
            },
            {
              id: '37632',
              type: 'studentsSkill',
            },
            {
              id: '37634',
              type: 'studentsSkill',
            },
            {
              id: '37636',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57484',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1406',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4121',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4146',
      type: 'student',
      attributes: {
        fullName: 'Jade Behlog',
        preferredName: 'Jade',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2008-11-20T00:00:00.000Z',
        ltsNumber: '1274159',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38188',
              type: 'studentsSkill',
            },
            {
              id: '38189',
              type: 'studentsSkill',
            },
            {
              id: '38190',
              type: 'studentsSkill',
            },
            {
              id: '38191',
              type: 'studentsSkill',
            },
            {
              id: '38192',
              type: 'studentsSkill',
            },
            {
              id: '38193',
              type: 'studentsSkill',
            },
            {
              id: '38194',
              type: 'studentsSkill',
            },
            {
              id: '38195',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1407',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '11',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4122',
              type: 'user',
            },
            {
              id: '4243',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4162',
      type: 'student',
      attributes: {
        fullName: 'Cecilia Walsh',
        preferredName: 'Cecilia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2010-05-04T00:00:00.000Z',
        ltsNumber: '1250247',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37644',
              type: 'studentsSkill',
            },
            {
              id: '37645',
              type: 'studentsSkill',
            },
            {
              id: '37646',
              type: 'studentsSkill',
            },
            {
              id: '37647',
              type: 'studentsSkill',
            },
            {
              id: '37648',
              type: 'studentsSkill',
            },
            {
              id: '37649',
              type: 'studentsSkill',
            },
            {
              id: '37650',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1408',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '5',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3929',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4163',
      type: 'student',
      attributes: {
        fullName: 'Ava Starley',
        preferredName: 'Ava',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2010-11-18T00:00:00.000Z',
        ltsNumber: '2029590',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37651',
              type: 'studentsSkill',
            },
            {
              id: '37652',
              type: 'studentsSkill',
            },
            {
              id: '37653',
              type: 'studentsSkill',
            },
            {
              id: '37654',
              type: 'studentsSkill',
            },
            {
              id: '37655',
              type: 'studentsSkill',
            },
            {
              id: '37656',
              type: 'studentsSkill',
            },
            {
              id: '37657',
              type: 'studentsSkill',
            },
            {
              id: '37658',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1409',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4147',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4164',
      type: 'student',
      attributes: {
        fullName: 'Barbara Zalepa',
        preferredName: 'Barbara',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1953-08-24T00:00:00.000Z',
        ltsNumber: '2001765',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37659',
              type: 'studentsSkill',
            },
            {
              id: '37660',
              type: 'studentsSkill',
            },
            {
              id: '37661',
              type: 'studentsSkill',
            },
            {
              id: '37662',
              type: 'studentsSkill',
            },
            {
              id: '37663',
              type: 'studentsSkill',
            },
            {
              id: '37664',
              type: 'studentsSkill',
            },
            {
              id: '37665',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1410',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4148',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4165',
      type: 'student',
      attributes: {
        fullName: 'Cheng Cheng',
        preferredName: 'Cheng',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2015-09-18T00:00:00.000Z',
        ltsNumber: '1983467',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37666',
              type: 'studentsSkill',
            },
            {
              id: '37667',
              type: 'studentsSkill',
            },
            {
              id: '37668',
              type: 'studentsSkill',
            },
            {
              id: '37669',
              type: 'studentsSkill',
            },
            {
              id: '37670',
              type: 'studentsSkill',
            },
            {
              id: '37671',
              type: 'studentsSkill',
            },
            {
              id: '37672',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1411',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '9',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4149',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4167',
      type: 'student',
      attributes: {
        fullName: 'John Rusnak',
        preferredName: 'John',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1951-11-26T00:00:00.000Z',
        ltsNumber: '2029573',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37680',
              type: 'studentsSkill',
            },
            {
              id: '37681',
              type: 'studentsSkill',
            },
            {
              id: '37682',
              type: 'studentsSkill',
            },
            {
              id: '37683',
              type: 'studentsSkill',
            },
            {
              id: '37684',
              type: 'studentsSkill',
            },
            {
              id: '37685',
              type: 'studentsSkill',
            },
            {
              id: '37686',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1413',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4150',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4168',
      type: 'student',
      attributes: {
        fullName: 'Ava Wilcox',
        preferredName: 'Ava',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-05-02T00:00:00.000Z',
        ltsNumber: '2024369',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37687',
              type: 'studentsSkill',
            },
            {
              id: '37688',
              type: 'studentsSkill',
            },
            {
              id: '37689',
              type: 'studentsSkill',
            },
            {
              id: '37690',
              type: 'studentsSkill',
            },
            {
              id: '37691',
              type: 'studentsSkill',
            },
            {
              id: '37692',
              type: 'studentsSkill',
            },
            {
              id: '37693',
              type: 'studentsSkill',
            },
            {
              id: '37694',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1414',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4151',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4170',
      type: 'student',
      attributes: {
        fullName: 'Benny Sica',
        preferredName: 'Benny',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2012-03-28T00:00:00.000Z',
        ltsNumber: '2038514',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37703',
              type: 'studentsSkill',
            },
            {
              id: '37704',
              type: 'studentsSkill',
            },
            {
              id: '37705',
              type: 'studentsSkill',
            },
            {
              id: '37706',
              type: 'studentsSkill',
            },
            {
              id: '37707',
              type: 'studentsSkill',
            },
            {
              id: '37708',
              type: 'studentsSkill',
            },
            {
              id: '37709',
              type: 'studentsSkill',
            },
            {
              id: '37710',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1416',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4152',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4171',
      type: 'student',
      attributes: {
        fullName: 'Bill Sica',
        preferredName: 'Bill',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1976-04-14T00:00:00.000Z',
        ltsNumber: '2006890',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37711',
              type: 'studentsSkill',
            },
            {
              id: '37712',
              type: 'studentsSkill',
            },
            {
              id: '37713',
              type: 'studentsSkill',
            },
            {
              id: '37714',
              type: 'studentsSkill',
            },
            {
              id: '37715',
              type: 'studentsSkill',
            },
            {
              id: '37716',
              type: 'studentsSkill',
            },
            {
              id: '37717',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1417',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4152',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4172',
      type: 'student',
      attributes: {
        fullName: 'Joey Sica',
        preferredName: 'Joey',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2010-05-05T00:00:00.000Z',
        ltsNumber: '2006886',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37718',
              type: 'studentsSkill',
            },
            {
              id: '37719',
              type: 'studentsSkill',
            },
            {
              id: '37720',
              type: 'studentsSkill',
            },
            {
              id: '37721',
              type: 'studentsSkill',
            },
            {
              id: '37722',
              type: 'studentsSkill',
            },
            {
              id: '37723',
              type: 'studentsSkill',
            },
            {
              id: '37724',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1418',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4152',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4173',
      type: 'student',
      attributes: {
        fullName: 'Aamina Davis',
        preferredName: 'Aamina',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2009-02-27T00:00:00.000Z',
        ltsNumber: '1970317',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37725',
              type: 'studentsSkill',
            },
            {
              id: '37726',
              type: 'studentsSkill',
            },
            {
              id: '37727',
              type: 'studentsSkill',
            },
            {
              id: '37728',
              type: 'studentsSkill',
            },
            {
              id: '37729',
              type: 'studentsSkill',
            },
            {
              id: '37730',
              type: 'studentsSkill',
            },
            {
              id: '37731',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1419',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4153',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4174',
      type: 'student',
      attributes: {
        fullName: 'Ruhani Hatch',
        preferredName: 'Ruhani',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-10-30T00:00:00.000Z',
        ltsNumber: '1970318',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37732',
              type: 'studentsSkill',
            },
            {
              id: '37733',
              type: 'studentsSkill',
            },
            {
              id: '37734',
              type: 'studentsSkill',
            },
            {
              id: '37735',
              type: 'studentsSkill',
            },
            {
              id: '37736',
              type: 'studentsSkill',
            },
            {
              id: '37737',
              type: 'studentsSkill',
            },
            {
              id: '37738',
              type: 'studentsSkill',
            },
            {
              id: '37739',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1420',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4153',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4175',
      type: 'student',
      attributes: {
        fullName: 'Borys Mayba',
        preferredName: 'Borys',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1983-06-13T00:00:00.000Z',
        ltsNumber: '2015083',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37740',
              type: 'studentsSkill',
            },
            {
              id: '37741',
              type: 'studentsSkill',
            },
            {
              id: '37742',
              type: 'studentsSkill',
            },
            {
              id: '37743',
              type: 'studentsSkill',
            },
            {
              id: '37744',
              type: 'studentsSkill',
            },
            {
              id: '37745',
              type: 'studentsSkill',
            },
            {
              id: '37746',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1421',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4154',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4176',
      type: 'student',
      attributes: {
        fullName: 'Emily Mayba',
        preferredName: 'Emily',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-09-15T00:00:00.000Z',
        ltsNumber: '2000478',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37747',
              type: 'studentsSkill',
            },
            {
              id: '37748',
              type: 'studentsSkill',
            },
            {
              id: '37749',
              type: 'studentsSkill',
            },
            {
              id: '37750',
              type: 'studentsSkill',
            },
            {
              id: '37751',
              type: 'studentsSkill',
            },
            {
              id: '37752',
              type: 'studentsSkill',
            },
            {
              id: '37753',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1422',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4154',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4177',
      type: 'student',
      attributes: {
        fullName: 'Ana Grosso',
        preferredName: 'Ana',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2014-05-12T00:00:00.000Z',
        ltsNumber: '1989034',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41854',
              type: 'studentsSkill',
            },
            {
              id: '41855',
              type: 'studentsSkill',
            },
            {
              id: '41856',
              type: 'studentsSkill',
            },
            {
              id: '41857',
              type: 'studentsSkill',
            },
            {
              id: '41852',
              type: 'studentsSkill',
            },
            {
              id: '41853',
              type: 'studentsSkill',
            },
            {
              id: '41858',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57157',
              type: 'klassDaysStudent',
            },
            {
              id: '57158',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1423',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4155',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4178',
      type: 'student',
      attributes: {
        fullName: 'Nathaniel Rice',
        preferredName: 'Nathaniel',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2006-07-04T00:00:00.000Z',
        ltsNumber: '1900535',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37761',
              type: 'studentsSkill',
            },
            {
              id: '37762',
              type: 'studentsSkill',
            },
            {
              id: '37763',
              type: 'studentsSkill',
            },
            {
              id: '37764',
              type: 'studentsSkill',
            },
            {
              id: '37765',
              type: 'studentsSkill',
            },
            {
              id: '37766',
              type: 'studentsSkill',
            },
            {
              id: '37767',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1424',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4156',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4179',
      type: 'student',
      attributes: {
        fullName: 'Ellie Friedman',
        preferredName: 'Ellie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2006-09-06T00:00:00.000Z',
        ltsNumber: '1922734',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37768',
              type: 'studentsSkill',
            },
            {
              id: '37769',
              type: 'studentsSkill',
            },
            {
              id: '37770',
              type: 'studentsSkill',
            },
            {
              id: '37771',
              type: 'studentsSkill',
            },
            {
              id: '37772',
              type: 'studentsSkill',
            },
            {
              id: '37773',
              type: 'studentsSkill',
            },
            {
              id: '37774',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1425',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4157',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4180',
      type: 'student',
      attributes: {
        fullName: 'Mika Friedman',
        preferredName: 'Mika',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2008-12-19T00:00:00.000Z',
        ltsNumber: '1922735',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37775',
              type: 'studentsSkill',
            },
            {
              id: '37776',
              type: 'studentsSkill',
            },
            {
              id: '37777',
              type: 'studentsSkill',
            },
            {
              id: '37778',
              type: 'studentsSkill',
            },
            {
              id: '37779',
              type: 'studentsSkill',
            },
            {
              id: '37780',
              type: 'studentsSkill',
            },
            {
              id: '37781',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1426',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4157',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4181',
      type: 'student',
      attributes: {
        fullName: 'Zohar Friedman',
        preferredName: 'Zohar',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-01-21T00:00:00.000Z',
        ltsNumber: '1922736',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37782',
              type: 'studentsSkill',
            },
            {
              id: '37783',
              type: 'studentsSkill',
            },
            {
              id: '37784',
              type: 'studentsSkill',
            },
            {
              id: '37785',
              type: 'studentsSkill',
            },
            {
              id: '37786',
              type: 'studentsSkill',
            },
            {
              id: '37787',
              type: 'studentsSkill',
            },
            {
              id: '37788',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1427',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4157',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4182',
      type: 'student',
      attributes: {
        fullName: 'Brenda Schlaen',
        preferredName: 'Brenda',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1974-08-28T00:00:00.000Z',
        ltsNumber: '2022379',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37789',
              type: 'studentsSkill',
            },
            {
              id: '37790',
              type: 'studentsSkill',
            },
            {
              id: '37791',
              type: 'studentsSkill',
            },
            {
              id: '37792',
              type: 'studentsSkill',
            },
            {
              id: '37793',
              type: 'studentsSkill',
            },
            {
              id: '37794',
              type: 'studentsSkill',
            },
            {
              id: '37795',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1428',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4157',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4183',
      type: 'student',
      attributes: {
        fullName: 'Zachary Lipo',
        preferredName: 'Zachary',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2010-07-11T00:00:00.000Z',
        ltsNumber: '1913689',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37796',
              type: 'studentsSkill',
            },
            {
              id: '37797',
              type: 'studentsSkill',
            },
            {
              id: '37798',
              type: 'studentsSkill',
            },
            {
              id: '37799',
              type: 'studentsSkill',
            },
            {
              id: '37800',
              type: 'studentsSkill',
            },
            {
              id: '37801',
              type: 'studentsSkill',
            },
            {
              id: '37802',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1429',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4158',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4185',
      type: 'student',
      attributes: {
        fullName: 'Caleb Aleba',
        preferredName: 'Caleb',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2006-08-04T00:00:00.000Z',
        ltsNumber: '1861918',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37810',
              type: 'studentsSkill',
            },
            {
              id: '37811',
              type: 'studentsSkill',
            },
            {
              id: '37812',
              type: 'studentsSkill',
            },
            {
              id: '37813',
              type: 'studentsSkill',
            },
            {
              id: '37814',
              type: 'studentsSkill',
            },
            {
              id: '37815',
              type: 'studentsSkill',
            },
            {
              id: '37816',
              type: 'studentsSkill',
            },
            {
              id: '37817',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1431',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4160',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4186',
      type: 'student',
      attributes: {
        fullName: 'Andrew Reid',
        preferredName: 'Andrew',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1981-08-25T00:00:00.000Z',
        ltsNumber: '2004849',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37818',
              type: 'studentsSkill',
            },
            {
              id: '37819',
              type: 'studentsSkill',
            },
            {
              id: '37820',
              type: 'studentsSkill',
            },
            {
              id: '37821',
              type: 'studentsSkill',
            },
            {
              id: '37822',
              type: 'studentsSkill',
            },
            {
              id: '37823',
              type: 'studentsSkill',
            },
            {
              id: '37824',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1432',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4161',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4187',
      type: 'student',
      attributes: {
        fullName: 'Mackenzie Reid',
        preferredName: 'Mackenzie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2015-06-07T00:00:00.000Z',
        ltsNumber: '2004841',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37825',
              type: 'studentsSkill',
            },
            {
              id: '37826',
              type: 'studentsSkill',
            },
            {
              id: '37827',
              type: 'studentsSkill',
            },
            {
              id: '37828',
              type: 'studentsSkill',
            },
            {
              id: '37829',
              type: 'studentsSkill',
            },
            {
              id: '37830',
              type: 'studentsSkill',
            },
            {
              id: '37831',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1433',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4161',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4202',
      type: 'student',
      attributes: {
        fullName: 'Juliana Adams',
        preferredName: 'Juliana',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2009-07-04T00:00:00.000Z',
        ltsNumber: '1658247',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37832',
              type: 'studentsSkill',
            },
            {
              id: '37833',
              type: 'studentsSkill',
            },
            {
              id: '37834',
              type: 'studentsSkill',
            },
            {
              id: '37835',
              type: 'studentsSkill',
            },
            {
              id: '37836',
              type: 'studentsSkill',
            },
            {
              id: '37837',
              type: 'studentsSkill',
            },
            {
              id: '37838',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1434',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3967',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4203',
      type: 'student',
      attributes: {
        fullName: 'Layla Kalman',
        preferredName: 'Layla',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-12-03T00:00:00.000Z',
        ltsNumber: '1861932',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37839',
              type: 'studentsSkill',
            },
            {
              id: '37840',
              type: 'studentsSkill',
            },
            {
              id: '37841',
              type: 'studentsSkill',
            },
            {
              id: '37842',
              type: 'studentsSkill',
            },
            {
              id: '37843',
              type: 'studentsSkill',
            },
            {
              id: '37844',
              type: 'studentsSkill',
            },
            {
              id: '37845',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1435',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4188',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4204',
      type: 'student',
      attributes: {
        fullName: 'Donna Marie Parker',
        preferredName: 'Donna Marie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1965-05-06T00:00:00.000Z',
        ltsNumber: '2017635',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37846',
              type: 'studentsSkill',
            },
            {
              id: '37847',
              type: 'studentsSkill',
            },
            {
              id: '37848',
              type: 'studentsSkill',
            },
            {
              id: '37849',
              type: 'studentsSkill',
            },
            {
              id: '37850',
              type: 'studentsSkill',
            },
            {
              id: '37851',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1436',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '19',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4189',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4205',
      type: 'student',
      attributes: {
        fullName: 'Austin Huang',
        preferredName: 'Austin',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2014-06-20T04:00:00.000Z',
        ltsNumber: '1997814',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '40722',
              type: 'studentsSkill',
            },
            {
              id: '40723',
              type: 'studentsSkill',
            },
            {
              id: '40724',
              type: 'studentsSkill',
            },
            {
              id: '40725',
              type: 'studentsSkill',
            },
            {
              id: '40720',
              type: 'studentsSkill',
            },
            {
              id: '40721',
              type: 'studentsSkill',
            },
            {
              id: '40726',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56352',
              type: 'klassDaysStudent',
            },
            {
              id: '56507',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1437',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4190',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4206',
      type: 'student',
      attributes: {
        fullName: 'Jianzhuang Huang',
        preferredName: 'Jay',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1983-04-20T05:00:00.000Z',
        ltsNumber: '1997815',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37859',
              type: 'studentsSkill',
            },
            {
              id: '37860',
              type: 'studentsSkill',
            },
            {
              id: '37861',
              type: 'studentsSkill',
            },
            {
              id: '37862',
              type: 'studentsSkill',
            },
            {
              id: '37863',
              type: 'studentsSkill',
            },
            {
              id: '37864',
              type: 'studentsSkill',
            },
            {
              id: '37865',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56744',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1437',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4190',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4207',
      type: 'student',
      attributes: {
        fullName: 'Emily Blakley',
        preferredName: 'Emily',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1993-08-16T00:00:00.000Z',
        ltsNumber: '2024368',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41800',
              type: 'studentsSkill',
            },
            {
              id: '41801',
              type: 'studentsSkill',
            },
            {
              id: '41804',
              type: 'studentsSkill',
            },
            {
              id: '41799',
              type: 'studentsSkill',
            },
            {
              id: '41803',
              type: 'studentsSkill',
            },
            {
              id: '41802',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57569',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1439',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '19',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4191',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4208',
      type: 'student',
      attributes: {
        fullName: 'Andrew Stallings',
        preferredName: 'Andrew',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2007-11-30T00:00:00.000Z',
        ltsNumber: '2002899',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37872',
              type: 'studentsSkill',
            },
            {
              id: '37873',
              type: 'studentsSkill',
            },
            {
              id: '37874',
              type: 'studentsSkill',
            },
            {
              id: '37875',
              type: 'studentsSkill',
            },
            {
              id: '37876',
              type: 'studentsSkill',
            },
            {
              id: '37877',
              type: 'studentsSkill',
            },
            {
              id: '37878',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1440',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4192',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4210',
      type: 'student',
      attributes: {
        fullName: 'Alex Polunchenko',
        preferredName: 'Alex',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2013-05-07T04:00:00.000Z',
        ltsNumber: '1862768',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37888',
              type: 'studentsSkill',
            },
            {
              id: '37890',
              type: 'studentsSkill',
            },
            {
              id: '37892',
              type: 'studentsSkill',
            },
            {
              id: '37887',
              type: 'studentsSkill',
            },
            {
              id: '37889',
              type: 'studentsSkill',
            },
            {
              id: '37891',
              type: 'studentsSkill',
            },
            {
              id: '37886',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57482',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1564',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4193',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4212',
      type: 'student',
      attributes: {
        fullName: 'Ekaterina Siganova',
        preferredName: 'Ekaterina',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1981-08-20T00:00:00.000Z',
        ltsNumber: '1969399',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37900',
              type: 'studentsSkill',
            },
            {
              id: '37901',
              type: 'studentsSkill',
            },
            {
              id: '37902',
              type: 'studentsSkill',
            },
            {
              id: '37903',
              type: 'studentsSkill',
            },
            {
              id: '37904',
              type: 'studentsSkill',
            },
            {
              id: '37905',
              type: 'studentsSkill',
            },
            {
              id: '37906',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56747',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1444',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4193',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4213',
      type: 'student',
      attributes: {
        fullName: 'Emari Brown',
        preferredName: 'Emari',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1995-03-02T00:00:00.000Z',
        ltsNumber: '2006911',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37907',
              type: 'studentsSkill',
            },
            {
              id: '37908',
              type: 'studentsSkill',
            },
            {
              id: '37909',
              type: 'studentsSkill',
            },
            {
              id: '37910',
              type: 'studentsSkill',
            },
            {
              id: '37911',
              type: 'studentsSkill',
            },
            {
              id: '37912',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1445',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '19',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4194',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4214',
      type: 'student',
      attributes: {
        fullName: 'Erin Gotthardt',
        preferredName: 'Erin',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1981-04-17T00:00:00.000Z',
        ltsNumber: '1975515',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37913',
              type: 'studentsSkill',
            },
            {
              id: '37914',
              type: 'studentsSkill',
            },
            {
              id: '37915',
              type: 'studentsSkill',
            },
            {
              id: '37916',
              type: 'studentsSkill',
            },
            {
              id: '37917',
              type: 'studentsSkill',
            },
            {
              id: '37918',
              type: 'studentsSkill',
            },
            {
              id: '37919',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57559',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1446',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4195',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4216',
      type: 'student',
      attributes: {
        fullName: 'Joshua Roenfeldt',
        preferredName: 'Joshua',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1983-03-24T00:00:00.000Z',
        ltsNumber: '1984495',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37927',
              type: 'studentsSkill',
            },
            {
              id: '37928',
              type: 'studentsSkill',
            },
            {
              id: '37929',
              type: 'studentsSkill',
            },
            {
              id: '37930',
              type: 'studentsSkill',
            },
            {
              id: '37931',
              type: 'studentsSkill',
            },
            {
              id: '37932',
              type: 'studentsSkill',
            },
            {
              id: '37933',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1448',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4195',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4217',
      type: 'student',
      attributes: {
        fullName: 'Eliza King',
        preferredName: 'Eliza',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2007-12-24T00:00:00.000Z',
        ltsNumber: '1677857',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37936',
              type: 'studentsSkill',
            },
            {
              id: '37937',
              type: 'studentsSkill',
            },
            {
              id: '37938',
              type: 'studentsSkill',
            },
            {
              id: '37939',
              type: 'studentsSkill',
            },
            {
              id: '37940',
              type: 'studentsSkill',
            },
            {
              id: '37934',
              type: 'studentsSkill',
            },
            {
              id: '37935',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57538',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1449',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4196',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4218',
      type: 'student',
      attributes: {
        fullName: 'Elias Adamson Endres',
        preferredName: 'Elias',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2004-04-25T00:00:00.000Z',
        ltsNumber: '2029591',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37941',
              type: 'studentsSkill',
            },
            {
              id: '37942',
              type: 'studentsSkill',
            },
            {
              id: '37943',
              type: 'studentsSkill',
            },
            {
              id: '37944',
              type: 'studentsSkill',
            },
            {
              id: '37945',
              type: 'studentsSkill',
            },
            {
              id: '37946',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1450',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '21',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4197',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4219',
      type: 'student',
      attributes: {
        fullName: 'Austin Anderson',
        preferredName: 'Austin',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2007-06-22T00:00:00.000Z',
        ltsNumber: '2027373',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37947',
              type: 'studentsSkill',
            },
            {
              id: '37948',
              type: 'studentsSkill',
            },
            {
              id: '37949',
              type: 'studentsSkill',
            },
            {
              id: '37950',
              type: 'studentsSkill',
            },
            {
              id: '37951',
              type: 'studentsSkill',
            },
            {
              id: '37952',
              type: 'studentsSkill',
            },
            {
              id: '37953',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1451',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4198',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4220',
      type: 'student',
      attributes: {
        fullName: 'Elizabeth Paoletti',
        preferredName: 'Elizabeth',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2011-06-02T00:00:00.000Z',
        ltsNumber: '1778449',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37954',
              type: 'studentsSkill',
            },
            {
              id: '37955',
              type: 'studentsSkill',
            },
            {
              id: '37956',
              type: 'studentsSkill',
            },
            {
              id: '37957',
              type: 'studentsSkill',
            },
            {
              id: '37958',
              type: 'studentsSkill',
            },
            {
              id: '37959',
              type: 'studentsSkill',
            },
            {
              id: '37960',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1452',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4199',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4221',
      type: 'student',
      attributes: {
        fullName: 'Jeffrey St. Pierre',
        preferredName: 'Jeffrey',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1963-06-27T00:00:00.000Z',
        ltsNumber: '1997817',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37961',
              type: 'studentsSkill',
            },
            {
              id: '37962',
              type: 'studentsSkill',
            },
            {
              id: '37963',
              type: 'studentsSkill',
            },
            {
              id: '37964',
              type: 'studentsSkill',
            },
            {
              id: '37965',
              type: 'studentsSkill',
            },
            {
              id: '37966',
              type: 'studentsSkill',
            },
            {
              id: '37967',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1453',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4199',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4222',
      type: 'student',
      attributes: {
        fullName: 'Rui Sha',
        preferredName: 'Rui',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2013-05-25T00:00:00.000Z',
        ltsNumber: '1970308',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37968',
              type: 'studentsSkill',
            },
            {
              id: '37969',
              type: 'studentsSkill',
            },
            {
              id: '37970',
              type: 'studentsSkill',
            },
            {
              id: '37971',
              type: 'studentsSkill',
            },
            {
              id: '37972',
              type: 'studentsSkill',
            },
            {
              id: '37973',
              type: 'studentsSkill',
            },
            {
              id: '37974',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1454',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4200',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4223',
      type: 'student',
      attributes: {
        fullName: 'Yi Sha',
        preferredName: 'Yi',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2015-01-29T00:00:00.000Z',
        ltsNumber: '1970309',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37975',
              type: 'studentsSkill',
            },
            {
              id: '37976',
              type: 'studentsSkill',
            },
            {
              id: '37977',
              type: 'studentsSkill',
            },
            {
              id: '37978',
              type: 'studentsSkill',
            },
            {
              id: '37979',
              type: 'studentsSkill',
            },
            {
              id: '37980',
              type: 'studentsSkill',
            },
            {
              id: '37981',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1455',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '9',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4200',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4224',
      type: 'student',
      attributes: {
        fullName: 'Leyla Kamil',
        preferredName: 'Leyla',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-05-29T00:00:00.000Z',
        ltsNumber: '1675205',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42071',
              type: 'studentsSkill',
            },
            {
              id: '42072',
              type: 'studentsSkill',
            },
            {
              id: '42073',
              type: 'studentsSkill',
            },
            {
              id: '42074',
              type: 'studentsSkill',
            },
            {
              id: '42075',
              type: 'studentsSkill',
            },
            {
              id: '42076',
              type: 'studentsSkill',
            },
            {
              id: '42077',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1456',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4201',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4225',
      type: 'student',
      attributes: {
        fullName: 'Faeda Shamil',
        preferredName: 'Faeda',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1985-01-01T00:00:00.000Z',
        ltsNumber: '1881755',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37989',
              type: 'studentsSkill',
            },
            {
              id: '37990',
              type: 'studentsSkill',
            },
            {
              id: '37991',
              type: 'studentsSkill',
            },
            {
              id: '37992',
              type: 'studentsSkill',
            },
            {
              id: '37993',
              type: 'studentsSkill',
            },
            {
              id: '37994',
              type: 'studentsSkill',
            },
            {
              id: '37995',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1457',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4201',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4226',
      type: 'student',
      attributes: {
        fullName: 'Heather Lee',
        preferredName: 'Heather',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1964-10-27T00:00:00.000Z',
        ltsNumber: '1998780',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '37996',
              type: 'studentsSkill',
            },
            {
              id: '37997',
              type: 'studentsSkill',
            },
            {
              id: '37998',
              type: 'studentsSkill',
            },
            {
              id: '37999',
              type: 'studentsSkill',
            },
            {
              id: '38000',
              type: 'studentsSkill',
            },
            {
              id: '38001',
              type: 'studentsSkill',
            },
            {
              id: '38002',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1458',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3993',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4244',
      type: 'student',
      attributes: {
        fullName: 'Jessica Betts',
        preferredName: 'Jessica',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1990-08-28T00:00:00.000Z',
        ltsNumber: '582827',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38003',
              type: 'studentsSkill',
            },
            {
              id: '38004',
              type: 'studentsSkill',
            },
            {
              id: '38005',
              type: 'studentsSkill',
            },
            {
              id: '38006',
              type: 'studentsSkill',
            },
            {
              id: '38007',
              type: 'studentsSkill',
            },
            {
              id: '38008',
              type: 'studentsSkill',
            },
            {
              id: '38009',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1459',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4227',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4245',
      type: 'student',
      attributes: {
        fullName: 'Lingjun Jiang',
        preferredName: 'Lingjun',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1989-07-09T00:00:00.000Z',
        ltsNumber: '2021481',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38010',
              type: 'studentsSkill',
            },
            {
              id: '38011',
              type: 'studentsSkill',
            },
            {
              id: '38012',
              type: 'studentsSkill',
            },
            {
              id: '38013',
              type: 'studentsSkill',
            },
            {
              id: '38014',
              type: 'studentsSkill',
            },
            {
              id: '38015',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1460',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '21',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4228',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4246',
      type: 'student',
      attributes: {
        fullName: 'Connor Dithrich',
        preferredName: 'Connor',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2011-04-15T00:00:00.000Z',
        ltsNumber: '1779854',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38016',
              type: 'studentsSkill',
            },
            {
              id: '38017',
              type: 'studentsSkill',
            },
            {
              id: '38018',
              type: 'studentsSkill',
            },
            {
              id: '38019',
              type: 'studentsSkill',
            },
            {
              id: '38020',
              type: 'studentsSkill',
            },
            {
              id: '38021',
              type: 'studentsSkill',
            },
            {
              id: '38022',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1461',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4229',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4248',
      type: 'student',
      attributes: {
        fullName: 'Journey Smith',
        preferredName: 'Journey',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-09-26T00:00:00.000Z',
        ltsNumber: '2006921',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38031',
              type: 'studentsSkill',
            },
            {
              id: '38032',
              type: 'studentsSkill',
            },
            {
              id: '38033',
              type: 'studentsSkill',
            },
            {
              id: '38034',
              type: 'studentsSkill',
            },
            {
              id: '38035',
              type: 'studentsSkill',
            },
            {
              id: '38036',
              type: 'studentsSkill',
            },
            {
              id: '38037',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1463',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4229',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4249',
      type: 'student',
      attributes: {
        fullName: 'Savannah Stinson',
        preferredName: 'Savannah',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2006-12-18T00:00:00.000Z',
        ltsNumber: '1236352',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38038',
              type: 'studentsSkill',
            },
            {
              id: '38039',
              type: 'studentsSkill',
            },
            {
              id: '38040',
              type: 'studentsSkill',
            },
            {
              id: '38041',
              type: 'studentsSkill',
            },
            {
              id: '38042',
              type: 'studentsSkill',
            },
            {
              id: '38043',
              type: 'studentsSkill',
            },
            {
              id: '38044',
              type: 'studentsSkill',
            },
            {
              id: '38045',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1464',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '6',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4230',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4250',
      type: 'student',
      attributes: {
        fullName: 'Stan Hunter Ii',
        preferredName: 'Stan',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1977-01-06T00:00:00.000Z',
        ltsNumber: '1970286',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38046',
              type: 'studentsSkill',
            },
            {
              id: '38047',
              type: 'studentsSkill',
            },
            {
              id: '38048',
              type: 'studentsSkill',
            },
            {
              id: '38049',
              type: 'studentsSkill',
            },
            {
              id: '38050',
              type: 'studentsSkill',
            },
            {
              id: '38051',
              type: 'studentsSkill',
            },
            {
              id: '38052',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1465',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4231',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4251',
      type: 'student',
      attributes: {
        fullName: 'Stan Hunter Iii',
        preferredName: 'Stan',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2013-12-03T00:00:00.000Z',
        ltsNumber: '1970275',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38053',
              type: 'studentsSkill',
            },
            {
              id: '38054',
              type: 'studentsSkill',
            },
            {
              id: '38055',
              type: 'studentsSkill',
            },
            {
              id: '38056',
              type: 'studentsSkill',
            },
            {
              id: '38057',
              type: 'studentsSkill',
            },
            {
              id: '38058',
              type: 'studentsSkill',
            },
            {
              id: '38059',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1466',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '9',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4231',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4252',
      type: 'student',
      attributes: {
        fullName: 'Evie Li',
        preferredName: 'Evie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2014-11-01T04:00:00.000Z',
        ltsNumber: '2004984',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38064',
              type: 'studentsSkill',
            },
            {
              id: '38065',
              type: 'studentsSkill',
            },
            {
              id: '38061',
              type: 'studentsSkill',
            },
            {
              id: '38066',
              type: 'studentsSkill',
            },
            {
              id: '38062',
              type: 'studentsSkill',
            },
            {
              id: '38067',
              type: 'studentsSkill',
            },
            {
              id: '38060',
              type: 'studentsSkill',
            },
            {
              id: '38063',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57048',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1467',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4232',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4253',
      type: 'student',
      attributes: {
        fullName: 'Joel Weeks',
        preferredName: 'Joel',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1970-05-03T00:00:00.000Z',
        ltsNumber: '2004989',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38068',
              type: 'studentsSkill',
            },
            {
              id: '38069',
              type: 'studentsSkill',
            },
            {
              id: '38070',
              type: 'studentsSkill',
            },
            {
              id: '38071',
              type: 'studentsSkill',
            },
            {
              id: '38072',
              type: 'studentsSkill',
            },
            {
              id: '38073',
              type: 'studentsSkill',
            },
            {
              id: '38074',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1468',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4232',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4255',
      type: 'student',
      attributes: {
        fullName: 'Anushree Karkhanis',
        preferredName: 'Anushree',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1984-10-18T00:00:00.000Z',
        ltsNumber: '2002872',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38082',
              type: 'studentsSkill',
            },
            {
              id: '38083',
              type: 'studentsSkill',
            },
            {
              id: '38084',
              type: 'studentsSkill',
            },
            {
              id: '38085',
              type: 'studentsSkill',
            },
            {
              id: '38086',
              type: 'studentsSkill',
            },
            {
              id: '38087',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1470',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '19',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4233',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4256',
      type: 'student',
      attributes: {
        fullName: 'Nikhil Karkhanis',
        preferredName: 'Nikhil',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1982-02-07T00:00:00.000Z',
        ltsNumber: '1970897',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38088',
              type: 'studentsSkill',
            },
            {
              id: '38089',
              type: 'studentsSkill',
            },
            {
              id: '38090',
              type: 'studentsSkill',
            },
            {
              id: '38091',
              type: 'studentsSkill',
            },
            {
              id: '38092',
              type: 'studentsSkill',
            },
            {
              id: '38093',
              type: 'studentsSkill',
            },
            {
              id: '38094',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1471',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4233',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4259',
      type: 'student',
      attributes: {
        fullName: 'Robert Griffith Gates',
        preferredName: 'Robert',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2012-08-02T00:00:00.000Z',
        ltsNumber: '1987437',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38110',
              type: 'studentsSkill',
            },
            {
              id: '38111',
              type: 'studentsSkill',
            },
            {
              id: '38112',
              type: 'studentsSkill',
            },
            {
              id: '38113',
              type: 'studentsSkill',
            },
            {
              id: '38114',
              type: 'studentsSkill',
            },
            {
              id: '38115',
              type: 'studentsSkill',
            },
            {
              id: '38116',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1474',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4236',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4260',
      type: 'student',
      attributes: {
        fullName: 'Paige Hinckley',
        preferredName: 'Paige',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2006-11-21T00:00:00.000Z',
        ltsNumber: '1900498',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38117',
              type: 'studentsSkill',
            },
            {
              id: '38118',
              type: 'studentsSkill',
            },
            {
              id: '38119',
              type: 'studentsSkill',
            },
            {
              id: '38120',
              type: 'studentsSkill',
            },
            {
              id: '38121',
              type: 'studentsSkill',
            },
            {
              id: '38122',
              type: 'studentsSkill',
            },
            {
              id: '38123',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1475',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '12',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4237',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4261',
      type: 'student',
      attributes: {
        fullName: 'Maggie Harmon',
        preferredName: 'Maggie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2016-09-15T00:00:00.000Z',
        ltsNumber: '2021475',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38124',
              type: 'studentsSkill',
            },
            {
              id: '38125',
              type: 'studentsSkill',
            },
            {
              id: '38126',
              type: 'studentsSkill',
            },
            {
              id: '38127',
              type: 'studentsSkill',
            },
            {
              id: '38128',
              type: 'studentsSkill',
            },
            {
              id: '38129',
              type: 'studentsSkill',
            },
            {
              id: '38130',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1476',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4238',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4262',
      type: 'student',
      attributes: {
        fullName: 'Mollie Harmon',
        preferredName: 'Mollie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2010-11-26T00:00:00.000Z',
        ltsNumber: '2015080',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38131',
              type: 'studentsSkill',
            },
            {
              id: '38132',
              type: 'studentsSkill',
            },
            {
              id: '38133',
              type: 'studentsSkill',
            },
            {
              id: '38134',
              type: 'studentsSkill',
            },
            {
              id: '38135',
              type: 'studentsSkill',
            },
            {
              id: '38136',
              type: 'studentsSkill',
            },
            {
              id: '38137',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1477',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4238',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4263',
      type: 'student',
      attributes: {
        fullName: 'Natalie Kocak',
        preferredName: 'Natalie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2010-07-14T00:00:00.000Z',
        ltsNumber: '2013449',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38138',
              type: 'studentsSkill',
            },
            {
              id: '38139',
              type: 'studentsSkill',
            },
            {
              id: '38140',
              type: 'studentsSkill',
            },
            {
              id: '38141',
              type: 'studentsSkill',
            },
            {
              id: '38142',
              type: 'studentsSkill',
            },
            {
              id: '38143',
              type: 'studentsSkill',
            },
            {
              id: '38144',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57483',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1478',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4239',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4264',
      type: 'student',
      attributes: {
        fullName: 'Joanna Adams',
        preferredName: 'Joanna',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-12-25T00:00:00.000Z',
        ltsNumber: '1775204',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38145',
              type: 'studentsSkill',
            },
            {
              id: '38146',
              type: 'studentsSkill',
            },
            {
              id: '38147',
              type: 'studentsSkill',
            },
            {
              id: '38148',
              type: 'studentsSkill',
            },
            {
              id: '38149',
              type: 'studentsSkill',
            },
            {
              id: '38150',
              type: 'studentsSkill',
            },
            {
              id: '38151',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1479',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4240',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4265',
      type: 'student',
      attributes: {
        fullName: 'Jocelyn Adams',
        preferredName: 'Jocelyn',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2009-10-16T00:00:00.000Z',
        ltsNumber: '1697782',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38152',
              type: 'studentsSkill',
            },
            {
              id: '38153',
              type: 'studentsSkill',
            },
            {
              id: '38154',
              type: 'studentsSkill',
            },
            {
              id: '38155',
              type: 'studentsSkill',
            },
            {
              id: '38156',
              type: 'studentsSkill',
            },
            {
              id: '38157',
              type: 'studentsSkill',
            },
            {
              id: '38158',
              type: 'studentsSkill',
            },
            {
              id: '38159',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1480',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '11',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4240',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4266',
      type: 'student',
      attributes: {
        fullName: 'Juliet Adams',
        preferredName: 'Juliet',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-03-19T00:00:00.000Z',
        ltsNumber: '1753038',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38160',
              type: 'studentsSkill',
            },
            {
              id: '38161',
              type: 'studentsSkill',
            },
            {
              id: '38162',
              type: 'studentsSkill',
            },
            {
              id: '38163',
              type: 'studentsSkill',
            },
            {
              id: '38164',
              type: 'studentsSkill',
            },
            {
              id: '38165',
              type: 'studentsSkill',
            },
            {
              id: '38166',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1481',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4240',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4267',
      type: 'student',
      attributes: {
        fullName: 'Kevin Adams',
        preferredName: 'Kevin',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1981-03-02T00:00:00.000Z',
        ltsNumber: '1697783',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38167',
              type: 'studentsSkill',
            },
            {
              id: '38168',
              type: 'studentsSkill',
            },
            {
              id: '38169',
              type: 'studentsSkill',
            },
            {
              id: '38170',
              type: 'studentsSkill',
            },
            {
              id: '38171',
              type: 'studentsSkill',
            },
            {
              id: '38172',
              type: 'studentsSkill',
            },
            {
              id: '38173',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1482',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4240',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4269',
      type: 'student',
      attributes: {
        fullName: 'Michael Korolev',
        preferredName: 'Michael',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2015-07-22T04:00:00.000Z',
        ltsNumber: '2128023',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41841',
              type: 'studentsSkill',
            },
            {
              id: '41842',
              type: 'studentsSkill',
            },
            {
              id: '41837',
              type: 'studentsSkill',
            },
            {
              id: '41838',
              type: 'studentsSkill',
            },
            {
              id: '41843',
              type: 'studentsSkill',
            },
            {
              id: '41839',
              type: 'studentsSkill',
            },
            {
              id: '41844',
              type: 'studentsSkill',
            },
            {
              id: '41840',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57477',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1744',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4242',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4288',
      type: 'student',
      attributes: {
        fullName: 'Justin Scheddin',
        preferredName: 'Justin',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1992-07-19T00:00:00.000Z',
        ltsNumber: '2031863',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38204',
              type: 'studentsSkill',
            },
            {
              id: '38205',
              type: 'studentsSkill',
            },
            {
              id: '38206',
              type: 'studentsSkill',
            },
            {
              id: '38207',
              type: 'studentsSkill',
            },
            {
              id: '38208',
              type: 'studentsSkill',
            },
            {
              id: '38209',
              type: 'studentsSkill',
            },
            {
              id: '38210',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1486',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4270',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4289',
      type: 'student',
      attributes: {
        fullName: 'Emma Petersen',
        preferredName: 'Emma',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-08-30T00:00:00.000Z',
        ltsNumber: '1970898',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42094',
              type: 'studentsSkill',
            },
            {
              id: '42096',
              type: 'studentsSkill',
            },
            {
              id: '42098',
              type: 'studentsSkill',
            },
            {
              id: '42092',
              type: 'studentsSkill',
            },
            {
              id: '42093',
              type: 'studentsSkill',
            },
            {
              id: '42095',
              type: 'studentsSkill',
            },
            {
              id: '42097',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57479',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1487',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4271',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4290',
      type: 'student',
      attributes: {
        fullName: 'Jennifer Petersen',
        preferredName: 'Jen',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1980-06-16T04:00:00.000Z',
        ltsNumber: '1970900',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38218',
              type: 'studentsSkill',
            },
            {
              id: '38219',
              type: 'studentsSkill',
            },
            {
              id: '38220',
              type: 'studentsSkill',
            },
            {
              id: '38221',
              type: 'studentsSkill',
            },
            {
              id: '38222',
              type: 'studentsSkill',
            },
            {
              id: '38223',
              type: 'studentsSkill',
            },
            {
              id: '38224',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1487',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4271',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4291',
      type: 'student',
      attributes: {
        fullName: 'Bilal Kamil',
        preferredName: 'Bilal',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2010-06-17T00:00:00.000Z',
        ltsNumber: '1675204',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38225',
              type: 'studentsSkill',
            },
            {
              id: '38226',
              type: 'studentsSkill',
            },
            {
              id: '38227',
              type: 'studentsSkill',
            },
            {
              id: '38228',
              type: 'studentsSkill',
            },
            {
              id: '38229',
              type: 'studentsSkill',
            },
            {
              id: '38230',
              type: 'studentsSkill',
            },
            {
              id: '38231',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1489',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '5',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4272',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4292',
      type: 'student',
      attributes: {
        fullName: 'Melissa Viall',
        preferredName: 'Melissa',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1977-01-20T00:00:00.000Z',
        ltsNumber: '1257061',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38232',
              type: 'studentsSkill',
            },
            {
              id: '38233',
              type: 'studentsSkill',
            },
            {
              id: '38234',
              type: 'studentsSkill',
            },
            {
              id: '38235',
              type: 'studentsSkill',
            },
            {
              id: '38236',
              type: 'studentsSkill',
            },
            {
              id: '38237',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1490',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '20',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4273',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4294',
      type: 'student',
      attributes: {
        fullName: 'Chris Cheng',
        preferredName: 'Chris',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2012-12-26T05:00:00.000Z',
        ltsNumber: '1698988',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42050',
              type: 'studentsSkill',
            },
            {
              id: '42051',
              type: 'studentsSkill',
            },
            {
              id: '42053',
              type: 'studentsSkill',
            },
            {
              id: '42055',
              type: 'studentsSkill',
            },
            {
              id: '42052',
              type: 'studentsSkill',
            },
            {
              id: '42054',
              type: 'studentsSkill',
            },
            {
              id: '42056',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57478',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1492',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4275',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4295',
      type: 'student',
      attributes: {
        fullName: 'Andrew Sadej',
        preferredName: 'Andrew',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2014-08-03T00:00:00.000Z',
        ltsNumber: '1983435',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '40715',
              type: 'studentsSkill',
            },
            {
              id: '40716',
              type: 'studentsSkill',
            },
            {
              id: '40717',
              type: 'studentsSkill',
            },
            {
              id: '40718',
              type: 'studentsSkill',
            },
            {
              id: '40713',
              type: 'studentsSkill',
            },
            {
              id: '40714',
              type: 'studentsSkill',
            },
            {
              id: '40719',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56351',
              type: 'klassDaysStudent',
            },
            {
              id: '56506',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1493',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4276',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4296',
      type: 'student',
      attributes: {
        fullName: 'Gabriella Sadej',
        preferredName: 'Gabriella',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-09-27T00:00:00.000Z',
        ltsNumber: '1983433',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38260',
              type: 'studentsSkill',
            },
            {
              id: '38262',
              type: 'studentsSkill',
            },
            {
              id: '38264',
              type: 'studentsSkill',
            },
            {
              id: '38261',
              type: 'studentsSkill',
            },
            {
              id: '38258',
              type: 'studentsSkill',
            },
            {
              id: '38259',
              type: 'studentsSkill',
            },
            {
              id: '38263',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57481',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1494',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4276',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4297',
      type: 'student',
      attributes: {
        fullName: 'Olivia Sadej',
        preferredName: 'Olivia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1981-10-05T00:00:00.000Z',
        ltsNumber: '1983437',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38267',
              type: 'studentsSkill',
            },
            {
              id: '38269',
              type: 'studentsSkill',
            },
            {
              id: '38270',
              type: 'studentsSkill',
            },
            {
              id: '38266',
              type: 'studentsSkill',
            },
            {
              id: '38265',
              type: 'studentsSkill',
            },
            {
              id: '38268',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57570',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1495',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '21',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4276',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4298',
      type: 'student',
      attributes: {
        fullName: 'Kaylie Olmstead',
        preferredName: 'Kaylie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2008-01-14T00:00:00.000Z',
        ltsNumber: '2004817',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38271',
              type: 'studentsSkill',
            },
            {
              id: '38272',
              type: 'studentsSkill',
            },
            {
              id: '38273',
              type: 'studentsSkill',
            },
            {
              id: '38274',
              type: 'studentsSkill',
            },
            {
              id: '38275',
              type: 'studentsSkill',
            },
            {
              id: '38276',
              type: 'studentsSkill',
            },
            {
              id: '38277',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1496',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4277',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4299',
      type: 'student',
      attributes: {
        fullName: 'Tomann Rice',
        preferredName: 'Tomann',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1985-01-01T00:00:00.000Z',
        ltsNumber: '1878560',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38278',
              type: 'studentsSkill',
            },
            {
              id: '38279',
              type: 'studentsSkill',
            },
            {
              id: '38280',
              type: 'studentsSkill',
            },
            {
              id: '38281',
              type: 'studentsSkill',
            },
            {
              id: '38282',
              type: 'studentsSkill',
            },
            {
              id: '38283',
              type: 'studentsSkill',
            },
            {
              id: '38284',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1497',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '13',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4278',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4300',
      type: 'student',
      attributes: {
        fullName: 'Evelyn Volkert',
        preferredName: 'Evelyn',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2011-08-24T00:00:00.000Z',
        ltsNumber: '1234505',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38285',
              type: 'studentsSkill',
            },
            {
              id: '38286',
              type: 'studentsSkill',
            },
            {
              id: '38287',
              type: 'studentsSkill',
            },
            {
              id: '38288',
              type: 'studentsSkill',
            },
            {
              id: '38289',
              type: 'studentsSkill',
            },
            {
              id: '38290',
              type: 'studentsSkill',
            },
            {
              id: '38291',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1498',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4279',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4301',
      type: 'student',
      attributes: {
        fullName: 'Joanna Volkert',
        preferredName: 'Joanna',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1980-01-01T00:00:00.000Z',
        ltsNumber: '1893751',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38292',
              type: 'studentsSkill',
            },
            {
              id: '38293',
              type: 'studentsSkill',
            },
            {
              id: '38294',
              type: 'studentsSkill',
            },
            {
              id: '38295',
              type: 'studentsSkill',
            },
            {
              id: '38296',
              type: 'studentsSkill',
            },
            {
              id: '38297',
              type: 'studentsSkill',
            },
            {
              id: '38298',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1499',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4279',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4302',
      type: 'student',
      attributes: {
        fullName: 'William Volkert',
        preferredName: 'William',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2013-04-05T00:00:00.000Z',
        ltsNumber: '1775216',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38299',
              type: 'studentsSkill',
            },
            {
              id: '38300',
              type: 'studentsSkill',
            },
            {
              id: '38301',
              type: 'studentsSkill',
            },
            {
              id: '38302',
              type: 'studentsSkill',
            },
            {
              id: '38303',
              type: 'studentsSkill',
            },
            {
              id: '38304',
              type: 'studentsSkill',
            },
            {
              id: '38305',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1500',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4279',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4303',
      type: 'student',
      attributes: {
        fullName: 'Paige Testa',
        preferredName: 'Paige',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2008-01-29T00:00:00.000Z',
        ltsNumber: '1658831',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38306',
              type: 'studentsSkill',
            },
            {
              id: '38307',
              type: 'studentsSkill',
            },
            {
              id: '38308',
              type: 'studentsSkill',
            },
            {
              id: '38309',
              type: 'studentsSkill',
            },
            {
              id: '38310',
              type: 'studentsSkill',
            },
            {
              id: '38311',
              type: 'studentsSkill',
            },
            {
              id: '38312',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1501',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '5',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4280',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4304',
      type: 'student',
      attributes: {
        fullName: 'Raelynne Lavine',
        preferredName: 'Raelynne',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2008-01-12T00:00:00.000Z',
        ltsNumber: '1997816',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38313',
              type: 'studentsSkill',
            },
            {
              id: '38314',
              type: 'studentsSkill',
            },
            {
              id: '38315',
              type: 'studentsSkill',
            },
            {
              id: '38316',
              type: 'studentsSkill',
            },
            {
              id: '38317',
              type: 'studentsSkill',
            },
            {
              id: '38318',
              type: 'studentsSkill',
            },
            {
              id: '38319',
              type: 'studentsSkill',
            },
            {
              id: '38320',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1502',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4281',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4305',
      type: 'student',
      attributes: {
        fullName: 'Ivan Mc Intyre',
        preferredName: 'Ivan',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2010-07-21T00:00:00.000Z',
        ltsNumber: '1970307',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38321',
              type: 'studentsSkill',
            },
            {
              id: '38322',
              type: 'studentsSkill',
            },
            {
              id: '38323',
              type: 'studentsSkill',
            },
            {
              id: '38324',
              type: 'studentsSkill',
            },
            {
              id: '38325',
              type: 'studentsSkill',
            },
            {
              id: '38326',
              type: 'studentsSkill',
            },
            {
              id: '38327',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1503',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4282',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4306',
      type: 'student',
      attributes: {
        fullName: 'Madeline Mc Intyre',
        preferredName: 'Madeline',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2008-11-11T00:00:00.000Z',
        ltsNumber: '1970306',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38328',
              type: 'studentsSkill',
            },
            {
              id: '38329',
              type: 'studentsSkill',
            },
            {
              id: '38330',
              type: 'studentsSkill',
            },
            {
              id: '38331',
              type: 'studentsSkill',
            },
            {
              id: '38332',
              type: 'studentsSkill',
            },
            {
              id: '38333',
              type: 'studentsSkill',
            },
            {
              id: '38334',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1504',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4282',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4307',
      type: 'student',
      attributes: {
        fullName: 'Rachel Mischler',
        preferredName: 'Rachel',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1973-11-26T00:00:00.000Z',
        ltsNumber: '2013452',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38335',
              type: 'studentsSkill',
            },
            {
              id: '38336',
              type: 'studentsSkill',
            },
            {
              id: '38337',
              type: 'studentsSkill',
            },
            {
              id: '38338',
              type: 'studentsSkill',
            },
            {
              id: '38339',
              type: 'studentsSkill',
            },
            {
              id: '38340',
              type: 'studentsSkill',
            },
            {
              id: '38341',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1505',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4283',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4308',
      type: 'student',
      attributes: {
        fullName: 'Amanda Caldwell',
        preferredName: 'Amanda',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-10-15T00:00:00.000Z',
        ltsNumber: '1753045',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38728',
              type: 'studentsSkill',
            },
            {
              id: '38729',
              type: 'studentsSkill',
            },
            {
              id: '38730',
              type: 'studentsSkill',
            },
            {
              id: '38731',
              type: 'studentsSkill',
            },
            {
              id: '38732',
              type: 'studentsSkill',
            },
            {
              id: '38726',
              type: 'studentsSkill',
            },
            {
              id: '38727',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57537',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1506',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4284',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4309',
      type: 'student',
      attributes: {
        fullName: 'Lillian Caldwell',
        preferredName: 'Lillian',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2008-06-16T00:00:00.000Z',
        ltsNumber: '1753042',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38349',
              type: 'studentsSkill',
            },
            {
              id: '38350',
              type: 'studentsSkill',
            },
            {
              id: '38351',
              type: 'studentsSkill',
            },
            {
              id: '38352',
              type: 'studentsSkill',
            },
            {
              id: '38353',
              type: 'studentsSkill',
            },
            {
              id: '38354',
              type: 'studentsSkill',
            },
            {
              id: '38355',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1507',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '12',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4284',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4310',
      type: 'student',
      attributes: {
        fullName: 'Rachel Anszelowicz',
        preferredName: 'Rachel',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1998-06-06T00:00:00.000Z',
        ltsNumber: '1987394',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38356',
              type: 'studentsSkill',
            },
            {
              id: '38357',
              type: 'studentsSkill',
            },
            {
              id: '38358',
              type: 'studentsSkill',
            },
            {
              id: '38359',
              type: 'studentsSkill',
            },
            {
              id: '38360',
              type: 'studentsSkill',
            },
            {
              id: '38361',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1508',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '20',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4285',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4311',
      type: 'student',
      attributes: {
        fullName: 'Evan Rackett',
        preferredName: 'Evan',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2013-02-18T00:00:00.000Z',
        ltsNumber: '2029581',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38362',
              type: 'studentsSkill',
            },
            {
              id: '38363',
              type: 'studentsSkill',
            },
            {
              id: '38364',
              type: 'studentsSkill',
            },
            {
              id: '38365',
              type: 'studentsSkill',
            },
            {
              id: '38366',
              type: 'studentsSkill',
            },
            {
              id: '38367',
              type: 'studentsSkill',
            },
            {
              id: '38368',
              type: 'studentsSkill',
            },
            {
              id: '38369',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1509',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4286',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4312',
      type: 'student',
      attributes: {
        fullName: 'Allison Abbey',
        preferredName: 'Allison',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-05-20T00:00:00.000Z',
        ltsNumber: '1642937',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38370',
              type: 'studentsSkill',
            },
            {
              id: '38371',
              type: 'studentsSkill',
            },
            {
              id: '38372',
              type: 'studentsSkill',
            },
            {
              id: '38373',
              type: 'studentsSkill',
            },
            {
              id: '38374',
              type: 'studentsSkill',
            },
            {
              id: '38375',
              type: 'studentsSkill',
            },
            {
              id: '38376',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57480',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1510',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4065',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4325',
      type: 'student',
      attributes: {
        fullName: 'Lucas Davis',
        preferredName: 'Lucas',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2008-05-03T00:00:00.000Z',
        ltsNumber: '1099674',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38384',
              type: 'studentsSkill',
            },
            {
              id: '38385',
              type: 'studentsSkill',
            },
            {
              id: '38386',
              type: 'studentsSkill',
            },
            {
              id: '38387',
              type: 'studentsSkill',
            },
            {
              id: '38388',
              type: 'studentsSkill',
            },
            {
              id: '38389',
              type: 'studentsSkill',
            },
            {
              id: '38390',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1512',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '12',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4313',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4326',
      type: 'student',
      attributes: {
        fullName: 'Ryan Davis',
        preferredName: 'Ryan',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1977-01-09T00:00:00.000Z',
        ltsNumber: '1969942',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38391',
              type: 'studentsSkill',
            },
            {
              id: '38392',
              type: 'studentsSkill',
            },
            {
              id: '38393',
              type: 'studentsSkill',
            },
            {
              id: '38394',
              type: 'studentsSkill',
            },
            {
              id: '38395',
              type: 'studentsSkill',
            },
            {
              id: '38396',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1513',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '21',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4313',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4327',
      type: 'student',
      attributes: {
        fullName: 'Simone Davis',
        preferredName: 'Simone',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1977-10-04T00:00:00.000Z',
        ltsNumber: '1099675',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38397',
              type: 'studentsSkill',
            },
            {
              id: '38398',
              type: 'studentsSkill',
            },
            {
              id: '38399',
              type: 'studentsSkill',
            },
            {
              id: '38400',
              type: 'studentsSkill',
            },
            {
              id: '38401',
              type: 'studentsSkill',
            },
            {
              id: '38402',
              type: 'studentsSkill',
            },
            {
              id: '38403',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1514',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4313',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4328',
      type: 'student',
      attributes: {
        fullName: 'Tyler Davis',
        preferredName: 'Tyler',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2010-04-22T00:00:00.000Z',
        ltsNumber: '1099673',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38404',
              type: 'studentsSkill',
            },
            {
              id: '38405',
              type: 'studentsSkill',
            },
            {
              id: '38406',
              type: 'studentsSkill',
            },
            {
              id: '38407',
              type: 'studentsSkill',
            },
            {
              id: '38408',
              type: 'studentsSkill',
            },
            {
              id: '38409',
              type: 'studentsSkill',
            },
            {
              id: '38410',
              type: 'studentsSkill',
            },
            {
              id: '38411',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1515',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '6',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4313',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4329',
      type: 'student',
      attributes: {
        fullName: 'Vladislav Kargin',
        preferredName: 'Vladislav',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1969-05-30T00:00:00.000Z',
        ltsNumber: '1971735',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38412',
              type: 'studentsSkill',
            },
            {
              id: '38413',
              type: 'studentsSkill',
            },
            {
              id: '38414',
              type: 'studentsSkill',
            },
            {
              id: '38415',
              type: 'studentsSkill',
            },
            {
              id: '38416',
              type: 'studentsSkill',
            },
            {
              id: '38417',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1516',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '21',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4314',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4330',
      type: 'student',
      attributes: {
        fullName: 'Zacharia Almashni',
        preferredName: 'Zacharia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2006-11-28T00:00:00.000Z',
        ltsNumber: '1971766',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38418',
              type: 'studentsSkill',
            },
            {
              id: '38419',
              type: 'studentsSkill',
            },
            {
              id: '38420',
              type: 'studentsSkill',
            },
            {
              id: '38421',
              type: 'studentsSkill',
            },
            {
              id: '38422',
              type: 'studentsSkill',
            },
            {
              id: '38423',
              type: 'studentsSkill',
            },
            {
              id: '38424',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1517',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4315',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4332',
      type: 'student',
      attributes: {
        fullName: 'Evan Rynders',
        preferredName: 'Evan',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2013-12-12T05:00:00.000Z',
        ltsNumber: '1989030',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38432',
              type: 'studentsSkill',
            },
            {
              id: '38433',
              type: 'studentsSkill',
            },
            {
              id: '38434',
              type: 'studentsSkill',
            },
            {
              id: '38435',
              type: 'studentsSkill',
            },
            {
              id: '38436',
              type: 'studentsSkill',
            },
            {
              id: '38437',
              type: 'studentsSkill',
            },
            {
              id: '38438',
              type: 'studentsSkill',
            },
            {
              id: '38439',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1519',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4316',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4333',
      type: 'student',
      attributes: {
        fullName: 'Mark Rynders',
        preferredName: 'Mark',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1983-11-26T00:00:00.000Z',
        ltsNumber: '2015078',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38440',
              type: 'studentsSkill',
            },
            {
              id: '38441',
              type: 'studentsSkill',
            },
            {
              id: '38442',
              type: 'studentsSkill',
            },
            {
              id: '38443',
              type: 'studentsSkill',
            },
            {
              id: '38444',
              type: 'studentsSkill',
            },
            {
              id: '38445',
              type: 'studentsSkill',
            },
            {
              id: '38446',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1520',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4316',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4334',
      type: 'student',
      attributes: {
        fullName: 'Nathan Rynders',
        preferredName: 'Nathan',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2016-02-03T00:00:00.000Z',
        ltsNumber: '2015077',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38447',
              type: 'studentsSkill',
            },
            {
              id: '38448',
              type: 'studentsSkill',
            },
            {
              id: '38449',
              type: 'studentsSkill',
            },
            {
              id: '38450',
              type: 'studentsSkill',
            },
            {
              id: '38451',
              type: 'studentsSkill',
            },
            {
              id: '38452',
              type: 'studentsSkill',
            },
            {
              id: '38453',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1521',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4316',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4335',
      type: 'student',
      attributes: {
        fullName: 'Sarah Rynders',
        preferredName: 'Sarah',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1981-05-15T00:00:00.000Z',
        ltsNumber: '1989031',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38454',
              type: 'studentsSkill',
            },
            {
              id: '38455',
              type: 'studentsSkill',
            },
            {
              id: '38456',
              type: 'studentsSkill',
            },
            {
              id: '38457',
              type: 'studentsSkill',
            },
            {
              id: '38458',
              type: 'studentsSkill',
            },
            {
              id: '38459',
              type: 'studentsSkill',
            },
            {
              id: '38460',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1522',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4316',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4336',
      type: 'student',
      attributes: {
        fullName: 'Giovanna Fiacco',
        preferredName: 'Giovanna',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2015-09-10T00:00:00.000Z',
        ltsNumber: '1983444',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38461',
              type: 'studentsSkill',
            },
            {
              id: '38462',
              type: 'studentsSkill',
            },
            {
              id: '38463',
              type: 'studentsSkill',
            },
            {
              id: '38464',
              type: 'studentsSkill',
            },
            {
              id: '38465',
              type: 'studentsSkill',
            },
            {
              id: '38466',
              type: 'studentsSkill',
            },
            {
              id: '38467',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1523',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4317',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4337',
      type: 'student',
      attributes: {
        fullName: 'Dustin Steffen',
        preferredName: 'Dustin',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1985-01-10T00:00:00.000Z',
        ltsNumber: '1983450',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38468',
              type: 'studentsSkill',
            },
            {
              id: '38469',
              type: 'studentsSkill',
            },
            {
              id: '38470',
              type: 'studentsSkill',
            },
            {
              id: '38471',
              type: 'studentsSkill',
            },
            {
              id: '38472',
              type: 'studentsSkill',
            },
            {
              id: '38473',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1524',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '19',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4318',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4338',
      type: 'student',
      attributes: {
        fullName: 'Emily Steffen',
        preferredName: 'Emily',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2011-09-30T00:00:00.000Z',
        ltsNumber: '1983447',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38474',
              type: 'studentsSkill',
            },
            {
              id: '38475',
              type: 'studentsSkill',
            },
            {
              id: '38476',
              type: 'studentsSkill',
            },
            {
              id: '38477',
              type: 'studentsSkill',
            },
            {
              id: '38478',
              type: 'studentsSkill',
            },
            {
              id: '38479',
              type: 'studentsSkill',
            },
            {
              id: '38480',
              type: 'studentsSkill',
            },
            {
              id: '38481',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1525',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4318',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4339',
      type: 'student',
      attributes: {
        fullName: 'Stephanie Bostic',
        preferredName: 'Stephanie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1983-10-16T00:00:00.000Z',
        ltsNumber: '1970903',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38482',
              type: 'studentsSkill',
            },
            {
              id: '38483',
              type: 'studentsSkill',
            },
            {
              id: '38484',
              type: 'studentsSkill',
            },
            {
              id: '38485',
              type: 'studentsSkill',
            },
            {
              id: '38486',
              type: 'studentsSkill',
            },
            {
              id: '38487',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1526',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '22',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4319',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4341',
      type: 'student',
      attributes: {
        fullName: 'Adrianna Walker',
        preferredName: 'Adrianna',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2010-06-29T00:00:00.000Z',
        ltsNumber: '2002882',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38494',
              type: 'studentsSkill',
            },
            {
              id: '38495',
              type: 'studentsSkill',
            },
            {
              id: '38496',
              type: 'studentsSkill',
            },
            {
              id: '38497',
              type: 'studentsSkill',
            },
            {
              id: '38498',
              type: 'studentsSkill',
            },
            {
              id: '38499',
              type: 'studentsSkill',
            },
            {
              id: '38500',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1528',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4320',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4342',
      type: 'student',
      attributes: {
        fullName: 'Steven Walker',
        preferredName: 'Steven',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1978-12-13T00:00:00.000Z',
        ltsNumber: '2002884',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38501',
              type: 'studentsSkill',
            },
            {
              id: '38502',
              type: 'studentsSkill',
            },
            {
              id: '38503',
              type: 'studentsSkill',
            },
            {
              id: '38504',
              type: 'studentsSkill',
            },
            {
              id: '38505',
              type: 'studentsSkill',
            },
            {
              id: '38506',
              type: 'studentsSkill',
            },
            {
              id: '38507',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1529',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4320',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4343',
      type: 'student',
      attributes: {
        fullName: 'Henry Liu',
        preferredName: 'Henry',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2014-12-14T05:00:00.000Z',
        ltsNumber: '1970299',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41861',
              type: 'studentsSkill',
            },
            {
              id: '41862',
              type: 'studentsSkill',
            },
            {
              id: '41863',
              type: 'studentsSkill',
            },
            {
              id: '41864',
              type: 'studentsSkill',
            },
            {
              id: '41859',
              type: 'studentsSkill',
            },
            {
              id: '41860',
              type: 'studentsSkill',
            },
            {
              id: '41865',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57159',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1530',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4321',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4344',
      type: 'student',
      attributes: {
        fullName: 'Yihong Liu',
        preferredName: 'Yihong',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2012-11-21T00:00:00.000Z',
        ltsNumber: '1681651',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38515',
              type: 'studentsSkill',
            },
            {
              id: '38516',
              type: 'studentsSkill',
            },
            {
              id: '38517',
              type: 'studentsSkill',
            },
            {
              id: '38518',
              type: 'studentsSkill',
            },
            {
              id: '38519',
              type: 'studentsSkill',
            },
            {
              id: '38520',
              type: 'studentsSkill',
            },
            {
              id: '38521',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57540',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1531',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4321',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4345',
      type: 'student',
      attributes: {
        fullName: 'Elena Niedzialkowski',
        preferredName: 'Elena',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2015-10-21T00:00:00.000Z',
        ltsNumber: '2027375',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38522',
              type: 'studentsSkill',
            },
            {
              id: '38523',
              type: 'studentsSkill',
            },
            {
              id: '38524',
              type: 'studentsSkill',
            },
            {
              id: '38525',
              type: 'studentsSkill',
            },
            {
              id: '38526',
              type: 'studentsSkill',
            },
            {
              id: '38527',
              type: 'studentsSkill',
            },
            {
              id: '38528',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1532',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4322',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4347',
      type: 'student',
      attributes: {
        fullName: 'Alexander Ma',
        preferredName: 'Alexander',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2015-01-02T00:00:00.000Z',
        ltsNumber: '1919989',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38536',
              type: 'studentsSkill',
            },
            {
              id: '38537',
              type: 'studentsSkill',
            },
            {
              id: '38538',
              type: 'studentsSkill',
            },
            {
              id: '38539',
              type: 'studentsSkill',
            },
            {
              id: '38540',
              type: 'studentsSkill',
            },
            {
              id: '38541',
              type: 'studentsSkill',
            },
            {
              id: '38542',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1534',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4323',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4348',
      type: 'student',
      attributes: {
        fullName: 'Angela Ma',
        preferredName: 'Angela',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-01-09T00:00:00.000Z',
        ltsNumber: '1759540',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38543',
              type: 'studentsSkill',
            },
            {
              id: '38544',
              type: 'studentsSkill',
            },
            {
              id: '38545',
              type: 'studentsSkill',
            },
            {
              id: '38546',
              type: 'studentsSkill',
            },
            {
              id: '38547',
              type: 'studentsSkill',
            },
            {
              id: '38548',
              type: 'studentsSkill',
            },
            {
              id: '38549',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1535',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4323',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4352',
      type: 'student',
      attributes: {
        fullName: 'Addison Mckillop',
        preferredName: 'Addy',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2015-10-20T04:00:00.000Z',
        ltsNumber: '2118981',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38576',
              type: 'studentsSkill',
            },
            {
              id: '38577',
              type: 'studentsSkill',
            },
            {
              id: '38580',
              type: 'studentsSkill',
            },
            {
              id: '38578',
              type: 'studentsSkill',
            },
            {
              id: '38573',
              type: 'studentsSkill',
            },
            {
              id: '38574',
              type: 'studentsSkill',
            },
            {
              id: '38579',
              type: 'studentsSkill',
            },
            {
              id: '38575',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56161',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1536',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4351',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4354',
      type: 'student',
      attributes: {
        fullName: 'Varvara Darovskikh',
        preferredName: 'Varia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2014-02-22T05:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38581',
              type: 'studentsSkill',
            },
            {
              id: '38582',
              type: 'studentsSkill',
            },
            {
              id: '38583',
              type: 'studentsSkill',
            },
            {
              id: '38584',
              type: 'studentsSkill',
            },
            {
              id: '38585',
              type: 'studentsSkill',
            },
            {
              id: '38586',
              type: 'studentsSkill',
            },
            {
              id: '38587',
              type: 'studentsSkill',
            },
            {
              id: '38588',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1537',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4353',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4356',
      type: 'student',
      attributes: {
        fullName: 'Chloe Williams',
        preferredName: 'Chloe',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2010-03-11T05:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42085',
              type: 'studentsSkill',
            },
            {
              id: '42086',
              type: 'studentsSkill',
            },
            {
              id: '42087',
              type: 'studentsSkill',
            },
            {
              id: '42088',
              type: 'studentsSkill',
            },
            {
              id: '42089',
              type: 'studentsSkill',
            },
            {
              id: '42090',
              type: 'studentsSkill',
            },
            {
              id: '42091',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1538',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4355',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4358',
      type: 'student',
      attributes: {
        fullName: 'Leafen Beaston',
        preferredName: 'Leafen',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-07-03T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38597',
              type: 'studentsSkill',
            },
            {
              id: '38598',
              type: 'studentsSkill',
            },
            {
              id: '38599',
              type: 'studentsSkill',
            },
            {
              id: '38600',
              type: 'studentsSkill',
            },
            {
              id: '38601',
              type: 'studentsSkill',
            },
            {
              id: '38602',
              type: 'studentsSkill',
            },
            {
              id: '38603',
              type: 'studentsSkill',
            },
            {
              id: '38604',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1539',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4357',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4363',
      type: 'student',
      attributes: {
        fullName: 'Jacqueline Deng',
        preferredName: 'Jackie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2017-01-14T05:00:00.000Z',
        ltsNumber: '2118983',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '40711',
              type: 'studentsSkill',
            },
            {
              id: '40712',
              type: 'studentsSkill',
            },
            {
              id: '40709',
              type: 'studentsSkill',
            },
            {
              id: '40707',
              type: 'studentsSkill',
            },
            {
              id: '40710',
              type: 'studentsSkill',
            },
            {
              id: '40708',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56160',
              type: 'klassDaysStudent',
            },
            {
              id: '56519',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1543',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '7',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4362',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4367',
      type: 'student',
      attributes: {
        fullName: 'Willow Fedoris',
        preferredName: 'Willow',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-10-08T04:00:00.000Z',
        ltsNumber: '1975510',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38637',
              type: 'studentsSkill',
            },
            {
              id: '38638',
              type: 'studentsSkill',
            },
            {
              id: '38639',
              type: 'studentsSkill',
            },
            {
              id: '38640',
              type: 'studentsSkill',
            },
            {
              id: '38641',
              type: 'studentsSkill',
            },
            {
              id: '38642',
              type: 'studentsSkill',
            },
            {
              id: '38643',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1546',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4042',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4379',
      type: 'student',
      attributes: {
        fullName: 'Zakariya Kamil',
        preferredName: 'Zeki',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2015-10-20T04:00:00.000Z',
        ltsNumber: '\t2124578',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38744',
              type: 'studentsSkill',
            },
            {
              id: '38745',
              type: 'studentsSkill',
            },
            {
              id: '38746',
              type: 'studentsSkill',
            },
            {
              id: '38748',
              type: 'studentsSkill',
            },
            {
              id: '38741',
              type: 'studentsSkill',
            },
            {
              id: '38742',
              type: 'studentsSkill',
            },
            {
              id: '38747',
              type: 'studentsSkill',
            },
            {
              id: '38743',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '54927',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1551',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4201',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4389',
      type: 'student',
      attributes: {
        fullName: 'Claire Jin',
        preferredName: 'Claire',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2016-11-09T05:00:00.000Z',
        ltsNumber: '2122165',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38808',
              type: 'studentsSkill',
            },
            {
              id: '38809',
              type: 'studentsSkill',
            },
            {
              id: '38810',
              type: 'studentsSkill',
            },
            {
              id: '38811',
              type: 'studentsSkill',
            },
            {
              id: '38812',
              type: 'studentsSkill',
            },
            {
              id: '38813',
              type: 'studentsSkill',
            },
            {
              id: '38814',
              type: 'studentsSkill',
            },
            {
              id: '38815',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1555',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4388',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4401',
      type: 'student',
      attributes: {
        fullName: 'Lola Rae Rodriguez',
        preferredName: 'Lola',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2015-04-07T04:00:00.000Z',
        ltsNumber: '2122174',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38869',
              type: 'studentsSkill',
            },
            {
              id: '38870',
              type: 'studentsSkill',
            },
            {
              id: '38865',
              type: 'studentsSkill',
            },
            {
              id: '38871',
              type: 'studentsSkill',
            },
            {
              id: '38867',
              type: 'studentsSkill',
            },
            {
              id: '38872',
              type: 'studentsSkill',
            },
            {
              id: '38868',
              type: 'studentsSkill',
            },
            {
              id: '38866',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56153',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1560',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4400',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4408',
      type: 'student',
      attributes: {
        fullName: 'Giuliana Stuccio',
        preferredName: 'Gigi',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2016-04-11T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '38924',
              type: 'studentsSkill',
            },
            {
              id: '38925',
              type: 'studentsSkill',
            },
            {
              id: '38926',
              type: 'studentsSkill',
            },
            {
              id: '38928',
              type: 'studentsSkill',
            },
            {
              id: '38921',
              type: 'studentsSkill',
            },
            {
              id: '38922',
              type: 'studentsSkill',
            },
            {
              id: '38927',
              type: 'studentsSkill',
            },
            {
              id: '38923',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56162',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1565',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4407',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4411',
      type: 'student',
      attributes: {
        fullName: 'Cláudia Marques',
        preferredName: 'Cláudia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1974-05-07T04:00:00.000Z',
        ltsNumber: '1409906',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39014',
              type: 'studentsSkill',
            },
            {
              id: '39015',
              type: 'studentsSkill',
            },
            {
              id: '39016',
              type: 'studentsSkill',
            },
            {
              id: '39017',
              type: 'studentsSkill',
            },
            {
              id: '39018',
              type: 'studentsSkill',
            },
            {
              id: '39019',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1567',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '21',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '3955',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4413',
      type: 'student',
      attributes: {
        fullName: 'Venus Rosario Gerena',
        preferredName: 'Venus',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1996-11-16T05:00:00.000Z',
        ltsNumber: '2127455',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39025',
              type: 'studentsSkill',
            },
            {
              id: '39026',
              type: 'studentsSkill',
            },
            {
              id: '39020',
              type: 'studentsSkill',
            },
            {
              id: '39022',
              type: 'studentsSkill',
            },
            {
              id: '39023',
              type: 'studentsSkill',
            },
            {
              id: '39024',
              type: 'studentsSkill',
            },
            {
              id: '39021',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56425',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1568',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4412',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4425',
      type: 'student',
      attributes: {
        fullName: 'Shuyuan Tian',
        preferredName: 'Shuyuan or Michelle',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1985-09-01T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39155',
              type: 'studentsSkill',
            },
            {
              id: '39156',
              type: 'studentsSkill',
            },
            {
              id: '39157',
              type: 'studentsSkill',
            },
            {
              id: '39158',
              type: 'studentsSkill',
            },
            {
              id: '39159',
              type: 'studentsSkill',
            },
            {
              id: '39160',
              type: 'studentsSkill',
            },
            {
              id: '39161',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1573',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4423',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4429',
      type: 'student',
      attributes: {
        fullName: 'Liam Sawyer',
        preferredName: 'Liam',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2015-06-16T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39212',
              type: 'studentsSkill',
            },
            {
              id: '39213',
              type: 'studentsSkill',
            },
            {
              id: '39214',
              type: 'studentsSkill',
            },
            {
              id: '39215',
              type: 'studentsSkill',
            },
            {
              id: '39216',
              type: 'studentsSkill',
            },
            {
              id: '39217',
              type: 'studentsSkill',
            },
            {
              id: '39218',
              type: 'studentsSkill',
            },
            {
              id: '39219',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1574',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4428',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4431',
      type: 'student',
      attributes: {
        fullName: 'Shuang Liang',
        preferredName: 'Shuang',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1988-11-01T05:00:00.000Z',
        ltsNumber: '2122483',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39274',
              type: 'studentsSkill',
            },
            {
              id: '39275',
              type: 'studentsSkill',
            },
            {
              id: '39276',
              type: 'studentsSkill',
            },
            {
              id: '39277',
              type: 'studentsSkill',
            },
            {
              id: '39278',
              type: 'studentsSkill',
            },
            {
              id: '39279',
              type: 'studentsSkill',
            },
            {
              id: '39280',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56746',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1575',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4362',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4432',
      type: 'student',
      attributes: {
        fullName: 'Alex Wang',
        preferredName: 'Alex',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2017-08-22T04:00:00.000Z',
        ltsNumber: '2122475',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '40689',
              type: 'studentsSkill',
            },
            {
              id: '40690',
              type: 'studentsSkill',
            },
            {
              id: '40691',
              type: 'studentsSkill',
            },
            {
              id: '40693',
              type: 'studentsSkill',
            },
            {
              id: '40694',
              type: 'studentsSkill',
            },
            {
              id: '40692',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56157',
              type: 'klassDaysStudent',
            },
            {
              id: '56516',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1576',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '7',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4430',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4447',
      type: 'student',
      attributes: {
        fullName: 'Liyana Taha',
        preferredName: 'Lilly',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2016-06-27T04:00:00.000Z',
        ltsNumber: '2122535',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39400',
              type: 'studentsSkill',
            },
            {
              id: '39401',
              type: 'studentsSkill',
            },
            {
              id: '39402',
              type: 'studentsSkill',
            },
            {
              id: '39404',
              type: 'studentsSkill',
            },
            {
              id: '39397',
              type: 'studentsSkill',
            },
            {
              id: '39398',
              type: 'studentsSkill',
            },
            {
              id: '39403',
              type: 'studentsSkill',
            },
            {
              id: '39399',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '55229',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1582',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4446',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4451',
      type: 'student',
      attributes: {
        fullName: 'Isaac Dettrey',
        preferredName: 'Isaac',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2010-05-14T04:00:00.000Z',
        ltsNumber: '2122536',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39414',
              type: 'studentsSkill',
            },
            {
              id: '39415',
              type: 'studentsSkill',
            },
            {
              id: '39420',
              type: 'studentsSkill',
            },
            {
              id: '39416',
              type: 'studentsSkill',
            },
            {
              id: '39421',
              type: 'studentsSkill',
            },
            {
              id: '39417',
              type: 'studentsSkill',
            },
            {
              id: '39418',
              type: 'studentsSkill',
            },
            {
              id: '39419',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57544',
              type: 'klassDaysStudent',
            },
            {
              id: '57596',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1584',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4450',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4453',
      type: 'student',
      attributes: {
        fullName: 'Etta Dettrey',
        preferredName: 'Etta',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-08-03T04:00:00.000Z',
        ltsNumber: '2122537',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39436',
              type: 'studentsSkill',
            },
            {
              id: '39430',
              type: 'studentsSkill',
            },
            {
              id: '39432',
              type: 'studentsSkill',
            },
            {
              id: '39437',
              type: 'studentsSkill',
            },
            {
              id: '39433',
              type: 'studentsSkill',
            },
            {
              id: '39435',
              type: 'studentsSkill',
            },
            {
              id: '39434',
              type: 'studentsSkill',
            },
            {
              id: '39431',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57546',
              type: 'klassDaysStudent',
            },
            {
              id: '57598',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1585',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4450',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4466',
      type: 'student',
      attributes: {
        fullName: 'Anny Yan',
        preferredName: 'Anny',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2009-03-20T04:00:00.000Z',
        ltsNumber: '2122542',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39680',
              type: 'studentsSkill',
            },
            {
              id: '39686',
              type: 'studentsSkill',
            },
            {
              id: '39685',
              type: 'studentsSkill',
            },
            {
              id: '39681',
              type: 'studentsSkill',
            },
            {
              id: '39682',
              type: 'studentsSkill',
            },
            {
              id: '39687',
              type: 'studentsSkill',
            },
            {
              id: '39683',
              type: 'studentsSkill',
            },
            {
              id: '39684',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57541',
              type: 'klassDaysStudent',
            },
            {
              id: '57593',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1589',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4465',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4467',
      type: 'student',
      attributes: {
        fullName: 'Chris Salvato',
        preferredName: 'Chris',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2012-02-08T07:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39696',
              type: 'studentsSkill',
            },
            {
              id: '39697',
              type: 'studentsSkill',
            },
            {
              id: '39698',
              type: 'studentsSkill',
            },
            {
              id: '39699',
              type: 'studentsSkill',
            },
            {
              id: '39700',
              type: 'studentsSkill',
            },
            {
              id: '39701',
              type: 'studentsSkill',
            },
            {
              id: '39702',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1590',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '268',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4472',
      type: 'student',
      attributes: {
        fullName: 'Nicholas Sadej',
        preferredName: 'Nicholas',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2017-01-01T05:00:00.000Z',
        ltsNumber: '2126946',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '40416',
              type: 'studentsSkill',
            },
            {
              id: '40417',
              type: 'studentsSkill',
            },
            {
              id: '40414',
              type: 'studentsSkill',
            },
            {
              id: '40412',
              type: 'studentsSkill',
            },
            {
              id: '40415',
              type: 'studentsSkill',
            },
            {
              id: '40413',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56136',
              type: 'klassDaysStudent',
            },
            {
              id: '56515',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1594',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '7',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4276',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4493',
      type: 'student',
      attributes: {
        fullName: 'Donald Levinson',
        preferredName: 'Donald',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2007-09-27T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39783',
              type: 'studentsSkill',
            },
            {
              id: '39784',
              type: 'studentsSkill',
            },
            {
              id: '39785',
              type: 'studentsSkill',
            },
            {
              id: '39786',
              type: 'studentsSkill',
            },
            {
              id: '39787',
              type: 'studentsSkill',
            },
            {
              id: '39788',
              type: 'studentsSkill',
            },
            {
              id: '39789',
              type: 'studentsSkill',
            },
            {
              id: '39790',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1603',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4492',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4494',
      type: 'student',
      attributes: {
        fullName: 'Delilah Levinson',
        preferredName: 'Delilah',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2007-09-27T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39791',
              type: 'studentsSkill',
            },
            {
              id: '39792',
              type: 'studentsSkill',
            },
            {
              id: '39793',
              type: 'studentsSkill',
            },
            {
              id: '39794',
              type: 'studentsSkill',
            },
            {
              id: '39795',
              type: 'studentsSkill',
            },
            {
              id: '39796',
              type: 'studentsSkill',
            },
            {
              id: '39797',
              type: 'studentsSkill',
            },
            {
              id: '39798',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1603',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4492',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4495',
      type: 'student',
      attributes: {
        fullName: 'Dahlia Levinson',
        preferredName: 'Dahlia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2008-09-26T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39799',
              type: 'studentsSkill',
            },
            {
              id: '39800',
              type: 'studentsSkill',
            },
            {
              id: '39801',
              type: 'studentsSkill',
            },
            {
              id: '39802',
              type: 'studentsSkill',
            },
            {
              id: '39803',
              type: 'studentsSkill',
            },
            {
              id: '39804',
              type: 'studentsSkill',
            },
            {
              id: '39805',
              type: 'studentsSkill',
            },
            {
              id: '39806',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1603',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4492',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4496',
      type: 'student',
      attributes: {
        fullName: 'Dannah Levinson',
        preferredName: 'Dannah',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-04-09T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39807',
              type: 'studentsSkill',
            },
            {
              id: '39808',
              type: 'studentsSkill',
            },
            {
              id: '39809',
              type: 'studentsSkill',
            },
            {
              id: '39810',
              type: 'studentsSkill',
            },
            {
              id: '39811',
              type: 'studentsSkill',
            },
            {
              id: '39812',
              type: 'studentsSkill',
            },
            {
              id: '39813',
              type: 'studentsSkill',
            },
            {
              id: '39814',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1603',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4492',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4514',
      type: 'student',
      attributes: {
        fullName: 'Alexandra Vergason',
        preferredName: 'Allie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2015-05-30T04:00:00.000Z',
        ltsNumber: '2126961',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39888',
              type: 'studentsSkill',
            },
            {
              id: '39889',
              type: 'studentsSkill',
            },
            {
              id: '39885',
              type: 'studentsSkill',
            },
            {
              id: '39890',
              type: 'studentsSkill',
            },
            {
              id: '39886',
              type: 'studentsSkill',
            },
            {
              id: '39891',
              type: 'studentsSkill',
            },
            {
              id: '39887',
              type: 'studentsSkill',
            },
            {
              id: '39884',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57476',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1610',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4513',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4531',
      type: 'student',
      attributes: {
        fullName: 'Aran Zebari',
        preferredName: 'Aran',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2016-04-20T04:00:00.000Z',
        ltsNumber: '2126956',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39969',
              type: 'studentsSkill',
            },
            {
              id: '39970',
              type: 'studentsSkill',
            },
            {
              id: '39971',
              type: 'studentsSkill',
            },
            {
              id: '39973',
              type: 'studentsSkill',
            },
            {
              id: '39966',
              type: 'studentsSkill',
            },
            {
              id: '39967',
              type: 'studentsSkill',
            },
            {
              id: '39972',
              type: 'studentsSkill',
            },
            {
              id: '39968',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56079',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1618',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4530',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4534',
      type: 'student',
      attributes: {
        fullName: 'Teagan Pitman',
        preferredName: 'Teagan',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2011-02-03T05:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39982',
              type: 'studentsSkill',
            },
            {
              id: '39983',
              type: 'studentsSkill',
            },
            {
              id: '39984',
              type: 'studentsSkill',
            },
            {
              id: '39985',
              type: 'studentsSkill',
            },
            {
              id: '39986',
              type: 'studentsSkill',
            },
            {
              id: '39987',
              type: 'studentsSkill',
            },
            {
              id: '39988',
              type: 'studentsSkill',
            },
            {
              id: '39989',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1620',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4533',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4535',
      type: 'student',
      attributes: {
        fullName: 'Killian Pitman',
        preferredName: 'Killian',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2016-08-20T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '39990',
              type: 'studentsSkill',
            },
            {
              id: '39991',
              type: 'studentsSkill',
            },
            {
              id: '39992',
              type: 'studentsSkill',
            },
            {
              id: '39993',
              type: 'studentsSkill',
            },
            {
              id: '39994',
              type: 'studentsSkill',
            },
            {
              id: '39995',
              type: 'studentsSkill',
            },
            {
              id: '39996',
              type: 'studentsSkill',
            },
            {
              id: '39997',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1620',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4533',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4582',
      type: 'student',
      attributes: {
        fullName: 'Jessie',
        preferredName: 'Jessie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1984-06-12T04:00:00.000Z',
        ltsNumber: '2122698',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '40300',
              type: 'studentsSkill',
            },
            {
              id: '40301',
              type: 'studentsSkill',
            },
            {
              id: '40302',
              type: 'studentsSkill',
            },
            {
              id: '40303',
              type: 'studentsSkill',
            },
            {
              id: '40304',
              type: 'studentsSkill',
            },
            {
              id: '40305',
              type: 'studentsSkill',
            },
            {
              id: '40306',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1555',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4388',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4634',
      type: 'student',
      attributes: {
        fullName: 'Madelyn Raway',
        preferredName: 'Madelyn',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2017-01-02T05:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '40674',
              type: 'studentsSkill',
            },
            {
              id: '40675',
              type: 'studentsSkill',
            },
            {
              id: '40676',
              type: 'studentsSkill',
            },
            {
              id: '40677',
              type: 'studentsSkill',
            },
            {
              id: '40678',
              type: 'studentsSkill',
            },
            {
              id: '40679',
              type: 'studentsSkill',
            },
            {
              id: '40680',
              type: 'studentsSkill',
            },
            {
              id: '40681',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1655',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4633',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4636',
      type: 'student',
      attributes: {
        fullName: 'Wajiha Ali',
        preferredName: 'Jia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1900-10-30T05:00:00.000Z',
        ltsNumber: '2126967',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '40682',
              type: 'studentsSkill',
            },
            {
              id: '40683',
              type: 'studentsSkill',
            },
            {
              id: '40684',
              type: 'studentsSkill',
            },
            {
              id: '40685',
              type: 'studentsSkill',
            },
            {
              id: '40686',
              type: 'studentsSkill',
            },
            {
              id: '40687',
              type: 'studentsSkill',
            },
            {
              id: '40688',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56422',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1656',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4635',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4640',
      type: 'student',
      attributes: {
        fullName: 'Lenaura Anna Leigha Starr',
        preferredName: 'Lani Starr',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2011-06-21T04:00:00.000Z',
        ltsNumber: 'I dont know',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '40736',
              type: 'studentsSkill',
            },
            {
              id: '40737',
              type: 'studentsSkill',
            },
            {
              id: '40738',
              type: 'studentsSkill',
            },
            {
              id: '40739',
              type: 'studentsSkill',
            },
            {
              id: '40740',
              type: 'studentsSkill',
            },
            {
              id: '40741',
              type: 'studentsSkill',
            },
            {
              id: '40742',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1658',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '5',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4639',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4668',
      type: 'student',
      attributes: {
        fullName: 'Madelyn Walker',
        preferredName: 'Madwlyn',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2014-01-15T05:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '40927',
              type: 'studentsSkill',
            },
            {
              id: '40928',
              type: 'studentsSkill',
            },
            {
              id: '40929',
              type: 'studentsSkill',
            },
            {
              id: '40930',
              type: 'studentsSkill',
            },
            {
              id: '40931',
              type: 'studentsSkill',
            },
            {
              id: '40932',
              type: 'studentsSkill',
            },
            {
              id: '40933',
              type: 'studentsSkill',
            },
            {
              id: '40934',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1670',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4320',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4713',
      type: 'student',
      attributes: {
        fullName: 'Beverly Rauscher',
        preferredName: 'Bevy',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-05-25T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41293',
              type: 'studentsSkill',
            },
            {
              id: '41294',
              type: 'studentsSkill',
            },
            {
              id: '41295',
              type: 'studentsSkill',
            },
            {
              id: '41296',
              type: 'studentsSkill',
            },
            {
              id: '41297',
              type: 'studentsSkill',
            },
            {
              id: '41298',
              type: 'studentsSkill',
            },
            {
              id: '41299',
              type: 'studentsSkill',
            },
            {
              id: '41300',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1685',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4150',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4730',
      type: 'student',
      attributes: {
        fullName: 'Ava Slicklen',
        preferredName: 'Ava',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-06-29T04:00:00.000Z',
        ltsNumber: '2127837',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41371',
              type: 'studentsSkill',
            },
            {
              id: '41364',
              type: 'studentsSkill',
            },
            {
              id: '41367',
              type: 'studentsSkill',
            },
            {
              id: '41368',
              type: 'studentsSkill',
            },
            {
              id: '41369',
              type: 'studentsSkill',
            },
            {
              id: '41366',
              type: 'studentsSkill',
            },
            {
              id: '41365',
              type: 'studentsSkill',
            },
            {
              id: '41370',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57548',
              type: 'klassDaysStudent',
            },
            {
              id: '57600',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1693',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4729',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4731',
      type: 'student',
      attributes: {
        fullName: 'Evan Zheng',
        preferredName: 'Evan',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2012-10-12T04:00:00.000Z',
        ltsNumber: '',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42106',
              type: 'studentsSkill',
            },
            {
              id: '42107',
              type: 'studentsSkill',
            },
            {
              id: '42109',
              type: 'studentsSkill',
            },
            {
              id: '42110',
              type: 'studentsSkill',
            },
            {
              id: '42111',
              type: 'studentsSkill',
            },
            {
              id: '42112',
              type: 'studentsSkill',
            },
            {
              id: '42108',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57566',
              type: 'klassDaysStudent',
            },
            {
              id: '57619',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1554',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4382',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4732',
      type: 'student',
      attributes: {
        fullName: 'Lucas Petersen',
        preferredName: 'Lucas',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2015-08-08T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41388',
              type: 'studentsSkill',
            },
            {
              id: '41389',
              type: 'studentsSkill',
            },
            {
              id: '41390',
              type: 'studentsSkill',
            },
            {
              id: '41392',
              type: 'studentsSkill',
            },
            {
              id: '41385',
              type: 'studentsSkill',
            },
            {
              id: '41386',
              type: 'studentsSkill',
            },
            {
              id: '41391',
              type: 'studentsSkill',
            },
            {
              id: '41387',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57396',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1694',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4271',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4734',
      type: 'student',
      attributes: {
        fullName: 'Grace Donahue',
        preferredName: 'Grace',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2014-03-11T04:00:00.000Z',
        ltsNumber: '2128021',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41397',
              type: 'studentsSkill',
            },
            {
              id: '41398',
              type: 'studentsSkill',
            },
            {
              id: '41394',
              type: 'studentsSkill',
            },
            {
              id: '41399',
              type: 'studentsSkill',
            },
            {
              id: '41395',
              type: 'studentsSkill',
            },
            {
              id: '41393',
              type: 'studentsSkill',
            },
            {
              id: '41400',
              type: 'studentsSkill',
            },
            {
              id: '41396',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '56822',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1695',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4733',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4748',
      type: 'student',
      attributes: {
        fullName: 'Charlize Gamble',
        preferredName: 'charlize',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2006-03-22T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41466',
              type: 'studentsSkill',
            },
            {
              id: '41467',
              type: 'studentsSkill',
            },
            {
              id: '41468',
              type: 'studentsSkill',
            },
            {
              id: '41469',
              type: 'studentsSkill',
            },
            {
              id: '41470',
              type: 'studentsSkill',
            },
            {
              id: '41471',
              type: 'studentsSkill',
            },
            {
              id: '41472',
              type: 'studentsSkill',
            },
            {
              id: '41473',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1700',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4746',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4757',
      type: 'student',
      attributes: {
        fullName: 'Eamon Lafond',
        preferredName: 'Eamon',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2012-02-09T05:00:00.000Z',
        ltsNumber: '2128040',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41571',
              type: 'studentsSkill',
            },
            {
              id: '41570',
              type: 'studentsSkill',
            },
            {
              id: '41564',
              type: 'studentsSkill',
            },
            {
              id: '41566',
              type: 'studentsSkill',
            },
            {
              id: '41567',
              type: 'studentsSkill',
            },
            {
              id: '41569',
              type: 'studentsSkill',
            },
            {
              id: '41565',
              type: 'studentsSkill',
            },
            {
              id: '41568',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57543',
              type: 'klassDaysStudent',
            },
            {
              id: '57595',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1704',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4756',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4769',
      type: 'student',
      attributes: {
        fullName: 'Wilson Lin',
        preferredName: 'Wilson',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2013-09-29T04:00:00.000Z',
        ltsNumber: '',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41813',
              type: 'studentsSkill',
            },
            {
              id: '41814',
              type: 'studentsSkill',
            },
            {
              id: '41815',
              type: 'studentsSkill',
            },
            {
              id: '41816',
              type: 'studentsSkill',
            },
            {
              id: '41817',
              type: 'studentsSkill',
            },
            {
              id: '41818',
              type: 'studentsSkill',
            },
            {
              id: '41819',
              type: 'studentsSkill',
            },
            {
              id: '41820',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57394',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1554',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4382',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4772',
      type: 'student',
      attributes: {
        fullName: 'Charles Cheng',
        preferredName: 'Charles',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2016-03-03T05:00:00.000Z',
        ltsNumber: '',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41869',
              type: 'studentsSkill',
            },
            {
              id: '41870',
              type: 'studentsSkill',
            },
            {
              id: '41871',
              type: 'studentsSkill',
            },
            {
              id: '41873',
              type: 'studentsSkill',
            },
            {
              id: '41867',
              type: 'studentsSkill',
            },
            {
              id: '41872',
              type: 'studentsSkill',
            },
            {
              id: '41868',
              type: 'studentsSkill',
            },
            {
              id: '41866',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57391',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1710',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4275',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4787',
      type: 'student',
      attributes: {
        fullName: 'Isabella Ruffo',
        preferredName: 'Isabella or Izzy',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-10-03T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42081',
              type: 'studentsSkill',
            },
            {
              id: '42083',
              type: 'studentsSkill',
            },
            {
              id: '42078',
              type: 'studentsSkill',
            },
            {
              id: '42080',
              type: 'studentsSkill',
            },
            {
              id: '42079',
              type: 'studentsSkill',
            },
            {
              id: '42082',
              type: 'studentsSkill',
            },
            {
              id: '42084',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57449',
              type: 'klassDaysStudent',
            },
            {
              id: '57450',
              type: 'klassDaysStudent',
            },
            {
              id: '57561',
              type: 'klassDaysStudent',
            },
            {
              id: '57614',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1715',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4786',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4791',
      type: 'student',
      attributes: {
        fullName: 'Chloe Williams',
        preferredName: 'Chloe',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2010-03-11T05:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41910',
              type: 'studentsSkill',
            },
            {
              id: '41905',
              type: 'studentsSkill',
            },
            {
              id: '41911',
              type: 'studentsSkill',
            },
            {
              id: '41906',
              type: 'studentsSkill',
            },
            {
              id: '41907',
              type: 'studentsSkill',
            },
            {
              id: '41908',
              type: 'studentsSkill',
            },
            {
              id: '41909',
              type: 'studentsSkill',
            },
            {
              id: '41904',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57545',
              type: 'klassDaysStudent',
            },
            {
              id: '57597',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1538',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4790',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4792',
      type: 'student',
      attributes: {
        fullName: 'Fiona Williams',
        preferredName: 'Fiona',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2013-07-30T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41915',
              type: 'studentsSkill',
            },
            {
              id: '41916',
              type: 'studentsSkill',
            },
            {
              id: '41917',
              type: 'studentsSkill',
            },
            {
              id: '41919',
              type: 'studentsSkill',
            },
            {
              id: '41912',
              type: 'studentsSkill',
            },
            {
              id: '41913',
              type: 'studentsSkill',
            },
            {
              id: '41918',
              type: 'studentsSkill',
            },
            {
              id: '41914',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57547',
              type: 'klassDaysStudent',
            },
            {
              id: '57599',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1538',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4790',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4794',
      type: 'student',
      attributes: {
        fullName: 'Milania Michalko',
        preferredName: 'Millie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2011-09-29T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42102',
              type: 'studentsSkill',
            },
            {
              id: '42104',
              type: 'studentsSkill',
            },
            {
              id: '42105',
              type: 'studentsSkill',
            },
            {
              id: '42101',
              type: 'studentsSkill',
            },
            {
              id: '42100',
              type: 'studentsSkill',
            },
            {
              id: '42103',
              type: 'studentsSkill',
            },
            {
              id: '42099',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57565',
              type: 'klassDaysStudent',
            },
            {
              id: '57618',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1717',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4793',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4803',
      type: 'student',
      attributes: {
        fullName: 'Channell-Semira',
        preferredName: 'Semira',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1995-11-30T05:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '41959',
              type: 'studentsSkill',
            },
            {
              id: '41960',
              type: 'studentsSkill',
            },
            {
              id: '41961',
              type: 'studentsSkill',
            },
            {
              id: '41962',
              type: 'studentsSkill',
            },
            {
              id: '41963',
              type: 'studentsSkill',
            },
            {
              id: '41964',
              type: 'studentsSkill',
            },
            {
              id: '41965',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1720',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4802',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4817',
      type: 'student',
      attributes: {
        fullName: 'Emilie Lewis',
        preferredName: 'Emilie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2017-02-13T05:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42113',
              type: 'studentsSkill',
            },
            {
              id: '42114',
              type: 'studentsSkill',
            },
            {
              id: '42115',
              type: 'studentsSkill',
            },
            {
              id: '42116',
              type: 'studentsSkill',
            },
            {
              id: '42117',
              type: 'studentsSkill',
            },
            {
              id: '42118',
              type: 'studentsSkill',
            },
            {
              id: '42119',
              type: 'studentsSkill',
            },
            {
              id: '42120',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1725',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4816',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4821',
      type: 'student',
      attributes: {
        fullName: 'Bella Forsberg',
        preferredName: 'Bella',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2014-12-31T05:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42141',
              type: 'studentsSkill',
            },
            {
              id: '42142',
              type: 'studentsSkill',
            },
            {
              id: '42137',
              type: 'studentsSkill',
            },
            {
              id: '42138',
              type: 'studentsSkill',
            },
            {
              id: '42143',
              type: 'studentsSkill',
            },
            {
              id: '42139',
              type: 'studentsSkill',
            },
            {
              id: '42144',
              type: 'studentsSkill',
            },
            {
              id: '42140',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57475',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1727',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4820',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4830',
      type: 'student',
      attributes: {
        fullName: 'Margot Mead',
        preferredName: 'Margot',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2016-09-03T04:00:00.000Z',
        ltsNumber: '',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42180',
              type: 'studentsSkill',
            },
            {
              id: '42181',
              type: 'studentsSkill',
            },
            {
              id: '42182',
              type: 'studentsSkill',
            },
            {
              id: '42184',
              type: 'studentsSkill',
            },
            {
              id: '42178',
              type: 'studentsSkill',
            },
            {
              id: '42183',
              type: 'studentsSkill',
            },
            {
              id: '42179',
              type: 'studentsSkill',
            },
            {
              id: '42177',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57474',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1731',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4069',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4831',
      type: 'student',
      attributes: {
        fullName: 'Margot Mead',
        preferredName: 'Margot',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2016-04-27T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42185',
              type: 'studentsSkill',
            },
            {
              id: '42186',
              type: 'studentsSkill',
            },
            {
              id: '42187',
              type: 'studentsSkill',
            },
            {
              id: '42188',
              type: 'studentsSkill',
            },
            {
              id: '42189',
              type: 'studentsSkill',
            },
            {
              id: '42190',
              type: 'studentsSkill',
            },
            {
              id: '42191',
              type: 'studentsSkill',
            },
            {
              id: '42192',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57549',
              type: 'klassDaysStudent',
            },
            {
              id: '57601',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1365',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4069',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4846',
      type: 'student',
      attributes: {
        fullName: 'Jaden Valek',
        preferredName: 'Jaden',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2007-11-05T05:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42280',
              type: 'studentsSkill',
            },
            {
              id: '42281',
              type: 'studentsSkill',
            },
            {
              id: '42282',
              type: 'studentsSkill',
            },
            {
              id: '42283',
              type: 'studentsSkill',
            },
            {
              id: '42284',
              type: 'studentsSkill',
            },
            {
              id: '42285',
              type: 'studentsSkill',
            },
            {
              id: '42286',
              type: 'studentsSkill',
            },
            {
              id: '42287',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1736',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4845',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4847',
      type: 'student',
      attributes: {
        fullName: 'Kian Valek',
        preferredName: 'Kian',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2010-03-25T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42288',
              type: 'studentsSkill',
            },
            {
              id: '42289',
              type: 'studentsSkill',
            },
            {
              id: '42290',
              type: 'studentsSkill',
            },
            {
              id: '42291',
              type: 'studentsSkill',
            },
            {
              id: '42292',
              type: 'studentsSkill',
            },
            {
              id: '42293',
              type: 'studentsSkill',
            },
            {
              id: '42294',
              type: 'studentsSkill',
            },
            {
              id: '42295',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1736',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4845',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4848',
      type: 'student',
      attributes: {
        fullName: 'Saige Valek',
        preferredName: 'Saige',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2012-06-19T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42296',
              type: 'studentsSkill',
            },
            {
              id: '42297',
              type: 'studentsSkill',
            },
            {
              id: '42298',
              type: 'studentsSkill',
            },
            {
              id: '42299',
              type: 'studentsSkill',
            },
            {
              id: '42300',
              type: 'studentsSkill',
            },
            {
              id: '42301',
              type: 'studentsSkill',
            },
            {
              id: '42302',
              type: 'studentsSkill',
            },
            {
              id: '42303',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1736',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4845',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4849',
      type: 'student',
      attributes: {
        fullName: 'Dustin Slicklen',
        preferredName: 'Dustin',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1984-12-28T05:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42304',
              type: 'studentsSkill',
            },
            {
              id: '42305',
              type: 'studentsSkill',
            },
            {
              id: '42306',
              type: 'studentsSkill',
            },
            {
              id: '42307',
              type: 'studentsSkill',
            },
            {
              id: '42308',
              type: 'studentsSkill',
            },
            {
              id: '42309',
              type: 'studentsSkill',
            },
            {
              id: '42310',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1737',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [],
        },
      },
    },
    {
      id: '4851',
      type: 'student',
      attributes: {
        fullName: 'Neil Stevenson',
        preferredName: 'Neil',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '1970-07-12T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42311',
              type: 'studentsSkill',
            },
            {
              id: '42312',
              type: 'studentsSkill',
            },
            {
              id: '42313',
              type: 'studentsSkill',
            },
            {
              id: '42314',
              type: 'studentsSkill',
            },
            {
              id: '42315',
              type: 'studentsSkill',
            },
            {
              id: '42316',
              type: 'studentsSkill',
            },
            {
              id: '42317',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [
            {
              id: '57571',
              type: 'klassDaysStudent',
            },
          ],
        },
        address: {
          data: {
            id: '1738',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '18',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4850',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4855',
      type: 'student',
      attributes: {
        fullName: 'Maura Egbers',
        preferredName: 'Maura',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2014-05-01T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42357',
              type: 'studentsSkill',
            },
            {
              id: '42358',
              type: 'studentsSkill',
            },
            {
              id: '42359',
              type: 'studentsSkill',
            },
            {
              id: '42360',
              type: 'studentsSkill',
            },
            {
              id: '42361',
              type: 'studentsSkill',
            },
            {
              id: '42362',
              type: 'studentsSkill',
            },
            {
              id: '42363',
              type: 'studentsSkill',
            },
            {
              id: '42364',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1740',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4854',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4856',
      type: 'student',
      attributes: {
        fullName: 'Ann Egbers',
        preferredName: 'Annie',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2016-03-09T05:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42365',
              type: 'studentsSkill',
            },
            {
              id: '42366',
              type: 'studentsSkill',
            },
            {
              id: '42367',
              type: 'studentsSkill',
            },
            {
              id: '42368',
              type: 'studentsSkill',
            },
            {
              id: '42369',
              type: 'studentsSkill',
            },
            {
              id: '42370',
              type: 'studentsSkill',
            },
            {
              id: '42371',
              type: 'studentsSkill',
            },
            {
              id: '42372',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1740',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4854',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4860',
      type: 'student',
      attributes: {
        fullName: 'Lily Pradun',
        preferredName: 'Lily',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '2017-09-05T04:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42383',
              type: 'studentsSkill',
            },
            {
              id: '42384',
              type: 'studentsSkill',
            },
            {
              id: '42385',
              type: 'studentsSkill',
            },
            {
              id: '42386',
              type: 'studentsSkill',
            },
            {
              id: '42387',
              type: 'studentsSkill',
            },
            {
              id: '42388',
              type: 'studentsSkill',
            },
            {
              id: '42389',
              type: 'studentsSkill',
            },
            {
              id: '42390',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1742',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4859',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4863',
      type: 'student',
      attributes: {
        fullName: 'Ksenia Koroleva',
        preferredName: 'Ksenia',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'female',
        birthdate: '1990-04-13T04:00:00.000Z',
        ltsNumber: '2029577',
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42398',
              type: 'studentsSkill',
            },
            {
              id: '42399',
              type: 'studentsSkill',
            },
            {
              id: '42400',
              type: 'studentsSkill',
            },
            {
              id: '42401',
              type: 'studentsSkill',
            },
            {
              id: '42402',
              type: 'studentsSkill',
            },
            {
              id: '42403',
              type: 'studentsSkill',
            },
            {
              id: '42404',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1744',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '12',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4242',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4880',
      type: 'student',
      attributes: {
        fullName: 'Michael Scott',
        preferredName: 'Mike',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2010-12-27T07:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42509',
              type: 'studentsSkill',
            },
            {
              id: '42510',
              type: 'studentsSkill',
            },
            {
              id: '42511',
              type: 'studentsSkill',
            },
            {
              id: '42512',
              type: 'studentsSkill',
            },
            {
              id: '42513',
              type: 'studentsSkill',
            },
            {
              id: '42514',
              type: 'studentsSkill',
            },
            {
              id: '42515',
              type: 'studentsSkill',
            },
            {
              id: '42516',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1753',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4878',
              type: 'user',
            },
          ],
        },
      },
    },
    {
      id: '4882',
      type: 'student',
      attributes: {
        fullName: 'Fly Zapper',
        preferredName: 'Fly',
        email: null,
        cloudinaryPhotoPublicId: null,
        gender: 'male',
        birthdate: '2014-04-08T06:00:00.000Z',
        ltsNumber: null,
        registered: false,
      },
      relationships: {
        studentsSkills: {
          data: [
            {
              id: '42517',
              type: 'studentsSkill',
            },
            {
              id: '42518',
              type: 'studentsSkill',
            },
            {
              id: '42519',
              type: 'studentsSkill',
            },
            {
              id: '42520',
              type: 'studentsSkill',
            },
            {
              id: '42521',
              type: 'studentsSkill',
            },
            {
              id: '42522',
              type: 'studentsSkill',
            },
            {
              id: '42523',
              type: 'studentsSkill',
            },
            {
              id: '42524',
              type: 'studentsSkill',
            },
          ],
        },
        klassDaysStudents: {
          data: [],
        },
        address: {
          data: {
            id: '1753',
            type: 'address',
          },
        },
        currentSkillLevel: {
          data: {
            id: '1',
            type: 'skillLevel',
          },
        },
        guardians: {
          data: [
            {
              id: '4878',
              type: 'user',
            },
          ],
        },
      },
    },
  ],
}
