import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CheckboxInputCompact from '../CheckboxInputCompact/CheckboxInputCompact'

function SmallRadioInput({
  id,
  label,
  className,
  errorMessage,
  value,
  onChange,
  name,
  helpText,
  options,
}) {
  return (
    <div
      name={name}
      className={className}
      value={value}
      data-cy={`${id}-input`}
    >
      {label && (
        <label
          className={`${classNames({
            'text-red-500': errorMessage,
          })} block text-gray-700 text-sm font-medium mb-1 ml-1`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      {helpText && <p className="mb-2 text-xs font-light">{helpText}</p>}
      <p className="mb-3 text-xs italic text-red-500">{errorMessage}</p>
      <div className="flex flex-col space-y-2 ml-2">
        {options.map((option) => (
          <CheckboxInputCompact
            key={option.value}
            id={option.value}
            label={option.label}
            value={option.value}
            onClick={(e) => onChange(e.target.value)}
            checked={value === option.value}
          />
        ))}
      </div>
    </div>
  )
}

SmallRadioInput.default = {
  className: '',
  errorMessage: '',
}

SmallRadioInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  helpText: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      checked: PropTypes.string.bool,
    })
  ).isRequired,
}

export default SmallRadioInput
