import React from 'react'
import { ChatAlt2Icon } from '@heroicons/react/outline'
import PropTypes from 'prop-types'

import mixpanel from '../../../utilities/mixpanel'
import { sendCrispMessage, openCrispChat } from '../../../utilities/crisp'

function SimpleJustifiedCTA({ headline, subheadline, showTrialButton }) {
  return (
    <div className="bg-gray-50 dark:bg-gray-800 dark:opacity-95">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-gray-300 sm:text-4xl">
          <span className="block">{headline}</span>
          <span className="block text-purple-700 dark:text-purple-500">
            {subheadline}
          </span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 space-x-3">
          {showTrialButton && (
            <div className="inline-flex rounded-md shadow">
              <button
                type="button"
                onClick={() => {
                  sendCrispMessage(
                    "I'm ready to start my free trial. What are the next steps?"
                  )
                  mixpanel.track('Clicked Start Free Trial')
                }}
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent dark:border-purple-900 text-base font-medium rounded-md text-white dark:text-gray-300 bg-purple-700 hover:bg-purple-800 dark:bg-purple-800 dark:hover:bg-purple-900"
              >
                <span className="hidden sm:inline">
                  Start your 30-day free trial
                </span>
                <span className="inline sm:hidden">Start free trial</span>
              </button>
            </div>
          )}
          <div className="inline-flex rounded-md shadow">
            <button
              onClick={() => {
                openCrispChat()
                mixpanel.track('Clicked Bottom CTA')
              }}
              type="button"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-purple-700 bg-white dark:bg-gray-300 hover:bg-purple-50"
            >
              <ChatAlt2Icon className="w-6 h-6" aria-hidden="true" />
              <span>Start a chat</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

SimpleJustifiedCTA.defaultProps = {
  headline: 'Ready to dive in?',
  subheadline: 'We can walk you through it.',
  showTrialButton: false,
}

SimpleJustifiedCTA.propTypes = {
  headline: PropTypes.string,
  subheadline: PropTypes.string,
  showTrialButton: PropTypes.bool,
}

export default SimpleJustifiedCTA
