export function sendCrispMessage(message) {
  if ($crisp) {
    $crisp.push(['do', 'message:send', ['text', message]])
    openCrispChat()
  }
}

export function openCrispChat() {
  $crisp && $crisp.push(['do', 'chat:open'])
}
