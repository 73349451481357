import React from 'react'
import PricingSection from './PricingSection'

export default {
  title: 'Pricing Feature/Components/PricingSection',
  component: PricingSection,
}

export function pricingSection(args) {
  return <PricingSection {...args} />
}
