import React from 'react'
import AccentDots from './AccentDots'

export default {
  title: 'Leevo UI/AccentDots',
  component: AccentDots,
  args: {
    className: 'm-4',
  },
}

export function accentDots(args) {
  return <AccentDots {...args} />
}
