import React from 'react'

import TermlyEmbed from '../leevo_ui/TermlyEmbed/TermlyEmbed'

function CookiePolicyFeature() {
  return (
    <TermlyEmbed
      title="Cookie Policy"
      termlyDocumentId="4a1ae440-3ff5-4765-b034-d5f98a9b9913"
    />
  )
}

export default CookiePolicyFeature
