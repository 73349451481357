import React from 'react'
import { mount } from 'enzyme'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { MemoryRouter, Route } from 'react-router-dom'
import flushPromises from 'flush-promises'
import { act } from 'react-dom/test-utils'

import FacilitiesKlassPackagesNew from './FacilitiesKlassPackagesNew'
import KlassPackageForm from '../leevo_ui/KlassPackageForm/KlassPackageForm'
import MockFacilityProvider from '../../providers/MockFacilityProvider'

const mockAxios = new MockAdapter(axios)

// FIXME: While all these tests pass, there are console.errors being
// generated by enzyme. We have brought this to the attention of the enzyme
// team: https://github.com/enzymejs/enzyme/issues/2011#issuecomment-602121952
// If it's a big deal in the future, and they don't fix this, we can mock console.error just
// for this spec.
describe('FacilitiesKlassPackagesNew', () => {
  let wrapper

  beforeEach(async () => {
    const mockKlassPackageResponse = {
      data: {
        id: '1',
        type: 'klassPackage',
        attributes: {
          price: 1241,
          active: true,
          title: 'Foobar',
          description: 'Barbaz',
          remainingKlassDays: 2,
          spotsLeft: 12412,
          priceInDollars: '$12.41',
        },
        relationships: {
          klasses: { data: [{ id: '59', type: 'klass' }] },
          supportedSkillLevels: { data: [{ id: '1', type: 'skillLevel' }] },
        },
      },
    }

    const mockKlassesResponse = {
      data: [
        {
          id: '59',
          type: 'klass',
          attributes: {
            maxStudents: 12412,
            timeOfDay: '11:00 AM',
            dayOfWeek: 'Wed',
            startDate: 'Oct 21',
            endDate: 'Nov 3',
            spotsLeft: 12412,
            remainingKlassDays: 2,
          },
          relationships: {
            facility: { data: { id: '1', type: 'facility' } },
            klassDays: {
              data: [
                { id: '443', type: 'klassDay' },
                { id: '444', type: 'klassDay' },
              ],
            },
            students: { data: [] },
            coaches: { data: [{ id: '20', type: 'coach' }] },
            supportedSkillLevels: { data: [{ id: '1', type: 'skillLevel' }] },
          },
        },
        {
          id: '60',
          type: 'klass',
          attributes: {
            maxStudents: null,
            timeOfDay: '12:00 AM',
            dayOfWeek: 'Thu',
            startDate: 'Oct 15',
            endDate: 'Oct 15',
            spotsLeft: null,
            remainingKlassDays: 1,
          },
          relationships: {
            facility: { data: { id: '1', type: 'facility' } },
            klassDays: { data: [{ id: '404', type: 'klassDay' }] },
            students: { data: [] },
            coaches: { data: [] },
            supportedSkillLevels: { data: [] },
          },
        },
        {
          id: '61',
          type: 'klass',
          attributes: {
            maxStudents: null,
            timeOfDay: '4:00 AM',
            dayOfWeek: 'Wed',
            startDate: 'Oct 14',
            endDate: 'Oct 28',
            spotsLeft: null,
            remainingKlassDays: 3,
          },
          relationships: {
            facility: { data: { id: '1', type: 'facility' } },
            klassDays: {
              data: [
                { id: '405', type: 'klassDay' },
                { id: '406', type: 'klassDay' },
                { id: '407', type: 'klassDay' },
              ],
            },
            students: { data: [] },
            coaches: { data: [{ id: '20', type: 'coach' }] },
            supportedSkillLevels: { data: [] },
          },
        },
        {
          id: '62',
          type: 'klass',
          attributes: {
            maxStudents: null,
            timeOfDay: '12:00 AM',
            dayOfWeek: 'Wed',
            startDate: 'Oct 14',
            endDate: 'Oct 28',
            spotsLeft: null,
            remainingKlassDays: 3,
          },
          relationships: {
            facility: { data: { id: '1', type: 'facility' } },
            klassDays: {
              data: [
                { id: '410', type: 'klassDay' },
                { id: '409', type: 'klassDay' },
                { id: '408', type: 'klassDay' },
              ],
            },
            students: { data: [] },
            coaches: { data: [{ id: '20', type: 'coach' }] },
            supportedSkillLevels: { data: [] },
          },
        },
        {
          id: '63',
          type: 'klass',
          attributes: {
            maxStudents: null,
            timeOfDay: '12:00 AM',
            dayOfWeek: 'Wed',
            startDate: 'Oct 14',
            endDate: 'Oct 28',
            spotsLeft: null,
            remainingKlassDays: 3,
          },
          relationships: {
            facility: { data: { id: '1', type: 'facility' } },
            klassDays: {
              data: [
                { id: '413', type: 'klassDay' },
                { id: '412', type: 'klassDay' },
                { id: '411', type: 'klassDay' },
              ],
            },
            students: { data: [] },
            coaches: { data: [] },
            supportedSkillLevels: {
              data: [
                { id: '2', type: 'skillLevel' },
                { id: '1', type: 'skillLevel' },
              ],
            },
          },
        },
        {
          id: '64',
          type: 'klass',
          attributes: {
            maxStudents: null,
            timeOfDay: '3:00 AM',
            dayOfWeek: 'Thu',
            startDate: 'Oct 22',
            endDate: 'Oct 29',
            spotsLeft: null,
            remainingKlassDays: 2,
          },
          relationships: {
            facility: { data: { id: '1', type: 'facility' } },
            klassDays: {
              data: [
                { id: '414', type: 'klassDay' },
                { id: '415', type: 'klassDay' },
              ],
            },
            students: { data: [] },
            coaches: { data: [{ id: '20', type: 'coach' }] },
            supportedSkillLevels: { data: [] },
          },
        },
        {
          id: '65',
          type: 'klass',
          attributes: {
            maxStudents: null,
            timeOfDay: '2:00 AM',
            dayOfWeek: 'Tue',
            startDate: 'Oct 13',
            endDate: 'Oct 20',
            spotsLeft: null,
            remainingKlassDays: 1,
          },
          relationships: {
            facility: { data: { id: '1', type: 'facility' } },
            klassDays: {
              data: [
                { id: '416', type: 'klassDay' },
                { id: '417', type: 'klassDay' },
              ],
            },
            students: { data: [] },
            coaches: { data: [{ id: '20', type: 'coach' }] },
            supportedSkillLevels: {
              data: [
                { id: '2', type: 'skillLevel' },
                { id: '1', type: 'skillLevel' },
              ],
            },
          },
        },
        {
          id: '66',
          type: 'klass',
          attributes: {
            maxStudents: null,
            timeOfDay: '2:00 AM',
            dayOfWeek: 'Tue',
            startDate: 'Oct 13',
            endDate: 'Oct 20',
            spotsLeft: null,
            remainingKlassDays: 1,
          },
          relationships: {
            facility: { data: { id: '1', type: 'facility' } },
            klassDays: {
              data: [
                { id: '418', type: 'klassDay' },
                { id: '419', type: 'klassDay' },
              ],
            },
            students: { data: [] },
            coaches: { data: [] },
            supportedSkillLevels: { data: [] },
          },
        },
        {
          id: '67',
          type: 'klass',
          attributes: {
            maxStudents: 124,
            timeOfDay: '9:00 AM',
            dayOfWeek: 'Tue',
            startDate: 'Oct 13',
            endDate: 'Oct 20',
            spotsLeft: 124,
            remainingKlassDays: 1,
          },
          relationships: {
            facility: { data: { id: '1', type: 'facility' } },
            klassDays: {
              data: [
                { id: '421', type: 'klassDay' },
                { id: '420', type: 'klassDay' },
              ],
            },
            students: { data: [] },
            coaches: { data: [{ id: '20', type: 'coach' }] },
            supportedSkillLevels: {
              data: [
                { id: '2', type: 'skillLevel' },
                { id: '1', type: 'skillLevel' },
              ],
            },
          },
        },
        {
          id: '68',
          type: 'klass',
          attributes: {
            maxStudents: 124,
            timeOfDay: '10:00 PM',
            dayOfWeek: 'Tue',
            startDate: 'Oct 13',
            endDate: 'Nov 3',
            spotsLeft: 124,
            remainingKlassDays: 4,
          },
          relationships: {
            facility: { data: { id: '1', type: 'facility' } },
            klassDays: {
              data: [
                { id: '423', type: 'klassDay' },
                { id: '422', type: 'klassDay' },
                { id: '424', type: 'klassDay' },
                { id: '425', type: 'klassDay' },
              ],
            },
            students: { data: [] },
            coaches: { data: [{ id: '20', type: 'coach' }] },
            supportedSkillLevels: {
              data: [
                { id: '2', type: 'skillLevel' },
                { id: '1', type: 'skillLevel' },
              ],
            },
          },
        },
        {
          id: '69',
          type: 'klass',
          attributes: {
            maxStudents: 124,
            timeOfDay: '1:00 AM',
            dayOfWeek: 'Fri',
            startDate: 'Oct 16',
            endDate: 'Oct 30',
            spotsLeft: 124,
            remainingKlassDays: 3,
          },
          relationships: {
            facility: { data: { id: '1', type: 'facility' } },
            klassDays: {
              data: [
                { id: '432', type: 'klassDay' },
                { id: '433', type: 'klassDay' },
                { id: '434', type: 'klassDay' },
              ],
            },
            students: { data: [] },
            coaches: { data: [{ id: '20', type: 'coach' }] },
            supportedSkillLevels: { data: [{ id: '1', type: 'skillLevel' }] },
          },
        },
        {
          id: '70',
          type: 'klass',
          attributes: {
            maxStudents: 124,
            timeOfDay: '2:00 PM',
            dayOfWeek: 'Fri',
            startDate: 'Oct 16',
            endDate: 'Nov 6',
            spotsLeft: 124,
            remainingKlassDays: 4,
          },
          relationships: {
            facility: { data: { id: '1', type: 'facility' } },
            klassDays: {
              data: [
                { id: '435', type: 'klassDay' },
                { id: '436', type: 'klassDay' },
                { id: '437', type: 'klassDay' },
                { id: '438', type: 'klassDay' },
              ],
            },
            students: { data: [] },
            coaches: { data: [{ id: '20', type: 'coach' }] },
            supportedSkillLevels: { data: [{ id: '1', type: 'skillLevel' }] },
          },
        },
        {
          id: '71',
          type: 'klass',
          attributes: {
            maxStudents: 124124,
            timeOfDay: '6:18 PM',
            dayOfWeek: 'Sun',
            startDate: 'Oct 18',
            endDate: 'Nov 1',
            spotsLeft: 124124,
            remainingKlassDays: 3,
          },
          relationships: {
            facility: { data: { id: '1', type: 'facility' } },
            klassDays: {
              data: [
                { id: '451', type: 'klassDay' },
                { id: '452', type: 'klassDay' },
                { id: '453', type: 'klassDay' },
              ],
            },
            students: { data: [] },
            coaches: { data: [{ id: '20', type: 'coach' }] },
            supportedSkillLevels: { data: [] },
          },
        },
      ],
      included: [
        {
          id: '20',
          type: 'coach',
          attributes: {
            fullName: 'Michael Orr',
            preferredName: 'Coach Michael',
            email: 'rene@example.com',
            cloudinaryPhotoPublicId: null,
            'invitedToSignUp?': false,
            'invitationAccepted?': false,
          },
          relationships: {
            facilities: { data: [{ id: '3', type: 'facility' }] },
            klassDays: {
              data: [
                { id: '405', type: 'klassDay' },
                { id: '406', type: 'klassDay' },
                { id: '407', type: 'klassDay' },
                { id: '408', type: 'klassDay' },
                { id: '409', type: 'klassDay' },
                { id: '410', type: 'klassDay' },
                { id: '414', type: 'klassDay' },
                { id: '415', type: 'klassDay' },
                { id: '416', type: 'klassDay' },
                { id: '417', type: 'klassDay' },
                { id: '420', type: 'klassDay' },
                { id: '421', type: 'klassDay' },
                { id: '422', type: 'klassDay' },
                { id: '423', type: 'klassDay' },
                { id: '424', type: 'klassDay' },
                { id: '425', type: 'klassDay' },
                { id: '432', type: 'klassDay' },
                { id: '433', type: 'klassDay' },
                { id: '434', type: 'klassDay' },
                { id: '435', type: 'klassDay' },
                { id: '436', type: 'klassDay' },
                { id: '437', type: 'klassDay' },
                { id: '438', type: 'klassDay' },
                { id: '443', type: 'klassDay' },
                { id: '444', type: 'klassDay' },
                { id: '451', type: 'klassDay' },
                { id: '452', type: 'klassDay' },
                { id: '453', type: 'klassDay' },
              ],
            },
          },
        },
        {
          id: '1',
          type: 'skillLevel',
          attributes: { name: 'Basic 1' },
          relationships: {
            nextSkillLevel: { data: { id: '2', type: 'skillLevel' } },
            skills: {
              data: [
                { id: '1', type: 'skill' },
                { id: '2', type: 'skill' },
                { id: '4', type: 'skill' },
                { id: '6', type: 'skill' },
                { id: '7', type: 'skill' },
                { id: '8', type: 'skill' },
                { id: '3', type: 'skill' },
                { id: '5', type: 'skill' },
              ],
            },
          },
        },
        {
          id: '2',
          type: 'skillLevel',
          attributes: { name: 'Basic 2' },
          relationships: {
            nextSkillLevel: { data: { id: '3', type: 'skillLevel' } },
            skills: {
              data: [
                { id: '9', type: 'skill' },
                { id: '10', type: 'skill' },
                { id: '11', type: 'skill' },
                { id: '12', type: 'skill' },
                { id: '13', type: 'skill' },
                { id: '14', type: 'skill' },
                { id: '15', type: 'skill' },
              ],
            },
          },
        },
      ],
    }

    const mockStudentsResponse = {
      meta: { registered: 0, total: 0 },
      data: [],
    }

    const mockFacilityResponse = {
      data: {
        id: 1,
        type: 'facility',
        attributes: {
          name: 'fake facility',
        },
      },
    }

    mockAxios.reset()
    mockAxios.onGet(/class_packages/).reply(200, mockKlassPackageResponse)
    mockAxios.onGet(/klasses/).reply(200, mockKlassesResponse)
    mockAxios.onGet(/students/).reply(200, mockStudentsResponse)
    mockAxios.onGet('/api/facilities/1').reply(200, mockFacilityResponse)

    wrapper = mount(
      <MemoryRouter initialEntries={['/facilities/1/class_packages/new']}>
        <Route path="/facilities/:facility_id/class_packages/new">
          <MockFacilityProvider>
            <FacilitiesKlassPackagesNew />
          </MockFacilityProvider>
        </Route>
      </MemoryRouter>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  describe('when all data loaded', () => {
    beforeEach(async () => {
      act(() => wrapper.update()) // update initial render useEffect
      await flushPromises()
      act(() => wrapper.update()) // update for state updates from result of promises
    })

    it('renders the KlassPackageForm', () => {
      expect(wrapper.find(KlassPackageForm)).toExist()
    })
  })
})
