import React from 'react'
import { shallow, mount } from 'enzyme'
import { Form } from 'react-final-form'

import PasswordFields from './PasswordFields'

describe('PasswordFields', () => {
  let wrapper

  test('renders', () => {
    wrapper = shallow(<PasswordFields />)
    expect(wrapper).toExist()
  })

  test('renders className on topmost div', () => {
    wrapper = mount(
      <Form
        onSubmit={jest.fn()}
        render={() => <PasswordFields className="foo" />}
      />
    )
    expect(wrapper.find('.foo')).toExist()
  })
})
