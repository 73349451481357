import React from 'react'
import { text } from '@storybook/addon-knobs'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { MemoryRouter, Route } from 'react-router-dom'

import faker from 'faker'

import FacilitiesKlassesIndex from './FacilitiesKlassesIndex'
import MockFacilityProvider from '../../providers/MockFacilityProvider'

export default {
  title: 'Facilities Klasses Index',
  component: FacilitiesKlassesIndex,
}

const mockKlassesResponse = {
  data: [
    {
      id: '704',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:00 AM',
        startDate: 'Sep 25',
        endDate: 'Oct 30',
        maxStudents: null,
        firstOccurrenceAt: '2021-09-25T14:00:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '18',
              type: 'skillLevel',
            },
            {
              id: '19',
              type: 'skillLevel',
            },
            {
              id: '20',
              type: 'skillLevel',
            },
            {
              id: '21',
              type: 'skillLevel',
            },
            {
              id: '22',
              type: 'skillLevel',
            },
            {
              id: '23',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [
            {
              id: '4128',
              type: 'student',
            },
            {
              id: '4297',
              type: 'student',
            },
            {
              id: '4481',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '175',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '703',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:00 AM',
        startDate: 'Sep 25',
        endDate: 'Oct 30',
        maxStudents: null,
        firstOccurrenceAt: '2021-09-25T14:00:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '1',
              type: 'skillLevel',
            },
            {
              id: '2',
              type: 'skillLevel',
            },
            {
              id: '3',
              type: 'skillLevel',
            },
            {
              id: '4',
              type: 'skillLevel',
            },
            {
              id: '5',
              type: 'skillLevel',
            },
            {
              id: '6',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [
            {
              id: '4363',
              type: 'student',
            },
            {
              id: '4308',
              type: 'student',
            },
            {
              id: '4379',
              type: 'student',
            },
            {
              id: '4389',
              type: 'student',
            },
            {
              id: '4401',
              type: 'student',
            },
            {
              id: '4127',
              type: 'student',
            },
            {
              id: '4424',
              type: 'student',
            },
            {
              id: '4432',
              type: 'student',
            },
            {
              id: '4217',
              type: 'student',
            },
            {
              id: '4447',
              type: 'student',
            },
            {
              id: '3988',
              type: 'student',
            },
            {
              id: '3990',
              type: 'student',
            },
            {
              id: '4451',
              type: 'student',
            },
            {
              id: '4453',
              type: 'student',
            },
            {
              id: '4466',
              type: 'student',
            },
            {
              id: '4469',
              type: 'student',
            },
            {
              id: '4296',
              type: 'student',
            },
            {
              id: '4472',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '175',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '697',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:00 AM',
        startDate: 'Sep 25',
        endDate: 'Oct 30',
        maxStudents: null,
        firstOccurrenceAt: '2021-09-25T14:00:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '7',
              type: 'skillLevel',
            },
            {
              id: '8',
              type: 'skillLevel',
            },
            {
              id: '10',
              type: 'skillLevel',
            },
            {
              id: '9',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [
            {
              id: '4205',
              type: 'student',
            },
            {
              id: '4295',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '175',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '737',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:00 AM',
        startDate: 'Sep 25',
        endDate: 'Oct 30',
        maxStudents: null,
        firstOccurrenceAt: '2021-09-25T14:00:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [],
        },
        students: {
          data: [
            {
              id: '4424',
              type: 'student',
            },
            {
              id: '4432',
              type: 'student',
            },
            {
              id: '4308',
              type: 'student',
            },
            {
              id: '4466',
              type: 'student',
            },
            {
              id: '4205',
              type: 'student',
            },
            {
              id: '4389',
              type: 'student',
            },
            {
              id: '3988',
              type: 'student',
            },
            {
              id: '4217',
              type: 'student',
            },
            {
              id: '4453',
              type: 'student',
            },
            {
              id: '3990',
              type: 'student',
            },
            {
              id: '4127',
              type: 'student',
            },
            {
              id: '4451',
              type: 'student',
            },
            {
              id: '4363',
              type: 'student',
            },
            {
              id: '4447',
              type: 'student',
            },
            {
              id: '4401',
              type: 'student',
            },
            {
              id: '4128',
              type: 'student',
            },
            {
              id: '4379',
              type: 'student',
            },
            {
              id: '4095',
              type: 'student',
            },
            {
              id: '3930',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '175',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '705',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:30 AM',
        startDate: 'Sep 25',
        endDate: 'Oct 30',
        maxStudents: null,
        firstOccurrenceAt: '2021-09-25T14:30:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '18',
              type: 'skillLevel',
            },
            {
              id: '19',
              type: 'skillLevel',
            },
            {
              id: '20',
              type: 'skillLevel',
            },
            {
              id: '21',
              type: 'skillLevel',
            },
            {
              id: '23',
              type: 'skillLevel',
            },
            {
              id: '22',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [
            {
              id: '4206',
              type: 'student',
            },
            {
              id: '4433',
              type: 'student',
            },
            {
              id: '4431',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '181',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '707',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:00 AM',
        startDate: 'Nov 6',
        endDate: 'Dec 18',
        maxStudents: null,
        firstOccurrenceAt: '2021-11-06T14:00:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '18',
              type: 'skillLevel',
            },
            {
              id: '19',
              type: 'skillLevel',
            },
            {
              id: '20',
              type: 'skillLevel',
            },
            {
              id: '22',
              type: 'skillLevel',
            },
            {
              id: '21',
              type: 'skillLevel',
            },
            {
              id: '23',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [],
        },
        klassPackages: {
          data: [
            {
              id: '176',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '698',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:00 AM',
        startDate: 'Nov 6',
        endDate: 'Dec 18',
        maxStudents: null,
        firstOccurrenceAt: '2021-11-06T14:00:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '7',
              type: 'skillLevel',
            },
            {
              id: '8',
              type: 'skillLevel',
            },
            {
              id: '9',
              type: 'skillLevel',
            },
            {
              id: '10',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [
            {
              id: '4205',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '176',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '706',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:00 AM',
        startDate: 'Nov 6',
        endDate: 'Dec 18',
        maxStudents: null,
        firstOccurrenceAt: '2021-11-06T14:00:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '1',
              type: 'skillLevel',
            },
            {
              id: '2',
              type: 'skillLevel',
            },
            {
              id: '4',
              type: 'skillLevel',
            },
            {
              id: '3',
              type: 'skillLevel',
            },
            {
              id: '5',
              type: 'skillLevel',
            },
            {
              id: '6',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [
            {
              id: '4352',
              type: 'student',
            },
            {
              id: '4408',
              type: 'student',
            },
            {
              id: '4447',
              type: 'student',
            },
            {
              id: '4095',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '176',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '717',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:30 AM',
        startDate: 'Nov 6',
        endDate: 'Dec 18',
        maxStudents: null,
        firstOccurrenceAt: '2021-11-06T14:30:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '18',
              type: 'skillLevel',
            },
            {
              id: '20',
              type: 'skillLevel',
            },
            {
              id: '19',
              type: 'skillLevel',
            },
            {
              id: '21',
              type: 'skillLevel',
            },
            {
              id: '22',
              type: 'skillLevel',
            },
            {
              id: '23',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [
            {
              id: '4206',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '182',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '709',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Tue',
        timeOfDay: '5:15 PM',
        startDate: 'Jan 4',
        endDate: 'Feb 8',
        maxStudents: null,
        firstOccurrenceAt: '2022-01-04T22:15:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '18',
              type: 'skillLevel',
            },
            {
              id: '19',
              type: 'skillLevel',
            },
            {
              id: '20',
              type: 'skillLevel',
            },
            {
              id: '21',
              type: 'skillLevel',
            },
            {
              id: '22',
              type: 'skillLevel',
            },
            {
              id: '23',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [],
        },
        klassPackages: {
          data: [
            {
              id: '177',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '699',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Tue',
        timeOfDay: '5:15 PM',
        startDate: 'Jan 4',
        endDate: 'Feb 8',
        maxStudents: null,
        firstOccurrenceAt: '2022-01-04T22:15:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '7',
              type: 'skillLevel',
            },
            {
              id: '9',
              type: 'skillLevel',
            },
            {
              id: '8',
              type: 'skillLevel',
            },
            {
              id: '10',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [],
        },
        klassPackages: {
          data: [
            {
              id: '177',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '708',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Tue',
        timeOfDay: '5:15 PM',
        startDate: 'Jan 4',
        endDate: 'Feb 8',
        maxStudents: null,
        firstOccurrenceAt: '2022-01-04T22:15:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '1',
              type: 'skillLevel',
            },
            {
              id: '3',
              type: 'skillLevel',
            },
            {
              id: '2',
              type: 'skillLevel',
            },
            {
              id: '4',
              type: 'skillLevel',
            },
            {
              id: '5',
              type: 'skillLevel',
            },
            {
              id: '6',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [
            {
              id: '4095',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '177',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '718',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Tue',
        timeOfDay: '5:45 PM',
        startDate: 'Jan 4',
        endDate: 'Feb 8',
        maxStudents: null,
        firstOccurrenceAt: '2022-01-04T22:45:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '18',
              type: 'skillLevel',
            },
            {
              id: '19',
              type: 'skillLevel',
            },
            {
              id: '20',
              type: 'skillLevel',
            },
            {
              id: '21',
              type: 'skillLevel',
            },
            {
              id: '22',
              type: 'skillLevel',
            },
            {
              id: '23',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [],
        },
        klassPackages: {
          data: [
            {
              id: '183',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '710',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:00 AM',
        startDate: 'Jan 8',
        endDate: 'Feb 12',
        maxStudents: null,
        firstOccurrenceAt: '2022-01-08T15:00:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '1',
              type: 'skillLevel',
            },
            {
              id: '2',
              type: 'skillLevel',
            },
            {
              id: '3',
              type: 'skillLevel',
            },
            {
              id: '4',
              type: 'skillLevel',
            },
            {
              id: '6',
              type: 'skillLevel',
            },
            {
              id: '5',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [
            {
              id: '4095',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '178',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '700',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:00 AM',
        startDate: 'Jan 8',
        endDate: 'Feb 12',
        maxStudents: null,
        firstOccurrenceAt: '2022-01-08T15:00:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '7',
              type: 'skillLevel',
            },
            {
              id: '9',
              type: 'skillLevel',
            },
            {
              id: '8',
              type: 'skillLevel',
            },
            {
              id: '10',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [
            {
              id: '4205',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '178',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '711',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:00 AM',
        startDate: 'Jan 8',
        endDate: 'Feb 12',
        maxStudents: null,
        firstOccurrenceAt: '2022-01-08T15:00:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '18',
              type: 'skillLevel',
            },
            {
              id: '19',
              type: 'skillLevel',
            },
            {
              id: '20',
              type: 'skillLevel',
            },
            {
              id: '21',
              type: 'skillLevel',
            },
            {
              id: '23',
              type: 'skillLevel',
            },
            {
              id: '22',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [],
        },
        klassPackages: {
          data: [
            {
              id: '178',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '719',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:30 AM',
        startDate: 'Jan 8',
        endDate: 'Feb 12',
        maxStudents: null,
        firstOccurrenceAt: '2022-01-08T15:30:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '18',
              type: 'skillLevel',
            },
            {
              id: '19',
              type: 'skillLevel',
            },
            {
              id: '20',
              type: 'skillLevel',
            },
            {
              id: '21',
              type: 'skillLevel',
            },
            {
              id: '23',
              type: 'skillLevel',
            },
            {
              id: '22',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [
            {
              id: '4206',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '184',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '712',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Tue',
        timeOfDay: '5:15 PM',
        startDate: 'Feb 15',
        endDate: 'Mar 22',
        maxStudents: null,
        firstOccurrenceAt: '2022-02-15T22:15:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '1',
              type: 'skillLevel',
            },
            {
              id: '2',
              type: 'skillLevel',
            },
            {
              id: '3',
              type: 'skillLevel',
            },
            {
              id: '4',
              type: 'skillLevel',
            },
            {
              id: '5',
              type: 'skillLevel',
            },
            {
              id: '6',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [
            {
              id: '4263',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '179',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '714',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Tue',
        timeOfDay: '5:15 PM',
        startDate: 'Feb 15',
        endDate: 'Mar 22',
        maxStudents: null,
        firstOccurrenceAt: '2022-02-15T22:15:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '19',
              type: 'skillLevel',
            },
            {
              id: '20',
              type: 'skillLevel',
            },
            {
              id: '18',
              type: 'skillLevel',
            },
            {
              id: '21',
              type: 'skillLevel',
            },
            {
              id: '22',
              type: 'skillLevel',
            },
            {
              id: '23',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [],
        },
        klassPackages: {
          data: [
            {
              id: '179',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '713',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Tue',
        timeOfDay: '5:15 PM',
        startDate: 'Feb 15',
        endDate: 'Mar 22',
        maxStudents: null,
        firstOccurrenceAt: '2022-02-15T22:15:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '1',
              type: 'skillLevel',
            },
            {
              id: '2',
              type: 'skillLevel',
            },
            {
              id: '3',
              type: 'skillLevel',
            },
            {
              id: '4',
              type: 'skillLevel',
            },
            {
              id: '5',
              type: 'skillLevel',
            },
            {
              id: '6',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [],
        },
        klassPackages: {
          data: [
            {
              id: '179',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '701',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Tue',
        timeOfDay: '5:15 PM',
        startDate: 'Feb 15',
        endDate: 'Mar 22',
        maxStudents: null,
        firstOccurrenceAt: '2022-02-15T22:15:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '8',
              type: 'skillLevel',
            },
            {
              id: '9',
              type: 'skillLevel',
            },
            {
              id: '7',
              type: 'skillLevel',
            },
            {
              id: '10',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [],
        },
        klassPackages: {
          data: [
            {
              id: '179',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '720',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Tue',
        timeOfDay: '5:45 PM',
        startDate: 'Feb 15',
        endDate: 'Mar 22',
        maxStudents: null,
        firstOccurrenceAt: '2022-02-15T22:45:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '18',
              type: 'skillLevel',
            },
            {
              id: '19',
              type: 'skillLevel',
            },
            {
              id: '20',
              type: 'skillLevel',
            },
            {
              id: '21',
              type: 'skillLevel',
            },
            {
              id: '22',
              type: 'skillLevel',
            },
            {
              id: '23',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [],
        },
        klassPackages: {
          data: [
            {
              id: '185',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '715',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:00 AM',
        startDate: 'Feb 19',
        endDate: 'Mar 26',
        maxStudents: null,
        firstOccurrenceAt: '2022-02-19T15:00:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '1',
              type: 'skillLevel',
            },
            {
              id: '2',
              type: 'skillLevel',
            },
            {
              id: '3',
              type: 'skillLevel',
            },
            {
              id: '4',
              type: 'skillLevel',
            },
            {
              id: '5',
              type: 'skillLevel',
            },
            {
              id: '6',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [
            {
              id: '4095',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '180',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '716',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:00 AM',
        startDate: 'Feb 19',
        endDate: 'Mar 26',
        maxStudents: null,
        firstOccurrenceAt: '2022-02-19T15:00:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '18',
              type: 'skillLevel',
            },
            {
              id: '19',
              type: 'skillLevel',
            },
            {
              id: '20',
              type: 'skillLevel',
            },
            {
              id: '22',
              type: 'skillLevel',
            },
            {
              id: '21',
              type: 'skillLevel',
            },
            {
              id: '23',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [],
        },
        klassPackages: {
          data: [
            {
              id: '180',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '702',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:00 AM',
        startDate: 'Feb 19',
        endDate: 'Mar 26',
        maxStudents: null,
        firstOccurrenceAt: '2022-02-19T15:00:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '7',
              type: 'skillLevel',
            },
            {
              id: '9',
              type: 'skillLevel',
            },
            {
              id: '8',
              type: 'skillLevel',
            },
            {
              id: '10',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [
            {
              id: '4205',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '180',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
    {
      id: '721',
      type: 'klass',
      attributes: {
        dayOfWeek: 'Sat',
        timeOfDay: '10:30 AM',
        startDate: 'Feb 19',
        endDate: 'Mar 26',
        maxStudents: null,
        firstOccurrenceAt: '2022-02-19T15:30:00.000Z',
      },
      relationships: {
        coaches: {
          data: [],
        },
        supportedSkillLevels: {
          data: [
            {
              id: '18',
              type: 'skillLevel',
            },
            {
              id: '19',
              type: 'skillLevel',
            },
            {
              id: '20',
              type: 'skillLevel',
            },
            {
              id: '21',
              type: 'skillLevel',
            },
            {
              id: '22',
              type: 'skillLevel',
            },
            {
              id: '23',
              type: 'skillLevel',
            },
          ],
        },
        students: {
          data: [
            {
              id: '4206',
              type: 'student',
            },
          ],
        },
        klassPackages: {
          data: [
            {
              id: '186',
              type: 'klassPackage',
            },
          ],
        },
      },
    },
  ],
  included: [
    {
      id: '18',
      type: 'skillLevel',
      attributes: {
        name: 'Adult 1',
      },
      relationships: {},
    },
    {
      id: '19',
      type: 'skillLevel',
      attributes: {
        name: 'Adult 2',
      },
      relationships: {},
    },
    {
      id: '20',
      type: 'skillLevel',
      attributes: {
        name: 'Adult 3',
      },
      relationships: {},
    },
    {
      id: '21',
      type: 'skillLevel',
      attributes: {
        name: 'Adult 4',
      },
      relationships: {},
    },
    {
      id: '22',
      type: 'skillLevel',
      attributes: {
        name: 'Adult 5',
      },
      relationships: {},
    },
    {
      id: '23',
      type: 'skillLevel',
      attributes: {
        name: 'Adult 6',
      },
      relationships: {},
    },
    {
      id: '175',
      type: 'klassPackage',
      attributes: {
        title: 'Session 1  - Saturdays 10am-11am',
      },
      relationships: {},
    },
    {
      id: '1',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 1',
      },
      relationships: {},
    },
    {
      id: '2',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 2',
      },
      relationships: {},
    },
    {
      id: '3',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 3',
      },
      relationships: {},
    },
    {
      id: '4',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 4',
      },
      relationships: {},
    },
    {
      id: '5',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 5',
      },
      relationships: {},
    },
    {
      id: '6',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 6',
      },
      relationships: {},
    },
    {
      id: '7',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 1',
      },
      relationships: {},
    },
    {
      id: '8',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 2',
      },
      relationships: {},
    },
    {
      id: '10',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 4',
      },
      relationships: {},
    },
    {
      id: '9',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 3',
      },
      relationships: {},
    },
    {
      id: '181',
      type: 'klassPackage',
      attributes: {
        title: 'Session 1 - Saturdays - Parent Skate',
      },
      relationships: {},
    },
    {
      id: '176',
      type: 'klassPackage',
      attributes: {
        title: 'Session 2  - Saturdays 10am-11am',
      },
      relationships: {},
    },
    {
      id: '182',
      type: 'klassPackage',
      attributes: {
        title: 'Session 2 - Saturdays - Parent Skate',
      },
      relationships: {},
    },
    {
      id: '177',
      type: 'klassPackage',
      attributes: {
        title: 'Session 3 - Tuesdays 5:15pm-6:15pm',
      },
      relationships: {},
    },
    {
      id: '183',
      type: 'klassPackage',
      attributes: {
        title: 'Session 3 - Tuesdays - Parent Skate',
      },
      relationships: {},
    },
    {
      id: '178',
      type: 'klassPackage',
      attributes: {
        title: 'Session 3 - Saturdays 10am-11am',
      },
      relationships: {},
    },
    {
      id: '184',
      type: 'klassPackage',
      attributes: {
        title: 'Session 3 - Saturdays - Parent Skate',
      },
      relationships: {},
    },
    {
      id: '179',
      type: 'klassPackage',
      attributes: {
        title: 'Session 4 - Tuesdays 5:15pm-6:15pm',
      },
      relationships: {},
    },
    {
      id: '185',
      type: 'klassPackage',
      attributes: {
        title: 'Session 4 - Tuesdays - Parent Skate',
      },
      relationships: {},
    },
    {
      id: '180',
      type: 'klassPackage',
      attributes: {
        title: 'Session 4 - Saturdays 10am-11am',
      },
      relationships: {},
    },
    {
      id: '186',
      type: 'klassPackage',
      attributes: {
        title: 'Session 4 - Saturdays - Parent Skate',
      },
      relationships: {},
    },
  ],
}

export function facilitiesKlassesIndex() {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onGet('/api/facilities/1').reply(200, {
    data: {
      id: 1,
      type: 'facility',
      attributes: {
        name: text('Returned Facility Name', faker.company.companyName()),
      },
    },
  })
  mockAxios.onGet('/api/facilities/1/klasses').reply(200, mockKlassesResponse)

  return (
    <MemoryRouter initialEntries={['/facilities/1/classes']}>
      <MockFacilityProvider>
        <Route path="/facilities/:facility_id/classes">
          <FacilitiesKlassesIndex />
        </Route>
      </MockFacilityProvider>
    </MemoryRouter>
  )
}
