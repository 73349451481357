import React from 'react'
import LogoutFeature from './LogoutFeature'
import { mount } from 'enzyme'
import { BrowserRouter, Redirect } from 'react-router-dom'

import MockAuthProvider from '../../providers/MockAuthProvider'

describe('LogoutFeature', () => {
  let wrapper
  const signOutUser = jest.fn()
  beforeEach(() => {
    wrapper = mount(
      <BrowserRouter>
        <MockAuthProvider signOutUser={signOutUser}>
          <LogoutFeature />
        </MockAuthProvider>
      </BrowserRouter>
    )
  })
  test('renders', () => {
    expect(wrapper).toExist()
  })
  test('calls signOutUser', () => {
    wrapper = mount(
      <BrowserRouter>
        <MockAuthProvider signOutUser={signOutUser}>
          <LogoutFeature />
        </MockAuthProvider>
      </BrowserRouter>
    )
    expect(signOutUser).toHaveBeenCalled()
  })
  test('redirects user', () => {
    expect(wrapper.find(Redirect)).toExist()
  })
})
