import { text, select, number } from '@storybook/addon-knobs'

import React, { useState } from 'react'
import Notification from './Notification'
import Button from '../Button/Button'

export default {
  title: 'Leevo UI/Notification',
  component: Notification,
}

export function NotificationStory() {
  const [show, setShow] = useState(true)
  return (
    <div className="m-10">
      <Button
        label="Show Notification"
        className="mr-3"
        onClick={() => setShow(true)}
      />
      <Button
        label="Hide Notification"
        outline
        color="red"
        onClick={() => setShow(false)}
      />
      <Notification
        show={show}
        color={select(
          'Color',
          Notification.colors,
          Notification.defaultProps.color
        )}
        disappearIn={number('Disappear in:', 2000)}
        onDisappearTimeout={() => setShow(false)}
        className="my-3"
      >
        {text('Message', 'Some Message')}
      </Notification>
    </div>
  )
}
