import React from 'react'
import DisclosureAgreementCheckbox from './DisclosureAgreementCheckbox'
import { shallow } from 'enzyme'

describe('DisclosureAgreementCheckbox', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(
        <DisclosureAgreementCheckbox
          disclosureType="one_liner"
          id={1}
          agreement="Foo"
          disclosureName="foobar"
        />
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
