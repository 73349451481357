import React, { useState } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'

import CheckboxInputCompact from '../../../leevo_ui/CheckboxInputCompact/CheckboxInputCompact'
import Modal from '../../../leevo_ui/Modal/Modal'
function DisclosureAgreementCheckbox({
  disclosureType,
  id,
  agreement,
  disclosureName,
  required,
  value,
  onClick,
}) {
  const [isModalOpened, setIsModalOpened] = useState(false)

  function generateLabel() {
    let output = []

    if (disclosureType === 'one_liner') {
      output.push(agreement)
    } else {
      output.push(
        <Modal
          data-cy="disclosure-modal"
          open={isModalOpened}
          header={disclosureName}
          onClickBackground={() => setIsModalOpened(false)}
          onClickClose={() => setIsModalOpened(false)}
        >
          {parse(agreement.replace(/\n/g, '<br />'))}
        </Modal>
      )
      output.push(
        <span>
          I have read and agree to the{' '}
          <a
            href="#"
            data-cy="disclosure-agreement-checkbox-modal-button"
            className="font-medium text-purple-600 hover:text-purple-500 cursor-pointer"
            onClick={(e) => {
              e.preventDefault()
              setIsModalOpened(true)
            }}
          >
            {disclosureName}
          </a>
        </span>
      )
    }

    if (!required) output.unshift(<span>(optional) </span>)

    return output
  }

  return (
    <li key={id}>
      <CheckboxInputCompact
        id={id}
        data-cy="diclosure-agreement-checkbox"
        value={value}
        checked={value}
        onClick={onClick}
        label={generateLabel()}
      />
    </li>
  )
}

DisclosureAgreementCheckbox.defaultProps = {
  required: false,
}

DisclosureAgreementCheckbox.propTypes = {
  disclosureType: PropTypes.oneOf(['one_liner', 'document']).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  agreement: PropTypes.string.isRequired,
  disclosureName: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.bool,
  onClick: PropTypes.func,
}

export default DisclosureAgreementCheckbox
