import { text, select, boolean } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'

import React from 'react'
import SkillButton from './SkillButton'
import htmlEntities from 'he'

export default {
  title: 'Leevo UI/SkillButton',
  component: SkillButton,
}

export function singleButton() {
  return (
    <div className="flex items-center justify-center">
      <SkillButton
        state={select(
          'State',
          SkillButton.states,
          SkillButton.defaultProps.state
        )}
        label={text('Label', 'A')}
        onClick={action('SkillButton-click')}
        size={select('Size', SkillButton.sizes, SkillButton.defaultProps.sizes)}
        loading={boolean('Loading?', false)}
      />
    </div>
  )
}

export function all() {
  return (
    <div className="flex flex-col items-center justify-center">
      {SkillButton.sizes.map((size) => (
        <div key={size} className="m-4 text-center">
          <h1>Size {size}</h1>
          {SkillButton.states.map((state) => (
            <SkillButton
              className="m-1"
              key={`${state}-${size}`}
              onClick={action(`CircleButton-${state}-${size} clicked`)}
              size={size}
              state={state}
              label={htmlEntities.decode(text('Label', 'A'))}
              loading={boolean('Loading?', false)}
            />
          ))}
        </div>
      ))}
    </div>
  )
}
