import React from 'react'
import { createForm } from 'final-form'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import htmlEntities from 'he'

import PresentedInfoSection from './PresentedInfoSection/PresentedInfoSection'
import InternalInfoSection from './InternalInfoSection/InternalInfoSection'

function DisclosureForm({ className, initialValues, onSubmit, ...rest }) {
  let agreement
  const { responses } = initialValues
  if (initialValues.agreement) {
    agreement = htmlEntities.decode(initialValues.agreement)
  }
  const formApi = createForm({
    mutators: {
      truncate: ([name, length], state, { changeValue }) => {
        changeValue(state, name, (oldValue) => oldValue.substring(0, length))
      },
    },
    initialValues: {
      type: 'one_liner',
      active: true,
      ...initialValues,
      agreement,
    },
    onSubmit,
  })

  return (
    <div {...rest} className={className}>
      <Form
        form={formApi}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <InternalInfoSection />
            <PresentedInfoSection
              mutable={!responses || responses.length === 0}
              form={form}
              values={values}
            />
          </form>
        )}
      />
    </div>
  )
}

DisclosureForm.defaultProps = {
  initialValues: {},
}

DisclosureForm.propTypes = {
  className: PropTypes.string,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}

export default DisclosureForm
