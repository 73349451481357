import React from 'react'
import SlideInRoute from './SlideInRoute'

export default {
  title: 'Leevo UI/Routes/SlideInRoute',
  component: SlideInRoute,
}

export function slideInRoute() {
  return (
    <SlideInRoute
      path="/"
      render={() => (
        <div className="m-5">
          <p className="m-5">This is the content of the route.</p>
          <div className="w-20 h-20 m-5 bg-purple-600 border-2 border-purple-900 rounded-full"></div>
        </div>
      )}
    />
  )
}
