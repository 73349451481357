import React from 'react'
import { MemoryRouter, Route } from 'react-router-dom'

import FacilitiesKlassPackagesNew from './FacilitiesKlassPackagesNew'

export default {
  title: 'FacilitiesKlassPackagesNew',
  component: FacilitiesKlassPackagesNew,
}

export function withSuccessfulRequest() {
  // FIXME: This story doesn't work because we need to mock the
  // response from some endpoints. Not worth the time at the time of
  // this writing, so commenting out until we have time or we implement
  // better fixtures to make this dead simple.

  // const mockAxios = new MockAdapter(axios)

  // mockAxios.reset()
  // mockAxios.onGet(/class_packages/).reply(200)
  // mockAxios.onGet(/klasses/).reply(200)
  // mockAxios.onPost().reply(200)

  return (
    <MemoryRouter initialEntries={['/class_packages/1/new']}>
      <Route path="/class_packages/:klass_id/new">
        <FacilitiesKlassPackagesNew />
      </Route>
      <Route path="/login">
        <div>Redirected to login 😔</div>
      </Route>
    </MemoryRouter>
  )
}
