import React from 'react'
import { mount } from 'enzyme'

import { MemoryRouter, Route } from 'react-router-dom'

import LoggingIn from './LoggingIn'

describe('LoggingIn', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/LoggingIn']}>
          <Route path="/LoggingIn">
            <LoggingIn />
          </Route>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
