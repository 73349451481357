import React from 'react'
import { text, number, select } from '@storybook/addon-knobs'

import RecommendationLevelCard from './RecommendationLevelCard'

export default {
  title: 'Coach Feature/Components/RecommendationLevelCard',
  component: RecommendationLevelCard,
}

const statuses = ['unintroduced', 'introduced']

export function recommendationLevelCard() {
  const skillLevelName = text('Skill Level Name:', 'Basic 1')
  const recommendations = [
    {
      id: '3',
      count: number('First Recommendation Student Count', 4),
      skill: {
        name: text('First Recommendation Name:', 'Forward two-foot slide'),
        shortLabel: text('First Recommendation Label:', 'C'),
      },
      skillLevel: {
        name: skillLevelName,
      },
      status: select('First Recommendation Status', statuses, statuses[0]),
    },
    {
      id: '4',
      count: number('Second Recommendation Student Count', 3),
      skill: {
        name: text('Second Recommendation Name:', 'Dip'),
        shortLabel: text('Second Recommendation Label:', 'D'),
      },
      skillLevel: {
        name: skillLevelName,
      },
      status: select('Second Recommendation Status', statuses, statuses[1]),
    },
  ]

  return (
    <div className="m-4">
      <RecommendationLevelCard recommendations={recommendations} />
    </div>
  )
}
