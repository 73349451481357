import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

import AddStudents from './AddStudents'

export default {
  title: 'Registration Feature/AddStudents',
  component: AddStudents,
}

// FIXME: This story is broken. Worth fixing?
export function addStudents() {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios
    .onGet('/api/users', { params: { email: 'test@example.com' } })
    .reply(200)
  mockAxios.onGet('/api/users').reply(204)
  mockAxios
    .onPost('/auth/sign_in', {
      email: 'test@example.com',
      password: 'password',
    })
    .reply(200, { data: { data: { id: 1, type: 'user' } } })
  mockAxios.onPost('/auth/sign_in').reply(401)
  mockAxios
    .onPost('/auth')
    .reply(200, { data: { data: { id: 1, type: 'user' } } })

  return <AddStudents />
}
