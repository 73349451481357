import React from 'react'
import { shallow, mount } from 'enzyme'

import CheckboxListInput from './CheckboxListInput'
import CheckboxInput from '../CheckboxInput/CheckboxInput'
import CheckButton from '../CheckButton/CheckButton'

describe('CheckboxListInput', () => {
  const options = [
    {
      id: 1,
      label: 'Tom',
      value: 'tom',
    },
    {
      id: 2,
      label: 'Mary',
      value: 'mary',
    },
  ]
  describe('without checkboxes', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(<CheckboxListInput />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('does not render inputs if no checkboxes are passed', () => {
      expect(wrapper.find(CheckboxInput)).not.toExist()
    })
  })

  describe('with options', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(<CheckboxListInput options={options} />)
    })

    test('renders with options', () => {
      expect(wrapper).toExist()
    })

    test('does render input if checkboxes are passed', () => {
      expect(wrapper.find(CheckboxInput)).toHaveLength(2)
    })

    test('renders large checkbox by default', () => {
      expect(wrapper.find(CheckboxInput).at(0).props().small).toBe(false)
    })

    describe('values', () => {
      test('are present when provided', () => {
        wrapper = shallow(
          <CheckboxListInput values={[{ value: 'tom' }]} options={options} />
        )
        expect(wrapper.find(CheckboxInput).at(0).props().checked).toBe(true)
      })
    })

    describe('className', () => {
      test('is present when provided', () => {
        wrapper.setProps({ className: 'some-className' })
        expect(wrapper).toHaveClassName('some-className')
      })
    })

    describe('interactivity', () => {
      const mockOnChange = jest.fn()
      beforeEach(() => {
        wrapper = mount(
          <CheckboxListInput options={options} onChange={mockOnChange} />
        )
      })
      describe('when a value is selected', () => {
        test('it fires the onChange function', () => {
          wrapper.find(CheckButton).at(0).simulate('click')
          expect(mockOnChange).toHaveBeenCalledWith([{ value: 'tom' }])
        })
      })

      describe('when the selected value is changed', () => {
        test('it fires the onChange function', () => {
          wrapper.setProps({ values: [{ value: 'tom' }] })
          wrapper.find(CheckButton).at(0).simulate('click')
          expect(mockOnChange).toHaveBeenCalledWith([])
        })
      })
    })
  })
})
