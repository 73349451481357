import React from 'react'
import faker from 'faker'
import { action } from '@storybook/addon-actions'

import FacilityEditForm from './FacilityEditForm'

export default {
  title: 'FacilityEditFeature/FacilityEditForm',
  component: FacilityEditForm,
  args: {
    id: 1,
    name: faker.company.companyName(),
    address: {
      line1: '5468 Mountain Garland Drive',
      line2: 'Suite A',
      city: 'Colorado Springs',
      state: 'CO',
      postCode: '80923',
    },
    curriculums: [
      { id: 1, name: 'LTS USA' },
      { id: 2, name: 'Excel Gymnastics' },
    ],
    providesFreeRentalSkates: true,
    supportsSkating: true,
    onSubmit: action('Submitted Form'),
    onCardChange: action('Card Changed'),
  },
}

export function newRecord(args) {
  return (
    <FacilityEditForm
      newRecord
      curriculums={args.curriculums}
      onSubmit={args.onSubmit}
    />
  )
}

export function prePopulated(args) {
  return <FacilityEditForm {...args} onSubmit={action('Submitted Form')} />
}
