import React from 'react'
import { mount } from 'enzyme'
import { Form } from 'react-final-form'
import { Router } from 'react-router-dom'
import { createMemoryHistory } from 'history'

import RentalSkatesFields from './RentalSkatesFields'

describe('RentalSkatesFields', () => {
  let wrapper
  const mockHistory = createMemoryHistory()

  beforeEach(() => {
    wrapper = mount(
      <Form
        onSubmit={jest.fn()}
        render={() => (
          <Router history={mockHistory}>
            <RentalSkatesFields />
          </Router>
        )}
      />
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
