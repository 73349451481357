import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from '../../leevo_ui/Button/Button'

const Input = React.forwardRef(
  (
    {
      className,
      label,
      helpText,
      errorMessage,
      id,
      type,
      required,
      disabled,
      maxLength,
      buttonLabel,
      onButtonClick,
      isButtonDisabled,
      value,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={className}>
        {label && (
          <label
            className={`${classNames({
              'border-red-500 text-red-500': errorMessage,
            })} block text-gray-800 text-sm font-medium ml-1`}
            htmlFor={id}
          >
            {label}
            {required && '*'}
          </label>
        )}
        {helpText && <p className="ml-1 text-xs text-gray-500">{helpText}</p>}
        <div className="flex items-center">
          <input
            {...rest}
            ref={ref}
            className={`${classNames({
              'text-red-500 border-red-500 placeholder-red-300 ring ring-red-500 ring-opacity-50 error-shake': errorMessage,
              'text-gray-800 placeholder-gray-500': !errorMessage,
              'shadow-inner': !disabled,
              'bg-gray-100': disabled,
            })} text-sm border rounded w-full py-2 px-3 leading-tight focus:ring focus:border-purple-500 focus:ring-purple-300 focus:ring-opacity-50  border-gray-300 mt-2 focus:outline-none`}
            id={id}
            type={type}
            disabled={disabled}
            required={required}
            maxLength={maxLength}
            value={value}
            data-cy={`${id}-input`}
          />
          {buttonLabel && onButtonClick && (
            <div>
              <Button
                label={buttonLabel}
                small
                className="mt-1 ml-2"
                onClick={onButtonClick}
                disabled={disabled || isButtonDisabled}
                data-cy={`${id}-input-button`}
              />
            </div>
          )}
        </div>
        <div className="flex items-center justify-between">
          <p
            data-cy="error-message"
            className="mt-1 mb-3 text-xs italic text-red-500"
          >
            {errorMessage}
          </p>
          {maxLength && (
            <span
              className={`${classNames({
                hidden: maxLength < 15,
                'text-gray-600': maxLength - value.length > 10,
                'text-red-500': maxLength - value.length <= 10,
              })} inline-block w-full text-right text-xs`}
            >
              {maxLength - value.length} characters remaining
            </span>
          )}
        </div>
      </div>
    )
  }
)

Input.displayName = 'Input'

Input.defaultProps = {
  value: '',
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password', 'number', 'search']).isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  helpText: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
  isButtonDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  value: PropTypes.string,
}

export default Input
