import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { RRuleSet } from 'rrule'
import axios from 'axios'

import { requestCreator } from '../../utilities/requests'
import { ruleSetFromRecurrenceRule } from '../../utilities/helpers'
import FacilitiesKlassDaysIndex from './FacilitiesKlassDaysIndex'
import useFacility from '../../utilities/hooks/useFacility'

const { get: getKlasses, cancel: cancelKlassesRequest } = requestCreator()

function FacilitiesKlassDaysIndexFeature() {
  const { push: historyPush, replace: redirect } = useHistory()
  const location = useLocation()
  const { facility, isLoading: isFacilityLoading } = useFacility()
  const [klasses, setKlasses] = useState([])
  const [isLoadingKlasses, setIsLoadingKlasses] = useState(true)

  const { date, month } = queryString.parse(location.search)
  const selectedDate = date ? moment(date) : null
  const selectedMonth = month
    ? moment()
        .year(month.split('-')[0])
        .month(month.split('-')[1])
        .subtract(1, 'month')
    : moment()

  useEffect(() => {
    setIsLoadingKlasses(true)
    if (facility.id) {
      getKlasses(`/api/facilities/${facility.id}/klasses`, {
        params: { all: true, include: 'coaches,supported_skill_levels' },
      })
        .then(setKlasses)
        .then(() => setIsLoadingKlasses(false))
        .catch((error) => {
          if (axios.isCancel(error)) {
            return
          } else if (error.response.status == 401) {
            redirect('/login')
          } else {
            throw error
          }
        })
    }

    return cancelKlassesRequest
  }, [facility.id, redirect])

  useEffect(() => {
    if (date || month || isFacilityLoading || klasses.length === 0) return

    navigateToMonth(moment())
  }, [klasses, isFacilityLoading, date, month, navigateToDate, navigateToMonth])

  function combinedRRuleFor(klasses) {
    return klasses.reduce(
      (newRuleSet, { recurrenceRule }) =>
        ruleSetFromRecurrenceRule(recurrenceRule, newRuleSet),
      new RRuleSet()
    )
  }

  const navigateToDate = useCallback(
    (date) => {
      const { date: locationDate } = queryString.parse(location.search)
      if (locationDate && date.isSame(selectedDate, 'day')) return

      const search = `?date=${date.format('YYYY-MM-DD')}`
      historyPush({ search })
    },
    [location.search, historyPush, selectedDate]
  )

  const navigateToMonth = useCallback(
    (month) => {
      const { month: locationMonth } = queryString.parse(location.search)
      if (locationMonth && month.isSame(selectedMonth, 'month')) return

      const search = `?month=${month.format('YYYY-MM')}`
      historyPush({ search })
    },
    [location.search, historyPush, selectedMonth]
  )

  function getDaysThisWeekWithClasses() {
    if (klasses.length === 0) return []

    const beginningOfWeek = moment(date).startOf('week').toDate()
    const endOfWeek = moment(date).endOf('week').toDate()
    const rule = combinedRRuleFor(klasses)
    const daysOfWeekForAllClasses = rule
      .between(beginningOfWeek, endOfWeek, true)
      .map((datetime) => moment(datetime).format('dddd'))
      .flat()
    const uniqueDaysOfWeekForAllClasses = daysOfWeekForAllClasses

    return uniqueDaysOfWeekForAllClasses
  }

  return (
    <FacilitiesKlassDaysIndex
      availableDays={getDaysThisWeekWithClasses()}
      isLoadingKlasses={isLoadingKlasses}
      facility={facility}
      klasses={klasses}
      selectedDate={selectedDate}
      selectedMonth={selectedMonth}
      onDateSelected={navigateToDate}
      onMonthChange={navigateToMonth}
    />
  )
}

export default FacilitiesKlassDaysIndexFeature
