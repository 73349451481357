import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Input from '../Input/Input'
import SelectInput from '../SelectInput/SelectInput'

const STATES = [
  { label: '', value: '' },
  { label: 'AL', value: 'AL' },
  { label: 'AK', value: 'AK' },
  { label: 'AS', value: 'AS' },
  { label: 'AZ', value: 'AZ' },
  { label: 'AR', value: 'AR' },
  { label: 'CA', value: 'CA' },
  { label: 'CO', value: 'CO' },
  { label: 'CT', value: 'CT' },
  { label: 'DE', value: 'DE' },
  { label: 'DC', value: 'DC' },
  { label: 'FM', value: 'FM' },
  { label: 'FL', value: 'FL' },
  { label: 'GA', value: 'GA' },
  { label: 'GU', value: 'GU' },
  { label: 'HI', value: 'HI' },
  { label: 'ID', value: 'ID' },
  { label: 'IL', value: 'IL' },
  { label: 'IN', value: 'IN' },
  { label: 'IA', value: 'IA' },
  { label: 'KS', value: 'KS' },
  { label: 'KY', value: 'KY' },
  { label: 'LA', value: 'LA' },
  { label: 'ME', value: 'ME' },
  { label: 'MH', value: 'MH' },
  { label: 'MD', value: 'MD' },
  { label: 'MA', value: 'MA' },
  { label: 'MI', value: 'MI' },
  { label: 'MN', value: 'MN' },
  { label: 'MS', value: 'MS' },
  { label: 'MO', value: 'MO' },
  { label: 'MT', value: 'MT' },
  { label: 'NE', value: 'NE' },
  { label: 'NV', value: 'NV' },
  { label: 'NH', value: 'NH' },
  { label: 'NJ', value: 'NJ' },
  { label: 'NM', value: 'NM' },
  { label: 'NY', value: 'NY' },
  { label: 'NC', value: 'NC' },
  { label: 'ND', value: 'ND' },
  { label: 'MP', value: 'MP' },
  { label: 'OH', value: 'OH' },
  { label: 'OK', value: 'OK' },
  { label: 'OR', value: 'OR' },
  { label: 'PW', value: 'PW' },
  { label: 'PA', value: 'PA' },
  { label: 'PR', value: 'PR' },
  { label: 'RI', value: 'RI' },
  { label: 'SC', value: 'SC' },
  { label: 'SD', value: 'SD' },
  { label: 'TN', value: 'TN' },
  { label: 'TX', value: 'TX' },
  { label: 'UT', value: 'UT' },
  { label: 'VT', value: 'VT' },
  { label: 'VI', value: 'VI' },
  { label: 'VA', value: 'VA' },
  { label: 'WA', value: 'WA' },
  { label: 'WV', value: 'WV' },
  { label: 'WI', value: 'WI' },
  { label: 'WY', value: 'WY' },
]

// FIXME: This should only be loaded once in the whole app, not on every mounting of this component.
function loadScript(url, callback) {
  const existingScript = document.getElementById(url)
  if (!existingScript) {
    const script = document.createElement('script')
    script.src = url
    script.id = url
    document.getElementsByTagName('head')[0].appendChild(script)
    if (script.readyState) {
      script.onreadystatechange = () => {
        if (
          script.readyState === 'loaded' ||
          script.readyState === 'complete'
        ) {
          script.onreadystatechange = null
          callback()
        }
      }
    } else {
      script.onload = () => callback()
    }
  }
  if (existingScript) callback()
}

function AddressInput({
  className,
  value,
  onChange,
  label,
  helpText,
  errorMessage,
}) {
  let autoComplete, addressObject
  const [address, setAddress] = useState(value)
  const autoCompleteRef = useRef(null)

  function handleScriptLoad(autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ['address'], componentRestrictions: { country: 'us' } }
    )
    autoComplete.setFields(['address_components'])
    autoComplete.addListener('place_changed', () => handlePlaceSelect())
  }

  function getProperty(identifier) {
    const defaultValue = { long_name: '', short_name: '' }
    const addressComponents = addressObject.address_components
    const foundValue =
      addressComponents &&
      addressComponents.find((component) =>
        component.types.includes(identifier)
      )
    return foundValue || defaultValue
  }

  function handlePlaceSelect() {
    addressObject = autoComplete.getPlace()

    const newAddress = {
      line1: `${getProperty('street_number').long_name} ${
        getProperty('route').short_name
      }`,
      line2: '',
      city: (getProperty('locality') || getProperty('sublocality')).long_name,
      state: getProperty('administrative_area_level_1').short_name,
      postCode: getProperty('postal_code').long_name,
    }
    setAddress(newAddress)
    onChange(newAddress)
  }

  function updateField(name, value) {
    const newAddress = { ...address, [name]: value }
    setAddress(newAddress)
    onChange(newAddress)
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad(autoCompleteRef)
    )
    // Clears all fields when the line1 search is cleared.
    autoCompleteRef.current.addEventListener('search', () => {
      setAddress({ line1: '' })
    })
    if (Object.keys(value).length > 0) {
      setAddress({ ...value })
    }
  }, []) /* eslint-disable-line */ // FIXME

  return (
    <div className={className}>
      {label && (
        <>
          <label
            className={`${classNames({
              'text-red-500': errorMessage,
            })} block text-gray-700 text-lg font-medium ml-1`}
          >
            {label}
          </label>
          <div className="pt-2 pb-3 w-16">
            <div className="border-t-2 border-purple-600 rounded-sm" />
          </div>
        </>
      )}
      {helpText && <p className="mb-6 text-xs">{helpText}</p>}
      {errorMessage && (
        <p className="mt-1 mb-3 text-xs italic text-red-500">{errorMessage}</p>
      )}
      <div className="flex flex-col items-center">
        <Input
          ref={autoCompleteRef}
          className="w-full"
          id="address-line1"
          label="Address Line 1"
          placeholder="Start typing your address..."
          value={address.line1}
          onChange={(event) => updateField('line1', event.target.value)}
          type="search"
        />
        <Input
          id="address-line2"
          className="w-full"
          type="search"
          label="Address Line 2"
          placeholder="Apartment or unit number, if needed"
          value={address.line2}
          onChange={(event) => updateField('line2', event.target.value)}
        />
        <div className="flex flex-row justify-between w-full space-x-3">
          <Input
            id="city"
            className="w-full"
            type="text"
            label="City"
            value={address.city}
            onChange={(event) => updateField('city', event.target.value)}
          />
          <SelectInput
            id="state"
            label="State"
            className="w-40"
            options={STATES}
            value={address.state}
            onChange={(event) => updateField('state', event.target.value)}
          />
          <Input
            id="postal-code"
            className="w-40"
            type="text"
            label="ZIP"
            value={address.postCode}
            onChange={(event) => updateField('postCode', event.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

AddressInput.defaultProps = {
  value: {},
  label: '',
  helpText: '',
}

AddressInput.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  value: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postCode: PropTypes.string,
  }),
  onChange: PropTypes.func,
}

export default AddressInput
