import React from 'react'
import AddCoachModal from './AddCoachModal'
import { mount } from 'enzyme'
import { MemoryRouter, Route } from 'react-router-dom'

describe('AddCoachModal', () => {
  let wrapper

  beforeEach(() => {
    wrapper = mount(
      <MemoryRouter initialEntries={['/:facility_id/']}>
        <Route path="/1/">
          <AddCoachModal />
        </Route>
      </MemoryRouter>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  //FIXME: Couldn't figure out how to get submissions to work.
  // Should circle back and test submission logic when able.
  it.todo('Add specs to cover submission logic')
})
