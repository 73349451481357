import React from 'react'

import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'

function RouteChangeNotification() {
  const newRoute = window.location.toString().replace('facility', 'facilities')

  return (
    <ApplicationLayout>
      <section className="m-4">
        <h1 className="mb-6 text-2xl text-purple-800">
          {"You're accessing an outdated address."}
        </h1>
        <p className="mb-2">{'This page has moved to:'}</p>
        <p className="mb-6 ml-4">
          <a
            className="font-mono text-purple-600 hover:text-purple-400"
            href={newRoute}
          >
            {newRoute}
          </a>
        </p>
        <p>
          {'If you hit this page from a bookmark, you may want to update it!'}
        </p>
      </section>
    </ApplicationLayout>
  )
}

export default RouteChangeNotification
