import React from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'
import Button from '../Button/Button'
import classNames from 'classnames'

function EmptyList({
  svgSrc,
  emoji,
  message,
  buttonText,
  onButtonClick,
  ...rest
}) {
  return (
    <div className="flex flex-col justify-center m-12" {...rest}>
      {svgSrc && <SVG className="w-32 m-auto" src={svgSrc} />}
      {emoji && <p className="text-5xl m-auto">{emoji}</p>}
      <p
        className={classNames('m-auto font-light text-center', {
          'pt-10': svgSrc,
        })}
      >
        {message}
      </p>
      {buttonText && onButtonClick && (
        <div className="flex justify-center pt-5">
          <Button color="purple" label={buttonText} onClick={onButtonClick} />
        </div>
      )}
    </div>
  )
}

EmptyList.defaultProps = {
  svgSrc: null,
  onButtonClick: () => {},
  buttonText: '',
}

EmptyList.propTypes = {
  onButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
  message: PropTypes.string,
  emoji: PropTypes.string,
  svgSrc: PropTypes.string,
}

export default EmptyList
