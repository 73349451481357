import React from 'react'
import { mount } from 'enzyme'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { MemoryRouter, Route } from 'react-router-dom'
import { act } from 'react-dom/test-utils'

import FacilitiesKlassesNew from './FacilitiesKlassesNew'
import MockFacilityProvider from '../../providers/MockFacilityProvider'

const mockAxios = new MockAdapter(axios)

// FIXME: While all these tests pass, there are console.errors being
// generated by enzyme. We have brought this to the attention of the enzyme
// team: https://github.com/enzymejs/enzyme/issues/2011#issuecomment-602121952
// If it's a big deal in the future, and they don't fix this, we can mock console.error just
// for this spec.
describe('FacilitiesKlassesNew', () => {
  let wrapper

  beforeEach(async () => {
    const attributes = {
      email: 'foo@bar.com',
      preferredName: 'Foo',
      fullName: 'Foo Bar',
      gender: 'female',
      ltsNumber: '123456',
      birthdate: new Date(),
      skillLevel: '14',
    }

    const relationships = { currentSkillLevel: { data: { id: 1 } } }

    mockAxios.reset()
    mockAxios.onGet().reply(200, { data: { attributes, relationships } })

    wrapper = mount(
      <MemoryRouter initialEntries={['/1']}>
        <Route path="/:facility_id">
          <MockFacilityProvider>
            <FacilitiesKlassesNew />
          </MockFacilityProvider>
        </Route>
      </MemoryRouter>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  describe('submission', () => {
    beforeEach(async () => {
      act(() => {
        wrapper
          .find('input[name="fullName"]')
          .simulate('change', { target: { value: 'foo' } })
      })
    })

    describe('when successful', () => {
      beforeEach(async () => {
        mockAxios.reset()
        mockAxios.onPut().reply(200)
        act(() => {
          wrapper.find('form').simulate('submit')
        })
      })

      // FIXME: This was working until we switched to notification component.
      //        Was difficult to fix, so marked as TODO
      test.todo('displays success message')
      // eslint-disable-next-line jest/no-commented-out-tests
      // test('displays success message', () => {
      //   const notification = wrapper.find('[data-cy="notification"]')
      //   expect(notification).toHaveText('Changes saved!')
      // })
    })

    describe('when unsuccessful', () => {
      describe('with provided message', function () {
        beforeEach(async () => {
          mockAxios.reset()
          mockAxios.onPut().reply(500, {
            error: 'Something went wrong.',
          })
          await act(async () => {
            wrapper.find('form').simulate('submit')
          })
        })

        // FIXME: This was working until we switched to notification component.
        //        Was difficult to fix, so marked as TODO
        test.todo('displays success message')
        // eslint-disable-next-line jest/no-commented-out-tests
        // test('displays success message', () => {
        //   const notification = wrapper.find('[data-cy="notification"]')
        //   expect(notification).toHaveText('Something went wrong.')
        // })
      })

      describe('without provided message', function () {
        beforeEach(async () => {
          mockAxios.reset()
          mockAxios.onPut().reply(500, {})
          await act(async () => {
            wrapper.find('form').simulate('submit')
          })
        })

        // FIXME: This was working until we switched to notification component.
        //        Was difficult to fix, so marked as TODO
        test.todo('displays success message')
        // eslint-disable-next-line jest/no-commented-out-tests
        // test('displays success message', () => {
        // const notification = wrapper.find('[data-cy="notification"]')
        //   const defaultMessage = 'We were unable to save your changes.'
        //   expect(notification).toHaveText(defaultMessage)
        // })
      })
    })
  })
})
