import React from 'react'
import PropTypes from 'prop-types'

import Avatar from '../../leevo_ui/Avatar/Avatar'
import Badge from '../../leevo_ui/Badge/Badge'
import { readableSkateType } from '../../../utilities/helpers'

function SkateRentalsListItem({
  userId,
  fullName,
  cloudinaryPhotoPublicId,
  size,
  skateType,
}) {
  return (
    <li
      data-cy="skate-rental-list-item"
      className="flex items-center justify-between p-4 text-gray-800 border-b border-gray-300 w-full"
    >
      <section className="flex items-center justify-start w-5/8 truncate">
        <Avatar
          className="hidden md:block"
          fullName={fullName}
          size={12}
          cloudinaryPhotoPublicId={cloudinaryPhotoPublicId}
          userId={userId}
          isStudent
        />
        <p className="mx-2 truncate">{fullName}</p>
      </section>
      <section className="md:flex flex-shrink-0 space-y-1 md:space-y-0 md:space-x-2 text-right w-3/8 justify-end">
        <Badge
          className="truncate"
          text={`${readableSkateType(skateType).label} - ${size}`}
          color={readableSkateType(skateType).color}
        />
      </section>
    </li>
  )
}

SkateRentalsListItem.defaultProps = {
  fullName: '',
  cloudinaryPhotoPublicId: '',
  skateType: '',
}

SkateRentalsListItem.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fullName: PropTypes.string,
  cloudinaryPhotoPublicId: PropTypes.string,
  size: PropTypes.number,
  skateType: PropTypes.oneOf([
    'men_figure',
    'women_figure',
    'youth_figure',
    'men_hockey',
    'women_hockey',
    'youth_hockey',
  ]),
}

export default SkateRentalsListItem
