import React from 'react'
import FacilitiesNewFeature from './FacilitiesNewFeature'

export default {
  title: 'Leevo UI/FacilitiesNewFeature',
  component: FacilitiesNewFeature,
  args: {
    className: 'm-4',
  },
}

export function facilitiesNewFeature(args) {
  return <FacilitiesNewFeature {...args} />
}
