import React from 'react'
import { mount } from 'enzyme'

import { MemoryRouter, Route } from 'react-router-dom'

import ContentPageLayout from './ContentPageLayout'

describe('ContentPageLayout', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/ContentPageLayout']}>
          <Route path="/ContentPageLayout">
            <ContentPageLayout title="foo" />
          </Route>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
