import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { MemoryRouter, Route } from 'react-router-dom'

import faker from 'faker'

import FacilitiesCoachesIndex from './FacilitiesCoachesIndex'

export default {
  title: 'Facilities Coaches Index',
  component: FacilitiesCoachesIndex,
}

export function facilitiesCoachesIndex() {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onGet('/api/facilities/1').reply(200, {
    data: {
      id: 1,
      type: 'facility',
      attributes: {
        name: faker.company.companyName(),
      },
    },
  })

  mockAxios.onGet('/api/facilities/1/coaches').reply(200, {
    data: [
      {
        id: '1',
        type: 'coach',
        attributes: {
          fullName: 'Active Coach',
          preferredName: 'Active',
          email: 'active@coach.com',
          cloudinaryPhotoPublicId: null,
          'invitedToSignUp?': false,
          'invitationAccepted?': false,
        },
        relationships: {
          facilities: {
            data: [{ id: '2', type: 'facility' }],
          },
          klassDays: {
            data: [],
          },
        },
      },
      {
        id: '2',
        type: 'coach',
        attributes: {
          fullName: 'Pending Coach',
          preferredName: 'Pending',
          email: 'pending@coach.com',
          cloudinaryPhotoPublicId: null,
          'invitedToSignUp?': true,
          'invitationAccepted?': false,
        },
        relationships: {
          facilities: {
            data: [{ id: '2', type: 'facility' }],
          },
          klassDays: {
            data: [],
          },
        },
      },
    ],
  })

  return (
    <MemoryRouter initialEntries={['/facilities/1/klasses']}>
      <Route path="/facilities/:facility_id/klasses">
        <FacilitiesCoachesIndex />
      </Route>
    </MemoryRouter>
  )
}
