import React from 'react'
import DropToUploadInput from './DropToUploadInput'
import { shallow } from 'enzyme'

describe('DropToUploadInput', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(<DropToUploadInput />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
