import React from 'react'
import MarketingPageFooter from './MarketingPageFooter'
import faker from 'faker'
import { action } from '@storybook/addon-actions'

const mockHistory = { push: (route) => action(`Navigate to ${route}`) }

export default {
  title: 'Leevo UI/Marketing Page Footer',
  component: MarketingPageFooter,
}

export function marketingPageFooter() {
  return (
    <div className="bg-gray-100">
      <section>{faker.lorem.paragraph()}</section>
      <MarketingPageFooter history={mockHistory} />
    </div>
  )
}
