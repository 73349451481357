import React from 'react'

import RightDrawer from './RightDrawer'

export default {
  title: 'Leevo UI/RightDrawer',
  component: RightDrawer,
}

export function RightDrawerComponent() {
  return (
    <RightDrawer>
      <div>I am in the drawer</div>
    </RightDrawer>
  )
}
