import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import pluralize from 'pluralize'

import Card from '../../leevo_ui/Card/Card'
import Badge from '../../leevo_ui/Badge/Badge'

function KlassPackageCard({
  id,
  active,
  title,
  facilityId,
  students,
  description,
  loading,
}) {
  return loading ? (
    <Card
      className="relative flex flex-col m-2 w-80 text-left animate-pulse"
      borderColor="gray"
    >
      <div className="px-4 py-2">
        <header>
          <section className="flex justify-between items-center pb-2 -ml-1">
            <div className="w-16 h-4 px-2 bg-gray-200 rounded-full" />
            <div className="w-24 h-4 px-2 bg-gray-200 rounded-full" />
          </section>
          <section className="space-y-1">
            <div className="w-24 h-5 px-2 rounded-md bg-gray-300" />
            <div className="w-28 h-5 px-2 rounded-md bg-gray-300" />
            <div className="w-16 h-5 px-2 rounded-md bg-gray-300" />
          </section>
        </header>
        <div className="w-44 h-4 px-2 rounded-md bg-gray-300 mt-5 mb-6" />
      </div>
      <footer className="w-full h-16 py-4 mt-3 text-center border-t border-gray-300 rounded-b-lg hover:bg-gray-100" />
    </Card>
  ) : (
    <Card
      interactive
      className="relative flex flex-col m-2 w-80 text-left"
      borderColor={active ? 'green' : 'gray'}
      data-cy="klass-package-item"
      onClick={() =>
        history.push(`/facilities/${facilityId}/class_packages/${id}/edit`)
      }
    >
      <div className="flex-grow px-4 py-2 w-full">
        <header>
          <section className="flex justify-between items-center pb-2 -ml-1">
            <Badge
              text={active ? 'Available' : 'Unavailable'}
              color={active ? 'green' : 'gray'}
            />
            <p className="font-semibold">
              {students.length} {pluralize('student', students.length)}
            </p>
          </section>
          <div className="flex items-start justify-between">
            <span className="inline-block text-base font-semibold xs:text-lg">
              {title}
            </span>
          </div>
        </header>
        <div className="flex flex-col justify-between mt-5 mb-3">
          <p className="inline-block text-sm">{description}</p>
        </div>
      </div>
      <footer className="w-full py-4 mt-3 text-center border-t border-gray-300 rounded-b-lg hover:bg-gray-100">
        <Link
          to={`/facilities/${facilityId}/class_packages/${id}/edit`}
          className="text-sm font-medium"
          data-cy="edit-class-package-button"
        >
          Edit this package
        </Link>
      </footer>
    </Card>
  )
}

KlassPackageCard.defaultProps = {
  id: '',
  active: false,
  loading: false,
  title: '',
  students: [],
  description: '',
  facilityId: '',
}

KlassPackageCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  active: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  students: PropTypes.array,
  description: PropTypes.string,
  facilityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default KlassPackageCard
