import React from 'react'

import {
  DocumentTextIcon,
  IdentificationIcon,
  LockClosedIcon,
  TruckIcon,
  CheckCircleIcon,
  TicketIcon,
  TemplateIcon,
  ChatIcon,
  UserGroupIcon,
  HandIcon,
  DeviceMobileIcon,
  CashIcon,
  PlusIcon,
} from '@heroicons/react/outline'

const features = [
  {
    name: 'Custom Curriculum Support',
    description:
      "Don't use the LTSUSA curriculum? We can load your own. It can even break levels into categories such as jumps and spins.",
    icon: TemplateIcon,
  },
  {
    name: 'White Glove Setup',
    description:
      'You work directly with someone on our team to get set up for your first session. With 5 minutes of your time, you can be set up to process registrations today.',
    icon: HandIcon,
  },
  {
    name: 'Free Parent Support',
    description:
      "Don't worry about answering questions parents have about Leevo. We provide free chat support with an average response time of 15 minutes to answer any questions.",
    icon: ChatIcon,
  },
  {
    name: 'Disclosures',
    description:
      'Present your waivers, agreements and disclosures through Leevo, and require that parents accept them on registration. We log when they were signed for you.',
    icon: DocumentTextIcon,
  },
  {
    name: 'Name Badge Exporting',
    description:
      'Generate a spreadsheet to create name badges for all enrolled students and make it easy to direct students to the right place.',
    icon: IdentificationIcon,
  },
  {
    name: 'Access Controls',
    description:
      "Coaches can only see and change the things they need, and nothing they don't. Coaches focus on classes, you focus on the program.",
    icon: LockClosedIcon,
  },
  {
    name: 'Simple Parent Onboarding',
    description:
      "Parents easily create accounts to see their student's progress. In less than 30 seconds, parents can access registration and their own dashboard.",
    icon: TruckIcon,
  },
  {
    name: 'Error-Free Registration',
    description:
      "Leevo's simple 3-minute registration process automatically puts kids in the right place, and makes it fool-proof so no one registers incorrectly.",
    icon: CheckCircleIcon,
  },
  {
    name: 'Skate Rentals',
    description:
      'Streamline your skate rentals by allowing parents to specify skate rental sizes for students. Then, you can have the skates ready for them as soon as they come in.',
    icon: TicketIcon,
  },
  {
    name: 'Simple Session Setup',
    description:
      'Create classes and package them into sessions in a snap. Set up your sessions for months in advance, and let your program run on auto pilot.',
    icon: UserGroupIcon,
  },
  {
    name: 'Any Device, Any Time',
    description:
      'Access Leevo from any web browser, on any device - desktop, tablet or mobile. Leevo makes it easy to make quick changes on the fly.',
    icon: DeviceMobileIcon,
  },

  {
    name: 'Parent Dashboard',
    description:
      'Parents can log in at any time to see how their kiddo is progressing in your program, reducing confusion and frustration.',
    icon: DeviceMobileIcon,
  },
  {
    name: 'Coupon Codes',
    description:
      'Issue coupon codes with a max number of redemptions. Perfect for issuing credits or offering unlimited discounts, such as a military discount.',
    icon: TicketIcon,
  },
  {
    name: 'Multi-class discounts',
    description:
      'Incentivize parents and students to sign up for multiple classes by providing a discount for additional classes for each student.',
    icon: CashIcon,
  },
  {
    name: 'Multi-student discounts',
    description:
      'Offer incentives to parents to register additional students by offering a discount for additional students.',
    icon: PlusIcon,
  },
]

function FeatureGrid() {
  return (
    <div className="bg-purple-900">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white dark:text-gray-300 tracking-tight">
          And Leevo does so much more...
        </h2>
        <p className="mt-4 max-w-3xl text-lg text-purple-200 dark:text-purple-300">
          We&apos;ve got you covered for everything you need for an incredible
          skating program.
        </p>
        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name}>
              <div>
                <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  <feature.icon
                    className="h-6 w-6 text-white dark:text-gray-300"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h3 className="text-lg font-medium text-white dark:text-gray-300">
                  {feature.name}
                </h3>
                <p className="mt-2 text-base text-purple-200 dark:text-purple-300">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

FeatureGrid.defaultProps = {}

FeatureGrid.propTypes = {}

export default FeatureGrid
