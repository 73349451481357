import React from 'react'
import { Form } from 'react-final-form'
import { action } from '@storybook/addon-actions'
import { text, boolean } from '@storybook/addon-knobs'

import MultiEmailField from './MultiEmailField'
import Button from '../../leevo_ui/Button/Button'

export default {
  title: 'Registration Feature/MultiEmailField',
  component: MultiEmailField,
}

export function multiEmailField() {
  const classes = boolean('Margins?', true) ? 'm-5' : null
  return (
    <Form
      onSubmit={action('Submit')}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <MultiEmailField
            className={classes}
            label={text('Label', 'Enter emails of important people')}
          />
          <Button
            className={classes || 'mt-2'}
            label="Submit"
            onClick={handleSubmit}
          />
        </form>
      )}
    />
  )
}
