import React from 'react'
import { shallow, mount } from 'enzyme'
import { Form } from 'react-final-form'

import PreferredNameField from './PreferredNameField'

describe('PreferredNameField', () => {
  let wrapper

  test('renders', () => {
    wrapper = shallow(<PreferredNameField />)
    expect(wrapper).toExist()
  })

  test('renders className on topmost div', () => {
    wrapper = mount(
      <Form
        onSubmit={jest.fn()}
        render={() => <PreferredNameField className="foo" />}
      />
    )
    expect(wrapper.find('div').first()).toHaveClassName('foo')
  })

  describe('label', () => {
    test("renders 'What's your preferred name?' by default", () => {
      wrapper = mount(
        <Form onSubmit={jest.fn()} render={() => <PreferredNameField />} />
      )
      expect(wrapper).toIncludeText('What do you prefer to be called?')
    })

    test('renders the provided value', () => {
      wrapper = mount(
        <Form
          onSubmit={jest.fn()}
          render={() => <PreferredNameField label="some label" />}
        />
      )
      expect(wrapper).toIncludeText('some label')
    })
  })
})
