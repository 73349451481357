import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'

function sendPageView(location) {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
}

// This code has mostly been lifted from here:
// http://github.com/react-ga/react-ga/issues/122#issuecomment-500497186
function GAListener({ children, trackingId, history }) {
  useEffect(() => {
    ReactGA.initialize(trackingId, { debug: false })
    sendPageView(history.location)
    // history.listen() returns a function that is later called to unlisten
    // Reference: https://github.com/ReactTraining/history/blob/master/docs/GettingStarted.md#basic-usage
    const unlisten = history.listen(sendPageView)
    return () => unlisten()
  }, [history, trackingId])

  return children
}

GAListener.propTypes = {
  children: PropTypes.node,
  trackingId: PropTypes.string,
  history: PropTypes.shape({
    listen: PropTypes.func,
  }),
}

export default withRouter(GAListener)
