import React from 'react'
import faker from 'faker'
import { MemoryRouter, Route } from 'react-router-dom'

import MarketingNavBar from './MarketingNavBar'

export default {
  title: 'Leevo UI/NavBar/MarketingNavBar',
  component: MarketingNavBar,
  args: {
    links: [
      { to: '/', name: 'Home' },
      { to: '/some/path', name: faker.lorem.word() },
      { to: '/some/other/path', name: faker.lorem.word() },
    ],
  },
}

export function marketingNavBar(args) {
  return (
    <MemoryRouter initialEntries={['/about']}>
      <Route path="/about">
        <MarketingNavBar {...args} />
      </Route>
    </MemoryRouter>
  )
}
