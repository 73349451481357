import React, { useEffect, useLayoutEffect } from 'react'
import { hot } from 'react-hot-loader/root'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import FullStory from 'react-fullstory'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import mixpanel from '../utilities/mixpanel'

import ScrollToTop from './utilities/ScrollToTop'
import FavIcons from './utilities/FavIcons'
import ProtectedRoute from './utilities/ProtectedRoute/ProtectedRoute'
import GoogleAnalyticsTracker from './utilities/GoogleAnalyticsTracker'
import FacilityProvider from '../providers/FacilityProvider'
import AuthProvider, {
  setAuthHeaders,
  storeAuthHeaders,
} from '../providers/AuthProvider'

import LoginFeature from './LoginFeature/LoginFeature'
import LogoutFeature from './LogoutFeature/LogoutFeature'
import ForgotPasswordFeature from './ForgotPasswordFeature/ForgotPasswordFeature'
import NewPasswordFeature from './NewPasswordFeature/NewPasswordFeature'
import PricingFeature from './PricingFeature/PricingFeature'
import FacilitiesKlassDaysIndexFeature from './FacilitiesKlassDaysIndex/FacilitiesKlassDaysIndexFeature'
import AboutFeature from './AboutFeature/AboutFeature'
import HomeFeature from './HomeFeature/HomeFeature'
import ProfileFeature from './ProfileFeature/ProfileFeature'
import CoachFeature from '../components/CoachFeature/CoachFeature'
import NoMatch from './NoMatch/NoMatch'
import RouteChangeNotification from './RouteChangeNotification/RouteChangeNotification'
import RegistrationFeature from './RegistrationFeature/RegistrationFeature'
import FacilityEditFeature from './FacilityEditFeature/FacilityEditFeature'
import FacilitiesNewFeature from './FacilitiesNewFeature/FacilitiesNewFeature'
import StudentsEditFeature from './StudentsEditFeature/StudentsEditFeature'
import StudentsNewFeature from './StudentsNewFeature/StudentsNewFeature'
import FacilitiesKlassesNew from './FacilitiesKlassesNew/FacilitiesKlassesNew'
import FacilitiesKlassesEdit from './FacilitiesKlassesEdit/FacilitiesKlassesEdit'
import FacilitiesKlassesIndex from './FacilitiesKlassesIndex/FacilitiesKlassesIndex'
import FacilitiesCoachesIndex from './FacilitiesCoachesIndex/FacilitiesCoachesIndex'
import FacilitiesStudentsIndex from './FacilitiesStudentsIndex/FacilitiesStudentsIndex'
import FacilitiesDiscountsIndex from './FacilitiesDiscountsIndex/FacilitiesDiscountsIndex'
import FacilitiesSkateRentalsIndex from './FacilitiesSkateRentalsIndex/FacilitiesSkateRentalsIndex'
import FacilitiesDisclosuresIndex from './FacilitiesDisclosuresIndex/FacilitiesDisclosuresIndex'
import FacilitiesDisclosuresNew from './FacilitiesDisclosuresNew/FacilitiesDisclosuresNew'
import FacilitiesDisclosuresEdit from './FacilitiesDisclosuresEdit/FacilitiesDisclosuresEdit'
import FacilitiesKlassPackagesIndex from './FacilitiesKlassPackagesIndex/FacilitiesKlassPackagesIndex'
import FacilitiesKlassPackagesNew from './FacilitiesKlassPackagesNew/FacilitiesKlassPackagesNew'
import FacilitiesKlassPackagesEdit from './FacilitiesKlassPackagesEdit/FacilitiesKlassPackagesEdit'
import GuardiansStudentsIndex from './GuardiansStudentsIndex/GuardiansStudentsIndex'
import AdminGuardiansIndex from './AdminGuardiansIndex/AdminGuardiansIndex'
import AdminLTSReportsNew from './AdminLTSReportsNew/AdminLTSReportsNew'
import AdminFacilitiesIndex from './AdminFacilitiesIndex/AdminFacilitiesIndex'
import AcceptInvitationFeature from './AcceptInvitationFeature/AcceptInvitationFeature'
import StudentImportsNew from './StudentImportsNew/StudentImportsNew'
import PrivacyPolicyFeature from './PrivacyPolicyFeature/PrivacyPolicyFeature'
import TermsAndConditionsFeature from './TermsAndConditionsFeature/TermsAndConditionsFeature'
import CookiePolicyFeature from './CookiePolicyFeature/CookiePolicyFeature'
import DisclaimerFeature from './DisclaimerFeature/DisclaimerFeature'
import RefundPolicyFeature from './RefundPolicyFeature/RefundPolicyFeature'
import LinkingStripe from './leevo_ui/static/support/LinkingStripe/LinkingStripe'
import HandlingLTSRegistrations from './leevo_ui/static/support/HandlingLTSRegistrations/HandlingLTSRegistrations'
import LoggingIn from './leevo_ui/static/support/LoggingIn/LoggingIn'

axios.interceptors.request.use(setAuthHeaders)
axios.interceptors.request.use((config) => {
  if (config.data && config.headers['Content-Type'] !== 'multipart/form-data') {
    config.data = snakeCaseKeys(config.data)
  }
  if (config.params) config.params = snakeCaseKeys(config.params)
  return config
})
axios.interceptors.response.use(storeAuthHeaders)

function Routes() {
  useEffect(() => {
    // Add Termly Consent Banner
    // FIXME: Removed because it kept blocking stripe.
    // if (process.env.TERMLY_ENABLED !== 'false') {
    //   const termlyScript = document.createElement('script')
    //   termlyScript.src = 'https://app.termly.io/embed.min.js'
    //   termlyScript.setAttribute('data-auto-block', 'on')
    //   termlyScript.setAttribute(
    //     'data-website-uuid',
    //     process.env.TERMLY_WEBSITE_UUID
    //   )
    //   document.getElementsByTagName('head')[0].appendChild(termlyScript)
    // }

    // Add Crisp Chat
    window.$crisp = []
    window.CRISP_WEBSITE_ID = process.env.CRISP_WEBSITE_ID

    const crispScript = document.createElement('script')
    crispScript.src = 'https://client.crisp.chat/l.js'
    crispScript.async = 1
    document.getElementsByTagName('head')[0].appendChild(crispScript)
  }, [])

  useLayoutEffect(() => {
    mixpanel.init(process.env.MIXPANEL_TOKEN)
  })

  return (
    <>
      <Helmet>
        <title>Leevo</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {process.env.FULLSTORY_ORG_ID && (
        <FullStory org={process.env.FULLSTORY_ORG_ID} />
      )}
      <FavIcons />
      <AuthProvider>
        {/* FIXME: Can we better organize these routes so they are easy to
        comprehend? */}
        <Router>
          <GoogleAnalyticsTracker
            trackingId={process.env.GOOGLE_ANALYTICS_TRACKING_ID}
          >
            <ScrollToTop />
            <Switch>
              {/* Removes trailing slashes from all URLS */}
              <Redirect
                from="/:url*(/+)"
                to={window.location.pathname.slice(0, -1)}
              />
              {/* FIXME: Added this to nudge people to update their bookmarks.
                         We can remove it when there's no more traffic to /facility* routes
                         Which should be only a few weeks after 2020-8-20
              */}
              <Route
                path="/facility*(.+)"
                component={RouteChangeNotification}
              />
              <Route exact path="/" component={HomeFeature} />
              <Route path="/admin/guardians" component={AdminGuardiansIndex} />
              <Route path="/admin/lts_reports" component={AdminLTSReportsNew} />
              <Route
                path="/admin/facilities"
                component={AdminFacilitiesIndex}
              />
              <ProtectedRoute
                exact
                path="/facilities/new"
                component={FacilitiesNewFeature}
              />
              <Route path="/facilities/:facility_id">
                <FacilityProvider>
                  <Switch>
                    <Route
                      path="/facilities/:facility_id/register"
                      component={RegistrationFeature}
                    />
                    <ProtectedRoute
                      path="/facilities/:facility_id/staff"
                      component={FacilitiesCoachesIndex}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/discounts"
                      component={FacilitiesDiscountsIndex}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/skate_rentals"
                      component={FacilitiesSkateRentalsIndex}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/disclosures"
                      component={FacilitiesDisclosuresIndex}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/disclosures/new"
                      component={FacilitiesDisclosuresNew}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/disclosures/:id/edit"
                      component={FacilitiesDisclosuresEdit}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/class_packages"
                      component={FacilitiesKlassPackagesIndex}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/class_packages/new"
                      component={FacilitiesKlassPackagesNew}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/class_packages/:klass_package_id/edit"
                      component={FacilitiesKlassPackagesEdit}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/(klasses|classes)"
                      component={FacilitiesKlassesIndex}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/classes/new"
                      component={FacilitiesKlassesNew}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/classes/:klass_id/edit"
                      component={FacilitiesKlassesEdit}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/students"
                      component={FacilitiesStudentsIndex}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/students/:student_id/edit"
                      component={StudentsEditFeature}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/students/new"
                      component={StudentsNewFeature}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/calendar"
                      component={FacilitiesKlassDaysIndexFeature}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/edit"
                      component={FacilityEditFeature}
                    />
                    <ProtectedRoute
                      path="/facilities/:facility_id/classes/:klass_id/class_days/:klass_day_date/:klass_day_time"
                      component={CoachFeature}
                    />
                    <ProtectedRoute
                      exact
                      path="/facilities/:facility_id/student_imports/new"
                      component={StudentImportsNew}
                    />
                    <Route
                      path="/facilities/:facility_id*"
                      component={NoMatch}
                    />
                  </Switch>
                </FacilityProvider>
              </Route>
              <ProtectedRoute path="/profile" component={ProfileFeature} />
              <ProtectedRoute
                exact
                path="/guardians/:guardian_id/students"
                component={GuardiansStudentsIndex}
              />
              {/* FIXME: Remove this route when traffic to this route dies down */}
              <ProtectedRoute
                exact
                path="/student_managers/:guardian_id/students"
                component={GuardiansStudentsIndex}
              />
              <Route
                path="/auth/invitation/accept"
                component={AcceptInvitationFeature}
              />
              <Route path="/pricing" component={PricingFeature} />
              <Route path="/login" component={LoginFeature} />
              <Route path="/logout" component={LogoutFeature} />
              <Route
                path="/forgot_password"
                component={ForgotPasswordFeature}
              />
              <Route path="/password/new" component={NewPasswordFeature} />
              <Route path="/about" component={AboutFeature} />
              <Route path="/privacy" component={PrivacyPolicyFeature} />
              <Route path="/terms" component={TermsAndConditionsFeature} />
              <Route path="/cookie-policy" component={CookiePolicyFeature} />
              <Route path="/disclaimer" component={DisclaimerFeature} />
              <Route path="/refunds" component={RefundPolicyFeature} />
              <Route
                path="/support/linking_stripe_to_leevo"
                component={LinkingStripe}
              />
              <Route
                path="/support/handling_lts_registrations"
                component={HandlingLTSRegistrations}
              />
              <Route path="/support/logging_in" component={LoggingIn} />
              <Route path="/404" component={NoMatch} />
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </GoogleAnalyticsTracker>
        </Router>
      </AuthProvider>
    </>
  )
}

export default hot(Routes)
