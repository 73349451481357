import React from 'react'
import Transition from './Transition'
import { mount } from 'enzyme'

describe('Transition', () => {
  let wrapper, child
  const mockOnClick = jest.fn()

  beforeEach(() => {
    child = <div>I am a child.</div>
    wrapper = mount(
      <Transition data-cy="foobar" className="foo" onClick={mockOnClick}>
        {child}
      </Transition>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('calls onClick when clicked', () => {
    wrapper.simulate('click')
    expect(mockOnClick).toHaveBeenCalled()
  })

  test('passes classNames along to a rendered div', () => {
    expect(wrapper.find('div.foo')).toExist()
  })

  test('passes dataCy property along to a rendered div', () => {
    expect(wrapper.find('[data-cy="foobar"]')).toExist()
  })

  test('renders children', () => {
    expect(wrapper).toIncludeText('I am a child.')
  })
})
