import React from 'react'
import Calendar from './Calendar'
import { MemoryRouter } from 'react-router-dom'
import MockFacilityProvider from '../../../providers/MockFacilityProvider'
import { mount } from 'enzyme'

describe('Calendar', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter>
          <MockFacilityProvider>
            <Calendar timeZone="America/Los_Angeles" isLoading={false} />
          </MockFacilityProvider>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
