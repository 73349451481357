import React from 'react'
import { mount } from 'enzyme'
import { Form } from 'react-final-form'

import EmailInfo from './EmailInfo'
import MockAuthProvider from '../../../providers/MockAuthProvider'

describe('EmailInfo', () => {
  const mockSetNextButtonDisabled = jest.fn()
  let wrapper

  beforeEach(() => {
    wrapper = mount(
      <MockAuthProvider>
        <Form
          onSubmit={jest.fn()}
          render={() => (
            <EmailInfo setNextButtonDisabled={mockSetNextButtonDisabled} />
          )}
        />
      </MockAuthProvider>
    )
  })

  test('renders', () => {
    expect(wrapper.find(EmailInfo)).toExist()
  })
})
