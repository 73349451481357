import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useHistory, useParams } from 'react-router-dom'
import { uniqBy as _uniqBy } from 'lodash'
import axios from 'axios'
import mixpanel from '../../utilities/mixpanel'

import CoachHeader from './CoachHeader/CoachHeader'
import StudentList from './StudentList/StudentList'
import SkillSelector from '../leevo_ui/SkillSelector/SkillSelector'
import LoadingSpinner from '../leevo_ui/LoadingSpinner/LoadingSpinner'
import Recommendations from './Recommendations/Recommendations'
import SkillDrawer from './SkillDrawer/SkillDrawer'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import SkillLevelList from '../leevo_ui/SkillLevelList/SkillLevelList'
import { requestCreator } from '../../utilities/requests'
import useFacility from '../../utilities/hooks/useFacility'

function useAttendanceApi(onUpdatedAttendance) {
  const [klassDaysStudents, setKlassDaysStudents] = useState([])

  function toggleAttendance(klassDaysStudentId) {
    const updatedStudent = klassDaysStudents.find(
      ({ id }) => id === klassDaysStudentId
    )

    if (!updatedStudent) {
      const errorMsg = `Klasses student ${klassDaysStudentId} cannot be found`
      return Promise.reject(new Error(errorMsg))
    }
    const newStatus = !updatedStudent.attended
    updatedStudent.attended = newStatus
    setKlassDaysStudents(klassDaysStudents)

    return axios
      .patch(`/api/klass_days_students/${klassDaysStudentId}`, {
        attended: newStatus,
      })
      .then(() => {
        onUpdatedAttendance()
        mixpanel.track('Marked Attendance')
      })
      .catch((error) => {
        updatedStudent.attended = !newStatus
        setKlassDaysStudents(klassDaysStudents)
        throw error
      })
  }

  return [setKlassDaysStudents, toggleAttendance]
}

const {
  get: getRecommendations,
  cancel: cancelRecommendationsRequest,
} = requestCreator()
const { get: getKlassDay, cancel: cancelKlassDaysRequest } = requestCreator()

// FIXME: This should be renamed to KlassDayShowFeature since this is how a class
// is shown and will also make it clear that the ID is a klass_day ID
// https://github.com/csalvato/leevo/pull/331#discussion_r393787492
function CoachFeature() {
  const history = useHistory()
  const {
    facility_id: facilityId,
    klass_id: klassId,
    klass_day_date: klassDayDate,
    klass_day_time: klassDayTime,
  } = useParams()
  const { facility, isLoading: isFacilityLoading } = useFacility()
  const [setKlassDaysStudents, toggleAttendance] = useAttendanceApi(
    updateRecommendations
  )
  const [recommendations, setRecommendations] = useState([])
  const [isRecommendationsLoading, setIsRecommendationsLoading] = useState(true)
  const [
    {
      id: klassDayId,
      coaches,
      startDatetime,
      students,
      klassDaysStudents,
      skillLevels,
      skillCategories,
    },
    setKlassDay,
  ] = useState({})
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  function updateRecommendations() {
    if (!klassDayId) return

    setIsRecommendationsLoading(true)
    getRecommendations(`/api/recommendations/${klassDayId}`)
      .then((recs) => {
        setRecommendations(recs)
        setIsRecommendationsLoading(false)
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          history.replace(`/facilities/${facilityId}/calendar`)
          throw error
        }
      })
  }

  useEffect(() => {
    if (!students) {
      setIsLoading(true)
      getKlassDay(
        `/api/classes/${klassId}/class_days/${klassDayDate}/${klassDayTime}`
      )
        .then((klassDay) => {
          setKlassDay(klassDay)
          setSelectedCategory(klassDay.skillCategories[0])
          if (klassDay.klassDaysStudents) {
            setKlassDaysStudents(klassDay.klassDaysStudents)
          }
          setIsLoading(false)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setIsLoading(false)
            history.replace(`/facilities/${facilityId}/calendar`)
            throw error
          }
        })
    }

    return cancelKlassDaysRequest
  }, [
    klassDaysStudents,
    setKlassDaysStudents,
    students,
    facilityId,
    history,
    klassId,
    klassDayDate,
    klassDayTime,
  ])

  useEffect(() => {
    updateRecommendations()
    return cancelRecommendationsRequest
  }, [klassDayId]) /* eslint-disable-line */ // FIXME

  function extractLevelsFromStudents() {
    if (!students) return students
    return students.map((student) => {
      return {
        id: student.currentSkillLevel.id,
        name: student.currentSkillLevel.name,
        skills: student.currentSkillLevel.skills,
      }
    })
  }

  function handlePromotion({ id, currentSkillLevel }) {
    return axios
      .put(`/api/facilities/${facilityId}/students/${id}`, {
        student: {
          currentSkillLevelId: currentSkillLevel.nextSkillLevel.id,
        },
      })
      .then(() => {
        mixpanel.track('Promoted Student', { studentId: id })
        return getKlassDay(
          `/api/classes/${klassId}/class_days/${klassDayDate}/${klassDayTime}`
        )
      })
      .then(setKlassDay)
      .catch((error) => {
        if (!axios.isCancel(error)) throw error
      })
  }

  function studentLists(students, classLevels, klassDayId) {
    if (!klassDayId) return null

    return classLevels.map((level) => (
      <StudentList
        key={level.id}
        className="last:mb-24"
        level={level}
        klassDayId={klassDayId}
        onPromotion={handlePromotion}
        students={students.filter(
          (student) => student.currentSkillLevel.name === level.name
        )}
        selectedCategory={selectedCategory}
        toggleAttendance={toggleAttendance}
        withSkillSelector={showSkillSelector}
      />
    ))
  }

  const classLevels = _uniqBy(extractLevelsFromStudents(), (e) => e.id).sort(
    (a, b) => a.id - b.id
  )

  const showSkillSelector = skillCategories && skillCategories.length > 1

  return (
    <ApplicationLayout>
      {isFacilityLoading ? (
        <LoadingSpinner
          size="1/5"
          className="flex items-center justify-center m-10"
        />
      ) : (
        <>
          {students && (
            <SkillDrawer
              className="block md:hidden print:hidden"
              selectedCategory={selectedCategory}
              skillCategories={skillCategories}
              skillLevels={classLevels}
              onCategorySelected={setSelectedCategory}
            />
          )}
          {isLoading ? (
            <LoadingSpinner
              size="1/5"
              className="flex items-center justify-center m-10"
            />
          ) : (
            <div className="flex justify-center">
              <div className="w-full max-w-4xl">
                <CoachHeader
                  coaches={coaches || []}
                  classStartDateTime={moment(startDatetime).tz(
                    facility.timeZone
                  )}
                  skillLevels={skillLevels || []}
                  numStudents={students ? students.length : 0}
                />
                <Recommendations
                  recommendations={recommendations}
                  isLoading={isRecommendationsLoading}
                />
                <div className="mt-4 md:grid md:grid-cols-4 md:gap-2">
                  <div className="relative col-span-2 md:col-span-2">
                    {showSkillSelector && (
                      <div className="sticky top-0 z-20 w-full bg-gray-100 min-h-14">
                        <div className="flex items-center justify-center mx-1 mb-2 bg-white shadow rounded-xl">
                          <SkillSelector
                            skillCategories={skillCategories}
                            selectedCategory={selectedCategory}
                            onCategorySelected={setSelectedCategory}
                          />
                        </div>
                      </div>
                    )}

                    {students &&
                      studentLists(students, classLevels, klassDayId)}
                  </div>

                  <div className="hidden md:block md:col-span-2 print:block">
                    <div className="sticky top-0 h-[calc(100vh - 96px)] overflow-scroll bg-white shadow rounded-xl print:relative print:h-auto print:top-auto print:overflow-visible print:h-auto">
                      <SkillLevelList
                        selectedCategory={selectedCategory}
                        skillLevels={classLevels}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </ApplicationLayout>
  )
}

export default CoachFeature
