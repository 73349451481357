import React from 'react'

import { boolean } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'

import ShowHidePasswordButton from './ShowHidePasswordButton'

export default {
  title: 'Leevo UI/ShowHidePasswordButton',
  component: ShowHidePasswordButton,
}

export function showPasswordButton() {
  return (
    <ShowHidePasswordButton
      showPassword={boolean('showPassword', false)}
      onIconClicked={action('icon clicked')}
    />
  )
}

export function hidePasswordButton() {
  return (
    <ShowHidePasswordButton
      showPassword={boolean('showPassword', true)}
      onIconClicked={action('icon clicked')}
    />
  )
}
