import React from 'react'
import { shallow, mount } from 'enzyme'

import RegistrationLayout from './RegistrationLayout'
import CircleButton from '../../leevo_ui/CircleButton/CircleButton'
import ProgressBar from '../../leevo_ui/ProgressBar/ProgressBar'

describe('RegistrationLayout', () => {
  let wrapper
  const mockChild = <div>I am a child.</div>

  test('renders', () => {
    wrapper = shallow(
      <RegistrationLayout title="foo">{mockChild}</RegistrationLayout>
    )
    expect(wrapper).toExist()
  })

  test('renders the title', () => {
    wrapper = shallow(
      <RegistrationLayout title="foo">{mockChild}</RegistrationLayout>
    )
    expect(wrapper).toIncludeText('foo')
  })

  test('renders the children', () => {
    wrapper = mount(
      <RegistrationLayout title="foo">{mockChild}</RegistrationLayout>
    )
    expect(wrapper).toIncludeText('I am a child')
  })

  describe('progressPercentage', () => {
    test('renders a ProgressBar with the progressPercentage', () => {
      wrapper = shallow(
        <RegistrationLayout title="foo" progressPercentage={90}>
          {mockChild}
        </RegistrationLayout>
      )
      expect(wrapper.find(ProgressBar).props().percentage).toBe(90)
    })

    test('does not render ProgressBar if color is not white', () => {
      wrapper = shallow(
        <RegistrationLayout
          title="foo"
          headerColor="purple"
          progressPercentage={90}
        >
          {mockChild}
        </RegistrationLayout>
      )
      expect(wrapper.find(ProgressBar)).not.toExist()
    })
  })

  describe('headerColor', () => {
    test('renders white by default', () => {
      wrapper = shallow(
        <RegistrationLayout title="foo">{mockChild}</RegistrationLayout>
      )
      expect(wrapper.find('header')).toHaveClassName('bg-white')
      expect(wrapper.find('header')).toHaveClassName('text-gray-900')
    })

    test('renders purple gradient when purple', () => {
      wrapper = shallow(
        <RegistrationLayout title="foo" headerColor="purple">
          {mockChild}
        </RegistrationLayout>
      )
      expect(wrapper.find('header')).toHaveClassName(
        'bg-gradient-purple-700-500'
      )
      expect(wrapper.find('header')).toHaveClassName('text-white')
    })
  })

  describe('onClickNext', () => {
    let mockOnClickNext
    beforeEach(() => {
      mockOnClickNext = jest.fn()
      wrapper = shallow(
        <RegistrationLayout title="foo" onClickNext={mockOnClickNext}>
          {mockChild}
        </RegistrationLayout>
      )
    })

    test('renders a CircleButton', () => {
      expect(wrapper.find(CircleButton)).toExist()
    })

    test('fires when CircleButton is clicked', () => {
      const nextButton = wrapper.find(CircleButton)
      nextButton.simulate('click')
      expect(mockOnClickNext).toHaveBeenCalled()
    })

    describe('isNextDisabled', () => {
      test('renders the CircleButton as disabled when true', () => {
        wrapper = shallow(
          <RegistrationLayout
            title="foo"
            onClickNext={mockOnClickNext}
            isNextDisabled
          >
            {mockChild}
          </RegistrationLayout>
        )
        expect(wrapper.find(CircleButton).props().disabled).toBe(true)
      })

      test('renders the CircleButton as enabled when false', () => {
        wrapper = shallow(
          <RegistrationLayout title="foo" onClickNext={mockOnClickNext}>
            {mockChild}
          </RegistrationLayout>
        )
        expect(wrapper.find(CircleButton).props().disabled).toBe(false)
      })
    })
  })
})
