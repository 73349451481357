import React from 'react'
import { shallow } from 'enzyme'
import moment from 'moment'
import { Route, MemoryRouter } from 'react-router-dom'

import FacilitiesKlassDaysIndex from './FacilitiesKlassDaysIndex'
import FacilityProvider from '../../providers/FacilityProvider'

describe('FacilitiesKlassDaysIndex', () => {
  let wrapper

  beforeEach(() => {
    wrapper = shallow(
      <MemoryRouter initialEntries={['/facilities/1']}>
        <Route path="/facilities/:facility_id">
          <FacilityProvider>
            <FacilitiesKlassDaysIndex
              facility={{ id: 1, name: 'fake-name' }}
              klasses={[]}
              selectedDate={moment()}
              isFetchingDate={false}
              isFetchingKlassList={false}
              onDateSelected={jest.fn()}
            />
          </FacilityProvider>
        </Route>
      </MemoryRouter>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
