import React from 'react'
import RecommendationLevelCard from './RecommendationLevelCard'
import { mount } from 'enzyme'

describe('RecommendationLevelCard', () => {
  let wrapper
  beforeEach(() => {
    const recommendations = [
      {
        id: 1,
        count: 1,
        skill: { shortLabel: 'A', name: 'foo1' },
        skillLevel: { name: 'bar' },
        status: 'unintroduced',
      },
      {
        id: 2,
        count: 2,
        skill: { shortLabel: 'B', name: 'foo2' },
        skillLevel: { name: 'bar' },
        status: 'unintroduced',
      },
    ]

    wrapper = mount(
      <RecommendationLevelCard recommendations={recommendations} />
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders the level name', () => {
    expect(wrapper).toIncludeText('bar')
  })

  test("renders all of the recommendations' skill names", () => {
    expect(wrapper).toIncludeText('foo1')
    expect(wrapper).toIncludeText('foo2')
  })

  test("renders all of the recommendations' short labels", () => {
    expect(wrapper).toIncludeText('A')
    expect(wrapper).toIncludeText('B')
  })

  test("renders all of the recommendations' student counts", () => {
    expect(wrapper).toIncludeText('1 student')
    expect(wrapper).toIncludeText('2 students')
  })
})
