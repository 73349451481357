import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function ExpandDownTransition({ children, show }) {
  return (
    <div
      className={classNames('transition duration-300 overflow-hidden', {
        'opacity-0 max-h-0 ease-out': !show,
        'opacity-100 max-h-full ease-in': show,
      })}
    >
      {children}
    </div>
  )
}

ExpandDownTransition.defaultProps = {
  show: false,
}

ExpandDownTransition.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
}

export default ExpandDownTransition
