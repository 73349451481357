import React from 'react'
import CheckoutForm from './CheckoutForm'
import { action } from '@storybook/addon-actions'

export default {
  title: 'Leevo UI/CheckoutForm',
  component: CheckoutForm,
  args: {
    className: 'm-5',
  },
}

export function checkoutForm(args) {
  return (
    <CheckoutForm
      {...args}
      onSubmit={(value) => Promise.resolve(action('Submitted')(value))}
    />
  )
}
