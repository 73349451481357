import React from 'react'
import PropTypes from 'prop-types'

import Modal from '../Modal/Modal'
import Button from '../Button/Button'

function ConfirmationModal({
  open,
  header,
  onClose,
  onNoClick,
  onYesClick,
  pending,
  children,
}) {
  return (
    <Modal
      open={open}
      header={header}
      onClickClose={onClose}
      onClickBackground={onClose}
    >
      <div className="flex flex-col justify-center">
        <div className="mx-1" data-cy="confirmation-modal-content">
          {children}
        </div>
        <footer className="flex justify-between mt-5">
          <Button
            className="w-24"
            label="No"
            onClick={onNoClick}
            outline
            data-cy="no-button"
          />
          <Button
            className="w-24"
            label="Yes"
            onClick={onYesClick}
            color="green"
            loading={pending}
            data-cy="yes-button"
          />
        </footer>
      </div>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  header: PropTypes.string,
  onClose: PropTypes.func,
  onNoClick: PropTypes.func,
  onYesClick: PropTypes.func,
  pending: PropTypes.bool,
  children: PropTypes.node,
}

export default ConfirmationModal
