import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactPhoneInput from 'react-phone-number-input/input'

function PhoneInput({
  id,
  className,
  value,
  onChange,
  label,
  errorMessage,
  placeholder,
  onBlur,
  onFocus,
  disabled,
  required,
  autoFocus,
  ...rest
}) {
  return (
    <div className={className}>
      {label && (
        <label
          className={`${classNames({
            'border-red-500': errorMessage,
            'text-red-500': errorMessage,
          })} block text-gray-800 text-sm font-medium ml-1`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <ReactPhoneInput
        {...rest}
        className={`${classNames({
          'text-red-500 border-red-500 placeholder-red-300 ring ring-red-500 ring-opacity-50 error-shake': errorMessage,
          'text-gray-800 placeholder-gray-500': !errorMessage,
          'shadow-inner': !disabled,
          'bg-gray-100': disabled,
        })} text-sm border rounded w-full py-2 px-3 leading-tight focus:ring focus:border-purple-500 focus:ring-purple-300 focus:ring-opacity-50  border-gray-300 mt-2`}
        country="US"
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        required={required}
        autoFocus={autoFocus}
        data-cy={`${id}-input`}
      />
      <p className="mb-3 text-xs italic text-red-500">{errorMessage}</p>
    </div>
  )
}

PhoneInput.default = {
  className: '',
  errorMessage: '',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
}

PhoneInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default PhoneInput
