import React from 'react'
import { mount } from 'enzyme'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { Router } from 'react-router-dom'
import { createMemoryHistory } from 'history'
import { act } from 'react-dom/test-utils'

import ProfileFeature from './ProfileFeature'
import ProfileForm from '../leevo_ui/ProfileForm/ProfileForm'
import LoadingSpinner from '../leevo_ui/LoadingSpinner/LoadingSpinner'
import MockAuthProvider from '../../providers/MockAuthProvider'

const mockAxios = new MockAdapter(axios)

// FIXME: While all these tests pass, there are console.errors being
// generated by enzyme. We have brought this to the attention of the enzyme
// team: https://github.com/enzymejs/enzyme/issues/2011#issuecomment-602121952
// If it's a big deal in the future, and they don't fix this, we can mock console.error just
// for this spec.
describe('ProfileFeature', () => {
  let wrapper

  beforeEach(async () => {
    const mockHistory = createMemoryHistory()
    const attributes = {
      email: 'foo@bar.com',
      preferredName: 'Foo',
      fullName: 'Foo Bar',
    }

    mockAxios.reset()
    mockAxios.onGet().reply(200, { data: { attributes } })

    wrapper = mount(
      <MockAuthProvider currentUser={{ id: 1, facilitiesUsers: [] }}>
        <Router history={mockHistory}>
          <ProfileFeature />
        </Router>
      </MockAuthProvider>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders a ProfileForm', () => {
    expect(wrapper.find(LoadingSpinner)).toExist()
  })

  test('renders a ProfileForm after loading completed', () => {
    wrapper.update()
    expect(wrapper.find(ProfileForm)).toExist()
  })

  describe('submission', () => {
    beforeEach(async () => {
      act(() => {
        wrapper
          .find('input[name="fullName"]')
          .simulate('change', { target: { value: 'foo' } })
        wrapper
          .find('input[name="preferredName"]')
          .simulate('change', { target: { value: 'bar' } })
        wrapper
          .find('input[name="email"]')
          .simulate('change', { target: { value: 'foo@bar.com' } })
        wrapper
          .find('input[name="password"]')
          .simulate('change', { target: { value: 'password' } })
        wrapper
          .find('input[name="passwordConfirmation"]')
          .simulate('change', { target: { value: 'password' } })
      })
    })

    describe('when successful', () => {
      beforeEach(async () => {
        mockAxios.reset()
        mockAxios.onPut().reply(200)
        act(() => {
          wrapper.find('form').simulate('submit')
        })
      })

      // FIXME: This was working until we switched to notification component.
      //        Was difficult to fix, so marked as TODO
      test.todo('displays success message')
      // eslint-disable-next-line jest/no-commented-out-tests
      // test('displays success message', () => {
      //   const notification = wrapper.find('[data-cy="notification"]')
      //   expect(notification).toHaveText('Changes saved!')
      // })
    })

    describe('when unsuccessful', () => {
      describe('with provided message', function () {
        beforeEach(async () => {
          mockAxios.reset()
          mockAxios.onPut().reply(500, {
            error: 'Something went wrong.',
          })
          await act(async () => {
            wrapper.find('form').simulate('submit')
          })
        })

        // FIXME: This was working until we switched to notification component.
        //        Was difficult to fix, so marked as TODO
        test.todo('displays success message')
        // eslint-disable-next-line jest/no-commented-out-tests
        // test('displays success message', () => {
        //   const notification = wrapper.find('[data-cy="notification"]')
        //   expect(notification).toHaveText('Something went wrong.')
        // })
      })

      describe('without provided message', function () {
        beforeEach(async () => {
          mockAxios.reset()
          mockAxios.onPut().reply(500, {})
          await act(async () => {
            wrapper.find('form').simulate('submit')
          })
        })

        // FIXME: This was working until we switched to notification component.
        //        Was difficult to fix, so marked as TODO
        test.todo('displays success message')
        // eslint-disable-next-line jest/no-commented-out-tests
        // test('displays success message', () => {
        // const notification = wrapper.find('[data-cy="notification"]')
        //   const defaultMessage = 'We were unable to save your changes.'
        //   expect(notification).toHaveText(defaultMessage)
        // })
      })
    })
  })
})
