import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'

import useAuth from '../../../utilities/hooks/useAuth'

import LoadingPage from '../../leevo_ui/LoadingPage/LoadingPage'

function ProtectedRoute({ component: Component, ...rest }) {
  const { isLoading, isSignedIn } = useAuth()
  const location = useLocation()
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) {
          return <LoadingPage />
        } else if (isSignedIn) {
          return <Component {...props} {...rest} />
        } else {
          return (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
          )
        }
      }}
    />
  )
}

ProtectedRoute.propTypes = {
  component: Route.propTypes.component,
}

export default ProtectedRoute
