import React from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'
import { Link, useParams } from 'react-router-dom'

import Avatar from '../../leevo_ui/Avatar/Avatar'
import caretDown from '../../../images/caret-down.svg'

function StudentListItem({ id, fullName, cloudinaryPhotoPublicId }) {
  const { facility_id: facilityId } = useParams()
  return (
    <Link to={`/facilities/${facilityId}/students/${id}/edit`}>
      <li
        data-cy="student-list-item"
        className="flex items-center justify-between p-4 text-gray-800 border-b border-gray-300"
      >
        <section className="flex items-center justify-start">
          <Avatar
            fullName={fullName}
            size={12}
            cloudinaryPhotoPublicId={cloudinaryPhotoPublicId}
            userId={id}
            isStudent
          />
          <p className="mx-2">{fullName}</p>
        </section>
        <section>
          <SVG
            className="w-6 h-6 text-purple-600 transform -rotate-90 fill-current"
            src={caretDown}
          />
        </section>
      </li>
    </Link>
  )
}

StudentListItem.defaultProps = {
  fullName: '',
  cloudinaryPhotoPublicId: '',
  pending: false,
}

StudentListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fullName: PropTypes.string,
  cloudinaryPhotoPublicId: PropTypes.string,
  pending: PropTypes.bool,
}

export default StudentListItem
