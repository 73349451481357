import React from 'react'
import LoginFeature from './LoginFeature'
import { mount } from 'enzyme'
import { MemoryRouter, Route } from 'react-router-dom'
import MockAuthProvider from '../../providers/MockAuthProvider'

describe('LoginFeature', () => {
  let wrapper

  function MockRoot() {
    return <div>Mock Component</div>
  }
  function MockCalendar() {
    return <div>Mock Calendar</div>
  }
  function MockStudentsIndex() {
    return <div>Mock Students Index</div>
  }
  function MockPrevious() {
    return <div>Mock Previous</div>
  }

  let currentUser = {
    id: 1,
    students: [],
    facilitiesUsers: [
      {
        coach: false,
        admin: false,
        facility: {
          id: 1,
          name: 'foobar',
        },
      },
    ],
  }

  describe('if signed in', () => {
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/login']}>
          <MockAuthProvider isSignedIn currentUser={currentUser}>
            <Route exact path="/" component={MockRoot} />
            <Route
              path="/facilities/:facility_id/calendar"
              component={MockCalendar}
            />
            <Route
              path="/guardians/:guardian_id/students"
              component={MockStudentsIndex}
            />
            <Route path="/foo" component={MockPrevious} />
            <Route path="/login">
              <LoginFeature />
            </Route>
          </MockAuthProvider>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    describe('with no previous path, students, coach or director status', () => {
      test('triggers a redirect to the root page', () => {
        expect(wrapper.find(MockRoot)).toExist()
        expect(wrapper.find(MockPrevious)).not.toExist()
        expect(wrapper.find(MockCalendar)).not.toExist()
        expect(wrapper.find(MockStudentsIndex)).not.toExist()
      })
    })

    describe('with a no previous path and a director at a facility', () => {
      test('triggers a redirect to the calendar index of the facility', () => {
        currentUser.facilitiesUsers[0].admin = true
        currentUser.facilitiesUsers[0].coach = false
        wrapper = mount(
          <MemoryRouter initialEntries={['/login']}>
            <MockAuthProvider isSignedIn currentUser={currentUser}>
              <Route exact path="/" component={MockRoot} />
              <Route
                path="/facilities/:facility_id/calendar"
                component={MockCalendar}
              />
              <Route
                path="/guardians/:guardian_id/students"
                component={MockStudentsIndex}
              />
              <Route path="/foo" component={MockPrevious} />
              <Route path="/login">
                <LoginFeature />
              </Route>
            </MockAuthProvider>
          </MemoryRouter>
        )
        expect(wrapper.find(MockRoot)).not.toExist()
        expect(wrapper.find(MockPrevious)).not.toExist()
        expect(wrapper.find(MockCalendar)).toExist()
        expect(wrapper.find(MockStudentsIndex)).not.toExist()
      })
    })

    describe('with a no previous path and a coach at a facility', () => {
      test('triggers a redirect to the classes index of the facility', () => {
        currentUser.facilitiesUsers[0].coach = true
        currentUser.facilitiesUsers[0].admin = true
        wrapper = mount(
          <MemoryRouter initialEntries={['/login']}>
            <MockAuthProvider isSignedIn currentUser={currentUser}>
              <Route exact path="/" component={MockRoot} />
              <Route
                path="/facilities/:facility_id/calendar"
                component={MockCalendar}
              />
              <Route
                path="/guardians/:guardian_id/students"
                component={MockStudentsIndex}
              />
              <Route path="/foo" component={MockPrevious} />
              <Route path="/login">
                <LoginFeature />
              </Route>
            </MockAuthProvider>
          </MemoryRouter>
        )
        expect(wrapper.find(MockRoot)).not.toExist()
        expect(wrapper.find(MockPrevious)).not.toExist()
        expect(wrapper.find(MockCalendar)).toExist()
        expect(wrapper.find(MockStudentsIndex)).not.toExist()
      })
    })

    describe('with no previous path and has students', () => {
      test('triggers a redirect to the classes index of the facility', () => {
        currentUser.facilitiesUsers[0].admin = false
        currentUser.facilitiesUsers[0].coach = false
        currentUser.students = [{ id: 1 }]
        wrapper = mount(
          <MemoryRouter initialEntries={['/login']}>
            <MockAuthProvider isSignedIn currentUser={currentUser}>
              <Route exact path="/" component={MockRoot} />
              <Route
                path="/facilities/:facility_id/calendar"
                component={MockCalendar}
              />
              <Route
                path="/guardians/:guardian_id/students"
                component={MockStudentsIndex}
              />
              <Route path="/foo" component={MockPrevious} />
              <Route path="/login">
                <LoginFeature />
              </Route>
            </MockAuthProvider>
          </MemoryRouter>
        )
        expect(wrapper.find(MockRoot)).not.toExist()
        expect(wrapper.find(MockPrevious)).not.toExist()
        expect(wrapper.find(MockCalendar)).not.toExist()
        expect(wrapper.find(MockStudentsIndex)).toExist()
      })
    })

    describe('with a a previous path', () => {
      test('triggers a redirect', () => {
        let mockLocation
        wrapper = mount(
          <MemoryRouter initialEntries={['/login']}>
            <MockAuthProvider isSignedIn currentUser={currentUser}>
              <Route exact path="/" component={MockRoot} />
              <Route
                path="/facilities/:facility_id/calendar"
                component={MockCalendar}
              />
              <Route path="/foo" component={MockPrevious} />
              <Route
                path="/login"
                render={({ location }) => {
                  mockLocation = location
                  mockLocation.state = { from: '/foo' }
                  return <LoginFeature />
                }}
              />
            </MockAuthProvider>
          </MemoryRouter>
        )
        expect(wrapper.find(MockRoot)).not.toExist()
        expect(wrapper.find(MockPrevious)).toExist()
        expect(wrapper.find(MockCalendar)).not.toExist()
      })
    })
  })

  describe('if not signed in', () => {
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/login']}>
          <MockAuthProvider>
            <Route path="/login">
              <LoginFeature />
            </Route>
            <Route exact path="/" component={MockRoot} />
          </MockAuthProvider>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('does not trigger a redirect', () => {
      expect(wrapper.find(LoginFeature)).toExist()
    })
  })
})
