import React from 'react'
import { mount } from 'enzyme'
import { createMemoryHistory } from 'history'
import { Router } from 'react-router-dom'

import NoMatch from './NoMatch'
import Button from '../leevo_ui/Button/Button'

const mockHistory = createMemoryHistory()
mockHistory.goBack = jest.fn()
describe('NoMatch', () => {
  let wrapper
  beforeEach(() => {
    wrapper = mount(
      <Router history={mockHistory}>
        <NoMatch />
      </Router>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
  test('renders a Button', () => {
    expect(wrapper.find(Button)).toExist()
  })

  test('clicking button fires goBack fn from history', () => {
    wrapper.find(Button).simulate('click')
    expect(mockHistory.goBack).toHaveBeenCalled()
  })
})
