import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Card from '../Card/Card'
import Button from '../Button/Button'
import CreditCardLogo from '../CreditCardLogo/CreditCardLogo'
import NewCreditCardModal from '../NewCreditCardModal/NewCreditCardModal'

function CreditCardManager({ className, brand, lastFour, onChange, ...rest }) {
  const [showCreditCardModal, setShowCreditCardModal] = useState(false)

  return (
    <div {...rest} className={className}>
      <NewCreditCardModal
        onClickClose={() => setShowCreditCardModal(false)}
        open={showCreditCardModal}
        onSubmit={(token) =>
          Promise.resolve(onChange(token)).then(() =>
            setShowCreditCardModal(false)
          )
        }
        header="Add a credit card"
      />
      <Card className="flex items-center p-4 max-w-sm">
        <CreditCardLogo
          brand={brand}
          className="inline-block w-12 h-auto mr-3"
        />
        <section>
          {brand && lastFour ? (
            <>
              <p className="text-sm">This account is billed to</p>
              <p className="text-sm font-semibold" data-cy="credit-card-info">
                {brand} card ending in {lastFour}
              </p>
            </>
          ) : (
            <p className="text-sm">This account does not have a credit card.</p>
          )}
        </section>
      </Card>
      <section className="mt-2 space-x-3">
        {brand && lastFour && (
          <Button
            small
            outline
            color="red"
            label="Remove"
            data-cy="remove-card-button"
            onClick={() =>
              // FIXME: Should we also present a warning to the user that if they
              // remove the card they may not be able to use the software if their payment
              // lapses?
              onChange({ id: null, isRemovingCard: true })
            }
          />
        )}
        <Button
          small
          outline
          color="purple"
          data-cy={brand && lastFour ? 'update-card-button' : 'add-card-button'}
          label={brand && lastFour ? 'Change' : 'Add New Card'}
          onClick={() => setShowCreditCardModal(true)}
        />
      </section>
    </div>
  )
}

CreditCardManager.defaultProps = {}

CreditCardManager.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  brand: PropTypes.oneOf([
    'American Express',
    'Diners Club',
    'Discover',
    'JCB',
    'MasterCard',
    'UnionPay',
    'Visa',
    'Unknown',
  ]),
  lastFour: PropTypes.string,
}

export default CreditCardManager
