import React from 'react'
import PropTypes from 'prop-types'

import CheckboxInput from '../CheckboxInput/CheckboxInput'

function CheckboxListInput({ className, options, small, onChange, values }) {
  function hasValue(optionValue) {
    return !!(values && values.find((val) => val.value === optionValue))
  }

  return (
    <div className={className} value={values} data-cy="checkbox-list-input">
      {options.map(({ value, label, id }) => (
        <CheckboxInput
          className="last:border-b-0 border-b border-gray-400"
          key={value}
          id={`${value}-${id}`}
          label={label}
          value={value}
          onClick={(e) => {
            // if value already exists in values, then it's being toggled to remove it.
            // FIXME: e.target.value is sometimes undefined for last child?
            if (hasValue(e.target.value) || !e.target.value) {
              const filteredValues = values.filter(
                (valObj) => valObj.value !== value
              )
              onChange(filteredValues)
            } else {
              // add it to values
              onChange([...values, { value: e.target.value }])
            }
          }}
          checked={hasValue(value)}
          small={small}
        />
      ))}
    </div>
  )
}

CheckboxListInput.defaultProps = {
  className: '',
  options: [],
  small: false,
  onChange: () => {},
  values: [],
}

CheckboxListInput.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
  small: PropTypes.bool,
  values: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
      })
    ),
  ]),
}
export default CheckboxListInput
