import React from 'react'
import NewCreditCardModal from './NewCreditCardModal'

export default {
  title: 'Leevo UI/NewCreditCardModal',
  component: NewCreditCardModal,
  args: {
    className: 'm-4',
  },
}

export function newCreditCardModal(args) {
  return <NewCreditCardModal {...args} />
}
