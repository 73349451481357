import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

import FacilityEditFeature from './FacilityEditFeature'

export default {
  title: 'FacilityEditFeature',
  component: FacilityEditFeature,
}

const facilityResponse = {
  data: {
    id: '3',
    type: 'facility',
    attributes: {
      name: 'Scott Hamilton Skating Academy',
      slug: 'scott-hamilton-skating-academy',
      isStripeConnected: false,
    },
  },
}

export function withSuccessfulRequest() {
  const mockAxios = new MockAdapter(axios)

  mockAxios.reset()
  mockAxios.onGet().reply(200, facilityResponse)
  mockAxios.onPut().reply(200)

  return <FacilityEditFeature />
}

export function withUnsuccessfulRequest() {
  const mockAxios = new MockAdapter(axios)

  mockAxios.reset()
  mockAxios.onGet().reply(200, facilityResponse)
  mockAxios.onPut().reply(500, { error: 'Something went wrong.' })

  return <FacilityEditFeature />
}
