import React from 'react'
import { shallow, mount } from 'enzyme'

import LargeRadioInput from './LargeRadioInput'
import CheckboxInput from '../CheckboxInput/CheckboxInput'

describe('LargeRadioInput', () => {
  let wrapper, options
  beforeEach(() => {
    options = [
      { label: 'Value A', value: 'a', checked: false },
      { label: 'Value B', value: 'b', checked: false },
      { label: 'Value C', value: 'c', checked: false },
    ]
  })

  test('renders', () => {
    wrapper = shallow(<LargeRadioInput id="id" options={options} />)
    expect(wrapper).toExist()
  })

  describe('id', () => {
    test('is required', () => {
      function renderWrapper() {
        shallow(<LargeRadioInput options={options} />)
      }
      expect(renderWrapper).toThrowError('`id` is marked as required')
    })
  })

  describe('label', () => {
    test('presents the label when present', () => {
      wrapper = shallow(
        <LargeRadioInput id="id" label="some-label" options={options} />
      )
      expect(wrapper).toIncludeText('some-label')
    })
  })

  describe('className', () => {
    test('is applied to top level div', () => {
      wrapper = shallow(
        <LargeRadioInput
          id="id"
          label="some-label"
          className="some-className"
          options={options}
        />
      )
      expect(wrapper).toHaveClassName('some-className')
    })
  })

  describe('errorMessage', () => {
    test('is present when provided', () => {
      wrapper = shallow(
        <LargeRadioInput id="id" errorMessage="some-error" options={options} />
      )
      expect(wrapper).toIncludeText('some-error')
    })

    test('changes color of label when provided', () => {
      wrapper = shallow(
        <LargeRadioInput
          id="id"
          label="some-label"
          errorMessage="some-error"
          options={options}
        />
      )
      expect(wrapper.find('label')).toHaveClassName('text-red-500')
    })
  })

  describe('helpText', () => {
    test('is present when provided', () => {
      wrapper = shallow(
        <LargeRadioInput id="id" helpText="some-helpText" options={options} />
      )
      expect(wrapper).toIncludeText('some-helpText')
    })
  })

  describe('value', () => {
    test('is present when provided', () => {
      wrapper = shallow(
        <LargeRadioInput id="id" value="some-value" options={options} />
      )
      expect(wrapper.find('div').at(0).props().value).toBe('some-value')
    })
  })

  describe('name', () => {
    test('is present when provided', () => {
      wrapper = shallow(
        <LargeRadioInput id="id" name="some-name" options={options} />
      )
      expect(wrapper.find('div').at(0).props().name).toBe('some-name')
    })
  })

  describe('options', () => {
    test('is required', () => {
      function renderWrapper() {
        shallow(<LargeRadioInput options={undefined} />)
      }
      expect(renderWrapper).toThrowError('`options` is marked as required')
    })

    test('renders all options', () => {
      wrapper = shallow(<LargeRadioInput id="id" options={options} />)
      expect(wrapper.find(CheckboxInput).length).toBe(3)
    })
  })

  describe('interactivity', () => {
    describe('when the selected value is changed', () => {
      test('it fires the onChange function', () => {
        const mockOnChange = jest.fn()
        wrapper = mount(
          <LargeRadioInput id="id" options={options} onChange={mockOnChange} />
        )
        wrapper.find('button').at(0).simulate('click')
        expect(mockOnChange).toHaveBeenCalledWith('a')
      })
    })

    describe('when a value is selected', () => {
      test('it fires the onChange function', () => {
        const mockOnChange = jest.fn()
        wrapper = mount(
          <LargeRadioInput id="id" options={options} onChange={mockOnChange} />
        )
        wrapper.find('button').at(0).simulate('click')
        expect(mockOnChange).toHaveBeenCalledWith('a')
      })
    })
  })
})
