import React from 'react'
import { text } from '@storybook/addon-knobs'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { MemoryRouter, Route } from 'react-router-dom'
import MockFacilityProvider from '../../providers/MockFacilityProvider'

import faker from 'faker'

import FacilitiesStudentsIndex from './FacilitiesStudentsIndex'

export default {
  title: 'Facilities Students Index',
  component: FacilitiesStudentsIndex,
}

export function facilitiesStudentsIndex() {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onGet('/api/facilities/1').reply(200, {
    data: {
      id: 1,
      type: 'facility',
      attributes: {
        name: text('Returned Facility Name', faker.company.companyName()),
      },
    },
  })

  mockAxios.onGet('/api/facilities/1/students').reply(200, {
    meta: { registered: 2, total: 3 },
    data: [
      {
        id: '1',
        type: 'student',
        attributes: {
          fullName: 'Jane Doe',
          preferredName: 'Janie',
          registered: true,
          cloudinaryPhotoPublicId: null,
        },
        relationships: {
          facilities: {
            data: [{ id: '1', type: 'facility' }],
          },
          klassDays: {
            data: [],
          },
        },
      },
      {
        id: '2',
        type: 'student',
        attributes: {
          fullName: 'John Doe',
          preferredName: 'John',
          registered: true,
          cloudinaryPhotoPublicId: null,
        },
        relationships: {
          facilities: {
            data: [{ id: '1', type: 'facility' }],
          },
          klassDays: {
            data: [],
          },
        },
      },
      {
        id: '3',
        type: 'student',
        attributes: {
          fullName: 'Raymond Reddington',
          preferredName: 'RayRay',
          registered: false,
          cloudinaryPhotoPublicId: null,
        },
        relationships: {
          facilities: {
            data: [{ id: '1', type: 'facility' }],
          },
          klassDays: {
            data: [],
          },
        },
      },
    ],
  })

  return (
    <MemoryRouter initialEntries={['/facilities/1/students']}>
      <MockFacilityProvider>
        <Route path="/facilities/:facility_id/students">
          <FacilitiesStudentsIndex />
        </Route>
      </MockFacilityProvider>
    </MemoryRouter>
  )
}
