import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { capitalize } from 'lodash'

function FilterTabs({
  className,
  options,
  value: activeTab,
  onChange,
  ...rest
}) {
  return (
    <div {...rest} className={className}>
      <div className="flex justify-center items-center py-3">
        <div className="shadow-inner bg-gray-300 rounded-sm inline-flex p-px">
          {options.map((option) => (
            <button
              key={option}
              onClick={() => onChange(option)}
              className={classNames(
                'font-semibold rounded-sm text-xs px-3 py-1 m-px focus:ring focus:ring-purple-400 focus:outline-none',
                { 'bg-white': activeTab === option }
              )}
            >
              {capitalize(option)}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

FilterTabs.defaultProps = {
  options: [],
}

FilterTabs.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default FilterTabs
