import React from 'react'
import { Form, Field } from 'react-final-form'
import { action } from '@storybook/addon-actions'

import AddressInput from './AddressInput'
import Button from '../Button/Button'

export default {
  title: 'Forms/AddressInput',
  component: AddressInput,
}

export function empty() {
  return (
    <Form
      onSubmit={action('Submitted form')}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field name="address">
              {({ input }) => (
                <AddressInput {...input} className="m-2" id="foo" />
              )}
            </Field>
            <div className="flex justify-left">
              <Button label="Submit" type="submit" />
            </div>
          </form>
        )
      }}
    />
  )
}

export function prepopulated() {
  return (
    <Form
      initialValues={{
        address: {
          line1: '1948 85th St',
          line2: 'Apt 2',
          city: 'Brooklyn',
          state: 'NY',
          postCode: '11214',
        },
      }}
      onSubmit={action('Submitted form')}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field name="address">
              {({ input }) => (
                <AddressInput {...input} className="m-2" id="foo" />
              )}
            </Field>
            <div className="flex justify-left">
              <Button label="Submit" type="submit" />
            </div>
          </form>
        )
      }}
    />
  )
}
