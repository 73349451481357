import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'
import moment from 'moment-timezone'
import axios from 'axios'

import svgSrc from '../../../images/empty-calendar.svg'

import LoadingSpinner from '../../leevo_ui/LoadingSpinner/LoadingSpinner'
import EmptyList from '../../leevo_ui/EmptyList/EmptyList'
import CoachClassCard from '../CoachClassCard/CoachClassCard'
import useFacility from '../../../utilities/hooks/useFacility'
import { ruleSetFromRecurrenceRule } from '../../../utilities/helpers'
import { requestCreator } from '../../../utilities/requests'

const { get: getKlassDays, cancel: cancelKlassDaysRequest } = requestCreator()
function KlassDaysList({ klasses, selectedDate }) {
  const {
    facility: { id: facilityId, timeZone },
  } = useFacility()
  const [isLoading, setIsLoading] = useState(false)
  const [klassDays, setKlassDays] = useState(null)

  useEffect(() => {
    if (selectedDate.isAfter(moment().subtract(1, 'week').startOf('day'))) {
      setKlassDays(null)
    } else {
      setIsLoading(true)
      getKlassDays(`/api/facilities/${facilityId}/class_days`, {
        params: {
          date: moment(selectedDate).format(),
          include: 'coaches',
          fields: {
            klassDay: 'coaches,startDatetime,skillLevels,klass',
            skillLevel: 'name',
            coach: 'fullName,preferredName,cloudinaryPhotoPublicId',
          },
        },
        cacheKey: selectedDate,
      })
        .then(setKlassDays)
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setIsLoading(false)
            setKlassDays(null)
            if (error.response.status == 401) {
              history.push('/')
            } else {
              history.replace('/404')
            }
          }
        })
        .finally(() => setIsLoading(false))
    }

    return cancelKlassDaysRequest
  }, [selectedDate, facilityId])

  function cardsFromKlassDays() {
    return klassDays
      .sort((a, b) => moment(a.startDatetime) - moment(b.startDatetime))
      .map(({ klass, startDatetime, coaches, skillLevels }) => {
        return (
          <CoachClassCard
            key={`${klass.id}-${startDatetime}`}
            classId={klass.id}
            date={moment(startDatetime).tz(timeZone)}
            coaches={coaches}
            skillLevels={skillLevels}
          />
        )
      })
  }

  function cardsFromRRule() {
    return klasses
      .map(({ id, recurrenceRule, coaches, supportedSkillLevels }) => {
        const ruleSet = ruleSetFromRecurrenceRule(recurrenceRule)
        const timesForClass = ruleSet.between(
          moment(selectedDate).startOf('day').toDate(),
          moment(selectedDate).endOf('day').toDate(),
          true
        )

        return timesForClass.map((klassTime) => ({
          date: moment(klassTime).tz(timeZone),
          card: (
            <CoachClassCard
              key={id}
              classId={id}
              date={moment(klassTime).tz(timeZone)}
              coaches={coaches}
              skillLevels={supportedSkillLevels.map(({ name }) => name)}
            />
          ),
        }))
      })
      .flat()
      .sort((a, b) => a.date - b.date)
      .map(({ card }) => card)
  }

  function KlassDaysListView(isLoading) {
    if (isLoading)
      return (
        <div className="flex flex-col justify-center m-12">
          <LoadingSpinner />
        </div>
      )

    const cards = klassDays === null ? cardsFromRRule() : cardsFromKlassDays()
    return cards.flat().length > 0 ? (
      cards.flat()
    ) : (
      <EmptyList message="No classes today." svgSrc={svgSrc} />
    )
  }

  return (
    <div
      className="flex flex-wrap items-stretch justify-center pt-2 shadow-inner"
      data-cy="class-list"
    >
      {KlassDaysListView(isLoading)}
    </div>
  )
}

KlassDaysList.defaultProps = {
  klasses: [],
}

KlassDaysList.propTypes = {
  klasses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      coaches: PropTypes.array.isRequired,
      startDatetime: PropTypes.oneOfType([
        PropTypes.string,
        momentPropTypes.momentObj,
      ]),
      students: PropTypes.arrayOf(
        PropTypes.shape({
          fullName: PropTypes.string.isRequired,
          preferredName: PropTypes.string.isRequired,
          currentSkillLevel: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
              .isRequired,
            name: PropTypes.string.isRequired,
          }),
        })
      ).isRequired,
    })
  ),
  selectedDate: momentPropTypes.momentObj,
}

export default KlassDaysList
