import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { get } from 'lodash'

import DisclosureForm from '../leevo_ui/DisclosureForm/DisclosureForm'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import { requestCreator } from '../../utilities/requests'
import useFacility from '../../utilities/hooks/useFacility'

const {
  get: getDisclosure,
  post: createDisclosure,
  cancel: cancelDisclosureRequest,
} = requestCreator()

function FacilitiesDisclosuresNew() {
  const history = useHistory()
  const { facility, isLoading: isFacilityLoading } = useFacility({
    isSubscriptionRequired: false,
  })
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!isFacilityLoading) {
      getDisclosure(`/api/facilities/${facility.id}/disclosures/new`)
        .then(() => setIsLoading(false))
        .catch((error) => {
          if (!axios.isCancel(error)) {
            history.push('/500')
            throw error
          }
        })
    }

    return cancelDisclosureRequest
  }, [setIsLoading, isFacilityLoading, facility.id, history])

  function onSubmit(values) {
    return createDisclosure(`/api/facilities/${facility.id}/disclosures`, {
      disclosure: values,
    })
      .then(() =>
        history.push({
          pathname: `/facilities/${facility.id}/disclosures`,
          state: {
            notification: {
              message: 'Disclosure created successfully!',
              color: 'green',
            },
          },
        })
      )
      .catch((error) => {
        if (!axios.isCancel(error)) {
          history.push({
            state: {
              notification: {
                message:
                  get(error, 'response.data.error') ||
                  'We were unable to save your changes.',
                color: 'red',
              },
            },
          })
        }
      })
  }

  return (
    <ApplicationLayout isContentLoading={isLoading}>
      <DisclosureForm onSubmit={onSubmit} className="p-4" />
    </ApplicationLayout>
  )
}

FacilitiesDisclosuresNew.defaultProps = {}

FacilitiesDisclosuresNew.propTypes = {}

export default FacilitiesDisclosuresNew
