import React from 'react'
import { shallow } from 'enzyme'

import KlassPackageListItem from './KlassPackageListItem'
import CheckButton from '../../../leevo_ui/CheckButton/CheckButton'

describe('KlassPackageListItem', () => {
  let wrapper, mockOnClick
  beforeEach(() => {
    mockOnClick = jest.fn()
    wrapper = shallow(
      <KlassPackageListItem
        id={1}
        title="Some title"
        description="Some description"
        priceInDollars="$50.00"
        remainingKlassDays={5}
        spotsLeft={1}
        selected={false}
        helpText="Some help text"
        onClick={mockOnClick}
      />
    )
  })
  it('renders', () => {
    expect(wrapper).toExist()
  })

  it('renders the title', () => {
    expect(wrapper).toIncludeText('Some title')
  })

  it('renders the description', () => {
    expect(wrapper).toIncludeText('Some description')
  })

  it('renders the price in dollars', () => {
    expect(wrapper).toIncludeText('$50.00')
  })

  describe('with spots left', () => {
    it('fires onClick when clicked', () => {
      wrapper.find('li').simulate('click')
      expect(mockOnClick).toHaveBeenCalledWith(1)
    })

    it('shows a CheckButton', () => {
      expect(wrapper.find(CheckButton)).toExist()
    })

    it('shows the spots left as red when there are fewer than 4', () => {
      expect(wrapper.find('p.text-red-600')).toHaveText('1 spot left')
    })
  })

  describe('when disabled', () => {
    beforeEach(() => {
      wrapper.setProps({ disabled: true })
    })

    it('does not fires onClick when clicked', () => {
      wrapper.find('li').simulate('click')
      expect(mockOnClick).not.toHaveBeenCalled()
    })

    it('does not show a CheckButton', () => {
      expect(wrapper.find(CheckButton)).not.toExist()
    })
  })

  describe('with an onClick function', () => {
    it('fires onClick when clicked', () => {
      wrapper.find('li').simulate('click')
      expect(mockOnClick).toHaveBeenCalledWith(1)
    })

    it('shows a CheckButton', () => {
      expect(wrapper.find(CheckButton)).toExist()
    })
  })

  describe('without an onClick function', () => {
    beforeEach(() => {
      wrapper.setProps({ onClick: undefined })
    })

    it('does not show a CheckButton', () => {
      expect(wrapper.find(CheckButton)).not.toExist()
    })
  })
})
