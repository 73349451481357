import React from 'react'
import { shallow, mount } from 'enzyme'

import CheckboxInputCompact from './CheckboxInputCompact'
import CheckButton from '../CheckButton/CheckButton'

describe('CheckboxInputCompact', () => {
  let wrapper
  beforeEach(() => {
    wrapper = shallow(<CheckboxInputCompact id="id" label="foo" />)
  })

  test('renders', () => {
    wrapper = shallow(<CheckboxInputCompact id="id" label="foo" />)
    expect(wrapper).toExist()
  })

  test('renders a CheckButton', () => {
    wrapper = shallow(<CheckboxInputCompact id="id" label="foo" />)
    expect(wrapper.find(CheckButton)).toExist()
  })

  describe('value', () => {
    test('sets the value on the CheckButton', () => {
      wrapper = shallow(
        <CheckboxInputCompact id="id" label="foo" value="some-value" />
      )
      const checkButtonValue = wrapper.find(CheckButton).props().value
      expect(checkButtonValue).toBe('some-value')
    })
  })

  describe('checked', () => {
    test('sets the value of checked on the CheckButton to true when set on CheckboxInputCompact', () => {
      wrapper = shallow(<CheckboxInputCompact id="id" label="foo" checked />)
      expect(wrapper.find(CheckButton).props().checked).toBe(true)
    })

    test('sets the value of checked on the CheckButton to false when not set on CheckboxInputCompact', () => {
      wrapper = shallow(<CheckboxInputCompact id="id" label="foo" />)
      expect(wrapper.find(CheckButton).props().checked).toBe(false)
    })
  })

  describe('id', () => {
    test('is required', () => {
      function renderWrapper() {
        shallow(<CheckboxInputCompact label="label" />)
      }
      expect(renderWrapper).toThrowError('`id` is marked as required')
    })
  })

  describe('label', () => {
    test('is required', () => {
      function renderWrapper() {
        shallow(<CheckboxInputCompact id="id" />)
      }
      expect(renderWrapper).toThrowError('`label` is marked as required')
    })

    test('presents the label when present', () => {
      wrapper = shallow(<CheckboxInputCompact id="id" label="some-label" />)
      expect(wrapper).toIncludeText('some-label')
    })
  })

  describe('onClick', () => {
    let mockOnClick
    beforeEach(() => {
      mockOnClick = jest.fn()
      wrapper = mount(
        <CheckboxInputCompact
          id="id"
          label="some-label"
          onClick={mockOnClick}
        />
      )
    })

    test('is called when CheckButton is clicked', () => {
      wrapper.find(CheckButton).simulate('click')
      expect(mockOnClick).toHaveBeenCalled()
    })
  })
})
