import React from 'react'
import SideBySideTestimonial from './SideBySideTestimonial'

export default {
  title: 'Home Feature/Components/SideBySideTestimonial',
  component: SideBySideTestimonial,
}

export function sideBySideTestimonial(args) {
  return <SideBySideTestimonial {...args} />
}
