import React from 'react'
import { shallow } from 'enzyme'
import PricingFeature from './PricingFeature'
import MarketingNavBar from '../leevo_ui/NavBar/MarketingNavBar/MarketingNavBar'

describe('PricingFeature', () => {
  let wrapper
  let mockHistory
  beforeEach(async () => {
    mockHistory = { push: jest.fn() }
    wrapper = shallow(<PricingFeature history={mockHistory} />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('properly sets the page title', () => {
    expect(wrapper.find('title')).toHaveText('Pricing | Leevo')
  })

  test('renders a MarketingNavBar', () => {
    expect(wrapper.find(MarketingNavBar)).toExist()
  })
})
