import React from 'react'
import { action } from '@storybook/addon-actions'
import { boolean } from '@storybook/addon-knobs'
import { Form } from 'react-final-form'

import PasswordField from './PasswordField'

export default {
  title: 'Leevo UI/Forms/PasswordField',
  component: PasswordField,
}

export function passwordField() {
  return (
    <Form
      onSubmit={action('Submit')}
      render={() => (
        <PasswordField
          className={boolean('With margin?', false) ? 'm-6' : ''}
        />
      )}
    />
  )
}
