import React from 'react'
import { mount } from 'enzyme'
import { MemoryRouter } from 'react-router-dom'

import FacilityEditForm from './FacilityEditForm'

// FIXME: While all these tests pass, there are console.errors being
// generated by enzyme. We have brought this to the attention of the enzyme
// team: https://github.com/enzymejs/enzyme/issues/2011#issuecomment-602121952
// If it's a big deal in the future, and they don't fix this, we can mock console.error just
// for this spec.
describe('FacilityEditForm', () => {
  let wrapper, mockOnSubmit, mockOnCardChange

  beforeEach(() => {
    mockOnSubmit = jest.fn()
    mockOnCardChange = jest.fn()
    wrapper = mount(
      <MemoryRouter>
        <FacilityEditForm
          id={1}
          onSubmit={mockOnSubmit}
          onCardChange={mockOnCardChange}
        />
      </MemoryRouter>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders a form', () => {
    expect(wrapper.find('form').length).toEqual(1)
  })

  describe('stripe connect button', () => {
    describe('when isStripeConnected is true', () => {
      test('shows a disconnect stripe button', () => {
        wrapper = mount(
          <MemoryRouter>
            <FacilityEditForm
              id={1}
              isStripeConnected={true}
              onSubmit={mockOnSubmit}
              onCardChange={mockOnCardChange}
            />
          </MemoryRouter>
        )
        expect(wrapper.find('[data-cy="disconnect-stripe-button"]')).toExist()
      })
    })
    describe('when isStripeConnected is false', () => {
      test('shows a disconnect stripe button', () => {
        wrapper = mount(
          <MemoryRouter>
            <FacilityEditForm
              id={1}
              isStripeConnected={false}
              onSubmit={mockOnSubmit}
              onCardChange={mockOnCardChange}
            />
          </MemoryRouter>
        )
        expect(wrapper.find('[data-cy="connect-stripe-button"]')).toExist()
      })
    })
  })
})
