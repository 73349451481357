import React from 'react'
import { text } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'
import htmlElements from 'he'
import { Form, Field } from 'react-final-form'

import Button from '../../leevo_ui/Button/Button'
import SmallRadioInput from './SmallRadioInput'

export default {
  title: 'Forms/SmallRadioInput',
  component: SmallRadioInput,
}

export function smallRadioInput() {
  const options = [
    { label: 'Female', value: 'female' },
    { label: 'Male', value: 'male' },
    { label: 'Non-binary', value: 'non-binary' },
    { label: 'Unspecified', value: 'unspecified' },
  ]
  return (
    <div className="m-10">
      <Form
        onSubmit={action('Submitted form')}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field name="some-field">
                {({ input }) => (
                  <SmallRadioInput
                    className="m-6"
                    id="foo"
                    label={htmlElements.decode(
                      text('Label', 'What is your gender?')
                    )}
                    helpText={htmlElements.decode(
                      text(
                        'Help Text',
                        'Gender info is used for LTS USA informational purposes only.'
                      )
                    )}
                    errorMessage={htmlElements.decode(
                      text('Error Message', '')
                    )}
                    options={options}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>
              <div className="flex justify-left">
                <Button label="Submit" type="submit" />
              </div>
            </form>
          )
        }}
      />
    </div>
  )
}
