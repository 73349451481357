import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import LargeRadioInput from '../../leevo_ui/LargeRadioInput/LargeRadioInput'
import { presence } from '../../../utilities/validators'

const options = [
  { label: 'Female', value: 'female' },
  { label: 'Male', value: 'male' },
  { label: 'Non-binary', value: 'non_binary' },
  { label: 'Unspecified', value: 'unspecified' },
]

function GenderField({ className, name }) {
  return (
    <Field name="gender" validate={presence}>
      {({ input, meta: { active, error, submitFailed } }) => (
        <LargeRadioInput
          {...input}
          id="gender"
          className={className}
          options={options}
          helpText={`To be enrolled in a Learn to Skate program requires that we register ${name} with LTS USA. They require this data to help with competition & event registration.`}
          label={`What is ${name}'s gender?`}
          errorMessage={!active && error && submitFailed ? error : ''}
        />
      )}
    </Field>
  )
}

GenderField.defaultProps = {
  name: 'the student',
}

GenderField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
}

export default GenderField
