import React from 'react'
import PropTypes from 'prop-types'

import CircleButton from '../../leevo_ui/CircleButton/CircleButton'
import ProgressBar from '../../leevo_ui/ProgressBar/ProgressBar'

import arrow from '../../../images/arrow-thin.svg'

function headerBackgroundColorClass(color) {
  switch (color) {
    case 'purple':
      return 'bg-gradient-purple-700-500 text-white'
    default:
      return 'bg-white text-gray-900'
  }
}

function RegistrationLayout({
  title,
  progressPercentage,
  children,
  onClickNext,
  isNextDisabled,
  headerColor,
  className,
}) {
  const showProgress =
    progressPercentage !== undefined && headerColor !== 'purple'

  return (
    <div
      className="flex justify-center bg-gray-200"
      data-cy="registration-layout"
    >
      <div className="relative w-full h-screen max-w-lg bg-white shadow-hover">
        {/* FIXME: Extract into leevo_ui component that includes an action button? */}
        <header
          className={`sticky top-0 ${headerBackgroundColorClass(headerColor)}`}
        >
          <div className="flex justify-center">
            <div className="w-full max-w-md px-4 pt-10 pb-6">
              <h1 className="text-xl font-semibold">{title}</h1>
            </div>
          </div>
          {showProgress && <ProgressBar percentage={progressPercentage} />}
        </header>
        <div
          className="overflow-scroll"
          style={{ height: 'calc(100vh - 163px)' }}
        >
          <div className="flex justify-center">
            <div className={`w-full ${className}`}>{children}</div>
          </div>
        </div>
        {onClickNext && (
          <CircleButton
            disabled={isNextDisabled}
            className="sticky float-right mr-3 bg-purple-700 border-none shadow-hover sm:mr-10"
            style={{ bottom: '2vh' }}
            size={16}
            color="purple"
            svgSrc={arrow}
            onClick={onClickNext}
            data-cy="submit"
          />
        )}
      </div>
    </div>
  )
}

RegistrationLayout.headerColors = ['purple', 'white']

RegistrationLayout.defaultProps = {
  isNextDisabled: false,
  headerColor: 'white',
  className: '',
}

RegistrationLayout.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  progressPercentage: PropTypes.number,
  children: PropTypes.node.isRequired,
  onClickNext: PropTypes.func,
  isNextDisabled: PropTypes.bool,
  headerColor: PropTypes.oneOf(RegistrationLayout.headerColors).isRequired,
}

export default RegistrationLayout
