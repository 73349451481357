import React from 'react'
import Recommendation from './Recommendation'
import { shallow } from 'enzyme'
import SkillButton from '../../leevo_ui/SkillButton/SkillButton'

describe('Recommendation', () => {
  let wrapper
  beforeEach(() => {
    wrapper = shallow(
      <Recommendation
        shortLabel="A"
        name="Some Skill"
        studentCount={0}
        status="unintroduced"
      />
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('throws an error when status is not valid', () => {
    function renderWrapper() {
      shallow(
        <Recommendation
          shortLabel="A"
          name="Some Skill"
          studentCount={0}
          status="foo"
        />
      )
    }
    expect(renderWrapper).toThrowError(
      'expected one of ["unintroduced","introduced"]'
    )
  })

  test('renders SkillButton', () => {
    expect(wrapper.find(SkillButton)).toExist()
  })

  test('renders name', () => {
    expect(wrapper.find('.recommendation-name')).toIncludeText('Some Skill')
  })

  test('renders 0 students when no students', () => {
    expect(wrapper).toIncludeText('0 students')
  })

  test('renders 1 student when 1 student', () => {
    wrapper.setProps({ studentCount: 1 })
    expect(wrapper).toIncludeText('1 student')
  })

  test('renders `X students` when more than 1 student', () => {
    wrapper.setProps({ studentCount: 100 })
    expect(wrapper).toIncludeText('100 students')
  })

  describe('when unintroduced', () => {
    test('renders that students needs to complete the skill when unintroduced', () => {
      expect(wrapper).toIncludeText(
        '0 students need to be introduced to this skill'
      )
    })
  })

  test('renders that students needs to complete the skill when introduced', () => {
    wrapper.setProps({ status: 'introduced' })
    expect(wrapper).toIncludeText('0 students need to complete this skill')
  })
})
