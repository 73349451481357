import React from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import amex from '../../../images/credit-cards/amex.svg'
import diners from '../../../images/credit-cards/diners.svg'
import discover from '../../../images/credit-cards/discover.svg'
import jcb from '../../../images/credit-cards/jcb.svg'
import mastercard from '../../../images/credit-cards/mastercard.svg'
import unionpay from '../../../images/credit-cards/unionpay.svg'
import visa from '../../../images/credit-cards/visa.svg'

function CreditCardLogo({ brand, className }) {
  let cardSvg
  switch (brand) {
    case 'American Express':
      cardSvg = amex
      break
    case 'Diners Club':
      cardSvg = diners
      break
    case 'Discover':
      cardSvg = discover
      break
    case 'JCB':
      cardSvg = jcb
      break
    case 'MasterCard':
      cardSvg = mastercard
      break
    case 'UnionPay':
      cardSvg = unionpay
      break
    case 'Visa':
      cardSvg = visa
      break
  }

  if (!cardSvg) return null

  return <SVG className={className} src={cardSvg} />
}

CreditCardLogo.defaultProps = {
  brand: 'Unknown',
}

CreditCardLogo.propTypes = {
  brand: PropTypes.oneOf([
    'American Express',
    'Diners Club',
    'Discover',
    'JCB',
    'MasterCard',
    'UnionPay',
    'Visa',
    'Unknown',
  ]),
  className: PropTypes.string,
}

export default CreditCardLogo
