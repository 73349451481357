import React from 'react'
import { number, boolean, text } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'
import classNames from 'classnames'
import { Form, Field } from 'react-final-form'
import faker from 'faker'

import Button from '../Button/Button'
import CheckboxListInput from './CheckboxListInput'

export default {
  title: 'Forms/CheckboxListInput',
  component: CheckboxListInput,
}

export function checkboxListInput() {
  const withMargins = boolean('With margins?', true)
  const checkboxCount = number('Number of additional checkboxes', 2)
  const isSmallList = boolean('Small checkboxes?', false)

  function generateCheckboxes() {
    const tomLabel = text('Name of custom checkbox', 'Tom')
    const checkboxes = [
      {
        id: -1,
        label: tomLabel,
        value: tomLabel.toLowerCase(),
      },
    ]
    for (let i = 0; i < checkboxCount; i++) {
      const name = faker.name.firstName()
      checkboxes.push({
        id: i,
        label: name,
        value: name.toLowerCase(),
      })
    }
    return checkboxes
  }

  const options = generateCheckboxes()
  const additionalDataKey = text('name of key for additional data', 'testKey')
  const additionalDataValue = text('value for additional data', 'test value')

  function addAdditionalData(values) {
    return values.map((value) => {
      return { ...value, [additionalDataKey]: additionalDataValue }
    })
  }

  return (
    <div className={classNames({ 'm-10': withMargins }, 'max-w-md')}>
      <Form
        onSubmit={action('Submitted form')}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field name="checkbox-list-input-field">
                {({ input }) => (
                  <CheckboxListInput
                    className="m-6"
                    id="foo"
                    options={options}
                    values={input.value}
                    small={isSmallList}
                    onChange={(values) => {
                      const newValues = addAdditionalData(values)
                      input.onChange(newValues)
                    }}
                  />
                )}
              </Field>
              <div className="flex justify-left">
                <Button label="Submit" />
              </div>
            </form>
          )
        }}
      />
    </div>
  )
}
