import React from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'

import Calendar from '../leevo_ui/Calendar/Calendar'
import KlassDatePicker from './KlassDatePicker/KlassDatePicker'
import KlassDaysList from './KlassDaysList/KlassDaysList'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import useFacility from '../../utilities/hooks/useFacility'

function FacilitiesKlassDaysIndex({
  availableDays,
  facility,
  klasses,
  selectedDate,
  selectedMonth,
  isLoadingKlasses,
  onDateSelected,
  onMonthChange,
}) {
  const { isLoading: isLoadingFacility } = useFacility()
  return (
    <ApplicationLayout isContentLoading={isLoadingFacility || isLoadingKlasses}>
      {selectedDate ? (
        <>
          <KlassDatePicker
            availableDays={availableDays}
            onDateSelected={onDateSelected}
            selectedDate={selectedDate}
          />
          <KlassDaysList klasses={klasses} selectedDate={selectedDate} />
        </>
      ) : (
        <div className="flex justify-center mt-5 mb-14">
          <Calendar
            className="w-full max-w-3xl pb-14"
            klasses={klasses}
            timeZone={facility.timeZone}
            isLoading={isLoadingKlasses}
            onDateSelected={onDateSelected}
            providedMonth={selectedMonth}
            onMonthChange={onMonthChange}
          />
        </div>
      )}
    </ApplicationLayout>
  )
}

FacilitiesKlassDaysIndex.defaultProps = {
  klasses: [],
  isLoadingKlasses: false,
}

FacilitiesKlassDaysIndex.propTypes = {
  availableDays: PropTypes.arrayOf(PropTypes.string),
  facility: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    timeZone: PropTypes.string,
  }).isRequired,
  klasses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      coaches: PropTypes.array.isRequired,
      startDatetime: PropTypes.oneOfType([
        PropTypes.string,
        momentPropTypes.momentObj,
      ]),
      students: PropTypes.arrayOf(
        PropTypes.shape({
          fullName: PropTypes.string.isRequired,
          preferredName: PropTypes.string.isRequired,
          currentSkillLevel: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
              .isRequired,
            name: PropTypes.string.isRequired,
          }),
        })
      ).isRequired,
    })
  ),
  isLoadingKlasses: PropTypes.bool,
  onDateSelected: PropTypes.func,
  onMonthChange: PropTypes.func,
  selectedDate: momentPropTypes.momentObj,
  selectedMonth: momentPropTypes.momentObj,
}

export default FacilitiesKlassDaysIndex
