import React from 'react'
import { mount } from 'enzyme'

import { MemoryRouter, Route } from 'react-router-dom'

import PricingSection from './PricingSection'

describe('PricingSection', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/PricingSection']}>
          <Route path="/PricingSection">
            <PricingSection />
          </Route>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
