import React from 'react'
import Input from './Input'
import { shallow } from 'enzyme'

describe('Input', () => {
  let wrapper
  beforeEach(() => {
    wrapper = shallow(<Input id="id-foo" type="text" />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders rest of provided props on the input element', () => {
    wrapper.setProps({ foo: 'bar' })
    expect(wrapper.find('input').prop('foo')).toBe('bar')
  })

  test('renders className on topmost div', () => {
    wrapper.setProps({ className: 'bar' })
    expect(wrapper.first('div')).toHaveClassName('bar')
  })

  describe('id', () => {
    test('is required', () => {
      function renderWrapper() {
        shallow(<Input />)
      }
      expect(renderWrapper).toThrowError('`id` is marked as required')
    })

    test('is rendered on the input element', () => {
      expect(wrapper.find('input').prop('id')).toBe('id-foo')
    })

    test('renders in the htmlFor of the label', () => {
      wrapper.setProps({ label: 'some label' }) // label must be specified to appear
      expect(wrapper.find('label').prop('htmlFor')).toBe('id-foo')
    })
  })

  describe('type', () => {
    test('is required', () => {
      function renderWrapper() {
        shallow(<Input />)
      }
      expect(renderWrapper).toThrowError('`type` is marked as required')
    })

    test('must be text or password', () => {
      function renderWrapper() {
        shallow(<Input type="foo" />)
      }

      expect(renderWrapper).toThrowError(
        'expected one of ["text","password","number","search"]'
      )
    })

    test('is rendered on the input element', () => {
      expect(wrapper.find('input').prop('type')).toBe('text')
    })
  })

  describe('label', () => {
    test('is rendered within a label element', () => {
      wrapper.setProps({ label: 'foo' })
      expect(wrapper.find('label')).toHaveText('foo')
    })

    test('does not render a label element if not provided', () => {
      expect(wrapper.find('label')).not.toExist()
    })
  })

  describe('errorMessage', () => {
    test('is present when provided', () => {
      wrapper.setProps({ errorMessage: 'foo' })
      expect(wrapper).toIncludeText('foo')
    })

    test('changes color of label when provided', () => {
      wrapper.setProps({ errorMessage: 'foo', label: 'some label' })
      expect(wrapper.find('label')).toHaveClassName('text-red-500')
    })

    test('changes color of input border when provided', () => {
      wrapper.setProps({ errorMessage: 'foo' })
      expect(wrapper.find('input')).toHaveClassName('border-red-500')
    })
  })

  describe('required', () => {
    beforeEach(() => {
      wrapper.setProps({ required: true })
    })

    test('renders a star at the end of the label', () => {
      wrapper.setProps({ label: 'some label' })
      expect(wrapper.find('label')).toIncludeText('*')
    })

    test('renders required on the input', () => {
      expect(wrapper.find('input')).toHaveProp('required')
    })
  })

  describe('disabled', () => {
    beforeEach(() => {
      wrapper.setProps({ disabled: true })
    })

    test('grays out the input', () => {
      expect(wrapper.find('input')).toHaveClassName('bg-gray-100')
    })

    test('renders disabled on the input', () => {
      expect(wrapper.find('input')).toHaveProp('disabled')
    })
  })

  describe('maxLength', () => {
    test('is shows character counter when provided', () => {
      wrapper.setProps({ maxLength: 20 })
      expect(wrapper).toIncludeText('20 characters remaining')
    })

    test('turns red when under 10 characters left', () => {
      wrapper.setProps({ maxLength: 8 })
      expect(wrapper).toIncludeText('8 characters remaining')
    })
  })
})
