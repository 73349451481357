import React from 'react'
import Modal from './Modal'
import { mount } from 'enzyme'

describe('Modal', () => {
  let wrapper, emptyDiv
  beforeEach(() => {
    emptyDiv = <div>Empty</div>
    wrapper = mount(<Modal>{emptyDiv}</Modal>)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders without header if not provided', () => {
    expect(wrapper.find('header')).not.toExist()
  })

  describe('when a header string is provider', () => {
    beforeEach(() => {
      wrapper = mount(<Modal header="foo">{emptyDiv}</Modal>)
    })
    test('renders header when provided', () => {
      expect(wrapper.find('header')).toIncludeText('foo')
    })

    test('renders with purple by default', () => {
      expect(wrapper.find('header').props().className).toMatch('purple')
    })

    test('renders with provided headerColor', () => {
      wrapper = mount(
        <Modal header="foo" headerColor="orange">
          {emptyDiv}
        </Modal>
      )
      expect(wrapper.find('header').props().className).toMatch('orange')
    })

    test('renders with a close button if onClickClose provided', () => {
      wrapper.setProps({ onClickClose: jest.fn() })
      expect(wrapper.find('[data-cy="modal-close-button"]').length).toBe(1)
    })
  })
})
