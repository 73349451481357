import React, { useEffect, useState, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import classNames from 'classnames'
import { Tab } from '@headlessui/react'
import { capitalize } from 'lodash'

import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import EmptyList from '../leevo_ui/EmptyList/EmptyList'
import IndexHeader from '../leevo_ui/IndexHeader/IndexHeader'
import { requestCreator } from '../../utilities/requests'
import emptyCalendar from '../../images/empty-calendar.svg'
import useFacility from '../../utilities/hooks/useFacility'
import KlassPackageCard from './KlassPackageCard/KlassPackageCard'

const {
  get: getKlassPackages,
  cancel: cancelKlassPackagesRequests,
} = requestCreator()

function FacilitiesKlassPackagesIndex() {
  const { isLoading: isFacilityLoading } = useFacility()
  const { facility_id: facilityId } = useParams()
  const { replace: redirect, push: navigate } = useHistory()
  const [klassPackages, setKlassPackages] = useState({
    active: [],
    disabled: [],
  })
  const [isLoading, setIsLoading] = useState(true)

  const getKlassPackagesOfState = useCallback(
    (state) => {
      setIsLoading(true)
      const key = state === 'active' ? 'active' : 'disabled'

      getKlassPackages(`/api/facilities/${facilityId}/class_packages`, {
        params: { filter: { active_eq: state === 'active' } },
      })
        .then((newKlassPackages) => {
          setKlassPackages((klassPackages) => ({
            ...klassPackages,
            [key]: newKlassPackages,
          }))
          setIsLoading(false)
        })
        .catch((error) => !axios.isCancel(error) && redirect('/404'))
    },
    [facilityId, redirect, setKlassPackages]
  )

  const panelClassNames =
    'flex flex-wrap justify-center lg:justify-start w-full max-w-2xl pt-2 mb-24'

  function tabClassNames({ selected }) {
    return classNames(
      'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
      'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-purple-400 ring-purple-400 ring-opacity-60',
      selected
        ? 'text-purple-700 bg-white shadow'
        : 'text-purple-500 hover:bg-white/[0.12] hover:text-purple-700'
    )
  }

  useEffect(() => {
    if (!isFacilityLoading) getKlassPackagesOfState('active')

    return cancelKlassPackagesRequests
  }, [isFacilityLoading, getKlassPackagesOfState])

  return (
    <ApplicationLayout>
      <IndexHeader
        title="Class Packages"
        onAddClick={() =>
          navigate(`/facilities/${facilityId}/class_packages/new`)
        }
      />
      <div className="w-full h-full px-2 pt-3 pb-24 sm:px-0">
        <Tab.Group
          manual
          onChange={(index) =>
            getKlassPackagesOfState(Object.keys(klassPackages)[index])
          }
        >
          <Tab.List className="flex p-1 mx-auto space-x-1 shadow-inner bg-gray-200/50 rounded-xl w-60">
            {Object.keys(klassPackages).map((state) => (
              <Tab key={state} className={tabClassNames}>
                {capitalize(state)}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels className="mt-2">
            <div className="flex justify-center">
              {Object.keys(klassPackages).map((state) => {
                let emptyStateMessage
                if (state === 'active') {
                  emptyStateMessage =
                    'You have no active class packages set for your menu. Create some class packages to allow new students to register.'
                } else {
                  emptyStateMessage =
                    'You have no disabled class packages set on your menu.'
                }
                return (
                  <Tab.Panel key={state} className={panelClassNames}>
                    {isLoading &&
                      [...Array(6)]
                        .map((_value, index) => index)
                        .map((value) => (
                          <KlassPackageCard key={value} loading />
                        ))}
                    {!isLoading && klassPackages[state].length === 0 && (
                      <EmptyList
                        message={emptyStateMessage}
                        svgSrc={emptyCalendar}
                        buttonText="Create One +"
                        onButtonClick={() =>
                          navigate(
                            `/facilities/${facilityId}/class_packages/new`
                          )
                        }
                      />
                    )}
                    {!isLoading &&
                      klassPackages[state].map((klassPackage) => (
                        <KlassPackageCard
                          key={klassPackage.id}
                          facilityId={facilityId}
                          {...klassPackage}
                        />
                      ))}
                  </Tab.Panel>
                )
              })}
            </div>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </ApplicationLayout>
  )
}

export default FacilitiesKlassPackagesIndex
