import React from 'react'
import FeatureGrid from './FeatureGrid'

export default {
  title: 'Home Feature/Components/FeatureGrid',
  component: FeatureGrid,
}

export function featureGrid(args) {
  return <FeatureGrid {...args} />
}
