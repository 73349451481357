import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { get } from 'lodash'

import Modal from '../Modal/Modal'
import Button from '../Button/Button'
import MultiEmailField from '../MultiEmailField/MultiEmailField'
import SelectInput from '../SelectInput/SelectInput'
import Notification from '../Notification/Notification'
import { requestCreator } from '../../../utilities/requests'

const { post: sendInvitations, cancel: cancelInvitations } = requestCreator()

function AddCoachModal({ open, onClose }) {
  const [notification, setNotification] = useState({ show: false, message: '' })
  const { facility_id: facilityId } = useParams()

  useEffect(() => {
    return cancelInvitations
  }, [])

  return (
    <Modal
      open={open}
      header="Add Team Members"
      onClickClose={onClose}
      onClickBackground={onClose}
    >
      <Form
        initialValues={{ privileges: 'coach' }}
        onSubmit={({ emails, privileges }) => {
          sendInvitations('/auth/invitation', {
            users: emails.map((email) => ({ email })),
            facilityId,
            coach: privileges == 'coach',
            admin: privileges == 'director',
          })
            .then((invitedUsers) => {
              onClose({ invitedUsers })
            })
            .catch((error) => {
              if (!axios.isCancel(error)) {
                setNotification({
                  show: true,
                  message:
                    get(error, 'response.data.error') ||
                    'Something went wrong. Try again.',
                })
              }
              return Promise.reject()
            })
        }}
        render={({ handleSubmit, pristine, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <MultiEmailField label="Who do you want to add to your team?" />
              <Field name="privileges">
                {({ input }) => (
                  <SelectInput
                    {...input}
                    id="privileges"
                    label="What permissions should they have?"
                    options={[
                      {
                        label: 'Coach - view classes and update skills',
                        value: 'coach',
                      },
                      {
                        label: 'Director - full access',
                        value: 'director',
                      },
                    ]}
                  />
                )}
              </Field>
              <div className="flex items-center justify-end">
                <Notification
                  show={notification.show}
                  color="red"
                  className="mr-2"
                  onDisappearTimeout={() =>
                    setNotification({ ...notification, show: false })
                  }
                >
                  {notification.message}
                </Notification>
                <Button
                  type="submit"
                  data-cy="submit"
                  label="Submit"
                  disabled={submitting || pristine}
                  loading={submitting}
                />
              </div>
            </form>
          )
        }}
      />
    </Modal>
  )
}

AddCoachModal.defaultProps = {
  open: false,
}

AddCoachModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

export default AddCoachModal
