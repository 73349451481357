import React from 'react'
import { mount } from 'enzyme'
import { MemoryRouter, Route } from 'react-router-dom'

import KlassForm from './KlassForm'

describe('KlassForm', () => {
  let wrapper, mockOnSubmit

  beforeEach(() => {
    mockOnSubmit = jest.fn()
    wrapper = mount(
      <MemoryRouter initialEntries={['/1']}>
        <Route path="/:facility_id">
          <KlassForm
            onSubmit={mockOnSubmit}
            dates={[]}
            coaches={[]}
            supportedSkillLevels={[]}
            maxStudents={undefined}
          />
        </Route>
      </MemoryRouter>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders a form', () => {
    expect(wrapper.find('form').length).toEqual(1)
  })

  test.todo('renders with pre-populated fields') //, () => {
  // expect(
  //   wrapper.find('[data-cy="preferred_name-input"]').hostNodes().props().value
  // ).toBe('foobar')
  // expect(
  //   wrapper.find('[data-cy="full_name-input"]').hostNodes().props().value
  // ).toBe('foobar barbaz')
  // expect(
  //   wrapper.find('[data-cy="gender-input"]').hostNodes().props().value
  // ).toBe('female')
  // expect(
  //   wrapper.find('[data-cy="ltsNumber-input"]').hostNodes().props().value
  // ).toBe('123456')
  // expect(
  //   wrapper.find('[data-cy="skillLevel-select-input"]').hostNodes().props()
  //     .value
  // ).toBe('14')
  //})

  describe('submission', () => {
    beforeEach(() => {
      wrapper.find('form').simulate('submit')
    })

    test.todo('calls the onSubmit function when all inputs are valid') //, () => {
    //expect(mockOnSubmit).toHaveBeenCalled()
    //})
  })
})
