import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import Button from '../leevo_ui/Button/Button'

function NoMatch() {
  const location = useLocation()
  const { goBack } = useHistory()

  Sentry.withScope(function (scope) {
    scope.setTag('http_status_code', '404')
    scope.setExtra('pathname', location.pathname)
    Sentry.captureMessage('404 Not Found')
  })

  return (
    <div className="fixed inset-0 inset-x-0 bottom-0 flex flex-col items-center justify-center px-4 pb-4">
      <h1 className="text-6xl text-purple-600 font-wordmark">OOPS!</h1>
      <p className="max-w-md px-4 mb-4 text-center">
        {
          "The page you were looking for doesn't exist, isn't available, or is loading incorrectly."
        }
      </p>
      <Button label="Go Back" onClick={goBack} />
    </div>
  )
}

export default NoMatch
