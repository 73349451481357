import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { Transition, Popover } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { useLocation, useHistory } from 'react-router-dom'
import { get } from 'lodash'

import Notification from '../../Notification/Notification'

const links = [
  { name: 'Home', to: '/' },
  { name: 'Pricing', to: '/pricing' },
  // { name: 'About', to: '/about' },
]

function MarketingNavBar() {
  const { state } = useLocation()
  const history = useHistory()

  return (
    <header className="w-full">
      <Popover className="relative bg-white dark:bg-gray-800">
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link
              to="/"
              className="pl-2 text-2xl text-purple-600 font-wordmark"
            >
              <span className="sr-only">Leevo</span>
              Leevo
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white dark:bg-gray-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 dark:hover:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 dark:focus:ring-purple-800">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            {links.map((item) => (
              <NavLink
                exact
                key={item.name}
                to={item.to}
                activeClassName="text-purple-500"
                className="text-base font-medium text-gray-500 hover:text-purple-900 dark:hover:text-purple-700"
              >
                {item.name}
              </NavLink>
            ))}
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <Link
              to="/login"
              className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-gray-300"
            >
              Sign in
            </Link>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-gray-800 divide-y-2 divide-gray-50 dark:divide-gray-700">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <Link
                      to="/"
                      className="pl-2 text-2xl text-purple-600 font-wordmark"
                    >
                      <span className="sr-only">Leevo</span>
                      Leevo
                    </Link>
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white dark:bg-gray-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 dark:hover:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 dark:focus:ring-purple-800">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <nav>
                <div className="flex flex-col">
                  {links.map((item) => (
                    <NavLink
                      exact
                      key={item.name}
                      to={item.to}
                      activeClassName="bg-purple-50 dark:bg-purple-800 border-purple-500 dark:border-purple-300 text-purple-700 dark:text-purple-400 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                      className="border-transparent dark:border-gray-800 text-gray-600 dark:text-gray-400 hover:bg-purple-50 dark:hover:bg-purple-900 hover:border-purple-300 dark:hover:border-purple-600 hover:text-purple-500 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    >
                      {item.name}
                    </NavLink>
                  ))}
                </div>
                <div className="mt-6">
                  <Link
                    to="/login"
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent dark:border-gray-800 rounded-md shadow-sm text-base font-medium text-white dark:text-gray-300 bg-purple-600 dark:bg-purple-800 hover:bg-purple-700 dark:hover:bg-purple-900"
                  >
                    Sign in
                  </Link>
                </div>
              </nav>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <Notification
        color={get(state, 'notification.color')}
        show={get(state, 'notification')}
        onDisappearTimeout={() =>
          history.push({ state: { notification: undefined } })
        }
        className="sticky top-0 z-50"
      >
        {get(state, 'notification.message')}
      </Notification>
    </header>
  )
}

export default MarketingNavBar
