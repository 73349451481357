import React from 'react'
import { mount } from 'enzyme'
import { MemoryRouter, Route } from 'react-router-dom'

import KlassPackageForm from './KlassPackageForm'

describe('KlassPackageForm', () => {
  let wrapper, mockOnSubmit, mockKlassesData

  beforeEach(() => {
    mockOnSubmit = jest.fn()
    mockKlassesData = [
      {
        id: 1,
        dayOfWeek: 'Mon',
        endDate: 'Mar 9',
        maxStudents: 10,
        price: 5000,
        priceInDollars: '$50.00',
        remainingKlassDays: 20,
        spotsLeft: 10,
        startDate: 'Mar 9',
        timeOfDay: '4:00 AM',
        supportedSkillLevels: [
          { id: 1, name: 'Basic 1' },
          { id: 2, name: 'Basic 2' },
          { id: 3, name: 'Basic 3' },
        ],
        coaches: [
          {
            id: 1,
            email: 'user1@example.com',
            fullName: 'Jane Doe',
            cloudinaryPhotoPublicId: null,
            preferredName: 'Janey',
          },
          {
            id: 2,
            email: 'user2@example.com',
            fullName: 'Emily Johnson',
            cloudinaryPhotoPublicId: null,
            preferredName: 'Emmy',
          },
        ],
      },
      {
        id: 2,
        selected: true,
        dayOfWeek: 'Mon',
        endDate: 'Mar 9',
        maxStudents: 10,
        price: 5000,
        priceInDollars: '$50.00',
        remainingKlassDays: 20,
        spotsLeft: 10,
        startDate: 'Mar 9',
        timeOfDay: '4:00 AM',
        supportedSkillLevels: [
          { id: 1, name: 'Basic 1' },
          { id: 2, name: 'Basic 2' },
          { id: 3, name: 'Basic 3' },
        ],
        coaches: [
          {
            id: 1,
            email: 'user1@example.com',
            fullName: 'Jane Doe',
            cloudinaryPhotoPublicId: null,
            preferredName: 'Janey',
          },
          {
            id: 2,
            email: 'user2@example.com',
            fullName: 'Emily Johnson',
            cloudinaryPhotoPublicId: null,
            preferredName: 'Emmy',
          },
        ],
      },
      {
        id: 3,
        dayOfWeek: 'Mon',
        endDate: 'Mar 9',
        maxStudents: 10,
        price: 5000,
        priceInDollars: '$50.00',
        remainingKlassDays: 0,
        spotsLeft: 10,
        startDate: 'Mar 9',
        timeOfDay: '4:00 AM',
        supportedSkillLevels: [
          { id: 1, name: 'Basic 1' },
          { id: 2, name: 'Basic 2' },
          { id: 3, name: 'Basic 3' },
        ],
        coaches: [
          {
            id: 1,
            email: 'user1@example.com',
            fullName: 'Jane Doe',
            cloudinaryPhotoPublicId: null,
            preferredName: 'Janey',
          },
          {
            id: 2,
            email: 'user2@example.com',
            fullName: 'Emily Johnson',
            cloudinaryPhotoPublicId: null,
            preferredName: 'Emmy',
          },
        ],
      },
    ]
    wrapper = mount(
      <MemoryRouter initialEntries={['/1']}>
        <Route path="/:facility_id">
          <KlassPackageForm
            onSubmit={mockOnSubmit}
            facilityId={1}
            price={5000}
            klasses={mockKlassesData}
            title="Foo"
            description="Lorem ipsum dolor sit amir"
            open={false}
          />
        </Route>
      </MemoryRouter>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders a form', () => {
    expect(wrapper.find('form').length).toEqual(1)
  })

  test('renders the classes with remaining class days', () => {
    expect(
      wrapper.find('[data-cy="klass-1-check-button"]').hostNodes()
    ).toExist()
    expect(
      wrapper.find('[data-cy="klass-2-check-button"]').hostNodes()
    ).toExist()
  })

  test('does not render the classes that have no remaining class days', () => {
    expect(
      wrapper.find('[data-cy="klass-3-check-button"]').hostNodes()
    ).not.toExist()
  })

  test('renders with pre-populated fields', () => {
    expect(
      wrapper.find('[data-cy="price-currency-input"]').hostNodes().props().value
    ).toBe('$50.00')
    expect(
      wrapper.find('[data-cy="title-input"]').hostNodes().props().value
    ).toBe('Foo')
    expect(
      wrapper.find('[data-cy="description-textarea"]').hostNodes().props().value
    ).toBe('Lorem ipsum dolor sit amir')
    expect(
      wrapper.find('[data-cy="open-checkbox-input"]').hostNodes().props()
        .checked
    ).toBe(false)
    expect(
      wrapper.find('[data-cy="klass-1-check-button"]').hostNodes().props()[
        'aria-pressed'
      ]
    ).toBe(false)
    expect(
      wrapper.find('[data-cy="klass-2-check-button"]').hostNodes().props()[
        'aria-pressed'
      ]
    ).toBe(true)
  })

  describe('with no klasses', () => {
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/1']}>
          <Route path="/:facility_id">
            <KlassPackageForm
              onSubmit={mockOnSubmit}
              facilityId={1}
              price={5000}
              klasses={[]}
              title="Foo"
              description="Lorem ipsum dolor sit amir"
              open={false}
            />
          </Route>
        </MemoryRouter>
      )
    })

    it('shows a link to create classes', () => {
      expect(
        wrapper.find('[data-cy="create-class-link"]').hostNodes()
      ).toExist()
    })
  })

  describe('submission', () => {
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/1']}>
          <Route path="/:facility_id">
            <KlassPackageForm
              onSubmit={mockOnSubmit}
              facilityId={1}
              klasses={mockKlassesData}
            />
          </Route>
        </MemoryRouter>
      )
      wrapper.find('form').simulate('submit')
    })

    test('does not call the onSubmit function when required fields are not filled', () => {
      expect(mockOnSubmit).not.toHaveBeenCalled()
    })

    test('calls the onSubmit function when all required fields', () => {
      wrapper
        .find('[data-cy="price-currency-input"]')
        .hostNodes()
        .simulate('keydown', { key: '1' })
        .simulate('keydown', { key: '9' })
        .simulate('keydown', { key: '9' })
      wrapper
        .find('[data-cy="title-input"]')
        .hostNodes()
        .simulate('change', { target: { value: 'Packaged Classes' } })
      wrapper
        .find('[data-cy="klass-1-check-button"]')
        .hostNodes()
        .simulate('click')
      wrapper
        .find('[data-cy="klass-2-check-button"]')
        .hostNodes()
        .simulate('click')
      wrapper.find('form').simulate('submit')
      expect(mockOnSubmit).toHaveBeenCalled()
    })
  })
})
