import React from 'react'
import { Link } from 'react-router-dom'

import ContentPageLayout from '../../../ContentPageLayout/ContentPageLayout'

const baseURL =
  'https://leevo-public.s3.amazonaws.com/images/support/logging_in'
let id = 0
const steps = [
  {
    id: id++,
    description: (
      <p>
        Go to{' '}
        <Link to="/forgot_password">https://goleevo.com/forgot_password</Link>
      </p>
    ),
    img: `${baseURL}/forgot_password.png`,
    alt: 'Forgot Password Form',
  },
  {
    id: id++,
    description: <p>Enter your email address</p>,
    img: `${baseURL}/enter_email.png`,
    alt: 'Forgot Password Form with Email',
  },
  {
    id: id++,
    description: (
      <p>Check your email for an email from Leevo to reset your password</p>
    ),
    img: `${baseURL}/email_inbox.png`,
    alt: 'Password Resetemail in inbox',
  },
  {
    id: id++,
    description: <p>Click the link in the email to reset your password.</p>,
    img: `${baseURL}/password_reset_email.png`,
    alt: 'The password reset email',
  },
  {
    id: id++,
    description: <p>Enter a new password and confirm it</p>,
    img: `${baseURL}/set_new_password.png`,
    alt: 'New Password Form',
  },
  {
    id: id++,
    description: (
      <p>
        🎉 You&apos;re done! Go to{' '}
        <Link to="/login">https://goleevo.com/login</Link> to log in
      </p>
    ),
  },
]

function LoggingIn() {
  return (
    <ContentPageLayout
      category="Support"
      title="Resolving trouble logging in"
      intro="Login issues can usually be resolved quickly by resetting your password. Let's learn how."
    >
      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3">
        <figure className="flex justify-center md:justify-start">
          <img
            className="max-w-full rounded-lg max-h-72"
            src={`${baseURL}/logging_in1.png`}
            alt="Login Screen Error"
          />
        </figure>
        <figure className="flex justify-center md:justify-end">
          <img
            className="max-w-full rounded-lg max-h-72"
            src={`${baseURL}/logging_in2.png`}
            alt="Registration Login error"
          />
        </figure>
      </div>
      <p>
        If you&apos;re seeing an error that you have invalid login credentials,
        it usually means you&apos;re entering your username or password
        incorrectly.
      </p>
      <p>The fastest way to resolve this issue is to reset your password:</p>
      <ol>
        {steps.map(({ id, description, img, alt, caption }) => (
          <li key={id}>
            {description}
            {img && (
              <figure>
                <img
                  className="max-w-full rounded-lg max-h-72"
                  src={img}
                  alt={alt}
                />
                {caption && (
                  <figcaption className="text-gray-400">{caption}</figcaption>
                )}
              </figure>
            )}
          </li>
        ))}
      </ol>
    </ContentPageLayout>
  )
}

LoggingIn.defaultProps = {}

LoggingIn.propTypes = {}

export default LoggingIn
