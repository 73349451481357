import React from 'react'
import PropTypes from 'prop-types'

import RecommendationLevelCard from '../RecommendationLevelCard/RecommendationLevelCard'
import LoadingSpinner from '../../leevo_ui/LoadingSpinner/LoadingSpinner'

function Recommendations({ recommendations, isLoading }) {
  if (!recommendations || recommendations.length === 0 || isLoading) {
    return (
      <div
        className="mx-1 bg-white shadow rounded-xl print:hidden"
        data-cy="recommendation-guidance"
      >
        <div className="flex items-start justify-between p-5 border-b border-gray-300">
          <p className="text-lg font-medium">
            <p>Recommendations</p>
          </p>
        </div>
        <p className="px-5 py-4 text-sm">
          {isLoading ? (
            <LoadingSpinner className="flex justify-center" />
          ) : (
            'Check attendance to see recommendations.'
          )}
        </p>
      </div>
    )
  } else {
    // Creates array of all level names
    const skillLevelIds = [
      ...new Set(recommendations.map(({ skillLevel }) => skillLevel.id)),
    ]
    return skillLevelIds.map((levelId) => (
      <RecommendationLevelCard
        recommendations={recommendations.filter(
          (rec) => rec.skillLevel.id === levelId
        )}
        key={levelId}
      />
    ))
  }
}

Recommendations.propTypes = {
  recommendations: PropTypes.arrayOf(
    PropTypes.shape({
      skillLevel: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  isLoading: PropTypes.bool,
}

export default Recommendations
