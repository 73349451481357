import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'
import documentAdd from '../../../images/document-add-outline.svg'
import check from '../../../images/badge-check-outline.svg'

function DropToUploadInput({ label, value, onChange, className, ...rest }) {
  const [isDragging, setIsDragging] = useState(false)
  const dropRef = useRef(null)
  const dragCounter = useRef(0) // Counting drags ensures that we don't refresh if drag is triggered on a child

  useEffect(() => {
    const el = dropRef.current
    el.addEventListener('dragenter', handleDragIn)
    el.addEventListener('dragleave', handleDragOut)
    el.addEventListener('dragover', handleDrag)
    el.addEventListener('drop', handleDrop)
    return () => {
      el.removeEventListener('dragenter', handleDragIn)
      el.removeEventListener('dragleave', handleDragOut)
      el.removeEventListener('dragover', handleDrag)
      el.removeEventListener('drop', handleDrop)
    }
  })

  function preventDefaultBehavior(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  function handleDrag(e) {
    preventDefaultBehavior(e)
  }

  function handleDragIn(e) {
    preventDefaultBehavior(e)
    dragCounter.current++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true)
    }
  }

  function handleDragOut(e) {
    preventDefaultBehavior(e)
    dragCounter.current--
    if (dragCounter.current === 0) setIsDragging(false)
  }

  function handleDrop(e) {
    preventDefaultBehavior(e)
    setIsDragging(false)
    dragCounter.current
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onChange(e.dataTransfer.files[0])
      e.dataTransfer.clearData()
      dragCounter.current = 0
    }
  }

  return (
    <div {...rest} className={className} ref={dropRef}>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div className="space-y-1 text-center min-h-20">
          {isDragging && 'Drop to select...'}
          {!isDragging && !value && (
            <>
              <SVG
                src={documentAdd}
                className="mx-auto h-12 w-12 text-gray-400 stroke-1 stroke-current"
              />
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                  <span>Upload a file</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    onChange={(e) => onChange(e.target.files[0])}
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-gray-500">CSV up to 10 MB</p>
            </>
          )}
          {!isDragging && value && (
            <div className="flex flex-col justify-center items-center h-full text-sm text-gray-600">
              <SVG
                src={check}
                className="mx-auto h-12 w-12 text-gray-400 stroke-1 stroke-current"
              />
              <label htmlFor="file-upload">
                <span className="font-medium">Selected File: </span>
                <span>{value.name}</span>
              </label>
              <button
                className="ml-2 relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                onClick={() => onChange(null)}
              >
                Clear
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

DropToUploadInput.defaultProps = {}

DropToUploadInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.shape({ name: PropTypes.string }),
  onChange: PropTypes.func,
  className: PropTypes.string,
}

export default DropToUploadInput
