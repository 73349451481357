import { select, boolean } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'

import React from 'react'
import CheckButton from './CheckButton'

export default {
  title: 'Leevo UI/CheckButton',
  component: CheckButton,
}

export function singleButton() {
  return (
    <div className="flex flex-col items-center justify-center">
      <CheckButton
        onClick={action('CheckButton clicked')}
        checked={boolean('Checked?', true)}
        disabled={boolean('Disabled?', false)}
        size={select('Size', CheckButton.sizes, CheckButton.defaultProps.size)}
        loading={boolean('Loading?', false)}
      />
    </div>
  )
}

export function all() {
  return (
    <div className="flex flex-col items-center justify-center">
      {CheckButton.sizes.map((size) => (
        <div key={size} className="m-4 text-center">
          <h1>Size {size}</h1>
          <CheckButton
            className="m-1"
            checked
            key={`checked-${size}`}
            onClick={action(`CheckButton-checked-${size} clicked`)}
            size={size}
            loading={boolean('Loading?', false)}
          />
          <CheckButton
            className="m-1"
            key={`unchecked-${size}`}
            onClick={action(`CheckButton-unchecked-${size} clicked`)}
            size={size}
            loading={boolean('Loading?', false)}
          />
        </div>
      ))}
    </div>
  )
}
