import React from 'react'
import { mount } from 'enzyme'

import MultiSelectKlassPackage from './MultiSelectKlassPackage'

describe('MultiSelectKlassPackage', () => {
  let wrapper

  beforeEach(() => {
    wrapper = mount(<MultiSelectKlassPackage value={[]} onChange={() => {}} />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
