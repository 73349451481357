import React from 'react'
import { text, number, boolean, select } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'

import RegistrationLayout from './RegistrationLayout'
import Input from '../../leevo_ui/Input/Input'

export default {
  title: 'Registration Feature/Registration Layout',
  component: RegistrationLayout,
}

export function registrationLayout() {
  const showNextButton = boolean('Show next button?', true)
  const onClickNext = showNextButton ? action('Clicked Next') : undefined
  const isNextDisabled = boolean('Next button disabled?', false)
  const hideProgressBar = boolean('Hide progress bar?', false)
  const progressPercentage = number('Percent Complete', 50, {
    range: true,
    min: 0,
    max: 100,
    step: 1,
  })

  return (
    <RegistrationLayout
      title={text('Title', 'Some title')}
      headerColor={select(
        'Header Color',
        RegistrationLayout.headerColors,
        RegistrationLayout.defaultProps.headerColor
      )}
      progressPercentage={hideProgressBar ? undefined : progressPercentage}
      onClickNext={onClickNext}
      isNextDisabled={isNextDisabled}
    >
      <Input
        autoFocus
        id="fullName"
        type="text"
        label="What's your full name?"
        placeholder="Robert Button"
      />
      <Input
        id="preferredName"
        type="text"
        label="What's your preferred name?"
        placeholder="Robbie"
      />
      <Input
        autoFocus
        id="fullName"
        type="text"
        label="What's your full name?"
        placeholder="Robert Button"
      />
      <Input
        id="preferredName"
        type="text"
        label="What's your preferred name?"
        placeholder="Robbie"
      />
      <Input
        autoFocus
        id="fullName"
        type="text"
        label="What's your full name?"
        placeholder="Robert Button"
      />
      <Input
        id="preferredName"
        type="text"
        label="What's your preferred name?"
        placeholder="Robbie"
      />
      <Input
        autoFocus
        id="fullName"
        type="text"
        label="What's your full name?"
        placeholder="Robert Button"
      />
      <Input
        id="preferredName"
        type="text"
        label="What's your preferred name?"
        placeholder="Robbie"
      />
    </RegistrationLayout>
  )
}
