import React, { useState } from 'react'
import DropToUploadInput from './DropToUploadInput'
import { action } from '@storybook/addon-actions'
import { Form, Field } from 'react-final-form'
import Button from '../Button/Button'

export default {
  title: 'Leevo UI/DropToUploadInput',
  component: DropToUploadInput,
  args: {
    label: 'Upload a file',
    className: 'm-4',
    onChange: () => {},
  },
}

export function VanillaInput(args) {
  const [selectedFile, setSelectedFile] = useState(null)

  return (
    <DropToUploadInput
      {...args}
      value={selectedFile}
      onChange={setSelectedFile}
    />
  )
}

export function finalFormInput(args) {
  return (
    <Form
      onSubmit={action('submitted')}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field name="uploadedFile" allowNull>
            {({ input }) => (
              <DropToUploadInput {...args} {...input} id="upload" />
            )}
          </Field>
          <div className="flex justify-left">
            <Button label="Submit" type="submit" />
          </div>
        </form>
      )}
    />
  )
}
