import React from 'react'
import { Helmet } from 'react-helmet'

export default function FavIcons() {
  const path = `/favicons/${process.env.NODE_ENV}`
  return (
    <Helmet>
      {/* generics */}
      <link rel="icon" href={`${path}/favicon32.png`} sizes="32x32" />
      <link rel="icon" href={`${path}/favicon57.png`} sizes="57x57" />
      <link rel="icon" href={`${path}/favicon76.png`} sizes="76x76" />
      <link rel="icon" href={`${path}/favicon96.png`} sizes="96x96" />
      <link rel="icon" href={`${path}/favicon128.png`} sizes="128x128" />
      <link rel="icon" href={`${path}/favicon192.png`} sizes="192x192" />
      <link rel="icon" href={`${path}/favicon228.png`} sizes="228x228" />

      {/* Android */}
      <link
        rel="shortcut icon"
        href={`${path}/favicon196.png`}
        sizes="196x196"
      />

      {/* // iOS */}
      <link
        rel="apple-touch-icon"
        href={`${path}/favicon120.png`}
        sizes="120x120"
      />
      <link
        rel="apple-touch-icon"
        href={`${path}/favicon152.png`}
        sizes="152x152"
      />
      <link
        rel="apple-touch-icon"
        href={`${path}/favicon180.png`}
        sizes="180x180"
      />

      {/* Windows IE 10--> */}
      <meta name="msapplication-TileColor" content="#805AD5" />
      <meta name="msapplication-TileImage" content={`${path}/favicon144.png`} />

      {/* <!— Windows 8.1 + IE11 and above —> */}
      <meta name="msapplication-config" content={`${path}/browserconfig.xml`} />
    </Helmet>
  )
}
