import { action } from '@storybook/addon-actions'

import React from 'react'
import Button from './Button'
import editIcon from '../../../images/edit-icon.svg'

export default {
  title: 'Leevo UI/Button',
  component: Button,
  argTypes: {
    type: {
      control: {
        type: 'select',
        options: Button.types,
      },
    },
    color: {
      control: {
        type: 'select',
        options: Button.colors,
      },
    },
    icon: {
      control: {
        type: 'select',
        options: ['none', editIcon],
      },
    },
  },
  args: {
    label: 'Save',
    disabled: Button.defaultProps.disabled,
    type: Button.defaultProps.type,
    color: Button.defaultProps.color,
    outline: Button.defaultProps.outline,
    light: Button.defaultProps.light,
    shadow: Button.defaultProps.shadow,
    loading: Button.defaultProps.loading,
    small: Button.defaultProps.small,
    icon: 'none',
  },
}

// FIXME: Migrate from knobs to Controls (87 files) and remove knobs addon
export function basic(args) {
  return (
    <div className="flex items-center justify-center m-5">
      <Button {...args} onClick={action('button-click')} />
    </div>
  )
}

export function pill(args) {
  return (
    <div className="flex items-center justify-center m-5">
      <Button {...args} onClick={action('button-click')} />
    </div>
  )
}
pill.args = {
  label: 'Add +',
  className: 'flex-shrink-0 px-3 rounded-full',
  small: true,
  outline: true,
}
