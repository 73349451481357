import React from 'react'
import Badge from './Badge'
import { shallow } from 'enzyme'

describe('Badge', () => {
  let wrapper
  beforeEach(() => {
    wrapper = shallow(<Badge state="neutral" label="A" />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
