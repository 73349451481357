import React from 'react'
import SelectInput from './SelectInput'
import { shallow, mount } from 'enzyme'

describe('SelectInput', () => {
  let wrapper

  test('renders', () => {
    wrapper = shallow(<SelectInput id="id" options={[]} />)
    expect(wrapper).toExist()
  })

  describe('id', () => {
    test('throws an error when not provided', () => {
      function renderWrapper() {
        shallow(<SelectInput options={[]} />)
      }
      expect(renderWrapper).toThrowError('`id` is marked as required')
    })

    test('renders on the select element', () => {
      wrapper = shallow(<SelectInput id="some-id" options={[]} />)
      expect(wrapper.find('select').props().id).toEqual('some-id')
    })
  })

  describe('label', () => {
    describe('when present', () => {
      test('renders a label with the label text', () => {
        wrapper = shallow(<SelectInput id="id" options={[]} label="foobar" />)
        expect(wrapper.find('label')).toHaveText('foobar')
      })
    })

    describe('when not present', () => {
      test('renders no label', () => {
        wrapper = shallow(<SelectInput id="id" options={[]} />)
        expect(wrapper.find('label')).not.toExist()
      })
    })
  })

  describe('helpText', () => {
    describe('when present', () => {
      test('renders the help text', () => {
        wrapper = shallow(
          <SelectInput id="id" options={[]} helpText="foobar" />
        )
        expect(wrapper).toIncludeText('foobar')
      })
    })
  })

  describe('placeholder', () => {
    describe('when present', () => {
      test('renders a default placeholder option', () => {
        wrapper = shallow(
          <SelectInput id="x" options={[]} placeholder="foobar" />
        )
        expect(wrapper.find('option').at(0)).toHaveText('foobar')
      })
    })

    describe('when not present', () => {
      test('renders no default placeholder option', () => {
        wrapper = shallow(<SelectInput id="id" options={[]} />)
        expect(wrapper.find('option').at(0)).not.toHaveText('foobar')
      })
    })
  })

  describe('className', () => {
    describe('when present', () => {
      test('applies to the topmost div', () => {
        wrapper = shallow(
          <SelectInput id="x" options={[]} className="foobar" />
        )
        expect(wrapper.find('div').at(0)).toHaveClassName('foobar')
      })
    })
  })

  describe('errorMessage', () => {
    describe('when present', () => {
      test('renders the error message', () => {
        wrapper = shallow(
          <SelectInput id="x" options={[]} errorMessage="foobar" />
        )
        expect(wrapper.find("[data-cy='x-input-error']").hostNodes()).toExist()
        expect(wrapper).toIncludeText('foobar')
      })
    })

    describe('when not present', () => {
      test('does not renders the error message markup', () => {
        wrapper = shallow(<SelectInput id="x" options={[]} />)
        expect(
          wrapper.find("[data-cy='x-input-error']").hostNodes()
        ).not.toExist()
      })
    })
  })

  describe('disabled', () => {
    describe('when present', () => {
      beforeEach(() => {
        wrapper = shallow(
          <SelectInput id="x" options={[]} errorMessage="foobar" disabled />
        )
      })
      test('disables the select', () => {
        expect(wrapper.find('select')).toBeDisabled()
      })

      test('changes the color of the select', () => {
        expect(wrapper.find('select')).toHaveClassName('bg-gray-100')
      })
    })
    describe('when not present', () => {
      test('does not disable the select', () => {
        wrapper = shallow(
          <SelectInput id="x" options={[]} errorMessage="foobar" />
        )
        expect(wrapper.find('select')).not.toBeDisabled()
      })
    })
  })

  describe('options', () => {
    let options
    describe('when not provided', () => {
      test('throws an error', () => {
        function renderWrapper() {
          shallow(<SelectInput id="x" />)
        }
        expect(renderWrapper).toThrowError('`options` is marked as required')
      })
    })

    describe('when options contain groups', () => {
      beforeEach(() => {
        options = [
          { label: 'Ungrouped X', value: 'x' },
          { label: 'Ungrouped Y', value: 'y' },
          { label: 'Level 1a', value: '1a', group: 'Level 1' },
          { label: 'Level 1b', value: '1b', group: 'Level 1' },
          { label: 'Level 1c', value: '1c', group: 'Level 1' },
          { label: 'Level 2a', value: '2a', group: 'Level 2' },
          { label: 'Level 2b', value: '2b', group: 'Level 2' },
          { label: 'Level 2c', value: '2c', group: 'Level 2' },
        ]
        wrapper = mount(<SelectInput id="id" options={options} />)
      })

      test('renders ungrouped items without optgroup', () => {
        expect(wrapper).toContainMatchingElements(2, 'select > option')
        expect(wrapper.find('select > option').at(0)).toHaveText('Ungrouped X')
        expect(wrapper.find('select > option').at(1)).toHaveText('Ungrouped Y')
      })

      test('renders optgroups for each group of options', () => {
        expect(wrapper.find('optgroup').length).toBe(2)
      })

      test('renders labels for group of options', () => {
        const firstOptionGroup = wrapper.find('optgroup').at(0)
        const secondOptionGroup = wrapper.find('optgroup').at(1)
        expect(firstOptionGroup.props().label).toBe('Level 1')
        expect(secondOptionGroup.props().label).toBe('Level 2')
      })

      test('renders both groups of items', () => {
        const firstOptionGroup = wrapper.find('optgroup').at(0)
        expect(firstOptionGroup.children().length).toBe(3)
        expect(firstOptionGroup.children().at(0)).toHaveText('Level 1a')
        expect(firstOptionGroup.children().at(1)).toHaveText('Level 1b')
        expect(firstOptionGroup.children().at(2)).toHaveText('Level 1c')

        const secondOptionGroup = wrapper.find('optgroup').at(1)
        expect(secondOptionGroup.children().length).toBe(3)
        expect(secondOptionGroup.children().at(0)).toHaveText('Level 2a')
        expect(secondOptionGroup.children().at(1)).toHaveText('Level 2b')
        expect(secondOptionGroup.children().at(2)).toHaveText('Level 2c')
      })
    })

    describe('when options do not contain groups', () => {
      beforeEach(() => {
        options = [
          { label: 'Ungrouped X', value: 'x' },
          { label: 'Ungrouped Y', value: 'y' },
          { label: 'Level 1a', value: '1a' },
          { label: 'Level 1b', value: '1b' },
          { label: 'Level 1c', value: '1c' },
          { label: 'Level 2a', value: '2a' },
          { label: 'Level 2b', value: '2b' },
          { label: 'Level 2c', value: '2c' },
        ]
        wrapper = mount(<SelectInput id="id" options={options} />)
      })
      test('renders all items without optgroup', () => {
        expect(wrapper.find('optgroup')).not.toExist()
        expect(wrapper.find('select > option').length).toBe(8)
      })
    })
  })

  describe('rest', () => {
    test('passes rest properties to select', () => {
      wrapper = shallow(<SelectInput id="id" options={[]} foo="bar" />)
      expect(wrapper.find('select').props().foo).toBe('bar')
    })
  })
})
