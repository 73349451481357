import React, { useState } from 'react'
import { text } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'
import htmlElements from 'he'

import BirthdatePicker from './BirthdatePicker'

export default {
  title: 'Forms/BirthdatePicker',
  component: BirthdatePicker,
}

export function BirthdatePickerStory() {
  const [selectedDate, setSelectedDate] = useState(null)
  return (
    <BirthdatePicker
      id="birthdate-picker-id"
      className="m-6"
      label={htmlElements.decode(text('Label', "What's your birthdate?"))}
      errorMessage={htmlElements.decode(text('Error Message', ''))}
      onChange={(value) => {
        action('Selected Date')(value)
        setSelectedDate(value)
      }}
      value={selectedDate}
    />
  )
}
