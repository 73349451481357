import React from 'react'
import { text, select } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'
import moment from 'moment'

import MultiSelectDayDateTime from './MultiSelectDayDateTime'

export default {
  title: 'Leevo UI/Klass Edit Form/MultiSelectDayDateTime',
  component: MultiSelectDayDateTime,
}

export function multiSelectDayDateTime() {
  const first = moment().subtract(39, 'd')
  return (
    <MultiSelectDayDateTime
      className="m-6"
      value={[
        { date: first, isTimeSet: true },
        { date: first.clone().add(1, 'w'), isTimeSet: true },
        { date: first.clone().add(2, 'w'), isTimeSet: true },
      ]}
      error={{
        message: text('Error message', ''),
        dates: select('Error Times', { None: [], First: [first] }, null),
      }}
      onChange={action('Changed')}
    />
  )
}
