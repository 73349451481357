import React from 'react'
import LoginModal from './LoginModal'
import { mount } from 'enzyme'
import Modal from '../Modal/Modal'
import MockAdapter from 'axios-mock-adapter'
import axios from 'axios'
import { act } from 'react-dom/test-utils'
import { createMemoryHistory } from 'history'
import { Router, Route } from 'react-router-dom'
import MockAuthProvider from '../../../providers/MockAuthProvider'

describe('LoginModal', () => {
  let wrapper, signInUser
  const mockHistory = createMemoryHistory()
  const mockAxios = new MockAdapter(axios)

  beforeEach(() => {
    signInUser = () => axios.post('/auth/sign_in')
    mockHistory.push = jest.fn()
    wrapper = mount(
      <MockAuthProvider signInUser={signInUser}>
        <Router history={mockHistory}>
          <Route>
            <LoginModal />
          </Route>
        </Router>
      </MockAuthProvider>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('shows Modal', () => {
    expect(wrapper.find(Modal).length).toEqual(1)
  })

  test('shows form in Modal', () => {
    expect(wrapper.find('form').length).toEqual(1)
  })

  test('login form has two Fields', () => {
    expect(wrapper.find('input').length).toEqual(2)
  })

  describe('adding email and password into fields', () => {
    beforeEach(() => {
      const email = wrapper.find('input[name="email"]')
      const password = wrapper.find('input[name="password"]')

      email.simulate('change', { target: { value: 'email@email.com' } })
      password.simulate('change', { target: { value: 'password' } })
    })

    test('submit button enabled', () => {
      expect(wrapper.find('button').prop('disabled')).toBe(false)
    })

    describe('with unsuccessful submit', () => {
      beforeEach(() => {
        mockAxios.onPost('/auth/sign_in').reply(401, {
          errors: ['Invalid credentials'],
        })
      })

      test('fails with error from server', async () => {
        await act(async () => wrapper.find('form').simulate('submit'))
        wrapper.update()
        expect(wrapper.find('.text-red-600')).toIncludeText(
          'Invalid credentials'
        )
      })
    })
  })
})
