import React from 'react'

import FacilitiesInactive from './FacilitiesInactive'
import MockAuthProvider from '../../providers/MockAuthProvider'
import MockFacilityProvider from '../../providers/MockFacilityProvider'

export default {
  title: 'Leevo UI/FacilitiesInactive',
  component: FacilitiesInactive,
  args: {
    isAdmin: true,
    hasBillingContact: true,
  },
}

export function facilitiesInactive({ isAdmin, hasBillingContact }) {
  return (
    <MockAuthProvider
      currentUser={{
        facilitiesUsers: [{ id: '1', admin: isAdmin, facility: { id: '1' } }],
      }}
    >
      <MockFacilityProvider
        facility={{
          id: '1',
          billingContact: hasBillingContact
            ? {
                fullName: 'Foobar Barbaz',
                preferredName: 'foo',
                email: 'foo@bar.com',
              }
            : undefined,
        }}
      >
        <FacilitiesInactive />
      </MockFacilityProvider>
    </MockAuthProvider>
  )
}
