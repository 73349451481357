import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import LargeRadioInput from '../../leevo_ui/LargeRadioInput/LargeRadioInput'
import { presence } from '../../../utilities/validators'

const options = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]

// FIXME: As of 2020-08-13 this component is no longer used.
//        Shall we remove? Or does it look like we plan to use it in the future?
function AddAnotherStudent({ className }) {
  return (
    <Field name="addAnotherStudent" validate={presence}>
      {({ input, meta: { active, error, submitFailed } }) => (
        <LargeRadioInput
          {...input}
          className={className}
          id="addAnotherStudent"
          options={options}
          label="Did you want to add another student?"
          errorMessage={!active && error && submitFailed ? error : ''}
        />
      )}
    </Field>
  )
}

AddAnotherStudent.propTypes = {
  className: PropTypes.string,
}

export default AddAnotherStudent
