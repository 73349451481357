import React from 'react'
import { text } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'
import htmlElements from 'he'
import { Form, Field } from 'react-final-form'

import Button from '../../leevo_ui/Button/Button'
import LargeRadioInput from './LargeRadioInput'

export default {
  title: 'Forms/LargeRadioInput',
  component: LargeRadioInput,
}

export function largeRadioInput() {
  const options = [
    { label: 'Female', value: 'female' },
    { label: 'Male', value: 'male' },
    { label: 'Non-binary', value: 'non-binary' },
    { label: 'Unspecified', value: 'unspecified' },
  ]
  return (
    <div className="max-w-md m-10">
      <Form
        onSubmit={action('Submitted form')}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field name="some-field">
                {({ input }) => (
                  <LargeRadioInput
                    className="m-6"
                    id="foo"
                    label={htmlElements.decode(
                      text('Label', 'What is your gender?')
                    )}
                    helpText={htmlElements.decode(
                      text(
                        'Help Text',
                        'To be enrolled in a Learn to Skate program requires that we register you with LTS USA. They require this data to help with competition & event registration.'
                      )
                    )}
                    errorMessage={htmlElements.decode(
                      text('Error Message', '')
                    )}
                    options={options}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>

              <div className="flex justify-left">
                <Button label="Submit" />
              </div>
            </form>
          )
        }}
      />
    </div>
  )
}
