import React from 'react'
import { MemoryRouter, Route } from 'react-router-dom'
import { mount } from 'enzyme'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { act } from 'react-dom/test-utils'

import GuardiansStudentsIndex from './GuardiansStudentsIndex'
import LoadingSpinner from '../leevo_ui/LoadingSpinner/LoadingSpinner'
import SkillLegend from '../leevo_ui/SkillLegend/SkillLegend'
import StudentCard from './StudentCard/StudentCard'
import MockAuthProvider from '../../providers/MockAuthProvider'

describe('GuardiansStudentsIndex', () => {
  let wrapper

  const mockStudentsResponse = {
    data: [
      {
        id: '2',
        type: 'student',
        attributes: {
          fullName: 'Jane Doe',
          preferredName: 'Janey',
          email: 'user2@example.com',
          cloudinaryPhotoPublicId: null,
          gender: 'unspecified',
          birthdate: null,
          ltsNumber: null,
        },
        relationships: {
          studentsSkills: { data: [{ id: '1', type: 'studentsSkill' }] },
          klassDaysStudents: { data: [] },
          currentSkillLevel: { data: { id: '1', type: 'skillLevel' } },
        },
      },
      {
        id: '3',
        type: 'student',
        attributes: {
          fullName: 'John Doe',
          preferredName: 'Johnny',
          email: 'user3@example.com',
          cloudinaryPhotoPublicId: null,
          gender: 'unspecified',
          birthdate: null,
          ltsNumber: null,
        },
        relationships: {
          studentsSkills: { data: [{ id: '1', type: 'studentsSkill' }] },
          klassDaysStudents: { data: [] },
          currentSkillLevel: { data: { id: '1', type: 'skillLevel' } },
        },
      },
    ],
    included: [
      {
        id: '1',
        type: 'skillLevel',
        attributes: { name: 'Basic 1' },
        relationships: {
          nextSkillLevel: { data: null },
          skills: { data: [{ id: '1', type: 'skill' }] },
        },
      },
      {
        id: '1',
        type: 'studentsSkill',
        attributes: {
          name: 'Stand up on the ice',
          shortLabel: 'A',
          status: 'unintroduced',
          ordinal: 1,
        },
        relationships: { skill: { data: { id: '1', type: 'skill' } } },
        links: { self: 'http://localhost:3000/api/students_skills/1' },
      },
    ],
  }

  beforeEach(async () => {
    const mockAxios = new MockAdapter(axios)

    mockAxios.reset()
    mockAxios.onGet().reply(200, mockStudentsResponse)

    await act(async () => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/guardians/1/students']}>
          <MockAuthProvider>
            <Route
              path="/guardians/:guardian_id/students"
              component={GuardiansStudentsIndex}
            />
          </MockAuthProvider>
        </MemoryRouter>
      )
    })
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders loading page before data loads', () => {
    expect(wrapper.find(LoadingSpinner)).toExist()
  })

  test('renders a list header with legend', () => {
    expect(wrapper.find(SkillLegend)).toExist()
  })

  describe('after data loads', () => {
    beforeEach(async () => {
      wrapper.update()
    })

    test('renders the student cards for students', () => {
      expect(wrapper.find(StudentCard)).toHaveLength(2)
    })
  })
})
