import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { MemoryRouter, Route } from 'react-router-dom'

import StudentsEditFeature from './StudentsEditFeature'

export default {
  title: 'StudentsEditFeature',
  component: StudentsEditFeature,
}

const mockSkillLevelResponse = {
  data: [
    {
      id: '1',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 1',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '2',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 2',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '3',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 3',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '4',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 4',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '5',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '5',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 5',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '6',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '6',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 6',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '11',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '7',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 1',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '8',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 2',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '9',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '9',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 3',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '10',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '10',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 4',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '11',
      type: 'skillLevel',
      attributes: {
        name: 'Pre-Free Skate',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '12',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '12',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 1',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '13',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '13',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 2',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '14',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '14',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 3',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '15',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '15',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 4',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '16',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '16',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 5',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '17',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '17',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 6',
      },
      relationships: {
        nextSkillLevel: {
          data: null,
        },
      },
    },
  ],
}

const mockStudentResponse = {
  data: {
    id: '670',
    type: 'user',
    attributes: {
      fullName: 'Aaaa Aaab',
      preferredName: 'Aaa',
      cloudinaryPhotoPublicId: null,
      email: null,
      gender: 'female',
      phone: null,
      birthdate: '2010-12-27T07:00:00.000Z',
      ltsNumber: '2412551',
      'invitedToSignUp?': false,
      'invitationAccepted?': false,
    },
    relationships: {
      facilities: { data: [{ id: '6', type: 'facility' }] },
      facilitiesUsers: { data: [{ id: '738', type: 'facilitiesUser' }] },
      students: { data: [] },
      klassDays: { data: [] },
      guardian: { data: { id: '268', type: 'user' } },
      address: { data: { id: '85', type: 'address' } },
      currentSkillLevel: { data: { id: '2', type: 'skillLevel' } },
    },
  },
  included: [
    {
      id: '85',
      type: 'address',
      attributes: {
        line1: '5468 Plumstead Dr',
        line2: '',
        city: 'Colorado Springs',
        state: 'CO',
        postCode: '80920',
      },
    },
    {
      id: '268',
      type: 'user',
      attributes: {
        fullName: 'Chris Salvato',
        preferredName: 'Chris',
        cloudinaryPhotoPublicId: null,
        email: 'csalvato@gmail.com',
        gender: 'unspecified',
        phone: '+13478038847',
        birthdate: null,
        ltsNumber: null,
        'invitedToSignUp?': false,
        'invitationAccepted?': true,
      },
      relationships: {
        facilities: {
          data: [
            { id: '2', type: 'facility' },
            { id: '3', type: 'facility' },
            { id: '6', type: 'facility' },
            { id: '7', type: 'facility' },
            { id: '8', type: 'facility' },
          ],
        },
        facilitiesUsers: {
          data: [
            { id: '706', type: 'facilitiesUser' },
            { id: '252', type: 'facilitiesUser' },
            { id: '466', type: 'facilitiesUser' },
            { id: '609', type: 'facilitiesUser' },
            { id: '616', type: 'facilitiesUser' },
          ],
        },
        students: {
          data: [
            { id: '269', type: 'student' },
            { id: '443', type: 'student' },
            { id: '524', type: 'student' },
            { id: '670', type: 'student' },
            { id: '671', type: 'student' },
          ],
        },
        klassDays: { data: [] },
        guardian: { data: null },
        address: { data: null },
        currentSkillLevel: { data: null },
      },
    },
  ],
}

export function withSuccessfulRequest() {
  const mockAxios = new MockAdapter(axios)

  mockAxios.reset()
  mockAxios.onGet(/users/).reply(200, mockStudentResponse)
  mockAxios.onGet(/\/skill_levels/).reply(200, mockSkillLevelResponse)
  mockAxios.onPut().reply(200)

  return (
    <MemoryRouter initialEntries={['/students/1']}>
      <Route path="/students/:student_id">
        <StudentsEditFeature />
      </Route>
    </MemoryRouter>
  )
}
