import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'
import classNames from 'classnames'

import Transition from '../Transitions/Transition/Transition'

import caret from '../../../images/caret-down.svg'

function Drawer({ children, titleText, emptyState }) {
  const [open, setOpen] = useState(false)

  return (
    <Transition
      show={open}
      unmountOnExit={false}
      mountOnEnter={false}
      enter="ease-out duration-500"
      enterFrom="max-h-16 transition-max-h"
      enterTo="max-h-96 overflow-y-scroll"
      leave="ease-out duration-300"
      leaveFrom="max-h-96 overflow-y-scroll"
      leaveTo="max-h-16 transition-max-h"
      onClick={() => setOpen(!open)}
      onExit={(node) => (node.scrollTop = 0)}
      className="fixed bottom-0 left-0 flex flex-col items-start w-full pt-2 pl-3 overflow-hidden cursor-pointer shadow-top origin-bottom max-h-16"
    >
      <div className="flex pt-3 pb-3">
        <div className="flex items-center">
          {/* FIXME: Fix header https://github.com/leevo/leevo/pull/480#discussion_r426217133 */}
          <Transition
            show={open}
            unmountOnExit={false}
            mountOnEnter={false}
            enter="ease-out duration-300"
            enterFrom="-rotate-90"
            enterTo="rotate-0"
            leave="ease-in duration-200"
            leaveFrom="rotate-0"
            leaveTo="-rotate-90"
            className="transform transition-transform -rotate-90"
          >
            <SVG
              className="w-4 h-4 m-2 fill-current stroke-current"
              src={caret}
            />
          </Transition>

          <h2 className="text-lg font-semibold text-gray-800">{`${titleText}`}</h2>
          <span
            className={`${classNames({
              'bg-purple-600': children.length > 0,
              'bg-gray-500': children.length === 0,
            })} p-1 rounded-full w-6 text-sm ml-2 h-6 leading-tight text-center font-bold text-white`}
          >
            {children.length}
          </span>
        </div>
      </div>
      <div className="pl-8 mb-3">
        {children.length === 0 ? emptyState : children}
      </div>
    </Transition>
  )
}

Drawer.defaultProps = {
  children: [],
}

Drawer.propTypes = {
  children: PropTypes.node,
  emptyState: PropTypes.node.isRequired,
  titleText: PropTypes.string.isRequired,
}
export default Drawer
