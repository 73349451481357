import React from 'react'
import { number } from '@storybook/addon-knobs'

import Drawer from './Drawer'

export default {
  title: 'Leevo UI/Drawer',
  component: Drawer,
}

export function DrawerComponent() {
  const itemCount = number('Number of Additional Items', 2)

  function generateItems() {
    const items = []
    for (let i = 0; i < itemCount; i++) {
      items.push({ id: i })
    }
    return items
  }

  const emptyState = <div>I am empty</div>

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen mt-10">
      <Drawer titleText="Your Classes" emptyState={emptyState}>
        {generateItems().map(({ id }) => (
          <div key={id}>{id}</div>
        ))}
      </Drawer>
    </div>
  )
}
