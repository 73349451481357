import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import mixpanel from '../../utilities/mixpanel'

import useAuth from '../../utilities/hooks/useAuth'

function LogoutFeature() {
  const { signOutUser } = useAuth()

  useEffect(() => {
    mixpanel.track('Signed Out')
    signOutUser()
  })

  return <Redirect to="/" />
}

export default LogoutFeature
