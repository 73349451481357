import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { createForm } from 'final-form'
import classNames from 'classnames'
import { debounce } from 'lodash'

import useFacility from '../../../utilities/hooks/useFacility'
import CheckButton from '../../leevo_ui/CheckButton/CheckButton'
import Notification from '../../leevo_ui/Notification/Notification'
import { requestCreator } from '../../../utilities/requests'

const { post: newDiscount, cancel: cancelDiscountsRequests } = requestCreator()

function MultiKlassDiscount({ amount, reductionMethod, active }) {
  const discountInputEl = useRef()
  const { facility } = useFacility()
  const [isSaved, setIsSaved] = useState(false)
  const [hasAutoSaveError, setHasAutoSaveError] = useState(false)
  const onSubmit = debounce((values) => {
    let url = `/api/facilities/${facility.id}/multi_klass_discounts`
    return newDiscount(url, {
      multi_klass_discount: { discount_attributes: values },
    })
      .then(() => setIsSaved(true))
      .catch(() => setHasAutoSaveError(true))
  }, 300)

  const formApi = createForm({
    initialValues: { amount, reductionMethod, active },
    onSubmit,
  })

  function validateAmount(amount) {
    const { reductionMethod } = formApi.getState().values

    if (!amount) return 'This is required.'
    if (amount <= 0) return 'Must be positive'
    if (amount > 100 && reductionMethod === 'percentage')
      return 'Must be less than 100%'
  }

  function toggleEnable(input) {
    if (input.checked) {
      discountInputEl.current.disabled = true
      discountInputEl.current.blur()
    } else {
      discountInputEl.current.disabled = false
      discountInputEl.current.focus()
    }
    input.onChange(!input.checked)
  }

  useEffect(() => cancelDiscountsRequests)

  return (
    <Form
      form={formApi}
      render={({ handleSubmit, values }) => (
        <div>
          <div className="flex items-center space-x-2">
            <Field name="active" type="checkbox">
              {({ input }) => (
                <>
                  <CheckButton
                    type="checkbox"
                    size={4}
                    {...input}
                    onClick={() => {
                      toggleEnable(input)
                      handleSubmit(values)
                    }}
                  />
                  <span
                    className="font-medium cursor-pointer"
                    onClick={() => {
                      toggleEnable(input)
                      handleSubmit(values)
                    }}
                  >
                    Multi-class discount
                  </span>
                </>
              )}
            </Field>
          </div>
          <p className="ml-6 text-sm">
            Discount class fees when one student is registered in multiple
            classes.
          </p>
          <div className="flex items-center justify-between mt-3">
            {/* <Field name="reductionMethod">
              {({ input }) => (
                <SelectInput
                  {...input}
                  id="discount-type"
                  className="w-32 ml-6"
                  options={[
                    { label: 'Percentage', value: 'percentage' },
                    { label: 'Cash Value', value: 'fixed' },
                  ]}
                  disabled={!values.active}
                />
              )}
            </Field> */}
            <div>
              <Notification
                show={isSaved}
                color="green"
                className="mr-3"
                disappearIn={3000} // 3 sec
                onDisappearTimeout={() => setIsSaved(false)}
              >
                Saved!
              </Notification>
            </div>
            <Field name="amount" validate={validateAmount}>
              {({ input, meta: { active, error, touched } }) => (
                <div>
                  <div
                    className={classNames(
                      'relative text-sm rounded-md shadow-sm',
                      { 'cursor-not-allowed': !values.active }
                    )}
                  >
                    <div
                      className={classNames(
                        'absolute inset-y-0 left-0 flex items-center pl-3',
                        { hidden: values.reductionMethod === 'percentage' }
                      )}
                    >
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>

                    <input
                      {...input}
                      ref={discountInputEl}
                      type="number"
                      min={1}
                      max={values.reductionMethod === 'percentage' ? 100 : null}
                      name="multi-student-discount"
                      className={classNames(
                        'block w-44 rounded-md focus:ring-purple-300 focus:ring-opacity-50 focus:border-purple-500 sm:text-sm transition duration-200 ease-linear spin-button-none pr-28',
                        {
                          'bg-gray-100 text-gray-100': !values.active,
                          'text-gray-700': values.active,
                          'pl-7': values.reductionMethod !== 'percentage',
                          'pl-2 text-right':
                            values.reductionMethod === 'percentage',
                          'border-red-500':
                            values.active && !active && error && touched,
                          'border-gray-300':
                            !values.active || active || !error || !touched,
                          'pointer-events-none': !values.active,
                        }
                      )}
                      onChange={(e) => {
                        input.onChange(e.target.value)
                        handleSubmit(values)
                      }}
                    />

                    <div
                      className={classNames(
                        'absolute inset-y-0 right-0 flex items-center px-2 border rounded-r-md font-medium',
                        {
                          'border-red-500 bg-red-100 text-red-500':
                            values.active && !active && error && touched,
                          'border-gray-300 bg-gray-100 text-gray-500':
                            !values.active || active || !error || !touched,
                        }
                      )}
                    >
                      {values.reductionMethod === 'percentage' && '%'} / student
                    </div>
                  </div>
                  <div
                    className={classNames('text-sm text-right mt-1', {
                      invisible: !values.active || !error || !touched,
                      'text-red-500': error && touched,
                      'error-shake':
                        values.active && !active && error && touched,
                    })}
                  >
                    {error || 'Looks good!'}
                  </div>
                </div>
              )}
            </Field>
          </div>
          <Notification
            show={hasAutoSaveError}
            color="red"
            className="mr-3"
            disappearIn={30000} // 30 sec
          >
            Something went wrong...
          </Notification>
        </div>
      )}
    />
  )
}

MultiKlassDiscount.defaultProps = {
  amount: '',
  reductionMethod: 'percentage',
  active: false,
}

MultiKlassDiscount.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reductionMethod: PropTypes.oneOf(['percentage', 'fixed']),
  active: PropTypes.bool,
}

export default MultiKlassDiscount
