import React from 'react'

import Recommendations from './Recommendations'

const recommendationsList = [
  {
    id: '3',
    count: 4,
    skill: {
      id: '3',
      name: 'Forward two-foot slide',
      shortLabel: 'C',
    },
    skillLevel: {
      id: '1',
      name: 'Basic 1',
    },
  },
  {
    id: '4',
    count: 4,
    skill: {
      id: '4',
      name: 'Dip',
      shortLabel: 'D',
    },
    skillLevel: {
      id: '1',
      name: 'Basic 1',
    },
  },
  {
    id: '189',
    count: 1,
    skill: {
      id: '189',
      name: 'Sit and stand up with skates on – off-ice',
      shortLabel: 'A',
    },
    skillLevel: {
      id: '28',
      name: 'Snowplow Sam 1',
    },
  },
  {
    id: '190',
    count: 1,
    skill: {
      id: '190',
      name: 'Sit and stand up – on-ice',
      shortLabel: 'B',
    },
    skillLevel: {
      id: '28',
      name: 'Snowplow Sam 1',
    },
  },
]

const recommendationOptions = {
  Empty: [],
  Several: recommendationsList,
}

export default {
  title: 'Coach Feature/Components/Recommendations',
  component: Recommendations,
  args: {
    recommendations: [],
    isLoading: false,
  },
  argTypes: {
    recommendations: {
      control: { type: 'select', options: Object.keys(recommendationOptions) },
    },
  },
}

export function recommendations({ recommendations, ...args }) {
  const selectedRecommendations = recommendationOptions[recommendations]
  return (
    <div className="m-4">
      <Recommendations {...args} recommendations={selectedRecommendations} />
    </div>
  )
}
