import React from 'react'
import PricingFeature from './PricingFeature'

export default {
  title: 'Pricing Feature',
  component: PricingFeature,
}

export function pricingFeature() {
  return <PricingFeature />
}
