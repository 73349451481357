import React from 'react'
import { shallow } from 'enzyme'

import RightDrawer from './RightDrawer'

describe('RightDrawer', () => {
  let wrapper

  beforeEach(() => {
    wrapper = shallow(
      <RightDrawer>
        <p>test child</p>
      </RightDrawer>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
