import React from 'react'
import { mount } from 'enzyme'
import { createMemoryHistory } from 'history'
import { Router } from 'react-router-dom'

import RouteChangeNotification from './RouteChangeNotification'
import MockAuthProvider from '../../providers/MockAuthProvider'

const mockHistory = createMemoryHistory()
describe('RouteChangeNotification', () => {
  let wrapper
  beforeEach(() => {
    wrapper = mount(
      <Router history={mockHistory}>
        <MockAuthProvider>
          <RouteChangeNotification />
        </MockAuthProvider>
      </Router>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
