import React from 'react'
import ExposableContent from './ExposableContent'

export default {
  title: 'Leevo UI/ExposableContent',
  component: ExposableContent,
  args: {
    className: 'm-4',
    hiddenLabel: 'View content (optional)',
    exposedLabel: 'Hide content',
  },
}

export function exposableContent(args) {
  return (
    <ExposableContent {...args}>
      This is the content that should be exposed ❤️
    </ExposableContent>
  )
}
