import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import MarketingNavBar from '../../leevo_ui/NavBar/MarketingNavBar/MarketingNavBar'
import MarketingPageFooter from '../../leevo_ui/MarketingPageFooter/MarketingPageFooter'

function TermlyEmbed({ title, termlyDocumentId }) {
  useEffect(() => {
    let existingTermlyScript = document.getElementById('termly-jssdk')
    if (existingTermlyScript) removeElement(existingTermlyScript)

    createNewTermlyJsScript()
  }, [termlyDocumentId])

  function removeElement(el) {
    el.parentNode.removeChild(el)
  }

  function createNewTermlyJsScript() {
    const [topmostJsScript] = document.getElementsByTagName('script')
    const newTermlyScript = document.createElement('script')
    newTermlyScript.id = 'termly-jssdk'
    newTermlyScript.src = 'https://app.termly.io/embed-policy.min.js'
    topmostJsScript.parentNode.insertBefore(newTermlyScript, topmostJsScript)
  }

  return (
    <div className="text-gray-900 bg-gray-100">
      <Helmet>
        <title>{title} | Leevo</title>
      </Helmet>
      <header className="relative flex flex-col items-center h-auto">
        <MarketingNavBar
          links={[
            { label: 'Home', to: '/' },
            { label: 'About', to: '/about' },
            { label: 'Pricing', to: '/pricing' },
            { label: 'My Classes', to: '/login' },
            { label: 'Log In', to: '/login' },
          ]}
        />
      </header>
      <section className="relative flex flex-col items-center min-h-screen pb-10 text-gray-700">
        <div className="container max-w-3xl">
          <div
            name="termly-embed"
            data-id={termlyDocumentId}
            data-type="iframe"
          />
        </div>
      </section>
      <MarketingPageFooter />
    </div>
  )
}

TermlyEmbed.defaultProps = {}

TermlyEmbed.propTypes = {
  title: PropTypes.string.isRequired,
  termlyDocumentId: PropTypes.string.isRequired,
}

export default TermlyEmbed
