import React from 'react'
import { mount } from 'enzyme'
import { MemoryRouter, Route } from 'react-router-dom'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { act } from 'react-dom/test-utils'
import flushPromises from 'flush-promises'

import FacilitiesCoachesIndex from './FacilitiesCoachesIndex'
import MockFacilityProvider from '../../providers/MockFacilityProvider'

describe('FacilitiesCoachesIndex', () => {
  let wrapper
  const mockAxios = new MockAdapter(axios)

  beforeEach(() => {
    mockAxios.reset()
    mockAxios.onGet('/api/facilities/1/coaches').reply(200, {
      data: [
        {
          id: '1',
          type: 'coach',
          attributes: {
            fullName: 'Active Coach',
            preferredName: 'Active',
            email: 'active@coach.com',
            cloudinaryPhotoPublicId: null,
            'invitedToSignUp?': false,
            'invitationAccepted?': false,
          },
          relationships: {
            facilities: {
              data: [{ id: '2', type: 'facility' }],
            },
            klassDays: {
              data: [],
            },
          },
        },
        {
          id: '2',
          type: 'coach',
          attributes: {
            fullName: 'Pending Coach',
            preferredName: 'Pending',
            email: 'pending@coach.com',
            cloudinaryPhotoPublicId: null,
            'invitedToSignUp?': true,
            'invitationAccepted?': false,
          },
          relationships: {
            facilities: {
              data: [
                {
                  id: '2',
                  type: 'facility',
                  attributes: { isSubscriptionActive: true },
                },
              ],
            },
            klassDays: {
              data: [],
            },
          },
        },
      ],
    })

    act(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/facilities/1/classes']}>
          <Route path="/facilities/:facility_id/classes">
            <MockFacilityProvider>
              <FacilitiesCoachesIndex />
            </MockFacilityProvider>
          </Route>
        </MemoryRouter>
      )
    })
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  describe('with all data loaded', function () {
    beforeEach(async () => {
      await flushPromises()
      act(() => wrapper.update())
      await flushPromises()
      act(() => wrapper.update())
    })

    it('renders active coaches before pending coaches', () => {
      expect(wrapper.find('li').at(0)).toIncludeText('Active Coach')
      expect(wrapper.find('li').at(1)).toIncludeText('pending@coach.com')
    })

    // FIXME: Couldn't get the functions to call that actually invite users.
    // Didn't have the time to debug and hack at it to get it working
    // eslint-disable-next-line
    describe.skip('successfully adding coaches', () => {
      beforeEach(async () => {
        mockAxios.reset()
        mockAxios.onPost('/auth/invitation').reply(201, {
          data: [
            {
              id: '3',
              email: 'test@example.com',
            },
          ],
        })

        act(async () => {
          wrapper.find("[data-cy='add-button']").hostNodes().simulate('click')
          wrapper
            .find('.react-multi-email input')
            .simulate('change', 'test@example.com')
          wrapper.find("[data-cy='submit']").hostNodes().simulate('click')
          await flushPromises()
          wrapper.update()
        })
      })

      it('adds the invited user to the list of pending users', () => {
        expect(wrapper.find('li').at(2)).toIncludeText('test@example.com')
      })
      it.todo('sends an invitation by hitting the invitations endpoint')
      it.todo('closes the modal')
      it.todo('shows a success message')
    })

    // FIXME: Couldn't get the functions to call that actually invite users.
    // Didn't have the time to debug and hack at it to get it working
    // eslint-disable-next-line
    describe.skip('unsuccessfully adding coaches', () => {
      it.todo('does not close the modal')
      it.todo('renders an error message')
    })
  })
})
