import React from 'react'
import { shallow, mount } from 'enzyme'
import { Form } from 'react-final-form'

import NameFields from './NameFields'

describe('NameFields', () => {
  let wrapper

  test('renders', () => {
    wrapper = shallow(<NameFields />)
    expect(wrapper).toExist()
  })

  describe('fullNameLabel', () => {
    test("renders 'What's your full name?' by default", () => {
      wrapper = mount(
        <Form onSubmit={jest.fn()} render={() => <NameFields />} />
      )
      expect(wrapper).toIncludeText("What's your full name?")
    })

    test('renders the provided value', () => {
      wrapper = mount(
        <Form
          onSubmit={jest.fn()}
          render={() => <NameFields fullNameLabel="some label" />}
        />
      )
      expect(wrapper).toIncludeText('some label')
    })
  })

  describe('preferredNameLabel', () => {
    test("renders 'What's your preferred name?' by default", () => {
      wrapper = mount(
        <Form onSubmit={jest.fn()} render={() => <NameFields />} />
      )
      expect(wrapper).toIncludeText('What do you prefer to be called?')
    })

    test('renders the provided value', () => {
      wrapper = mount(
        <Form
          onSubmit={jest.fn()}
          render={() => <NameFields preferredNameLabel="some label" />}
        />
      )
      expect(wrapper).toIncludeText('some label')
    })
  })
})
