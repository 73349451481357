import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get as _get } from 'lodash'
import { Form, Field } from 'react-final-form'

import { isEmail } from '../../../utilities/validators'

import Modal from '../Modal/Modal'
import Button from '../Button/Button'
import Input from '../Input/Input'
import Notification from '../Notification/Notification'
import useAuth from '../../../utilities/hooks/useAuth'

function passwordRequired(value) {
  return value ? undefined : 'Please enter your password'
}

function composeValidators(...validators) {
  return (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    )
}

function LoginModal({ open, onClickBackground, notificationMessage }) {
  const { signInUser } = useAuth()
  const [loginError, setLoginError] = useState('')

  return (
    <Modal open={open} header="Login" onClickBackground={onClickBackground}>
      <Form
        onSubmit={(values) => {
          const { email, password } = values
          return signInUser({ email, password }).catch((error) => {
            const errorMsg = _get(
              error,
              'response.data.errors[0]',
              'Something went wrong. Please try again.'
            )
            setLoginError(errorMsg)
          })
        }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              {notificationMessage && (
                <Notification show color="green" className="px-4 mt-3 mb-8">
                  {notificationMessage}
                </Notification>
              )}
              {loginError && <div className="text-red-600">{loginError}</div>}
              <Field name="email" validate={composeValidators(isEmail)}>
                {({ input, meta: { active, error, submitFailed } }) => (
                  <Input
                    {...input}
                    id="username"
                    className="mb-4"
                    autoFocus
                    type="text"
                    label="Your email"
                    placeholder="Email"
                    disabled={submitting}
                    errorMessage={!active && error && submitFailed ? error : ''}
                  />
                )}
              </Field>
              <Field name="password" validate={passwordRequired}>
                {({ input, meta: { active, error, submitFailed } }) => {
                  return (
                    <Input
                      {...input}
                      className="mb-6"
                      id="password"
                      type="password"
                      label="Your password"
                      placeholder="Password"
                      disabled={submitting}
                      errorMessage={
                        !active && error && submitFailed ? error : ''
                      }
                    />
                  )
                }}
              </Field>
              <div className="flex items-center justify-between">
                <a
                  data-cy="forgot-password"
                  href="/forgot_password"
                  className="ml-1 text-sm font-medium text-purple-600 align-baseline hover:text-purple-400"
                >
                  Forgot password?
                </a>
                <Button
                  type="submit"
                  data-cy="submit"
                  label="Submit"
                  disabled={submitting}
                  loading={submitting}
                />
              </div>
            </form>
          )
        }}
      />
    </Modal>
  )
}

LoginModal.defaultProps = {
  open: true,
}

LoginModal.propTypes = {
  onClickBackground: PropTypes.func,
  notificationMessage: PropTypes.string,
  open: PropTypes.bool,
}

export default LoginModal
