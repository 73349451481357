import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import Input from '../../../leevo_ui/Input/Input'
import ExpandDownTransition from '../../../leevo_ui/Transitions/ExpandDownTransition/ExpandDownTransition'
import { isEmail } from '../../../../utilities/validators'

function LoginFields({
  onForgotPasswordClick,
  onEmailChange,
  onPasswordChange,
  hasAccount,
  isEmailValid,
}) {
  return (
    <>
      <Field name="email" validate={isEmail}>
        {({ input, meta: { active, error, submitFailed } }) => (
          <Input
            {...input}
            id="email"
            type="text"
            label="What's your email address?"
            autoComplete="username"
            placeholder="Your email"
            onKeyPress={(e) => {
              if (parseInt(e.which) === 13 && isEmailValid && hasAccount)
                e.preventDefault()
            }}
            onChange={(event) => {
              input.onChange(event)
              onEmailChange(event.target.value)
            }}
            errorMessage={!active && error && submitFailed ? error : ''}
          />
        )}
      </Field>
      {/* FIXME: Improve styling here. This notification appearing can likely be done a lot better */}
      <ExpandDownTransition show={hasAccount && isEmailValid}>
        <div className="m-4 font-thin text-center">
          <p>You already have an account with us! 🎉</p>
          <p>Enter your password to sign in and register.</p>
        </div>
        <Field name="password">
          {({ input }) => {
            return (
              <Input
                {...input}
                id="password"
                className="w-full"
                autoComplete="current-password"
                type="password"
                label="Password"
                onChange={(event) => {
                  input.onChange(event)
                  onPasswordChange(event.target.value)
                }}
                placeholder="••••••••"
              />
            )
          }}
        </Field>
        <a
          onClick={onForgotPasswordClick}
          className="text-sm font-medium text-purple-600 align-baseline hover:text-purple-400"
        >
          Forgot password?
        </a>
      </ExpandDownTransition>
      <ExpandDownTransition show={!hasAccount && isEmailValid}>
        <div className="font-thin text-center">
          <p>👋 It looks like you&apos;re new here.</p>
          <p className="mt-4">
            Just answer 3 basic questions and we can make an account for you.
          </p>
          <p className="mt-4">
            If you think you have an account already, check the email address
            for typos. 🙈
          </p>
        </div>
      </ExpandDownTransition>
    </>
  )
}

LoginFields.defaultProps = {
  hasAccount: false,
  isEmailValid: false,
  handlePasswordChange: () => {},
}

LoginFields.propTypes = {
  onForgotPasswordClick: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func,
  onEmailChange: PropTypes.func.isRequired,
  hasAccount: PropTypes.bool,
  isEmailValid: PropTypes.bool,
}

export default LoginFields
