import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import classNames from 'classnames'
import { orderBy } from 'lodash'

import CardFormSection from '../../leevo_ui/CardFormSection/CardFormSection'
import CurrencyInput from '../CurrencyInput/CurrencyInput'
import CheckButton from '../CheckButton/CheckButton'
import Avatar from '../Avatar/Avatar'
import CheckboxInput from '../CheckboxInput/CheckboxInput'
// FIXME: Move this to the leevo_ui directory
import MultiSelectStudent from '../KlassForm/MultiSelectStudent/MultiSelectStudent'
import Input from '../Input/Input'
import TextArea from '../TextArea/TextArea'

function KlassPackageForm({
  onSubmit,
  price,
  klasses,
  title,
  description,
  open,
  students,
  facilityId,
}) {
  return (
    <Form
      initialValues={{
        price,
        klasses: klasses
          .filter((klass) => klass.selected)
          .map((klass) => klass.id),
        title,
        description,
        open,
        students: orderBy(students, ['selected', 'fullName'], ['desc']),
      }}
      onSubmit={onSubmit}
      validate={({ price, _klasses, title }) => {
        let errors = {}
        // FIXME: We don't want to enforce this if the only thing that's changing is
        // the active state, but we don't have a way to know that here without more work.
        // if (!klasses || klasses.length < 1) {
        //   errors.klasses = 'A package must have at least one class selected.'
        // }

        if (!price) errors.price = 'A package must have a price set.'
        if (!title) errors.title = 'A package must have a title.'
        if (Object.keys(errors).length === 0) errors = null

        return errors
      }}
      render={({ handleSubmit, submitting }) => (
        <div className="flex justify-center p-5 bg-gray-100">
          <form className="max-w-4xl" onSubmit={handleSubmit}>
            <CardFormSection
              title="Menu Info"
              description="This information is displayed to parents and guardians when they are picking items from your menu of classes."
              submitting={submitting}
            >
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <Field name="title">
                    {({ input, meta: { error, submitFailed } }) => (
                      <Input
                        {...input}
                        id="title"
                        type="text"
                        label="Title"
                        helpText="This is displayed in large bold print on the menu."
                        placeholder="Tuesdays - 5:00 PM"
                        errorMessage={submitFailed && error ? error : ''}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <Field name="description">
                    {({ input, meta: { error, submitFailed } }) => (
                      <TextArea
                        {...input}
                        id="description"
                        maxLength={200}
                        label="Description"
                        helpText="This is displayed below the title on the menu."
                        placeholder="Classes run between Jan 1 and March 15, starting at 8 PM and run for 30 minutes."
                        errorMessage={submitFailed && error ? error : ''}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <Field name="price">
                    {({ input, meta: { error, submitFailed } }) => (
                      <div>
                        <CurrencyInput
                          {...input}
                          id="price"
                          label="Price"
                          helpText="This is the base price of the package. On the menu, this price is pro-rated based on how many classes are left."
                          placeholder="$120.00"
                          errorMessage={submitFailed && error ? error : ''}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <Field name="open" type="checkbox">
                    {({ input }) => (
                      <CheckboxInput
                        {...input}
                        id="open"
                        label="Active on menu?"
                        onClick={() => input.onChange(!input.checked)}
                        data-cy="open-checkbox-input"
                      />
                    )}
                  </Field>
                </div>
              </div>
            </CardFormSection>

            <CardFormSection
              title="Classes"
              description={
                <section className="space-y-2">
                  <p>
                    When a student buys this package, we auto-place them in
                    these classes.
                  </p>
                  <p>
                    Leevo will only add a student to one class per day for their
                    level.
                  </p>
                  <p>
                    If no class supports their level, they will not be able to
                    see this package during registration.
                  </p>
                </section>
              }
              submitting={submitting}
            >
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <Field name="klasses">
                    {({ input, meta: { error, submitFailed } }) => (
                      <div>
                        <label className="block mb-1 ml-1 font-medium text-gray-800">
                          Select Classes
                        </label>
                        <div>
                          <div
                            className={`${classNames({
                              'border-gray-300': !submitFailed && error,
                              'text-red-400 border-red-500 ring ring-red-500 ring-opacity-50 error-shake':
                                submitFailed && error,
                            })} h-64 mb-2 overflow-scroll border rounded-md shadow-inner focus:ring focus:border-purple-500 focus:ring-purple-300 focus:ring-opacity-50`}
                          >
                            {klasses.filter(
                              (klass) => klass.remainingKlassDays > 0
                            ).length === 0 && (
                              <div className="flex flex-col justify-center m-12 font-light text-center">
                                <p>{"You don't have any upcoming classes."}</p>
                                <p data-cy="create-class-link">
                                  {/* FIXME: We should probably create our own Link component
                                that wraps this one, and includes the necessary
                                default styles, then replace all existing a tags
                                with that Link component. */}
                                  <Link
                                    className="font-normal text-purple-600 hover:text-purple-400"
                                    to={`/facilities/${facilityId}/classes/new`}
                                  >
                                    Create some classes
                                  </Link>{' '}
                                  with dates in the future to see them appear in
                                  this list.
                                </p>
                              </div>
                            )}
                            <ul className="m-0">
                              {klasses
                                .filter((klass) => klass.remainingKlassDays > 0)
                                .map((klass) => (
                                  <li
                                    key={klass.id}
                                    className="flex items-start w-full px-4 py-2 border-b border-gray-300 cursor-pointer"
                                    onClick={() => {
                                      const valueAsSet = new Set(input.value)
                                      if (valueAsSet.has(klass.id)) {
                                        valueAsSet.delete(klass.id)
                                      } else {
                                        valueAsSet.add(klass.id)
                                      }
                                      input.onChange(Array.from(valueAsSet))
                                    }}
                                  >
                                    <div className="w-1/12">
                                      <CheckButton
                                        className="mt-1 mr-4"
                                        ariaProps={{
                                          'aria-pressed': new Set(
                                            input.value
                                          ).has(klass.id),
                                          'aria-label': 'select date',
                                        }}
                                        checked={new Set(input.value).has(
                                          klass.id
                                        )}
                                        data-cy={`klass-${klass.id}-check-button`}
                                        size={5}
                                      />
                                    </div>
                                    <div className="w-11/12">
                                      <header className="flex items-center justify-between">
                                        <span className="inline-block text-sm font-medium xs:text-base">
                                          {klass.dayOfWeek} - {klass.timeOfDay}
                                        </span>
                                        <span className="inline-block text-xs font-medium xs:text-sm">
                                          {klass.startDate} to {klass.endDate}
                                        </span>
                                      </header>
                                      <div className="flex justify-between">
                                        <div className="w-full">
                                          <div className="flex flex-wrap items-center mt-1">
                                            {klass.coaches.map((coach) => (
                                              <div
                                                key={coach.id}
                                                className="flex items-center mr-2 last:mr-0"
                                              >
                                                <Avatar
                                                  fullName={coach.fullName}
                                                  cloudinaryPhotoPublicId={
                                                    coach.cloudinaryPhotoPublicId
                                                  }
                                                  size={5}
                                                  userId={coach.id}
                                                />
                                                <span className="ml-1 text-xs font-medium text-gray-600">
                                                  {coach.preferredName ||
                                                    coach.email}
                                                </span>
                                              </div>
                                            ))}
                                          </div>
                                          <span className="inline-block w-full mt-1 text-xs font-medium text-gray-600 truncate">
                                            {klass.supportedSkillLevels
                                              .map(
                                                (skillLevel) => skillLevel.name
                                              )
                                              .join(', ')}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                        {submitFailed && error && (
                          <p className="mb-3 text-xs italic text-red-500">
                            {submitFailed && error ? error : ''}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
              </div>
            </CardFormSection>

            <CardFormSection
              title="Students"
              description={
                <section className="space-y-2">
                  <p>
                    Students are automatically added to a package when
                    registering.
                  </p>
                  <p>Add a student to enroll them in all selected classes.</p>
                  <p>
                    Remove a student to un-enroll them from all selected
                    classes.
                  </p>
                </section>
              }
              submitting={submitting}
            >
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <Field name="students">
                    {({ input, meta: { data } }) => {
                      return (
                        <MultiSelectStudent
                          {...input}
                          onChange={input.onChange}
                          loading={data.loading}
                        />
                      )
                    }}
                  </Field>
                </div>
              </div>
            </CardFormSection>
          </form>
        </div>
      )}
    />
  )
}

KlassPackageForm.defaultProps = {
  klasses: [],
  students: [],
  open: true,
}

KlassPackageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  price: PropTypes.number,
  klasses: PropTypes.array,
  title: PropTypes.string,
  students: PropTypes.array,
  description: PropTypes.string,
  open: PropTypes.bool,
  facilityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}

export default KlassPackageForm
