import React from 'react'
import { mount } from 'enzyme'
import { BrowserRouter } from 'react-router-dom'

import SlideInRoute from './SlideInRoute'

describe('SlideInRoute', () => {
  let wrapper, child

  beforeEach(() => {
    child = <div>I am a child.</div>
    wrapper = mount(
      <BrowserRouter>
        <SlideInRoute render={() => child} />
      </BrowserRouter>
    )
  })

  describe('when show is true', () => {
    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('renders children', () => {
      expect(wrapper).toHaveText('I am a child.')
    })
  })
})
