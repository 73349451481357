import React from 'react'
import { number, text } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'
import moment from 'moment'
import htmlEntities from 'he'

import KlassDaysList from './KlassDaysList'

function coachClasses(numCoaches) {
  const generatedCoachClasses = []
  for (let i = 0; i < numCoaches; i++) {
    generatedCoachClasses.push({
      id: i + 1,
      coaches: [
        {
          id: i + 1,
          preferredName: htmlEntities.decode(text('Preferred Name', 'Emmy')),
          fullName: htmlEntities.decode(text('Full Name', 'Emmy Pellerin')),
          cloudinaryPhotoPublicId: 'qrz0yfenrunmvcb0epwa',
        },
      ],
      startDatetime: moment('2019-12-28T19:43:25-07:00'),
      students: [{ currentSkillLevel: { name: 'Basic 1' } }],
      skillLevels: ['Basic 1'],
    })
  }
  return generatedCoachClasses
}

export default {
  title: 'Facilities Calendar/Components/KlassDaysList',
  component: KlassDaysList,
}

export function klassDaysList() {
  return (
    <KlassDaysList
      onSelectClass={action('selected class')}
      coachClasses={coachClasses(number('Number of Classes', 5))}
    />
  )
}
