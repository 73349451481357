import React from 'react'
import { shallow } from 'enzyme'
import { BrowserRouter } from 'react-router-dom'

import AddStudents from './AddStudents'

// FIXME: Add specs for this file.  Did not write immediately, since E2E tests
// handle the most important cases for this file.
describe('AddStudents', () => {
  let wrapper

  test('renders', () => {
    wrapper = shallow(
      <BrowserRouter>
        <AddStudents nextStepUrl="foo" onStudentAdded={jest.fn()} />
      </BrowserRouter>
    )
    expect(wrapper).toExist()
  })
})
