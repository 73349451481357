import React from 'react'
import PropTypes from 'prop-types'
import htmlElements from 'he'

import ConfirmationModal from '../../leevo_ui/ConfirmationModal/ConfirmationModal'

function PromotionModal({
  open,
  studentName,
  originalLevel,
  newLevel,
  onCancel,
  onConfirm,
  pending,
}) {
  function singleLineHtmlFor(string) {
    return htmlElements.decode(string.split(' ').join('&nbsp'))
  }
  return (
    <ConfirmationModal
      open={open}
      header="Are you sure?"
      onClose={onCancel}
      onNoClick={onCancel}
      onYesClick={onConfirm}
      pending={pending}
    >
      <p>
        You are about to promote{' '}
        <span className="font-medium">{singleLineHtmlFor(studentName)}</span>{' '}
        from{' '}
        <span className="font-medium">{singleLineHtmlFor(originalLevel)}</span>{' '}
        to <span className="font-medium">{singleLineHtmlFor(newLevel)}</span>.
      </p>
      <p className="mt-3">Are you sure you want to do this?</p>
    </ConfirmationModal>
  )
}

PromotionModal.defaultProps = {}

PromotionModal.propTypes = {
  open: PropTypes.bool,
  studentName: PropTypes.string.isRequired,
  originalLevel: PropTypes.string.isRequired,
  newLevel: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  pending: PropTypes.bool,
}

export default PromotionModal
