import React from 'react'
import { action } from '@storybook/addon-actions'
import { boolean } from '@storybook/addon-knobs'
import { Form } from 'react-final-form'

import AddAnotherStudent from './AddAnotherStudent'

export default {
  title: 'Registration Feature/AddAnotherStudent',
  component: AddAnotherStudent,
}

export function addAnotherStudent() {
  return (
    <Form
      onSubmit={action('Submit')}
      render={() => (
        <AddAnotherStudent
          className={boolean('With margin?', false) ? 'm-6' : ''}
        />
      )}
    />
  )
}
