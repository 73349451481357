import React from 'react'
import { action } from '@storybook/addon-actions'

import KlassPackageForm from './KlassPackageForm'

export default {
  title: 'Leevo UI/Klass Packages Form',
  component: KlassPackageForm,
}

export function emptyWithoutKlasses() {
  return (
    <KlassPackageForm
      klasses={[]}
      facilityId={1}
      onSubmit={action('Submitted Form')}
    />
  )
}

export function emptyWithKlasses() {
  return (
    <KlassPackageForm
      facilityId={1}
      klasses={[
        {
          id: 1,
          dayOfWeek: 'Mon',
          endDate: 'Mar 9',
          maxStudents: 10,
          price: 5000,
          priceInDollars: '$50.00',
          remainingKlassDays: 20,
          spotsLeft: 10,
          startDate: 'Mar 9',
          timeOfDay: '4:00 AM',
          supportedSkillLevels: [
            { id: 1, name: 'Basic 1' },
            { id: 2, name: 'Basic 2' },
            { id: 3, name: 'Basic 3' },
          ],
          coaches: [
            {
              id: 1,
              email: 'user1@example.com',
              fullName: 'Jane Doe',
              cloudinaryPhotoPublicId: null,
              preferredName: 'Janey',
            },
            {
              id: 2,
              email: 'user2@example.com',
              fullName: 'Emily Johnson',
              cloudinaryPhotoPublicId: null,
              preferredName: 'Emmy',
            },
          ],
        },
        {
          id: 2,
          dayOfWeek: 'Mon',
          endDate: 'Mar 9',
          maxStudents: 10,
          price: 5000,
          priceInDollars: '$50.00',
          remainingKlassDays: 20,
          spotsLeft: 10,
          startDate: 'Mar 9',
          timeOfDay: '4:00 AM',
          supportedSkillLevels: [
            { id: 1, name: 'Basic 1' },
            { id: 2, name: 'Basic 2' },
            { id: 3, name: 'Basic 3' },
          ],
          coaches: [
            {
              id: 1,
              email: 'user1@example.com',
              fullName: 'Jane Doe',
              cloudinaryPhotoPublicId: null,
              preferredName: 'Janey',
            },
            {
              id: 2,
              email: 'user2@example.com',
              fullName: 'Emily Johnson',
              cloudinaryPhotoPublicId: null,
              preferredName: 'Emmy',
            },
          ],
        },
        {
          id: 3,
          dayOfWeek: 'Mon',
          endDate: 'Mar 9',
          maxStudents: 10,
          price: 5000,
          priceInDollars: '$50.00',
          remainingKlassDays: 20,
          spotsLeft: 10,
          startDate: 'Mar 9',
          timeOfDay: '4:00 AM',
          supportedSkillLevels: [
            { id: 1, name: 'Basic 1' },
            { id: 2, name: 'Basic 2' },
            { id: 3, name: 'Basic 3' },
          ],
          coaches: [
            {
              id: 1,
              email: 'user1@example.com',
              fullName: 'Jane Doe',
              cloudinaryPhotoPublicId: null,
              preferredName: 'Janey',
            },
            {
              id: 2,
              email: 'user2@example.com',
              fullName: 'Emily Johnson',
              cloudinaryPhotoPublicId: null,
              preferredName: 'Emmy',
            },
          ],
        },
      ]}
      onSubmit={action('Submitted Form')}
    />
  )
}

export function prePopulated() {
  return (
    <KlassPackageForm
      onSubmit={action('Submitted Form')}
      price={5000}
      klasses={[
        {
          id: 1,
          selected: true,
          dayOfWeek: 'Mon',
          endDate: 'Mar 9',
          maxStudents: 10,
          price: 5000,
          priceInDollars: '$50.00',
          remainingKlassDays: 20,
          spotsLeft: 10,
          startDate: 'Mar 9',
          timeOfDay: '4:00 AM',
          supportedSkillLevels: [
            { id: 1, name: 'Basic 1' },
            { id: 2, name: 'Basic 2' },
            { id: 3, name: 'Basic 3' },
          ],
          coaches: [
            {
              id: 1,
              email: 'user1@example.com',
              fullName: 'Jane Doe',
              cloudinaryPhotoPublicId: null,
              preferredName: 'Janey',
            },
            {
              id: 2,
              email: 'user2@example.com',
              fullName: 'Emily Johnson',
              cloudinaryPhotoPublicId: null,
              preferredName: 'Emmy',
            },
          ],
        },
        {
          id: 2,
          dayOfWeek: 'Mon',
          endDate: 'Mar 9',
          maxStudents: 10,
          price: 5000,
          priceInDollars: '$50.00',
          remainingKlassDays: 20,
          spotsLeft: 10,
          startDate: 'Mar 9',
          timeOfDay: '4:00 AM',
          supportedSkillLevels: [
            { id: 1, name: 'Basic 1' },
            { id: 2, name: 'Basic 2' },
            { id: 3, name: 'Basic 3' },
          ],
          coaches: [
            {
              id: 1,
              email: 'user1@example.com',
              fullName: 'Jane Doe',
              cloudinaryPhotoPublicId: null,
              preferredName: 'Janey',
            },
            {
              id: 2,
              email: 'user2@example.com',
              fullName: 'Emily Johnson',
              cloudinaryPhotoPublicId: null,
              preferredName: 'Emmy',
            },
          ],
        },
        {
          id: 3,
          dayOfWeek: 'Mon',
          endDate: 'Mar 9',
          maxStudents: 10,
          price: 5000,
          priceInDollars: '$50.00',
          remainingKlassDays: 20,
          spotsLeft: 10,
          startDate: 'Mar 9',
          timeOfDay: '4:00 AM',
          supportedSkillLevels: [
            { id: 1, name: 'Basic 1' },
            { id: 2, name: 'Basic 2' },
            { id: 3, name: 'Basic 3' },
          ],
          coaches: [
            {
              id: 1,
              email: 'user1@example.com',
              fullName: 'Jane Doe',
              cloudinaryPhotoPublicId: null,
              preferredName: 'Janey',
            },
            {
              id: 2,
              email: 'user2@example.com',
              fullName: 'Emily Johnson',
              cloudinaryPhotoPublicId: null,
              preferredName: 'Emmy',
            },
          ],
        },
      ]}
      title="Foo"
      description="Lorem ipsum dolor sit amir"
      open={true}
      facilityId={1}
      students={[
        {
          type: 'student',
          id: '524',
          fullName: 'Aaisha Andersen',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '524',
          selected: false,
        },
        {
          type: 'student',
          id: '639',
          fullName: 'Abby Riley',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '639',
          selected: false,
        },
        {
          type: 'student',
          id: '139',
          fullName: 'Abby Rogers',
          currentSkillLevel: { id: '4', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '139',
          selected: true,
        },
        {
          type: 'student',
          id: '641',
          fullName: 'Addison Wageman',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '641',
          selected: false,
        },
        {
          type: 'student',
          id: '444',
          fullName: 'Adleigh Bunkelman',
          currentSkillLevel: { id: '8', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '444',
          selected: true,
        },
        {
          type: 'student',
          id: '497',
          fullName: 'Aiden Smoot',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '497',
          selected: false,
        },
        {
          type: 'student',
          id: '345',
          fullName: 'Aksel Bailly',
          currentSkillLevel: { id: '9', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '345',
          selected: false,
        },
        {
          type: 'student',
          id: '318',
          fullName: 'Aliana Rider',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '318',
          selected: false,
        },
        {
          type: 'student',
          id: '502',
          fullName: 'Alyvia Ogle',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '502',
          selected: false,
        },
        {
          type: 'student',
          id: '654',
          fullName: 'Anastasia Whitlatch',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '654',
          selected: false,
        },
        {
          type: 'student',
          id: '495',
          fullName: 'Andrew Perkins',
          currentSkillLevel: { id: '8', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '495',
          selected: false,
        },
        {
          type: 'student',
          id: '451',
          fullName: 'Anja Newcombe',
          currentSkillLevel: { id: '12', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '451',
          selected: false,
        },
        {
          type: 'student',
          id: '146',
          fullName: 'Anna Sainsbury',
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '146',
          selected: false,
        },
        {
          type: 'student',
          id: '445',
          fullName: 'Asher Bunkelman',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '445',
          selected: true,
        },
        {
          type: 'student',
          id: '467',
          fullName: 'Athena Kapphahn',
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '467',
          selected: false,
        },
        {
          type: 'student',
          id: '483',
          fullName: 'Ava Starkey',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '483',
          selected: false,
        },
        {
          type: 'student',
          id: '436',
          fullName: 'Avery Berger',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '436',
          selected: false,
        },
        {
          type: 'student',
          id: '479',
          fullName: 'Avy Murphy',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '479',
          selected: false,
        },
        {
          type: 'student',
          id: '429',
          fullName: 'Bailey Simpkins',
          currentSkillLevel: { id: '8', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '429',
          selected: true,
        },
        {
          type: 'student',
          id: '142',
          fullName: 'Bea Vieth',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '142',
          selected: false,
        },
        {
          type: 'student',
          id: '447',
          fullName: 'Beatrice',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '447',
          selected: true,
        },
        {
          type: 'student',
          id: '141',
          fullName: 'Becca Mclachlan',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '141',
          selected: false,
        },
        {
          type: 'student',
          id: '544',
          fullName: 'Blakelyn larue',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '544',
          selected: false,
        },
        {
          type: 'student',
          id: '339',
          fullName: 'Brady Lang',
          currentSkillLevel: { id: '9', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '339',
          selected: false,
        },
        {
          type: 'student',
          id: '311',
          fullName: 'Brixton Turrell',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '311',
          selected: true,
        },
        {
          type: 'student',
          id: '516',
          fullName: 'Brock Bauman',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '516',
          selected: true,
        },
        {
          type: 'student',
          id: '334',
          fullName: 'Brooklyn Mitchell',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '334',
          selected: true,
        },
        {
          type: 'student',
          id: '402',
          fullName: 'Brylee Ries',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '402',
          selected: false,
        },
        {
          type: 'student',
          id: '461',
          fullName: 'Brynn Busson',
          currentSkillLevel: { id: '9', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '461',
          selected: false,
        },
        {
          type: 'student',
          id: '488',
          fullName: 'Brynn Heiss',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '488',
          selected: false,
        },
        {
          type: 'student',
          id: '512',
          fullName: 'Bryson Tarpley',
          currentSkillLevel: { id: '24', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '512',
          selected: false,
        },
        {
          type: 'student',
          id: '144',
          fullName: 'Bryton Speelman',
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '144',
          selected: false,
        },
        {
          type: 'student',
          id: '405',
          fullName: 'Carlee Klippel',
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '405',
          selected: true,
        },
        {
          type: 'student',
          id: '297',
          fullName: 'Chloe Metzger',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '297',
          selected: false,
        },
        {
          type: 'student',
          id: '336',
          fullName: 'Christian Kane',
          currentSkillLevel: { id: '8', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '336',
          selected: false,
        },
        {
          type: 'student',
          id: '540',
          fullName: 'Christopher Burnett',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '540',
          selected: false,
        },
        {
          type: 'student',
          id: '645',
          fullName: 'Claire Hobart',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '645',
          selected: false,
        },
        {
          type: 'student',
          id: '407',
          fullName: 'Clarissa Steele',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '407',
          selected: false,
        },
        {
          type: 'student',
          id: '346',
          fullName: 'Cleopatra Mikuta',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '346',
          selected: true,
        },
        {
          type: 'student',
          id: '470',
          fullName: 'Cleopatra Mikuta',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '470',
          selected: false,
        },
        {
          type: 'student',
          id: '398',
          fullName: 'Colin Cameron',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '398',
          selected: false,
        },
        {
          type: 'student',
          id: '454',
          fullName: 'Dean Wiles',
          currentSkillLevel: { id: '18', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '454',
          selected: false,
        },
        {
          type: 'student',
          id: '716',
          fullName: 'Elise Carr',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '716',
          selected: false,
        },
        {
          type: 'student',
          id: '714',
          fullName: 'Elisee Carr',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '714',
          selected: false,
        },
        {
          type: 'student',
          id: '738',
          fullName: 'Elizabeth Crimmins',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '738',
          selected: false,
        },
        {
          type: 'student',
          id: '527',
          fullName: 'Elle Rubin',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '527',
          selected: false,
        },
        {
          type: 'student',
          id: '404',
          fullName: 'Ellie Klippel',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '404',
          selected: false,
        },
        {
          type: 'student',
          id: '320',
          fullName: 'Elsie Hewitt-Nord',
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '320',
          selected: false,
        },
        {
          type: 'student',
          id: '395',
          fullName: 'Elsie Vondran',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '395',
          selected: false,
        },
        {
          type: 'student',
          id: '305',
          fullName: 'Emilee Williams',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '305',
          selected: true,
        },
        {
          type: 'student',
          id: '501',
          fullName: 'Emilee Williams',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '501',
          selected: false,
        },
        {
          type: 'student',
          id: '726',
          fullName: 'Emma Beaty',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '726',
          selected: false,
        },
        {
          type: 'student',
          id: '646',
          fullName: 'Emree Hobart',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '646',
          selected: false,
        },
        {
          type: 'student',
          id: '535',
          fullName: 'Erik Hare',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '535',
          selected: false,
        },
        {
          type: 'student',
          id: '720',
          fullName: 'Ethan Beck',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '720',
          selected: false,
        },
        {
          type: 'student',
          id: '721',
          fullName: 'Ethan Beck',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '721',
          selected: false,
        },
        {
          type: 'student',
          id: '718',
          fullName: 'Evelyn Becker',
          currentSkillLevel: { id: '11', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '718',
          selected: false,
        },
        {
          type: 'student',
          id: '743',
          fullName: 'Ewan Walton',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '743',
          selected: false,
        },
        {
          type: 'student',
          id: '418',
          fullName: 'Galena Ngo',
          currentSkillLevel: { id: '5', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '418',
          selected: false,
        },
        {
          type: 'student',
          id: '327',
          fullName: 'Gemma Loose',
          currentSkillLevel: { id: '4', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '327',
          selected: false,
        },
        {
          type: 'student',
          id: '485',
          fullName: 'Genevieve Villelli',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '485',
          selected: false,
        },
        {
          type: 'student',
          id: '325',
          fullName: 'Gianna Grossglauser',
          currentSkillLevel: { id: '4', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '325',
          selected: false,
        },
        {
          type: 'student',
          id: '338',
          fullName: 'Giovanni Grossglauser',
          currentSkillLevel: { id: '8', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '338',
          selected: false,
        },
        {
          type: 'student',
          id: '505',
          fullName: 'Haider Touraille',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '505',
          selected: false,
        },
        {
          type: 'student',
          id: '315',
          fullName: 'Hanne Bailly',
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '315',
          selected: false,
        },
        {
          type: 'student',
          id: '464',
          fullName: 'Harper Combs',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '464',
          selected: true,
        },
        {
          type: 'student',
          id: '341',
          fullName: 'Harrison Hofman',
          currentSkillLevel: { id: '8', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '341',
          selected: false,
        },
        {
          type: 'student',
          id: '734',
          fullName: 'Hawke Warren',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '734',
          selected: false,
        },
        {
          type: 'student',
          id: '466',
          fullName: 'Henley Combs',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '466',
          selected: true,
        },
        {
          type: 'student',
          id: '449',
          fullName: 'Hunter Bergener',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '449',
          selected: false,
        },
        {
          type: 'student',
          id: '528',
          fullName: 'JaCee Hardy',
          currentSkillLevel: { id: '4', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '528',
          selected: false,
        },
        {
          type: 'student',
          id: '147',
          fullName: 'Jack Magnus',
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '147',
          selected: false,
        },
        {
          type: 'student',
          id: '432',
          fullName: 'Jackson Jones',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '432',
          selected: false,
        },
        {
          type: 'student',
          id: '316',
          fullName: 'Jane Gardunia',
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '316',
          selected: false,
        },
        {
          type: 'student',
          id: '487',
          fullName: 'Jane Gardunia',
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '487',
          selected: false,
        },
        {
          type: 'student',
          id: '507',
          fullName: 'Jasmine Touraille',
          currentSkillLevel: { id: '8', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '507',
          selected: true,
        },
        {
          type: 'student',
          id: '332',
          fullName: 'Jillian Speelman',
          currentSkillLevel: { id: '6', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '332',
          selected: false,
        },
        {
          type: 'student',
          id: '397',
          fullName: 'Juliette Cameron',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '397',
          selected: false,
        },
        {
          type: 'student',
          id: '699',
          fullName: 'Kai Golladay',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '699',
          selected: false,
        },
        {
          type: 'student',
          id: '496',
          fullName: 'Kallie Perkins',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '496',
          selected: false,
        },
        {
          type: 'student',
          id: '440',
          fullName: 'Katelyn Nichczynski',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '440',
          selected: true,
        },
        {
          type: 'student',
          id: '330',
          fullName: 'Katie Skon',
          currentSkillLevel: { id: '11', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '330',
          selected: false,
        },
        {
          type: 'student',
          id: '431',
          fullName: 'Kaydence Jones',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '431',
          selected: false,
        },
        {
          type: 'student',
          id: '546',
          fullName: 'Kelly Holland',
          currentSkillLevel: { id: '11', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '546',
          selected: false,
        },
        {
          type: 'student',
          id: '510',
          fullName: 'Kenley Starkey',
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '510',
          selected: false,
        },
        {
          type: 'student',
          id: '469',
          fullName: 'Kenna Kapphahn',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '469',
          selected: false,
        },
        {
          type: 'student',
          id: '520',
          fullName: 'Kennedy Bazzar',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '520',
          selected: false,
        },
        {
          type: 'student',
          id: '414',
          fullName: 'Kennedy Cole',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '414',
          selected: false,
        },
        {
          type: 'student',
          id: '499',
          fullName: 'Kensley Sommerfeldt',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '499',
          selected: false,
        },
        {
          type: 'student',
          id: '536',
          fullName: 'Khloe Candelario',
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '536',
          selected: true,
        },
        {
          type: 'student',
          id: '140',
          fullName: 'Kyndall Fouche',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '140',
          selected: true,
        },
        {
          type: 'student',
          id: '412',
          fullName: 'Kyndall Fouche',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '412',
          selected: false,
        },
        {
          type: 'student',
          id: '453',
          fullName: 'Landon Geurin',
          currentSkillLevel: { id: '25', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '453',
          selected: false,
        },
        {
          type: 'student',
          id: '149',
          fullName: 'Leah Madsen',
          currentSkillLevel: { id: '11', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '149',
          selected: false,
        },
        {
          type: 'student',
          id: '434',
          fullName: 'Levi Rogge',
          currentSkillLevel: { id: '8', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '434',
          selected: false,
        },
        {
          type: 'student',
          id: '299',
          fullName: 'Levi Simental',
          currentSkillLevel: { id: '9', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '299',
          selected: false,
        },
        {
          type: 'student',
          id: '303',
          fullName: 'Liam Greene',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '303',
          selected: false,
        },
        {
          type: 'student',
          id: '425',
          fullName: 'Lilly Yochum',
          currentSkillLevel: { id: '8', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '425',
          selected: true,
        },
        {
          type: 'student',
          id: '307',
          fullName: 'Lily-Marie Perreira',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '307',
          selected: false,
        },
        {
          type: 'student',
          id: '741',
          fullName: 'Lucy Ross',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '741',
          selected: false,
        },
        {
          type: 'student',
          id: '471',
          fullName: 'Lydia Perkins',
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '471',
          selected: false,
        },
        {
          type: 'student',
          id: '477',
          fullName: 'Mackenzie Montee',
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '477',
          selected: false,
        },
        {
          type: 'student',
          id: '475',
          fullName: 'Macy Minert',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '475',
          selected: false,
        },
        {
          type: 'student',
          id: '455',
          fullName: 'Maddie',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '455',
          selected: false,
        },
        {
          type: 'student',
          id: '301',
          fullName: 'Maddy Forkner',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '301',
          selected: false,
        },
        {
          type: 'student',
          id: '490',
          fullName: 'Madilyn Kelly',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '490',
          selected: false,
        },
        {
          type: 'student',
          id: '655',
          fullName: 'Madison Meyer',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '655',
          selected: false,
        },
        {
          type: 'student',
          id: '409',
          fullName: 'Marley Shea Gardy',
          currentSkillLevel: { id: '8', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '409',
          selected: true,
        },
        {
          type: 'student',
          id: '731',
          fullName: 'Matias Quijas',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '731',
          selected: false,
        },
        {
          type: 'student',
          id: '518',
          fullName: 'Matthew Jorden',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '518',
          selected: false,
        },
        {
          type: 'student',
          id: '457',
          fullName: 'Max Rubin',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '457',
          selected: false,
        },
        {
          type: 'student',
          id: '504',
          fullName: 'Maycee Potts',
          currentSkillLevel: { id: '9', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '504',
          selected: false,
        },
        {
          type: 'student',
          id: '148',
          fullName: "Meave O'Toole",
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '148',
          selected: false,
        },
        {
          type: 'student',
          id: '492',
          fullName: 'Mia Kelly',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '492',
          selected: false,
        },
        {
          type: 'student',
          id: '145',
          fullName: 'Molly Derr',
          currentSkillLevel: { id: '5', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '145',
          selected: false,
        },
        {
          type: 'student',
          id: '313',
          fullName: 'Monet Bailly',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '313',
          selected: true,
        },
        {
          type: 'student',
          id: '150',
          fullName: 'Mylee Nelson',
          currentSkillLevel: { id: '6', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '150',
          selected: false,
        },
        {
          type: 'student',
          id: '493',
          fullName: 'Nolan Moehle',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '493',
          selected: false,
        },
        {
          type: 'student',
          id: '697',
          fullName: 'Nora Hernández-Hedges',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '697',
          selected: false,
        },
        {
          type: 'student',
          id: '423',
          fullName: 'Nora Snyder',
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '423',
          selected: true,
        },
        {
          type: 'student',
          id: '348',
          fullName: 'Noxon Turrell',
          currentSkillLevel: { id: '8', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '348',
          selected: false,
        },
        {
          type: 'student',
          id: '428',
          fullName: 'Paige Simpkins',
          currentSkillLevel: { id: '8', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '428',
          selected: false,
        },
        {
          type: 'student',
          id: '458',
          fullName: 'Paisley Bardwell',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '458',
          selected: true,
        },
        {
          type: 'student',
          id: '427',
          fullName: 'Paityn Simpkins',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '427',
          selected: true,
        },
        {
          type: 'student',
          id: '531',
          fullName: 'Paxton kay',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '531',
          selected: false,
        },
        {
          type: 'student',
          id: '400',
          fullName: 'Penelope Rosendahl',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '400',
          selected: true,
        },
        {
          type: 'student',
          id: '437',
          fullName: 'Rachel Berger',
          currentSkillLevel: { id: '18', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '437',
          selected: false,
        },
        {
          type: 'student',
          id: '309',
          fullName: 'Ryley Snyder',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '309',
          selected: false,
        },
        {
          type: 'student',
          id: '711',
          fullName: 'Shelby Wilkerson',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '711',
          selected: false,
        },
        {
          type: 'student',
          id: '739',
          fullName: 'Sophia Crimmins',
          currentSkillLevel: { id: '18', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '739',
          selected: false,
        },
        {
          type: 'student',
          id: '729',
          fullName: 'Sophia Wise',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '729',
          selected: false,
        },
        {
          type: 'student',
          id: '508',
          fullName: 'Stettson Mercado',
          currentSkillLevel: { id: '8', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '508',
          selected: false,
        },
        {
          type: 'student',
          id: '343',
          fullName: 'Steve Cooley',
          currentSkillLevel: { id: '2', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '343',
          selected: true,
        },
        {
          type: 'student',
          id: '732',
          fullName: 'Thiago Quijas',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '732',
          selected: false,
        },
        {
          type: 'student',
          id: '537',
          fullName: 'Tia Candelario',
          currentSkillLevel: { id: '3', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '537',
          selected: true,
        },
        {
          type: 'student',
          id: '473',
          fullName: 'Trinity Pfitzer',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '473',
          selected: false,
        },
        {
          type: 'student',
          id: '514',
          fullName: 'Tyler Tarpley',
          currentSkillLevel: { id: '24', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '514',
          selected: false,
        },
        {
          type: 'student',
          id: '481',
          fullName: 'Tysen Potts',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '481',
          selected: true,
        },
        {
          type: 'student',
          id: '143',
          fullName: 'Vivian Guerrier',
          currentSkillLevel: { id: '4', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '143',
          selected: false,
        },
        {
          type: 'student',
          id: '460',
          fullName: 'Waylon Bardwell',
          currentSkillLevel: { id: '7', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '460',
          selected: false,
        },
        {
          type: 'student',
          id: '421',
          fullName: 'Wyatt Avery',
          currentSkillLevel: { id: '8', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '421',
          selected: false,
        },
        {
          type: 'student',
          id: '463',
          fullName: 'Zeke Busson',
          currentSkillLevel: { id: '1', type: 'skillLevel' },
          relationshipNames: ['currentSkillLevel'],
          value: '463',
          selected: true,
        },
      ]}
    />
  )
}
