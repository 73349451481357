import React from 'react'
import { shallow } from 'enzyme'

import RegistrationDashboardSection from './RegistrationDashboardSection'

describe('RegistrationDashboardSection', () => {
  let wrapper
  beforeEach(() => {
    wrapper = shallow(
      <RegistrationDashboardSection title="foo">
        I am a child.
      </RegistrationDashboardSection>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders children', () => {
    expect(wrapper).toIncludeText('I am a child')
  })

  test('has dark text when not disabled', () => {
    expect(wrapper).toHaveClassName('border-gray-600')
    expect(wrapper).toHaveClassName('text-gray-800')
  })

  test('grays out the text when disabled', () => {
    wrapper.setProps({ disabled: true })
    expect(wrapper).toHaveClassName('border-gray-400')
    expect(wrapper).toHaveClassName('text-gray-400')
  })

  describe('title', () => {
    test('is required', () => {
      function renderWrapper() {
        shallow(<RegistrationDashboardSection />)
      }
      expect(renderWrapper).toThrowError('`title` is marked as required')
    })

    test('renders', () => {
      expect(wrapper).toIncludeText('foo')
    })
  })

  describe('completed', () => {
    test('renders checkmark when not disabled', () => {
      wrapper.setProps({ completed: true })
      expect(wrapper.find('[data-cy="checkmark"]')).toExist()
    })

    test('renders no checkmark when disabled', () => {
      wrapper.setProps({ completed: true, disabled: true })
      expect(wrapper.find('[data-cy="checkmark"]')).not.toExist()
    })
  })

  describe('nextStepAction', () => {
    let mockOnClick
    beforeEach(() => {
      mockOnClick = jest.fn()
      wrapper.setProps({
        nextStepAction: { onClick: mockOnClick, label: 'next step label' },
      })
    })

    describe('when section is not disabled', () => {
      test('renders a button for the next step action', () => {
        expect(wrapper.find('[data-cy="next-action-button"]')).toExist()
      })

      test('renders the provided next action label in the button', () => {
        const button = wrapper.find('[data-cy="next-action-button"]')
        expect(button.props().label).toBe('next step label')
      })

      test('calls onClick when the next step button is clicked', () => {
        wrapper.find('[data-cy="next-action-button"]').simulate('click')
        expect(mockOnClick).toHaveBeenCalled()
      })
    })

    describe('when section is disabled', () => {
      beforeEach(() => {
        wrapper.setProps({ disabled: true })
      })

      test('it does not render a button for the next step action', () => {
        expect(wrapper.find('[data-cy="next-action-button"]')).not.toExist()
      })
    })
  })

  describe('headerAction', () => {
    let mockOnClick
    beforeEach(() => {
      mockOnClick = jest.fn()
      wrapper.setProps({
        headerAction: { onClick: mockOnClick, label: 'header action label' },
      })
    })

    describe('when section is not disabled', () => {
      test('renders a button for the header action', () => {
        expect(wrapper.find('[data-cy="header-action-button"]')).toExist()
      })

      test('renders the provided header action label in the button', () => {
        const button = wrapper.find('[data-cy="header-action-button"]')
        expect(button.props().label).toBe('header action label')
      })

      test('calls onClick when the header action button is clicked', () => {
        wrapper.find('[data-cy="header-action-button"]').simulate('click')
        expect(mockOnClick).toHaveBeenCalled()
      })
    })

    describe('when section is disabled', () => {
      beforeEach(() => {
        wrapper.setProps({ disabled: true })
      })

      test('it does not render a button for the next step action', () => {
        expect(wrapper.find('[data-cy="next-action-button"]')).not.toExist()
      })
    })
  })
})
