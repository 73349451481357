import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'

import Input from '../Input/Input'
import PhoneInput from '../PhoneInput/PhoneInput'
import CardFormSection from '../../leevo_ui/CardFormSection/CardFormSection'
import UploadButton from '../../leevo_ui/UploadButton/UploadButton'
import { requestCreator } from '../../../utilities/requests'
import {
  isPhoneNumber,
  presence,
  isEmail,
  isValidPassword,
} from '../../../utilities/validators'

const { put: updateUser, cancel: cancelUserRequests } = requestCreator()

function ProfileForm({
  onSubmit,
  id,
  fullName,
  preferredName,
  email,
  phone,
  cloudinaryPhotoPublicId,
}) {
  useEffect(() => cancelUserRequests)

  return (
    <Form
      mutators={{
        clearPasswordFields: (_args, state, utils) => {
          utils.changeValue(state, 'password', () => '')
          utils.changeValue(state, 'passwordConfirmation', () => '')
        },
      }}
      initialValues={{
        fullName,
        preferredName,
        email,
        phone,
        cloudinaryPhotoPublicId,
      }}
      validate={(values) => {
        const errors = {}
        if (values.passwordConfirmation !== values.password)
          errors.passwordConfirmation = 'Passwords do not match'
        return errors
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, form }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event)
            form.mutators.clearPasswordFields(event)
          }}
          className="p-4"
        >
          <CardFormSection
            title="Personal Info"
            description="Information about you that's shown to coaches and directors. Your contact info is used to send you notifications."
            submitting={submitting}
          >
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                <Field name="fullName" validate={presence}>
                  {({ input, meta: { active, error, submitFailed } }) => (
                    <Input
                      {...input}
                      id="full_name"
                      autoFocus
                      className="w-full mr-3"
                      type="text"
                      label="Your full name"
                      placeholder="Richard Button"
                      errorMessage={
                        !active && error && submitFailed ? error : ''
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                <Field name="preferredName" validate={presence}>
                  {({ input, meta: { active, error, submitFailed } }) => (
                    <Input
                      {...input}
                      id="preferred_name"
                      className="w-full mr-3"
                      type="text"
                      label="Your preferred name"
                      placeholder="Richard"
                      errorMessage={
                        !active && error && submitFailed ? error : ''
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                <Field name="email" validate={isEmail}>
                  {({ input, meta: { active, error, submitFailed } }) => {
                    return (
                      <Input
                        {...input}
                        id="email"
                        className="w-full"
                        type="text"
                        label="Your email"
                        placeholder="richard@example.com"
                        errorMessage={
                          !active && error && submitFailed ? error : ''
                        }
                      />
                    )
                  }}
                </Field>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                <Field name="phone" validate={isPhoneNumber}>
                  {({ input, meta: { active, error, submitFailed } }) => (
                    <PhoneInput
                      {...input}
                      id="phone"
                      label="Phone Number"
                      placeholder="Your phone number"
                      maxlength="14"
                      errorMessage={
                        !active && error && submitFailed ? error : ''
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
            <Field name="cloudinaryPhotoPublicId">
              {({ input }) => (
                <UploadButton
                  cloudinaryPhotoPublicId={input.value}
                  onPhotoUpload={(newPublicId) => {
                    updateUser(`/api/users/${id}`, {
                      user: { cloudinaryPhotoPublicId: newPublicId },
                    }).then(() => {
                      input.onChange(newPublicId)
                    })
                  }}
                />
              )}
            </Field>
          </CardFormSection>
          <CardFormSection
            title="Password"
            description="Your password logs you into Leevo. Your password must be 8 characters or longer."
            submitting={submitting}
          >
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                <Field
                  name="password"
                  validate={(password) => {
                    if (!password) return null
                    return isValidPassword(password)
                  }}
                >
                  {({ input, meta: { active, error, touched } }) => {
                    return (
                      <Input
                        {...input}
                        id="password"
                        className="w-full"
                        type="password"
                        label="New Password"
                        placeholder="••••••••"
                        errorMessage={!active && error && touched ? error : ''}
                      />
                    )
                  }}
                </Field>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                <Field name="passwordConfirmation">
                  {({ input, meta: { active, error, touched } }) => {
                    return (
                      <Input
                        {...input}
                        id="password_confirmation"
                        className="w-full"
                        type="password"
                        label="Confirm new password"
                        placeholder="••••••••"
                        errorMessage={!active && error && touched ? error : ''}
                      />
                    )
                  }}
                </Field>
              </div>
            </div>
          </CardFormSection>
        </form>
      )}
    />
  )
}

ProfileForm.propTypes = {
  fullName: PropTypes.string,
  preferredName: PropTypes.string,
  cloudinaryPhotoPublicId: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default ProfileForm
