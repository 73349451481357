import React from 'react'
import PropTypes from 'prop-types'

import SkillButton from '../../leevo_ui/SkillButton/SkillButton'

function SkillLevelList({ skillLevels, selectedCategory }) {
  function skillButtons(skills) {
    return skills
      .sort((a, b) => a.ordinal - b.ordinal)
      .filter((skill) => selectedCategory === skill.category)
      .map((skill) => (
        <div key={skill.shortLabel} className="flex items-center py-2">
          <SkillButton label={skill.shortLabel} />
          <div className="pl-2">{skill.name}</div>
        </div>
      ))
  }

  return skillLevels.map((skillLevel) => (
    <div key={skillLevel.id}>
      <h2 className="sticky top-0 p-5 border-b border-gray-300 text-gray-700 font-medium bg-gray-50 first:border-t-0 border-t">
        {skillLevel.name} {selectedCategory && ` - ${selectedCategory}`}
      </h2>
      <div
        className="flex flex-col justify-center p-3"
        style={{ breakInside: 'avoid' }}
      >
        {skillButtons(skillLevel.skills)}
      </div>
    </div>
  ))
}

SkillLevelList.defaultProps = {
  skillLevels: [],
}

SkillLevelList.propTypes = {
  skillLevels: PropTypes.array,
  selectedCategory: PropTypes.string,
}
export default SkillLevelList
