import React from 'react'
import PropTypes from 'prop-types'

import { AuthContext } from '../providers/AuthProvider'

export default function MockAuthProvider({
  isLoading,
  isSignedIn,
  currentUser,
  signInUser,
  registerUser,
  signOutUser,
  verifyCredentials,
  ...rest
}) {
  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isSignedIn,
        currentUser,
        signInUser,
        registerUser,
        signOutUser,
        verifyCredentials,
      }}
      {...rest}
    />
  )
}

MockAuthProvider.defaultProps = {
  isLoading: false,
  isSignedIn: false,
  currentUser: { facilitiesUsers: [] },
  signInUser: () => {},
  registerUser: () => {},
  signOutUser: () => {},
  verifyCredentials: () => {},
}

MockAuthProvider.propTypes = {
  isLoading: PropTypes.bool,
  isSignedIn: PropTypes.bool,
  currentUser: PropTypes.object,
  signInUser: PropTypes.func,
  registerUser: PropTypes.func,
  signOutUser: PropTypes.func,
  verifyCredentials: PropTypes.func,
}

export { MockAuthProvider }
