import React from 'react'
import { text, boolean } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'
import classNames from 'classnames'
import htmlElements from 'he'

import CheckboxInputCompact from './CheckboxInputCompact'

export default {
  title: 'Forms/CheckboxInputCompact',
  component: CheckboxInputCompact,
}

export function checkboxInputCompact() {
  const withMargins = boolean('With margins?', true)
  return (
    <div className={classNames({ 'm-10': withMargins }, 'flex justify-center')}>
      <CheckboxInputCompact
        id="foo"
        label={htmlElements.decode(text('Label', 'Female'))}
        value={text('Value', 'female')}
        onClick={action('Clicked button')}
        checked={boolean('Checked?', CheckboxInputCompact.defaultProps.checked)}
        small={boolean('Small?', CheckboxInputCompact.defaultProps.small)}
      />
    </div>
  )
}
