import React from 'react'
import { shallow } from 'enzyme'
import SVG from 'react-inlinesvg'

import Drawer from './Drawer'

describe('Drawer', () => {
  let wrapper

  beforeEach(() => {
    wrapper = shallow(
      <Drawer titleText="title" emptyState={<div>empty</div>}>
        <p>test child</p>
      </Drawer>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('render title while drawer is closed', () => {
    expect(wrapper.find('h2')).toHaveText('title')
  })
  test('renders title when drawer is open', () => {
    wrapper.simulate('click')
    expect(wrapper.find('h2')).toHaveText('title')
  })

  test('renders children in drawer', () => {
    expect(wrapper.find('p')).toExist()
    expect(wrapper.find('p')).toHaveText('test child')
  })

  test('renders SVG for toggle indication', () => {
    expect(wrapper.find(SVG)).toExist()
  })

  test('renders empty state if no children', () => {
    wrapper = shallow(
      <Drawer titleText="title" emptyState={<p>empty</p>}></Drawer>
    )

    expect(wrapper.find('p')).toHaveText('empty')
  })
})
