import React from 'react'
import HomeFeature from './HomeFeature'

export default {
  title: 'Home Feature',
  component: HomeFeature,
}

export function homeFeature() {
  return <HomeFeature />
}
