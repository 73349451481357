import React from 'react'
import PropTypes from 'prop-types'

import CheckButton from '../CheckButton/CheckButton'

function CheckboxInputCompact({
  label,
  id,
  onClick,
  checked,
  value,
  'data-cy': dataCy,
}) {
  return (
    <div className="flex mb-0 last:mb-1">
      <CheckButton
        data-cy={dataCy}
        className="mt-0.5"
        onClick={onClick}
        id={id}
        checked={checked}
        size={4}
        value={value}
      />
      <label className="ml-3 text-sm" htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

CheckboxInputCompact.defaultProps = {
  checked: false,
}

CheckboxInputCompact.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.node.isRequired,
  value: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  'data-cy': PropTypes.string,
}

export default CheckboxInputCompact
