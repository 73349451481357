import React, { useState } from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { action } from '@storybook/addon-actions'

import AddCoachModal from './AddCoachModal'
import Button from '../Button/Button'

import bgPhoto from '../../../images/sample/skating-wallpaper.jpg'

export default {
  title: 'Leevo UI/Modals/AddCoachModal',
  component: AddCoachModal,
}

export function AddCoachModalWithSuccess() {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onPost().reply(200, {
    data: [
      { id: 1, type: 'user', attributes: { email: 'foobar@example.com' } },
    ],
  })

  const [opened, setOpened] = useState(true)
  return (
    <>
      <img className="max-w-6xl" src={bgPhoto} />
      <div className="fixed w-full h-full" style={{ top: '45%', left: '45%' }}>
        <Button
          color="yellow"
          label="Open AddCoachModal"
          className="shadow-hover"
          onClick={() => setOpened(true)}
        />
      </div>
      <AddCoachModal
        open={opened}
        onClose={({ invitedUsers }) => {
          action('Invited Users')(invitedUsers)
          setOpened(false)
        }}
      />
    </>
  )
}

export function AddCoachModalWithFailure() {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onPost().reply(500)

  const [opened, setOpened] = useState(true)
  return (
    <>
      <img className="max-w-6xl" src={bgPhoto} />
      <div className="fixed w-full h-full" style={{ top: '45%', left: '45%' }}>
        <Button
          color="yellow"
          label="Open AddCoachModal"
          className="shadow-hover"
          onClick={() => setOpened(true)}
        />
      </div>
      <AddCoachModal
        open={opened}
        onClose={({ invitedUsers }) => {
          action('Invited Users')(invitedUsers)
          setOpened(false)
        }}
      />
    </>
  )
}
