import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { FacilityContext } from '../../providers/FacilityProvider'
import useAuth from './useAuth'

export default function useFacility(
  { isSubscriptionRequired } = { isSubscriptionRequired: true }
) {
  const context = useContext(FacilityContext)
  if (!context) {
    throw new Error('useFacility must be used within a FacilityProvider')
  }

  const {
    facility: { subscriptionStatus },
    isLoading,
  } = context
  const history = useHistory()
  const { currentUser } = useAuth()

  // FIXME: There should be a spec around this behavior
  useEffect(() => {
    const isActiveOrTrialing = ['active', 'trialing'].includes(
      subscriptionStatus
    )
    const isGated =
      !currentUser.superuser && !isActiveOrTrialing && isSubscriptionRequired
    if (!isLoading) {
      history.replace({
        ...history.location,
        state: { ...history.location.state, isGated },
      })
    }
  }, [
    subscriptionStatus,
    isLoading,
    history,
    currentUser,
    isSubscriptionRequired,
  ])

  return context
}
