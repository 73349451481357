import React from 'react'
import { mount } from 'enzyme'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { Router, Route } from 'react-router-dom'
import { createMemoryHistory } from 'history'
import { act } from 'react-dom/test-utils'

import FacilityEditFeature from './FacilityEditFeature'
import FacilityEditForm from '../leevo_ui/FacilityEditForm/FacilityEditForm'
import MockFacilityProvider from '../../providers/MockFacilityProvider'

const mockAxios = new MockAdapter(axios)

// FIXME: While all these tests pass, there are console.errors being
// generated by enzyme. We have brought this to the attention of the enzyme
// team: https://github.com/enzymejs/enzyme/issues/2011#issuecomment-602121952
// If it's a big deal in the future, and they don't fix this, we can mock console.error just
// for this spec.
describe('FacilityEditFeature', () => {
  let wrapper, facilityResponse
  const mockHistory = createMemoryHistory()

  beforeEach(async () => {
    facilityResponse = {
      data: {
        id: '1',
        type: 'facility',
        attributes: {
          name: 'Scott Hamilton Skating Academy',
          slug: 'scott-hamilton-skating-academy',
          isStripeConnected: true,
        },
      },
    }

    mockAxios.reset()
    mockAxios.onGet().reply(200, facilityResponse)

    act(() => {
      wrapper = mount(
        <Router history={mockHistory}>
          <Route path="/facilities/:facility_id">
            <MockFacilityProvider>
              <FacilityEditFeature />
            </MockFacilityProvider>
          </Route>
        </Router>
      )
      mockHistory.push('/facilities/1')
    })
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders a FacilityEditForm once the facility is retrieved', () => {
    wrapper.update()
    expect(wrapper.find(FacilityEditForm)).toExist()
  })

  describe('when user is not authorized at the facility', () => {
    test('redirects to root path', () => {
      mockAxios.reset()
      mockAxios.onGet().reply(401)
      mockHistory.push = jest.fn()

      act(() => {
        wrapper = mount(
          <Router history={mockHistory}>
            <Route path="/facilities/:facility_id">
              <MockFacilityProvider>
                <FacilityEditFeature />
              </MockFacilityProvider>
            </Route>
          </Router>
        )
      })

      expect(mockHistory.push).not.toHaveBeenCalledWith('/login')
    })
  })

  describe('submission', () => {
    beforeEach(() => {
      wrapper.update()
      act(() => {
        wrapper
          .find('input[name="name"]')
          .simulate('change', { target: { value: 'foobar' } })
      })
    })

    describe('when successful', () => {
      beforeEach(async () => {
        mockAxios.reset()
        mockAxios.onPut().reply(200, facilityResponse)
        act(() => {
          wrapper.find('form').simulate('submit')
        })
        wrapper.update()
      })

      // FIXME: This was working until we switched to notification component.
      //        Was difficult to fix, so marked as TODO
      test.todo('displays success message')
      // async () => {
      //   await flushPromises()
      //   const notification = wrapper
      //     .find('[data-cy="notification"]')
      //     .hostNodes()
      //   expect(notification).toHaveText('Changes saved!')
      // })
    })

    describe('when unsuccessful', () => {
      describe('with provided message', function () {
        beforeEach(async () => {
          mockAxios.reset()
          mockAxios.onPut().reply(500, {
            error: 'Something went wrong.',
          })
          act(() => {
            wrapper.find('form').simulate('submit')
          })
        })

        // FIXME: This was working until we switched to notification component.
        //        Was difficult to fix, so marked as TODO
        test.todo('displays success message')
        // () => {
        //   const notification = wrapper
        //     .find('[data-cy="notification"]')
        //     .hostNodes()
        //   expect(notification).toHaveText('Something went wrong.')
        // })
      })

      describe('without provided message', function () {
        beforeEach(() => {
          mockAxios.reset()
          mockAxios.onPut().reply(500, {})
          act(() => {
            wrapper.find('form').simulate('submit')
            wrapper.update()
          })
        })

        // FIXME: This was working until we switched to notification component.
        //        Was difficult to fix, so marked as TODO
        test.todo('displays success message')
        //  () => {
        //    wrapper.update()
        //    const notification = wrapper.find(
        //      Notification
        //    )
        //    const defaultMessage =
        //      'We were unable to save your changes.'
        //    expect(
        //      notification
        //    ).toHaveText(
        //      defaultMessage
        //    )
        //  })
      })
    })
  })
})
