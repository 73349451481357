import React from 'react'
import { text, select, boolean } from '@storybook/addon-knobs'

import StudentSkills from './StudentSkills'

export default {
  title: 'Coach Feature/Components/StudentSkills',
  component: StudentSkills,
}

const skillStates = ['unintroduced', 'introduced', 'completed']

export function studentSkills() {
  const skills = [
    {
      shortLabel: 'A',
      status: select('Skill A State', skillStates, 'introduced'),
      loading: boolean('Skill A Loading?', false),
    },
    {
      shortLabel: 'B',
      status: select('Skill B State', skillStates, 'neutral'),
      loading: boolean('Skill B Loading?', false),
    },
    {
      shortLabel: 'C',
      status: select('Skill C State', skillStates, 'completed'),
      loading: boolean('Skill C Loading?', false),
    },
    {
      shortLabel: 'D',
      status: select('Skill D State', skillStates, 'introduced'),
      loading: boolean('Skill D Loading?', false),
    },
    {
      shortLabel: 'E',
      status: select('Skill E State', skillStates, 'completed'),
      loading: boolean('Skill E Loading?', false),
    },
    {
      shortLabel: 'F',
      status: select('Skill F State', skillStates, 'introduced'),
      loading: boolean('Skill F Loading?', false),
    },
  ]

  return (
    <div className="m-3">
      <StudentSkills skills={skills} errorMessage={text('Error Message', '')} />
    </div>
  )
}
