import React from 'react'
import { shallow, mount } from 'enzyme'

import CheckboxInput from './CheckboxInput'
import CheckButton from '../CheckButton/CheckButton'

describe('CheckboxInput', () => {
  let wrapper
  beforeEach(() => {
    wrapper = shallow(<CheckboxInput id="id" label="foo" />)
  })

  test('renders', () => {
    wrapper = shallow(<CheckboxInput id="id" label="foo" />)
    expect(wrapper).toExist()
  })

  test('renders a CheckButton', () => {
    wrapper = shallow(<CheckboxInput id="id" label="foo" />)
    expect(wrapper.find(CheckButton)).toExist()
  })

  describe('value', () => {
    test('sets the value on the CheckButton', () => {
      wrapper = shallow(
        <CheckboxInput id="id" label="foo" value="some-value" />
      )
      const checkButtonValue = wrapper.find(CheckButton).props().value
      expect(checkButtonValue).toBe('some-value')
    })
  })

  describe('checked', () => {
    test('sets the value of checked on the CheckButton to true when set on CheckboxInput', () => {
      wrapper = shallow(<CheckboxInput id="id" label="foo" checked />)
      expect(wrapper.find(CheckButton).props().checked).toBe(true)
    })

    test('sets the value of checked on the CheckButton to false when not set on CheckboxInput', () => {
      wrapper = shallow(<CheckboxInput id="id" label="foo" />)
      expect(wrapper.find(CheckButton).props().checked).toBe(false)
    })
  })

  describe('small', () => {
    test('sets the value of CheckButton size to 6 rather than 8', () => {
      wrapper = shallow(<CheckboxInput id="id" label="foo" small={true} />)
      expect(wrapper.find(CheckButton).props().size).toBe(6)
    })

    test('sets the default value of CheckButton size to  8', () => {
      wrapper = shallow(<CheckboxInput id="id" label="foo" />)
      expect(wrapper.find(CheckButton).props().size).toBe(8)
    })
  })

  describe('id', () => {
    test('is required', () => {
      function renderWrapper() {
        shallow(<CheckboxInput label="label" />)
      }
      expect(renderWrapper).toThrowError('`id` is marked as required')
    })
  })

  describe('label', () => {
    test('is required', () => {
      function renderWrapper() {
        shallow(<CheckboxInput id="id" />)
      }
      expect(renderWrapper).toThrowError('`label` is marked as required')
    })

    test('presents the label when present', () => {
      wrapper = shallow(<CheckboxInput id="id" label="some-label" />)
      expect(wrapper).toIncludeText('some-label')
    })
  })

  describe('onClick', () => {
    let mockOnClick
    beforeEach(() => {
      mockOnClick = jest.fn()
      wrapper = mount(
        <CheckboxInput id="id" label="some-label" onClick={mockOnClick} />
      )
    })

    test('is called when CheckButton is clicked', () => {
      wrapper.find(CheckButton).simulate('click')
      expect(mockOnClick).toHaveBeenCalled()
    })

    test('is called when label is clicked', () => {
      wrapper.find('label').simulate('click')
      expect(mockOnClick).toHaveBeenCalled()
    })
  })
})
