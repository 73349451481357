import React from 'react'
import { mount } from 'enzyme'

import DisclosuresListItem from './DisclosuresListItem'
import MockFacilityProvider from '../../../providers/MockFacilityProvider'
import { MemoryRouter, Route } from 'react-router-dom'

describe('DisclosuresListItem', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MockFacilityProvider>
          <MemoryRouter initialEntries={['/index']}>
            <Route path="/index">
              <DisclosuresListItem name="foo" id="bar" />
            </Route>
          </MemoryRouter>
        </MockFacilityProvider>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
