import React from 'react'
import Avatar from './Avatar'
import { mount } from 'enzyme'
import SVG from 'react-inlinesvg'
import { Image } from 'cloudinary-react'

import defaultPhoto from '../../../images/user-default.svg'

describe('Avatar', () => {
  let wrapper
  beforeEach(() => {
    wrapper = mount(<Avatar isStudent={false} />)
  })

  test('renders at size 16 when size is 16', () => {
    wrapper.setProps({ size: 16 })

    expect(wrapper.find('.h-16.w-16')).toExist()
  })

  test('renders at size 24 when size is 24', () => {
    wrapper.setProps({ size: 24 })

    expect(wrapper.find('.h-24.w-24')).toExist()
  })

  test('renders at size 10 when size is 10', () => {
    wrapper.setProps({ size: 10 })

    expect(wrapper.find('.h-10.w-10')).toExist()
  })

  test('renders at size 12 when size is 12', () => {
    wrapper.setProps({ size: 12 })

    expect(wrapper.find('.h-12.w-12')).toExist()
  })

  test('picture is shown when there is a cloudinaryPhotoPublicId', () => {
    wrapper.setProps({
      cloudinaryPhotoPublicId: 'fake',
      fullName: 'Arya Stark',
      id: 2,
      isStudent: false,
    })

    wrapper.update()
    expect(wrapper.find(Image)).toExist()
  })

  describe('when src is not present', () => {
    describe('for non student users', () => {
      test('full name initials are shown with unique colors dependent on userIds', () => {
        wrapper.setProps({
          src: undefined,
          fullName: 'Arya Stark',
          userId: 2,
          isStudent: false,
        })

        expect(wrapper.find('svg').text()).toEqual('AS')
        expect(wrapper.find('svg').prop('className')).toContain(
          'bg-yellow-100 text-yellow-700'
        )
      })

      test('purple placeholder person SVG is shown as a fallback when there is no src or full name', () => {
        wrapper.setProps({
          src: undefined,
          fullName: undefined,
          userId: 2,
          isStudent: false,
        })

        expect(wrapper.find(SVG).props().src).toEqual(defaultPhoto)
      })
    })

    describe('for students', () => {
      test('full name initials are shown with default purple colors', () => {
        wrapper.setProps({
          src: undefined,
          fullName: 'Arya Stark',
          userId: 2,
          isStudent: true,
        })

        expect(wrapper.find('svg').text()).toEqual('AS')
        expect(wrapper.find('svg').prop('className')).toContain(
          'bg-purple-100 text-purple-600'
        )
      })

      test('purple placeholder person SVG is shown as a fallback when there is no src or full name', () => {
        wrapper.setProps({
          src: undefined,
          fullName: undefined,
          userId: 2,
          isStudent: true,
        })

        expect(wrapper.find(SVG).props().src).toEqual(defaultPhoto)
      })
    })
  })
})
