import React from 'react'
import { mount } from 'enzyme'

import MockFacilityProvider from '../../providers/MockFacilityProvider'
import { MemoryRouter, Route } from 'react-router-dom'

import AdminLTSReportsNew from './AdminLTSReportsNew'

describe('AdminLTSReportsNew', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MockFacilityProvider>
          <MemoryRouter initialEntries={['/AdminLTSReportsNew']}>
            <Route path="/AdminLTSReportsNew">
              <AdminLTSReportsNew />
            </Route>
          </MemoryRouter>
        </MockFacilityProvider>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
