import React, { useState } from 'react'
import { action } from '@storybook/addon-actions'
import { text } from '@storybook/addon-knobs'

import LoginModal from './LoginModal'
import Button from '../Button/Button'

import bgPhoto from '../../../images/sample/skating-wallpaper.jpg'

export default {
  title: 'Leevo UI/Modals/LoginModal',
  component: LoginModal,
}

export function LoginModalStory() {
  const [opened, setOpened] = useState(true)
  return (
    <>
      <img className="max-w-6xl" src={bgPhoto} />
      <div className="fixed w-full h-full" style={{ top: '45%', left: '45%' }}>
        <Button
          color="yellow"
          label="Open LoginModal"
          className="shadow-hover"
          onClick={() => setOpened(true)}
        />
      </div>
      <LoginModal
        open={opened}
        onClickBackground={() => setOpened(false)}
        signInUser={() => action('signing in user')}
        notificationMessage={text('Notification Message', '')}
      />
    </>
  )
}

export function LoginModalWithNotification() {
  const [opened, setOpened] = useState(true)
  return (
    <>
      <img className="max-w-6xl" src={bgPhoto} />
      <div className="fixed w-full h-full" style={{ top: '45%', left: '45%' }}>
        <Button
          color="yellow"
          label="Open LoginModal"
          className="shadow-hover"
          onClick={() => setOpened(true)}
        />
      </div>
      <LoginModal
        open={opened}
        onClickBackground={() => setOpened(false)}
        signInUser={() => action('signing in user')}
        notificationMessage={text(
          'notificationMessage',
          'You have successfully updated your password!'
        )}
      />
    </>
  )
}
