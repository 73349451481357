import React from 'react'
import { mount } from 'enzyme'
import { MemoryRouter, Route } from 'react-router-dom'

import PaymentConfirmation from './PaymentConfirmation'
import MockAuthProvider from '../../../providers/MockAuthProvider'

describe('PaymentConfirmation', () => {
  let wrapper

  // FIXME: Write more test cases.
  test('renders', () => {
    wrapper = mount(
      <MockAuthProvider>
        <MemoryRouter initialEntries={['/foo']} initialIndex={0}>
          <Route
            path="/foo"
            render={() => <PaymentConfirmation lineItems={[]} total={50} />}
          />
        </MemoryRouter>
      </MockAuthProvider>
    )
    expect(wrapper).toExist()
  })
})
