import { isPossiblePhoneNumber } from 'react-phone-number-input'

// FIXME: Can probably do this without making work for ourselves by
// using a library like `validator` or `yup`.
export function isEmail(value) {
  if (!value) return 'Required'
  // Lifted from here: https://emailregex.com/
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const errorMessage = 'Email must be valid.'
  // Strip out all whitespace. Emails can't have whitespace and the model does this automatically. on back end, too
  return value.replace(/\s+/g, '').match(regex) ? undefined : errorMessage
}

export function presence(value) {
  return !value ? 'Required' : null
}

export function isValidPassword(password) {
  if (!password) return 'Required'
  if (password.length < 8) return 'Password must be at least 8 characters'
  if (password.length > 128) return 'Password must be at most 128 characters'
}

export function isValidAddress(address) {
  const message =
    'When providing an address, include the street address, city, state and ZIP.'
  if (!address) return message

  const hasAnyFields = Object.keys(address).length !== 0
  const hasPartialEntry = !(
    address.line1 &&
    address.city &&
    address.state &&
    address.postCode
  )
  return hasAnyFields && hasPartialEntry ? message : null
}

export function isValidPasswordAndPasswordConfirmation({
  password,
  passwordConfirmation,
}) {
  const errors = {}

  errors.password = isValidPassword(password)

  if (!passwordConfirmation) {
    errors.passwordConfirmation = 'Required'
  } else if (password !== passwordConfirmation) {
    errors.passwordConfirmation = 'Passwords do not match'
  }

  return errors
}

export function isPhoneNumber(value) {
  return !isPossiblePhoneNumber(value) ? 'Must be a valid phone number' : null
}
