import React from 'react'
import Card from './Card'
import { shallow } from 'enzyme'

describe('Card', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(
        <Card>
          <div className="empty">Empty Div</div>
        </Card>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('that children node renders in Card', () => {
      expect(wrapper.find('.empty')).toIncludeText('Empty Div')
    })

    test('the title does not show on default', () => {
      expect(wrapper.find('h1').length).toEqual(0)
    })
  })

  describe('renders with title props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(
        <Card title="Empty Div Title">
          <div className="empty">Empty Div</div>
        </Card>
      )
    })

    test('the title renders', () => {
      expect(wrapper.find('h1').length).toEqual(1)
    })

    test('the title is the same as the prop passed', () => {
      expect(wrapper.find('h1')).toIncludeText('Empty Div Title')
    })
  })
})
