import React from 'react'
import faker from 'faker'
import { text } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'

import ProfileForm from './ProfileForm'

export default {
  title: 'Leevo UI/ProfileForm',
  component: ProfileForm,
}

export function empty() {
  return <ProfileForm onSubmit={action('Submitted Form')} />
}

export function prePopulated() {
  const email = text('Email', faker.internet.email())
  const preferredName = text('Preferred Name', faker.name.firstName())
  const fullName = text(
    'Full Name',
    `${preferredName} ${faker.name.lastName()}`
  )
  return (
    <ProfileForm
      email={email}
      preferredName={preferredName}
      fullName={fullName}
      onSubmit={action('Submitted Form')}
    />
  )
}
