import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import axios from 'axios'
import URI from 'urijs'
import fileDownload from 'js-file-download'
import { useParams, Link } from 'react-router-dom'
import moment from 'moment'
import pluralize from 'pluralize'

import Button from '../Button/Button'
import Input from '../Input/Input'
import CheckboxInput from '../CheckboxInput/CheckboxInput'
import SelectInput from '../SelectInput/SelectInput'
import Notification from '../Notification/Notification'
import CardFormSection from '../CardFormSection/CardFormSection'
import CreditCardManager from '../CreditCardManager/CreditCardManager'
import AddressInput from '../AddressInput/AddressInput'
import { priceInDollars } from '../../../utilities/helpers'
import { isValidAddress, presence } from '../../../utilities/validators'

function FacilityEditForm({
  onSubmit,
  id,
  name,
  address,
  curriculums,
  stripeCreditCardBrand,
  stripeCreditCardLast4,
  onCardChange,
  isStripeConnected,
  upcomingInvoiceStart,
  upcomingInvoiceEnd,
  upcomingInvoiceTotal,
  upcomingInvoiceStudentsCount,
  isSubscriptionCancelledAtPeriodEnd,
  subscriptionStatus,
  trialEnd,
  newRecord,
  providesFreeRentalSkates,
  supportsSkating,
}) {
  const { facility_id: facilityId } = useParams()
  const [showStripeConnected, setShowStripeConnected] = useState(
    isStripeConnected
  )
  const activeOrTrialing = ['active', 'trialing'].includes(subscriptionStatus)
  const [
    isGuardianExportDownloading,
    setIsGuardianExportDownloading,
  ] = useState(false)
  const [
    isNameBadgeExportDownloading,
    setIsNameBadgeExportDownloading,
  ] = useState(false)

  useEffect(() => {
    setShowStripeConnected(isStripeConnected)
  }, [isStripeConnected])

  const stripeButtonURI = URI({
    protocol: 'https',
    hostname: 'dashboard.stripe.com',
    path: '/oauth/authorize',
    query: {
      response_type: 'code',
      client_id: process.env.STRIPE_CONNECT_CLIENT_ID,
      scope: 'read_write',
      state: id,
      redirect_uri: `${window.location.origin}/api/stripe/new`,
    },
  })

  return (
    <Form
      initialValues={{ name, address, providesFreeRentalSkates }}
      validate={(values) => {
        const errors = {}
        if (!values.name) errors.name = 'Required'
        return errors
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className="p-4">
          <CardFormSection
            title="Facility Info"
            description="General facility information that is displayed to all parents, directors and coaches."
            submitting={submitting}
          >
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                <Field name="name" validate={presence}>
                  {({ input, meta: { active, error, submitFailed } }) => (
                    <Input
                      {...input}
                      id="name"
                      autoFocus
                      className="w-full mr-3"
                      type="text"
                      label="Facility Name"
                      placeholder="Your facility's name"
                      errorMessage={
                        !active && error && submitFailed ? error : ''
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 mt-4">
                <Field name="address" validate={isValidAddress}>
                  {({ input, meta: { active, error, submitFailed } }) => (
                    <AddressInput
                      {...input}
                      label="Location"
                      errorMessage={
                        !active && error && submitFailed ? error : ''
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
            {supportsSkating && (
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <Field name="providesFreeRentalSkates" type="checkbox">
                    {({ input }) => (
                      <CheckboxInput
                        {...input}
                        id="providesFreeRentalSkates"
                        label="Provide rental skates?"
                        helpText={`${
                          input.checked ? 'We will' : "We won't"
                        } ask students for skate type and size when registering.`}
                        onClick={() => input.onChange(!input.checked)}
                        data-cy="providesFreeRentalSkates-checkbox-input"
                      />
                    )}
                  </Field>
                </div>
              </div>
            )}
            {newRecord && (
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 mt-4">
                  <Field name="curriculum" validate={presence}>
                    {({ input, meta: { active, error, submitFailed } }) => (
                      <SelectInput
                        {...input}
                        id="curriculums"
                        label="Curriculum"
                        placeholder="Select Curriculum"
                        options={curriculums.map(({ id, name }) => ({
                          id,
                          label: name || 'Unnamed',
                        }))}
                        errorMessage={
                          !active && error && submitFailed ? error : ''
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>
            )}
          </CardFormSection>
          {!newRecord && (
            <>
              <CardFormSection
                title="Subscription"
                description="Manage the payment method on file that will cover your monthly fee, based on the number of students who have taken a class in the past month."
                showSubmit={false}
              >
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    {!activeOrTrialing && (
                      <div
                        className="mb-5 space-y-5 text-sm"
                        data-cy="no-subscription-notice"
                      >
                        <p>{"You don't have an active subscription. "}</p>
                        <p>
                          To start using Leevo, enter a new credit card below.
                        </p>
                      </div>
                    )}
                    {subscriptionStatus === 'trialing' && (
                      <div data-cy="trialing-notice" className="text-sm">
                        <p className="mb-4">
                          {'Your free trial will end on '}
                          <span className="font-semibold">
                            {moment.unix(trialEnd).format('MMMM Do, YYYY')}
                          </span>
                          {'.'}
                        </p>
                        <p className="mb-4">
                          {"You won't be charged until your trial ends. "}
                          {stripeCreditCardBrand &&
                            'If you no longer want to use Leevo, remove your card.'}
                          {!stripeCreditCardBrand &&
                            'To access Leevo after your trial ends, add a credit card.'}
                        </p>
                      </div>
                    )}
                    {subscriptionStatus === 'active' && (
                      <div
                        data-cy="active-subscription-info"
                        className="text-sm"
                      >
                        <p className="mb-4">
                          Upcoming bill:{' '}
                          <span className="font-semibold">
                            ${priceInDollars(upcomingInvoiceTotal)}
                          </span>
                        </p>
                        <p className="mb-4">
                          Number of students:{' '}
                          <span className="font-semibold">
                            {pluralize(
                              'student',
                              upcomingInvoiceStudentsCount,
                              true
                            )}
                          </span>
                        </p>
                        <p className="mb-1">
                          Billing period start:{' '}
                          <span className="font-semibold">
                            {moment
                              .unix(upcomingInvoiceStart)
                              .format('MMMM Do, YYYY')}
                          </span>
                        </p>
                        <p className="mb-4">
                          Billing period end:{' '}
                          <span className="font-semibold">
                            {moment
                              .unix(upcomingInvoiceEnd)
                              .format('MMMM Do, YYYY')}
                          </span>
                        </p>
                        <p className="mb-4 text-xs">
                          If you enter the next{' '}
                          <Link
                            className="font-semibold text-purple-600 hover:text-purple-400"
                            to="/pricing"
                          >
                            pricing tier
                          </Link>{' '}
                          before the end of your billing period, then your price
                          may increase.
                        </p>
                      </div>
                    )}
                    {isSubscriptionCancelledAtPeriodEnd && (
                      <Notification
                        show
                        color="red"
                        className="max-w-sm mb-5 text-sm"
                        data-cy="cancellation-notification"
                      >
                        <p className="font-medium">
                          {subscriptionStatus === 'active' &&
                            'Your subscription is not active.'}
                          {subscriptionStatus === 'trialing' &&
                            'Your subscription will cancel after your trial.'}
                        </p>
                        <p className="mt-2 font-medium">
                          You will lose access to Leevo on{' '}
                          <span className="font-semibold">
                            {moment
                              .unix(upcomingInvoiceEnd || trialEnd)
                              .format('MMMM Do, YYYY')}
                          </span>
                          {'.'}
                        </p>
                        <p className="mt-2 font-medium">
                          To ensure you have access to Leevo, update your credit
                          card info.
                        </p>
                      </Notification>
                    )}
                    {onCardChange && (
                      <CreditCardManager
                        brand={activeOrTrialing ? stripeCreditCardBrand : null}
                        lastFour={
                          activeOrTrialing ? stripeCreditCardLast4 : null
                        }
                        onChange={onCardChange}
                      />
                    )}
                  </div>
                </div>
              </CardFormSection>

              <CardFormSection
                title="Payment Processing"
                description="Connect your Stripe account to Leevo to enable registration, which automatically places students into classes."
                showSubmit={false}
              >
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <section className="w-full">
                      <div className="text-xs">
                        {showStripeConnected && (
                          <p className="my-3 text-red-700">
                            Clicking this button will disconnect Stripe from
                            your account.
                            <br />
                            That means you will no longer be able to register
                            new students using Leevo.
                          </p>
                        )}
                      </div>
                      {showStripeConnected && (
                        <Button
                          data-cy="disconnect-stripe-button"
                          outline
                          className="my-3"
                          color="red"
                          label="Disconnect Stripe Account"
                          small
                          onClick={() =>
                            axios
                              .delete(`/api/stripe/${id}`)
                              .then(() => setShowStripeConnected(false))
                          }
                        />
                      )}
                      {!showStripeConnected && (
                        <a
                          href={stripeButtonURI}
                          className="my-3 stripe-connect light-blue"
                          data-cy="connect-stripe-button"
                        >
                          <span>Connect with Stripe</span>
                        </a>
                      )}
                    </section>
                  </div>
                </div>
              </CardFormSection>
              <CardFormSection
                title="Data export"
                description="Export your data to a spreadsheet."
                showSubmit={false}
              >
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <section className="w-full text-gray-800">
                      <p className="mb-1 text-lg font-medium">
                        Guardian Contact List
                      </p>
                      <div className="text-xs text-gray-600">
                        <p>
                          Click the button below to download a list of the
                          guardians of all currently enrolled students.
                        </p>
                        <Button
                          outline
                          type="button"
                          label={
                            isGuardianExportDownloading
                              ? 'Preparing...'
                              : 'Download'
                          }
                          className="my-5 text-base"
                          small
                          disabled={isGuardianExportDownloading}
                          loading={isGuardianExportDownloading}
                          onClick={() => {
                            setIsGuardianExportDownloading(true)
                            axios
                              .get(
                                `/api/facilities/${facilityId}/guardian_exports`,
                                { responseType: 'blob' }
                              )
                              .then((response) => {
                                const header =
                                  response.headers['content-disposition']
                                const [_, filename] = header.match(
                                  /filename="(.+)";/
                                )
                                fileDownload(response.data, filename)
                              })
                              .catch((error) => {
                                throw error
                              })
                              .finally(() =>
                                setIsGuardianExportDownloading(false)
                              )
                          }}
                        />
                      </div>
                    </section>
                  </div>
                  <div className="col-span-3 sm:col-span-2">
                    <section className="w-full text-gray-800">
                      <p className="mb-1 text-lg font-medium">
                        Name Badge Data
                      </p>
                      <div className="text-xs text-gray-600">
                        <p>
                          Click the button below to download a list of all
                          students in upcoming classes, with their skill level.
                        </p>
                        <Button
                          outline
                          type="button"
                          label={
                            isNameBadgeExportDownloading
                              ? 'Preparing...'
                              : 'Download'
                          }
                          className="my-5 text-base"
                          small
                          disabled={isNameBadgeExportDownloading}
                          loading={isNameBadgeExportDownloading}
                          onClick={() => {
                            setIsNameBadgeExportDownloading(true)
                            axios
                              .post(
                                `/api/facilities/${facilityId}/name_badge_exports`,
                                { responseType: 'blob' }
                              )
                              .then((response) => {
                                const header =
                                  response.headers['content-disposition']
                                const [_, filename] = header.match(
                                  /filename="(.+)";/
                                )
                                fileDownload(response.data, filename)
                              })
                              .catch((error) => {
                                throw error
                              })
                              .finally(() =>
                                setIsNameBadgeExportDownloading(false)
                              )
                          }}
                        />
                      </div>
                    </section>
                  </div>
                </div>
              </CardFormSection>
            </>
          )}
        </form>
      )}
    />
  )
}

FacilityEditForm.defaultProps = {
  card: {},
  address: {},
  curriculums: [],
  newRecord: false,
}

FacilityEditForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  address: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postCode: PropTypes.string,
  }),
  curriculums: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    })
  ),
  stripeCreditCardBrand: PropTypes.string,
  stripeCreditCardLast4: PropTypes.string,
  trialEnd: PropTypes.number,
  isStripeConnected: PropTypes.bool,
  isSubscriptionCancelledAtPeriodEnd: PropTypes.bool,
  subscriptionStatus: PropTypes.string,
  upcomingInvoiceStart: PropTypes.number,
  upcomingInvoiceEnd: PropTypes.number,
  upcomingInvoiceTotal: PropTypes.number,
  upcomingInvoiceStudentsCount: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  onCardChange: PropTypes.func,
  newRecord: PropTypes.bool,
  providesFreeRentalSkates: PropTypes.bool,
  supportsSkating: PropTypes.bool,
}

export default FacilityEditForm
