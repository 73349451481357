import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { Form } from 'react-final-form'
import { action } from '@storybook/addon-actions'

import EmailInfo from './EmailInfo'

export default {
  title: 'Registration Feature/Email Info',
  component: EmailInfo,
}

export function emailInfo() {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios
    .onGet('/api/users', { params: { email: 'test@example.com' } })
    .reply(200)
  mockAxios.onGet('/api/users').reply(204)
  mockAxios
    .onPost('/auth/sign_in', {
      email: 'test@example.com',
      password: 'password',
    })
    .reply(200, { data: { data: { id: 1, type: 'user' } } })
  mockAxios.onPost('/auth/sign_in').reply(401)

  return (
    <Form
      onSubmit={action('Submit')}
      render={({ handleSubmit }) => (
        <EmailInfo
          onSubmit={handleSubmit}
          setNextButtonDisabled={action('Set Next Button Disabled')}
        />
      )}
    />
  )
}
