import React, { useState } from 'react'
import SlideInTransition from './SlideInTransition'
import Button from '../../Button/Button'

export default {
  title: 'Leevo UI/Transitions/SlideInTransition',
  component: SlideInTransition,
}

export function SlideInTransitionStory() {
  const [show, setShow] = useState(true)
  return (
    <div className="flex flex-col items-center justify-center m-5">
      <Button label="Toggle" onClick={() => setShow(!show)} />
      <SlideInTransition show={show}>
        <div className="w-20 h-20 m-5 bg-purple-600 border-2 border-purple-900 rounded-full"></div>
      </SlideInTransition>
    </div>
  )
}
