import React from 'react'
import { action } from '@storybook/addon-actions'

import CouponCodeModal from './CouponCodeModal'
import MockFacilityProvider from '../../../providers/MockFacilityProvider'

export default {
  title: 'FacilitiesDiscountsIndex/CouponCodeModal',
  component: CouponCodeModal,
  args: {
    className: 'm-4',
    header: 'New Coupon Code',
    onClose: action('closing'),
  },
}

export function couponCodeModal(args) {
  return (
    <MockFacilityProvider>
      <CouponCodeModal {...args} />
    </MockFacilityProvider>
  )
}
