import React from 'react'
import { mount } from 'enzyme'
import { MemoryRouter } from 'react-router'

import MultiKlassDiscount from './MultiKlassDiscount'
import MockFacilityProvider from '../../../providers/MockFacilityProvider'

describe('MultiKlassDiscount', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter>
          <MockFacilityProvider>
            <MultiKlassDiscount />
          </MockFacilityProvider>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
