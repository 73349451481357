import React from 'react'
import SkateRentalListItem from './SkateRentalListItem'
import faker from 'faker'
import { sample } from 'lodash'

export default {
  title: 'FacilitiesSkateRentalsIndex/SkateRentalListItem',
  component: SkateRentalListItem,
  args: {
    fullName: faker.name.findName(),
    size: Math.round(Math.random() * 20).toString(),
    cloudinaryPhotoPublicId: 'qrz0yfenrunmvcb0epwa',
    skateType: sample([
      'men_figure',
      'women_figure',
      'youth_figure',
      'men_hockey',
      'women_hockey',
      'youth_hockey',
    ]),
    itemCount: 6,
  },
  argTypes: {
    itemCount: { control: { type: 'range', min: 0, max: 20 } },
  },
}

export function skateRentalListItem(args) {
  const items = []
  for (let i = 0; i < args.itemCount; i++) {
    const firstItem = i === 0
    items.push({
      id: i + 1,
      fullName: firstItem ? args.fullName : faker.name.findName(),
      size: firstItem ? args.size : Math.round(Math.random() * 20).toString(),
      skateType: firstItem
        ? args.skateType
        : sample([
            'men_figure',
            'women_figure',
            'youth_figure',
            'men_hockey',
            'women_hockey',
            'youth_hockey',
          ]),
      cloudinaryPhotoPublicId: firstItem
        ? args.cloudinaryPhotoPublicId
        : Math.random() >= 0.5
        ? 'qrz0yfenrunmvcb0epwa'
        : '',
    })
  }

  return (
    <>
      {items.map((item) => (
        <SkateRentalListItem key={item.id} {...item} />
      ))}
    </>
  )
}
