import React from 'react'
import { mount } from 'enzyme'
import { MemoryRouter, Route } from 'react-router-dom'

import ProtectedRoute from './ProtectedRoute'
import LoginFeature from '../../LoginFeature/LoginFeature'
import LoadingPage from '../../leevo_ui/LoadingPage/LoadingPage'
import MockAuthProvider from '../../../providers/MockAuthProvider'

describe('ProtectedRoute', () => {
  let wrapper
  function MockComponent() {
    return <div>Mock Component</div>
  }

  beforeEach(() => {
    wrapper = mount(
      <MockAuthProvider isLoading>
        <MemoryRouter initialEntries={['/foo']} initialIndex={0}>
          <ProtectedRoute path="/foo" component={MockComponent} />
          <Route path="/login" component={LoginFeature} />
        </MemoryRouter>
      </MockAuthProvider>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  describe('when loading', () => {
    test('has loading page', () => {
      expect(wrapper.find(LoadingPage)).toExist()
    })
  })

  describe('when signed in', () => {
    beforeEach(() => {
      wrapper = mount(
        <MockAuthProvider isSignedIn>
          <MemoryRouter initialEntries={['/foo']} initialIndex={0}>
            <ProtectedRoute path="/foo" component={MockComponent} />
            <Route path="/login" component={LoginFeature} />
          </MemoryRouter>
        </MockAuthProvider>
      )
    })
    test('renders component', () => {
      expect(wrapper.find(MockComponent)).toExist()
    })
  })

  describe('when not loading and not signed in', () => {
    beforeEach(() => {
      wrapper = mount(
        <MockAuthProvider>
          <MemoryRouter initialEntries={['/foo']} initialIndex={0}>
            <ProtectedRoute path="/foo" component={MockComponent} />
            <Route path="/login" component={LoginFeature} />
          </MemoryRouter>
        </MockAuthProvider>
      )
    })

    test('renders the login path', () => {
      const { location } = wrapper.find(LoginFeature).props()

      expect(location.pathname).toBe('/login')
    })

    test('provides the location to return to after successful login', () => {
      const { location } = wrapper.find(LoginFeature).props()
      const redirectPathname = location.state.from.pathname

      expect(redirectPathname).toBe('/foo')
    })
  })
})
