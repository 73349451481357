import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { ReactMultiEmail } from 'react-multi-email'
import 'react-multi-email/style.css'
import classNames from 'classnames'

export function presence(value) {
  return !value ? 'You must enter at least one email address' : null
}

function MultiEmailField({ className, label, ...rest }) {
  const [emails, setEmails] = useState([])

  function hasErrorMessage({ active, error, submitFailed }) {
    return !active && error && submitFailed
  }

  return (
    <Field name="emails" validate={presence}>
      {({ input, meta: { active, submitFailed, error } }) => (
        <div className={className}>
          {label && (
            <label
              className={`${classNames({
                'border-red-500': hasErrorMessage({
                  active,
                  submitFailed,
                  error,
                }),
                'text-red-500': hasErrorMessage({
                  active,
                  submitFailed,
                  error,
                }),
              })} block text-gray-700 text-sm font-light mb-1 ml-1`}
            >
              {label}
            </label>
          )}
          <ReactMultiEmail
            {...rest}
            placeholder="Enter email address then press tab or enter"
            emails={emails}
            onChange={(emails) => {
              input.onChange(emails)
              setEmails(emails)
            }}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              )
            }}
            data-cy="emails-input"
          />
          <p className="mb-3 text-xs italic text-red-500">
            {hasErrorMessage({ active, submitFailed, error }) ? error : ''}
          </p>
        </div>
      )}
    </Field>
  )
}

MultiEmailField.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
}

export default MultiEmailField
