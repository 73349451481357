import React from 'react'
import { shallow } from 'enzyme'
import { BrowserRouter } from 'react-router-dom'

import CreateAccount from './CreateAccount'

// FIXME: Write specs for this
describe('CreateAccount', () => {
  let wrapper

  test('renders', () => {
    wrapper = shallow(
      <BrowserRouter>
        <CreateAccount nextStepUrl="foo" />
      </BrowserRouter>
    )
    expect(wrapper).toExist()
  })
})
