import React, { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import PropTypes from 'prop-types'

import Input from '../../leevo_ui/Input/Input'
import SmallRadioInput from '../../leevo_ui/SmallRadioInput/SmallRadioInput'
import AddressInput from '../../leevo_ui/AddressInput/AddressInput'
import BirthdatePicker from '../../leevo_ui/BirthdatePicker/BirthdatePicker'
import SkillLevelField from '../../RegistrationFeature/SkillLevelField/SkillLevelField'
import RentalSkatesFields from '../../RegistrationFeature/RentalSkatesFields/RentalSkatesFields'
import CardFormSection from '../../leevo_ui/CardFormSection/CardFormSection'
import UploadButton from '../../leevo_ui/UploadButton/UploadButton'
import ExpandDownTransition from '../../leevo_ui/Transitions/ExpandDownTransition/ExpandDownTransition'
import CheckboxInput from '../../leevo_ui/CheckboxInput/CheckboxInput'
import { presence, isValidAddress } from '../../../utilities/validators'
import { requestCreator } from '../../../utilities/requests'
import GuardianManager from './GuardianManager/GuardianManager'

const { put: updateStudent, cancel: cancelStudentRequests } = requestCreator()

function StudentEditForm({
  id,
  facilityId,
  onSubmit,
  fullName,
  preferredName,
  cloudinaryPhotoPublicId,
  birthdate,
  gender,
  ltsNumber,
  skillLevel,
  address,
  guardians,
  skateRental,
  requiresAddress,
  requiresBirthdate,
  requiresGender,
  requiresLtsNumber,
  facilitySupportsSkating,
  facilityProvidesFreeRentalSkates,
}) {
  useEffect(() => cancelStudentRequests)

  return (
    <Form
      initialValues={{
        fullName,
        preferredName,
        cloudinaryPhotoPublicId,
        gender,
        birthdate,
        ltsNumber,
        skillLevel,
        address,
        skateRental,
      }}
      onSubmit={(values) => {
        // Only submit guardians if it's a new record, otherwise they are
        // created on the fly
        onSubmit({ ...values, guardians: !id ? values.guardians : null })
      }}
      render={({ handleSubmit, submitting, values }) => (
        <div className="flex justify-center p-5 bg-gray-100">
          <form className="max-w-4xl" onSubmit={handleSubmit}>
            <CardFormSection
              title="Student Profile"
              description="This information is used to keep your membership current. It is displayed in your dashboard, and to coaches and directors."
              submitting={submitting}
            >
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <Field name="fullName" validate={presence}>
                    {({ input, meta: { active, error, submitFailed } }) => (
                      <Input
                        {...input}
                        id="full_name"
                        autoFocus
                        type="text"
                        label="Full Name"
                        placeholder="Richard Button"
                        errorMessage={
                          !active && error && submitFailed ? error : ''
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <Field name="preferredName" validate={presence}>
                    {({ input, meta: { active, error, submitFailed } }) => (
                      <Input
                        {...input}
                        id="preferred_name"
                        type="text"
                        label="Preferred Name"
                        placeholder="Richard"
                        errorMessage={
                          !active && error && submitFailed ? error : ''
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>
              {(!requiresLtsNumber || !facilitySupportsSkating) && (
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <SkillLevelField name="the student" helpText="" />
                  </div>
                </div>
              )}
              <Field name="cloudinaryPhotoPublicId">
                {({ input }) => (
                  <UploadButton
                    cloudinaryPhotoPublicId={input.value}
                    onPhotoUpload={(newPublicId) => {
                      if (!id) {
                        input.onChange(newPublicId)
                      } else {
                        updateStudent(
                          `/api/facilities/${facilityId}/users/${id}`,
                          {
                            user: { cloudinaryPhotoPublicId: newPublicId },
                          }
                        ).then(() => {
                          input.onChange(newPublicId)
                        })
                      }
                    }}
                  />
                )}
              </Field>
              {requiresGender && (
                <div>
                  <Field name="gender" validate={presence}>
                    {({ input, meta: { active, error, submitFailed } }) => (
                      <SmallRadioInput
                        id="gender"
                        label="Gender"
                        errorMessage={
                          !active && error && submitFailed ? error : ''
                        }
                        options={[
                          { label: 'Female', value: 'female' },
                          { label: 'Male', value: 'male' },
                          { label: 'Non-binary', value: 'non_binary' },
                          { label: 'Unspecified', value: 'unspecified' },
                        ]}
                        value={input.value}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
              )}
              {requiresBirthdate && (
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3">
                    <Field name="birthdate">
                      {({ input, meta: { active, error, submitFailed } }) => (
                        <BirthdatePicker
                          {...input}
                          id="birthdate"
                          label="Birthdate"
                          errorMessage={
                            !active && error && submitFailed ? error : ''
                          }
                        />
                      )}
                    </Field>
                  </div>
                </div>
              )}
              {requiresAddress && (
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 mt-4">
                    <Field name="address" validate={isValidAddress}>
                      {({ input, meta: { active, error, submitFailed } }) => (
                        <AddressInput
                          {...input}
                          label="Mailing address"
                          errorMessage={
                            !active && error && submitFailed ? error : ''
                          }
                        />
                      )}
                    </Field>
                  </div>
                </div>
              )}
            </CardFormSection>
            <CardFormSection
              title="Guardian Information"
              description="This information is related to the student's parent, guardian or other responsible party."
              submitting={submitting}
            >
              <div className="grid grid-cols-3 gap-6">
                <Field name="guardians">
                  {({ input: { onChange } }) => (
                    <GuardianManager
                      onChange={onChange}
                      className="col-span-3"
                      guardians={guardians}
                      studentId={id}
                    />
                  )}
                </Field>
              </div>
            </CardFormSection>
            {facilitySupportsSkating && (
              <CardFormSection
                title="Skating Information"
                description="Information related to skating and the student's standing with Learn to Skate USA."
                submitting={submitting}
              >
                {facilityProvidesFreeRentalSkates && (
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <Field name="skateRental.active" type="checkbox">
                        {({ input }) => (
                          <CheckboxInput
                            {...input}
                            id="skateRental.active"
                            label="Needs skate rental?"
                            helpText={`The facility ${
                              input.checked ? 'will' : "won't"
                            } have skates ready for the student before class.`}
                            onClick={() => input.onChange(!input.checked)}
                          />
                        )}
                      </Field>
                      <ExpandDownTransition show={!!values.skateRental.active}>
                        <RentalSkatesFields className="pl-4" values={values} />
                      </ExpandDownTransition>
                    </div>
                  </div>
                )}
                {requiresLtsNumber && (
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <Field name="ltsNumber">
                        {({ input, meta: { active, error, submitFailed } }) => (
                          <Input
                            {...input}
                            id="ltsNumber"
                            type="text"
                            label="LTS Member Number"
                            placeholder="LTS or USFSA number"
                            errorMessage={
                              !active && error && submitFailed ? error : ''
                            }
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                )}
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <SkillLevelField name="the student" helpText="" />
                  </div>
                </div>
              </CardFormSection>
            )}
          </form>
        </div>
      )}
    />
  )
}

// FIXME: Should pull this from rails back end in some way.
StudentEditForm.genders = ['male', 'female', 'non_binary', 'unspecified']

StudentEditForm.defaultProps = {
  fullName: '',
  preferredName: '',
  email: '',
  birthdate: '',
  gender: 'unspecified',
  ltsNumber: '',
  skillLevel: '',
  skateRental: {},
  address: {},
  requiresAddress: false,
  requiresBirthdate: false,
  requiresGender: false,
  requiresLtsNumber: false,
}

StudentEditForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  facilityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullName: PropTypes.string,
  preferredName: PropTypes.string,
  cloudinaryPhotoPublicId: PropTypes.string,
  email: PropTypes.string,
  birthdate: PropTypes.string,
  guardians: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      fullName: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      cloudinaryPhotoPublicId: PropTypes.string,
      pending: PropTypes.bool,
      emailBounced: PropTypes.bool,
    })
  ),
  gender: PropTypes.oneOf(StudentEditForm.genders),
  ltsNumber: PropTypes.string,
  skillLevel: PropTypes.string,
  address: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postCode: PropTypes.string,
  }),
  skateRental: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  requiresAddress: PropTypes.bool,
  requiresBirthdate: PropTypes.bool,
  requiresGender: PropTypes.bool,
  requiresLtsNumber: PropTypes.bool,
  facilitySupportsSkating: PropTypes.bool,
  facilityProvidesFreeRentalSkates: PropTypes.bool,
}

export default StudentEditForm
