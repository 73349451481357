import React from 'react'
import { shallow } from 'enzyme'
import MarketingPageFooter from './MarketingPageFooter'

describe('MarketingPageFooter', () => {
  let wrapper
  beforeEach(async () => {
    wrapper = shallow(<MarketingPageFooter />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders copyright info', () => {
    expect(wrapper).toIncludeText('©')
  })
})
