import React from 'react'
import { action } from '@storybook/addon-actions'
import { boolean, text } from '@storybook/addon-knobs'
import { Form } from 'react-final-form'
import faker from 'faker'

import GenderField from './GenderField'

export default {
  title: 'Registration Feature/GenderField',
  component: GenderField,
}

export function genderField() {
  const nameField = text('Name', faker.name.firstName())
  const nameToggle = boolean('Default name?', false) ? undefined : nameField
  return (
    <Form
      onSubmit={action('Submit')}
      render={() => (
        <GenderField
          className={boolean('With margin?', false) ? 'm-6' : ''}
          name={nameToggle}
        />
      )}
    />
  )
}
