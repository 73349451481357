import React from 'react'
import GuardianInfo from './GuardianInfo'
import { shallow } from 'enzyme'

describe('GuardianInfo', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(<GuardianInfo />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
