import React from 'react'
import Badge from './Badge'

export default {
  title: 'Leevo UI/Badge',
  component: Badge,
  args: {
    text: 'Active',
    color: 'green',
  },
  argTypes: {
    color: {
      control: {
        type: 'select',
        options: {
          Gray: 'gray',
          Red: 'red',
          Green: 'green',
          Orange: 'orange',
          Blue: 'blue',
          Purple: 'purple',
        },
      },
    },
  },
}

export function badge(args) {
  return (
    <div className="mt-10 text-center">
      <Badge {...args} />
    </div>
  )
}
