import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import SVG from 'react-inlinesvg'
import { squareOfSize } from '../../../utilities/tailwindClassHelpers'

function classes({ color, size, disabled, loading }) {
  let classes = [
    squareOfSize(size),
    'flex-shrink-0',
    'rounded-full',
    'border',
    'text-center',
    'font-medium',
    'focus:outline-none',
    'focus:ring',
    'focus:ring-opacity-50',
  ]

  switch (color) {
    case 'green':
      classes.push(
        'border-green-800',
        'bg-green-700',
        'text-white',
        'focus:ring-green-400'
      )
      break
    case 'yellow':
      if (disabled) classes.push('text-gray-600')
      classes.push(
        'border-yellow-500',
        'bg-yellow-400',
        'focus:ring-yellow-400'
      )
      break
    case 'purple':
      classes.push(
        'border-purple-900',
        'bg-purple-600',
        'text-white',
        'focus:ring-purple-400'
      )
      break
    case 'gray':
      classes.push(
        'border-gray-400',
        'bg-gray-300',
        'text-white',
        'focus:ring-gray-300'
      )
      break
    // FIXME: We added this color type to make it easier to have a grayed out
    // date on the KlassesDatePicker that still behaved like a button. We don't
    // think this is the right approach, though, because the button is not disabled,
    // so the name `disabled` isn't correct. Also, we want to avoid adding
    // additional button color types whenever the base colors don't work.
    // We think the better approach here is may be to create a DateSelectorButton
    // component that relies on CircleButton, but overwrites some of the styles.
    case 'disabled-gray':
      classes.push('border-gray-400', 'bg-white', 'text-gray-400')
      break
    case 'white':
      if (disabled) classes.push('text-gray-300')
      classes.push(
        'border-gray-400',
        'bg-white',
        'focus:ring-gray-300',
        'text-gray-600'
      )
      break
    default:
      // transparent
      if (disabled) classes.push('text-gray-300')
      classes.push(
        'border-gray-400',
        'bg-transparent',
        'focus:ring-gray-300',
        'text-gray-600'
      )
      break
  }

  if (size < 10) {
    classes.push('text-xs')
  } else if (size < 16) {
    classes.push('text-base')
  } else if (size < 24) {
    classes.push('text-lg')
  } else if (size < 32) {
    classes.push('text-2xl')
  } else {
    classes.push('text-6xl')
  }

  if (disabled) classes.push('opacity-50', 'cursor-not-allowed')

  if (loading) classes.push('spinner')
  return classNames(classes)
}

function renderContent(size, svgSrc, label) {
  if (svgSrc) {
    return (
      <SVG
        className="w-1/2 h-auto m-auto fill-current stroke-current"
        src={svgSrc}
      />
    )
  } else {
    return size > 4 && label
  }
}

const CircleButton = React.forwardRef(
  (
    {
      ariaProps,
      disabled,
      onClick,
      className,
      style,
      size,
      svgSrc,
      label,
      color,
      value,
      loading,
      ...rest
    },
    ref
  ) => {
    return (
      <button
        {...rest}
        {...ariaProps}
        type="button"
        ref={ref}
        disabled={disabled}
        onClick={onClick}
        className={`${classes({
          color,
          size,
          disabled,
          loading,
        })} ${className}`}
        style={style}
        value={value}
      >
        {renderContent(size, svgSrc, label)}
      </button>
    )
  }
)

CircleButton.displayName = 'CircleButton'

CircleButton.sizes = [
  1,
  2,
  3,
  4,
  5,
  6,
  8,
  10,
  12,
  14,
  16,
  20,
  24,
  32,
  40,
].reverse()

CircleButton.colors = [
  'green',
  'yellow',
  'purple',
  'white',
  'gray',
  'disabled-gray',
  'transparent',
]

CircleButton.defaultProps = {
  ariaProps: {},
  checked: false,
  size: 10,
  className: '',
  color: 'white',
  onClick: () => {},
  loading: false,
}

CircleButton.propTypes = {
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(CircleButton.sizes),
  style: PropTypes.object,
  color: PropTypes.oneOf(CircleButton.colors),
  label: PropTypes.string,
  svgSrc: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  ariaProps: PropTypes.shape({
    'aria-pressed': PropTypes.bool,
    'aria-label': PropTypes.string,
  }),
  value: PropTypes.string,
}

export default CircleButton
