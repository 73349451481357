import React from 'react'

import SkillLevelList from './SkillLevelList'

export default {
  title: 'Leevo UI/SkillLevelList',
  component: SkillLevelList,
}

const skills = [
  {
    shortLabel: 'A',
    state: 'introduced',
    name: 'Forward one foot glides – R and L',
  },
  {
    shortLabel: 'B',
    state: 'neutral',
    name: 'Backward two-foot glide',
  },
  {
    shortLabel: 'C',
    state: 'completed',
    name: 'Backward swizzles – 6–8 in a row',
  },
  {
    shortLabel: 'D',
    state: 'introduced',
    name: 'Two-foot turn, forward to backward in place',
  },
  {
    shortLabel: 'E',
    state: 'completed',
    name: 'Moving snowplow stop',
  },
  {
    shortLabel: 'F',
    state: 'introduced',
    name:
      'Forward alternating half swizzle pumps, in a straight line (slalom-like pattern)',
  },
]

export function skillLevelList() {
  return (
    <SkillLevelList
      skillLevels={[
        { name: 'Foobar', skills },
        { name: 'Barbaz', skills },
      ]}
    />
  )
}
