import React from 'react'
import { action } from '@storybook/addon-actions'

import YouTubeEmbedModal from './YouTubeEmbedModal'

export default {
  title: 'Leevo UI/YouTubeEmbedModal',
  component: YouTubeEmbedModal,
  args: {
    open: false,
    onClose: action('onClose'),
    embedUrl:
      'https://www.youtube.com/embed/sb7vQEBsX_w?autoplay=1&modestbranding=1&playsinline=0&rel=0',
  },
}

export function youTubeEmbedModal(args) {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      Use controls to open and close the modal
      <YouTubeEmbedModal {...args} />
    </div>
  )
}
