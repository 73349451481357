import React from 'react'
import SkillDrawer from './SkillDrawer'
import { shallow } from 'enzyme'

describe('SkillDrawer', () => {
  let wrapper
  beforeEach(() => {
    wrapper = shallow(
      <SkillDrawer onCategorySelected={jest.fn()} skillLevels={[]} />
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
