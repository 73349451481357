import React from 'react'
import { text } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'
import { Form } from 'react-final-form'

import KlassPackageList from './KlassPackageList'
import Button from '../../leevo_ui/Button/Button'

export default {
  title: 'Registration Feature/KlassPackageList',
  component: KlassPackageList,
}

export function klassPackageList() {
  function generateKlasses() {
    const klasses = []
    for (let i = 0; i < 2; i++) {
      klasses.push({
        id: i,
        dayOfWeek: 'Monday',
        timeOfDay: '12:30 PM',
        startDate: 'Jul 11',
        endDate: 'Oct 23',
        remainingKlassDays: 4,
        spotsLeft: 2,
        priceInDollars: '65.99',
      })
    }
    return klasses
  }

  return (
    <Form
      onSubmit={action('Submit')}
      render={({ handleSubmit }) => (
        <>
          <KlassPackageList
            studentId={1}
            studentName="Serena"
            studentLevel="Free Skate 4"
          />
          <KlassPackageList
            studentId={2}
            studentName={text('Student Name', 'Mary')}
            studentLevel={text('Student Level', 'Basic 1')}
            availableKlasses={generateKlasses()}
          />
          <KlassPackageList
            studentId={3}
            studentName={text('Student Name 2', 'Joe')}
            studentLevel={text('Student Level 2', 'Basic 2')}
            availableKlasses={generateKlasses()}
          />
          <KlassPackageList
            studentId={3}
            studentName={text('Student Name 3', 'Sue')}
            studentLevel={text('Student Level 3', 'Basic 3')}
            availableKlasses={[]}
          />
          <KlassPackageList
            studentId={3}
            studentName={text('Student Name 4', 'Sally')}
            studentLevel={text('Student Level 4', 'Basic 4')}
            availableKlasses={generateKlasses()}
            selectedKlasses={[{ id: 0 }]}
          />
          <Button className="mx-4 my-6" onClick={handleSubmit} label="Submit" />
        </>
      )}
    />
  )
}
