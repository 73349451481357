import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import mixpanel from '../../../utilities/mixpanel'

import { parseRecord } from '../../../utilities/jsonApiHelpers'
import Button from '../../leevo_ui/Button/Button'
import Avatar from '../../leevo_ui/Avatar/Avatar'
import StudentSkills from '../StudentSkills/StudentSkills'
import StudentAttendanceCheckButton from '../StudentAttendanceCheckButton/StudentAttendanceCheckButton'
import PromotionModal from '../PromotionModal/PromotionModal'

function StudentCard({
  student,
  klassDaysStudent,
  selectedCategory,
  onPromotion,
  toggleAttendance,
}) {
  const {
    fullName,
    preferredName,
    cloudinaryPhotoPublicId,
    studentsSkills,
    currentSkillLevel,
  } = student
  const [skills, setSkills] = useState(allSkillsMarkedAsNotLoading())
  const [completedLevel, setCompletedLevel] = useState(() =>
    isLevelCompleted(skills)
  )
  const [skillErrorMessage, setSkillErrorMessage] = useState('')
  const [isPromotionModalOpen, setIsPromotionModalOpen] = useState(false)
  const [isPendingPromotion, setIsPendingPromotion] = useState(false)

  useEffect(() => {
    const completed = isLevelCompleted(skills)

    setCompletedLevel(completed)
  }, [skills])

  function isLevelCompleted(skills) {
    return skills.every(
      ({ status, skill: { optional } }) => optional || status === 'completed'
    )
  }

  function currentSkills() {
    const currentSkillsIds = currentSkillLevel.skills.map((skill) => skill.id)

    return studentsSkills.filter((studentsSkill) =>
      currentSkillsIds.includes(studentsSkill.skill.id)
    )
  }

  function allSkillsMarkedAsNotLoading() {
    return currentSkills().map((studentsSkill) => ({
      ...studentsSkill,
      loading: false,
    }))
  }

  function promote() {
    setIsPendingPromotion(true)
    onPromotion(student).then(() => setIsPendingPromotion(false))
  }

  function handleMarkSkill({ id }) {
    const orderOfSkills = ['unintroduced', 'introduced', 'completed']
    const skill = skills.find((skill) => skill.id === id)
    const currentIndex = orderOfSkills.indexOf(skill.status)
    const nextIndex = (currentIndex + 1) % 3
    const updateTo = orderOfSkills[nextIndex]

    setSkills(
      skills.map((skill) =>
        skill.id === id ? { ...skill, loading: true } : skill
      )
    )

    axios
      .patch(`/api/students_skills/${id}`, {
        studentsSkill: { status: updateTo },
      })
      .then((response) => {
        const { id, status } = parseRecord(response)
        setSkills(
          skills.map((skill) =>
            skill.id === id ? { ...skill, status, loading: false } : skill
          )
        )
        mixpanel.track("Marked Student's Skill", { studentsSkill: id, status })
      })
      .catch((error) => {
        setSkills(
          skills.map((skill) =>
            skill.id === id ? { ...skill, loading: false } : skill
          )
        )
        setSkillErrorMessage('Oops something went wrong')
        throw error
      })
  }

  return (
    <div
      className="w-full px-5 pt-3 pb-6 my-3 border-b border-gray-200 last:border-0"
      style={{ breakInside: 'avoid' }} // avoids the page breaking in the middle of a student card
      data-cy="student-card"
    >
      <div className="flex justify-between">
        <div className="flex flex-col justify-between">
          <div className="flex items-center justify-start mb-2">
            {toggleAttendance && (
              <StudentAttendanceCheckButton
                klassDaysStudent={klassDaysStudent}
                toggleAttendance={toggleAttendance}
              />
            )}
            <p className="inline-block font-medium">{fullName}</p>
          </div>
          {!!skills.length && (
            <StudentSkills
              skills={skills.filter(
                (skill) => selectedCategory === skill.category
              )}
              onMarkSkill={handleMarkSkill}
              errorMessage={skillErrorMessage}
            />
          )}
        </div>
        <Avatar
          fullName={fullName}
          isStudent
          cloudinaryPhotoPublicId={cloudinaryPhotoPublicId}
          alt={`Photo of ${fullName}`}
          userId={student.id}
        />
      </div>
      {completedLevel && currentSkillLevel.nextSkillLevel && (
        <Button
          label="Promote"
          color="green"
          className="mt-4"
          onClick={() => setIsPromotionModalOpen(true)}
          data-cy="promote-button"
          fullWidth
        />
      )}
      {currentSkillLevel.nextSkillLevel && (
        <PromotionModal
          open={isPromotionModalOpen}
          pending={isPendingPromotion}
          studentName={preferredName}
          originalLevel={currentSkillLevel.name}
          newLevel={currentSkillLevel.nextSkillLevel.name}
          onCancel={
            isPendingPromotion
              ? undefined
              : () => setIsPromotionModalOpen(false)
          }
          onConfirm={promote}
        />
      )}
    </div>
  )
}

StudentCard.defaultProps = {
  onPromotion: () => {},
}

StudentCard.propTypes = {
  student: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fullName: PropTypes.string.isRequired,
    preferredName: PropTypes.string.isRequired,
    cloudinaryPhotoPublicId: PropTypes.string,
    studentsSkills: PropTypes.array.isRequired,
    currentSkillLevel: PropTypes.shape({
      name: PropTypes.string.isRequired,
      skills: PropTypes.array.isRequired,
      nextSkillLevel: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        name: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
  selectedCategory: PropTypes.string,
  onPromotion: PropTypes.func,
  klassDaysStudent: PropTypes.object,
  toggleAttendance: PropTypes.func,
}

export default StudentCard
