import React from 'react'
import PropTypes from 'prop-types'

import SkillButton from '../../leevo_ui/SkillButton/SkillButton'

function StudentSkills({ skills, onMarkSkill, errorMessage }) {
  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap">
        {skills
          .sort((a, b) => a.ordinal - b.ordinal)
          .map((skill) => {
            return (
              <SkillButton
                key={skill.shortLabel}
                label={skill.shortLabel}
                state={skill.status}
                onClick={() => onMarkSkill(skill)}
                loading={skill.loading}
              />
            )
          })}
      </div>
      {errorMessage && (
        <div className="pt-2 text-sm text-red-600">{errorMessage}</div>
      )}
    </div>
  )
}

StudentSkills.defaultProps = {
  skills: [],
  onMarkSkill: () => {},
  errorMessage: '',
}

StudentSkills.propTypes = {
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      shortLabel: PropTypes.string.isRequired,
      status: PropTypes.oneOf(SkillButton.states).isRequired,
      loading: PropTypes.bool,
    })
  ).isRequired,
  onMarkSkill: PropTypes.func,
  errorMessage: PropTypes.string,
}

export default StudentSkills
