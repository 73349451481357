import React from 'react'
import SimpleJustifiedCTA from './SimpleJustifiedCTA'

export default {
  title: 'Leevo UI/SimpleJustifiedCTA',
  component: SimpleJustifiedCTA,
}

export function simpleJustifiedCta(args) {
  return <SimpleJustifiedCTA {...args} />
}
