import React from 'react'
import KlassPackageCard from './KlassPackageCard'

export default {
  title: 'Leevo UI/KlassPackageCard',
  component: KlassPackageCard,
  args: {
    className: 'm-4',
    active: false,
    title: 'Sundays - 6:15 PM',
    description:
      'Classes run on Sundays from Oct 4, 2020 to Oct 11, 2020 at 6:15 PM.',
    facilityId: 1,
    students: [{ id: 1 }, { id: 2 }],
    loading: false,
  },
}

export function klassPackageCard(args) {
  return <KlassPackageCard {...args} />
}
