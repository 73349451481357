import React from 'react'
import SlideInTransition from './SlideInTransition'
import { mount } from 'enzyme'

describe('SlideInTransition', () => {
  let wrapper, child

  beforeEach(() => {
    child = <div>I am a child.</div>
    wrapper = mount(<SlideInTransition>{child}</SlideInTransition>)
  })

  describe('when show is true', () => {
    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('renders children', () => {
      expect(wrapper).toHaveText('I am a child.')
    })
  })

  describe('when show is false', () => {
    test('renders no children', () => {
      wrapper = mount(
        <SlideInTransition show={false}>{child}</SlideInTransition>
      )
      expect(wrapper).toHaveText('')
    })
  })
})
