import React from 'react'
import PropTypes from 'prop-types'
import { useParams, Link } from 'react-router-dom'
import moment from 'moment'
import momentPropTypes from 'react-moment-proptypes'
import { levelArrayToString } from '../../../utilities/helpers'

import Card from '../../leevo_ui/Card/Card'
import Avatar from '../../leevo_ui/Avatar/Avatar'

function CoachClassCard({ classId, date, coaches, skillLevels }) {
  const { facility_id } = useParams()

  return (
    <Link
      to={`/facilities/${facility_id}/classes/${classId}/class_days/${moment(
        date
      ).format('YYYY-MM-DD')}/${moment(date).format('kk:mm')}`}
      className="mt-2 duration-300 transform scale-95 cursor-pointer w-96 focus:outline-none focus:scale-100 hover:scale-100"
    >
      <Card
        interactive
        className="flex flex-col justify-between w-full h-full text-left"
        borderColor="purple"
        data-cy="class-card"
      >
        <div className="flex items-center justify-between px-2 py-1 my-2 space-x-1 w-full">
          <div className="overflow-hidden font-medium leading-tight text-left text-purple-700">
            {coaches.length === 0 && (
              <p data-cy="coach-name" className="truncate">
                Unassigned
              </p>
            )}
            {coaches.map((coach) => (
              <p data-cy="coach-name" key={coach.id} className="truncate">
                {coach.preferredName || coach.email}
              </p>
            ))}
          </div>
          <div className="flex justify-end space-x-1">
            {coaches.length === 0 && (
              <p data-cy="coach-name" className="truncate">
                <Avatar size={16} />
              </p>
            )}
            {coaches.map((coach) => (
              <Avatar
                fullName={coach.fullName}
                key={coach.id}
                size={16}
                cloudinaryPhotoPublicId={coach.cloudinaryPhotoPublicId}
                userId={coach.id}
              />
            ))}
          </div>
        </div>
        <div className="flex items-center justify-between px-2 py-1 text-xs font-medium text-gray-600 bg-gray-200 w-full">
          <p>{moment(date).format('LT')}</p>
          <p className="w-4/6 text-right" data-cy="class-levels">
            {levelArrayToString(skillLevels)}
          </p>
        </div>
      </Card>
    </Link>
  )
}

CoachClassCard.defaultProps = {}

CoachClassCard.propTypes = {
  classId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  date: momentPropTypes.momentObj,
  coaches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      preferredName: PropTypes.string.isRequired,
      cloudinaryPhotoPublicId: PropTypes.string,
    })
  ).isRequired,
  skillLevels: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default CoachClassCard
