import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { uniq as _uniq } from 'lodash'

function SelectInput({
  className,
  label,
  errorMessage,
  id,
  placeholder,
  disabled,
  options,
  helpText,
  ...rest
}) {
  function optionGroups() {
    return _uniq(options.map(({ group }) => group))
  }

  function renderOptionsForGroup(group) {
    const groupOptions = options.filter((option) => group === option.group)
    const renderedOptions = groupOptions.map(({ value, label }) => (
      <option key={value} value={value}>
        {label}
      </option>
    ))
    return renderedOptions
  }

  return (
    <div className={className}>
      {label && (
        <label
          className={`${classNames({
            'text-red-500': errorMessage,
          })} block text-gray-700 text-sm font-medium mb-1 ml-1`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      {helpText && <p className="mb-6 text-xs">{helpText}</p>}
      <div className="relative">
        <select
          className={`${classNames({
            'text-red-500 border-red-500 placeholder-red-300 ring ring-red-500 ring-opacity-50 error-shake': errorMessage,
            'text-gray-800': !errorMessage,
            'shadow-inner': !disabled,
            'bg-gray-100': disabled,
          })} appearance-none bg-white text-sm border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:ring focus:border-purple-500 focus:ring-purple-300 focus:ring-opacity-50 placeholder-gray-500  border-gray-300 transition duration-200 ease-linear`}
          id={id}
          disabled={disabled}
          data-cy={`${id}-select-input`}
          {...rest}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {optionGroups().map((group) => {
            if (group === undefined) return renderOptionsForGroup(group)
            return (
              <optgroup key={group} label={group}>
                {renderOptionsForGroup(group)}
              </optgroup>
            )
          })}
        </select>
      </div>
      {errorMessage && (
        <p
          className="mb-3 text-xs italic text-red-500"
          data-cy={`${id}-input-error`}
        >
          {errorMessage}
        </p>
      )}
    </div>
  )
}

SelectInput.default = {
  className: '',
  errorMessage: '',
  placeholder: '',
  label: '',
  helpText: '',
  disabled: false,
}

SelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
      group: PropTypes.string,
    })
  ).isRequired,
}

export default SelectInput
