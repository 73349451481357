import React from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'
import moment from 'moment'
import classNames from 'classnames'

import CircleButton from '../../leevo_ui/CircleButton/CircleButton'
import angleLeft from '../../../images/angle-left.svg'
import angleRight from '../../../images/angle-right.svg'

function buttonColor(buttonDate, selectedDate, notAvailable) {
  if (buttonDate.isSame(selectedDate)) {
    return 'purple'
  } else if (notAvailable) {
    return 'disabled-gray'
  } else {
    return 'white'
  }
}
// FIXME - pull date buttons into new component
function KlassDatePicker({ selectedDate, availableDays, onDateSelected }) {
  return (
    <div className="sticky top-0 z-20 flex flex-col items-center justify-center w-full p-3 pb-3 bg-white border-b-2 border-gray-400">
      <div className="flex justify-between w-full max-w-md mb-4 date-arrow items-between">
        <CircleButton
          size={6}
          color="gray"
          className="text-purple-600"
          svgSrc={angleLeft}
          onClick={() =>
            onDateSelected(moment(selectedDate).subtract(7, 'days'))
          }
        />
        <p className="font-semibold">
          {moment(selectedDate).format('MMMM D, Y')}
        </p>
        <CircleButton
          size={6}
          color="gray"
          data-cy="next-week-button"
          className="text-purple-600"
          svgSrc={angleRight}
          onClick={() => onDateSelected(moment(selectedDate).add(7, 'days'))}
        />
      </div>
      <div className="flex items-center justify-between w-full max-w-md">
        {/*FIXME: move the days of the week circle buttons into an array */}
        <div className="flex flex-col items-center justify-center date-button">
          <p className="text-sm font-light">{moment().day(0).format('ddd')}</p>
          <CircleButton
            size={10}
            data-cy="sunday-date"
            className={classNames({
              'border-none': selectedDate.weekday() !== 0,
            })}
            color={buttonColor(
              moment(selectedDate).day(0),
              selectedDate,
              !availableDays.includes('Sunday')
            )}
            label={moment(selectedDate).day(0).format('D')}
            onClick={() => {
              onDateSelected(moment(selectedDate).day(0))
            }}
          />
        </div>
        <div className="flex flex-col items-center justify-center date-button">
          <p className="text-sm font-light">{moment().day(1).format('ddd')}</p>
          <CircleButton
            size={10}
            className={classNames({
              'border-none': selectedDate.weekday() !== 1,
            })}
            color={buttonColor(
              moment(selectedDate).day(1),
              selectedDate,
              !availableDays.includes('Monday')
            )}
            label={moment(selectedDate).day(1).format('D')}
            onClick={() => onDateSelected(moment(selectedDate).day(1))}
          />
        </div>
        <div className="flex flex-col items-center justify-center date-button">
          <p className="text-sm font-light">{moment().day(2).format('ddd')}</p>
          <CircleButton
            size={10}
            data-cy="tuesday-date"
            className={classNames({
              'border-none': selectedDate.weekday() !== 2,
            })}
            color={buttonColor(
              moment(selectedDate).day(2),
              selectedDate,
              !availableDays.includes('Tuesday')
            )}
            label={moment(selectedDate).day(2).format('D')}
            onClick={() => onDateSelected(moment(selectedDate).day(2))}
          />
        </div>
        <div className="flex flex-col items-center justify-center date-button">
          <p className="text-sm font-light">{moment().day(3).format('ddd')}</p>
          <CircleButton
            size={10}
            className={classNames({
              'border-none': selectedDate.weekday() !== 3,
            })}
            color={buttonColor(
              moment(selectedDate).day(3),
              selectedDate,
              !availableDays.includes('Wednesday')
            )}
            label={moment(selectedDate).day(3).format('D')}
            onClick={() => onDateSelected(moment(selectedDate).day(3))}
          />
        </div>
        <div className="flex flex-col items-center justify-center date-button">
          <p className="text-sm font-light">{moment().day(4).format('ddd')}</p>
          <CircleButton
            size={10}
            className={classNames({
              'border-none': selectedDate.weekday() !== 4,
            })}
            color={buttonColor(
              moment(selectedDate).day(4),
              selectedDate,
              !availableDays.includes('Thursday')
            )}
            label={moment(selectedDate).day(4).format('D')}
            onClick={() => onDateSelected(moment(selectedDate).day(4))}
          />
        </div>
        <div className="flex flex-col items-center justify-center date-button">
          <p className="text-sm font-light">{moment().day(5).format('ddd')}</p>
          <CircleButton
            size={10}
            className={classNames({
              'border-none': selectedDate.weekday() !== 5,
            })}
            color={buttonColor(
              moment(selectedDate).day(5),
              selectedDate,
              !availableDays.includes('Friday')
            )}
            label={moment(selectedDate).day(5).format('D')}
            onClick={() => onDateSelected(moment(selectedDate).day(5))}
          />
        </div>
        <div className="flex flex-col items-center justify-center date-button">
          <p className="text-sm font-light">{moment().day(6).format('ddd')}</p>
          <CircleButton
            size={10}
            className={classNames({
              'border-none': selectedDate.weekday() !== 6,
            })}
            color={buttonColor(
              moment(selectedDate).day(6),
              selectedDate,
              !availableDays.includes('Saturday')
            )}
            label={moment(selectedDate).day(6).format('D')}
            onClick={() => onDateSelected(moment(selectedDate).day(6))}
          />
        </div>
      </div>
    </div>
  )
}

KlassDatePicker.defaultProps = {
  onDateSelected: () => {},
}

KlassDatePicker.propTypes = {
  selectedDate: momentPropTypes.momentObj,
  onDateSelected: PropTypes.func,
  availableDays: PropTypes.arrayOf(PropTypes.string),
}

export default KlassDatePicker
