import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import pluralize from 'pluralize'
import { isFinite } from 'lodash'

import CheckButton from '../../../leevo_ui/CheckButton/CheckButton'

function KlassPackageListItem({
  id,
  title,
  description,
  priceInDollars,
  remainingKlassDays,
  spotsLeft,
  selected,
  onClick,
  helpText,
  disabled,
}) {
  return (
    <li
      data-cy={`${id}-${title}`}
      onClick={() => !disabled && onClick(id)}
      className={`flex items-center justify-start p-4 border-b border-gray-300 ${classNames(
        { 'cursor-pointer select-none': !disabled }
      )}`}
    >
      <div className="w-8 mr-2">
        {!disabled && onClick && (
          <CheckButton
            ariaProps={{
              'aria-pressed': selected,
              'aria-label': 'select class',
            }}
            checked={selected}
            data-cy={`${id}-${title}-check-button`}
            size={8}
          />
        )}
      </div>

      <div className="w-full">
        <div
          className={`flex justify-between font-medium ${classNames({
            'text-gray-800': !disabled,
            'text-gray-500': disabled,
          })}`}
        >
          <p>{title}</p>
          <p>{priceInDollars}</p>
        </div>
        <div className="flex justify-between text-xs font-medium">
          <p
            className={`${classNames({
              'text-gray-600': !disabled,
              'text-gray-500': disabled,
            })}`}
          >
            {remainingKlassDays} {pluralize('class', remainingKlassDays)}{' '}
            remaining
          </p>
          {isFinite(parseInt(spotsLeft)) && (
            <p
              className={`${classNames({
                'text-red-600': spotsLeft < 4 && !disabled,
                'text-gray-600': spotsLeft >= 4 && !disabled,
                'text-red-300': disabled,
              })}`}
            >
              {spotsLeft > 0 &&
                `${spotsLeft} ${pluralize('spot', spotsLeft)} left`}
              {spotsLeft <= 0 && 'Classes are full'}
            </p>
          )}
        </div>
        <p
          className={`text-sm mt-2 whitespace-pre-line ${classNames({
            'text-gray-600': !disabled,
            'text-gray-500': disabled,
          })}`}
        >
          {description}
        </p>
        {helpText && (
          <p
            className={`text-sm mt-2 italic ${classNames({
              'text-gray-600': !disabled,
              'text-gray-500': disabled,
            })}`}
          >
            {helpText}
          </p>
        )}
      </div>
    </li>
  )
}

KlassPackageListItem.defaultProps = {
  selected: false,
  alreadyEnrolled: false,
}

KlassPackageListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  priceInDollars: PropTypes.string.isRequired,
  remainingKlassDays: PropTypes.number.isRequired,
  spotsLeft: PropTypes.number,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  onClick: PropTypes.func,
}

export default KlassPackageListItem
