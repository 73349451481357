import React from 'react'
import { MemoryRouter, Route } from 'react-router-dom'
import { shallow, mount } from 'enzyme'

import StudentListItem from './StudentListItem'

describe('StudentListItem', () => {
  let wrapper
  beforeEach(() => {
    wrapper = mount(
      <MemoryRouter initialEntries={['/1']}>
        <Route path="/:facility_id">
          <StudentListItem id={1} fullName="Jane Doe" />
        </Route>
      </MemoryRouter>
    )
  })

  describe('id', () => {
    test('is required', () => {
      function renderWrapper() {
        shallow(<StudentListItem />)
      }
      expect(renderWrapper).toThrowError('`id` is marked as required')
    })
  })

  it('renders', () => {
    expect(wrapper).toExist()
  })

  it('renders the full name', () => {
    expect(wrapper.html()).toContain('Jane Doe')
  })

  it('renders a link to the student edit page for the facility', () => {
    expect(wrapper.find('a[href="/facilities/1/students/1/edit"]')).toExist()
  })
})
