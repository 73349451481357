import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import Input from '../Input/Input'
import ShowHidePasswordButton from '../ShowHidePasswordButton/ShowHidePasswordButton'

function PasswordFields({ className }) {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className={className}>
      <Field name="password">
        {({ input, meta: { active, error, submitFailed } }) => (
          <div className="relative">
            <ShowHidePasswordButton
              showPassword={showPassword}
              onIconClicked={() => setShowPassword(!showPassword)}
              className="absolute top-0 right-0"
            />
            <Input
              {...input}
              id="password"
              className="pt-1"
              type={showPassword ? 'text' : 'password'}
              label="New password"
              placeholder="Password"
              errorMessage={!active && error && submitFailed ? error : ''}
            />
          </div>
        )}
      </Field>
      <Field name="passwordConfirmation">
        {({ input, meta: { active, error, submitFailed } }) => (
          <div className="relative">
            <ShowHidePasswordButton
              showPassword={showPassword}
              onIconClicked={() => setShowPassword(!showPassword)}
              className="absolute top-0 right-0"
            />
            <Input
              {...input}
              id="password-confirmation"
              className="pt-1"
              type={showPassword ? 'text' : 'password'}
              label="Confirm new password"
              placeholder="Re-enter password"
              errorMessage={!active && error && submitFailed ? error : ''}
            />
          </div>
        )}
      </Field>
    </div>
  )
}

PasswordFields.propTypes = {
  className: PropTypes.string,
}

export default PasswordFields
