import React from 'react'
import PromotionModal from './PromotionModal'
import { mount } from 'enzyme'

describe('PromotionModal', () => {
  let wrapper
  beforeEach(() => {
    wrapper = mount(
      <PromotionModal
        studentName="Matt"
        originalLevel="First"
        newLevel="Second"
        onCancel={() => {}}
        onConfirm={() => {}}
        pending={false}
      />
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders the student name', () => {
    expect(wrapper).toIncludeText('Matt')
  })

  test('renders the original level name', () => {
    expect(wrapper).toIncludeText('First')
  })

  test('renders the new level name', () => {
    expect(wrapper).toIncludeText('Second')
  })

  test('renders the modal close button if onCancel is present', () => {
    const closeButton = wrapper
      .find('[data-cy="modal-close-button"]')
      .hostNodes()
    expect(closeButton).toExist()
  })

  test('does not renders the modal close button if onCancel is not present', () => {
    wrapper.setProps({ onCancel: undefined })
    const closeButton = wrapper
      .find('[data-cy="modal-close-button"]')
      .hostNodes()
    expect(closeButton).not.toExist()
  })
})
