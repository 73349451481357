import React from 'react'
import { action } from '@storybook/addon-actions'
import { text } from '@storybook/addon-knobs'

import NewPasswordModal from './NewPasswordModal'

import bgPhoto from '../../../images/sample/skating-wallpaper.jpg'

export default {
  title: 'Leevo UI/Modals/NewPasswordModal',
  component: NewPasswordModal,
}

function newPasswordSubmission({ password, passwordConfirmation }) {
  action(
    `submission with password:"${password}" passwordConfirmation:"${passwordConfirmation}"`
  )()
}

export function defaultModal() {
  return (
    <>
      <img className="max-w-6xl" src={bgPhoto} />
      <NewPasswordModal newPasswordSubmission={newPasswordSubmission} />
    </>
  )
}

export function newPasswordModalSuccess() {
  return (
    <>
      <img className="max-w-6xl" src={bgPhoto} />
      <NewPasswordModal
        newPasswordSubmission={newPasswordSubmission}
        successMessage={text(
          'successMessage',
          'Your password has been successfully updated.'
        )}
      />
    </>
  )
}

export function newPasswordModalFailed() {
  return (
    <>
      <img className="max-w-6xl" src={bgPhoto} />
      <NewPasswordModal
        errorMessage={text(
          'errorMessage',
          'This error is likely coming from the backend. An example is not enough characters'
        )}
        newPasswordSubmission={newPasswordSubmission}
      />
    </>
  )
}
