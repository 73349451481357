import React from 'react'
import Button from './Button'
import { shallow } from 'enzyme'

describe('Button', () => {
  describe('with required props', () => {
    let wrapper, mockOnButtonClick
    beforeEach(() => {
      mockOnButtonClick = jest.fn()
      wrapper = shallow(
        <Button label="Required label" onClick={mockOnButtonClick} />
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('fires onClick function when clicked', () => {
      wrapper.simulate('click')
      expect(mockOnButtonClick).toHaveBeenCalled()
    })

    test('label renders inside Button', () => {
      expect(wrapper).toIncludeText('Required label')
    })

    test('non outline classes are added', () => {
      expect(wrapper.hasClass('bg-purple-600')).toEqual(true)
    })

    test('non disabled classes are added', () => {
      expect(wrapper.hasClass('hover:text-white')).toEqual(true)
    })

    test('color defaults to purple', () => {
      expect(wrapper.hasClass('bg-purple-600')).toEqual(true)
    })

    test('size defaults to larger padding', () => {
      expect(wrapper.hasClass('py-2')).toEqual(true)
      expect(wrapper.hasClass('px-4')).toEqual(true)
    })

    test('does not have disabled classes', () => {
      expect(wrapper.hasClass('opacity-50')).toEqual(false)
    })

    test('does not have fullWidth classes', () => {
      expect(wrapper.hasClass('min-w-full')).toEqual(false)
    })
    test('does not have outline classes', () => {
      expect(wrapper.hasClass('bg-transparent')).toEqual(false)
    })
  })

  describe('with disabled props', () => {
    let wrapper, mockOnButtonClick
    beforeEach(() => {
      mockOnButtonClick = jest.fn()
      wrapper = shallow(
        <Button label="Required label" onClick={mockOnButtonClick} disabled />
      )
    })

    test('adds disabled classes', () => {
      expect(wrapper.hasClass('opacity-50')).toEqual(true)
    })
  })

  describe('with fullWidth props', () => {
    let wrapper, mockOnButtonClick
    beforeEach(() => {
      mockOnButtonClick = jest.fn()
      wrapper = shallow(
        <Button label="Required label" onClick={mockOnButtonClick} fullWidth />
      )
    })

    test('adds fullWidth classes', () => {
      expect(wrapper.hasClass('min-w-full')).toEqual(true)
    })
  })

  describe('with outline props', () => {
    let wrapper, mockOnButtonClick
    beforeEach(() => {
      mockOnButtonClick = jest.fn()
      wrapper = shallow(
        <Button label="Required label" onClick={mockOnButtonClick} outline />
      )
    })

    test('adds outline classes', () => {
      expect(wrapper.hasClass('bg-transparent')).toEqual(true)
    })
  })

  describe('with light props', () => {
    let wrapper, mockOnButtonClick
    beforeEach(() => {
      mockOnButtonClick = jest.fn()
      wrapper = shallow(
        <Button label="Required label" onClick={mockOnButtonClick} light />
      )
    })

    test('adds lighter color classes', () => {
      expect(wrapper.hasClass('bg-purple-400')).toEqual(true)
    })
  })

  describe('with shadow props', () => {
    let wrapper, mockOnButtonClick
    beforeEach(() => {
      mockOnButtonClick = jest.fn()
      wrapper = shallow(
        <Button label="Required label" onClick={mockOnButtonClick} shadow />
      )
    })

    test('adds shadow-hover class', () => {
      expect(wrapper.hasClass('shadow-hover')).toEqual(true)
    })
  })

  describe('with color props', () => {
    let wrapper, mockOnButtonClick
    beforeEach(() => {
      mockOnButtonClick = jest.fn()
      wrapper = shallow(
        <Button
          label="Required label"
          onClick={mockOnButtonClick}
          color="red"
        />
      )
    })

    test('changes color classes', () => {
      expect(wrapper.hasClass('bg-red-600')).toEqual(true)
      expect(wrapper.hasClass('hover:bg-red-700')).toEqual(true)
    })
  })

  describe('with small props', () => {
    let wrapper, mockOnButtonClick
    beforeEach(() => {
      mockOnButtonClick = jest.fn()
      wrapper = shallow(
        <Button label="Required label" onClick={mockOnButtonClick} small />
      )
    })

    test('has smaller padding', () => {
      expect(wrapper).toHaveClassName('py-1')
      expect(wrapper).toHaveClassName('px-2')
    })

    test('has smaller text', () => {
      expect(wrapper).toHaveClassName('text-sm')
    })
  })

  describe('type prop', () => {
    it('is invalid when not button, submit or reset', () => {
      function render() {
        shallow(<Button label="Required label" type="foo" />)
      }
      expect(render).toThrowError(
        'Invalid prop `type` of value `foo` supplied to `Button`, expected one of ["button","submit","reset"]'
      )
    })

    it('is valid with type button', () => {
      function render() {
        shallow(<Button label="Required label" type="button" />)
      }
      expect(render).not.toThrowError()
    })

    it('is valid with type submit', () => {
      function render() {
        shallow(<Button label="Required label" type="submit" />)
      }
      expect(render).not.toThrowError()
    })

    it('is valid with type reset', () => {
      function render() {
        shallow(<Button label="Required label" type="reset" />)
      }
      expect(render).not.toThrowError()
    })
  })
})
