import React from 'react'
import CreditCardLogo from './CreditCardLogo'

export default {
  title: 'Leevo UI/CreditCardLogo',
  component: CreditCardLogo,
  args: { brand: 'Visa', className: 'w-12 h-auto' },
}

export function creditCardLogo(args) {
  return <CreditCardLogo {...args} />
}
