import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { get } from 'lodash'

import Modal from '../leevo_ui/Modal/Modal'
import Button from '../leevo_ui/Button/Button'
import useFacility from '../../utilities/hooks/useFacility'
import useAuth from '../../utilities/hooks/useAuth'

function FacilitiesInactive() {
  const [isAdmin, setIsAdmin] = useState(false)
  const { facility } = useFacility()
  const { currentUser, isLoading } = useAuth()

  useEffect(() => {
    if (currentUser.facilitiesUsers) {
      const facilitiesUser = currentUser.facilitiesUsers.find(
        (facilitiesUser) => facility.id === facilitiesUser.facility.id
      )
      setIsAdmin(get(facilitiesUser, 'admin'))
    }
  }, [currentUser.facilitiesUsers, facility])

  return isLoading ? null : (
    <Modal
      open
      headerColor="red"
      header="Oh no! 😲"
      data-cy="inactive-facility-notice"
    >
      {isAdmin ? (
        <div className="space-y-4" data-cy="inactive-facility-admin-notice">
          <p className="font-semibold">
            It looks like your subscription was cancelled. 😞
          </p>
          <p>{"Don't worry! We've saved all of your data."}</p>
          <p>
            Add a credit card on the{' '}
            <Link
              to={`/facilities/${facility.id}/edit`}
              className="text-purple-600 hover:text-purple-400"
            >
              settings page
            </Link>{' '}
            to access it.
          </p>
          <footer className="flex justify-center">
            <Link to={`/facilities/${facility.id}/edit`}>
              <Button className="mt-5" label="Restart Subscription" />
            </Link>
          </footer>
        </div>
      ) : (
        <div className="space-y-4" data-cy="inactive-facility-user-notice">
          <p>It looks like this facility is no longer using Leevo. 😞</p>
          <p>
            <span className="font-medium">Questions?</span> Contact the
            administrator.
          </p>
          {facility.billingContact && (
            <div>
              <p className="ml-5 font-semibold">
                {facility.billingContact.fullName}
              </p>
              <p className="ml-5 font-medium">
                <a
                  className="text-purple-600 hover:text-purple-400"
                  href={`mailto:${facility.billingContact.email}?subject=What happened to Leevo?&body=Hey ${facility.billingContact.preferredName}, what happened to Leevo?`}
                >
                  {facility.billingContact.email}
                </a>
              </p>
            </div>
          )}
        </div>
      )}
    </Modal>
  )
}

FacilitiesInactive.defaultProps = {}

FacilitiesInactive.propTypes = {}

export default FacilitiesInactive
