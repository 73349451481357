import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { get as _get } from 'lodash'

import KlassPackageForm from '../leevo_ui/KlassPackageForm/KlassPackageForm'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import { requestCreator } from '../../utilities/requests'
import useFacility from '../../utilities/hooks/useFacility'

const { get: getKlasses, cancel: cancelKlassesRequests } = requestCreator()
const {
  post: createKlassPackage,
  cancel: cancelKlassPackageRequests,
} = requestCreator()
const { get: getStudents, cancel: cancelStudentsRequests } = requestCreator()

function FacilitiesKlassPackagesNew() {
  const history = useHistory()
  const { facility, isLoading: isFacilityLoading } = useFacility()
  const [isLoading, setIsLoading] = useState(true)
  const [price, setPrice] = useState(null)
  const [klasses, setKlasses] = useState([])
  const [title, setTitle] = useState(null)
  const [students, setStudents] = useState([])
  const [description, setDescription] = useState(null)
  const [openStatus, setOpenStatus] = useState(true)

  useEffect(() => {
    if (!isFacilityLoading) {
      getKlasses(`/api/facilities/${facility.id}/klasses`, {
        params: { include: 'coaches,supported_skill_levels' },
      })
        .then((klasses) => setKlasses(klasses))
        .then(() =>
          getStudents(`/api/facilities/${facility.id}/students`, {
            params: {
              include: 'current_skill_level',
              fields: {
                student: 'fullName,cloudinaryPhotoId,current_skill_level,age',
                skill_level: 'name',
              },
            },
          })
        )
        .then(({ records: studentList }) =>
          setStudents(
            studentList.map((student) => ({
              ...student,
              label: student.fullName, // set a label for compatibility with multi-select searching
              value: student.id,
              selected: false,
            }))
          )
        )
        .then(() => setIsLoading(false))
        .catch((error) => {
          if (!axios.isCancel(error)) {
            history.push('/500')
          }
        })
    }

    return () => {
      cancelKlassesRequests()
      cancelKlassPackageRequests()
      cancelStudentsRequests()
    }
  }, [isFacilityLoading, facility, history])

  function onSubmit(values) {
    setKlasses(
      klasses.map((klass) => ({
        ...klass,
        selected: values.klasses.includes(klass.id),
      }))
    )
    setStudents(
      students.map((student) => ({
        ...student,
        selected: values.students
          .filter((student) => student.selected)
          .map((student) => student.id)
          .includes(student.id),
      }))
    )
    setPrice(values.price)
    setTitle(values.title)
    setDescription(values.description)
    setOpenStatus(values.open)
    return createKlassPackage(`/api/facilities/${facility.id}/class_packages`, {
      klassPackage: {
        ...values,
        students: values.students
          .filter((student) => student.selected)
          .map((student) => student.id),
      },
    })
      .then(() =>
        history.push({
          pathname: `/facilities/${facility.id}/class_packages`,
          state: {
            notification: {
              message: 'Class package created successfully!',
              color: 'green',
            },
          },
        })
      )
      .catch((error) => {
        if (!axios.isCancel(error)) {
          const message =
            _get(error, 'response.data.error') ||
            'We were unable to save your changes.'
          history.replace({
            state: {
              notification: {
                message,
                color: 'red',
              },
            },
          })
        }
      })
  }

  return (
    <ApplicationLayout isContentLoading={isLoading}>
      <KlassPackageForm
        price={price}
        klasses={klasses}
        title={title}
        description={description}
        students={students}
        open={openStatus}
        facilityId={facility.id}
        onSubmit={onSubmit}
      />
    </ApplicationLayout>
  )
}

export default FacilitiesKlassPackagesNew
