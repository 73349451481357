import React from 'react'
import { mount } from 'enzyme'
import SVG from 'react-inlinesvg'
import { Image } from 'cloudinary-react'

import CirclePhoto from './CirclePhoto'
import defaultPhoto from '../../../../images/user-default.svg'

describe('CirclePhoto', () => {
  let wrapper
  beforeEach(() => {
    wrapper = mount(<CirclePhoto />)
  })

  test('renders the cloudinary picture', () => {
    wrapper.setProps({ cloudinaryPhotoPublicId: 'fake' })
    wrapper.update()
    expect(wrapper.find(Image).props().publicId).toEqual('fake')
  })

  test('renders default backup svg when no picture is present picture', () => {
    wrapper.setProps({ cloudinaryPhotoPublicId: undefined })

    expect(wrapper.find(SVG).props().src).toEqual(defaultPhoto)
  })

  test('renders at size 16 when size is 16', () => {
    wrapper.setProps({ size: 16, cloudinaryPhotoPublicId: 'fake' })

    expect(wrapper.find('.h-16.w-16')).toExist()
  })

  test('renders at size 24 when size is 24', () => {
    wrapper.setProps({ size: 24, cloudinaryPhotoPublicId: 'fake' })

    expect(wrapper.find('.h-24.w-24')).toExist()
  })

  test('renders at size 12 when size is 12', () => {
    wrapper.setProps({ size: 12, cloudinaryPhotoPublicId: 'fake' })

    expect(wrapper.find('.h-12.w-12')).toExist()
  })

  test('renders at size 10 when size is 10', () => {
    wrapper.setProps({ size: 10, cloudinaryPhotoPublicId: 'fake' })

    expect(wrapper.find('.h-10.w-10')).toExist()
  })

  test('renders at size 8 when size is 8', () => {
    wrapper.setProps({ size: 8, cloudinaryPhotoPublicId: 'fake' })

    expect(wrapper.find('.h-8.w-8')).toExist()
  })

  test('renders at size 5 when size is 5', () => {
    wrapper.setProps({ size: 5, cloudinaryPhotoPublicId: 'fake' })

    expect(wrapper.find('.h-5.w-5')).toExist()
  })
})
