import React from 'react'
import { boolean, select, text } from '@storybook/addon-knobs'

import InitialsAvatar from './InitialsAvatar'

export default {
  title: 'Leevo UI/Avatar/InitialsAvatar',
  component: InitialsAvatar,
}

export function oneNameAvatar() {
  const name = text('name', 'Arya')
  return (
    <div>
      Can only use one initial when one name is given:
      <br />
      {name}
      <InitialsAvatar
        color={select('Color', InitialsAvatar.colors, InitialsAvatar.colors[0])}
        fullName={name}
        size={select(
          'Size',
          InitialsAvatar.sizes,
          InitialsAvatar.defaultProps.size
        )}
      />
    </div>
  )
}

export function twoNamesAvatar() {
  const name = text('name', 'Arya Stark')
  return (
    <div>
      {name}
      <InitialsAvatar
        color={select('Color', InitialsAvatar.colors, InitialsAvatar.colors[0])}
        fullName={name}
        size={select(
          'Size',
          InitialsAvatar.sizes,
          InitialsAvatar.defaultProps.size
        )}
      />
    </div>
  )
}
export function threeNamesAvatar() {
  const name = text('name', 'Arya NoName Stark')
  return (
    <div>
      Initials avatar can only has room for two initials even though 3 names are
      given:
      <br />
      {name}
      <InitialsAvatar
        color={select('Color', InitialsAvatar.colors, InitialsAvatar.colors[0])}
        fullName={name}
        size={select(
          'Size',
          InitialsAvatar.sizes,
          InitialsAvatar.defaultProps.size
        )}
      />
    </div>
  )
}

export function allColors() {
  function randomCharacter() {
    return String.fromCharCode(97 + Math.round(Math.random() * 25))
  }

  const randomNames = []
  for (let i = 0; i < InitialsAvatar.colors.length; i++) {
    const randomNumber = Math.ceil(Math.random() * 3)
    switch (randomNumber) {
      case 1:
        randomNames.push({ id: i, name: `${randomCharacter()}` })
        break
      case 2:
        randomNames.push({
          id: i,
          name: `${randomCharacter()} ${randomCharacter()}`,
        })
        break
      default:
        randomNames.push({
          id: i,
          name: `${randomCharacter()} ${randomCharacter()} ${randomCharacter()}`,
        })
        break
    }
  }

  return (
    <div className="flex flex-wrap justify-between">
      {randomNames.map(({ id, name }) => (
        <div key={id}>
          <InitialsAvatar
            color={
              boolean('student initials', false)
                ? 'purple'
                : InitialsAvatar.colors[
                    parseInt(id) % InitialsAvatar.colors.length
                  ]
            }
            fullName={name}
            size={select(
              'Size',
              InitialsAvatar.sizes,
              InitialsAvatar.defaultProps.size
            )}
          />
        </div>
      ))}
    </div>
  )
}
