import React from 'react'
import ApplicationSidebar from './ApplicationSidebar'
import MockAuthProvider from '../../../../providers/MockAuthProvider'

export default {
  title: 'Leevo UI/ApplicationSidebar',
  component: ApplicationSidebar,
  args: {
    className: 'm-4',
    isAuthLoading: false,
    superuser: false,
    onClose: () => console.log('closing') /* eslint-disable-line no-console */,
  },
}

export function applicationSidebar(args) {
  return (
    <MockAuthProvider
      isLoading={args.isAuthLoading}
      currentUser={{ superuser: args.superuser, facilitiesUsers: [] }}
    >
      <ApplicationSidebar open={args.open} onClose={args.onClose} />
    </MockAuthProvider>
  )
}
