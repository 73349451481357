import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import faker from 'faker'
import { action } from '@storybook/addon-actions'

import SelectKlasses from './SelectKlasses'

export default {
  title: 'Registration Feature/SelectKlasses',
  component: SelectKlasses,
}

// FIXME: This story is broken. Worth fixing?
export function selectKlasses() {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onGet().reply(200, {
    data: generateKlasses(),
  })

  function generateStudents() {
    const students = []
    const skillLevelName = faker.company.bsBuzz()
    for (let i = 0; i < 3; i++) {
      students.push({
        id: i + 1,
        preferredName: faker.name.firstName(),
        currentSkillLevel: {
          id: (i + 1) % 2,
          name: `${skillLevelName} ${(i + 1) % 2}`,
        },
      })
    }
    return students
  }

  function generateKlasses() {
    const klasses = []
    for (let i = 0; i < 2; i++) {
      klasses.push({
        id: i + 1,
        type: 'klass',
        attributes: {
          dayOfWeek: 'Mon',
          timeOfDay: '12:30 PM',
          startDate: 'Jul 11',
          endDate: 'Oct 23',
          remainingKlassDays: 4,
          spotsLeft: 2,
          priceInDollars: '65.99',
          supportedSkillLevels: [{ id: 1, name: '' }],
        },
      })
    }
    return klasses
  }

  return (
    <SelectKlasses
      studentsList={generateStudents()}
      onKlassesSelected={action('Selected Classes')}
    />
  )
}
