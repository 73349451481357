import React from 'react'
import { mount } from 'enzyme'
import { MemoryRouter } from 'react-router'

import MultiStudentDiscount from './MultiStudentDiscount'
import MockFacilityProvider from '../../../providers/MockFacilityProvider'

describe('MultiStudentDiscount', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter>
          <MockFacilityProvider>
            <MultiStudentDiscount />
          </MockFacilityProvider>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
