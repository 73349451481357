import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import CardFormSection from '../../../leevo_ui/CardFormSection/CardFormSection'
import Notification from '../../../leevo_ui/Notification/Notification'
import TextArea from '../../../leevo_ui/TextArea/TextArea'
import CheckboxInput from '../../../leevo_ui/CheckboxInput/CheckboxInput'
import SelectInput from '../../../leevo_ui/SelectInput/SelectInput'
import { presence } from '../../../../utilities/validators'

const ONE_LINER_MAX_LENGTH = 140

function PresentedInfoSection({ values, form, mutable }) {
  return (
    <>
      <CardFormSection
        title="Presented Info"
        description="This information is shown during registration."
      >
        <Notification show={!mutable} color="purple">
          This information cannot be changed once someone has responded. If you
          need to change it, disable this disclosure, and create a new one.
        </Notification>
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-3 space-y-2 sm:col-span-2">
            <Field name="required" type="checkbox">
              {({ input }) => (
                <CheckboxInput
                  {...input}
                  disabled={!mutable}
                  id="required"
                  label="Required?"
                  helpText={
                    input.checked
                      ? 'Registrants are required to agree or cannot register.'
                      : 'Registrants can decline to agree and still register.'
                  }
                  onClick={() => input.onChange(!input.checked)}
                  small
                />
              )}
            </Field>
          </div>
          <div className="col-span-3 space-y-2 sm:col-span-2">
            <Field name="disclosureType">
              {({ input }) => (
                <SelectInput
                  {...input}
                  onChange={(e) => {
                    const { value } = e.target
                    input.onChange(value)
                    if (value === 'one_liner')
                      form.mutators.truncate('agreement', ONE_LINER_MAX_LENGTH)
                  }}
                  disabled={!mutable}
                  id="disclosure-type"
                  className="w-32"
                  options={[
                    { label: 'One-liner', value: 'one_liner' },
                    { label: 'Document', value: 'document' },
                  ]}
                />
              )}
            </Field>
          </div>
          <div className="col-span-3 space-y-2">
            <Field name="agreement" validate={presence}>
              {({ input, meta: { active, error, touched } }) => (
                <TextArea
                  {...input}
                  id="agreement"
                  maxLength={
                    values.disclosureType === 'document'
                      ? null
                      : ONE_LINER_MAX_LENGTH
                  }
                  disabled={!mutable}
                  className="w-full mr-3"
                  rows={values.disclosureType === 'document' ? 10 : 2}
                  type="text"
                  label="Agreement"
                  helpText="This is what registrant accepts."
                  placeholder="I agree to..."
                  errorMessage={!active && error && touched ? error : ''}
                />
              )}
            </Field>
          </div>
        </div>
      </CardFormSection>
    </>
  )
}

PresentedInfoSection.defaultProps = {
  values: {},
  mutable: true,
}

PresentedInfoSection.propTypes = {
  className: PropTypes.string,
  values: PropTypes.object,
  form: PropTypes.object,
  mutable: PropTypes.bool,
}

export default PresentedInfoSection
