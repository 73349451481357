import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { Form } from 'react-final-form'
import axios from 'axios'

import KlassPackageList from '../KlassPackageList/KlassPackageList'
import RegistrationLayout from '../RegistrationLayout/RegistrationLayout'
import LoadingSpinner from '../../leevo_ui/LoadingSpinner/LoadingSpinner'
import { requestCreator } from '../../../utilities/requests'
import useAuth from '../../../utilities/hooks/useAuth'

const { get: getStudents, cancel: cancelStudentsRequest } = requestCreator()

function SelectKlasses({ studentsList, onKlassesSelected }) {
  const [isLoading, setIsLoading] = useState(true)
  const [students, setStudents] = useState([])
  const { currentUser } = useAuth()
  const { facility_id } = useParams()
  const history = useHistory()

  useEffect(() => {
    getStudents(`/api/guardians/${currentUser.id}/students`, {
      params: {
        include:
          'current_skill_level,available_klass_packages,fully_enrolled_klass_packages',
        facility_id,
      },
    })
      .then((students) => {
        if (students.length == 0)
          history.replace(`/facilities/${facility_id}/register`)
        setStudents(
          [...students].map((student) => {
            return {
              ...student,
              selectedKlassPackages: studentsList.find(
                (s) => s.id === student.id
              ).selectedKlassPackages,
            }
          })
        )
        setIsLoading(false)
      })
      .catch((err) => {
        if (!axios.isCancel(err)) history.replace('/500')
      })
    return cancelStudentsRequest
  }, []) /* eslint-disable-line */ // FIXME

  function convertKeysToStudentIds(selectedKlassPackages) {
    const newValues = {}
    Object.keys(selectedKlassPackages).forEach((klassKey) => {
      const [, newKey] = klassKey.split('-')
      newValues[newKey] = selectedKlassPackages[klassKey]
    })
    return newValues
  }

  function initialValues() {
    // The hash that is in students.selectedKlassPackages is just the studentId, but
    // the form field can't just be a numeric string, so we need to transform it
    // into the string that the field will be using.
    return studentsList.reduce((newHash, student) => {
      const selected_packages = student.selectedKlassPackages
      if (selected_packages) {
        newHash[`user-${student.id}-klasses`] = selected_packages.map(
          (selected_package) => ({
            id: selected_package.id,
            title: selected_package.title,
          })
        )
      }
      return newHash
    }, {})
  }

  return (
    <Form
      initialValues={initialValues()}
      onSubmit={(values) => {
        onKlassesSelected(convertKeysToStudentIds(values))
      }}
      render={({ handleSubmit, submitting }) => (
        <RegistrationLayout
          title="Select Your Classes"
          progressPercentage={100}
          onClickNext={handleSubmit}
          isNextDisabled={submitting}
        >
          {isLoading ? (
            <LoadingSpinner
              size="12"
              className="flex items-center justify-center my-10"
            />
          ) : (
            <form onSubmit={handleSubmit}>
              {students.map(
                ({
                  id,
                  preferredName,
                  currentSkillLevel: { name: studentLevel },
                  availableKlassPackages,
                  fullyEnrolledKlassPackages,
                  selectedKlassPackages,
                }) => (
                  <KlassPackageList
                    key={id}
                    studentId={id}
                    studentName={preferredName}
                    studentLevel={studentLevel}
                    availableKlassPackages={availableKlassPackages}
                    enrolledKlassPackages={fullyEnrolledKlassPackages}
                    selectedKlassPackages={selectedKlassPackages}
                  />
                )
              )}
            </form>
          )}
        </RegistrationLayout>
      )}
    />
  )
}

SelectKlasses.defaultProps = {
  studentsList: [],
}

SelectKlasses.propTypes = {
  // FIXME: Specify the shape of objects in the array.
  studentsList: PropTypes.array,
  onKlassesSelected: PropTypes.func.isRequired,
}

export default SelectKlasses
