import React from 'react'
import MultiKlassDiscount from './MultiKlassDiscount'

export default {
  title: 'FacilitiesDiscountsIndex/MultiKlassDiscount',
  component: MultiKlassDiscount,
  args: {
    className: 'm-4',
  },
}

export function multiKlassDiscount(args) {
  return <MultiKlassDiscount {...args} />
}
