import React from 'react'
import DisclaimerFeature from './DisclaimerFeature'
import { shallow } from 'enzyme'

describe('DisclaimerFeature', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(<DisclaimerFeature />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
