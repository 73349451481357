import { select, text } from '@storybook/addon-knobs'
import faker from 'faker'

import React, { useState } from 'react'
import Modal from './Modal'
import Button from '../Button/Button'

import bgPhoto from '../../../images/sample/skating-wallpaper.jpg'

const short = 'Foobar'
const long = faker.lorem.paragraphs(5)

const contentOptions = { Short: short, Long: long }

export default {
  title: 'Leevo UI/Modals/Modal',
  component: Modal,
}

export function ModalStory() {
  const [opened, setOpened] = useState(true)
  return (
    <>
      <img className="max-w-6xl" src={bgPhoto} />
      <div className="fixed w-full h-full" style={{ top: '45%', left: '45%' }}>
        <Button
          color="yellow"
          label="Open Modal"
          className="shadow-hover"
          onClick={() => setOpened(true)}
        />
      </div>
      <Modal
        headerColor={select(
          'Color',
          Modal.headerColors,
          Modal.defaultProps.headerColor
        )}
        open={opened}
        header={text('Header Text', 'Some Header')}
        onClickBackground={() => setOpened(false)}
        onClickClose={() => setOpened(false)}
      >
        <div>{select('Content', contentOptions, short)}</div>
      </Modal>
    </>
  )
}
