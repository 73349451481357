import React from 'react'
import { mount } from 'enzyme'

import { MemoryRouter, Route } from 'react-router-dom'

import MainFeaturesSection from './MainFeaturesSection'

describe('MainFeaturesSection', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/MainFeaturesSection']}>
          <Route path="/MainFeaturesSection">
            <MainFeaturesSection />
          </Route>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
