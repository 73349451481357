import React from 'react'
import { mount } from 'enzyme'

import { MemoryRouter, Route } from 'react-router-dom'

import FeatureGrid from './FeatureGrid'

describe('FeatureGrid', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/FeatureGrid']}>
          <Route path="/FeatureGrid">
            <FeatureGrid />
          </Route>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
