import React from 'react'
import CircleButton from './CircleButton'
import { shallow } from 'enzyme'

describe('CircleButton', () => {
  let wrapper
  beforeEach(() => {
    wrapper = shallow(<CircleButton />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
