import React from 'react'
import { shallow } from 'enzyme'

import StudentSkills from './StudentSkills'
import SkillButton from '../../leevo_ui/SkillButton/SkillButton'

describe('StudentSkills', () => {
  let wrapper

  describe('StudentSkills with no data', () => {
    beforeEach(() => {
      wrapper = shallow(<StudentSkills skills={[]} />)
    })
    test('renders', () => {
      expect(wrapper).toExist()
    })
    test('renders with no skills', () => {
      expect(wrapper.find(SkillButton).length).toEqual(0)
    })
  })

  describe('StudentSkills with skills', () => {
    const skills = [
      { id: '1', shortLabel: 'A', status: 'introduced', loading: false },
      { id: '2', shortLabel: 'B', status: 'neutral', loading: false },
      { id: '3', shortLabel: 'C', status: 'completed', loading: false },
      { id: '4', shortLabel: 'D', status: 'introduced', loading: false },
      { id: '5', shortLabel: 'E', status: 'completed', loading: false },
      { id: '6', shortLabel: 'F', status: 'introduced', loading: false },
    ]
    beforeEach(() => {
      wrapper = shallow(<StudentSkills skills={skills} />)
    })

    test('renders with 6 skills', () => {
      expect(wrapper.find(SkillButton).length).toEqual(6)
    })
  })
})
