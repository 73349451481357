import React from 'react'
import CookiePolicyFeature from './CookiePolicyFeature'

export default {
  title: 'CookiePolicyFeature',
  component: CookiePolicyFeature,
  args: {
    className: 'm-4',
  },
}

export function cookiePolicyFeature(args) {
  return <CookiePolicyFeature {...args} />
}
