import React from 'react'
import IndexHeader from './IndexHeader'

export default {
  title: 'Leevo UI/IndexHeader',
  component: IndexHeader,
  args: {
    title: 'Students',
    onAddClick: () => console.log('clicked') /* eslint-disable-line */,
  },
}

export function indexHeader(args) {
  return <IndexHeader {...args} />
}
