import React from 'react'
import PropTypes from 'prop-types'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { useParams, Redirect, useHistory } from 'react-router-dom'

import RegistrationLayout from '../RegistrationLayout/RegistrationLayout'
import Button from '../../leevo_ui/Button/Button'
import useAuth from '../../../utilities/hooks/useAuth'
import { priceInDollars } from '../../../utilities/helpers'

function PaymentConfirmation({
  lineItems,
  total,
  couponCodeAmount,
  couponCodePromotionAmount,
}) {
  const history = useHistory()
  const { width, height } = useWindowSize()
  const { isSignedIn, currentUser } = useAuth()
  let { facility_id } = useParams()

  const redirect = !isSignedIn || !lineItems || lineItems.length === 0

  return redirect ? (
    <Redirect to={`/facilities/${facility_id}/register`} />
  ) : (
    <>
      <Confetti
        width={width}
        height={height}
        recycle={false}
        initialVelocityY={-20}
      />
      <RegistrationLayout
        title="You're all done!"
        progressPercentage={100}
        isNextDisabled={true}
        className="m-4"
      >
        <table className="w-full table-fixed">
          <tbody>
            <tr className="border-b border-purple-900">
              <td className="w-2/3 py-2">
                {/* FIXME: Your payment to <facility_name> is probably ideal here. */}
                <h2 className="font-semibold text-gray-900">
                  Your payment is complete!
                </h2>
                <h3 className="mb-6 text-sm text-gray-900">
                  {"Here's what you've purchased:"}
                </h3>
              </td>
              <td className="w-1/3 px-4 py-2"></td>
            </tr>
            {lineItems.map((lineItem) => (
              <tr
                key={lineItem.id}
                className="text-sm font-medium border-t border-gray-400 first:border-none"
              >
                <td className="px-4 py-2">
                  <p className="leading-tight">{lineItem.description}</p>
                </td>
                <td className="px-4 py-2 text-right">
                  <p>${(lineItem.price / 100.0).toFixed(2)}</p>
                </td>
              </tr>
            ))}
            {couponCodeAmount && (
              <tr className="text-sm font-medium border-t border-gray-400 first:border-none text-green-700">
                <td className="px-4 py-2">
                  <p className="leading-tight">
                    Coupon Code for {couponCodePromotionAmount} off
                  </p>
                </td>
                <td
                  className="px-4 py-2 text-right"
                  data-cy="coupon-code-amount"
                >
                  <p>-${priceInDollars(couponCodeAmount)}</p>
                </td>
              </tr>
            )}
            {/* FIXME: should be <tfoot> */}
            <tr className="font-semibold border-t border-purple-900">
              <td className="px-4 py-2">Total</td>
              <td className="px-4 py-2 text-right">
                <p>${(total / 100.0).toFixed(2)}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="flex flex-col items-center justify-center mt-10 text-sm md:text-base">
          <p>You will receive an email receipt shortly</p>
          <p>
            Or,{' '}
            <button
              className="text-purple-600 hover:text-purple-400 cursor-pointer"
              onClick={window.print}
            >
              print this page
            </button>{' '}
            for your records. 🖨
          </p>
          <Button
            className="mt-10"
            label="Go to your student dashboard"
            onClick={() =>
              history.push(`/guardians/${currentUser.id}/students`)
            }
            data-cy="parent-dashboard-call-to-action"
          />
        </div>
      </RegistrationLayout>
    </>
  )
}

PaymentConfirmation.propTypes = {
  lineItems: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  couponCodeAmount: PropTypes.number,
  couponCodePromotionAmount: PropTypes.string,
}

export default PaymentConfirmation
