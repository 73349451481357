import React from 'react'
import CheckoutForm from './CheckoutForm'
import { mount } from 'enzyme'
import { MemoryRouter, Route } from 'react-router-dom'

// FIXME: Add specs for this file.  Did not write immediately, since E2E tests
// handle the most important cases for this file.
describe('CheckoutForm', () => {
  let wrapper
  beforeEach(() => {
    wrapper = mount(
      <MemoryRouter initialEntries={['/foo']} initialIndex={0}>
        <Route
          path="/foo"
          render={() => (
            <CheckoutForm id="id" type="text" onPaymentProcessed={jest.fn()} />
          )}
        />
      </MemoryRouter>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
