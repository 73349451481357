import React from 'react'
import { shallow } from 'enzyme'

import ResponsesSection from './ResponsesSection'
import MockFacilityProvider from '../../../providers/MockFacilityProvider'

describe('ResponsesSection', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(
        <MockFacilityProvider>
          <ResponsesSection />
        </MockFacilityProvider>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
