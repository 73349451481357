import React from 'react'
import { mount } from 'enzyme'

import MultiSelectStudent from './MultiSelectStudent'

describe('MultiSelectStudent', () => {
  let wrapper

  beforeEach(() => {
    wrapper = mount(<MultiSelectStudent value={[]} onChange={() => {}} />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
