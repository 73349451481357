import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  CashIcon,
  CogIcon,
  CubeIcon,
  CubeTransparentIcon,
  DocumentIcon,
  DocumentReportIcon,
  LogoutIcon,
  OfficeBuildingIcon,
  PencilIcon,
  TicketIcon,
  UserGroupIcon,
  UserIcon,
  XIcon,
} from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid'

import useAuth from '../../../../utilities/hooks/useAuth'

function ApplicationSidebar({ open, onClose }) {
  const {
    isLoading: isAuthLoading,
    currentUser: { facilitiesUsers, students, id, superuser },
  } = useAuth()

  function navigationLink(item) {
    return (
      <NavLink
        key={item.name}
        to={item.destination}
        onClick={onClose}
        activeClassName="bg-purple-900 text-white opacity-95 shadow-inner"
        className="flex items-center px-3 py-2 text-sm font-medium text-purple-300 rounded hover:text-purple-200 group"
      >
        <item.icon
          className="flex-shrink-0 w-6 h-6 mr-3 text-purple-300 group-hover:text-purple-300"
          aria-hidden="true"
        />
        {item.name}
      </NavLink>
    )
  }

  function skeletonLink() {
    return (
      <div className="flex items-center px-3 py-2 rounded">
        <div className="w-5 h-5 mr-2 bg-purple-300 rounded-md animate-pulse" />
        <div className="h-4 px-2 bg-purple-300 rounded-md w-36 animate-pulse" />
      </div>
    )
  }

  function skeletonLinks() {
    return (
      <>
        {skeletonLink()}

        <div className="py-2 border-t border-purple-300">
          <div className="h-6 px-2 bg-purple-300 rounded-md w-36 animate-pulse" />
        </div>
        {Array.from({ length: 5 }).map(() => skeletonLink())}
      </>
    )
  }

  function sidebarContent() {
    return isAuthLoading ? (
      skeletonLinks()
    ) : (
      <>
        {superuser && (
          <div className="py-2 border-t border-purple-300">
            <h3 className="font-medium text-purple-300">
              ⚡️ God mode links! ⚡️
            </h3>
            {superUserLinks()}
          </div>
        )}
        {navigationLinks()}
        {facilitiesUsers.map((facilitiesUser) => (
          <div
            className="py-2 border-t border-purple-300"
            key={facilitiesUser.id}
          >
            <h3 className="font-medium text-purple-300">
              {facilitiesUser.facility.name}
            </h3>
            {facilityNavigationLinks(facilitiesUser)}
          </div>
        ))}
      </>
    )
  }

  function navigationLinks() {
    const navigation = []
    if (students && students.length > 0) {
      navigation.push(
        navigationLink({
          name: 'Student Dashboard',
          destination: `/guardians/${id}/students`,
          icon: UserGroupIcon,
        })
      )
    }

    return navigation
  }

  function superUserLinks() {
    const navigation = [
      navigationLink({
        name: 'Guardian Lookup',
        destination: '/admin/guardians',
        icon: SearchIcon,
      }),
      navigationLink({
        name: 'LTS Reports',
        destination: '/admin/lts_reports',
        icon: DocumentReportIcon,
      }),
      navigationLink({
        name: 'Facilities',
        destination: '/admin/facilities',
        icon: OfficeBuildingIcon,
      }),
    ]

    return navigation
  }

  function secondaryNavigationLinks() {
    const secondaryNavigation = [
      { name: 'Profile', destination: '/profile', icon: UserIcon },
      { name: 'Logout', destination: '/logout', icon: LogoutIcon },
    ]

    return secondaryNavigation.map(navigationLink)
  }

  function facilityNavigationLinks({
    coach,
    admin,
    facility: { id, providesFreeRentalSkates },
  }) {
    const facilitiesLinks = []
    const baseUrl = `/facilities/${id}`

    if (coach || admin) {
      facilitiesLinks.push({
        name: 'Calendar',
        destination: `${baseUrl}/calendar`,
        icon: CalendarIcon,
      })
    }

    if (admin) {
      facilitiesLinks.push([
        { name: 'Staff', destination: `${baseUrl}/staff`, icon: CalendarIcon },
        { name: 'Settings', destination: `${baseUrl}/edit`, icon: CogIcon },
        {
          name: 'Classes',
          destination: `${baseUrl}/classes`,
          icon: CubeTransparentIcon,
        },
        {
          name: 'Class Packages',
          destination: `${baseUrl}/class_packages`,
          icon: CubeIcon,
        },
        {
          name: 'Students',
          destination: `${baseUrl}/students`,
          icon: UserGroupIcon,
        },
        {
          name: 'Discounts',
          destination: `${baseUrl}/discounts`,
          icon: CashIcon,
        },
        {
          name: 'Disclosures',
          destination: `${baseUrl}/disclosures`,
          icon: DocumentIcon,
        },
      ])

      if (providesFreeRentalSkates) {
        facilitiesLinks.push({
          name: 'Skate Rentals',
          destination: `${baseUrl}/skate_rentals`,
          icon: TicketIcon,
        })
      }
    }

    facilitiesLinks.push({
      name: 'Register Students',
      destination: `${baseUrl}/register`,
      icon: PencilIcon,
    })

    return facilitiesLinks.flat().map(navigationLink)
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex lg:hidden print:hidden"
          onClose={onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div
              className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-purple-700"
              data-cy="mobile-menu"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 p-1 -mr-14">
                  <button
                    type="button"
                    className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:bg-gray-600"
                    onClick={onClose}
                    data-cy="close-sidebar-button"
                  >
                    <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
                    <span className="sr-only">Close sidebar</span>
                  </button>
                </div>
              </Transition.Child>
              <div className="flex items-center flex-shrink-0 px-6">
                <span className="text-3xl text-white font-wordmark">Leevo</span>
              </div>
              <div className="flex-1 h-0 mt-5 overflow-y-auto">
                <nav className="flex flex-col h-full px-2">
                  <div className="flex-1 space-y-1">{sidebarContent()}</div>
                  <div className="flex-shrink-0 block w-full pt-5 pb-20">
                    {secondaryNavigationLinks()}
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0 print:hidden">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <nav className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto bg-purple-700 border-r border-purple-900">
            <div className="flex items-center flex-shrink-0 px-6">
              <span className="text-3xl text-white font-wordmark">Leevo</span>
            </div>
            <div className="flex flex-col flex-grow px-2 mt-5">
              <div className="flex-1 space-y-1">{sidebarContent()}</div>
            </div>
            <div className="flex-shrink-0 block w-full px-2 pb-20">
              {secondaryNavigationLinks()}
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

ApplicationSidebar.defaultProps = {
  open: false,
  onClose: () => {},
}

ApplicationSidebar.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ApplicationSidebar
