import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import CardFormSection from '../../../leevo_ui/CardFormSection/CardFormSection'
import Input from '../../../leevo_ui/Input/Input'
import CheckboxInput from '../../../leevo_ui/CheckboxInput/CheckboxInput'
import { presence } from '../../../../utilities/validators'

function InternalInfoSection() {
  return (
    <>
      <CardFormSection
        title="Internal Info"
        description="For your reference. Not shown to registrants."
        showSubmit={false}
      >
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-3 sm:col-span-2">
            <Field name="name" validate={presence}>
              {({ input, meta: { active, error, touched } }) => (
                <Input
                  {...input}
                  id="name"
                  autoFocus={!input.value}
                  className="w-full mr-3"
                  type="text"
                  label="Name"
                  helpText="For your reference. Not shown to registrants."
                  errorMessage={!active && error && touched ? error : ''}
                />
              )}
            </Field>
            <Field name="active" type="checkbox">
              {({ input }) => (
                <CheckboxInput
                  {...input}
                  id="active"
                  label="Active?"
                  helpText={
                    input.checked
                      ? 'Registrants will be presented with this disclosure.'
                      : 'Registrants will not be presented with this disclosure.'
                  }
                  onClick={() => input.onChange(!input.checked)}
                  small
                />
              )}
            </Field>
          </div>
        </div>
      </CardFormSection>
    </>
  )
}

InternalInfoSection.defaultProps = {}

InternalInfoSection.propTypes = {
  className: PropTypes.string,
}

export default InternalInfoSection
