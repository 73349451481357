import React from 'react'
import RefundPolicyFeature from './RefundPolicyFeature'
import { shallow } from 'enzyme'

describe('RefundPolicyFeature', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(<RefundPolicyFeature />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
