import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { debounce as _debounce } from 'lodash'
import axios from 'axios'
import { get as _get } from 'lodash'

import LoginFields from './LoginFields/LoginFields'
import SlideInTransition from '../../leevo_ui/Transitions/SlideInTransition/SlideInTransition'
import ForgotPasswordModal from '../../leevo_ui/ForgotPasswordModal/ForgotPasswordModal'
import { isEmail } from '../../../utilities/validators'

function EmailInfo({ setNextButtonDisabled }) {
  const [hasAccount, setHasAccount] = useState(false)
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [email, setEmail] = useState('')

  const handleEmailChange = _debounce(
    (email) => {
      if (email && isEmail(email) !== 'Email must be valid.') {
        axios.get('/api/users', { params: { email } }).then((response) => {
          setIsEmailValid(true)
          if (response.status === 200) {
            setHasAccount(true)
          } else if (response.status === 204) {
            setHasAccount(false)
            setNextButtonDisabled(false)
          }
        })
      } else {
        setHasAccount(false)
        setNextButtonDisabled(true)
        setIsEmailValid(false)
      }
      setEmail(email)
    },
    300,
    { leading: true }
  )

  // FIXME: This is copy/pasted from ForgotPasswordFeature
  // Should probably be in the modal so it doesn't need to be redefined in
  // multiple places.
  function onForgotPasswordSubmit({ email }) {
    setSuccessMessage('')
    setErrorMessage('')
    return axios
      .post('/auth/password', {
        email,
        redirect_url: `${window.location.origin}/password/new`,
      })
      .then(({ data }) => setSuccessMessage(data.message))
      .catch((error) => {
        if (_get(error, 'response.data.errors')) {
          setErrorMessage(error.response.data.errors.join(' '))
        } else {
          setErrorMessage(error.toString())
        }
      })
  }

  return (
    <>
      <SlideInTransition>
        <LoginFields
          onForgotPasswordClick={() => setShowForgotPassword(true)}
          onEmailChange={handleEmailChange}
          onPasswordChange={(password) => setNextButtonDisabled(!password)}
          hasAccount={hasAccount}
          isEmailValid={isEmailValid}
        />
      </SlideInTransition>
      <ForgotPasswordModal
        open={showForgotPassword}
        initialEmail={email}
        errorMessage={errorMessage}
        onForgotPasswordSubmit={onForgotPasswordSubmit}
        successMessage={successMessage}
        onClickBackground={() => setShowForgotPassword(false)}
        onClickClose={() => setShowForgotPassword(false)}
      />
    </>
  )
}

EmailInfo.defaultProps = {}

EmailInfo.propTypes = {
  setNextButtonDisabled: PropTypes.func.isRequired,
}

export default EmailInfo
