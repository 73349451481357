import React from 'react'
import { mount } from 'enzyme'
import moment from 'moment'
import { MemoryRouter, Route } from 'react-router-dom'

import EmptyList from '../../leevo_ui/EmptyList/EmptyList'
import KlassDaysList from './KlassDaysList'
import MockFacilityProvider from '../../../providers/MockFacilityProvider'

describe('KlassDaysList', () => {
  let wrapper

  const klasses = [
    {
      id: 1,
      coaches: [
        {
          id: 1,
          fullName: 'Emily Seibert',
          preferredName: 'Emily',
          cloudinaryPhotoPublicId: 'fake',
        },
      ],
      startDateTime: moment('2019-12-28T19:43:25-07:00'),
      students: [
        {
          fullName: 'Foo Bar',
          preferredName: 'Foo',
          currentSkillLevel: { id: 1, name: 'Basic 1' },
        },
      ],
      skillLevels: ['Basic 1'],
    },
    {
      id: 2,
      coaches: [
        {
          id: 2,
          fullName: 'John Murray',
          preferredName: 'John',
          cloudinaryPhotoPublicId: 'fake',
        },
      ],
      startDateTime: moment('2019-12-28T19:43:25-07:00'),
      students: [
        {
          fullName: 'Bar Baz',
          preferredName: 'Bar',
          currentSkillLevel: { id: 1, name: 'Basic 2' },
        },
      ],
      skillLevels: ['Basic 2'],
    },
  ]

  beforeEach(() => {
    wrapper = mount(
      <MemoryRouter>
        <MockFacilityProvider>
          <KlassDaysList coachClasses={[]} selectedDate={moment()} />
        </MockFacilityProvider>
      </MemoryRouter>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('coachClasses is empty with no classes', () => {
    expect(wrapper.find(EmptyList)).toExist()
  })

  test('when provided list of klasses it displays the list', () => {
    wrapper = mount(
      <MemoryRouter initialEntries={['/1']}>
        <Route path="/:facility_id">
          <MockFacilityProvider>
            <KlassDaysList coachClasses={klasses} selectedDate={moment()} />
          </MockFacilityProvider>
        </Route>
      </MemoryRouter>
    )
    const klassList = wrapper.find('[data-cy="class-list"]').hostNodes()
    expect(klassList).toExist(1)
  })
})
