import React from 'react'
import PropTypes from 'prop-types'

import InitialsAvatar from './InitialsAvatar/InitialsAvatar'
import CirclePhoto from './CirclePhoto/CirclePhoto'
import { createUploadWidget } from '../../../utilities/cloudinary'

export default function Avatar({
  alt,
  className,
  fullName,
  isStudent,
  size,
  cloudinaryPhotoPublicId,
  userId,
  onPhotoUpload,
}) {
  let uploadWidget = { open: null }
  if (onPhotoUpload) {
    uploadWidget = createUploadWidget((_error, result) => {
      if (result.event == 'success') onPhotoUpload(result.info.public_id)
    })
  }

  const color = isStudent
    ? 'purple'
    : InitialsAvatar.colors[parseInt(userId) % InitialsAvatar.colors.length]

  if (cloudinaryPhotoPublicId || !fullName) {
    return (
      <CirclePhoto
        alt={alt}
        className={className}
        size={size}
        cloudinaryPhotoPublicId={cloudinaryPhotoPublicId}
        onClick={uploadWidget.open}
      />
    )
  } else {
    return (
      <InitialsAvatar
        className={className}
        fullName={fullName}
        color={color}
        size={size}
        onClick={uploadWidget.open}
      />
    )
  }
}

Avatar.sizes = [5, 8, 10, 12, 16, 24]

Avatar.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  fullName: PropTypes.string,
  isStudent: PropTypes.bool,
  size: PropTypes.oneOf(Avatar.sizes),
  cloudinaryPhotoPublicId: PropTypes.string,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onPhotoUpload: PropTypes.func,
}

Avatar.defaultProps = {
  alt: 'Photo',
  isStudent: false,
  size: 24,
}
