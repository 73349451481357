import React from 'react'
import PropTypes from 'prop-types'

import RightDrawer from '../../leevo_ui/RightDrawer/RightDrawer'
import SkillLevelList from '../../leevo_ui/SkillLevelList/SkillLevelList'
import SkillSelector from '../../leevo_ui/SkillSelector/SkillSelector'
import bookIcon from '../../../images/book-open-solid.svg'

function SkillDrawer({
  skillLevels,
  selectedCategory,
  skillCategories,
  onCategorySelected,
  className,
}) {
  return (
    <RightDrawer className={className} buttonIcon={bookIcon}>
      {skillCategories.length > 1 && (
        <div className="flex justify-center items-center my-1 sticky top-0">
          <SkillSelector
            skillCategories={skillCategories}
            selectedCategory={selectedCategory}
            onCategorySelected={onCategorySelected}
          />
        </div>
      )}
      <SkillLevelList
        skillCategories={skillCategories}
        selectedCategory={selectedCategory}
        skillLevels={skillLevels}
      />
    </RightDrawer>
  )
}

SkillDrawer.defaultProps = {
  skillLevels: [],
  skillCategories: [],
  className: '',
}

SkillDrawer.propTypes = {
  skillLevels: PropTypes.array.isRequired,
  className: PropTypes.string,
  selectedCategory: PropTypes.string,
  skillCategories: PropTypes.array,
  onCategorySelected: PropTypes.func.isRequired,
}

export default SkillDrawer
