import React from 'react'
import { action } from '@storybook/addon-actions'
import { boolean } from '@storybook/addon-knobs'
import { Form } from 'react-final-form'

import LoginFields from './LoginFields'

export default {
  title: 'Registration Feature/Email Info/LoginFields',
  component: LoginFields,
}

export function loginFields() {
  return (
    <Form
      render={() => (
        <LoginFields
          onForgotPasswordClick={action('Forgot password click')}
          onEmailChange={action('Changed Email')}
          hasAccount={boolean('Has Account?', false)}
          isEmailValid={boolean('Is email valid?', false)}
          onPasswordChange={action('Changed Password')}
        />
      )}
      onSubmit={action('submit')}
    />
  )
}
