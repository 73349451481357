import React from 'react'
import { text } from '@storybook/addon-knobs'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { MemoryRouter, Route } from 'react-router-dom'
import MockFacilityProvider from '../../providers/MockFacilityProvider'

import faker from 'faker'

import FacilitiesKlassPackagesIndex from './FacilitiesKlassPackagesIndex'

export default {
  title: 'Facilities Klass Packages Index',
  component: FacilitiesKlassPackagesIndex,
}

export function facilitiesKlassPackagesIndex() {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onGet('/api/facilities/1').reply(200, {
    data: {
      id: 1,
      type: 'facility',
      attributes: {
        name: text('Returned Facility Name', faker.company.companyName()),
      },
    },
  })
  mockAxios.onGet('/api/facilities/1/class_packages').reply(200, {
    data: [
      {
        attributes: {
          title: 'Sundays - 6:15 PM',
          description:
            'Classes run on Sundays from Oct 4, 2020 to Oct 11, 2020 at 6:15 PM.',
          active: true,
        },
        id: '1',
        relationships: { students: { data: [] } },
        type: 'klassPackage',
      },
      {
        attributes: {
          title: 'Mondays - 6:15 PM (Times Vary) (Basic 1-3 Only)',
          description:
            'Classes run on Mondays from Oct 4, 2020 to Oct 11, 2020 at 6:15 PM.',
          active: false,
        },
        id: '2',
        relationships: { students: { data: [] } },
        type: 'klassPackage',
      },
      {
        attributes: {
          title: 'Mondays - 6:15 PM',
          description:
            'Classes run on Mondays from Oct 4, 2020 to Oct 11, 2020 at 6:15 PM.',
          active: false,
        },
        id: '3',
        relationships: { students: { data: [] } },
        type: 'klassPackage',
      },
      {
        attributes: {
          title: 'Sundays - 6:15 PM',
          description:
            'Classes run on Sundays from Oct 4, 2020 to Oct 11, 2020 at 6:15 PM.',
          active: true,
        },
        id: '4',
        relationships: { students: { data: [] } },
        type: 'klassPackage',
      },
      {
        attributes: {
          title: 'Mondays - 6:15 PM',
          description:
            'Classes run on Mondays from Oct 4, 2020 to Oct 11, 2020 at 6:15 PM.',
          active: false,
        },
        id: '5',
        relationships: { students: { data: [] } },
        type: 'klassPackage',
      },
      {
        attributes: {
          title: 'Mondays - 6:15 PM',
          description:
            'Classes run on Mondays from Oct 4, 2020 to Oct 11, 2020 at 6:15 PM. Classes run on Mondays from Oct 4, 2020 to Oct 11, 2020 at 6:15 PM.',
          active: false,
        },
        id: '6',
        relationships: { students: { data: [] } },
        type: 'klassPackage',
      },
    ],
    included: [],
  })

  return (
    <MemoryRouter initialEntries={['/facilities/1/class_packages']}>
      <MockFacilityProvider>
        <Route path="/facilities/:facility_id/class_packages">
          <FacilitiesKlassPackagesIndex />
        </Route>
      </MockFacilityProvider>
    </MemoryRouter>
  )
}

export function FacilitiesKlassPackagesIndexWithNoKlasses() {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onGet('/api/facilities/1').reply(200, {
    data: {
      id: 1,
      type: 'facility',
      attributes: {
        name: text('Returned Facility Name', faker.company.companyName()),
      },
    },
  })
  mockAxios.onGet('/api/facilities/1/class_packages').reply(200, {
    data: [],
    included: [],
  })

  return (
    <MemoryRouter initialEntries={['/facilities/1/class_packages']}>
      <MockFacilityProvider>
        <Route path="/facilities/:facility_id/class_packages">
          <FacilitiesKlassPackagesIndex />
        </Route>
      </MockFacilityProvider>
    </MemoryRouter>
  )
}
