import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button/Button'

function CardFormSection({
  title,
  description,
  children,
  submitting,
  showSubmit,
}) {
  return (
    <>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {title}
            </h3>
            <p className="mt-1 text-xs text-gray-600">{description}</p>
          </div>
        </div>

        <div className="mt-5 md:mt-0 md:col-span-2">
          <div className="shadow rounded-lg">
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              {children}
            </div>
            {showSubmit && (
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <Button
                  data-cy="submit"
                  type="submit"
                  label="Save"
                  disabled={submitting}
                  loading={submitting}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="last:hidden" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </>
  )
}

CardFormSection.defaultProps = {
  showSubmit: true,
}

CardFormSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  children: PropTypes.node,
  submitting: PropTypes.bool,
  showSubmit: PropTypes.bool,
}

export default CardFormSection
