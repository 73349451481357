import React from 'react'
import { mount } from 'enzyme'

import InitialsAvatar from './InitialsAvatar'

describe('InitialsAvatar', () => {
  let wrapper
  beforeEach(() => {
    wrapper = mount(<InitialsAvatar fullName="Arya Stark" />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders className on topmost div', () => {
    wrapper.setProps({ className: 'bar' })
    expect(wrapper.first('div')).toHaveClassName('bar')
  })

  test('Only shows first and last initials for 3 or more names', () => {
    wrapper.setProps({ fullName: 'Arya NoName Stark' })

    expect(wrapper.find('svg').text()).toEqual('AS')
  })

  test('Only shows first initial for 1 name', () => {
    wrapper.setProps({ fullName: 'Arya' })

    expect(wrapper.find('svg').text()).toEqual('A')
  })

  test('renders at size 10 when size is 10', () => {
    wrapper.setProps({ fullName: 'Arya', size: 10 })

    expect(wrapper.find('.h-10.w-10')).toExist()
  })

  test('renders at size 12 when size is 12', () => {
    wrapper.setProps({ fullName: 'Arya', size: 12 })

    expect(wrapper.find('.h-12.w-12')).toExist()
  })

  test('renders at size 16 when size is 16', () => {
    wrapper.setProps({ fullName: 'Arya', size: 16 })

    expect(wrapper.find('.h-16.w-16')).toExist()
  })

  test('renders at size 24 when size is 24', () => {
    wrapper.setProps({ fullName: 'Arya', size: 24 })

    expect(wrapper.find('.h-24.w-24')).toExist()
  })

  test('uses color classes passed in', () => {
    wrapper.setProps({
      fullName: 'Arya',
      color: 'green',
    })

    expect(wrapper.find('.bg-green-100.text-green-600')).toExist()
  })
})
