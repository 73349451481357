import React from 'react'

import ContentPageLayout from '../../../ContentPageLayout/ContentPageLayout'
import mixpanel from '../../../../../utilities/mixpanel'
import { sendCrispMessage } from '../../../../../utilities/crisp'

function HandlingLTSRegistrations() {
  return (
    <ContentPageLayout
      category="Learn To Skate Integration"
      title="How to automate your LTS Registrations"
      intro="If your facility or program uses Learn to Skate USA's curriculum and insurance, you have a lot of things to do to keep your program compliant. Leevo automates all of that away, so you can focus growing your program and teaching skating. Here's how."
    >
      <section>
        <p>
          When using the LTSUSA curriculum, you need to do two things to stay
          compliant and ensure you have continued secondary liability insurance
          coverage from LTSUSA:
        </p>
        <ol>
          <li>
            <strong>Registrations</strong> - Make sure that every student on the
            ice has a valid LTS membership as quickly as possible.
          </li>
          <li>
            <strong>Renewals</strong> - That when they reregister in the next
            fiscal year, their registration is renewed.
          </li>
        </ol>
      </section>
      <p>
        There are four ways that directors can manually process LTS fees from
        their members for registrations and renewals.
      </p>
      <ol>
        <li>
          <strong>Burden parents</strong> - parents handle registrations on
          their own
        </li>
        <li>
          <strong>Burden the program</strong> - roll the fees into your costs
        </li>
        <li>
          <strong>Charge parents</strong> - charge parents extra for LTS numbers
          when needed
        </li>
        <li>
          <strong>Automate it with Leevo</strong> - Leevo handles everything,
          you do nothing.
        </li>
      </ol>
      <p>
        Let&apos;s cover each of these options in depth, and explore how they
        can work in your program.
      </p>
      <h3>Burden parents</h3>
      <p>
        With this method, tell parent that they need to get a Learn to Skate
        membership before they register for classes with you.
      </p>
      <p>
        Theoretically, this approach should unburden the director because
        parents should be able to handle the registrations, right?
      </p>
      <p> But that almost never happens in practice.</p>
      <p>
        For new parents, they get confused why they have to register in multiple
        places. So much so that they never register at all. If they do register,
        you need a way of collecting those LTS numbers from parents to ensure
        they are valid, then check them against valid LTS memberships in the
        LTSUSA system.
      </p>
      <p>
        Needless to say, despite &quot;parents doing all the work&quot;, this
        situation usually results in confused parents and an overworked
        director.
      </p>
      <h3>Burden the program</h3>
      <p>
        Since burdening the parent with registrations actually makes{' '}
        <em>more</em> work for the director, many programs decide to take the
        full burden on themselves.
      </p>
      <p>
        This means increasing the cost of classes so that you can cover the
        costs of LTS fees every fiscal year. So, instead of charging $100 for a
        class, you may charge $120 so that you can cover the LTS fee, plus a
        little extra to handle hiring someone to process the fees.
      </p>
      <p>
        The primary benefit of this approach is that you don&apos;t need to
        confuse parents with the LTS fee, and make more work for yourself cross
        referencing numbers that parents provide on registration. Also, it
        avoids confusion for new parents, who won&apos;t understand why they
        need this number at all.
      </p>
      <p>
        The main drawback to this approach is that it puts an incredible burden
        on the director to manage all LTS registrations, often taking over 20,
        30 or 40 hours per week to rifle through the registrations to see who
        needs to be registered, who needs to be renewed, and who is already up
        to date.
      </p>
      <p>
        Even for a full time director, this is a massive investment of time ...
        and most directors are volunteers or part time employees!
      </p>
      <p>
        Aside from that, it places a financial bookeeping burden on the program.
        At the end of each membership year, you need to pay for every LTS
        registration for all skaters.
      </p>
      <p>
        For example, if your program has 100 students, and all of them need to
        be registered, you need to pay $1,650.00 come July 1st for all of those
        registrations.
      </p>
      <p>
        To avoid that burden, you often have to charge parents more than you
        would like for your classes to make sure you have enough money in your
        account to cover LTS fees at the end of the membership year, as well.
      </p>
      <h3>Charge Parents</h3>
      <p>
        Burdening the parent with registrations creates a lot of work for the
        director and the parent, and causes parents to ultimately turn off the
        skating.
      </p>
      <p>
        Burdening the director doesn&apos;t really help reduce busywork for the
        director, but does shelter parents from knowing the nitty gritty on
        LTSUSA memberships.
      </p>
      <p>
        So, maybe, a better solution is to charge parents for an LTS number, but
        only when they need it.
      </p>
      <p>
        The benefit to this approach is that you won&apos;t overcharge parents
        who don&apos;t need LTSUSA registrations.
      </p>
      <p>
        The downside to this approach, like the others, is that it takes
        considerable work. Since Leevo is the only software that checks the
        status of LTS USA fees, an off-the-shelf solution like Wix or Shopify
        can&apos;t charge people for LTS numbers selectively. So you&apos;re
        stuck collecting payments in person, via cash or check, keeping track of
        who has an LTS number. Then at the start of every session, you need to
        see if registrants need a new one and collect that charge separately,
        and make sure to charge the parent the appropriate amount.
      </p>
      <p>
        Needless to say, handling cash and cross referencing tables and
        spreadsheets to see who has a current LTS number is no small feat. On
        average, this approach can also take a director 20-40 hours/week in a
        program with as few as 50 students.
      </p>
      <h3>Automate it with Leevo</h3>
      <p>
        Leevo takes an approach that&apos;s similar to the{' '}
        <em>Charge Parents</em> approach.
      </p>
      <p>
        When someone registers through Leevo, the registration forms asks if
        they have a LTS number, and lets them know there&apos;s a once-per-year
        charge in order to complete the registration.
      </p>
      <p>
        If they say they don&apos;t have a number, Leevo automatically gets a
        new LTS number for the student. This new student and their number will
        populate in your program&apos;s Learn to Skate USA account.
      </p>
      <p>
        If they say do have a number, Leevo collects the number that they have.
        If the number is valid, we refund the fee we collect. If it is not
        valid, we renew their existing number, or get them a new one.
      </p>
      <p>
        This makes it easy to charge parents for the fee directly, with minimal
        effort and with no confusion. Tou don&apos;t need to do accounting magic
        tricks or spend loads of time taking care of ensuring compliance with
        LTSUSA&apos;s rules.
      </p>
      <p>
        It also means parents never pay more than they should for memberships
        they don&apos;t need, and that your program doesn&apos;t have a big
        LTSUSA bill at the start of the new membership year.
      </p>
      <p>
        All this is done automatically, behind the scenes. As a director, you
        don&apos;t have to do a thing.
      </p>
      <p>
        The downside to using Leevo is that it carries a cost, since you need to
        be an active Leevo subscriber to take advantage of this benefit.
      </p>
      <h3>Conclusion</h3>
      <p>
        Currently, these are your four options when it comes to running an
        LTSUSA program. There&apos;s no <em>best</em> option, but just the best
        option <em>for you</em>.
      </p>
      <p>
        If you run a program where parents with mostly very experienced skaters,
        having the parents/students manage their own LTS numbers may work for
        you.
      </p>
      <p>
        But if you want to have this done for you completely automatically, so
        that you can focus on skating instead of LTS compliance, then
        Leevo&apos;s approach might be the best for you. That&apos;s why we
        created Leevo, after all.
      </p>
      <p>
        If that&apos;s the case (or if you have more questions),{' '}
        <a
          href="#"
          onClick={() => {
            sendCrispMessage(
              "I'd like Leevo to handle LTS registrations for me. What are the next steps?"
            )
            mixpanel.track('Clicked Start Free Trial')
          }}
        >
          start a live chat with us
        </a>{' '}
        to learn how you can get started.
      </p>
    </ContentPageLayout>
  )
}

HandlingLTSRegistrations.defaultProps = {}

HandlingLTSRegistrations.propTypes = {}

export default HandlingLTSRegistrations
