import React from 'react'

import TermlyEmbed from '../leevo_ui/TermlyEmbed/TermlyEmbed'

function PrivacyPolicyFeature() {
  return (
    <TermlyEmbed
      title="Privacy Policy"
      termlyDocumentId="572cfd77-b3c3-4241-9dca-16acc37d2d61"
    />
  )
}

export default PrivacyPolicyFeature
