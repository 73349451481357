import React from 'react'
import { shallow, mount } from 'enzyme'

import StudentCard from './StudentCard'
import StudentSkills from '../StudentSkills/StudentSkills'
import Avatar from '../../leevo_ui/Avatar/Avatar'
import CheckButton from '../../leevo_ui/CheckButton/CheckButton'
import StudentAttendanceCheckButton from '../StudentAttendanceCheckButton/StudentAttendanceCheckButton'
import PromotionModal from '../PromotionModal/PromotionModal'

describe('StudentCard', () => {
  describe('StudentCard with no skills or toggle attendance function', () => {
    let wrapper

    beforeEach(() => {
      wrapper = shallow(
        <StudentCard
          klassDaysStudent={{ attended: false, id: 3 }}
          student={{
            id: 1,
            fullName: 'Student TestName',
            preferredName: 'Student',
            cloudinaryPhotoPublicId: 'fake',
            studentsSkills: [],
            currentSkillLevel: {
              id: 1,
              name: 'Basic 1',
              skills: [
                { id: 1, shortLabel: 'A' },
                { id: 2, shortLabel: 'B' },
                { id: 3, shortLabel: 'C' },
                { id: 4, shortLabel: 'D' },
                { id: 5, shortLabel: 'E' },
                { id: 6, shortLabel: 'F' },
              ],
              nextSkillLevel: { id: 2, name: 'name' },
            },
          }}
        />
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('does not render a StudentSkills component', () => {
      expect(wrapper.find(StudentSkills).length).toEqual(0)
    })

    test('renders student photo', () => {
      expect(wrapper.find(Avatar).length).toEqual(1)
    })

    test('does not render an attendance check button', () => {
      expect(wrapper.find(StudentAttendanceCheckButton).length).toEqual(0)
    })
  })

  describe('StudentCard with skills', () => {
    let wrapper, student, mockOnPromotion

    beforeEach(() => {
      student = {
        id: 1,
        fullName: 'Student TestName',
        preferredName: 'Student',
        cloudinaryPhotoPublicId: 'fake',
        studentsSkills: [
          {
            id: 1,
            shortLabel: 'A',
            status: 'completed',
            skill: { id: 1 },
          },
          {
            id: 2,
            shortLabel: 'B',
            status: 'introduced',
            skill: {
              id: 2,
              optional: true,
            },
          },
        ],
        currentSkillLevel: {
          id: 1,
          name: 'Basic 1',
          nextSkillLevel: {
            id: 2,
            name: 'name',
          },
          skills: [
            {
              id: 1,
              shortLabel: 'A',
              name: 'name',
            },
          ],
        },
      }
      mockOnPromotion = jest.fn().mockResolvedValue()
      wrapper = mount(
        <StudentCard
          klassDaysStudent={{
            attended: false,
            id: 3,
          }}
          student={student}
          onPromotion={mockOnPromotion}
        />
      )
    })

    test('renders StudentSkills component', () => {
      expect(wrapper.find(StudentSkills).length).toEqual(1)
    })

    test('renders promotion button when all required skills completed', () => {
      expect(wrapper.find('[data-cy="promote-button"]')).toExist()
    })

    test('renders promotion modal clicking on promotion button', () => {
      wrapper.find('[data-cy="promote-button"]').hostNodes().simulate('click')
      expect(wrapper.find(PromotionModal)).toExist()
    })

    test('calls onPromotion when promoted', () => {
      wrapper.find('[data-cy="promote-button"]').hostNodes().simulate('click')
      wrapper.find('[data-cy="yes-button"]').hostNodes().simulate('click')
      expect(mockOnPromotion).toHaveBeenCalled()
    })

    test('does not render promotion button when required skills are not completed', () => {
      student.studentsSkills = [
        {
          id: 1,
          shortLabel: 'A',
          status: 'unintroduced',
          skill: { id: 1 },
        },
        {
          id: 1,
          shortLabel: 'A',
          status: 'completed',
          skill: {
            id: 1,
            optional: true,
          },
        },
      ]
      wrapper = mount(
        <StudentCard
          klassDaysStudent={{
            attended: false,
            id: 3,
          }}
          student={student}
        />
      )
      expect(wrapper.find('[data-cy="promote-button"]')).not.toExist()
    })
  })

  describe('with a function to toggle attendance', () => {
    let wrapper
    const klassDaysStudentId = 3

    beforeAll(() => {
      wrapper = mount(
        <StudentCard
          klassDaysStudent={{ attended: false, id: klassDaysStudentId }}
          toggleAttendance={jest.fn()}
          student={{
            id: 1,
            fullName: 'Student TestName',
            preferredName: 'Student',
            cloudinaryPhotoPublicId: 'fake',
            studentsSkills: [
              {
                id: '1',
                shortLabel: 'A',
                status: 'introduced',
                skill: { id: 1 },
              },
              { id: '2', shortLabel: 'B', status: 'neutral', skill: { id: 2 } },
              {
                id: '3',
                shortLabel: 'C',
                status: 'completed',
                skill: { id: 3 },
              },
              {
                id: '4',
                shortLabel: 'D',
                status: 'introduced',
                skill: { id: 4 },
              },
              {
                id: '5',
                shortLabel: 'E',
                status: 'completed',
                skill: { id: 5 },
              },
              {
                id: '6',
                shortLabel: 'F',
                status: 'introduced',
                skill: { id: 6 },
              },
            ],
            currentSkillLevel: {
              id: 1,
              name: 'Basic 1',
              skills: [
                { id: 1, shortLabel: 'A' },
                { id: 2, shortLabel: 'B' },
                { id: 3, shortLabel: 'C' },
                { id: 4, shortLabel: 'D' },
                { id: 5, shortLabel: 'E' },
                { id: 6, shortLabel: 'F' },
              ],
              nextSkillLevel: { id: 2, name: 'name' },
            },
          }}
        />
      )
    })

    test('does render an attendance check button with initial state as unchecked', () => {
      expect(wrapper.find(StudentAttendanceCheckButton).length).toEqual(1)
    })

    test('renders enabled attendance check button', () => {
      expect(wrapper.find(CheckButton).props().disabled).toEqual(false)
    })
  })
})
