import React from 'react'
import { mount } from 'enzyme'

import MockFacilityProvider from '../../../providers/MockFacilityProvider'
import { MemoryRouter, Route } from 'react-router-dom'

import SkateRentalListItem from './SkateRentalListItem'

describe('SkateRentalListItem', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MockFacilityProvider>
          <MemoryRouter initialEntries={['/SkateRentalListItem']}>
            <Route path="/SkateRentalListItem">
              <SkateRentalListItem userId="1" skateType="men_figure" />
            </Route>
          </MemoryRouter>
        </MockFacilityProvider>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
