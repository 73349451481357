import React from 'react'
import { mount } from 'enzyme'
import { MemoryRouter } from 'react-router-dom'

import FacilitiesInactive from './FacilitiesInactive'
import MockFacilityProvider from '../../providers/MockFacilityProvider'
import MockAuthProvider from '../../providers/MockAuthProvider'

describe('FacilitiesInactive', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter>
          <MockAuthProvider>
            <MockFacilityProvider>
              <FacilitiesInactive />
            </MockFacilityProvider>
          </MockAuthProvider>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
