import React from 'react'
import AdminFacilitiesIndex from './AdminFacilitiesIndex'
import { shallow } from 'enzyme'
import { MemoryRouter, Route } from 'react-router-dom'

describe('AdminFacilitiesIndex', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(
        <MemoryRouter initialEntries={['/']}>
          <Route path="/">
            <AdminFacilitiesIndex />
          </Route>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
