import { action } from '@storybook/addon-actions'
import React from 'react'

import DisclosureForm from './DisclosureForm'

export default {
  title: 'Leevo UI/DisclosureForm',
  component: DisclosureForm,
  args: {
    className: 'm-4',
  },
}

export function emptyForm(args) {
  return (
    <DisclosureForm
      {...args}
      initialValues={{}}
      onSubmit={action('Submitted')}
    />
  )
}

export function prepopulated(args) {
  return (
    <DisclosureForm
      {...args}
      onSubmit={action('Submitted')}
      initialValues={{
        title: 'Some name',
        type: 'document',
        required: false,
        active: false,
        agreement: 'The quick brown fox jumps over the lazy dog',
      }}
    />
  )
}
