import React from 'react'
import { shallow } from 'enzyme'

import SkillLevelList from './SkillLevelList'

describe('SkillLevelList', () => {
  let wrapper

  beforeEach(() => {
    wrapper = shallow(
      <SkillLevelList skillLevels={[{ name: 'foo', skills: [] }]} />
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
