import React from 'react'

import FacilitiesKlassPackagesEdit from './FacilitiesKlassPackagesEdit'

export default {
  title: 'FacilitiesKlassPackagesEdit',
  component: FacilitiesKlassPackagesEdit,
}

export function withSuccessfulRequest() {
  // FIXME: This story doesn't work because we need to mock the
  // response from some endpoints. Not worth the time at the time of
  // this writing, so commenting out until we have time or we implement
  // better fixtures to make this dead simple.

  // const mockAxios = new MockAdapter(axios)

  // mockAxios.reset()
  // mockAxios.onGet(/class_packages/).reply(200)
  // mockAxios.onGet(/klasses/).reply(200)
  // mockAxios.onGet(/klasses/).reply(200)
  // mockAxios.onPut().reply(200)

  return <FacilitiesKlassPackagesEdit />
}
