import React from 'react'
import { mount } from 'enzyme'

import FacilitiesDisclosuresIndex from './FacilitiesDisclosuresIndex'
import MockFacilityProvider from '../../providers/MockFacilityProvider'
import { MemoryRouter, Route } from 'react-router-dom'

describe('FacilitiesDisclosuresIndex', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MockFacilityProvider>
          <MemoryRouter initialEntries={['/disclosures']}>
            <Route path="/disclosures">
              <FacilitiesDisclosuresIndex />
            </Route>
          </MemoryRouter>
        </MockFacilityProvider>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
