import React from 'react'
import { shallow } from 'enzyme'
import ReactPhoneInput from 'react-phone-number-input/input'

import PhoneInput from './PhoneInput'

describe('PhoneInput', () => {
  let wrapper, mockOnChange
  beforeEach(() => {
    mockOnChange = jest.fn()
  })

  test('renders', () => {
    wrapper = shallow(<PhoneInput id="id" onChange={mockOnChange} />)
    expect(wrapper).toExist()
  })

  test('renders a ReactPhoneInput', () => {
    wrapper = shallow(<PhoneInput id="id" onChange={mockOnChange} />)
    expect(wrapper.find(ReactPhoneInput)).toExist()
  })

  test('renders label', () => {
    wrapper = shallow(
      <PhoneInput id="id" label="foo" onChange={mockOnChange} />
    )
    expect(wrapper).toIncludeText('foo')
  })

  describe('error message', () => {
    beforeEach(() => {
      wrapper = shallow(
        <PhoneInput id="id" errorMessage="foo error" onChange={mockOnChange} />
      )
    })

    test('renders error message', () => {
      expect(wrapper).toIncludeText('foo error')
    })

    test('renders error styles', () => {
      const inputComponent = wrapper.find(ReactPhoneInput)
      expect(inputComponent).toHaveClassName('border-red-500')
      expect(inputComponent).toHaveClassName('text-red-500')
      expect(inputComponent).toHaveClassName('error-shake')
    })
  })
})
