import React from 'react'
import { action } from '@storybook/addon-actions'
import { boolean } from '@storybook/addon-knobs'
import { Form } from 'react-final-form'

import PhoneField from './PhoneField'

export default {
  title: 'Registration Feature/PhoneField',
  component: PhoneField,
}

export function phoneField() {
  return (
    <Form
      onSubmit={action('Submit')}
      render={() => (
        <PhoneField className={boolean('With margin?', false) ? 'm-6' : ''} />
      )}
    />
  )
}
