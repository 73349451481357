import React from 'react'
import DisclosuresList from './DisclosuresList'
import { shallow } from 'enzyme'

describe('DisclosuresList', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(<DisclosuresList />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
