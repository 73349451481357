import React from 'react'
import DisclosureForm from './DisclosureForm'
import { shallow } from 'enzyme'

describe('DisclosureForm', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(<DisclosureForm onSubmit={jest.fn()} />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
