import { text, boolean } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'

import React, { useState } from 'react'
import ConfirmationModal from './ConfirmationModal'
import Button from '../Button/Button'
import htmlEntities from 'he'

import bgPhoto from '../../../images/sample/skating-wallpaper.jpg'

export default {
  title: 'Leevo UI/Modals/ConfirmationModal',
  component: ConfirmationModal,
}

export function ConfirmationModalStory() {
  const [opened, setOpened] = useState(true)
  return (
    <>
      <img className="max-w-6xl" src={bgPhoto} />
      <div className="fixed w-full h-full" style={{ top: '45%', left: '45%' }}>
        <Button
          color="yellow"
          label="Open ConfirmationModal"
          className="shadow-hover"
          onClick={() => setOpened(true)}
        />
      </div>
      <ConfirmationModal
        open={opened}
        header={htmlEntities.decode(text('Header Text', 'Are you sure?'))}
        onClose={() => setOpened(false)}
        onYesClick={action('Yes clicked')}
        onNoClick={action('No clicked')}
        pending={boolean('Pending confirmation?', false)}
      >
        {text('Confirmation Text', 'Foo bar baz')}
      </ConfirmationModal>
    </>
  )
}
