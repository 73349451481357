import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

import MockFacilityProvider from '../../providers/MockFacilityProvider'
import FacilitiesDisclosuresIndex from './FacilitiesDisclosuresIndex'

export default {
  title: 'FacilitiesDisclosuresIndex',
  component: FacilitiesDisclosuresIndex,
  args: {
    className: 'm-4',
  },
}

const noDisclosuresResponse = {
  data: [],
}

const mockDisclosuresResponse = {
  data: [
    {
      id: '1',
      type: 'disclosure',
      attributes: { name: 'Liability Waiver', active: true, required: true },
    },
    {
      id: '2',
      type: 'disclosure',
      attributes: { name: 'Photo Release', active: true, required: false },
    },
    {
      id: '3',
      type: 'disclosure',
      attributes: { name: 'COVID Release', active: false, required: true },
    },
  ],
}

export function facilitiesDisclosuresIndexWithoutDisclosures(args) {
  const mockAxios = new MockAdapter(axios)

  mockAxios.reset()
  mockAxios.onGet().reply(200, noDisclosuresResponse)

  return (
    <MockFacilityProvider>
      <FacilitiesDisclosuresIndex {...args} />
    </MockFacilityProvider>
  )
}

export function facilitiesDisclosuresIndexWithDisclosures(args) {
  const mockAxios = new MockAdapter(axios)

  mockAxios.reset()
  mockAxios.onGet().reply(200, mockDisclosuresResponse)

  return (
    <MockFacilityProvider>
      <FacilitiesDisclosuresIndex {...args} />
    </MockFacilityProvider>
  )
}
