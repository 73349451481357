import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { capitalize } from 'lodash'
import Fuse from 'fuse.js'

import useFacility from '../../../utilities/hooks/useFacility'
import Input from '../../leevo_ui/Input/Input'
import Badge from '../../leevo_ui/Badge/Badge'
import CardFormSection from '../../leevo_ui/CardFormSection/CardFormSection'
import EmptyList from '../../leevo_ui/EmptyList/EmptyList'

const fuzzySearchOptions = {
  findAllMatches: true,
  keys: [
    'respondent.fullName',
    'respondent.preferredName',
    'student.fullName',
    'student.preferredName',
  ],
}

function ResponsesSection({ responses }) {
  const { facility } = useFacility({ isSubscriptionRequired: false })
  const [query, setQuery] = useState('')

  function filteredResponses() {
    if (!query) return responses

    const fuzzySearch = new Fuse(responses, fuzzySearchOptions)
    return fuzzySearch.search(query).map(({ item }) => item)
  }

  return (
    <div className="p-4">
      <CardFormSection
        title="Responses"
        description="The registrants who have responded to this disclosure."
        showSubmit={false}
      >
        {responses.length === 0 && (
          <EmptyList
            data-cy="no-responses-empty-list"
            message="No one has responded...yet."
            emoji="😶"
          />
        )}
        {responses.length > 0 && (
          <>
            <Input
              id="search"
              type="search"
              placeholder="Search by name..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <ul className="divide-y">
              {filteredResponses().map((response) => (
                <div
                  key={response.id}
                  className="flex items-center justify-between py-4"
                >
                  <div className="w-1/2 truncate">
                    <Link
                      to={`/facilities/${facility.id}/students/${response.student.id}/edit`}
                      className="font-medium text-purple-600 transition duration-100 ease-in-out hover:text-purple-400 focus:ring focus:outline-none ring-purple-400 ring:opacity-75"
                    >
                      {response.student.fullName || 'Unknown'}
                    </Link>
                    <p className="text-sm text-gray-700">
                      Signed by {response.respondent.fullName || 'Unknown'}
                    </p>
                  </div>
                  <div className="w-1/2 text-right">
                    <Badge
                      color={response.agreed ? 'green' : 'gray'}
                      text={response.agreed ? 'Agreed' : 'Not agreed'}
                      data-cy={`${
                        response.agreed ? 'agreed' : 'not-agreed'
                      }-badge`}
                    />
                    <p className="px-2 text-sm text-gray-700">
                      {capitalize(moment(response.updatedAt).fromNow())}
                    </p>
                  </div>
                </div>
              ))}
            </ul>
          </>
        )}
      </CardFormSection>
    </div>
  )
}

ResponsesSection.defaultProps = {
  responses: [],
}

ResponsesSection.propTypes = {
  responses: PropTypes.array,
}

export default ResponsesSection
