import React from 'react'

import MockFacilityProvider from '../../../providers/MockFacilityProvider'
import DisclosuresList from './DisclosuresList'

export default {
  title: 'FacilityDisclosuresIndex/DisclosuresList',
  component: DisclosuresList,
  args: {
    className: 'm-4',
    heading: 'Optional Heading',
    disclosures: [
      { id: 1, title: 'Disclosure 1', active: true, required: false },
      { id: 2, title: 'Disclosure 2', active: false, required: true },
      { id: 3, title: 'Disclosure 3', active: true, required: false },
    ],
  },
}

export function disclosuresList(args) {
  return (
    <MockFacilityProvider>
      <DisclosuresList {...args} />
    </MockFacilityProvider>
  )
}
