import React from 'react'
import { mount } from 'enzyme'
import { Field, Form } from 'react-final-form'

import KlassPackageList from './KlassPackageList'
import KlassPackageListItem from './KlassPackageListItem/KlassPackageListItem'

describe('KlassPackageList', () => {
  let wrapper

  beforeEach(() => {
    const studentId = 1
    const studentName = 'foo'
    const studentLevel = 'Basic 1'
    const availableKlassPackages = []
    wrapper = mount(
      <Form
        onSubmit={() => {}}
        render={() => (
          <KlassPackageList
            studentId={studentId}
            studentName={studentName}
            studentLevel={studentLevel}
            availableKlassPackages={availableKlassPackages}
          />
        )}
      />
    )
  })

  it('renders', () => {
    expect(wrapper).toExist()
  })

  it('renders a field with name that includes student id', () => {
    expect(wrapper.find(Field).props().name).toBe('user-1-klasses')
  })

  it('renders an info message if there are no classes available', () => {
    expect(wrapper).toIncludeText('There are no classes available for Basic 1')
  })

  describe('with available classes', () => {
    beforeEach(() => {
      const studentId = 1
      const studentName = 'foo'
      const studentLevel = 'Basic 1'
      const availableKlassPackages = [
        {
          id: 1,
          title: 'foo',
          description: 'bar',
          priceInDollarsForStudents: { 1: '$50.00' },
          remainingKlassDays: 5,
          remainingKlassDaysForStudents: { 1: 5 },
          spotsLeftForStudents: { 1: 5 },
        },
        {
          id: 2,
          title: 'foo1',
          description: 'bar1',
          priceInDollarsForStudents: { 1: '$50.00' },
          remainingKlassDaysForStudents: { 1: 5 },
          spotsLeftForStudents: { 1: 5 },
        },
      ]

      wrapper = mount(
        <Form
          onSubmit={() => {}}
          render={() => (
            <KlassPackageList
              studentId={studentId}
              studentName={studentName}
              studentLevel={studentLevel}
              availableKlassPackages={availableKlassPackages}
            />
          )}
        />
      )
    })

    it('renders a class list item for each provided class', () => {
      expect(wrapper.find(KlassPackageListItem)).toHaveLength(2)
    })

    it('sets the class to selected when clicked', () => {
      const firstKlass = wrapper.find(KlassPackageListItem).first()
      firstKlass.simulate('click')
      expect(wrapper.find('button.bg-purple-600')).toExist()
    })

    it('sets the class back to unselected when clicked again', () => {
      const firstKlass = wrapper.find(KlassPackageListItem).first()
      firstKlass.simulate('click')
      firstKlass.simulate('click')
      expect(wrapper.find('button.bg-gray-300')).toExist()
    })
  })

  // FIXME: Add tests around selectedKlassPackages and enrolledKlassPackages.
})
