import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { MemoryRouter, Route } from 'react-router-dom'

import StudentsNewFeature from './StudentsNewFeature'

export default {
  title: 'StudentsNewFeature',
  component: StudentsNewFeature,
}

const mockSkillLevelResponse = {
  data: [
    {
      id: '1',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 1',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '2',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 2',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '3',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 3',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '4',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 4',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '5',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '5',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 5',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '6',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '6',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 6',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '11',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '7',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 1',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '8',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 2',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '9',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '9',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 3',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '10',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '10',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 4',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '11',
      type: 'skillLevel',
      attributes: {
        name: 'Pre-Free Skate',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '12',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '12',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 1',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '13',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '13',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 2',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '14',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '14',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 3',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '15',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '15',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 4',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '16',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '16',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 5',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '17',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '17',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 6',
      },
      relationships: {
        nextSkillLevel: {
          data: null,
        },
      },
    },
  ],
}

const userAttributes = {
  email: 'foo@bar.com',
  preferredName: 'Foo',
  fullName: 'Foo Bar',
  gender: 'female',
  ltsNumber: '123456',
  birthdate: new Date(),
  skillLevel: '14',
}

const userRelationships = { currentSkillLevel: { data: { id: '1' } } }

export function withSuccessfulRequest() {
  const mockAxios = new MockAdapter(axios)

  mockAxios.reset()
  mockAxios.onGet(/users/).reply(200, {
    data: {
      type: 'user',
      id: 1,
      attributes: userAttributes,
      relationships: userRelationships,
    },
  })
  mockAxios.onGet(/\/skill_levels/).reply(200, mockSkillLevelResponse)
  mockAxios.onPut().reply(200)

  return (
    <MemoryRouter initialEntries={['/students/new']}>
      <Route path="/students/new">
        <StudentsNewFeature />
      </Route>
    </MemoryRouter>
  )
}
