import React from 'react'
import PropTypes from 'prop-types'

import Modal from '../Modal/Modal'
import CheckoutForm from '../CheckoutForm/CheckoutForm'

function NewCreditCardModal({ className, open, onSubmit, ...rest }) {
  return (
    <Modal
      {...rest}
      className={className}
      open={open}
      header="Add new credit card"
      data-cy="new-credit-card-modal"
    >
      <CheckoutForm submitButtonLabel="Add Card" onSubmit={onSubmit} />
    </Modal>
  )
}

NewCreditCardModal.defaultProps = {}

NewCreditCardModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
}

export default NewCreditCardModal
