import React, { useState } from 'react'
import { text, select, boolean, number } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'
import htmlElements from 'he'
import { Form, Field } from 'react-final-form'

import Input from './Input'

export default {
  title: 'Forms/Input',
  component: Input,
}

export function VanillaInput() {
  const [value, setValue] = useState('')

  return (
    <Input
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className="m-6"
      id="email"
      type={select('Type', ['text', 'password'], 'text')}
      maxLength={number('Max Length', 100)}
      label={htmlElements.decode(text('Label', 'Your email'))}
      helpText={htmlElements.decode(text('Label', 'This is your email'))}
      placeholder={htmlElements.decode(text('Placeholder', 'Email'))}
      errorMessage={htmlElements.decode(text('Error Message', ''))}
      buttonLabel={htmlElements.decode(text('Button Label', 'Submit'))}
      onButtonClick={action('clicked button')}
      isButtonDisabled={boolean('Button Disabled?', false)}
      disabled={boolean('Disabled?', false)}
      required={boolean('Required?', false)}
    />
  )
}

export function finalFormInput() {
  return (
    <Form
      onSubmit={action('submitted')}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field name="input">
            {({ input }) => (
              <Input
                {...input}
                className="m-6"
                id="email"
                type={select('Type', ['text', 'password'], 'text')}
                maxLength={number('Max Length', 100)}
                label={htmlElements.decode(text('Label', 'Your email'))}
                helpText={htmlElements.decode(
                  text('Label', 'This is your email')
                )}
                placeholder={htmlElements.decode(text('Placeholder', 'Email'))}
                errorMessage={htmlElements.decode(text('Error Message', ''))}
                buttonLabel={htmlElements.decode(
                  text('Button Label', 'Submit')
                )}
                onButtonClick={action('clicked button')}
                isButtonDisabled={boolean('Button Disabled?', false)}
                disabled={boolean('Disabled?', false)}
                required={boolean('Required?', false)}
              />
            )}
          </Field>
        </form>
      )}
    />
  )
}
