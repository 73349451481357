import React from 'react'
import AnnouncementBanner from './AnnouncementBanner'

export default {
  title: 'Leevo UI/AnnouncementBanner',
  component: AnnouncementBanner,
  args: {
    show: true,
    longMessage: "Big news! We're excited to announce a brand new product.",
    message: 'We announced a new product!',
    ctaText: 'Learn more',
    onClose: () => console.log('closed') /* eslint-disable-line no-console */,
  },
}

export function AnnouncementBannerComponent(args) {
  return <AnnouncementBanner {...args} />
}
