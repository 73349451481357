import React, { useState, useEffect } from 'react'
import { get as _get } from 'lodash'
import axios from 'axios'

import FacilityEditForm from '../leevo_ui/FacilityEditForm/FacilityEditForm'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import Notification from '../leevo_ui/Notification/Notification'
import { requestCreator } from '../../utilities/requests'
import useFacility from '../../utilities/hooks/useFacility'

const { put: updateFacility, cancel: cancelFacilityRequest } = requestCreator()

function FacilityEditFeature() {
  const {
    facility,
    isLoading: isFacilityLoading,
    update: updateFacilityContext,
  } = useFacility({
    isSubscriptionRequired: false,
  })
  const [notification, setNotification] = useState({
    success: null,
    message: null,
  })

  useEffect(() => cancelFacilityRequest)

  function onSubmit(values) {
    return updateFacility(`/api/facilities/${facility.id}`, {
      facility: values,
    })
      .then(() => {
        setNotification({ success: true, message: 'Changes saved!' })
      })
      .catch((error) => {
        const message =
          _get(error, 'response.data.error') ||
          'We were unable to save your changes.'
        if (!axios.isCancel(error)) setNotification({ success: false, message })
      })
  }

  return (
    <ApplicationLayout isContentLoading={isFacilityLoading}>
      <Notification
        show={notification.message}
        color={notification.success ? 'green' : 'red'}
      >
        {notification.message}
      </Notification>
      <FacilityEditForm
        {...facility}
        onCardChange={({ id, isRemovingCard }) => {
          return updateFacility(`/api/facilities/${facility.id}`, {
            facility: { isRemovingCard, stripe_credit_card_token: id },
            include: 'curriculums,billing_contact',
          }).then((updatedFacilityData) => {
            updateFacilityContext(updatedFacilityData)
          })
        }}
        onSubmit={onSubmit}
      />
    </ApplicationLayout>
  )
}

export default FacilityEditFeature
