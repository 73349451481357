import React from 'react'
import { mount } from 'enzyme'
import { MemoryRouter, Route } from 'react-router-dom'

import StudentEditForm from './StudentEditForm'

describe('StudentEditForm', () => {
  let wrapper, mockOnSubmit

  describe('when all options are required', () => {
    beforeEach(() => {
      mockOnSubmit = jest.fn(() => Promise.resolve())
      wrapper = mount(
        <MemoryRouter initialEntries={['/1']}>
          <Route path="/:facility_id">
            <StudentEditForm
              onSubmit={mockOnSubmit}
              preferredName="foobar"
              fullName="foobar barbaz"
              gender="female"
              ltsNumber="123456"
              skillLevel="14"
              address={{
                line1: '1234 Anytown Way',
                city: 'Anytown',
                state: 'AL',
                postCode: '12345',
              }}
              birthdate={new Date().toString()}
              requiresAddress
              requiresBirthdate
              requiresGender
              requiresLtsNumber
            />
          </Route>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('renders a form', () => {
      expect(wrapper.find('form')).toExist()
    })

    test('renders with pre-populated fields', () => {
      expect(
        wrapper.find('[data-cy="preferred_name-input"]').hostNodes().props()
          .value
      ).toBe('foobar')
      expect(
        wrapper.find('[data-cy="full_name-input"]').hostNodes().props().value
      ).toBe('foobar barbaz')
      expect(
        wrapper.find('[data-cy="gender-input"]').hostNodes().props().value
      ).toBe('female')
    })

    describe('submission', () => {
      beforeEach(() => {
        wrapper.find('form').first().simulate('submit')
      })

      test('calls the onSubmit function', () => {
        expect(mockOnSubmit).toHaveBeenCalled()
      })
    })
  })

  describe('when no optional fields are required', () => {
    beforeEach(() => {
      mockOnSubmit = jest.fn()
      wrapper = mount(
        <MemoryRouter initialEntries={['/1']}>
          <Route path="/:facility_id">
            <StudentEditForm
              onSubmit={mockOnSubmit}
              preferredName="foobar"
              fullName="foobar barbaz"
              skillLevel="14"
            />
          </Route>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('renders a form', () => {
      expect(wrapper.find('form')).toExist()
    })

    test('renders with pre-populated fields', () => {
      expect(
        wrapper.find('[data-cy="preferred_name-input"]').hostNodes().props()
          .value
      ).toBe('foobar')
      expect(
        wrapper.find('[data-cy="full_name-input"]').hostNodes().props().value
      ).toBe('foobar barbaz')
    })

    describe('submission', () => {
      beforeEach(() => {
        wrapper.find('form').first().simulate('submit')
      })

      test('calls the onSubmit function', () => {
        expect(mockOnSubmit).toHaveBeenCalled()
      })
    })
  })
})
