import React from 'react'
import { MemoryRouter, Route } from 'react-router-dom'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

import GuardiansStudentsIndex from './GuardiansStudentsIndex'

export default {
  title: 'GuardiansStudentsIndex/GuardiansStudentsIndex',
  component: GuardiansStudentsIndex,
}

const mockStudentsResponse = {
  data: [
    {
      id: '2',
      type: 'student',
      attributes: {
        fullName: 'Jane Doe',
        preferredName: 'Janey',
        email: 'user2@example.com',
        cloudinaryPhotoPublicId: null,
        gender: 'unspecified',
        birthdate: null,
        ltsNumber: null,
      },
      relationships: {
        studentsSkills: { data: [{ id: '1', type: 'studentsSkill' }] },
        klassDaysStudents: { data: [] },
        currentSkillLevel: { data: { id: '1', type: 'skillLevel' } },
      },
    },
    {
      id: '3',
      type: 'student',
      attributes: {
        fullName: 'Jane Doe',
        preferredName: 'Janey',
        email: 'user2@example.com',
        cloudinaryPhotoPublicId: null,
        gender: 'unspecified',
        birthdate: null,
        ltsNumber: null,
      },
      relationships: {
        studentsSkills: { data: [{ id: '1', type: 'studentsSkill' }] },
        klassDaysStudents: { data: [] },
        currentSkillLevel: { data: { id: '1', type: 'skillLevel' } },
      },
    },
    {
      id: '4',
      type: 'student',
      attributes: {
        fullName: 'Jane Doe',
        preferredName: 'Janey',
        email: 'user2@example.com',
        cloudinaryPhotoPublicId: null,
        gender: 'unspecified',
        birthdate: null,
        ltsNumber: null,
      },
      relationships: {
        studentsSkills: { data: [{ id: '1', type: 'studentsSkill' }] },
        klassDaysStudents: { data: [] },
        currentSkillLevel: { data: { id: '1', type: 'skillLevel' } },
      },
    },
    {
      id: '5',
      type: 'student',
      attributes: {
        fullName: 'Jane Doe',
        preferredName: 'Janey',
        email: 'user2@example.com',
        cloudinaryPhotoPublicId: null,
        gender: 'unspecified',
        birthdate: null,
        ltsNumber: null,
      },
      relationships: {
        studentsSkills: { data: [{ id: '1', type: 'studentsSkill' }] },
        klassDaysStudents: { data: [] },
        currentSkillLevel: { data: { id: '1', type: 'skillLevel' } },
      },
    },
  ],
  included: [
    {
      id: '1',
      type: 'skillLevel',
      attributes: { name: 'Basic 1' },
      relationships: {
        nextSkillLevel: { data: null },
        skills: { data: [{ id: '1', type: 'skill' }] },
      },
    },
    {
      id: '1',
      type: 'studentsSkill',
      attributes: {
        name: 'Stand up on the ice',
        shortLabel: 'A',
        status: 'unintroduced',
        ordinal: 1,
      },
      relationships: { skill: { data: { id: '1', type: 'skill' } } },
      links: { self: 'http://localhost:3000/api/students_skills/1' },
    },
  ],
}

export function guardiansStudentsIndex() {
  const mockAxios = new MockAdapter(axios)

  mockAxios.reset()
  mockAxios.onGet().reply(200, mockStudentsResponse)

  return (
    <MemoryRouter initialEntries={['/guardians/1/students']}>
      <Route
        path="/guardians/:guardian_id/students"
        component={GuardiansStudentsIndex}
      />
    </MemoryRouter>
  )
}
