import React from 'react'

import LoadingPage from './LoadingPage'

export default {
  title: 'Leevo UI/LoadingPage',
  component: LoadingPage,
}

export function loadingPage() {
  return <LoadingPage />
}
