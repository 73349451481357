import React from 'react'
import { text, number, boolean } from '@storybook/addon-knobs'
import htmlElements from 'he'

import CurrencyInput from './CurrencyInput'

export default {
  title: 'Forms/CurrencyInput',
  component: CurrencyInput,
}

export function CurrencyInputStory() {
  const [value, setValue] = React.useState(0)
  return (
    <CurrencyInput
      className={boolean('With margins?', true) ? 'm-6' : ''}
      id="currency"
      max={number('Max value:', CurrencyInput.defaultProps.max)}
      label={htmlElements.decode(text('Label', 'Set a price'))}
      placeholder={htmlElements.decode(text('Placeholder', '$50.00'))}
      errorMessage={htmlElements.decode(text('Error Message', ''))}
      disabled={boolean('Disabled?', false)}
      required={boolean('Required?', false)}
      onChange={setValue}
      value={value}
    />
  )
}
