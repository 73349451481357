import { text, boolean } from '@storybook/addon-knobs'
import htmlElements from 'he'

import React from 'react'
import SelectInput from './SelectInput'

export default {
  title: 'Forms/SelectInput',
  component: SelectInput,
}

const options = [
  { label: 'Level 1a', value: '1a', group: 'Level 1' },
  { label: 'Level 1b', value: '1b', group: 'Level 1' },
  { label: 'Level 1c', value: '1c', group: 'Level 1' },
  { label: 'Level 2a', value: '2a', group: 'Level 2' },
  { label: 'Level 2b', value: '2b', group: 'Level 2' },
  { label: 'Level 2c', value: '2c', group: 'Level 2' },
  { label: 'Ungrouped X', value: 'x' },
  { label: 'Ungrouped Y', value: 'y' },
]

export function selectInput() {
  return (
    <SelectInput
      options={options}
      className="m-6"
      id="select"
      label={htmlElements.decode(text('Label', 'Pick an option'))}
      helpText={htmlElements.decode(
        text('Help Text', 'Here is some help text. It should help guide you.')
      )}
      placeholder={htmlElements.decode(text('Placeholder', 'Select an Option'))}
      errorMessage={htmlElements.decode(text('Error Message', ''))}
      disabled={boolean('Disabled?', false)}
    />
  )
}
