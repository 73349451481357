import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { get } from 'lodash'

import DisclosureForm from '../leevo_ui/DisclosureForm/DisclosureForm'
import ResponsesSection from './ResponsesSection/ResponsesSection'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import { requestCreator } from '../../utilities/requests'
import useFacility from '../../utilities/hooks/useFacility'

const {
  get: getDisclosure,
  put: updateDisclosure,
  cancel: cancelDisclosureRequest,
} = requestCreator()

function FacilitiesDisclosuresEdit() {
  const history = useHistory()
  const { facility, isLoading: isFacilityLoading } = useFacility({
    isSubscriptionRequired: false,
  })
  const [isLoading, setIsLoading] = useState(true)
  const [disclosure, setDisclosure] = useState({})
  const { id: disclosureId } = useParams()

  useEffect(() => {
    if (!isFacilityLoading) {
      getDisclosure(
        `/api/facilities/${facility.id}/disclosures/${disclosureId}/edit`,
        {
          params: {
            fields: {
              disclosureResponse: 'agreed,updated_at',
              student: 'fullName',
              user: 'fullName',
            },
            include: 'responses,responses.student,responses.respondent',
          },
        }
      )
        .then(setDisclosure)
        .then(() => setIsLoading(false))
        .catch((error) => {
          if (!axios.isCancel(error)) {
            history.push('/500')
            throw error
          }
        })
    }

    return cancelDisclosureRequest
  }, [setDisclosure, isFacilityLoading, disclosureId, facility.id, history])

  function onSubmit(values) {
    return updateDisclosure(
      `/api/facilities/${facility.id}/disclosures/${disclosureId}`,
      { disclosure: values }
    )
      .then(() =>
        history.push({
          pathname: `/facilities/${facility.id}/disclosures`,
          state: {
            notification: {
              message: 'Disclosure updated successfully!',
              color: 'green',
            },
          },
        })
      )
      .catch((error) => {
        if (!axios.isCancel(error)) {
          history.push({
            state: {
              notification: {
                message:
                  get(error, 'response.data.error') ||
                  'We were unable to save your changes.',
                color: 'red',
              },
            },
          })
        }
      })
  }

  return (
    <ApplicationLayout isContentLoading={isLoading}>
      <DisclosureForm
        initialValues={disclosure}
        onSubmit={onSubmit}
        className="p-4"
      />
      <ResponsesSection responses={disclosure.responses} />
    </ApplicationLayout>
  )
}

FacilitiesDisclosuresEdit.defaultProps = {}

FacilitiesDisclosuresEdit.propTypes = {}

export default FacilitiesDisclosuresEdit
