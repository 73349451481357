import React from 'react'
import { mount } from 'enzyme'

import { MemoryRouter, Route } from 'react-router-dom'

import TestimonialWithLargeAvatar from './TestimonialWithLargeAvatar'

describe('TestimonialWithLargeAvatar', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/TestimonialWithLargeAvatar']}>
          <Route path="/TestimonialWithLargeAvatar">
            <TestimonialWithLargeAvatar />
          </Route>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
