import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { get as _get } from 'lodash'

import KlassPackageForm from '../leevo_ui/KlassPackageForm/KlassPackageForm'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import { requestCreator } from '../../utilities/requests'
import useFacility from '../../utilities/hooks/useFacility'

const { get: getKlasses, cancel: cancelKlassesRequests } = requestCreator()
const { get: getStudents, cancel: cancelStudentsRequests } = requestCreator()

const {
  get: getKlassPackage,
  put: updateKlassPackages,
  cancel: cancelKlassPackageRequests,
} = requestCreator()

function FacilitiesKlassPackagesEdit() {
  const history = useHistory()
  const { facility, isLoading: isFacilityLoading } = useFacility()
  const [isLoading, setIsLoading] = useState(true)
  const [price, setPrice] = useState(null)
  const [klasses, setKlasses] = useState([])
  const [title, setTitle] = useState(null)
  const [students, setStudents] = useState([])
  const [description, setDescription] = useState(null)
  const [openStatus, setOpenStatus] = useState(null)
  const [klassPackage, setKlassPackage] = useState({})
  const { klass_package_id: klassPackageId } = useParams()

  useEffect(() => {
    if (!isFacilityLoading) {
      let localKlassPackage
      getKlassPackage(
        `/api/facilities/${facility.id}/class_packages/${klassPackageId}`,
        { params: { include: 'students' } }
      )
        .then((retrievedKlassPackage) => {
          localKlassPackage = retrievedKlassPackage
          setKlassPackage(localKlassPackage)
          setPrice(retrievedKlassPackage.price)
          setTitle(retrievedKlassPackage.title)
          setDescription(retrievedKlassPackage.description)
          setOpenStatus(retrievedKlassPackage.active)
        })
        .then(() =>
          getKlasses(`/api/facilities/${facility.id}/klasses`, {
            params: {
              include: 'coaches,supported_skill_levels',
            },
          })
            .then((klasses) =>
              setKlasses(
                klasses.map((klass) => ({
                  ...klass,
                  selected: localKlassPackage.klasses
                    .map((k) => k.id)
                    .includes(klass.id),
                }))
              )
            )
            .then(() =>
              getStudents(`/api/facilities/${facility.id}/students`, {
                params: {
                  include: 'current_skill_level',
                  fields: {
                    student:
                      'fullName,cloudinaryPhotoId,current_skill_level,age',
                    skill_level: 'name',
                  },
                },
              })
            )
            .then(({ records: studentList }) =>
              setStudents(
                studentList.map((student) => ({
                  ...student,
                  value: student.id,
                  selected: localKlassPackage.students
                    .map((k) => k.id)
                    .includes(student.id),
                }))
              )
            )
            .then(() => setIsLoading(false))
            .catch((error) => {
              if (!axios.isCancel(error)) {
                history.push('/500')
                throw error
              }
            })
        )
    }

    return () => {
      cancelKlassesRequests()
      cancelKlassPackageRequests()
      cancelStudentsRequests()
    }
  }, [isFacilityLoading, facility, history, klassPackageId])

  function onSubmit(values) {
    return updateKlassPackages(
      `/api/facilities/${facility.id}/class_packages/${klassPackage.id}`,
      {
        klassPackage: {
          ...values,
          active: values.open,
          klasses: values.klasses,
          students: values.students
            .filter((student) => student.selected)
            .map((student) => student.id),
        },
      }
    )
      .then(() =>
        history.push({
          pathname: `/facilities/${facility.id}/class_packages`,
          state: {
            notification: {
              message: 'Class package updated successfully!',
              color: 'green',
            },
          },
        })
      )
      .catch((error) => {
        if (!axios.isCancel(error)) {
          const message =
            _get(error, 'response.data.error') ||
            'We were unable to save your changes.'
          history.replace({
            state: {
              notification: {
                message,
                color: 'red',
              },
            },
          })
        }
      })
  }

  return (
    <ApplicationLayout isContentLoading={isLoading}>
      <KlassPackageForm
        price={price}
        klasses={klasses}
        title={title}
        students={students}
        description={description}
        open={openStatus}
        facilityId={facility.id}
        onSubmit={onSubmit}
      />
    </ApplicationLayout>
  )
}

export default FacilitiesKlassPackagesEdit
