import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { formatPhoneNumber } from 'react-phone-number-input'
import SVG from 'react-inlinesvg'

import Badge from '../../Badge/Badge'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import envelopeIcon from '../../../../images/envelope-solid.svg'
import phoneIcon from '../../../../images/phone-solid.svg'
import removeIcon from '../../../../images/times-solid.svg'

function GuardianInfo({
  className,
  id,
  fullName,
  phone,
  email,
  pending,
  emailBounced,
  onRemove,
  ...rest
}) {
  const [isRemoving, setIsRemoving] = useState(false)
  const showBadge = pending || emailBounced
  const badgeColor = emailBounced ? 'red' : 'gray'
  const badgeText = emailBounced ? 'Emails Bounced' : 'Pending Invite'

  return (
    <div {...rest} className={className}>
      {fullName && (
        <div className="flex items-center justify-start">
          <p className="text-lg font-light">{fullName}</p>
          {showBadge && (
            <div className="flex-shrink-0 ml-3">
              <Badge color={badgeColor} text={badgeText} />
            </div>
          )}
        </div>
      )}
      {email && (
        <a
          href={`mailto:${email}`}
          className="flex items-center w-full font-light text-purple-600 hover:text-purple-500"
          data-cy="guardian-email"
        >
          <SVG
            className="flex-shrink-0 w-3 h-3 mr-1 fill-current"
            src={envelopeIcon}
          />
          {email}
          {showBadge && !fullName && (
            <div className="flex-shrink-0 ml-3">
              <Badge color={badgeColor} text={badgeText} />
            </div>
          )}
        </a>
      )}
      {phone && (
        <a
          href={`tel:${phone}`}
          className="flex items-center w-full font-light text-purple-600 hover:text-purple-500"
        >
          <SVG className="w-3 h-3 mr-1 fill-current" src={phoneIcon} />
          {formatPhoneNumber(phone)}
        </a>
      )}
      <footer className="flex justify-end w-full">
        <button
          disabled={isRemoving}
          className="flex items-center mt-3 text-red-600 cursor-pointer hover:text-red-400"
          data-cy={`${email}-remove-button`}
          onClick={() => {
            setIsRemoving(true)
            onRemove(id).then(() => setIsRemoving(false))
          }}
        >
          {isRemoving && <LoadingSpinner color="red" className="mr-4" />}
          {!isRemoving && (
            <>
              <SVG className="w-2 h-2 mr-1 fill-current" src={removeIcon} />
              <span className="text-sm">Remove</span>
            </>
          )}
        </button>
      </footer>
    </div>
  )
}

GuardianInfo.defaultProps = {}

GuardianInfo.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullName: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  pending: PropTypes.bool,
  emailBounced: PropTypes.bool,
  onRemove: PropTypes.func,
}

export default GuardianInfo
