import React from 'react'
import CopyField from './CopyField'

export default {
  title: 'Admin/AdminGuardiansIndex/CopyField',
  component: CopyField,
  args: {
    text: 'Foobar',
  },
}

export function copyField(args) {
  return <CopyField {...args} />
}
