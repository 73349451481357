import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import PhoneInput from '../../leevo_ui/PhoneInput/PhoneInput'
import { isPhoneNumber } from '../../../utilities/validators'

function PhoneField({ className }) {
  return (
    <Field name="phone" validate={isPhoneNumber}>
      {({ input, meta: { active, error, submitFailed } }) => (
        <PhoneInput
          {...input}
          id="phone"
          className={className}
          label="What's your phone number?"
          placeholder="Your phone number"
          maxlength="14"
          errorMessage={!active && error && submitFailed ? error : ''}
        />
      )}
    </Field>
  )
}

PhoneField.propTypes = {
  className: PropTypes.string,
}

export default PhoneField
