import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { capitalize } from 'lodash'
import { get, uniqBy } from 'lodash'
import LoadingSpinner from '../leevo_ui/LoadingSpinner/LoadingSpinner'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import EmptyList from '../leevo_ui/EmptyList/EmptyList'
import IndexHeader from '../leevo_ui/IndexHeader/IndexHeader'
import KlassCard from './KlassCard/KlassCard'
import SelectInput from '../leevo_ui/SelectInput/SelectInput'
import { requestCreator } from '../../utilities/requests'
import emptyCalendar from '../../images/empty-calendar.svg'
import useFacility from '../../utilities/hooks/useFacility'
import moment from 'moment'

const { get: getKlasses, cancel: cancelKlassesRequest } = requestCreator()

function FacilitiesKlassesIndex() {
  const { facility, isLoading: isFacilityLoading } = useFacility()
  const history = useHistory()
  const [klasses, setKlasses] = useState({
    active: [],
    past: [],
  })
  const [selectedKlassPackage, setSelectedKlassPackage] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const klassPackageOptions = useMemo(() => {
    function optionsForState(state) {
      const options = klasses[state]
        .map(({ klassPackages }) => klassPackages)
        .flat()
        .filter((x) => !!x)
        .map(({ title: label, id: value }) => ({ label, value }))
      options.unshift({ label: 'All', value: '' })
      return uniqBy(options, 'value')
    }

    if (klasses.length === 0) {
      return null
    } else {
      return {
        active: optionsForState('active'),
        past: optionsForState('past'),
      }
    }
  }, [klasses])

  function tabClassNames({ selected }) {
    return classNames(
      'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
      'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-purple-400 ring-purple-400 ring-opacity-60',
      selected
        ? 'text-purple-700 bg-white shadow'
        : 'text-purple-500 hover:bg-white/[0.12] hover:text-purple-700'
    )
  }

  const panelClassNames =
    'flex flex-wrap justify-center lg:justify-start w-full max-w-2xl pt-2 mb-24'

  useEffect(() => {
    setIsLoading(true)
    if (!isFacilityLoading) {
      getKlasses(`/api/facilities/${facility.id}/klasses`, {
        params: {
          all: true,
          include: 'coaches,supported_skill_levels,klass_packages',
          fields: {
            klass:
              'dayOfWeek,timeOfDay,startDate,endDate,coaches,supported_skill_levels,maxStudents,students,klass_packages,firstOccurrenceAt,finalOccurrenceAt',
            coach: 'fullName,cloudinaryPhotoPublicId',
            skillLevel: 'name',
            klassPackage: 'title',
          },
        },
      })
        .then((klasses) => {
          setKlasses({
            active: klasses.filter(
              ({ finalOccurrenceAt }) =>
                !finalOccurrenceAt ||
                moment(finalOccurrenceAt).isAfter(moment())
            ),
            past: klasses.filter(
              ({ finalOccurrenceAt }) =>
                finalOccurrenceAt &&
                !moment(finalOccurrenceAt).isAfter(moment())
            ),
          })
        })
        .then(() => setIsLoading(false))
        .catch((error) => {
          if (!axios.isCancel(error)) {
            if (get(error, 'response.status') === 401) {
              history.replace('/login')
            } else {
              history.replace('/500')
            }
          }
        })
    }

    return cancelKlassesRequest
  }, [facility, history, isFacilityLoading])

  return (
    <ApplicationLayout>
      <IndexHeader
        title="Classes"
        onAddClick={() =>
          history.push(`/facilities/${facility.id}/classes/new`)
        }
      />
      {isLoading ? (
        <LoadingSpinner
          size="1/5"
          className="flex items-center justify-center h-full"
        />
      ) : (
        <div
          className="flex flex-col items-center justify-center pt-2 shadow-inner"
          data-cy="klass-list"
        >
          <Tab.Group manual onChange={(index) => setSelectedKlassPackage(null)}>
            <Tab.List className="flex p-1 mx-auto space-x-1 shadow-inner bg-gray-200/50 rounded-xl w-60">
              {Object.keys(klasses).map((state) => (
                <Tab key={state} className={tabClassNames}>
                  {capitalize(state)}
                </Tab>
              ))}
            </Tab.List>

            <Tab.Panels className="mt-2">
              <div className="flex flex-wrap justify-center w-full max-w-2xl pt-2 mb-24 lg:justify-start">
                {Object.keys(klasses).map((state) => {
                  let emptyStateMessage
                  if (state === 'active') {
                    emptyStateMessage = 'You have no classes scheduled.'
                  } else {
                    emptyStateMessage = 'You have no past classes.'
                  }
                  return (
                    <Tab.Panel key={state} className={panelClassNames}>
                      {klassPackageOptions[state] && (
                        <div className="items-center w-full max-w-2xl px-8 pt-2 lg:flex lg:px-3">
                          <p className="w-full font-medium text-gray-700 lg:w-1/3">
                            Filter by class package:{' '}
                          </p>
                          <SelectInput
                            className="w-full lg:w-2/3"
                            options={klassPackageOptions[state]}
                            value={selectedKlassPackage}
                            onChange={(e) =>
                              setSelectedKlassPackage(e.target.value)
                            }
                            id="select-klass-package"
                            data-cy="klass-package-select"
                          />
                        </div>
                      )}
                      {klasses[state].length === 0 && (
                        <div className="items-center w-full max-w-2xl px-8 pt-2 lg:flex lg:px-3 justify-center">
                          <EmptyList
                            message={emptyStateMessage}
                            svgSrc={emptyCalendar}
                            buttonText="Create One +"
                            onButtonClick={() =>
                              history.push(
                                `/facilities/${facility.id}/classes/new`
                              )
                            }
                          />
                        </div>
                      )}
                      {klasses[state]
                        .filter(({ klassPackages }) => {
                          return (
                            !selectedKlassPackage ||
                            klassPackages
                              .map(({ id }) => id)
                              .includes(selectedKlassPackage)
                          )
                        })
                        .sort(
                          (a, b) =>
                            new Date(a.firstOccurrenceAt) -
                            new Date(b.firstOccurrenceAt)
                        )
                        .map((klass) => (
                          <KlassCard key={klass.id} {...klass} />
                        ))}
                    </Tab.Panel>
                  )
                })}
              </div>
            </Tab.Panels>
          </Tab.Group>
        </div>
      )}
    </ApplicationLayout>
  )
}

export default FacilitiesKlassesIndex
