import React from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { get as _get } from 'lodash'

import useAuth from '../../utilities/hooks/useAuth'
import LoginModal from '../leevo_ui/LoginModal/LoginModal'

function LoginFeature() {
  const { isSignedIn, currentUser } = useAuth()
  const location = useLocation()
  const notificationMessage = _get(location, 'state.message')

  function redirectPath() {
    const previousPath = _get(location, 'state.from')
    if (previousPath) return previousPath

    const coachFacilitiesUser = currentUser.facilitiesUsers.find(
      ({ admin, coach }) => admin || coach
    )

    if (coachFacilitiesUser)
      return `/facilities/${coachFacilitiesUser.facility.id}/calendar`

    const isParent = !!_get(currentUser, 'students.length')
    if (isParent) return `/guardians/${currentUser.id}/students`
    return '/'
  }

  return (
    <>
      <Helmet>
        <title>Login | Leevo</title>
      </Helmet>
      {isSignedIn ? (
        <Redirect to={redirectPath()} />
      ) : (
        <LoginModal notificationMessage={notificationMessage} />
      )}
    </>
  )
}

export default LoginFeature
