import React from 'react'
import moment from 'moment'
import { text, number, date, boolean } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'
import faker from 'faker'
import MockFacilityProvider from '../../providers/MockFacilityProvider'

import FacilitiesKlassDaysIndex from './FacilitiesKlassDaysIndex'

function klasses({ numKlasses, date }) {
  const klasses = []
  for (let i = 0; i < numKlasses; i++) {
    const preferredName = faker.name.firstName()
    const fullName = `${preferredName} ${faker.name.lastName()}`
    klasses.push({
      id: i + 1,
      coaches: [
        {
          id: 1,
          fullName,
          preferredName,
          cloudinaryPhotoPublicId: 'qrz0yfenrunmvcb0epwa',
        },
      ],
      startDateTime: date,
      students: [
        {
          fullName: 'Foo Bar',
          preferredName: 'Foo',
          currentSkillLevel: {
            id: 1,
            name: 'Basic 1',
            nextSkillLevel: { id: 2, name: 'Basic 2' },
          },
        },
        {
          fullName: 'Bar',
          preferredName: 'Foobar',
          currentSkillLevel: { id: 2, name: 'Basic 2' },
          nextSkillLevel: { id: 3, name: 'Basic 3' },
        },
      ],
      skillLevels: ['Basic 1', 'Basic 2'],
    })
  }
  return klasses
}

export default {
  title: 'Facilities Klass Days Index',
  component: FacilitiesKlassDaysIndex,
}

export function facilitiesKlassDaysIndex() {
  const selectedDate = date('Selected Date', new Date())

  return (
    <MockFacilityProvider>
      <FacilitiesKlassDaysIndex
        availableDays={['Sunday', 'Monday', 'Saturday']}
        facility={{
          id: 1,
          name: text('Facility Name', faker.company.companyName()),
        }}
        klasses={klasses({
          numKlasses: number(
            'Number of Classes',
            Math.floor(Math.random() * 9) - 1
          ),
          date: moment(selectedDate).format('YYYY-MM-DD'),
        })}
        selectedDate={moment(selectedDate)}
        isFetchingDate={boolean('Fetching Date?', false)}
        isFetchingKlassList={boolean('Fetching Klass List?', false)}
        onDateSelected={action('Date selected')}
      />
    </MockFacilityProvider>
  )
}
