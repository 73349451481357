import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { createForm } from 'final-form'
import classNames from 'classnames'
import pluralize from 'pluralize'

import SelectInput from '../../leevo_ui/SelectInput/SelectInput'
import useFacility from '../../../utilities/hooks/useFacility'
import TextArea from '../../leevo_ui/TextArea/TextArea'
import Button from '../../leevo_ui/Button/Button'
import Notification from '../../leevo_ui/Notification/Notification'
import Input from '../../leevo_ui/Input/Input'
import Modal from '../../leevo_ui/Modal/Modal'
import CheckButton from '../../leevo_ui/CheckButton/CheckButton'
import ExposableContent from '../../leevo_ui/ExposableContent/ExposableContent'
import { presence } from '../../../utilities/validators'
import { requestCreator } from '../../../utilities/requests'

const {
  post: createDiscount,
  put: updateDiscount,
  cancel: cancelDiscountsRequests,
} = requestCreator()

function CouponCodeModal({ open, header, discount, onClose }) {
  const { facility } = useFacility()
  const [notification, setNotification] = useState({ show: false, message: '' })
  const initialReductionMethod = discount
    ? discount.reductionMethod
    : 'percentage'
  let initialDiscountAmount = 10
  if (discount) {
    initialDiscountAmount = discount.amount
    if (initialReductionMethod === 'fixed') {
      initialDiscountAmount = initialDiscountAmount / 100
    }
  }
  const formApi = createForm({
    initialValues: {
      reductionMethod: initialReductionMethod,
      active: discount ? discount.active : true,
      amount: initialDiscountAmount,
      code: discount ? discount.code : '',
      description: discount ? discount.description : '',
      maxRedemptions: discount ? discount.maxRedemptions : '',
    },
    onSubmit,
  })

  function onSubmit(values) {
    const updateOrCreate = discount ? updateDiscount : createDiscount
    let url = `/api/facilities/${facility.id}/coupon_codes`
    if (discount) url += `/${discount.id}`

    return updateOrCreate(url, {
      couponCode: {
        discountAttributes: {
          ...values,
          amount:
            values.reductionMethod === 'percentage'
              ? values.amount
              : values.amount * 100,
        },
        code: values.code,
        max_redemptions: values.maxRedemptions,
      },
    })
      .then(onClose)
      .catch((error) => {
        const { errors } = error.response.data
        let messages = []
        Object.keys(errors).forEach((key) => {
          const fieldName =
            key.charAt(0).toUpperCase() +
            key.replace('.', ' ').slice(1).toLowerCase()
          messages.push(
            <li key={key}>
              {fieldName} {errors[key][0]}
            </li>
          )
        })
        setNotification({
          show: true,
          message: (
            <ul
              className={classNames({
                'list-disc list-inside': messages.length > 1,
              })}
            >
              {messages}
            </ul>
          ),
        })
      })
  }

  useEffect(() => {
    return cancelDiscountsRequests
  }, [discount])

  function generateCode() {
    return Math.random().toString(16).substr(2, 7).toUpperCase()
  }

  function render({ handleSubmit, submitting, values, pristine }) {
    return (
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col-reverse md:flex-row md:items-center md:justify-between space-y-4">
          <div className="flex items-center space-x-2 mt-6 md:mt-0">
            <Field name="reductionMethod">
              {({ input }) => (
                <SelectInput
                  {...input}
                  id="discount-type"
                  className="w-32"
                  options={[
                    { label: 'Percentage', value: 'percentage' },
                    { label: 'Cash Value', value: 'fixed' },
                  ]}
                />
              )}
            </Field>
            <Field
              name="amount"
              validate={(value) => {
                if (!value) return 'Oops! This is required.'
                if (value < 0) return 'Must be positive'
              }}
            >
              {({ input }) => (
                <div className="flex justify-end">
                  <div className="relative text-sm rounded-md shadow-sm">
                    <div
                      className={classNames(
                        'absolute inset-y-0 left-0 flex items-center pl-3',
                        { hidden: values.reductionMethod === 'percentage' }
                      )}
                    >
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>

                    <input
                      {...input}
                      type="number"
                      min={1}
                      step={0.01}
                      name="amount"
                      className={classNames(
                        'block w-32 pr-14 rounded-md focus:ring-purple-300 focus:ring-opacity-50 focus:border-purple-500 sm:text-sm transition duration-200 ease-linear spin-button-none border-gray-300 text-gray-700',
                        {
                          'pl-7': values.reductionMethod !== 'percentage',
                          'pl-2 text-right':
                            values.reductionMethod === 'percentage',
                        }
                      )}
                    />

                    <div className="absolute inset-y-0 right-0 flex items-center px-2 border rounded-r-md font-medium border-gray-300 bg-gray-100 text-gray-500">
                      {values.reductionMethod === 'percentage' && '%'} off
                    </div>
                  </div>
                </div>
              )}
            </Field>
          </div>
          <Field name="active" type="checkbox">
            {({ input }) => (
              <div className="flex items-center space-x-2">
                <CheckButton
                  type="checkbox"
                  size={4}
                  {...input}
                  onClick={() => input.onChange(!input.checked)}
                />
                <span
                  className="font-medium text-gray-700 cursor-pointer"
                  onClick={() => input.onChange(!input.checked)}
                >
                  Active
                </span>
              </div>
            )}
          </Field>
        </div>
        <div className="flex justify-between items-center">
          <div>
            <Field name="code" validate={presence}>
              {({ input, meta: { error, active, touched } }) => (
                <Input
                  {...input}
                  id="code-input"
                  rows={1}
                  maxLength={8}
                  type="text"
                  className="w-56 mt-6 md:mt-8"
                  label="Code"
                  helpText="Customers enter this on checkout."
                  buttonLabel="Generate"
                  onButtonClick={() => input.onChange(generateCode())}
                  errorMessage={!active && error && touched ? error : ''}
                />
              )}
            </Field>
          </div>
          {discount && discount.numRedemptions !== null && (
            <div className="text-sm font-medium text-gray-900 text-right pt-3">
              <p>Redeemed</p>
              <p>
                <span className="font-semibold">{discount.numRedemptions}</span>{' '}
                {pluralize('time', discount.numRedemptions, false)}
              </p>
            </div>
          )}
        </div>
        <Field
          name="maxRedemptions"
          validate={(value) => {
            if (value < 0) return 'Must be positive'
          }}
        >
          {({ input }) => (
            <ExposableContent
              className="py-3"
              isInitiallyExposed={!!input.value}
              hiddenLabel="Add Redemption Limit"
              exposedLabel="Remove Redemption Limit"
              onHide={() => input.onChange('')}
            >
              <div className="flex items-center space-x-2 mt-6 mb-3 md:mt-0">
                <div className="flex justify-end">
                  <div className="relative text-sm rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 flex items-center px-3 border rounded-l-md font-medium border-gray-300 bg-gray-100 text-gray-500">
                      <span className="text-gray-500 sm:text-sm">
                        Redeemable
                      </span>
                    </div>
                    <input
                      {...input}
                      type="number"
                      min={1}
                      step={1}
                      name="max_redemptions"
                      className="block pr-16 rounded-md focus:ring-purple-300 focus:ring-opacity-49 focus:border-purple-500 sm:text-sm transition duration-200 ease-linear spin-button-none border-gray-300 text-gray-700 text-right"
                    />

                    <div className="absolute inset-y-0 right-0 flex items-center px-2 border rounded-r-md font-medium border-gray-300 bg-gray-100 text-gray-500">
                      {pluralize('time', parseInt(input.value), false)}
                    </div>
                  </div>
                </div>
              </div>
            </ExposableContent>
          )}
        </Field>
        <Field name="description">
          {({ input }) => (
            <ExposableContent
              isInitiallyExposed={!!input.value}
              hiddenLabel="Add Description (optional)"
              exposedLabel="Remove Description"
              onHide={() => input.onChange('')}
            >
              <TextArea
                {...input}
                id="description"
                rows={1}
                maxLength={100}
                className="mt-6 md:mt-8"
                label="Description"
                helpText="This won't be shown to customers."
              />
            </ExposableContent>
          )}
        </Field>

        <div className="flex items-center justify-end mt-5 sticky bottom-0 right-0 bg-white">
          <Notification show={notification.show} color="red" className="mr-2">
            {notification.message}
          </Notification>
          <Button
            type="submit"
            data-cy="submit"
            label="Submit"
            disabled={submitting || pristine}
            loading={submitting}
          />
        </div>
      </form>
    )
  }

  return (
    <Modal open={open} header={header} onClickClose={onClose}>
      <Form form={formApi} render={render} />
    </Modal>
  )
}

CouponCodeModal.defaultProps = {
  discount: null,
}

CouponCodeModal.propTypes = {
  discount: PropTypes.object,
  open: PropTypes.bool,
  header: PropTypes.string,
  onClose: PropTypes.func,
}

export default CouponCodeModal
