import React from 'react'
import { mount } from 'enzyme'
import { MemoryRouter, Route } from 'react-router-dom'
import flushPromises from 'flush-promises'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { act } from 'react-dom/test-utils'

import AcceptInvitationFeature from './AcceptInvitationFeature'
import MockAuthProvider from '../../providers/MockAuthProvider'

describe('AcceptInvitationFeature', () => {
  let wrapper

  beforeEach(async () => {
    wrapper = mount(
      <MockAuthProvider isLoading>
        <MemoryRouter
          initialEntries={['/auth/invitation/accept?invitation_token=foobar']}
        >
          <Route path="/auth/invitation/accept">
            <AcceptInvitationFeature />
          </Route>
        </MemoryRouter>
      </MockAuthProvider>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  it('renders a loading page', () => {
    expect(wrapper.find('[data-cy="loading-page"]')).toExist()
  })

  describe('with all data loaded', function () {
    beforeEach(async () => {
      wrapper = mount(
        <MockAuthProvider>
          <MemoryRouter
            initialEntries={['/auth/invitation/accept?invitation_token=foobar']}
          >
            <Route path="/auth/invitation/accept">
              <AcceptInvitationFeature />
            </Route>
          </MemoryRouter>
        </MockAuthProvider>
      )
    })

    it('renders a full name input', () => {
      expect(wrapper.find('[data-cy="fullName-input"]')).toExist()
    })

    it('renders a preferred name input', () => {
      expect(wrapper.find('[data-cy="preferredName-input"]')).toExist()
    })

    it('renders a new password input', () => {
      expect(wrapper.find('[data-cy="password-input"]')).toExist()
    })

    it('renders a password confirmation input', () => {
      expect(wrapper.find('[data-cy="password-confirmation-input"]')).toExist()
    })

    // FIXME: Couldn't get this to fire the submit handler. Should come back and rewrite.
    // eslint-disable-next-line
    describe.skip('on successfully submitting to accept the invitation', () => {
      let mockAxios

      beforeEach(async () => {
        mockAxios = new MockAdapter(axios)
        mockAxios.onPut().reply(200, {})
        await act(async () => {
          wrapper.find('[data-cy="submit"]').hostNodes().simulate('click')
          await flushPromises()
          wrapper.update()
        })
      })

      it('sends a patch to the invitations path', () => {
        expect(mockAxios.history.put.length).toBe(1)
      })
    })

    // eslint-disable-next-line
    describe.skip('on unsuccessfully submitting to accept the invitation')
  })
})
