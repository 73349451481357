import React from 'react'
import HandlingLTSRegistrations from './HandlingLTSRegistrations'

export default {
  title: 'Leevo UI/HandlingLTSRegistrations',
  component: HandlingLTSRegistrations,
  args: {},
}

export function handlingLtsRegistrations(args) {
  return <HandlingLTSRegistrations {...args} />
}
