import React from 'react'
import { mount } from 'enzyme'
import { Form } from 'react-final-form'
import { Router } from 'react-router-dom'
import { createMemoryHistory } from 'history'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

import SkillLevelField from './SkillLevelField'

const mockAxios = new MockAdapter(axios)

describe('SkillLevelField', () => {
  let wrapper
  const mockHistory = createMemoryHistory()

  beforeEach(() => {
    const mockResponse = {
      data: [
        { id: '1', type: 'skillLevel', attributes: { name: 'Basic 1' } },
        { id: '2', type: 'skillLevel', attributes: { name: 'Basic 2' } },
      ],
    }

    mockAxios.reset()
    mockAxios.onGet().reply(200, mockResponse)

    wrapper = mount(
      <Form
        onSubmit={jest.fn()}
        render={() => (
          <Router history={mockHistory}>
            <SkillLevelField className="foo" />
          </Router>
        )}
      />
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders className on the topmost div', () => {
    expect(wrapper.find('div').at(0)).toHaveClassName('foo')
  })

  test('renders the placeholder option', () => {
    const firstOption = wrapper.find('option').at(0)
    expect(firstOption).toHaveText('Select Level')
    expect(firstOption).toBeDisabled()
    expect(firstOption).toHaveValue('')
  })

  test("renders an 'I don't know' placeholder option", () => {
    wrapper.update() // Update after the useEffect hook runs
    const secondOption = wrapper.find('option').at(1)
    expect(secondOption).toHaveText("I don't know")
    expect(secondOption).toHaveValue('-1')
  })

  test('renders the options from the additional two options from server response', () => {
    wrapper.update() // Update after the useEffect hook runs
    expect(wrapper.find('option')).toHaveLength(4)
  })

  describe('name', () => {
    describe('when not present', () => {
      beforeEach(() => {
        wrapper = mount(
          <Form
            onSubmit={jest.fn()}
            render={() => (
              <Router history={mockHistory}>
                <SkillLevelField />
              </Router>
            )}
          />
        )
      })

      test("renders 'the student' in the question", () => {
        expect(wrapper.first('label')).toIncludeText('the student')
      })

      test("renders 'the student' in the help text", () => {
        expect(wrapper.first('p')).toIncludeText('the student')
      })
    })

    describe('when present', () => {
      beforeEach(() => {
        wrapper = mount(
          <Form
            onSubmit={jest.fn()}
            render={() => (
              <Router history={mockHistory}>
                <SkillLevelField name="Meg" />
              </Router>
            )}
          />
        )
      })

      test('renders the name in the question', () => {
        expect(wrapper.first('label')).toIncludeText('Meg')
      })

      test('renders the name in the help text', () => {
        expect(wrapper.first('p')).toIncludeText('Meg')
      })
    })
  })
})
