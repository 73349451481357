import React from 'react'
import PropTypes from 'prop-types'

import Recommendation from '../Recommendation/Recommendation'

function RecommendationLevelCard({ recommendations }) {
  const levelName = recommendations[0].skillLevel.name
  return (
    <div
      className="mx-1 bg-white shadow rounded-xl"
      data-cy={`recommendation-level-card-for-${levelName}`}
    >
      <div className="flex items-start justify-between p-5 border-b border-gray-300">
        <p className="text-lg font-medium">
          <p>{levelName} Recommendations</p>
        </p>
      </div>

      {recommendations.map(({ id, skill, count, status }) => (
        <Recommendation
          key={id}
          shortLabel={skill.shortLabel}
          name={skill.name}
          studentCount={count}
          status={status}
        />
      ))}
    </div>
  )
}

RecommendationLevelCard.defaultProps = {
  recommendations: [],
}

RecommendationLevelCard.propTypes = {
  recommendations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      count: PropTypes.number.isRequired,
      skill: PropTypes.shape({
        shortLabel: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      skillLevel: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      status: PropTypes.oneOf(['unintroduced', 'introduced']).isRequired,
    })
  ).isRequired,
}

export default RecommendationLevelCard
