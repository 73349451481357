import React from 'react'
import { mount } from 'enzyme'
import { MemoryRouter, Route } from 'react-router-dom'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { act } from 'react-dom/test-utils'
import flushPromises from 'flush-promises'

import FacilitiesKlassesIndex from './FacilitiesKlassesIndex'
import MockFacilityProvider from '../../providers/MockFacilityProvider'

describe('FacilitiesKlassesIndex', () => {
  let wrapper

  beforeEach(() => {
    const mockAxios = new MockAdapter(axios)
    mockAxios.reset()
    mockAxios.onGet('/api/facilities/1').reply(200, {
      data: {
        id: 1,
        type: 'facility',
        attributes: {
          name: 'fake facility',
        },
      },
    })
    mockAxios.onGet('/api/facilities/1/klasses').reply(200, {
      data: [
        {
          attributes: {
            dayOfWeek: 'Mon',
            endDate: 'Mar 9',
            maxStudents: 10,
            price: 5000,
            priceInDollars: '$50.00',
            remainingKlassDays: 5,
            spotsLeft: 10,
            startDate: 'Mar 9',
            timeOfDay: '4:00 AM',
          },
          id: '1',
          relationships: {
            coaches: {
              data: [
                { id: '5', type: 'coach' },
                { id: '6', type: 'coach' },
              ],
            },
            facility: {
              data: {
                id: '1',
                type: 'facility',
              },
            },
            klasses: {
              data: [],
            },
            students: {
              data: [],
            },
            supportedSkillLevels: {
              data: [
                {
                  id: '1',
                  type: 'skillLevel',
                },
              ],
            },
          },
          type: 'klass',
        },
        {
          attributes: {
            dayOfWeek: 'Mon',
            endDate: 'Mar 9',
            maxStudents: 10,
            price: 5000,
            priceInDollars: '$50.00',
            remainingKlassDays: 0,
            spotsLeft: 10,
            startDate: 'Mar 9',
            timeOfDay: '4:00 AM',
          },
          id: '2',
          relationships: {
            coaches: {
              data: [
                { id: '5', type: 'coach' },
                { id: '6', type: 'coach' },
              ],
            },
            facility: {
              data: {
                id: '1',
                type: 'facility',
              },
            },
            klasses: {
              data: [],
            },
            students: {
              data: [],
            },
            supportedSkillLevels: {
              data: [
                {
                  id: '1',
                  type: 'skillLevel',
                },
                {
                  id: '2',
                  type: 'skillLevel',
                },
              ],
            },
          },
          type: 'klass',
        },
      ],
      included: [
        {
          attributes: {
            email: 'user5@example.com',
            fullName: 'Jane Doe',
            cloudinaryPhotoPublicId: null,
            preferredName: 'Janey',
          },
          id: '5',
          type: 'coach',
        },
        {
          attributes: {
            email: 'user6@example.com',
            fullName: 'Emily Johnson',
            cloudinaryPhotoPublicId: null,
            preferredName: 'Emmy',
          },
          id: '6',
          type: 'coach',
        },
        {
          attributes: {
            name: 'Basic 1',
          },
          id: '1',
          relationships: {
            nextSkillLevel: {
              data: null,
            },
            skills: {
              data: [
                {
                  id: '1',
                  type: 'skill',
                },
              ],
            },
          },
          type: 'skillLevel',
        },
        {
          attributes: {
            name: 'Basic 2',
          },
          id: '2',
          relationships: {
            nextSkillLevel: {
              data: null,
            },
            skills: {
              data: [
                {
                  id: '2',
                  type: 'skill',
                },
              ],
            },
          },
          type: 'skillLevel',
        },
      ],
    })

    act(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/facilities/1/classes']}>
          <Route path="/facilities/:facility_id/classes">
            <MockFacilityProvider>
              <FacilitiesKlassesIndex />
            </MockFacilityProvider>
          </Route>
        </MemoryRouter>
      )
    })
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  describe('with all data loaded', function () {
    beforeEach(async () => {
      // Not quite sure why we need to flush then update repeatedly..
      await flushPromises()
      act(() => wrapper.update())
      await flushPromises()
      act(() => wrapper.update())
    })

    test('renders a card for each klass provided from server', () => {
      const list_items = wrapper.find('[data-cy="klass-item"]').hostNodes()
      expect(list_items.length).toBe(2)
    })
  })
})
