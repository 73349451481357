import React from 'react'
import faker from 'faker'
import { text } from '@storybook/addon-knobs'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { action } from '@storybook/addon-actions'

import StudentEditForm from './StudentEditForm'

export default {
  title: 'Leevo UI/Student Edit Form',
  component: StudentEditForm,
  args: {
    requiresAddress: false,
    requiresBirthdate: false,
    requiresGender: false,
    requiresLtsNumber: false,
    GuardianType: 'Active',
    facilityProvidesFreeRentalSkates: true,
    facilitySupportsSkating: true,
  },
  argTypes: {
    GuardianType: {
      control: { type: 'radio', options: ['None', 'Invited', 'Active'] },
    },
  },
}

const mockSkillLevelResponse = {
  data: [
    {
      id: '1',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 1',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '2',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 2',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '3',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 3',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '4',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 4',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '5',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '5',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 5',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '6',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '6',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 6',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '11',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '7',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 1',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '8',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 2',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '9',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '9',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 3',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '10',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '10',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 4',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '11',
      type: 'skillLevel',
      attributes: {
        name: 'Pre-Free Skate',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '12',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '12',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 1',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '13',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '13',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 2',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '14',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '14',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 3',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '15',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '15',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 4',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '16',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '16',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 5',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '17',
            type: 'skillLevel',
          },
        },
      },
    },
    {
      id: '17',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 6',
      },
      relationships: {
        nextSkillLevel: {
          data: null,
        },
      },
    },
  ],
}

export function empty(args) {
  const mockAxios = new MockAdapter(axios)

  mockAxios.reset()
  mockAxios.onGet().reply(200, mockSkillLevelResponse)

  return <StudentEditForm {...args} onSubmit={action('Submitted Form')} />
}

export function prePopulated(args) {
  const mockAxios = new MockAdapter(axios)

  mockAxios.reset()
  mockAxios.onGet().reply(200, mockSkillLevelResponse)

  const preferredName = text('Preferred Name', faker.name.firstName())
  const fullName = text(
    'Full Name',
    `${preferredName} ${faker.name.lastName()}`
  )
  const gender = 'female'
  const ltsNumber = '123456'
  const skillLevel = '14'
  const GuardianEmail = faker.internet.email()

  let guardian
  if (args.GuardianType === 'Invited') {
    guardian = {
      email: GuardianEmail,
      pending: true,
    }
  } else if (args.GuardianType === 'Active') {
    guardian = {
      fullName: faker.name.findName(),
      phone: '+13478038847',
      email: GuardianEmail,
      pending: false,
    }
  }
  return (
    <StudentEditForm
      {...args}
      preferredName={preferredName}
      fullName={fullName}
      gender={gender}
      ltsNumber={ltsNumber}
      skillLevel={skillLevel}
      birthdate={new Date()}
      guardian={guardian}
      address={{
        line1: '2036 East 55 St.',
        city: 'Brooklyn',
        state: 'NY',
        postCode: '11234',
      }}
      onSubmit={action('Submitted Form')}
    />
  )
}
