import React from 'react'
import LinkingStripe from './LinkingStripe'

export default {
  title: 'Leevo UI/LinkingStripe',
  component: LinkingStripe,
  args: {},
}

export function linkingStripe(args) {
  return <LinkingStripe {...args} />
}
