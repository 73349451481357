import React from 'react'
import { mount } from 'enzyme'
import { Form } from 'react-final-form'

import HasLTSNumberField from './HasLTSNumberField'

describe('HasLTSNumberField', () => {
  let wrapper
  beforeEach(() => {
    wrapper = mount(
      <Form onSubmit={jest.fn()} render={() => <HasLTSNumberField />} />
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders className on the LargeRadioInput', () => {
    wrapper = mount(
      <Form
        onSubmit={jest.fn()}
        render={() => <HasLTSNumberField className="foo" />}
      />
    )
    expect(wrapper.find('[data-cy="large-radio-input"]')).toHaveClassName('foo')
  })

  describe('name', () => {
    describe('when not present', () => {
      test("renders 'the skater' in the question", () => {
        expect(wrapper.first('label')).toIncludeText('this skater')
      })
    })

    describe('when present', () => {
      beforeEach(() => {
        wrapper = mount(
          <Form
            onSubmit={jest.fn()}
            render={() => <HasLTSNumberField name="Meg" />}
          />
        )
      })

      test('renders the name in the question', () => {
        expect(wrapper.first('label')).toIncludeText('Meg')
      })
    })
  })
})
