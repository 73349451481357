import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { createForm } from 'final-form'
import MultiSelect from 'react-multi-select-component'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { get, orderBy } from 'lodash'

import CardFormSection from '../CardFormSection/CardFormSection'
import CheckButton from '../CheckButton/CheckButton'
import Avatar from '../Avatar/Avatar'
import Input from '../Input/Input'
import MultiSelectDayDateTime from './MultiSelectDayDateTime/MultiSelectDayDateTime'
import MultiSelectKlassPackage from './MultiSelectKlassPackage/MultiSelectKlassPackage'
import MultiSelectStudent from './MultiSelectStudent/MultiSelectStudent'
import {
  alphabetizeByFullName,
  selectedToTop,
} from '../../../utilities/helpers'
import { requestCreator } from '../../../utilities/requests'

const { get: getStudents, cancel: cancelStudentsRequests } = requestCreator()

function KlassForm({
  onSubmit,
  dates,
  coaches,
  students: selectedStudents,
  supportedSkillLevels,
  klassPackages: selectedKlassPackages,
  maxStudents,
}) {
  const { facility_id: facilityId } = useParams()
  const formApi = createForm({
    mutators: {
      setIsStudentsLoading: ([loading], state) => {
        if (state.fields.students) {
          state.fields.students.data = {
            ...state.fields.students.data,
            loading,
          }
        }
      },
      swapStudentList([newStudents], state, { changeValue }) {
        changeValue(state, 'students', () => {
          const isSwapping = true
          const newValue = this.determineSelections(
            [newStudents, isSwapping],
            state
          )
          return newValue
        })
        this.setIsStudentsLoading([false], state)
      },
      updateStudentList(_args, state, { changeValue }) {
        changeValue(state, 'students', () => {
          const newValue = this.determineSelections(
            [state.formState.values.students],
            state
          )
          return newValue
        })
        this.setIsStudentsLoading([false], state)
      },
      determineSelections([students, isSwapping], state) {
        const newStudentsList = students.map((student) => {
          let selected
          const selectedStudent = state.formState.values.students.find(
            (selected) => selected.id === student.id
          )
          if (get(selectedStudent, 'isLocked')) return selectedStudent

          const preloadedStudent = selectedStudents.find(
            (s) => s.id === get(selectedStudent, 'id')
          )

          const wasStudentOriginallyOmitted =
            !preloadedStudent &&
            this.isStudentInSelectedKlassPackage([student], state)

          if (isSwapping && wasStudentOriginallyOmitted) {
            selected = false
          } else {
            selected =
              selectedStudents.map((s) => s.id).includes(student.id) ||
              this.isStudentInSelectedKlassPackage([student], state)
          }
          return { ...student, selected }
        })

        return orderBy(newStudentsList, ['selected', 'fullName'], ['desc'])
      },
      isStudentInSelectedKlassPackage: ([student], state) => {
        return state.formState.values.klassPackages
          .filter((kp) => kp.selected)
          .map((kp) => kp.students)
          .flat()
          .map((s) => s.id)
          .includes(student.id)
      },
    },
    initialValues: {
      dates,
      coaches: coaches.filter((x) => x.selected),
      supportedSkillLevels: supportedSkillLevels.filter((x) => x.selected),
      klassPackages: selectedKlassPackages,
      students: orderBy(selectedStudents, ['selected', 'fullName'], ['desc']),
      maxStudents,
    },
    validate: ({ dates }) => {
      let errors = {}
      if (!dates || dates.length < 1) {
        errors.dates = {
          message: 'A class must have at least one date selected.',
        }
      } else if (dates.filter((v) => !v.isTimeSet).length > 0) {
        errors.dates = {
          dates: dates.filter((v) => !v.isTimeSet).map((v) => v.date),
          message: 'All selected class days must also have a time set.',
        }
      } else {
        errors = null
      }
      return errors
    },
    onSubmit,
  })

  useEffect(() => {
    getStudents(`/api/facilities/${facilityId}/students`, {
      params: {
        ids: selectedStudents.map((student) => student.id),
        skillLevels: supportedSkillLevels
          .filter((skillLevel) => skillLevel.selected)
          .map((skillLevel) => skillLevel.id),
        include: 'current_skill_level',
        fields: {
          student: 'fullName,cloudinaryPhotoId,current_skill_level,age',
          skill_level: 'name',
        },
      },
    }).then(({ records: studentList }) => {
      formApi.mutators.swapStudentList(studentList)
    })
    return cancelStudentsRequests
  }, [supportedSkillLevels, selectedStudents, formApi, facilityId])

  return (
    <Form
      form={formApi}
      render={({ form, handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit} className="p-4">
            <CardFormSection
              title="Class Times"
              description={
                <section className="space-y-2">
                  <p>Classes run on one day of the week.</p>
                  <p>You can bundle multiple classes in class packages.</p>
                </section>
              }
              submitting={submitting}
            >
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <Field name="dates">
                    {({ input, meta: { error, submitFailed, active } }) => (
                      <MultiSelectDayDateTime
                        {...input}
                        error={!active && error && submitFailed ? error : null}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </CardFormSection>
            <CardFormSection
              title="Coaching Settings"
              description={
                <section className="space-y-2">
                  <p>
                    Pick the coaches that will be teaching this class and the
                    skill levels they can teach.
                  </p>
                  <p>
                    If you want students to stay with the same coach as they
                    progress, add all of those level to the class.
                  </p>
                </section>
              }
              submitting={submitting}
            >
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <Field name="coaches">
                    {({ input, meta: { active, error, submitFailed } }) => (
                      <div data-cy="coaches-select">
                        <label
                          className={`${classNames({
                            'text-red-500 border-red-500':
                              !active && error && submitFailed,
                          })} block text-gray-800 text-sm font-medium ml-1`}
                          htmlFor="coaches-input"
                        >
                          Who will be coaching this class?
                        </label>
                        <MultiSelect
                          id="coaches-input"
                          className="mt-1 text-gray-800"
                          options={coaches
                            .sort(alphabetizeByFullName)
                            .sort(selectedToTop)}
                          value={input.value}
                          onChange={input.onChange}
                          labelledBy="Select Coaches"
                          hasSelectAll={false}
                          ItemRenderer={({
                            checked,
                            option,
                            onClick,
                            disabled,
                          }) => {
                            return (
                              <div
                                className="flex items-center space-x-2"
                                data-cy={`coach-${option.preferredName}`}
                              >
                                <CheckButton
                                  onClick={onClick}
                                  checked={checked}
                                  disabled={disabled}
                                  tabIndex={-1}
                                  size={4}
                                />
                                <Avatar
                                  fullName={option.fullName}
                                  cloudinaryPhotoPublicId={
                                    option.cloudinaryPhotoPublicId
                                  }
                                  size={8}
                                  userId={option.id}
                                />
                                <span>{option.label}</span>
                              </div>
                            )
                          }}
                          valueRenderer={(selected, _options) => {
                            if (selected.length === 0) {
                              return 'Select coaches'
                            } else if (selected.length < 3) {
                              return selected
                                .map(({ label, email }) => label || email)
                                .join(', ')
                            } else {
                              return `${selected.length} coaches selected`
                            }
                          }}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <Field name="supportedSkillLevels">
                    {({ input, meta: { active, error, submitFailed } }) => (
                      <div data-cy="skill-levels-select">
                        <label
                          className={`${classNames({
                            'text-red-500': !active && error && submitFailed,
                          })} block text-gray-800 text-sm font-medium ml-1`}
                          htmlFor="skill-levels-input"
                        >
                          What levels will they be coaching?
                        </label>
                        <MultiSelect
                          className="text-gray-800"
                          options={supportedSkillLevels
                            .sort(alphabetizeByFullName)
                            .sort(selectedToTop)}
                          value={input.value}
                          onChange={(newSkillLevels) => {
                            input.onChange(newSkillLevels)
                            form.mutators.setIsStudentsLoading(true)
                            getStudents(
                              `/api/facilities/${facilityId}/students`,
                              {
                                params: {
                                  skillLevels: newSkillLevels.map(
                                    (skillLevel) => skillLevel.id
                                  ),
                                  include: 'current_skill_level',
                                  fields: {
                                    student:
                                      'fullName,cloudinaryPhotoId,current_skill_level,age',
                                    skill_level: 'name',
                                  },
                                },
                              }
                            ).then(({ records: studentList }) => {
                              formApi.mutators.swapStudentList(studentList)
                            })
                          }}
                          labelledBy="What levels will they be coaching?"
                          hasSelectAll={false}
                          ItemRenderer={({
                            checked,
                            option,
                            onClick,
                            disabled,
                          }) => {
                            return (
                              <div
                                className="flex items-center space-x-2"
                                data-cy={option.label}
                              >
                                <CheckButton
                                  onClick={onClick}
                                  checked={checked}
                                  disabled={disabled}
                                  tabIndex={-1}
                                  size={4}
                                />
                                <span>{option.label}</span>
                              </div>
                            )
                          }}
                          valueRenderer={(selected, _options) => {
                            if (selected.length === 0) {
                              return 'No skill level requirement'
                            } else if (selected.length === 1) {
                              return selected
                                .map(({ label }) => label)
                                .join(', ')
                            } else {
                              return `${selected.length} skill levels selected`
                            }
                          }}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <Field name="maxStudents">
                    {({ input, meta: { active, error, submitFailed } }) => (
                      <Input
                        {...input}
                        id="max_students"
                        className="w-full mr-3"
                        type="number"
                        min={0}
                        label="How many spots are in this class?"
                        placeholder="Leave blank for unlimited spots"
                        errorMessage={
                          !active && error && submitFailed ? error : ''
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>
            </CardFormSection>
            <CardFormSection
              title="Class Packages"
              description={
                <section className="space-y-2">
                  <p>
                    When a student buys a package, we auto-place them in its
                    classes.
                  </p>
                  <p>
                    When promoted, if their current class does not support their
                    new level, we move them to another class in the package.
                  </p>
                  <p>
                    Leevo will only add a student to one class per day for their
                    level.
                  </p>
                </section>
              }
              submitting={submitting}
            >
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <Field name="klassPackages">
                    {({ input }) => (
                      <MultiSelectKlassPackage
                        {...input}
                        onChange={(newKlassPackages) => {
                          input.onChange(newKlassPackages)
                          form.mutators.updateStudentList()
                        }}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </CardFormSection>
            <CardFormSection
              title="Students"
              className="px-4"
              description={
                <section className="space-y-2">
                  <p>
                    If you must add students manually, you should{' '}
                    <span className="font-semibold">not</span> add them here.
                    You should add them to the package for this class, instead.
                  </p>
                  <p>
                    You can use this section to manually add students to classes
                    for exceptional cases.
                  </p>
                </section>
              }
              submitting={submitting}
            >
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <Field name="students">
                    {({ input, meta: { data } }) => {
                      return (
                        <MultiSelectStudent
                          {...input}
                          onChange={(students) => {
                            input.onChange(
                              students.map((student) => {
                                const isStudentLocked =
                                  student.isLocked ||
                                  input.value.find((s) => s.id === student.id)
                                    .selected !== student.selected
                                return {
                                  ...student,
                                  isLocked: isStudentLocked,
                                }
                              })
                            )
                          }}
                          loading={data.loading}
                        />
                      )
                    }}
                  </Field>
                </div>
              </div>
            </CardFormSection>
          </form>
        )
      }}
    />
  )
}

KlassForm.defaultProps = {
  dates: [],
  coaches: [],
  supportedSkillLevels: [],
  klassPackages: [],
  students: [],
}

KlassForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  dates: PropTypes.array,
  coaches: PropTypes.array,
  klassPackages: PropTypes.array,
  supportedSkillLevels: PropTypes.array,
  students: PropTypes.array,
  maxStudents: PropTypes.number,
}

export default KlassForm
