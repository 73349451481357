import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { get as _get } from 'lodash'
import mixpanel from '../../utilities/mixpanel'

import ForgotPasswordModal from '../leevo_ui/ForgotPasswordModal/ForgotPasswordModal'

export default function ForgotPasswordFeature() {
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  function onForgotPasswordSubmit({ email }) {
    setSuccessMessage('')
    setErrorMessage('')
    return axios
      .post('/auth/password', {
        email,
        redirect_url: `${window.location.origin}/password/new`,
      })
      .then(({ data }) => {
        mixpanel.track('Password Reset Initiated')
        setSuccessMessage(data.message)
      })
      .catch((error) => {
        mixpanel.track('Password Reset Initiation Failed')
        if (_get(error, 'response.data.errors')) {
          setErrorMessage(error.response.data.errors.join(' '))
        } else {
          setErrorMessage(error.toString())
        }
      })
  }

  return (
    <>
      <Helmet>
        <title>Forgot Password | Leevo</title>
      </Helmet>
      <ForgotPasswordModal
        errorMessage={errorMessage}
        onForgotPasswordSubmit={onForgotPasswordSubmit}
        successMessage={successMessage}
      />
    </>
  )
}
