import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { get as _get } from 'lodash'

import Notification from '../leevo_ui/Notification/Notification'
import StudentEditForm from '../leevo_ui/StudentEditForm/StudentEditForm'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import { requestCreator } from '../../utilities/requests'
import useFacility from '../../utilities/hooks/useFacility'
import CardFormSection from '../leevo_ui/CardFormSection/CardFormSection'

const {
  get: getStudent,
  put: updateStudent,
  cancel: cancelStudentRequests,
} = requestCreator()

function StudentsEditFeature() {
  const history = useHistory()
  const [fields, setFields] = useState({ klassesAsStudent: [] })
  const [isLoading, setIsLoading] = useState(true)
  const [notification, setNotification] = useState({
    show: false,
    success: null,
    message: '',
  })
  const { student_id: studentId, facility_id: facilityId } = useParams()
  const { facility, isLoading: isFacilityLoading } = useFacility()

  useEffect(() => {
    if (!isFacilityLoading) {
      getStudent(`/api/facilities/${facilityId}/users/${studentId}`, {
        params: {
          include:
            'address,guardians,skate_rental,klasses_as_student,klasses_as_student.coaches',
        },
      })
        .then((student) => {
          student.skillLevel = _get(student, 'currentSkillLevel.id')
          setFields(student)
          setIsLoading(false)
        })
        .catch((error) => !axios.isCancel(error) && history.push('/login'))
    }
    return cancelStudentRequests
  }, [facilityId, history, isFacilityLoading, studentId])

  function onSubmit(values) {
    let message = 'Changes saved!'
    return updateStudent(`/api/facilities/${facilityId}/users/${studentId}`, {
      include:
        'address,guardians,skate_rental,klasses_as_student,klasses_as_student.coaches',
      user: {
        ...values,
        skateRental: undefined,
        skateRentalAttributes: {
          active: values.skateRental.active,
          skateType: values.skateRental.skateType,
          size: values.skateRental.size,
        },
      },
    })
      .then((student) => {
        student.skillLevel = _get(student, 'currentSkillLevel.id')
        let skateRental
        if (student.skateRental) {
          skateRental = {
            active: student.skateRental.active,
            skateType: student.skateRental.skateType,
            size: student.skateRental.size,
          }
        }
        setFields({ ...student, skateRental })
        setNotification({ show: true, success: true, message })
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          message =
            _get(error, 'response.data.error') ||
            'We were unable to save your changes.'
          setNotification({ show: true, success: false, message })
        }
      })
  }

  return (
    <ApplicationLayout isContentLoading={isLoading}>
      <Notification
        show={notification.show}
        color={notification.success ? 'green' : 'red'}
        className="sticky top-0"
        onDisappearTimeout={() =>
          setNotification({ ...notification, show: false })
        }
      >
        {notification.message}
      </Notification>
      <StudentEditForm
        {...fields}
        {...(facility.curriculums && facility.curriculums[0])}
        facilitySupportsSkating={facility.supportsSkating}
        facilityProvidesFreeRentalSkates={facility.providesFreeRentalSkates}
        id={studentId}
        facilityId={facilityId}
        onSubmit={onSubmit}
      />
      <div className="flex justify-center p-5 bg-gray-100">
        <div className="max-w-4xl w-full">
          <CardFormSection
            title="Class Information"
            description="See what classes in which the student is currently enrolled."
            showSubmit={false}
          >
            <ul>
              {fields.klassesAsStudent.length === 0 && (
                <p className="font-medium">
                  {fields.preferredName} has not been enrolled in any active
                  classes.
                </p>
              )}
              {fields.klassesAsStudent.map(
                ({ id, dayOfWeek, timeOfDay, startDate, endDate, coaches }) => (
                  <li
                    key={id}
                    className="flex items-center justify-between space-x-2"
                  >
                    <p className="font-semibold w-1/3">
                      {dayOfWeek} - {timeOfDay}
                    </p>
                    <p className="text-xs font-medium text-gray-700 xs:text-sm  w-1/3 text-center">
                      {startDate} to {endDate}
                    </p>
                    <p className="font-medium w-1/3 truncate text-right">
                      {coaches
                        .map(({ preferredName }) => preferredName)
                        .join(', ')}
                    </p>
                  </li>
                )
              )}
            </ul>
          </CardFormSection>
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default StudentsEditFeature
