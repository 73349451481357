import React from 'react'
import PropTypes from 'prop-types'
import { useParams, Link } from 'react-router-dom'
import pluralize from 'pluralize'

import Avatar from '../../leevo_ui/Avatar/Avatar'
import Badge from '../../leevo_ui/Badge/Badge'
import Card from '../../leevo_ui/Card/Card'
import { levelArrayToString } from '../../../utilities/helpers'

function KlassCard({
  id,
  dayOfWeek,
  timeOfDay,
  startDate,
  endDate,
  coaches,
  supportedSkillLevels,
  maxStudents,
  students,
}) {
  const { facility_id: facilityId } = useParams()

  let spotsLeft
  if (maxStudents !== null && maxStudents !== undefined) {
    const difference = maxStudents - students.length
    spotsLeft = difference > 0 ? difference : 0
  }

  let badgeColor
  if (maxStudents === null || maxStudents === undefined || spotsLeft >= 4) {
    badgeColor = 'green'
  } else if (spotsLeft < 4 && spotsLeft > 0) {
    badgeColor = 'orange'
  } else {
    badgeColor = 'red'
  }

  let badgeText
  if (maxStudents === null || maxStudents === undefined) {
    badgeText = 'Available'
  } else if (spotsLeft > 0) {
    badgeText = `${spotsLeft} ${pluralize('spot', spotsLeft)} left`
  } else {
    badgeText = 'Full'
  }

  return (
    <Card
      borderColor={badgeColor}
      data-cy="klass-item"
      className="relative flex flex-col m-2 w-80"
    >
      <header className="flex justify-between px-4 py-2 mb-3">
        <div>
          <p className="font-semibold xs:text-lg">
            {dayOfWeek} - {timeOfDay}
          </p>
          <p className="text-xs font-medium text-gray-700 xs:text-sm">
            {startDate} to {endDate}
          </p>
        </div>
        <div className="text-right">
          <p className="text-xs text-gray-700 xs:text-sm">
            <span className="font-semibold">{students.length}</span>{' '}
            {pluralize('student', students.length)}
          </p>
          <Badge text={badgeText} color={badgeColor} />
        </div>
      </header>
      <section className="flex items-end justify-between px-4">
        <p className="text-xs text-gray-700 xs:text-sm">
          {supportedSkillLevels.length === 0
            ? 'Any'
            : levelArrayToString(
                supportedSkillLevels.map((s) => s.name),
                { maxLength: 25 }
              )}
        </p>
        <div className="flex justify-end overflow-hidden">
          {coaches.map((coach, index) => (
            <Avatar
              key={coach.id}
              fullName={coach.fullName}
              cloudinaryPhotoPublicId={coach.cloudinaryPhotoPublicId}
              alt={`Photo of ${coach.fullName}`}
              userId={coach.id}
              size={10}
              className={`${
                index != 0 ? '-ml-3' : ''
              } inline-block rounded-full border-white border-2`}
            />
          ))}
        </div>
      </section>
      <footer className="flex divide-x divide-gray-300">
        <Link
          to={`/facilities/${facilityId}/classes/${id}/edit`}
          className="w-1/2 py-4 mt-3 text-center border-t border-gray-300 rounded-b-lg cursor-pointer hover:bg-gray-100 text-sm font-medium"
          data-cy="edit"
        >
          Edit
        </Link>
        <Link
          to={`/facilities/${facilityId}/classes/new?copy_id=${id}`}
          className="text-sm font-medium w-1/2 py-4 mt-3 text-center border-t border-gray-300 cursor-pointer hover:bg-gray-100"
          data-cy="edit-class-package-button"
        >
          Copy
        </Link>
      </footer>
    </Card>
  )
}

KlassCard.defaultProps = {
  students: [],
  coaches: [],
  supportedSkillLevels: [],
  maxStudents: null,
}

KlassCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  coaches: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string.isRequired,
      cloudinaryPhotoPublicId: PropTypes.string,
    })
  ),
  dayOfWeek: PropTypes.string,
  timeOfDay: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  supportedSkillLevels: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string })
  ),
  students: PropTypes.array,
  maxStudents: PropTypes.number,
  spotsLeft: PropTypes.number,
}

export default KlassCard
