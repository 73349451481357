import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { MemoryRouter, Route } from 'react-router-dom'

import FacilitiesKlassesEdit from './FacilitiesKlassesEdit'

export default {
  title: 'FacilitiesKlassesEdit',
  component: FacilitiesKlassesEdit,
}

const mockSkillLevels = {
  data: [
    {
      id: '1',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 1',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '1',
              type: 'skill',
            },
            {
              id: '2',
              type: 'skill',
            },
            {
              id: '4',
              type: 'skill',
            },
            {
              id: '6',
              type: 'skill',
            },
            {
              id: '7',
              type: 'skill',
            },
            {
              id: '8',
              type: 'skill',
            },
            {
              id: '3',
              type: 'skill',
            },
            {
              id: '5',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '2',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 2',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '3',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '9',
              type: 'skill',
            },
            {
              id: '10',
              type: 'skill',
            },
            {
              id: '11',
              type: 'skill',
            },
            {
              id: '12',
              type: 'skill',
            },
            {
              id: '13',
              type: 'skill',
            },
            {
              id: '14',
              type: 'skill',
            },
            {
              id: '15',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '3',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 3',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '4',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '16',
              type: 'skill',
            },
            {
              id: '17',
              type: 'skill',
            },
            {
              id: '18',
              type: 'skill',
            },
            {
              id: '19',
              type: 'skill',
            },
            {
              id: '20',
              type: 'skill',
            },
            {
              id: '21',
              type: 'skill',
            },
            {
              id: '22',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '4',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 4',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '5',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '23',
              type: 'skill',
            },
            {
              id: '24',
              type: 'skill',
            },
            {
              id: '25',
              type: 'skill',
            },
            {
              id: '26',
              type: 'skill',
            },
            {
              id: '27',
              type: 'skill',
            },
            {
              id: '28',
              type: 'skill',
            },
            {
              id: '29',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '5',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 5',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '6',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '30',
              type: 'skill',
            },
            {
              id: '31',
              type: 'skill',
            },
            {
              id: '32',
              type: 'skill',
            },
            {
              id: '33',
              type: 'skill',
            },
            {
              id: '34',
              type: 'skill',
            },
            {
              id: '35',
              type: 'skill',
            },
            {
              id: '36',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '6',
      type: 'skillLevel',
      attributes: {
        name: 'Basic 6',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '11',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '37',
              type: 'skill',
            },
            {
              id: '38',
              type: 'skill',
            },
            {
              id: '39',
              type: 'skill',
            },
            {
              id: '40',
              type: 'skill',
            },
            {
              id: '41',
              type: 'skill',
            },
            {
              id: '42',
              type: 'skill',
            },
            {
              id: '43',
              type: 'skill',
            },
            {
              id: '44',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '7',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 1',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '8',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '45',
              type: 'skill',
            },
            {
              id: '46',
              type: 'skill',
            },
            {
              id: '47',
              type: 'skill',
            },
            {
              id: '48',
              type: 'skill',
            },
            {
              id: '49',
              type: 'skill',
            },
            {
              id: '50',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '8',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 2',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '9',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '51',
              type: 'skill',
            },
            {
              id: '52',
              type: 'skill',
            },
            {
              id: '53',
              type: 'skill',
            },
            {
              id: '54',
              type: 'skill',
            },
            {
              id: '55',
              type: 'skill',
            },
            {
              id: '56',
              type: 'skill',
            },
            {
              id: '57',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '9',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 3',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '10',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '58',
              type: 'skill',
            },
            {
              id: '59',
              type: 'skill',
            },
            {
              id: '60',
              type: 'skill',
            },
            {
              id: '61',
              type: 'skill',
            },
            {
              id: '62',
              type: 'skill',
            },
            {
              id: '63',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '10',
      type: 'skillLevel',
      attributes: {
        name: 'Snowplow Sam 4',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '2',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '64',
              type: 'skill',
            },
            {
              id: '65',
              type: 'skill',
            },
            {
              id: '66',
              type: 'skill',
            },
            {
              id: '67',
              type: 'skill',
            },
            {
              id: '68',
              type: 'skill',
            },
            {
              id: '69',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '11',
      type: 'skillLevel',
      attributes: {
        name: 'Pre-Free Skate',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '12',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '77',
              type: 'skill',
            },
            {
              id: '70',
              type: 'skill',
            },
            {
              id: '71',
              type: 'skill',
            },
            {
              id: '72',
              type: 'skill',
            },
            {
              id: '73',
              type: 'skill',
            },
            {
              id: '74',
              type: 'skill',
            },
            {
              id: '75',
              type: 'skill',
            },
            {
              id: '76',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '12',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 1',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '13',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '84',
              type: 'skill',
            },
            {
              id: '78',
              type: 'skill',
            },
            {
              id: '79',
              type: 'skill',
            },
            {
              id: '80',
              type: 'skill',
            },
            {
              id: '81',
              type: 'skill',
            },
            {
              id: '82',
              type: 'skill',
            },
            {
              id: '83',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '13',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 2',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '14',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '91',
              type: 'skill',
            },
            {
              id: '85',
              type: 'skill',
            },
            {
              id: '86',
              type: 'skill',
            },
            {
              id: '87',
              type: 'skill',
            },
            {
              id: '88',
              type: 'skill',
            },
            {
              id: '89',
              type: 'skill',
            },
            {
              id: '90',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '14',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 3',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '15',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '98',
              type: 'skill',
            },
            {
              id: '92',
              type: 'skill',
            },
            {
              id: '93',
              type: 'skill',
            },
            {
              id: '94',
              type: 'skill',
            },
            {
              id: '95',
              type: 'skill',
            },
            {
              id: '96',
              type: 'skill',
            },
            {
              id: '97',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '15',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 4',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '16',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '105',
              type: 'skill',
            },
            {
              id: '99',
              type: 'skill',
            },
            {
              id: '100',
              type: 'skill',
            },
            {
              id: '101',
              type: 'skill',
            },
            {
              id: '102',
              type: 'skill',
            },
            {
              id: '103',
              type: 'skill',
            },
            {
              id: '104',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '16',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 5',
      },
      relationships: {
        nextSkillLevel: {
          data: {
            id: '17',
            type: 'skillLevel',
          },
        },
        skills: {
          data: [
            {
              id: '111',
              type: 'skill',
            },
            {
              id: '106',
              type: 'skill',
            },
            {
              id: '107',
              type: 'skill',
            },
            {
              id: '108',
              type: 'skill',
            },
            {
              id: '109',
              type: 'skill',
            },
            {
              id: '110',
              type: 'skill',
            },
          ],
        },
      },
    },
    {
      id: '17',
      type: 'skillLevel',
      attributes: {
        name: 'Free Skate 6',
      },
      relationships: {
        nextSkillLevel: {
          data: null,
        },
        skills: {
          data: [
            {
              id: '118',
              type: 'skill',
            },
            {
              id: '112',
              type: 'skill',
            },
            {
              id: '113',
              type: 'skill',
            },
            {
              id: '114',
              type: 'skill',
            },
            {
              id: '115',
              type: 'skill',
            },
            {
              id: '116',
              type: 'skill',
            },
            {
              id: '117',
              type: 'skill',
            },
          ],
        },
      },
    },
  ],
}

const mockCoaches = {
  data: [
    {
      id: '134',
      type: 'coach',
      attributes: {
        fullName: 'Moe Herr',
        preferredName: 'Moe',
        email: 'moebrooks1@gmail.com',
        cloudinaryPhotoPublicId: null,
        'invitedToSignUp?': false,
        'invitationAccepted?': false,
      },
      relationships: {
        facilities: {
          data: [
            {
              id: '6',
              type: 'facility',
            },
          ],
        },
        klassDays: {
          data: [
            {
              id: '165',
              type: 'klassDay',
            },
            {
              id: '166',
              type: 'klassDay',
            },
            {
              id: '167',
              type: 'klassDay',
            },
            {
              id: '168',
              type: 'klassDay',
            },
          ],
        },
      },
    },
    {
      id: '135',
      type: 'coach',
      attributes: {
        fullName: 'Brenna Anderson',
        preferredName: 'Brenna',
        email: 'brennabriele@gmail.com',
        cloudinaryPhotoPublicId: null,
        'invitedToSignUp?': false,
        'invitationAccepted?': false,
      },
      relationships: {
        facilities: {
          data: [
            {
              id: '6',
              type: 'facility',
            },
          ],
        },
        klassDays: {
          data: [
            {
              id: '157',
              type: 'klassDay',
            },
            {
              id: '158',
              type: 'klassDay',
            },
            {
              id: '159',
              type: 'klassDay',
            },
            {
              id: '160',
              type: 'klassDay',
            },
          ],
        },
      },
    },
    {
      id: '136',
      type: 'coach',
      attributes: {
        fullName: 'Sherry Wilkinson',
        preferredName: 'Sherry',
        email: 'sastefani@aol.com',
        cloudinaryPhotoPublicId: null,
        'invitedToSignUp?': false,
        'invitationAccepted?': false,
      },
      relationships: {
        facilities: {
          data: [
            {
              id: '6',
              type: 'facility',
            },
          ],
        },
        klassDays: {
          data: [
            {
              id: '379',
              type: 'klassDay',
            },
            {
              id: '380',
              type: 'klassDay',
            },
            {
              id: '381',
              type: 'klassDay',
            },
            {
              id: '382',
              type: 'klassDay',
            },
            {
              id: '383',
              type: 'klassDay',
            },
            {
              id: '385',
              type: 'klassDay',
            },
            {
              id: '386',
              type: 'klassDay',
            },
            {
              id: '387',
              type: 'klassDay',
            },
            {
              id: '161',
              type: 'klassDay',
            },
            {
              id: '162',
              type: 'klassDay',
            },
            {
              id: '163',
              type: 'klassDay',
            },
            {
              id: '164',
              type: 'klassDay',
            },
            {
              id: '549',
              type: 'klassDay',
            },
            {
              id: '550',
              type: 'klassDay',
            },
            {
              id: '551',
              type: 'klassDay',
            },
            {
              id: '552',
              type: 'klassDay',
            },
            {
              id: '553',
              type: 'klassDay',
            },
            {
              id: '554',
              type: 'klassDay',
            },
            {
              id: '555',
              type: 'klassDay',
            },
            {
              id: '556',
              type: 'klassDay',
            },
            {
              id: '557',
              type: 'klassDay',
            },
            {
              id: '558',
              type: 'klassDay',
            },
            {
              id: '559',
              type: 'klassDay',
            },
            {
              id: '560',
              type: 'klassDay',
            },
            {
              id: '561',
              type: 'klassDay',
            },
            {
              id: '562',
              type: 'klassDay',
            },
            {
              id: '563',
              type: 'klassDay',
            },
          ],
        },
      },
    },
    {
      id: '154',
      type: 'coach',
      attributes: {
        fullName: 'K Thornbrough',
        preferredName: 'K',
        email: 'krthornbrugh24@gmail.com',
        cloudinaryPhotoPublicId: null,
        'invitedToSignUp?': false,
        'invitationAccepted?': false,
      },
      relationships: {
        facilities: {
          data: [
            {
              id: '6',
              type: 'facility',
            },
          ],
        },
        klassDays: {
          data: [
            {
              id: '361',
              type: 'klassDay',
            },
            {
              id: '362',
              type: 'klassDay',
            },
            {
              id: '363',
              type: 'klassDay',
            },
            {
              id: '364',
              type: 'klassDay',
            },
            {
              id: '365',
              type: 'klassDay',
            },
            {
              id: '367',
              type: 'klassDay',
            },
            {
              id: '368',
              type: 'klassDay',
            },
            {
              id: '369',
              type: 'klassDay',
            },
            {
              id: '564',
              type: 'klassDay',
            },
            {
              id: '565',
              type: 'klassDay',
            },
            {
              id: '566',
              type: 'klassDay',
            },
            {
              id: '567',
              type: 'klassDay',
            },
            {
              id: '568',
              type: 'klassDay',
            },
            {
              id: '569',
              type: 'klassDay',
            },
            {
              id: '570',
              type: 'klassDay',
            },
            {
              id: '571',
              type: 'klassDay',
            },
            {
              id: '572',
              type: 'klassDay',
            },
            {
              id: '573',
              type: 'klassDay',
            },
            {
              id: '574',
              type: 'klassDay',
            },
            {
              id: '575',
              type: 'klassDay',
            },
            {
              id: '576',
              type: 'klassDay',
            },
            {
              id: '577',
              type: 'klassDay',
            },
            {
              id: '578',
              type: 'klassDay',
            },
          ],
        },
      },
    },
    {
      id: '265',
      type: 'coach',
      attributes: {
        fullName: 'Grant Ogren',
        preferredName: 'Grant',
        email: 'grant.ogren@gmail.com',
        cloudinaryPhotoPublicId: null,
        'invitedToSignUp?': false,
        'invitationAccepted?': false,
      },
      relationships: {
        facilities: {
          data: [
            {
              id: '6',
              type: 'facility',
            },
          ],
        },
        klassDays: { data: [] },
      },
    },
    {
      id: '285',
      type: 'coach',
      attributes: {
        fullName: null,
        preferredName: null,
        email: 'chris.j.anders@hotmail.com',
        cloudinaryPhotoPublicId: null,
        'invitedToSignUp?': true,
        'invitationAccepted?': false,
      },
      relationships: {
        facilities: {
          data: [
            {
              id: '6',
              type: 'facility',
            },
          ],
        },
        klassDays: {
          data: [
            {
              id: '352',
              type: 'klassDay',
            },
            {
              id: '353',
              type: 'klassDay',
            },
            {
              id: '354',
              type: 'klassDay',
            },
            {
              id: '355',
              type: 'klassDay',
            },
            {
              id: '356',
              type: 'klassDay',
            },
            {
              id: '358',
              type: 'klassDay',
            },
            {
              id: '359',
              type: 'klassDay',
            },
            {
              id: '360',
              type: 'klassDay',
            },
            {
              id: '524',
              type: 'klassDay',
            },
            {
              id: '525',
              type: 'klassDay',
            },
            {
              id: '526',
              type: 'klassDay',
            },
            {
              id: '527',
              type: 'klassDay',
            },
            {
              id: '528',
              type: 'klassDay',
            },
            {
              id: '529',
              type: 'klassDay',
            },
            {
              id: '530',
              type: 'klassDay',
            },
            {
              id: '531',
              type: 'klassDay',
            },
            {
              id: '532',
              type: 'klassDay',
            },
            {
              id: '533',
              type: 'klassDay',
            },
            {
              id: '519',
              type: 'klassDay',
            },
            {
              id: '520',
              type: 'klassDay',
            },
            {
              id: '521',
              type: 'klassDay',
            },
            {
              id: '522',
              type: 'klassDay',
            },
            {
              id: '523',
              type: 'klassDay',
            },
          ],
        },
      },
    },
    {
      id: '286',
      type: 'coach',
      attributes: {
        fullName: 'John Saitta',
        preferredName: 'John',
        email: 'john.saitta@sbcglobal.net',
        cloudinaryPhotoPublicId: null,
        'invitedToSignUp?': false,
        'invitationAccepted?': true,
      },
      relationships: {
        facilities: {
          data: [
            {
              id: '6',
              type: 'facility',
            },
          ],
        },
        klassDays: { data: [] },
      },
    },
    {
      id: '287',
      type: 'coach',
      attributes: {
        fullName: null,
        preferredName: null,
        email: 'h.sigmon@comcast.net',
        cloudinaryPhotoPublicId: null,
        'invitedToSignUp?': true,
        'invitationAccepted?': false,
      },
      relationships: {
        facilities: {
          data: [
            {
              id: '6',
              type: 'facility',
            },
          ],
        },
        klassDays: {
          data: [
            {
              id: '406',
              type: 'klassDay',
            },
            {
              id: '407',
              type: 'klassDay',
            },
            {
              id: '408',
              type: 'klassDay',
            },
            {
              id: '409',
              type: 'klassDay',
            },
            {
              id: '410',
              type: 'klassDay',
            },
            {
              id: '412',
              type: 'klassDay',
            },
            {
              id: '413',
              type: 'klassDay',
            },
            {
              id: '414',
              type: 'klassDay',
            },
            {
              id: '534',
              type: 'klassDay',
            },
            {
              id: '535',
              type: 'klassDay',
            },
            {
              id: '536',
              type: 'klassDay',
            },
            {
              id: '537',
              type: 'klassDay',
            },
            {
              id: '538',
              type: 'klassDay',
            },
            {
              id: '539',
              type: 'klassDay',
            },
            {
              id: '540',
              type: 'klassDay',
            },
            {
              id: '541',
              type: 'klassDay',
            },
            {
              id: '542',
              type: 'klassDay',
            },
            {
              id: '543',
              type: 'klassDay',
            },
            {
              id: '544',
              type: 'klassDay',
            },
            {
              id: '545',
              type: 'klassDay',
            },
            {
              id: '546',
              type: 'klassDay',
            },
            {
              id: '547',
              type: 'klassDay',
            },
            {
              id: '548',
              type: 'klassDay',
            },
          ],
        },
      },
    },
    {
      id: '288',
      type: 'coach',
      attributes: {
        fullName: null,
        preferredName: null,
        email: 'infinite.edges.cda@gmail.com',
        cloudinaryPhotoPublicId: null,
        'invitedToSignUp?': true,
        'invitationAccepted?': false,
      },
      relationships: {
        facilities: {
          data: [
            {
              id: '6',
              type: 'facility',
            },
          ],
        },
        klassDays: { data: [] },
      },
    },
    {
      id: '289',
      type: 'coach',
      attributes: {
        fullName: null,
        preferredName: null,
        email: 'ktleclaire@gmail.com',
        cloudinaryPhotoPublicId: null,
        'invitedToSignUp?': true,
        'invitationAccepted?': false,
      },
      relationships: {
        facilities: {
          data: [
            {
              id: '6',
              type: 'facility',
            },
          ],
        },
        klassDays: { data: [] },
      },
    },
    {
      id: '290',
      type: 'coach',
      attributes: {
        fullName: null,
        preferredName: null,
        email: 'kskates@frontier.com',
        cloudinaryPhotoPublicId: null,
        'invitedToSignUp?': true,
        'invitationAccepted?': false,
      },
      relationships: {
        facilities: {
          data: [
            {
              id: '6',
              type: 'facility',
            },
          ],
        },
        klassDays: { data: [] },
      },
    },
    {
      id: '291',
      type: 'coach',
      attributes: {
        fullName: null,
        preferredName: null,
        email: 'lindsaymalcolmbailey@gmail.com',
        cloudinaryPhotoPublicId: null,
        'invitedToSignUp?': true,
        'invitationAccepted?': false,
      },
      relationships: {
        facilities: {
          data: [
            {
              id: '6',
              type: 'facility',
            },
          ],
        },
        klassDays: { data: [] },
      },
    },
    {
      id: '292',
      type: 'coach',
      attributes: {
        fullName: 'Lisa Jewett',
        preferredName: 'Lisa',
        email: 'lisajojewett@gmail.com',
        cloudinaryPhotoPublicId: null,
        'invitedToSignUp?': false,
        'invitationAccepted?': true,
      },
      relationships: {
        facilities: {
          data: [
            {
              id: '6',
              type: 'facility',
            },
          ],
        },
        klassDays: { data: [] },
      },
    },
    {
      id: '293',
      type: 'coach',
      attributes: {
        fullName: 'Kimmi Jeffers',
        preferredName: 'Kimmi',
        email: 'jeffers.kimmi@gmail.com',
        cloudinaryPhotoPublicId: null,
        'invitedToSignUp?': false,
        'invitationAccepted?': true,
      },
      relationships: {
        facilities: {
          data: [
            {
              id: '6',
              type: 'facility',
            },
          ],
        },
        klassDays: { data: [] },
      },
    },
    {
      id: '294',
      type: 'coach',
      attributes: {
        fullName: 'Dean Wiles',
        preferredName: 'Dean',
        email: 'deanwiles@yahoo.com',
        cloudinaryPhotoPublicId: null,
        'invitedToSignUp?': false,
        'invitationAccepted?': true,
      },
      relationships: {
        facilities: {
          data: [
            {
              id: '6',
              type: 'facility',
            },
          ],
        },
        klassDays: { data: [] },
      },
    },
  ],
}

export function withSuccessfulRequest() {
  // FIXME: This story doesn't work because we need to mock the
  // response from the klasses endpoint.

  const mockAxios = new MockAdapter(axios)

  mockAxios.reset()
  mockAxios.onGet(/skill_levels/).reply(200, mockSkillLevels)
  mockAxios.onGet(/coaches/).reply(200, mockCoaches)
  mockAxios.onPut().reply(200)
  mockAxios.onDelete().reply(200)

  return (
    <MemoryRouter initialEntries={['facilities/1/klasses/1/edit']}>
      <Route path="facilities/1/klasses/:klass_id/edit">
        <FacilitiesKlassesEdit />
      </Route>
      <Route path="facilities/1/klasses/:klass_id/edit">
        Redirection after delete was successful!
      </Route>
    </MemoryRouter>
  )
}

export function withUnsuccessfulRequest() {
  // FIXME: This story doesn't work because we need to mock the
  // response from the klasses endpoint.

  const mockAxios = new MockAdapter(axios)

  mockAxios.reset()
  mockAxios.onGet(/skill_levels/).reply(200, mockSkillLevels)
  mockAxios.onGet(/coaches/).reply(200, mockCoaches)
  mockAxios.onPut().reply(500)
  mockAxios.onDelete().reply(500)

  return (
    <MemoryRouter initialEntries={['facilities/1/klasses/1/edit']}>
      <Route path="facilities/1/klasses/:klass_id/edit">
        <FacilitiesKlassesEdit />
      </Route>
      <Route path="facilities/1/klasses/:klass_id/edit">
        Redirection after delete was successful!
      </Route>
    </MemoryRouter>
  )
}
