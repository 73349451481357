import React, { useState } from 'react'
import { action } from '@storybook/addon-actions'

import StudentAttendanceCheckButton from './StudentAttendanceCheckButton'
import Card from '../../leevo_ui/Card/Card'

export default {
  title: 'Coach Feature/Components/StudentAttendanceCheckButton',
  component: StudentAttendanceCheckButton,
}

export function SuccessfulToggles() {
  const [attended, setAttended] = useState(false)
  return (
    <div className="m-3">
      toggleAttendance function resolves
      <Card className="relative block w-64 h-24 p-3 mt-4">
        <StudentAttendanceCheckButton
          klassDaysStudent={{ id: 1, attended }}
          toggleAttendance={(args) => {
            action('Toggled attendance for ID')(args)
            setAttended(!attended)
            return Promise.resolve()
          }}
        />
      </Card>
    </div>
  )
}

export function failedToggles() {
  return (
    <div className="m-3">
      toggleAttendance function rejects
      <Card className="relative block w-64 h-24 p-3 mt-4">
        <StudentAttendanceCheckButton
          klassDaysStudent={{ id: 1, attended: true }}
          toggleAttendance={(args) => {
            action('Toggled attendance for ID')(args)
            return Promise.reject()
          }}
        />
      </Card>
      <Card className="relative block w-64 h-24 p-3 mt-4">
        <StudentAttendanceCheckButton
          klassDaysStudent={{ id: 1, attended: false }}
          toggleAttendance={(args) => {
            action('Toggled attendance for ID')(args)
            return Promise.reject()
          }}
        />
      </Card>
    </div>
  )
}

export function NetworkDelay() {
  const [attended1, setAttended1] = useState(false)
  return (
    <div className="m-3">
      <div className="font-bold">2 second network delay</div>
      <div>
        Promise takes time to resolve or reject. Button but will be translucent
        and disabled while waiting
      </div>
      <Card className="relative w-64 h-24 p-3 mt-4">
        This card resolves
        <StudentAttendanceCheckButton
          klassDaysStudent={{ id: 1, attended: attended1 }}
          toggleAttendance={() =>
            new Promise(function (resolve, _reject) {
              setTimeout(function () {
                resolve()
              }, 2000)
            }).then(() => setAttended1(!attended1))
          }
        />
      </Card>
      <Card className="relative w-64 h-24 p-3 mt-4">
        This card rejects
        <StudentAttendanceCheckButton
          klassDaysStudent={{ id: 1, attended: false }}
          toggleAttendance={() =>
            new Promise(function (_resolve, reject) {
              setTimeout(function () {
                reject()
              }, 2000)
            })
          }
        />
      </Card>
    </div>
  )
}
