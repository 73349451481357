import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import Transition from '../../../Transitions/Transition/Transition'

function NavBarMenu({ opened, links }) {
  return (
    <Transition
      show={opened}
      enter="ease-out duration-300"
      enterFrom="opacity-0 -translate-y-full"
      enterTo="opacity-100 translate-y-0"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-full"
      className="absolute z-40 w-full transition-all transform"
      style={{ top: '64px' }}
    >
      <nav className="min-w-full text-black">
        {links.map((link) => (
          <NavLink
            key={link.label}
            exact
            to={link.to}
            activeClassName="text-white bg-purple-400 px-4 border-l-8 border-purple-500"
            className="flex flex-wrap items-center justify-between px-6 py-2 text-lg bg-white border border-t-0 hover:border-purple-500 hover:bg-purple-500 hover:text-white"
          >
            {link.label}
          </NavLink>
        ))}
      </nav>
    </Transition>
  )
}

NavBarMenu.defaultProps = {
  opened: false,
}

NavBarMenu.propTypes = {
  opened: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default NavBarMenu
