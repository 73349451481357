import normalize from 'json-api-normalizer'
import Jsona from 'jsona'
import { get as _get } from 'lodash'

const jsonApiDataFormatter = new Jsona()

export function parseResponse(response) {
  if (!_get(response, 'data.data')) return null
  return parseCollection(response) || parseRecord(response)
}

export function parseCollection(response) {
  let records
  const dataIsArray = Array.isArray(_get(response, 'data.data'))
  const entityType = _get(response, 'data.data[0].type')
  if (!dataIsArray) return null
  if (!entityType) {
    records = []
  } else {
    records = jsonApiDataFormatter.denormalizeReduxObject({
      reduxObject: normalize(response.data),
      entityType,
    })
  }

  // FIXME: This should be always return { records, meta } but
  // doing so would mean changing code *everywhere* where collections
  // are returned. This creates as slightly different API when there
  // is metadata which can be changed later
  if (response.data.meta) {
    return { records, meta: response.data.meta }
  } else {
    return records
  }
}

export function parseRecord(response) {
  const entityId = _get(response, 'data.data.id')
  const entityType = _get(response, 'data.data.type')
  if (!entityType || !entityId) return null

  const record = jsonApiDataFormatter.denormalizeReduxObject({
    reduxObject: normalize(response.data),
    entityType,
    entityIds: entityId,
  })

  return { ...record, meta: response.data.meta }
}
