import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link, useParams, Redirect } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import SVG from 'react-inlinesvg'
import axios from 'axios'

import DisclosureAgreementCheckbox from './DisclosureAgreementCheckbox/DisclosureAgreementCheckbox'
import RegistrationLayout from '../RegistrationLayout/RegistrationLayout'
import CheckoutForm from '../../leevo_ui/CheckoutForm/CheckoutForm'
import useAuth from '../../../utilities/hooks/useAuth'
import Input from '../../leevo_ui/Input/Input'
import Notification from '../../leevo_ui/Notification/Notification'
import ExposableContent from '../../leevo_ui/ExposableContent/ExposableContent'
import { priceInDollars } from '../../../utilities/helpers'
import { requestCreator } from '../../../utilities/requests'
import { requiredToTop } from '../../../utilities/helpers.js'

import editIcon from '../../../images/edit-icon.svg'
import poweredByStripe from '../../../images/powered-by-stripe.svg'
import sslCheckout from '../../../images/ssl-checkout.png'

const { get: getFacility, cancel: cancelFacilityRequest } = requestCreator()
const {
  post: finishCheckoutSession,
  cancel: cancelCheckoutSessionRequest,
} = requestCreator()
function Checkout({
  students,
  onCompletedCheckout,
  lineItems,
  couponCode,
  onCouponCodeChange,
  couponCodeError,
  couponCodeAmount,
  couponCodePromotionAmount,
  total,
  disclosures: initialDisclosures,
}) {
  const [stripeAccount, setStripeAccount] = useState(null)
  const { isSignedIn } = useAuth()
  const { facility_id } = useParams()
  const [disclosures, setDisclosures] = useState(
    initialDisclosures.sort(requiredToTop).map((d) => ({ ...d, agreed: false }))
  )

  const hasAgreedToAllRequiredDisclosures = useMemo(
    () => disclosures.every((d) => !d.required || d.agreed),
    [disclosures]
  )

  const redirect =
    !isSignedIn ||
    !lineItems ||
    lineItems.length === 0 ||
    !students ||
    students.length === 0

  function handleCheckoutSubmit(token) {
    const url = `/api/facilities/${facility_id}/checkouts`
    return finishCheckoutSession(url, {
      sourceId: token.id,
      students,
      couponCode,
      disclosures: disclosures.map(({ id, agreed }) => ({ id, agreed })),
    }).then(onCompletedCheckout)
  }

  useEffect(() => {
    getFacility(`/api/facilities/${facility_id}`)
      .then(({ stripeUserId }) => {
        setStripeAccount(stripeUserId)
      })
      .catch((error) => !axios.isCancel(error) && history.push('/500'))

    return () => {
      cancelFacilityRequest()
      cancelCheckoutSessionRequest()
    }
  })

  return redirect ? (
    <Redirect to={`/facilities/${facility_id}/register`} />
  ) : (
    <RegistrationLayout
      title="Checkout"
      progressPercentage={100}
      className="m-4"
    >
      <div className="max-w-md pt-4 m-auto">
        <table className="w-full table-fixed">
          <tbody>
            <tr className="border-b border-purple-900">
              <td className="w-2/3 px-4 py-2">
                <h2 className="text-lg font-semibold text-gray-900">
                  Order Summary
                </h2>
              </td>
              <td className="w-1/3 px-4 py-2 text-right">
                <Link
                  className="font-medium text-purple-600 cursor-pointer"
                  to={`/facilities/${facility_id}/register`}
                >
                  <SVG
                    className="inline-block w-4 h-4 mb-1 mr-1 fill-current"
                    src={editIcon}
                  />
                  <span>Edit</span>
                </Link>
              </td>
            </tr>
            {lineItems.map(({ id, description, price }) => (
              <tr
                key={id}
                className="text-sm font-medium border-t border-gray-400 first:border-none"
                data-cy={`line-item-${id}`}
              >
                <td className="px-4 py-2">
                  <p className="leading-tight" data-cy="line-item-description">
                    {description}
                  </p>
                </td>
                <td className="px-4 py-2 text-right" data-cy="line-item-price">
                  <p>${priceInDollars(price)}</p>
                </td>
              </tr>
            ))}
            {couponCodeAmount && (
              <tr className="text-sm font-medium border-t border-gray-400 first:border-none text-green-700">
                <td className="px-4 py-2">
                  <p className="leading-tight">
                    Coupon Code for {couponCodePromotionAmount} off
                  </p>
                </td>
                <td
                  className="px-4 py-2 text-right"
                  data-cy="coupon-code-amount"
                >
                  <p>-${priceInDollars(couponCodeAmount)}</p>
                </td>
              </tr>
            )}
            {/* FIXME: should be <tfoot> */}
            <tr
              className="font-semibold border-t border-purple-900"
              data-cy="total"
            >
              <td className="px-4 py-2">Total</td>
              <td className="px-4 py-2 text-right">
                <p>${priceInDollars(total)}</p>
              </td>
            </tr>
          </tbody>
        </table>
        {disclosures.length > 0 && (
          <section>
            <div className="px-4 pt-10 border-b border-purple-900">
              <h2 className="pb-2 text-lg font-semibold text-gray-900">
                Legal Agreements
              </h2>
            </div>
            <ul className="px-4 py-2 text-sm font-gray-800 space-y-2">
              {disclosures.map(
                ({ id, name, agreement, disclosureType, required, agreed }) => (
                  <DisclosureAgreementCheckbox
                    key={id}
                    id={id}
                    disclosureType={disclosureType}
                    disclosureName={name}
                    agreement={agreement}
                    required={required}
                    onClick={() =>
                      setDisclosures((disclosures) =>
                        disclosures.map((d) =>
                          d.id === id ? { ...d, agreed: !d.agreed } : d
                        )
                      )
                    }
                    value={agreed}
                  />
                )
              )}
            </ul>
          </section>
        )}
        <section>
          <div className="px-4 pt-10 border-b border-purple-900">
            <h2 className="pb-2 text-lg font-semibold text-gray-900">
              Payment Information
            </h2>
          </div>
          <div className="px-4 py-6">
            <Notification
              show={!hasAgreedToAllRequiredDisclosures}
              disappearIn={null}
              color="purple"
            >
              👆 You must agree to required agreements.
            </Notification>

            <ExposableContent hiddenLabel="Enter Coupon Code" className="my-2">
              <Form
                onSubmit={onCouponCodeChange}
                render={({ handleSubmit, pristine, submitting }) => (
                  <form onSubmit={handleSubmit}>
                    <Field name="couponCode">
                      {({ input }) => (
                        <Input
                          {...input}
                          id="coupon_code"
                          label="Coupon Code"
                          errorMessage={couponCodeError}
                          buttonLabel="Add"
                          onButtonClick={() =>
                            handleSubmit({ couponCode: input.value })
                          }
                          isButtonDisabled={pristine || submitting}
                          disabled={submitting}
                        />
                      )}
                    </Field>
                  </form>
                )}
              />
            </ExposableContent>
            {stripeAccount && (
              <CheckoutForm
                disabled={!hasAgreedToAllRequiredDisclosures}
                submitButtonLabel={`Pay $${priceInDollars(total)}`}
                onSubmit={handleCheckoutSubmit}
              />
            )}
          </div>
        </section>
        <div className="flex items-center justify-between space-x-2 mx-4">
          <SVG src={poweredByStripe} className="w-32" />
          <img
            alt="Fully secured SSL checkout"
            src={sslCheckout}
            className="w-32"
          />
        </div>
      </div>
    </RegistrationLayout>
  )
}

Checkout.defaultProps = {}

Checkout.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      selectedKlasses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        })
      ),
    })
  ).isRequired,
  onCompletedCheckout: PropTypes.func.isRequired,
  lineItems: PropTypes.array.isRequired,
  couponCodeAmount: PropTypes.number.isRequired,
  couponCodePromotionAmount: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  onCouponCodeChange: PropTypes.func.isRequired,
  couponCodeError: PropTypes.string,
  couponCode: PropTypes.string,
  disclosures: PropTypes.array,
}

export default Checkout
