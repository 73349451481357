import React from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'
import moment from 'moment'
import pluralize from 'pluralize'

import Avatar from '../../leevo_ui/Avatar/Avatar'
import { levelArrayToString } from '../../../utilities/helpers'

function CoachHeader({
  coaches,
  classStartDateTime,
  skillLevels,
  numStudents,
}) {
  return (
    <header className="py-1 mx-1 my-4 text-xs font-medium text-gray-600 bg-white border-t-2 shadow rounded-xl">
      <div className="flex items-center justify-between px-2">
        <div className="flex m-3 space-x-3">
          {coaches.map(
            ({ preferredName, cloudinaryPhotoPublicId, id, fullName }) => (
              <div
                key={id}
                className="flex flex-col items-center justify-center"
              >
                <Avatar
                  fullName={fullName}
                  size={16}
                  cloudinaryPhotoPublicId={cloudinaryPhotoPublicId}
                  userId={id}
                />
                <h1 className="text-sm" data-cy="coach-name">
                  {preferredName}
                </h1>
              </div>
            )
          )}
        </div>
        <div className="mr-3 text-right">
          <h2>{moment(classStartDateTime).format('MMMM D @ LT')}</h2>
          <h2 data-cy="class-levels">
            {levelArrayToString(skillLevels, { maxLength: 35 })}
          </h2>
          {numStudents !== undefined && numStudents !== null && (
            <p>{pluralize('student', numStudents, true)}</p>
          )}
        </div>
      </div>
    </header>
  )
}
CoachHeader.defaultProps = {}

CoachHeader.propTypes = {
  classStartDateTime: momentPropTypes.momentObj,
  coaches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      preferredName: PropTypes.string.isRequired,
      cloudinaryPhotoPublicId: PropTypes.string,
    })
  ).isRequired,
  skillLevels: PropTypes.arrayOf(PropTypes.string).isRequired,
  numStudents: PropTypes.number,
}

export default CoachHeader
