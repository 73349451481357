import React from 'react'
import StudentList from './StudentList'
import { mount } from 'enzyme'
import StudentCard from '../StudentCard/StudentCard'
import { MemoryRouter } from 'react-router-dom'

describe('StudentList', () => {
  let wrapper
  describe('StudentList with no students', () => {
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/facilities/1/class_days/1']}>
          <StudentList
            klassDayId={1}
            level={{
              id: 1,
              name: 'name',
              skills: [{ id: 1, name: 'foo', shortLabel: 'A' }],
            }}
            students={[]}
            toggleAttendance={() => {}}
          />
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper.find(StudentList)).toExist()
    })

    test('does not render any StudentCard', () => {
      expect(wrapper.find(StudentCard).length).toEqual(0)
    })
  })

  describe('StudentList with students', () => {
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/facilities/1/class_days/1']}>
          <StudentList
            level={{ id: 1, name: 'name', skills: [] }}
            klassDayId={1}
            toggleAttendance={() => {}}
            students={[
              {
                id: 1,
                fullName: 'Tyrion Lannister',
                preferredName: 'Tyrion',
                cloudinaryPhotoPublicId: 'fake',
                klassDaysStudents: [
                  { id: 1, attended: false, klassDay: { id: 1 } },
                ],
                currentSkillLevel: {
                  id: 1,
                  name: 'Basic 1',
                  skills: [
                    { id: 1, shortLabel: 'A', skill: { id: 1 } },
                    { id: 2, shortLabel: 'B', skill: { id: 2 } },
                    { id: 3, shortLabel: 'C', skill: { id: 3 } },
                    { id: 4, shortLabel: 'D', skill: { id: 4 } },
                    { id: 5, shortLabel: 'E', skill: { id: 5 } },
                    { id: 6, shortLabel: 'F', skill: { id: 6 } },
                  ],
                  nextSkillLevel: { id: 2, name: 'name' },
                },
                studentsSkills: [
                  {
                    id: 1,
                    shortLabel: 'A',
                    status: 'introduced',
                    skill: { id: 1 },
                  },
                  {
                    id: 2,
                    shortLabel: 'B',
                    status: 'neutral',
                    skill: { id: 2 },
                  },
                  {
                    id: 3,
                    shortLabel: 'C',
                    status: 'completed',
                    skill: { id: 3 },
                  },
                  {
                    id: 4,
                    shortLabel: 'D',
                    status: 'introduced',
                    skill: { id: 4 },
                  },
                  {
                    id: 5,
                    shortLabel: 'E',
                    status: 'completed',
                    skill: { id: 5 },
                  },
                  {
                    id: 6,
                    shortLabel: 'F',
                    status: 'neutral',
                    skill: { id: 6 },
                  },
                ],
              },
              {
                id: 2,
                fullName: 'Cercei Lannister',
                preferredName: 'Cercei',
                cloudinaryPhotoPublicId: 'fake',
                currentSkillLevel: {
                  id: 1,
                  name: 'Basic 1',
                  skills: [
                    { id: 1, shortLabel: 'A', skill: { id: 1 } },
                    { id: 2, shortLabel: 'B', skill: { id: 2 } },
                    { id: 3, shortLabel: 'C', skill: { id: 3 } },
                    { id: 4, shortLabel: 'D', skill: { id: 4 } },
                    { id: 5, shortLabel: 'E', skill: { id: 5 } },
                    { id: 6, shortLabel: 'F', skill: { id: 6 } },
                  ],
                  nextSkillLevel: { id: 2, name: 'name' },
                },
                klassDaysStudents: [
                  { id: 2, attended: false, klassDay: { id: 1 } },
                ],
                studentsSkills: [
                  {
                    id: 1,
                    shortLabel: 'A',
                    status: 'introduced',
                    skill: { id: 1 },
                  },
                  {
                    id: 2,
                    shortLabel: 'B',
                    status: 'neutral',
                    skill: { id: 2 },
                  },
                  {
                    id: 3,
                    shortLabel: 'C',
                    status: 'completed',
                    skill: { id: 3 },
                  },
                  {
                    id: 4,
                    shortLabel: 'D',
                    status: 'introduced',
                    skill: { id: 4 },
                  },
                  {
                    id: 5,
                    shortLabel: 'E',
                    status: 'completed',
                    skill: { id: 5 },
                  },
                  {
                    id: 6,
                    shortLabel: 'F',
                    status: 'completed',
                    skill: { id: 6 },
                  },
                ],
              },
            ]}
          />
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper.find(StudentList)).toExist()
    })

    test('render 2 StudentCard components', () => {
      expect(wrapper.find(StudentCard).length).toEqual(2)
    })

    test('renders in alphabetical order by full name', () => {
      const firstStudent = wrapper.find(StudentCard).at(0).props().student
      const secondStudent = wrapper.find(StudentCard).at(1).props().student

      expect(firstStudent.fullName).toEqual('Cercei Lannister')
      expect(secondStudent.fullName).toEqual('Tyrion Lannister')
    })
  })
})
