import React from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'

import StudentCard from '../StudentCard/StudentCard'
import SkillLegend from '../../leevo_ui/SkillLegend/SkillLegend'

function StudentList({
  className,
  level,
  students,
  klassDayId,
  onPromotion,
  selectedCategory,
  withSkillSelector,
  toggleAttendance,
}) {
  const sortedStudents = students.sort((a, b) => {
    return a.fullName.localeCompare(b.fullName, 'en', {
      sensitivity: 'base',
    })
  })

  return (
    <div className={className} data-cy="student-list">
      <div className="mx-1 bg-white shadow rounded-xl">
        <header
          className={`sticky bg-white rounded-t-xl z-10 ${
            withSkillSelector ? 'top-14' : 'top-0'
          }`}
        >
          <div className="flex items-start justify-between px-5 pt-5 pb-3">
            <p className="text-lg font-medium">
              <p>
                {level.name} {selectedCategory && `- ${selectedCategory}`}
              </p>
              <p className="text-sm text-gray-600">
                {pluralize('student', students.length, true)}
              </p>
            </p>
          </div>
          <SkillLegend />
        </header>
        <div>
          {sortedStudents.map((student) => {
            const klassDaysStudent = student.klassDaysStudents.find(
              (klassDaysStudent) => klassDaysStudent.klassDay.id == klassDayId
            )
            return (
              <StudentCard
                toggleAttendance={toggleAttendance}
                selectedCategory={selectedCategory}
                key={student.id}
                student={student}
                klassDaysStudent={klassDaysStudent}
                onPromotion={onPromotion}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

StudentList.defaultProps = {
  onPromotion: () => {},
}

StudentList.propTypes = {
  className: PropTypes.string,
  klassDayId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  level: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        shortLabel: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  students: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      fullName: PropTypes.string.isRequired,
      cloudinaryPhotoPublicId: PropTypes.string,
      studentsSkills: PropTypes.array.isRequired,
      klassDaysStudents: PropTypes.array.isRequired,
      currentSkillLevel: PropTypes.shape({
        name: PropTypes.string,
      }).isRequired,
    }).isRequired
  ),
  selectedCategory: PropTypes.string,
  withSkillSelector: PropTypes.bool,
  onPromotion: PropTypes.func,
  toggleAttendance: PropTypes.func,
}

export default StudentList
