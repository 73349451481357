import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import Fuse from 'fuse.js'
import { get } from 'lodash'
import classNames from 'classnames'

import CheckButton from '../../CheckButton/CheckButton'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import Input from '../../Input/Input'

const fuzzySearchOptions = {
  findAllMatches: true,
  threshold: 0.4,
  keys: ['fullName'],
}

function MultiSelectStudent({
  className,
  value: students,
  onChange,
  loading,
  label,
  helpText,
  errorMessage,
  required,
}) {
  const [query, setQuery] = useState('')
  const [selected, setSelected] = useState(
    new Set(
      students
        .filter((student) => student.selected)
        .map((student) => student.id)
    )
  )

  useEffect(() => {
    setSelected(
      new Set(
        students
          .filter((student) => student.selected)
          .map((student) => student.id)
      )
    )
  }, [students])

  function toggle(student) {
    let newSelectedStudents
    if (selected.has(student.id)) {
      selected.delete(student.id)
      newSelectedStudents = new Set(selected)
    } else {
      newSelectedStudents = new Set(selected.add(student.id))
    }
    setSelected(newSelectedStudents)
    onChange(
      students.map((student) => {
        return {
          ...student,
          selected: newSelectedStudents.has(student.id),
        }
      })
    )
  }

  function filteredStudents() {
    if (!query) return students

    const fuzzySearch = new Fuse(students, fuzzySearchOptions)
    return fuzzySearch.search(query).map(({ item }) => item)
  }

  return (
    <section className={className} data-cy="students-multi-select">
      {label && (
        <label
          className={`${classNames({
            'border-red-500 text-red-500': errorMessage,
            'text-gray-800': !errorMessage,
          })} block font-medium ml-1`}
        >
          {label}
          {required && '*'}
        </label>
      )}
      {helpText && <p className="ml-1 text-xs text-gray-500">{helpText}</p>}
      <p
        className={`block mb-1 ml-1 text-sm font-light ${classNames({
          'text-gray-700': !errorMessage,
          'text-red-500': errorMessage,
        })}`}
      >
        {!loading && `${pluralize('student', selected.size, true)} enrolled`}
      </p>
      <div
        className={`${classNames({
          'border-gray-300': !errorMessage,
          'text-red-400 border-red-500 ring ring-red-500 ring-opacity-50 error-shake': errorMessage,
        })} h-64 mb-2 overflow-scroll border rounded-md shadow-inner focus:ring focus:border-purple-500 focus:ring-purple-300 focus:ring-opacity-50`}
      >
        {loading ? (
          <LoadingSpinner
            size="8"
            className="flex items-center justify-center my-10"
          />
        ) : (
          <>
            <Input
              className="sticky top-0 p-1 pb-0 bg-white"
              id="student-search"
              type="search"
              placeholder="Search for students by name..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <ul>
              {filteredStudents().map((student) => (
                <li
                  key={student.id}
                  className="flex items-center h-12 p-1 text-gray-800 border-b border-gray-300 xs:p-3"
                  data-cy="student-option"
                >
                  <div
                    className="flex items-center justify-between w-full cursor-pointer"
                    onClick={() => toggle(student)}
                  >
                    <div className="flex items-center justify-start">
                      <CheckButton
                        className="mr-2"
                        ariaProps={{
                          'aria-pressed': selected.has(student.id),
                          'aria-label': 'select date',
                        }}
                        checked={selected.has(student.id)}
                        data-cy={`${student.fullName}-check-button`}
                        size={5}
                      />
                      <p>{student.fullName}</p>
                    </div>
                    <div className="text-sm text-gray-700">
                      {get(student, 'currentSkillLevel.name') && (
                        <p>{get(student, 'currentSkillLevel.name')}</p>
                      )}
                      <p className="text-right">
                        Age {student.age || 'unknown'}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </section>
  )
}

MultiSelectStudent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  className: PropTypes.string,
  label: PropTypes.string,
  helpText: PropTypes.string,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
    dates: PropTypes.array,
  }),
}

export default MultiSelectStudent
