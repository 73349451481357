import React from 'react'
import PropTypes from 'prop-types'

import { Form } from 'react-final-form'

import Modal from '../Modal/Modal'
import Button from '../Button/Button'
import PasswordFields from '../PasswordFields/PasswordFields'

import { isValidPasswordAndPasswordConfirmation } from '../../../utilities/validators'

function NewPasswordModal({
  errorMessage,
  newPasswordSubmission,
  successMessage,
}) {
  return (
    <>
      <Modal open={true} header="Create new password">
        <Form
          validate={isValidPasswordAndPasswordConfirmation}
          onSubmit={newPasswordSubmission}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="pb-4 text-sm text-gray-700">
                  Password must be at least 8 characters
                </div>
                <PasswordFields />
                {errorMessage && (
                  <div
                    data-cy="error-message"
                    className="mb-4 text-sm font-medium text-red-600"
                  >
                    {errorMessage}
                  </div>
                )}
                {!successMessage && (
                  <div className="flex justify-end">
                    <Button
                      data-cy="submit"
                      label="Submit"
                      type="submit"
                      disabled={submitting}
                      loading={submitting}
                    />
                  </div>
                )}
                {successMessage && (
                  <div className="mb-4 text-sm font-medium text-purple-800">
                    {successMessage}
                  </div>
                )}
              </form>
            )
          }}
        />
      </Modal>
    </>
  )
}

NewPasswordModal.propTypes = {
  errorMessage: PropTypes.string,
  newPasswordSubmission: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
}

export default NewPasswordModal
