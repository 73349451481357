import React from 'react'
import NavBarLink from './NavBarLink'
import { capitalize as _capitalize } from 'lodash'

export default {
  title: 'Leevo UI/NavBar/MarketingNavBar/NavBarLink',
  component: NavBarLink,
}

export function navBarLink() {
  return (
    <ul>
      {NavBarLink.colors.map((color) => (
        <li key={color}>
          <NavBarLink color={color} to="/">
            Home
          </NavBarLink>
          <NavBarLink className="ml-6" color={color} to="/some/path">
            Home
          </NavBarLink>
          <p className="inline ml-6 capitalize">{_capitalize(color)}</p>
        </li>
      ))}
    </ul>
  )
}
