import React from 'react'
import CheckButton from './CheckButton'
import { shallow } from 'enzyme'

describe('CheckButton', () => {
  let wrapper
  beforeEach(() => {
    wrapper = shallow(<CheckButton />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
