import axios from 'axios'
import { parseResponse } from './jsonApiHelpers'
import qs from 'qs'

export function paramsSerializer(params) {
  return qs.stringify(params, { arrayFormat: 'brackets' })
}

export function requestCreator() {
  let getSource, putSource, postSource, patchSource
  const cache = {}

  function get(url, options = {}) {
    const { params, cacheKey, headers } = options
    cancel(`Fetching with new params: ${params}`)
    getSource = axios.CancelToken.source()
    if (cache[cacheKey]) {
      return Promise.resolve(cache[cacheKey])
    } else {
      return axios
        .get(url, {
          headers,
          params,
          paramsSerializer,
          cancelToken: getSource.token,
        })
        .then((response) => {
          const responseData = parseResponse(response)

          if (cacheKey) cache[cacheKey] = responseData
          return responseData
        })
    }
  }

  function post(url, data, options = {}) {
    postSource = axios.CancelToken.source()
    return axios
      .post(url, data, {
        paramsSerializer,
        cancelToken: postSource.token,
        ...options,
      })
      .then(parseResponse)
  }

  function put(url, data) {
    putSource = axios.CancelToken.source()
    return axios
      .put(url, data, { paramsSerializer, cancelToken: putSource.token })
      .then(parseResponse)
  }

  // `delete` is a reserved word, so we call it `deleteVerb` and alias it later
  function deleteVerb(url, data) {
    putSource = axios.CancelToken.source()
    return axios
      .delete(url, data, { paramsSerializer, cancelToken: putSource.token })
      .then(parseResponse)
  }

  function patch(url, data) {
    patchSource = axios.CancelToken.source()
    return axios
      .patch(url, data, { paramsSerializer, cancelToken: patchSource.token })
      .then(parseResponse)
  }

  function cancel(message) {
    if (getSource) getSource.cancel(message)
    if (postSource) postSource.cancel(message)
    if (putSource) putSource.cancel(message)
    if (patchSource) patchSource.cancel(message)
  }

  return { get, post, patch, put, delete: deleteVerb, cancel }
}
