import React from 'react'

import Table from './Table'
import Avatar from '../Avatar/Avatar'

export default {
  title: 'Leevo UI/Table',
  component: Table,
}

export function table() {
  return (
    <Table headers={['Name', 'Guardian', 'Enrolled?', '']}>
      {[...Array(50)]
        .map((_value, index) => index)
        .map((value) => (
          <tr key={value}>
            <td className="px-5 py-4 whitespace-nowrap">
              <div className="flex items-center">
                <Avatar
                  fullName="Foo bar"
                  isStudent
                  src={undefined}
                  alt="Photo of Foo Bar"
                  userId={1}
                  size={10}
                />
                <div className="ml-4">
                  <div className="text-sm font-medium leading-5 text-gray-900">
                    Jane Doe
                  </div>
                  <div className="text-sm leading-5 text-gray-500">
                    Snowplow Sam 1
                  </div>
                </div>
              </div>
            </td>
            <td className="px-5 py-4 whitespace-nowrap">
              <div className="flex items-center">
                <Avatar
                  fullName="Foo bar"
                  isStudent
                  src={undefined}
                  alt="Photo of Foo Bar"
                  userId={1}
                  size={10}
                />
                <div className="ml-4">
                  <div className="text-sm font-medium leading-5 text-gray-900">
                    Janine Doe
                  </div>
                  <div className="text-sm leading-5 text-gray-500">
                    janine.doe@example.com
                  </div>
                </div>
              </div>
            </td>
            <td className="px-5 py-4 whitespace-nowrap">Enrolled</td>
            <td className="px-5 py-4 text-sm font-medium leading-5 text-right whitespace-nowrap">
              <a href="#" className="text-purple-600 hover:text-purple-900">
                Edit
              </a>
            </td>
          </tr>
        ))}
    </Table>
  )
}
