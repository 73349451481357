import React from 'react'
import AdminFacilityRow from './AdminFacilityRow'

export default {
  title: 'Leevo UI/AdminFacilityRow',
  component: AdminFacilityRow,
  args: {
    className: 'm-4',
  },
}

export function adminFacilityRow(args) {
  return <AdminFacilityRow {...args} />
}
