import React from 'react'

import KlassCard from './KlassCard'

export default {
  title: 'Leevo UI/KlassCard',
  component: KlassCard,
}

const coaches = [
  { id: 1, fullName: 'Jane Doe' },
  { id: 2, fullName: 'Mary Jane' },
  { id: 3, fullName: 'Ronald McDonald' },
]

const students = [{ id: 1 }, { id: 2 }, { id: 3 }]

const supportedSkillLevels = [
  { id: 1, name: 'Snowplow Sam 1' },
  { id: 2, name: 'Snowplow Sam 2' },
  { id: 3, name: 'Snowplow Sam 3' },
  { id: 3, name: 'Basic 1' },
]

export function klassCard() {
  return (
    <KlassCard
      dayOfWeek="Sun"
      timeOfDay="5:00 PM"
      startDate="Oct 20"
      endDate="Dec 15"
      coaches={coaches}
      students={students}
      supportedSkillLevels={supportedSkillLevels}
      maxStudents={3}
    />
  )
}
