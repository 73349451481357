import React from 'react'
import { NavLink } from 'react-router-dom'
import { shallow } from 'enzyme'

import NavBarLink from './NavBarLink'

describe('NavBarLink', () => {
  let wrapper
  beforeEach(() => {
    wrapper = shallow(<NavBarLink to="/some/path">Foo</NavBarLink>)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders children within a NavLink', () => {
    expect(wrapper.find(NavLink).children()).toHaveText('Foo')
  })

  test('renders NavLink with to prop', () => {
    expect(wrapper.find(NavLink).props().to).toMatch('/some/path')
  })

  describe('white color', () => {
    test('renders with white text', () => {
      expect(wrapper.find(NavLink)).toHaveClassName('text-white')
    })

    test('renders with gray hover text', () => {
      const { className } = wrapper.find(NavLink).props()
      expect(className).toMatch('hover:text-gray-200')
    })

    test('renders with gray active text', () => {
      const { activeClassName } = wrapper.find(NavLink).props()
      expect(activeClassName).toBe('text-gray-400')
    })
  })

  describe('black color', () => {
    beforeEach(() => {
      wrapper = shallow(
        <NavBarLink to="/some/path" color="black">
          Foo
        </NavBarLink>
      )
    })
    test('renders with black text', () => {
      expect(wrapper.find(NavLink)).toHaveClassName('text-black')
    })

    test('renders with dark gray hover text', () => {
      const { className } = wrapper.find(NavLink).props()
      expect(className).toMatch('hover:text-gray-600')
    })

    test('renders with gray active text', () => {
      const { activeClassName } = wrapper.find(NavLink).props()
      expect(activeClassName).toBe('text-gray-800')
    })
  })

  describe('any other color', () => {
    beforeEach(() => {
      wrapper = shallow(
        <NavBarLink to="/some/path" color="purple">
          Foo
        </NavBarLink>
      )
    })
    test('renders with text at 500 color level', () => {
      expect(wrapper.find(NavLink)).toHaveClassName('text-purple-500')
    })

    test('renders with hover text at 500 color level', () => {
      const { className } = wrapper.find(NavLink).props()
      expect(className).toMatch('hover:text-purple-300')
    })

    test('renders with hover text at 700 color level', () => {
      const { activeClassName } = wrapper.find(NavLink).props()
      expect(activeClassName).toBe('text-purple-700')
    })
  })
})
