import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import SVG from 'react-inlinesvg'

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

function classes({
  color,
  light,
  outline,
  disabled,
  fullWidth,
  shadow,
  small,
}) {
  let standardColors, outlineColors, hoverClasses, focusClasses
  if (light) {
    switch (color) {
      case 'red':
        standardColors = 'bg-red-400 active:bg-red-500 text-white'
        outlineColors = 'active:bg-red-800 text-red-400 border-red-400'
        hoverClasses = 'hover:bg-red-500'
        focusClasses = 'focus:ring-red-400'
        break
      case 'purple':
        standardColors = 'bg-purple-400 active:bg-purple-500 text-white'
        outlineColors = 'active:bg-purple-800 text-purple-400 border-purple-400'
        hoverClasses = 'hover:bg-purple-500'
        focusClasses = 'focus:ring-purple-400'
        break
      case 'yellow':
        standardColors = 'bg-yellow-400 active:bg-yellow-500 text-white'
        outlineColors = 'active:bg-yellow-800 text-yellow-400 border-yellow-400'
        hoverClasses = 'hover:bg-yellow-500'
        focusClasses = 'focus:ring-yellow-400'
        break
      case 'teal':
        standardColors = 'bg-teal-400 active:bg-teal-500 text-white'
        outlineColors = 'active:bg-teal-800 text-teal-400 border-teal-400'
        hoverClasses = 'hover:bg-teal-500'
        focusClasses = 'focus:ring-teal-400'
        break
      case 'pink':
        standardColors = 'bg-pink-400 active:bg-pink-500 text-white'
        outlineColors = 'active:bg-pink-800 text-pink-400 border-pink-400'
        hoverClasses = 'hover:bg-pink-500'
        focusClasses = 'focus:ring-pink-400'
        break
      case 'blue':
        standardColors = 'bg-blue-400 active:bg-blue-500 text-white'
        outlineColors = 'active:bg-blue-800 text-blue-400 border-blue-400'
        hoverClasses = 'hover:bg-blue-500'
        focusClasses = 'focus:ring-blue-400'
        break
      case 'green':
        standardColors = 'bg-green-400 active:bg-green-500 text-white'
        outlineColors = 'active:bg-green-800 text-green-400 border-green-400'
        hoverClasses = 'hover:bg-green-500'
        focusClasses = 'focus:ring-green-400'
        break
      case 'orange':
        standardColors = 'bg-orange-400 active:bg-orange-500 text-white'
        outlineColors = 'active:bg-orange-800 text-orange-400 border-orange-400'
        hoverClasses = 'hover:bg-orange-500'
        focusClasses = 'focus:ring-orange-400'
        break
    }
  } else {
    switch (color) {
      case 'red':
        standardColors = 'bg-red-600 active:bg-red-700 text-white'
        outlineColors = 'active:bg-red-800 text-red-600 border-red-600'
        hoverClasses = 'hover:bg-red-700'
        focusClasses = 'focus:ring-red-400'
        break
      case 'purple':
        standardColors = 'bg-purple-600 active:bg-purple-700 text-white'
        outlineColors = 'active:bg-purple-800 text-purple-600 border-purple-600'
        hoverClasses = 'hover:bg-purple-700'
        focusClasses = 'focus:ring-purple-400'
        break
      case 'yellow':
        standardColors = 'bg-yellow-600 active:bg-yellow-700 text-white'
        outlineColors = 'active:bg-yellow-800 text-yellow-600 border-yellow-600'
        hoverClasses = 'hover:bg-yellow-700'
        focusClasses = 'focus:ring-yellow-400'
        break
      case 'teal':
        standardColors = 'bg-teal-600 active:bg-teal-700 text-white'
        outlineColors = 'active:bg-teal-800 text-teal-600 border-teal-600'
        hoverClasses = 'hover:bg-teal-700'
        focusClasses = 'focus:ring-teal-400'
        break
      case 'pink':
        standardColors = 'bg-pink-600 active:bg-pink-700 text-white'
        outlineColors = 'active:bg-pink-800 text-pink-600 border-pink-600'
        hoverClasses = 'hover:bg-pink-700'
        focusClasses = 'focus:ring-pink-400'
        break
      case 'blue':
        standardColors = 'bg-blue-600 active:bg-blue-700 text-white'
        outlineColors = 'active:bg-blue-800 text-blue-600 border-blue-600'
        hoverClasses = 'hover:bg-blue-700'
        focusClasses = 'focus:ring-blue-400'
        break
      case 'green':
        standardColors = 'bg-green-600 active:bg-green-700 text-white'
        outlineColors = 'active:bg-green-800 text-green-600 border-green-600'
        hoverClasses = 'hover:bg-green-700'
        focusClasses = 'focus:ring-green-400'
        break
      case 'orange':
        standardColors = 'bg-orange-600 active:bg-orange-700 text-white'
        outlineColors = 'active:bg-orange-800 text-orange-600 border-orange-600'
        hoverClasses = 'hover:bg-orange-700'
        focusClasses = 'focus:ring-orange-400'
        break
      default:
        // white
        standardColors = 'bg-white active:bg-gray-200 text-gray-600'
        outlineColors = 'active:bg-gray-100 text-white border-white'
        hoverClasses = 'hover:bg-gray-100 hover:text-gray-600'
        focusClasses = 'focus:ring-white'
        break
    }
  }

  let classes = [
    'rounded font-medium focus:outline-none focus:ring focus:ring-opacity-75 transition ease-in-out duration-200',
  ]

  if (fullWidth) classes.push('min-w-full')
  if (shadow) classes.push('shadow-hover')
  classes.push(small ? 'py-1 px-2 text-sm' : 'py-2 px-4')

  if (outline) {
    classes.push('bg-transparent', 'border', outlineColors)
  } else {
    classes.push(standardColors)
  }

  if (disabled) {
    classes.push('opacity-50', 'cursor-not-allowed')
  } else {
    classes.push('hover:border-transparent', hoverClasses, focusClasses)
    if (color !== 'white') classes.push('hover:text-white')
  }

  return classNames(classes)
}

function Button({
  className,
  disabled,
  onClick,
  label,
  color,
  light,
  outline,
  fullWidth,
  shadow,
  loading,
  type,
  small,
  icon,
  ...rest
}) {
  return (
    <button
      {...rest}
      className={`${className} ${classes({
        color,
        light,
        outline,
        disabled,
        fullWidth,
        shadow,
        small,
      })}`}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {loading && <LoadingSpinner color="white" size="4" className="mr-2" />}
      {icon && (
        <SVG
          className="inline w-4 h-auto mr-2 mb-1 fill-current stroke-current"
          src={icon}
        />
      )}
      {label}
    </button>
  )
}

Button.types = ['button', 'submit', 'reset']

Button.colors = [
  'red',
  'purple',
  'yellow',
  'teal',
  'pink',
  'blue',
  'green',
  'orange',
  'white',
]

Button.defaultProps = {
  color: 'purple',
  type: 'button',
  fullWidth: false,
  outline: false,
  disabled: false,
  light: false,
  shadow: false,
  onClick: () => {},
  loading: false,
  small: false,
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  color: PropTypes.oneOf(Button.colors).isRequired,
  label: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  light: PropTypes.bool,
  type: PropTypes.oneOf(Button.types),
  className: PropTypes.string,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  small: PropTypes.bool,
}

export default Button
