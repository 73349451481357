import React from 'react'
import CoachClassCard from './CoachClassCard'
import moment from 'moment'
import { boolean, number, text } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'
import htmlEntities from 'he'
import faker from 'faker'

export default {
  title: 'Facilities Calendar/Components/CoachClassCard',
  component: CoachClassCard,
}

export function coachClassCard() {
  function coaches(numCoaches) {
    const coaches = []
    for (let i = 0; i < numCoaches; i++) {
      coaches.push({
        id: i + 1,
        preferredName: htmlEntities.decode(
          text(`Coach ${i + 1} Name`, faker.name.firstName())
        ),
        cloudinaryPhotoPublicId: 'qrz0yfenrunmvcb0epwa',
      })
    }
    return coaches
  }

  function levels(numLevels) {
    const levels = []
    for (let i = 0; i < numLevels; i++) {
      levels.push(
        htmlEntities.decode(
          text(
            `Level ${i + 1} Name`,
            `${faker.company.bsBuzz()} ${Math.floor(Math.random() * 10)}`
          )
        )
      )
    }
    return levels
  }

  return (
    <div className={boolean('With margins?', true) ? 'm-3' : undefined}>
      <CoachClassCard
        facilityId={1}
        classId={1}
        date={moment('2019-12-28T19:43:25-07:00')}
        coaches={coaches(number('Number of Coaches', 1))}
        skillLevels={levels(number('Number of Levels', 1))}
        onSelectClass={action('selected class')}
      />
    </div>
  )
}
