import React from 'react'
import PropTypes from 'prop-types'

import CircleButton from '../CircleButton/CircleButton'

function stateToColor(state) {
  switch (state) {
    case 'introduced':
      return 'yellow'
    case 'completed':
      return 'green'
    case 'highlighted':
      return 'purple'
    default:
      // neutral or unintroduced
      return 'white'
  }
}

function SkillButton({ size, state, label, onClick, loading }) {
  return (
    <CircleButton
      size={size}
      color={stateToColor(state)}
      onClick={onClick}
      data-cy="skill-button"
      className="mt-1 mr-1"
      label={label}
      loading={loading}
    />
  )
}

SkillButton.sizes = CircleButton.sizes
SkillButton.states = [
  'neutral',
  'unintroduced',
  'introduced',
  'completed',
  'highlighted',
]

SkillButton.defaultProps = {
  state: 'neutral',
  label: 'A',
  size: CircleButton.defaultProps.size,
  onClick: CircleButton.defaultProps.onClick,
}

SkillButton.propTypes = {
  id: PropTypes.string,
  state: PropTypes.oneOf(SkillButton.states).isRequired,
  label: PropTypes.string.isRequired,
  size: CircleButton.propTypes.size,
  onClick: CircleButton.propTypes.onClick,
  loading: CircleButton.propTypes.loading,
}

export default SkillButton
