import React, { useState } from 'react'
import { action } from '@storybook/addon-actions'
import faker from 'faker/locale/en_CA'

import PromotionModal from './PromotionModal'
import Button from '../../leevo_ui/Button/Button'

import bgPhoto from '../../../images/sample/skating-wallpaper.jpg'

export default {
  title: 'Coach Feature/Components/PromotionModal',
  component: PromotionModal,
  args: {
    pending: false,
    studentName: faker.name.findName(),
    originalLevel: 'Basic 1',
    newLevel: 'Basic 2',
  },
  argTypes: {
    open: { table: { disable: true } },
  },
}

export function PromotionModalStory(args) {
  const [opened, setOpened] = useState(true)
  return (
    <>
      <img className="max-w-6xl" src={bgPhoto} />
      <div className="fixed w-full h-full" style={{ top: '45%', left: '45%' }}>
        <Button
          color="yellow"
          label="Open PromotionModal"
          className="shadow-hover"
          onClick={() => setOpened(true)}
        />
      </div>
      <PromotionModal
        {...args}
        open={opened}
        onCancel={action('Cancelled Promotion')}
        onConfirm={action('Confirmed Promotion')}
      />
    </>
  )
}
