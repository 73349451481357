import React from 'react'
import { mount } from 'enzyme'

import MultiSelectDayDateTime from './MultiSelectDayDateTime'

describe('MultiSelectDayDateTime', () => {
  let wrapper

  beforeEach(() => {
    wrapper = mount(<MultiSelectDayDateTime onChange={() => {}} />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
