import React from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import hidePasswordEye from '../../../images/hidePasswordEye.svg'
import showPasswordEye from '../../../images/showPasswordEye.svg'

export default function ShowHidePasswordButton({
  className,
  showPassword,
  onIconClicked,
}) {
  return (
    <SVG
      onClick={onIconClicked}
      className={`${className} w-6 h-6 pb-1 m-2 text-gray-500 cursor-pointer fill-current hover:text-purple-700`}
      src={showPassword ? hidePasswordEye : showPasswordEye}
    />
  )
}

ShowHidePasswordButton.propTypes = {
  className: PropTypes.string,
  showPassword: PropTypes.bool.isRequired,
  onIconClicked: PropTypes.func.isRequired,
}
