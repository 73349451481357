import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

import FacilitiesSkateRentalsIndex from './FacilitiesSkateRentalsIndex'
import MockFacilityProvider from '../../providers/MockFacilityProvider'
import skateRentalsResponse from './fixtures/skate_rentals'

export default {
  title: 'FacilitiesSkateRentalsIndex',
  component: FacilitiesSkateRentalsIndex,
  args: {
    className: 'm-4',
  },
}

export function facilitiesSkateRentalsIndex(args) {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onGet(/skate_rentals/).reply(200, skateRentalsResponse)

  return (
    <MockFacilityProvider>
      <FacilitiesSkateRentalsIndex {...args} />
    </MockFacilityProvider>
  )
}
