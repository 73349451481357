import React from 'react'
import { mount } from 'enzyme'
import { MemoryRouter, Route } from 'react-router-dom'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { act } from 'react-dom/test-utils'
import flushPromises from 'flush-promises'

import FacilitiesStudentsIndex from './FacilitiesStudentsIndex'
import MockFacilityProvider from '../../providers/MockFacilityProvider'
import MockAuthProvider from '../../providers/AuthProvider'

describe('FacilitiesStudentsIndex', () => {
  let wrapper
  const mockAxios = new MockAdapter(axios)

  beforeEach(() => {
    mockAxios.reset()
    mockAxios.onGet('/api/facilities/1').reply(200, {
      data: {
        id: 1,
        type: 'facility',
        attributes: {
          name: 'fake facility',
        },
      },
    })
    mockAxios.onGet('/api/facilities/1/students').reply(200, {
      meta: { registered: 2, total: 3 },
      data: [
        {
          id: '1',
          type: 'student',
          attributes: {
            fullName: 'Student 1 Full Name',
            registered: true,
            preferredName: 'Student 1 Preferred Name',
            cloudinaryPhotoPublicId: null,
          },
          relationships: {},
        },
        {
          id: '2',
          type: 'student',
          attributes: {
            fullName: 'Student 2 Full Name',
            registered: true,
            preferredName: 'Student 2 Preferred Name',
            cloudinaryPhotoPublicId: null,
          },
          relationships: {},
        },
      ],
    })

    act(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/facilities/1/classes']}>
          <Route path="/facilities/:facility_id/classes">
            <MockAuthProvider>
              <MockFacilityProvider>
                <FacilitiesStudentsIndex />
              </MockFacilityProvider>
            </MockAuthProvider>
          </Route>
        </MemoryRouter>
      )
    })
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  describe('with all data loaded', function () {
    beforeEach(async () => {
      await flushPromises()
      act(() => wrapper.update())
    })

    it('renders all registered students', () => {
      expect(wrapper.find('li').at(0)).toIncludeText('Student 1 Full Name')
      expect(wrapper.find('li').at(1)).toIncludeText('Student 2 Full Name')
    })
  })
})
