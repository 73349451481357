import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { MemoryRouter } from 'react-router-dom'

import StudentList from './StudentList'

const level = {
  id: 1,
  name: 'Basic 1',
  skills: [
    {
      shortLabel: 'A',
      name: 'Forward one foot glides – R and L',
    },
    {
      shortLabel: 'B',
      name: 'Backward two-foot glide',
    },
    {
      shortLabel: 'C',
      name: 'Backward swizzles – 6–8 in a row',
    },
    {
      shortLabel: 'D',
      name: 'Two-foot turn, forward to backward in place',
    },
    {
      shortLabel: 'E',
      name: 'Moving snowplow stop',
    },
    {
      shortLabel: 'F',
      name:
        'Forward alternating half swizzle pumps, in a straight line (slalom-like pattern)',
    },
  ],
}

const students = [
  {
    id: 1,
    fullName: 'Cercei Lannister',
    preferredName: 'Cercei',
    cloudinaryPhotoPublicId: 'qrz0yfenrunmvcb0epwa',
    currentSkillLevel: {
      id: 1,
      name: 'Basic 1',
      skills: [
        { id: 1, shortLabel: 'A', name: 'name' },
        { id: 2, shortLabel: 'B', name: 'name' },
        { id: 3, shortLabel: 'C', name: 'name' },
        { id: 4, shortLabel: 'D', name: 'name' },
        { id: 5, shortLabel: 'E', name: 'name' },
        { id: 6, shortLabel: 'F', name: 'name' },
      ],
      nextSkillLevel: { id: 2, name: 'Basic 2' },
    },
    klassDaysStudents: [{ id: 3, attended: false, klassDay: { id: 1 } }],
    studentsSkills: [
      {
        id: 1,
        shortLabel: 'A',
        status: 'introduced',
        name: 'name',
        skill: { id: 1 },
      },
      {
        id: 2,
        shortLabel: 'B',
        status: 'neutral',
        name: 'name',
        skill: { id: 2 },
      },
      {
        id: 3,
        shortLabel: 'C',
        status: 'completed',
        name: 'name',
        skill: { id: 3 },
      },
      {
        id: 4,
        shortLabel: 'D',
        status: 'introduced',
        name: 'name',
        skill: { id: 4 },
      },
      {
        id: 5,
        shortLabel: 'E',
        status: 'completed',
        name: 'name',
        skill: { id: 5 },
      },
      {
        id: 6,
        shortLabel: 'F',
        status: 'completed',
        name: 'name',
        skill: { id: 6 },
      },
    ],
  },
  {
    id: 2,
    fullName: 'Tyrion Lannister',
    preferredName: 'Tyrion',
    cloudinaryPhotoPublicId: 'qrz0yfenrunmvcb0epwa',
    klassDaysStudents: [{ id: 4, attended: true, klassDay: { id: 1 } }],
    currentSkillLevel: {
      id: 1,
      name: 'Basic 1',
      skills: [
        { id: 1, shortLabel: 'A', name: 'name' },
        { id: 2, shortLabel: 'B', name: 'name' },
        { id: 3, shortLabel: 'C', name: 'name' },
        { id: 4, shortLabel: 'D', name: 'name' },
        { id: 5, shortLabel: 'E', name: 'name' },
        { id: 6, shortLabel: 'F', name: 'name' },
      ],
      nextSkillLevel: { id: 2, name: 'Basic 2' },
    },
    studentsSkills: [
      {
        id: 7,
        shortLabel: 'A',
        status: 'introduced',
        name: 'name',
        skill: { id: 1 },
      },
      {
        id: 8,
        shortLabel: 'B',
        status: 'neutral',
        name: 'name',
        skill: { id: 2 },
      },
      {
        id: 9,
        shortLabel: 'C',
        status: 'completed',
        name: 'name',
        skill: { id: 3 },
      },
      {
        id: 10,
        shortLabel: 'D',
        status: 'introduced',
        name: 'name',
        skill: { id: 4 },
      },
      {
        id: 11,
        shortLabel: 'E',
        status: 'completed',
        name: 'name',
        skill: { id: 5 },
      },
      {
        id: 12,
        shortLabel: 'F',
        status: 'completed',
        name: 'name',
        skill: { id: 6 },
      },
    ],
  },
]

export default {
  title: 'Coach Feature/Components/StudentList',
  component: StudentList,
}
export function studentList() {
  const mockAttendanceApi = new MockAdapter(axios)

  mockAttendanceApi.reset()
  mockAttendanceApi.onPatch(/\/klass_days_students\/(2|3)/).reply(200, {})

  return (
    <MemoryRouter initialEntries={['/facilities/1/class_days/1']}>
      <div className="m-3">
        <StudentList klassDayId={1} level={level} students={students} />
      </div>
    </MemoryRouter>
  )
}
