import React from 'react'
import Calendar from './Calendar'
import data from './calendar-fixture.json'
import MockFacilityProvider from '../../../providers/MockFacilityProvider'
import { parseResponse } from '../../../utilities/jsonApiHelpers'

export default {
  title: 'Leevo UI/Calendar',
  component: Calendar,
  args: {
    className: 'h-screen w-screen',
    klasses: parseResponse(data),
    timeZone: 'America/Los_Angeles',
    isLoading: false,
  },
}

export function calendar(args) {
  return (
    <MockFacilityProvider>
      <Calendar {...args} />
    </MockFacilityProvider>
  )
}
