import React from 'react'
import { action } from '@storybook/addon-actions'
import { boolean, text } from '@storybook/addon-knobs'
import { Form } from 'react-final-form'
import faker from 'faker'

import AddressField from './AddressField'

export default {
  title: 'Registration Feature/Address Field',
  component: AddressField,
}

export function empty() {
  const nameField = text('Name', faker.name.firstName())
  return (
    <Form
      onSubmit={action('Submit')}
      render={() => (
        <AddressField
          className={boolean('With margin?', true) ? 'm-6' : ''}
          name={nameField}
        />
      )}
    />
  )
}

export function prepopulated() {
  const nameField = text('Name', faker.name.firstName())
  return (
    <Form
      initialValues={{
        address: {
          line1: '123 Anytown Rd.',
          line2: 'Apt. 2',
          city: 'Springfield',
          state: 'IL',
          postCode: '12345',
        },
      }}
      onSubmit={action('Submit')}
      render={() => (
        <AddressField
          className={boolean('With margin?', true) ? 'm-6' : ''}
          name={nameField}
        />
      )}
    />
  )
}
