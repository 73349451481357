import React from 'react'
import { action } from '@storybook/addon-actions'
import moment from 'moment'

import KlassForm from './KlassForm'

export default {
  title: 'Leevo UI/Klass Form',
  component: KlassForm,
}

const coachOptions = [
  { label: 'John Doe', value: 1, selected: false },
  { label: 'Jane Doe', value: 2, selected: false },
  { label: 'Mary Sue', value: 3, selected: false },
  { label: 'Joe Rogan', value: 4, selected: false },
]

const skillLevelOptions = [
  { value: 1, label: 'Basic 1', selected: false },
  { value: 2, label: 'Basic 2', selected: false },
  { value: 3, label: 'Basic 3', selected: false },
  { value: 4, label: 'Basic 4', selected: false },
]

const studentOptions = [
  { value: 1, label: 'Arya Stark', selected: false },
  { value: 2, label: 'Sansa Stark', selected: false },
  { value: 3, label: 'Tyrion Lannister', selected: false },
  { value: 4, label: 'Samwell Tarly', selected: false },
]

export function empty() {
  return (
    <KlassForm
      onSubmit={action('Submitted Form')}
      coaches={coachOptions}
      supportedSkillLevels={skillLevelOptions}
    />
  )
}

export function prePopulated() {
  return (
    <KlassForm
      dates={[
        { date: moment().subtract(19 + 20, 'd'), isTimeSet: true },
        { date: moment().subtract(12 + 20, 'd'), isTimeSet: true },
        { date: moment().subtract(5 + 20, 'd'), isTimeSet: true },
      ]}
      coaches={coachOptions.map((coach, index) =>
        index !== 0 ? coach : { ...coach, selected: true }
      )}
      supportedSkillLevels={skillLevelOptions.map((skillLevel, index) =>
        index !== 0 ? skillLevel : { ...skillLevel, selected: true }
      )}
      students={studentOptions.map((student, index) =>
        index !== 0 ? student : { ...student, selected: true }
      )}
      maxStudents={10}
      onSubmit={action('Submitted Form')}
    />
  )
}
