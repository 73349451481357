import React, { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { get } from 'lodash'
import Fuse from 'fuse.js'

import Card from '../leevo_ui/Card/Card'
import SelectInput from '../leevo_ui/SelectInput/SelectInput'
import IndexHeader from '../leevo_ui/IndexHeader/IndexHeader'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import Input from '../leevo_ui/Input/Input'
import SkateRentalListItem from './SkateRentalListItem/SkateRentalListItem'
import { requestCreator } from '../../utilities/requests'
import {
  alphabetizeByFullName,
  readableSkateType,
} from '../../utilities/helpers'
import useFacility from '../../utilities/hooks/useFacility'
import useAuth from '../../utilities/hooks/useAuth'

const fuzzySearchOptions = {
  findAllMatches: true,
  keys: ['student.fullName', 'student.preferredName', 'size', 'skateType'],
}

const {
  get: getSkateRentals,
  cancel: cancelSkateRentealsRequest,
} = requestCreator()
function FacilitiesSkateRentalsIndex() {
  const { facility, isLoading: isFacilityLoading } = useFacility()
  const { replace: redirect } = useHistory()
  const [query, setQuery] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [skateRentals, setSkateRentals] = useState([])
  const { isSignedIn } = useAuth()
  const [selectedSize, setSelectedSize] = useState(null)
  const [selectedSkateType, setSelectedSkateType] = useState(null)
  const [klassPackageOptions, setKlassPackageOptions] = useState([])
  const [selectedKlassPackage, setSelectedKlassPackage] = useState('all')

  useEffect(() => {
    setIsLoading(true)
    if (!isFacilityLoading) {
      getSkateRentals(`/api/facilities/${facility.id}/skate_rentals`, {
        params: {
          filter: { active_eq: true },
          include: 'student',
          fields: {
            student: 'fullName,cloudinaryPhotoPublicId,klass_packages',
          },
        },
      })
        .then(({ records: skateRentals, meta }) => {
          setSkateRentals(
            skateRentals.sort((a, b) =>
              alphabetizeByFullName(a.student, b.student)
            )
          )
          setKlassPackageOptions([
            { label: 'All', value: 'all' },
            ...meta.klass_packages.map(({ id, title }) => ({
              label: title,
              value: id,
            })),
          ])
        })
        .then(() => setIsLoading(false))
        .catch((error) => {
          if (!axios.isCancel(error)) {
            if (get(error, 'response.status') === 401 && !isSignedIn) {
              redirect('/login')
            } else {
              redirect('/500')
              throw error
            }
          }
        })
    }

    return cancelSkateRentealsRequest
  }, [facility, isSignedIn, isFacilityLoading, redirect])

  const filteredSkateRentals = useMemo(() => {
    let filteredSkateRentals = skateRentals
    if (selectedSkateType !== null) {
      filteredSkateRentals = filteredSkateRentals.filter(
        (rental) => selectedSkateType === rental.skateType
      )
    }
    if (selectedSize !== null) {
      filteredSkateRentals = filteredSkateRentals.filter(
        (rental) => selectedSize === `${rental.skateType}${rental.size}`
      )
    }
    if (selectedKlassPackage !== 'all') {
      filteredSkateRentals = filteredSkateRentals.filter((rental) =>
        rental.student.klassPackages
          .map(({ id }) => id)
          .includes(selectedKlassPackage)
      )
    }

    if (!query) return filteredSkateRentals

    const fuzzySearch = new Fuse(filteredSkateRentals, fuzzySearchOptions)
    return fuzzySearch.search(query).map(({ item }) => item)
  }, [
    selectedSkateType,
    selectedSize,
    selectedKlassPackage,
    query,
    skateRentals,
  ])

  // Results in an object like:
  //  { youth_hockey: { "11.5": 10 }, women_hockey: { "10.5": 5 }, women_figure: { "9.5": 1 } }
  const [groupedSkateRentals, setGroupedSkateRentals] = useState({})
  useEffect(() => {
    setGroupedSkateRentals(
      filteredSkateRentals.reduce((accumulator, { skateType, size }) => {
        const skateTypeGroup = accumulator[skateType] || {}
        return {
          ...accumulator,
          [skateType]: {
            ...skateTypeGroup,
            [size]: (skateTypeGroup[size] || 0) + 1,
          },
        }
      }, {})
    )
  }, [filteredSkateRentals])

  return (
    <ApplicationLayout isContentLoading={isLoading}>
      <IndexHeader title="Skate Rentals" />
      <div className="flex justify-center pt-2">
        {klassPackageOptions && (
          <div className="items-center w-full max-w-2xl px-8 pt-2 md:flex md:px-3">
            <p className="w-full font-medium text-gray-700 md:w-1/3">
              Filter by class package:{' '}
            </p>
            <SelectInput
              className="w-full md:w-2/3"
              options={klassPackageOptions}
              value={selectedKlassPackage}
              onChange={(e) => setSelectedKlassPackage(e.target.value)}
              id="select-klass-package"
            />
          </div>
        )}
      </div>
      <div className="flex justify-center px-2 pt-2">
        <div className="grid w-full max-w-2xl grid-cols-2 gap-3 md:grid-cols-3">
          {selectedSkateType === null ? (
            Object.keys(groupedSkateRentals)
              .sort((a, b) => a.localeCompare(b))
              .map((skateTypeKey) => (
                <Card
                  interactive
                  active={selectedSkateType === skateTypeKey}
                  onClick={() => {
                    setSelectedSkateType(
                      selectedSkateType === skateTypeKey ? null : skateTypeKey
                    )
                  }}
                  key={skateTypeKey}
                  borderColor={readableSkateType(skateTypeKey).color}
                  className="col-span-1 p-3 text-right"
                  data-cy={`${skateTypeKey}-card`}
                >
                  <h2 className="justify-between space-x-6 font-semibold text-right text-gray-900">
                    {readableSkateType(skateTypeKey).label}
                  </h2>
                  <span className="block text-xl font-medium text-gray-900">
                    {Object.values(groupedSkateRentals[skateTypeKey]).reduce(
                      (accumulator, sizeCount) => accumulator + sizeCount,
                      0
                    )}
                  </span>
                </Card>
              ))
          ) : (
            <Card
              interactive
              active={selectedSkateType}
              onClick={() => {
                setSelectedSkateType(null)
                setSelectedSize(null)
              }}
              borderColor={readableSkateType(selectedSkateType).color}
              className="col-span-1 p-3 mt-2 text-right"
              data-cy={`${selectedSkateType}-card`}
            >
              <h2 className="justify-between space-x-6 font-semibold text-right text-gray-900">
                {readableSkateType(selectedSkateType).label}
              </h2>
              <span className="block text-xl font-medium text-gray-900">
                {Object.values(groupedSkateRentals[selectedSkateType]).reduce(
                  (accumulator, sizeCount) => accumulator + sizeCount,
                  0
                )}
              </span>
            </Card>
          )}
        </div>
      </div>
      <div className="flex justify-center px-2 pt-2">
        <div className="grid w-full max-w-2xl grid-cols-3 gap-3 md:grid-cols-4">
          {selectedSkateType !== null &&
            Object.keys(groupedSkateRentals[selectedSkateType])
              .sort((a, b) => (parseFloat(a) >= parseFloat(b) ? 1 : -1))
              .map((sizeKey) => (
                <Card
                  interactive
                  active={selectedSize === `${selectedSkateType}${sizeKey}`}
                  onClick={() => {
                    if (selectedSize === `${selectedSkateType}${sizeKey}`) {
                      setSelectedSize(null)
                    } else {
                      setSelectedSize(`${selectedSkateType}${sizeKey}`)
                    }
                  }}
                  key={`${selectedSkateType}-${sizeKey}`}
                  borderColor={readableSkateType(selectedSkateType).color}
                  className="flex justify-between p-3 font-medium text-gray-900"
                  data-cy={`${sizeKey}-card`}
                >
                  <p className="font-semibold">Size {sizeKey}</p>
                  <p>{groupedSkateRentals[selectedSkateType][sizeKey]}</p>
                </Card>
              ))}
        </div>
      </div>
      <div className="flex justify-center">
        <ul className="w-full max-w-2xl pt-2 mx-2 my-2 bg-white rounded-lg shadow">
          <Input
            id="search"
            type="search"
            className="m-3"
            placeholder="Search by name, type or size..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          {filteredSkateRentals.map(
            ({
              id,
              size,
              skateType,
              student: { id: userId, fullName, cloudinaryPhotoPublicId },
            }) => (
              <SkateRentalListItem
                key={id}
                userId={userId}
                size={size}
                skateType={skateType}
                fullName={fullName}
                cloudinaryPhotoPublicId={cloudinaryPhotoPublicId}
              />
            )
          )}
        </ul>
      </div>
    </ApplicationLayout>
  )
}

export default FacilitiesSkateRentalsIndex
