import React from 'react'
import { text } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'

import RegistrationDashboard from './RegistrationDashboard'

export default {
  title: 'Registration Feature/RegistrationDashboard',
  component: RegistrationDashboard,
}

const sections = [
  {
    title: 'Section 1',
    completed: true,
    headerAction: {
      onClick: action('Clicked Section 1 Edit'),
      label: 'Edit',
    },
  },
  {
    title: 'Section 2',
    children: <div>Some student info</div>,
    completed: true,
    headerAction: {
      onClick: action('Clicked Add Student'),
      label: 'Add Student',
    },
  },
  {
    title: 'Section 3',
    nextStepAction: {
      onClick: action('Clicked Pick Your Classes'),
      label: 'Pick your classes',
    },
  },
  { title: 'Section 4', disabled: true },
]

export function registrationDashboard() {
  return (
    <RegistrationDashboard
      sections={sections}
      title={text('Title', 'Next Steps...')}
    />
  )
}
