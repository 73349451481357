import React from 'react'
import PropTypes from 'prop-types'

function Badge({ text, color, className, ...rest }) {
  const classes = {
    red: 'bg-red-100 text-red-800',
    green: 'bg-green-100 text-green-800',
    gray: 'bg-gray-200 text-gray-600',
    orange: 'bg-orange-200 text-orange-700',
    blue: 'bg-blue-200 text-blue-700',
    purple: 'bg-purple-200 text-purple-700',
  }

  let colorClasses = classes[color] || classes.gray

  return (
    <span
      {...rest}
      className={`${className} ${colorClasses} px-2 inline-flex text-center text-xs leading-5 font-semibold rounded-full`}
    >
      {text}
    </span>
  )
}

Badge.defaultProps = {
  text: '',
  color: 'gray',
}

Badge.propTypes = {
  text: PropTypes.string,
  color: PropTypes.oneOf(['gray', 'green', 'red', 'orange', 'blue', 'purple']),
  className: PropTypes.string,
}

export default Badge
