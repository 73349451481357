import mixpanel from 'mixpanel-browser'

function attributesOf(user) {
  const attributes = { ...user }

  // Only keep attributes, nothing nested or collected
  Object.keys(attributes).forEach((key) => {
    if (typeof attributes[key] == 'object' || Array.isArray(attributes[key]))
      delete attributes[key]
  })

  return attributes
}

mixpanel.trackAuthEvent = (event, currentUser, properties = {}) => {
  mixpanel.track(event, properties)
  mixpanel.identify(currentUser.id)
  mixpanel.people.set(attributesOf(currentUser))
}

if (process.env.MIXPANEL_ENABLED === 'false') {
  mixpanel.init = () => {}
  mixpanel.track = () => {}
  mixpanel.identify = () => {}
  mixpanel.people = { set: () => {} }
}

export default mixpanel
