import React from 'react'
import { Form } from 'react-final-form'
import { text, boolean } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'

import FullNameField from './FullNameField'

export default {
  title: 'Registration Feature/FullNameField',
  component: FullNameField,
}

export function fullNameField() {
  const labelField = text('label', 'This is an alternative label.')
  const labelToggle = boolean('Default label?', false) ? undefined : labelField
  return (
    <Form
      onSubmit={action('Submit')}
      render={() => (
        <FullNameField
          className={boolean('With margin?', false) ? 'm-6' : ''}
          label={labelToggle}
        />
      )}
    />
  )
}
