import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import htmlEntities from 'he'
import faker from 'faker'

import CoachFeature from './CoachFeature'
import MockFacilityProvider from '../../providers/MockFacilityProvider'

const mixedStudents = [
  {
    id: 1,
    fullName: 'Cercei Lannister',
    preferredName: 'Cercei',
    cloudinaryPhotoPublicId: 'qrz0yfenrunmvcb0epwa',
    currentSkillLevel: {
      id: 1,
      name: 'Snowplow Sam 1',
      skills: [
        { id: 1, shortLabel: 'A', name: 'name' },
        { id: 2, shortLabel: 'B', name: 'name' },
        { id: 3, shortLabel: 'C', name: 'name' },
        { id: 4, shortLabel: 'D', name: 'name' },
        { id: 5, shortLabel: 'E', name: 'name' },
        { id: 6, shortLabel: 'F', name: 'name' },
      ],
      nextSkillLevel: { id: 2, name: 'Basic 2' },
    },
    klassDaysStudents: [{ id: 3, attended: false, klassDay: { id: 1 } }],
    studentsSkills: [
      {
        id: 1,
        shortLabel: 'A',
        status: 'introduced',
        name: 'name',
        skill: { id: 1 },
      },
      {
        id: 2,
        shortLabel: 'B',
        status: 'neutral',
        name: 'name',
        skill: { id: 2 },
      },
      {
        id: 3,
        shortLabel: 'C',
        status: 'completed',
        name: 'name',
        skill: { id: 3 },
      },
      {
        id: 4,
        shortLabel: 'D',
        status: 'introduced',
        name: 'name',
        skill: { id: 4 },
      },
      {
        id: 5,
        shortLabel: 'E',
        status: 'completed',
        name: 'name',
        skill: { id: 5 },
      },
      {
        id: 6,
        shortLabel: 'F',
        status: 'completed',
        name: 'name',
        skill: { id: 6 },
      },
    ],
  },
  {
    id: 2,
    fullName: 'Tyrion Lannister',
    preferredName: 'Tyrion',
    cloudinaryPhotoPublicId: 'qrz0yfenrunmvcb0epwa',
    klassDaysStudents: [{ id: 4, attended: true, klassDay: { id: 1 } }],
    currentSkillLevel: {
      id: 2,
      name: 'Basic 2',
      skills: [
        { id: 1, shortLabel: 'A', name: 'name' },
        { id: 2, shortLabel: 'B', name: 'name' },
        { id: 3, shortLabel: 'C', name: 'name' },
        { id: 4, shortLabel: 'D', name: 'name' },
        { id: 5, shortLabel: 'E', name: 'name' },
        { id: 6, shortLabel: 'F', name: 'name' },
      ],
      nextSkillLevel: { id: 2, name: 'Basic 2' },
    },
    studentsSkills: [
      {
        id: 7,
        shortLabel: 'A',
        status: 'introduced',
        name: 'name',
        skill: { id: 1 },
      },
      {
        id: 8,
        shortLabel: 'B',
        status: 'neutral',
        name: 'name',
        skill: { id: 2 },
      },
      {
        id: 9,
        shortLabel: 'C',
        status: 'completed',
        name: 'name',
        skill: { id: 3 },
      },
      {
        id: 10,
        shortLabel: 'D',
        status: 'introduced',
        name: 'name',
        skill: { id: 4 },
      },
      {
        id: 11,
        shortLabel: 'E',
        status: 'completed',
        name: 'name',
        skill: { id: 5 },
      },
      {
        id: 12,
        shortLabel: 'F',
        status: 'completed',
        name: 'name',
        skill: { id: 6 },
      },
    ],
  },
]

const students = [
  {
    id: 1,
    fullName: 'Cercei Lannister',
    preferredName: 'Cercei',
    cloudinaryPhotoPublicId: 'qrz0yfenrunmvcb0epwa',
    currentSkillLevel: {
      id: 1,
      name: 'Basic 1',
      skills: [
        { id: 1, shortLabel: 'A', name: 'name' },
        { id: 2, shortLabel: 'B', name: 'name' },
        { id: 3, shortLabel: 'C', name: 'name' },
        { id: 4, shortLabel: 'D', name: 'name' },
        { id: 5, shortLabel: 'E', name: 'name' },
        { id: 6, shortLabel: 'F', name: 'name' },
      ],
      nextSkillLevel: { id: 2, name: 'Basic 2' },
    },
    klassDaysStudents: [{ id: 3, attended: false, klassDay: { id: 1 } }],
    studentsSkills: [
      {
        id: 1,
        shortLabel: 'A',
        status: 'introduced',
        name: 'name',
        skill: { id: 1 },
      },
      {
        id: 2,
        shortLabel: 'B',
        status: 'neutral',
        name: 'name',
        skill: { id: 2 },
      },
      {
        id: 3,
        shortLabel: 'C',
        status: 'completed',
        name: 'name',
        skill: { id: 3 },
      },
      {
        id: 4,
        shortLabel: 'D',
        status: 'introduced',
        name: 'name',
        skill: { id: 4 },
      },
      {
        id: 5,
        shortLabel: 'E',
        status: 'completed',
        name: 'name',
        skill: { id: 5 },
      },
      {
        id: 6,
        shortLabel: 'F',
        status: 'completed',
        name: 'name',
        skill: { id: 6 },
      },
    ],
  },
  {
    id: 2,
    fullName: 'Tyrion Lannister',
    preferredName: 'Tyrion',
    cloudinaryPhotoPublicId: 'qrz0yfenrunmvcb0epwa',
    klassDaysStudents: [{ id: 4, attended: true, klassDay: { id: 1 } }],
    currentSkillLevel: {
      id: 1,
      name: 'Basic 1',
      skills: [
        { id: 1, shortLabel: 'A', name: 'name' },
        { id: 2, shortLabel: 'B', name: 'name' },
        { id: 3, shortLabel: 'C', name: 'name' },
        { id: 4, shortLabel: 'D', name: 'name' },
        { id: 5, shortLabel: 'E', name: 'name' },
        { id: 6, shortLabel: 'F', name: 'name' },
      ],
      nextSkillLevel: { id: 2, name: 'Basic 2' },
    },
    studentsSkills: [
      {
        id: 7,
        shortLabel: 'A',
        status: 'introduced',
        name: 'name',
        skill: { id: 1 },
      },
      {
        id: 8,
        shortLabel: 'B',
        status: 'neutral',
        name: 'name',
        skill: { id: 2 },
      },
      {
        id: 9,
        shortLabel: 'C',
        status: 'completed',
        name: 'name',
        skill: { id: 3 },
      },
      {
        id: 10,
        shortLabel: 'D',
        status: 'introduced',
        name: 'name',
        skill: { id: 4 },
      },
      {
        id: 11,
        shortLabel: 'E',
        status: 'completed',
        name: 'name',
        skill: { id: 5 },
      },
      {
        id: 12,
        shortLabel: 'F',
        status: 'completed',
        name: 'name',
        skill: { id: 6 },
      },
    ],
  },
]

const studentsSelect = {
  Mixed: mixedStudents,
  Same: students,
}

export default {
  title: 'Coach Feature',
  component: CoachFeature,
  args: {
    coachCount: 1,
    skillLevelsCount: 1,
    startDatetime: '2019-12-28T19:43:25-07:00',
    students: mixedStudents,
    isLoading: false,
  },
  argTypes: {
    coachCount: { control: { type: 'range', min: 0, max: 20 } },
    skillLevelsCount: { control: { type: 'range', min: 0, max: 20 } },
    startDatetime: { control: { type: 'date' } },
    students: { control: { type: 'select', options: studentsSelect } },
  },
}

export function coachFeature({ coachCount, skillLevelsCount, ...args }) {
  const mockAxios = new MockAdapter(axios)

  function coaches(numCoaches) {
    const coaches = []
    for (let i = 0; i < numCoaches; i++) {
      coaches.push({
        id: i + 1,
        preferredName: 'Emmy',
        fullName: 'Emily Scott',
        cloudinaryPhotoPublicId:
          Math.random() >= 0.5 ? 'qrz0yfenrunmvcb0epwa' : '',
      })
    }
    return coaches
  }

  function levels(numLevels) {
    const levels = []
    for (let i = 0; i < numLevels; i++) {
      levels.push(
        htmlEntities.decode(
          `Level ${i + 1} Name`,
          `${faker.company.bsBuzz()} ${Math.floor(Math.random() * 10)}`
        )
      )
    }
    return levels
  }

  mockAxios.reset()
  mockAxios.onPatch(/\/klass_days_students\/(3|4)/).reply(200, {})

  return (
    <MockFacilityProvider>
      <CoachFeature
        {...args}
        coaches={coaches(coachCount)}
        skillLevels={levels(skillLevelsCount)}
        onGetKlassDay={() => {}}
        match={{ params: { facilityId: 1, klassDayId: 1 } }}
      />
    </MockFacilityProvider>
  )
}
