import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { get } from 'lodash'

import Button from '../Button/Button'
import Notification from '../Notification/Notification'
import './CheckoutForm.css'

// Stripe Style Object:
// https://stripe.com/docs/js/appendix/style
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#2d3748',
      fontFamily: '"Montserrat", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#a0aec0',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

const GENERIC_ERROR_MESSAGE =
  'There was a processing error. Check the information and try again.'

function CheckoutForm({ className, submitButtonLabel, onSubmit, disabled }) {
  function CardSection() {
    const stripe = useStripe()
    const elements = useElements()
    const [submitEnabled, setSubmitEnabled] = useState(false)
    const [error, setError] = useState({ show: false, message: '' })
    const [isProcessing, setIsProcessing] = useState(false)

    function handleSubmit(event) {
      event.preventDefault()
      event.stopPropagation()
      const isStripeLoaded = stripe && elements

      if (!isStripeLoaded || isProcessing || !submitEnabled) return // Stripe.js has not loaded yet so do nothing

      setIsProcessing(true)
      const card = elements.getElement(CardElement)
      stripe
        .createToken(card, { type: 'card', currency: 'usd' })
        .then(({ error, token }) => {
          if (error) {
            const message = error.message || GENERIC_ERROR_MESSAGE
            setError({ show: true, message })
            setIsProcessing(false)
          } else {
            return Promise.resolve(onSubmit(token)).then(() =>
              setIsProcessing(false)
            )
          }
        })
        .catch(handleProcessingError)
    }

    function handleProcessingError(error) {
      setIsProcessing(false)
      setError({
        show: true,
        message: get(error, 'response.data.error') || GENERIC_ERROR_MESSAGE,
      })
    }

    return (
      <form onSubmit={handleSubmit} className={className}>
        <CardElement
          options={{ ...CARD_ELEMENT_OPTIONS, disabled }}
          onChange={(e) => setSubmitEnabled(e.complete)}
        />
        <div className="text-right">
          <Button
            type="submit"
            label={submitButtonLabel}
            color="purple"
            disabled={!submitEnabled || isProcessing || disabled}
            loading={isProcessing}
            className="my-3"
            data-cy="pay-button"
          />
        </div>
        <Notification
          show={error.show}
          color="red"
          disappearIn={5000}
          onDisappearTimeout={() => setError({ show: false, message: '' })}
          data-cy="credit-card-failure-notification"
        >
          {error.message}
        </Notification>
      </form>
    )
  }

  // Stripe requires that it's a functional component using `useStripe` wrapped
  // in Elements to work, which is why we have a functional component
  // within a functional component
  return (
    <Elements stripe={loadStripe(process.env.STRIPE_PUBLISHABLE_KEY)}>
      <CardSection />
    </Elements>
  )
}

CheckoutForm.defaultProps = {
  submitButtonLabel: 'Submit',
  className: '',
  disabled: false,
}

CheckoutForm.propTypes = {
  className: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
}

export default CheckoutForm
