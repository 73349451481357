import React from 'react'
import { action } from '@storybook/addon-actions'
import { boolean } from '@storybook/addon-knobs'
import { Form } from 'react-final-form'

import PasswordFields from './PasswordFields'
import Button from '../Button/Button'

export default {
  title: 'Leevo UI/Forms/PasswordFields',
  component: PasswordFields,
}

export function passwordFields() {
  return (
    <Form
      onSubmit={action('Submit')}
      render={({ handleSubmit }) => (
        <>
          <PasswordFields className={boolean('Margins?', true) ? 'm-6' : ''} />
          <Button label="Submit" type="submit" onClick={handleSubmit} />
        </>
      )}
    />
  )
}
