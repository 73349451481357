import React from 'react'
import { text } from '@storybook/addon-knobs'

import ForgotPasswordModal from './ForgotPasswordModal'
import { action } from '@storybook/addon-actions'

export default {
  title: 'Leevo UI/Modals/ForgotPasswordModal',
  component: ForgotPasswordModal,
}

export function defaultModal() {
  return (
    <ForgotPasswordModal
      onForgotPasswordSubmit={({ email }) =>
        action(`submission with email: ${email}`)()
      }
    />
  )
}

export function forgotPasswordEmailNotFound() {
  return (
    <ForgotPasswordModal
      errorMessage={text(
        'errorMessage',
        'Unable to find user with email unkownuser@nowhere.com.'
      )}
      onForgotPasswordSubmit={({ email }) =>
        action(`submission with email: ${email}`)()
      }
    />
  )
}

export function forgotPasswordSuccess() {
  return (
    <ForgotPasswordModal
      onForgotPasswordSubmit={({ email }) =>
        action(`submission with email: ${email}`)()
      }
      successMessage={text(
        'successMessage',
        'An email has been sent to coach@example.com containing instructions for resetting your password.'
      )}
    />
  )
}
