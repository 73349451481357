import React from 'react'
import { shallow } from 'enzyme'

import LoginFields from './LoginFields'

describe('LoginFields', () => {
  let wrapper

  // FIXME: Add more specs.
  test('renders', () => {
    wrapper = shallow(
      <LoginFields
        onForgotPasswordClick={jest.fn()}
        onEmailChange={jest.fn()}
      />
    )
    expect(wrapper).toExist()
  })
})
