import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import KlassPackageListItem from './KlassPackageListItem/KlassPackageListItem'

function KlassPackageList({
  studentId,
  studentName,
  studentLevel,
  availableKlassPackages,
  enrolledKlassPackages,
  selectedKlassPackages,
}) {
  const [selected, setSelected] = useState(
    new Set(selectedKlassPackages.map((s) => s.id))
  )

  return (
    <Field name={`user-${studentId}-klasses`}>
      {({ input }) => (
        <>
          <div className="sticky top-0 px-4 py-3 font-medium text-gray-600 bg-gray-100">
            {studentName} - {studentLevel}
          </div>
          <ul>
            {availableKlassPackages.length === 0 &&
              enrolledKlassPackages.length === 0 && (
                <p
                  className="p-4 text-sm text-gray-800"
                  data-cy="no-classes-notification"
                >
                  {`There are no classes available for ${studentLevel}.`}
                </p>
              )}
            {availableKlassPackages.map(
              ({
                id,
                title,
                description,
                remainingKlassDaysForStudents,
                spotsLeftForStudents,
                priceInDollarsForStudents,
              }) => {
                return (
                  <KlassPackageListItem
                    key={id}
                    id={id}
                    title={title}
                    description={description}
                    remainingKlassDays={
                      remainingKlassDaysForStudents[studentId]
                    }
                    spotsLeft={spotsLeftForStudents[studentId]}
                    priceInDollars={priceInDollarsForStudents[studentId]}
                    selected={selected.has(id)}
                    onClick={(id) => {
                      if (selected.has(id)) {
                        selected.delete(id)
                        setSelected(new Set(selected))
                      } else {
                        setSelected(new Set(selected.add(id)))
                      }
                      input.onChange(
                        availableKlassPackages.filter((klassPackage) =>
                          selected.has(klassPackage.id)
                        )
                      )
                    }}
                    disabled={
                      isFinite(spotsLeftForStudents[studentId]) &&
                      spotsLeftForStudents[studentId] <= 0
                    }
                  />
                )
              }
            )}

            {enrolledKlassPackages.map(
              ({
                id,
                title,
                description,
                remainingKlassDaysForStudents,
                spotsLeftForStudents,
                priceInDollars,
              }) => {
                return (
                  <KlassPackageListItem
                    key={id}
                    id={id}
                    title={title}
                    description={description}
                    remainingKlassDays={
                      remainingKlassDaysForStudents[studentId]
                    }
                    spotsLeft={spotsLeftForStudents[studentId]}
                    priceInDollars={priceInDollars}
                    helpText="This student is already enrolled in all classes for this package."
                    disabled
                  />
                )
              }
            )}
          </ul>
        </>
      )}
    </Field>
  )
}

KlassPackageList.defaultProps = {
  availableKlassPackages: [],
  enrolledKlassPackages: [],
  selectedKlassPackages: [],
  onChange: () => {},
}

KlassPackageList.propTypes = {
  studentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  studentName: PropTypes.string.isRequired,
  studentLevel: PropTypes.string.isRequired,
  // FIXME: Can availableKlassPackages + enrolledKlassPackages be combined somehow?
  availableKlassPackages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      remainingKlassDaysForStudents: PropTypes.object.isRequired,
      spotsLeft: PropTypes.number,
      priceInDollarsForStudents: PropTypes.object.isRequired,
    })
  ),
  enrolledKlassPackages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      remainingKlassDaysForStudents: PropTypes.object.isRequired,
      spotsLeft: PropTypes.number,
      priceInDollarsForStudents: PropTypes.object.isRequired,
    })
  ),
  selectedKlassPackages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
}

export default KlassPackageList
