import React, { useState, useEffect } from 'react'

import Button from '../Button/Button'
import Modal from '../Modal/Modal'
import Transition from '../Transitions/Transition/Transition'
import useAuth from '../../../utilities/hooks/useAuth'
import { requestCreator } from '../../../utilities/requests'
import mixpanel from '../../../utilities/mixpanel'

const {
  get: getMarkedInterest,
  post: registerInterest,
  cancel: cancelInterestRequests,
} = requestCreator()

function FeatureInterestBanner() {
  const { currentUser } = useAuth()
  const [showBanner, setShowBanner] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const featureName = 'General Private Coaching Features'

  useEffect(() => {
    getMarkedInterest('/api/feature_interests', {
      params: { name: featureName },
    })
      .then(() => setShowBanner(false))
      .catch(() => setShowBanner(true))

    return cancelInterestRequests
  }, [])

  function markInterest(interested) {
    mixpanel.track('Marked Interest', { feature: featureName, interested })
    registerInterest('/api/feature_interests', {
      name: featureName,
      interested,
    })
    setShowInfoModal(false)
    setShowBanner(false)
  }

  return (
    <>
      <Modal
        open={showInfoModal}
        header="🥇 Private Coaching Features"
        onClickBackground={() => setShowInfoModal(false)}
        onClickClose={() => setShowInfoModal(false)}
      >
        <>
          <p className="mb-5">
            Be the first to get <span className="italic">free</span> features
            for your private coaching business:
          </p>
          <ul className="mb-5 space-y-2 list-inside">
            <li>
              👩‍💻 <span className="italic">Free</span> website for any new client
              to see
            </li>
            <li>🗓 Hands-off client scheduling</li>
            <li>💵 Easily process payments up front</li>
            <li>📈 Complete records for your taxes</li>
            <li>💰 $5 when you refer another coach</li>
          </ul>

          <p className="mb-12">
            {"Click below and we will notify you when it's ready."}
          </p>
          <div className="flex justify-end">
            <Button
              color="green"
              label="Notify me!"
              onClick={() => markInterest(true)}
            />
          </div>
        </>
      </Modal>
      <Transition
        show={currentUser.coach && showBanner}
        mountOnEnter={true}
        unmountOnExit={false}
        enterFrom="translate-y-full"
        enterTo="translate-y-0"
        leaveTo="translate-y-full"
        leaveFrom="translate-y-0"
        className="fixed bottom-0 right-0 z-50 w-full lg:max-w-[calc(100%-16rem)] p-4 text-white transition duration-300 ease-in transform bg-pink-700"
      >
        <footer className="flex items-center justify-end">
          <div className="mr-2">
            <span className="hidden text-xl md:inline">👋 </span>
            Want free tools for your private coaching business?
            <span className="hidden text-xl md:inline"> 👉</span>
          </div>
          <div className="flex">
            <Button
              color="green"
              label="Yes!"
              onClick={() => {
                mixpanel.track('Clicked "Yes" on Coaching Feature Banner')
                setShowInfoModal(true)
              }}
            />
            <Button
              className="ml-2"
              color="white"
              outline
              label="No"
              data-cy="feature-interest-no"
              onClick={() => markInterest(false)}
            />
          </div>
        </footer>
      </Transition>
    </>
  )
}

export default FeatureInterestBanner
