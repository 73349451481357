import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import Input from '../../leevo_ui/Input/Input'
import { isValidPassword } from '../../../utilities/validators'

function PasswordField({ autoComplete, className }) {
  return (
    <Field name="password" validate={isValidPassword}>
      {({ input, meta: { active, submitFailed, error } }) => (
        <Input
          {...input}
          id="password"
          autoComplete={autoComplete}
          className={className}
          type="password"
          label="Set your account password"
          placeholder="••••••••"
          errorMessage={!active && error && submitFailed ? error : ''}
        />
      )}
    </Field>
  )
}

PasswordField.defaultProps = {
  autoComplete: 'new-password',
}

PasswordField.propTypes = {
  autoComplete: PropTypes.oneOf(['new-password', 'current-password']),
  className: PropTypes.string,
}

export default PasswordField
