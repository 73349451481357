import React from 'react'

const lakeCityLogo =
  'https://leevo-public.s3.amazonaws.com/images/lake-city.png'
const eauClaireLogo =
  'https://leevo-public.s3.amazonaws.com/images/eau-claire.png'

function SideBySideTestimonial() {
  return (
    <section className="bg-purple-800 dark:bg-purple-900">
      <div className="mx-auto max-w-7xl md:grid md:grid-cols-2 md:px-6 lg:px-8">
        <div className="px-4 py-12 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-purple-900 md:dark:border-purple-800 lg:pr-16">
          <div className="md:flex-shrink-0">
            <img
              className="h-12 filter brightness-150"
              src={lakeCityLogo}
              alt="Lake City Figure Skating Club Logo"
            />
          </div>
          <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
            <div className="relative text-lg font-medium text-white dark:text-gray-300 md:flex-grow">
              <svg
                className="absolute top-0 left-0 w-8 h-8 text-purple-600 dark:bg-purple-7700 transform -translate-x-3 -translate-y-2"
                fill="currentColor"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="relative">
                I’m not going to lie, Leevo has changed my life!
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex items-start">
                <div>
                  <div className="text-base font-medium text-white">
                    Moe Herr
                  </div>
                  <div className="text-base font-medium text-purple-200">
                    Director, Spokane Figure Skating Club
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
        <div className="px-4 py-12 border-t-2 border-purple-900 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
          <div className="md:flex-shrink-0">
            <img
              className="h-12 filter brightness-150"
              src={eauClaireLogo}
              alt="Eau Claire Figure Skating Club Logo"
            />
          </div>
          <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
            <div className="relative text-lg font-medium text-white md:flex-grow">
              <svg
                className="absolute top-0 left-0 w-8 h-8 text-purple-600 transform -translate-x-3 -translate-y-2"
                fill="currentColor"
                viewBox="0 0 32 32"
              >
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="relative">
                Our program is run so much smoother with Leevo.
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex items-start">
                <div>
                  <div className="text-base font-medium text-white">
                    Kimberly Nass
                  </div>
                  <div className="text-base font-medium text-purple-200">
                    Director, Eau Claire Figure Skating Club
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  )
}

SideBySideTestimonial.defaultProps = {}

SideBySideTestimonial.propTypes = {}

export default SideBySideTestimonial
