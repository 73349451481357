import React from 'react'
import Recommendations from './Recommendations'
import { mount } from 'enzyme'

import RecommendationLevelCard from '../RecommendationLevelCard/RecommendationLevelCard'
import LoadingSpinner from '../../leevo_ui/LoadingSpinner/LoadingSpinner'

describe('Recommendations', () => {
  describe('with empty recommendations array', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(<Recommendations recommendations={[]} />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
    test('renders guidance to mark attendance for recommendations', () => {
      const tip = wrapper
        .find('[data-cy="recommendation-guidance"]')
        .hostNodes()
      expect(tip).toExist()
    })

    test('shows loading spinner when loading', () => {
      wrapper.setProps({ isLoading: true })
      expect(wrapper.find(LoadingSpinner)).toExist()
    })
  })

  describe('with no recommendations set', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(<Recommendations />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('renders guidance to mark attendance for recommendations', () => {
      const tip = wrapper
        .find('[data-cy="recommendation-guidance"]')
        .hostNodes()
      expect(tip).toExist()
    })

    test('shows loading spinner when loading', () => {
      wrapper.setProps({ isLoading: true })
      expect(wrapper.find(LoadingSpinner)).toExist()
    })
  })

  describe('with recommendations in a single skill level', () => {
    let wrapper
    beforeEach(() => {
      const recommendations = [
        {
          id: 1,
          count: 1,
          skill: { shortLabel: 'A', name: 'foo1' },
          skillLevel: { id: 1, name: 'bar' },
          status: 'unintroduced',
        },
        {
          id: 2,
          count: 2,
          skill: { shortLabel: 'B', name: 'foo2' },
          skillLevel: { id: 1, name: 'bar' },
          status: 'unintroduced',
        },
      ]
      wrapper = mount(<Recommendations recommendations={recommendations} />)
    })

    test('renders RecommendationLevelCards', () => {
      expect(wrapper.find(RecommendationLevelCard).length).toEqual(1)
    })

    test('shows loading spinner when loading', () => {
      wrapper.setProps({ isLoading: true })
      expect(wrapper.find(LoadingSpinner)).toExist()
    })
  })

  describe('with recommendations in multiple skill levels', () => {
    let wrapper
    beforeEach(() => {
      const recommendations = [
        {
          id: 1,
          count: 1,
          skill: { shortLabel: 'A', name: 'foo1' },
          skillLevel: { id: 1, name: 'bar1' },
          status: 'unintroduced',
        },
        {
          id: 2,
          count: 2,
          skill: { shortLabel: 'B', name: 'foo2' },
          skillLevel: { id: 2, name: 'bar2' },
          status: 'unintroduced',
        },
      ]
      wrapper = mount(<Recommendations recommendations={recommendations} />)
    })

    test('renders RecommendationLevelCards', () => {
      expect(wrapper.find(RecommendationLevelCard).length).toEqual(2)
    })

    test('shows loading spinner when loading', () => {
      wrapper.setProps({ isLoading: true })
      expect(wrapper.find(LoadingSpinner)).toExist()
    })
  })
})
