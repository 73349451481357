import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import AddressInput from '../../leevo_ui/AddressInput/AddressInput'

function AddressField({ className, name }) {
  return (
    <Field
      name="address"
      validate={(value) => {
        if (
          !value ||
          !(value.line1 && value.city && value.state && value.postCode)
        ) {
          return 'Must specify a street address, city, state and ZIP.'
        } else {
          return null
        }
      }}
    >
      {({ input, meta: { active, error, submitFailed } }) => (
        <AddressInput
          {...input}
          value={input.value || {}}
          className={className}
          label={`What's ${name}'s mailing address?`}
          helpText={`We use this information to register ${name} with Learn to Skate USA.`}
          errorMessage={!active && error && submitFailed ? error : ''}
        />
      )}
    </Field>
  )
}

AddressField.defaultProps = {
  name: 'the student',
}

AddressField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
}

export default AddressField
