import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { MemoryRouter, Route } from 'react-router-dom'

import MockFacilityProvider from '../../providers/MockFacilityProvider'
import FacilitiesDisclosuresEdit from './FacilitiesDisclosuresEdit'

export default {
  title: 'FacilitiesDisclosuresEdit',
  component: FacilitiesDisclosuresEdit,
  args: {
    className: 'm-4',
  },
}

const mockDisclosure = {
  data: {
    id: 1,
    type: 'disclosure',
    attributes: {
      name: 'Some name',
      disclosureType: 'document',
      required: true,
      active: true,
      agreement: 'The quick brown fox jumps over the lazy dog',
    },
    relationships: {
      responses: {
        data: [
          { id: '1', type: 'response' },
          { id: '2', type: 'response' },
          { id: '3', type: 'response' },
        ],
      },
    },
  },
  included: [
    {
      id: '1',
      type: 'response',
      attributes: {
        agreed: false,
        updatedAt: '2020-01-01T00:00:00.000Z',
      },
      relationships: {
        student: { data: { id: '3', type: 'student' } },
        respondent: { data: { id: '2', type: 'user' } },
      },
    },
    {
      id: '2',
      type: 'response',
      attributes: {
        agreed: true,
        updatedAt: '2020-01-01T00:00:00.000Z',
      },
      relationships: {
        student: { data: { id: '4', type: 'student' } },
        respondent: { data: { id: '1', type: 'user' } },
      },
    },
    {
      id: '3',
      type: 'response',
      attributes: {
        agreed: true,
        updatedAt: '2020-01-01T00:00:00.000Z',
      },
      relationships: {
        student: { data: { id: '5', type: 'student' } },
        respondent: { data: { id: '1', type: 'user' } },
      },
    },
    {
      id: '1',
      type: 'user',
      attributes: { fullName: 'Jane Doe' },
    },
    {
      id: '2',
      type: 'user',
      attributes: { fullName: 'John Doe' },
    },
    {
      id: '3',
      type: 'student',
      attributes: { fullName: 'James Doe' },
    },
    {
      id: '4',
      type: 'student',
      attributes: { fullName: 'Roberta Doe' },
    },
    {
      id: '5',
      type: 'student',
      attributes: { fullName: 'Harold Doe' },
    },
  ],
}

export function facilitiesDisclosuresEdit(args) {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onGet(/disclosures/).reply(200, mockDisclosure)

  return (
    <MemoryRouter initialEntries={['/facilities/1/disclosures/1/edit']}>
      <MockFacilityProvider>
        <Route path="/facilities/1/disclosures/:disclosure_id/edit">
          <FacilitiesDisclosuresEdit {...args} />
        </Route>
      </MockFacilityProvider>
    </MemoryRouter>
  )
}
