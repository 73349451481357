import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-date-picker'
import Calendar from 'react-calendar'
import moment from 'moment'
import classNames from 'classnames'

function BirthdatePicker({
  errorMessage,
  className,
  onChange,
  value,
  label,
  id,
}) {
  return (
    <div className={className}>
      {label && (
        <label
          className={`${classNames({
            'border-red-500': errorMessage,
            'text-red-500': errorMessage,
          })} block text-gray-700 text-sm font-medium mb-1 ml-1`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <div id={id}>
        <DatePicker
          className={`${classNames({
            'border-red-500 text-red-500 error-shake': errorMessage,
            'text-gray-700': !errorMessage,
          })} shadow border rounded w-full text-center text-sm text-gray-700 mb-1 py-2 px-3 leading-tight focus:ring bg-white`}
          disabled
          disableCalendar
          value={value}
          monthPlaceholder="MM"
          dayPlaceholder="DD"
          yearPlaceholder="YYYY"
          calendarIcon={null}
          clearIcon={null}
        />
        <p className="mb-3 text-xs italic text-red-500">{errorMessage}</p>
        <div className="flex justify-center">
          <Calendar
            className="my-1 text-gray-700 border-gray-700 rounded"
            defaultActiveStartDate={
              value ? moment(value).toDate() : moment('2011-01-01').toDate()
            } // Default start date should be last full decade
            onChange={onChange}
            defaultView={value ? 'month' : 'decade'}
            value={value ? moment(value).toDate() : null}
            maxDate={moment().toDate()}
            calendarType="US"
          />
        </div>
      </div>
    </div>
  )
}

BirthdatePicker.default = {
  className: '',
  errorMessage: '',
  onChange: () => {},
}

BirthdatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
}

export default BirthdatePicker
