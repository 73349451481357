import React from 'react'
import { shallow } from 'enzyme'

import UploadButton from './UploadButton'

describe('UploadButton', () => {
  let wrapper

  beforeEach(() => {
    wrapper = shallow(<UploadButton userId={1} />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
