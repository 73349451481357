import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Fuse from 'fuse.js'
import pluralize from 'pluralize'

import Input from '../../Input/Input'
import CheckButton from '../../CheckButton/CheckButton'
import { alphabetizeByFullName } from '../../../../utilities/helpers'

const fuzzySearchOptions = {
  findAllMatches: true,
  keys: ['title'],
}

function MultiSelectKlassPackage({
  className,
  value: klassPackages,
  onChange,
}) {
  const [query, setQuery] = useState('')
  const [selected, setSelected] = useState(
    new Set(
      klassPackages
        .filter((klassPackage) => klassPackage.selected)
        .map((klassPackage) => klassPackage.id)
    )
  )

  useEffect(() => {
    setSelected(
      new Set(
        klassPackages
          .filter((klassPackage) => klassPackage.selected)
          .map((klassPackage) => klassPackage.id)
      )
    )
  }, [klassPackages])

  function toggle(klassPackage) {
    let newSelectedKlassPackages
    if (selected.has(klassPackage.id)) {
      selected.delete(klassPackage.id)
      newSelectedKlassPackages = new Set(selected)
    } else {
      newSelectedKlassPackages = new Set(selected.add(klassPackage.id))
    }
    setSelected(newSelectedKlassPackages)
    onChange(
      klassPackages.map((klassPackage) => {
        return {
          ...klassPackage,
          selected: newSelectedKlassPackages.has(klassPackage.id),
        }
      })
    )
  }

  function filteredKlassPackages() {
    if (!query) return klassPackages

    const fuzzySearch = new Fuse(klassPackages, fuzzySearchOptions)
    return fuzzySearch.search(query).map(({ item }) => item)
  }

  return (
    <section className={className} data-cy="klassPackages-multi-select">
      <p className="block mb-1 ml-1 text-sm font-light text-gray-700">
        {pluralize('class package', selected.size, true)} selected
      </p>
      <div className="h-64 mb-2 overflow-scroll border rounded-md shadow-inner ">
        <Input
          className="sticky top-0 p-1 pb-0 bg-white"
          id="klass-package-search"
          type="search"
          placeholder="Search for packages by name..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <ul>
          {filteredKlassPackages()
            .sort(alphabetizeByFullName)
            .map((klassPackage) => (
              <li
                key={klassPackage.id}
                className="flex items-center h-12 p-1 text-gray-800 border-b border-gray-300 xs:p-3"
                data-cy="klass-package-option"
              >
                <div
                  className="flex items-center justify-between cursor-pointer w-full"
                  onClick={() => toggle(klassPackage)}
                >
                  <div className="flex items-center justify-start">
                    <CheckButton
                      className="mr-2"
                      ariaProps={{
                        'aria-pressed': selected.has(klassPackage.id),
                        'aria-label': 'select date',
                      }}
                      checked={selected.has(klassPackage.id)}
                      data-cy={`${klassPackage.title}-check-button`}
                      size={5}
                    />
                    <p>{klassPackage.title}</p>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </section>
  )
}

MultiSelectKlassPackage.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  className: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
    dates: PropTypes.array,
  }),
}

export default MultiSelectKlassPackage
