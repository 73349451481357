import React from 'react'
import { select, number, boolean } from '@storybook/addon-knobs'
import ProgressBar from './ProgressBar'

export default {
  title: 'Leevo UI/ProgressBar',
  component: ProgressBar,
}

export function progressBar() {
  const withPadding = boolean('With padding?', true)
  return (
    <ProgressBar
      className={withPadding ? 'm-10' : null}
      size={select('Size', ProgressBar.sizes)}
      percentage={number('Percentage', 50, {
        range: true,
        min: 0,
        max: 100,
        step: 1,
      })}
    />
  )
}
