import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'
import { get } from 'lodash'

import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import IndexHeader from '../leevo_ui/IndexHeader/IndexHeader'
import Notification from '../leevo_ui/Notification/Notification'
import useFacility from '../../utilities/hooks/useFacility'
import DisclosuresList from './DisclosuresList/DisclosuresList'
import EmptyList from '../leevo_ui/EmptyList/EmptyList'
import LoadingSpinner from '../leevo_ui/LoadingSpinner/LoadingSpinner'
import { requestCreator } from '../../utilities/requests'
import useAuth from '../../utilities/hooks/useAuth'

const {
  get: getDisclosures,
  cancel: cancelDisclosuresRequests,
} = requestCreator()

function FacilitiesDisclosuresIndex() {
  const { push: navigate, replace: redirect } = useHistory()
  const { facility, isLoading: isFacilityLoading } = useFacility()
  const [disclosures, setDisclosures] = useState([])
  const [isLoadingDisclosures, setIsLoadingDisclosures] = useState(true)
  const { isSignedIn } = useAuth()

  useEffect(() => {
    if (!isFacilityLoading) {
      getDisclosures(`/api/facilities/${facility.id}/disclosures`)
        .then((retrievedDisclosures) => {
          setDisclosures(retrievedDisclosures)
          setIsLoadingDisclosures(false)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            if (get(error, 'response.status') === 401 && !isSignedIn) {
              redirect('/login')
            } else {
              redirect('/500')
            }
          }
        })
    }

    return cancelDisclosuresRequests
  }, [isFacilityLoading, facility.id, isSignedIn, redirect])

  function renderEmpty() {
    return (
      <EmptyList
        message="None have been made yet. 📃"
        buttonText="Create One"
        onButtonClick={() =>
          navigate(`/facilities/${facility.id}/disclosures/new`)
        }
      />
    )
  }

  function renderLists() {
    const required = disclosures.filter((d) => d.required && d.active)
    const optional = disclosures.filter((d) => !d.required && d.active)
    const disabled = disclosures.filter((d) => !d.active)

    const lists = []

    if (required.length > 0)
      lists.push(
        <DisclosuresList
          data-cy="required-disclosures"
          heading="✅ Required"
          disclosures={required}
        />
      )

    if (optional.length > 0)
      lists.push(
        <DisclosuresList
          data-cy="optional-disclosures"
          heading="🤞 Optional"
          disclosures={optional}
        />
      )

    if (disabled.length > 0)
      lists.push(
        <DisclosuresList
          data-cy="disabled-disclosures"
          heading="💤 Disabled"
          disclosures={disabled}
        />
      )

    return lists
  }

  return (
    <ApplicationLayout>
      <IndexHeader
        title="Disclosures"
        onAddClick={() =>
          navigate(`/facilities/${facility.id}/disclosures/new`)
        }
      />
      {isLoadingDisclosures ? (
        <LoadingSpinner
          size="1/5"
          className="flex items-center justify-center m-10"
        />
      ) : (
        <div className="flex justify-center h-full p-5 bg-gray-100">
          <div className="w-full max-w-2xl">
            <Notification show data-cy="disclosures-notice" color="blue">
              By using Leevo&apos;s disclosure features, you are agreeing to our
              disclosure liability notice.{' '}
              <Link
                to="/terms"
                className="text-purple-600 hover:text-purple-400"
              >
                See full terms
              </Link>
            </Notification>
            <div className="rounded-lg shadow">
              <div className="px-4 py-5 space-y-12 bg-white sm:p-6">
                {disclosures.length === 0 ? renderEmpty() : renderLists()}
              </div>
            </div>
          </div>
        </div>
      )}
    </ApplicationLayout>
  )
}

FacilitiesDisclosuresIndex.defaultProps = {}

FacilitiesDisclosuresIndex.propTypes = {
  className: PropTypes.string,
}

export default FacilitiesDisclosuresIndex
