import React from 'react'
import PropTypes from 'prop-types'
import CircleButton from '../CircleButton/CircleButton'

import checkmark from '../../../images/checkmark.svg'

const CheckButton = React.forwardRef(
  (
    {
      onClick,
      className,
      size,
      style,
      checked,
      disabled,
      ariaProps,
      loading,
      value,
      'data-cy': dataCy,
      ...rest
    },
    ref
  ) => {
    return (
      <CircleButton
        {...rest}
        ref={ref}
        ariaProps={ariaProps}
        data-cy={dataCy || `${value}-checkbox`}
        disabled={disabled}
        onClick={onClick}
        className={className}
        size={size}
        style={style}
        color={checked ? 'purple' : 'gray'}
        svgSrc={checked ? checkmark : undefined}
        loading={loading}
        value={value}
      />
    )
  }
)

CheckButton.displayName = 'CheckButton'
CheckButton.sizes = CircleButton.sizes

CheckButton.defaultProps = {
  size: CircleButton.defaultProps.size,
  loading: CircleButton.defaultProps.loading,
}

CheckButton.propTypes = {
  ariaProps: PropTypes.shape({
    'aria-pressed': PropTypes.bool,
    'aria-label': PropTypes.string,
  }),
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  'data-cy': PropTypes.string,
  size: PropTypes.oneOf(CircleButton.sizes),
  loading: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.string,
}

export default CheckButton
