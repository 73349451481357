import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { get } from 'lodash'
import Fuse from 'fuse.js'

import LoadingSpinner from '../leevo_ui/LoadingSpinner/LoadingSpinner'
import IndexHeader from '../leevo_ui/IndexHeader/IndexHeader'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import Input from '../leevo_ui/Input/Input'
import AddCoachModal from '../leevo_ui/AddCoachModal/AddCoachModal'
import Notification from '../leevo_ui/Notification/Notification'
import TeamMemberListItem from './TeamMemberListItem/TeamMemberListItem'
import { requestCreator } from '../../utilities/requests'
import useFacility from '../../utilities/hooks/useFacility'
import useAuth from '../../utilities/hooks/useAuth'

const { get: getCoaches, cancel: cancelCoachesRequest } = requestCreator()

const fuzzySearchOptions = {
  findAllMatches: true,
  keys: ['fullName', 'preferredName', 'email'],
}

function FacilitiesCoachesIndex() {
  const { facility, isLoading: isFacilityLoading } = useFacility({
    isSubscriptionRequired: false,
  })
  const history = useHistory()
  const [query, setQuery] = useState('')
  const [coaches, setCoaches] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isAddingUser, setIsAddingUser] = useState(false)
  const [notification, setNotification] = useState({ show: false, message: '' })
  const { isSignedIn, isLoading: isAuthLoading } = useAuth()

  useEffect(() => {
    setIsLoading(true)

    if (!isFacilityLoading && !isAuthLoading) {
      getCoaches(`/api/facilities/${facility.id}/coaches`)
        .then((coaches) => setCoaches(coaches))
        .then(() => setIsLoading(false))
        .catch((error) => {
          if (!axios.isCancel(error)) {
            if (get(error, 'response.status') === 401 && !isSignedIn) {
              history.replace('/login')
            } else {
              history.replace('/500')
            }
          }
        })
    }

    return cancelCoachesRequest
  }, [isFacilityLoading, isAuthLoading, isSignedIn, facility, history])

  function isPending(coach) {
    return coach.invitedToSignUp && !coach.invitationAccepted
  }

  function activeCoaches() {
    const activeCoaches = coaches.filter((coach) => !isPending(coach))
    if (!query) return activeCoaches

    const fuzzySearch = new Fuse(activeCoaches, fuzzySearchOptions)
    return query
      ? fuzzySearch.search(query).map(({ item }) => item)
      : activeCoaches
  }

  function pendingCoaches() {
    const pendingCoaches = coaches.filter((coach) => isPending(coach))
    if (!query) return pendingCoaches

    const fuzzySearch = new Fuse(pendingCoaches, fuzzySearchOptions)
    return query
      ? fuzzySearch.search(query).map(({ item }) => item)
      : pendingCoaches
  }

  return (
    <ApplicationLayout data-cy="staff-index">
      <AddCoachModal
        open={isAddingUser}
        onClose={({ invitedUsers }) => {
          setIsAddingUser(false)
          if (invitedUsers) {
            setNotification({
              show: true,
              message: 'Successfully added staff members',
            })
            setCoaches([...coaches, ...invitedUsers])
          }
        }}
      />
      <Notification
        show={notification.show}
        color="green"
        onDisappearTimeout={() =>
          setNotification({ ...notification, show: false })
        }
      >
        {notification.message}
      </Notification>
      <IndexHeader title="Staff" onAddClick={() => setIsAddingUser(true)} />
      {isLoading ? (
        <LoadingSpinner
          size="1/5"
          className="flex items-center justify-center m-10"
        />
      ) : (
        <div className="flex justify-center h-full">
          <ul className="w-full max-w-2xl pt-2 mx-2 my-4 bg-white rounded-lg shadow">
            <Input
              id="search"
              type="search"
              className="m-3"
              placeholder="Search by name or email..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            {activeCoaches().map((coach) => (
              <TeamMemberListItem key={coach.id} {...coach} />
            ))}
            {pendingCoaches().map((coach) => (
              <TeamMemberListItem key={coach.id} pending {...coach} />
            ))}
          </ul>
        </div>
      )}
    </ApplicationLayout>
  )
}

export default FacilitiesCoachesIndex
