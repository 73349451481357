import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import SVG from 'react-inlinesvg'

import copy from '../../../images/copy-icon.svg'
import CheckButton from '../../leevo_ui/CheckButton/CheckButton'

function CopyField({ text, className }) {
  const [copied, setCopied] = useState(false)

  return (
    <div className={className}>
      <div className="flex items-center space-x-2">
        <p>{text}</p>
        <CopyToClipboard
          text={text}
          onCopy={() => {
            setCopied(true)
            setTimeout(() => setCopied(false), 1000)
          }}
        >
          {copied ? (
            <div className="flex items-center space-x-1">
              <CheckButton checked size={4} />{' '}
              <span className="text-xs">Copied!</span>
            </div>
          ) : (
            <SVG
              className="w-3 h-auto text-purple-600 fill-current stroke-current"
              src={copy}
            />
          )}
        </CopyToClipboard>
      </div>
    </div>
  )
}

CopyField.defaultProps = {}

CopyField.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default CopyField
