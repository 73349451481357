import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import AdminFacilityRow from './AdminFacilityRow/AdminFacilityRow'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import IndexHeader from '../leevo_ui/IndexHeader/IndexHeader'
import Table from '../leevo_ui/Table/Table'
import { requestCreator } from '../../utilities/requests'

const { get: getFacilities, cancel: cancelFacilityRequests } = requestCreator()

function AdminFacilitiesIndex() {
  const [isLoading, setIsLoading] = useState(true)
  const [facilities, setFacilities] = useState([])
  const { replace: redirect, push: navigate } = useHistory()

  useEffect(() => {
    getFacilities('/api/admin/facilities', {
      params: {
        fields: {
          facility:
            'name,numberOfStudentsEnrolledThisMonth,lastTimeSkillMarked,lastDirectorSignInAt,lastKlassStartDatetime,nextKlassStartDatetime,lastContactedAt',
        },
      },
    })
      .then(setFacilities)
      .catch((error) => {
        if (axios.isCancel(error)) {
          return
        } else if (error.response.status == 401) {
          redirect('/login')
        } else {
          throw error
        }
      })
      .finally(() => setIsLoading(false))
    return cancelFacilityRequests
  }, [redirect])

  return (
    <ApplicationLayout isContentLoading={isLoading}>
      <IndexHeader
        title="Facilities"
        onAddClick={() => navigate('/facilities/new')}
      />
      <div className="mx-auto bg-white rounded-lg shadow">
        <Table
          headers={[
            'ID',
            'Name',
            'Students This Month',
            'Last Director Sign In',
            'Last Skill Marked',
            'Last Class',
            'Next Class',
            'Last Contacted',
          ]}
        >
          {facilities.map((facility) => (
            <AdminFacilityRow key={facility.id} {...facility} />
          ))}
        </Table>
      </div>
    </ApplicationLayout>
  )
}

AdminFacilitiesIndex.defaultProps = {}

AdminFacilitiesIndex.propTypes = {}

export default AdminFacilitiesIndex
