import { select, text, boolean } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'
import React from 'react'
import htmlEntities from 'he'

import CircleButton from './CircleButton'
import checkmark from '../../../images/checkmark.svg'

export default {
  title: 'Leevo UI/CircleButton',
  component: CircleButton,
}

export function singleButton() {
  return (
    <div className="flex flex-col items-center justify-center m-5">
      <CircleButton
        onClick={action('CircleButton clicked')}
        size={select(
          'Size',
          CircleButton.sizes,
          CircleButton.defaultProps.size
        )}
        label={htmlEntities.decode(text('Label', 'A'))}
        color={select(
          'Color',
          CircleButton.colors,
          CircleButton.defaultProps.color
        )}
        disabled={boolean('Disabled?', false)}
        loading={boolean('Loading?', false)}
      />
    </div>
  )
}

export function all() {
  return (
    <div className="flex flex-col items-center justify-center">
      {CircleButton.sizes.map((size) => (
        <div key={size} className="w-full m-4 text-center">
          <h1 className="mb-2 text-2xl">Size {size}</h1>

          <div>
            <h2 className="mt-2 text-xl">Enabled</h2>
            {CircleButton.colors.map((color) => (
              <CircleButton
                className="m-1"
                key={`${color}-${size}`}
                onClick={action(`CircleButton-${color}-${size} clicked`)}
                size={size}
                color={color}
                label={htmlEntities.decode(text('Label', 'A'))}
                svgSrc={boolean('Icon Label?', false) ? checkmark : undefined}
                loading={boolean('Loading?', false)}
              />
            ))}
          </div>
          <div>
            <h2 className="mt-2 text-xl">Disabled</h2>
            {CircleButton.colors.map((color) => (
              <CircleButton
                className="m-1"
                key={`${color}-${size}`}
                onClick={action(`CircleButton-${color}-${size} clicked`)}
                size={size}
                color={color}
                label={htmlEntities.decode(text('Label', 'A'))}
                svgSrc={boolean('Icon Label?', false) ? checkmark : undefined}
                loading={boolean('Loading?', false)}
                disabled
              />
            ))}
          </div>
          <hr className="my-8 border-2 border-gray-500" />
        </div>
      ))}
    </div>
  )
}
