import React, { useState } from 'react'
import { text, boolean, number } from '@storybook/addon-knobs'
import { Form, Field } from 'react-final-form'
import { action } from '@storybook/addon-actions'
import htmlElements from 'he'

import TextArea from './TextArea'

export default {
  title: 'Forms/TextArea',
  component: TextArea,
}

export function VanillaTextArea() {
  const [value, setValue] = useState('Some default value')

  return (
    <TextArea
      className="m-6"
      id="email"
      maxLength={number('Max Length', 100)}
      label={htmlElements.decode(text('Label', 'Description'))}
      helpText={htmlElements.decode(
        text('Help Text', 'This is some help text')
      )}
      placeholder={htmlElements.decode(text('Placeholder', 'Email'))}
      errorMessage={htmlElements.decode(text('Error Message', ''))}
      disabled={boolean('Disabled?', false)}
      required={boolean('Required?', false)}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  )
}

export function finalFormInput() {
  return (
    <Form
      onSubmit={action('submitted')}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field name="input">
            {({ input }) => (
              <TextArea
                {...input}
                className="m-6"
                id="email"
                maxLength={number('Max Length', 100)}
                label={htmlElements.decode(text('Label', 'Your email'))}
                helpText={htmlElements.decode(
                  text('Label', 'This is your email')
                )}
                placeholder={htmlElements.decode(text('Placeholder', 'Email'))}
                errorMessage={htmlElements.decode(text('Error Message', ''))}
                disabled={boolean('Disabled?', false)}
                required={boolean('Required?', false)}
              />
            )}
          </Field>
        </form>
      )}
    />
  )
}
