import React from 'react'
import { boolean, select } from '@storybook/addon-knobs'

import CirclePhoto from './CirclePhoto'

export default {
  title: 'Leevo UI/Avatar/CirclePhoto',
  component: CirclePhoto,
}

export function circlePhoto() {
  return (
    <div className="flex items-center justify-center">
      <CirclePhoto
        cloudinaryPhotoPublicId={
          boolean('Default SVG?', false) ? undefined : 'qrz0yfenrunmvcb0epwa'
        }
        size={select('Size', CirclePhoto.sizes, 24)}
      />
    </div>
  )
}
