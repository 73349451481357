import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import BirthdatePicker from '../../leevo_ui/BirthdatePicker/BirthdatePicker'
import { presence } from '../../../utilities/validators'

function BirthdateField({ className, name }) {
  return (
    <Field name="birthdate" validate={presence}>
      {({ input, meta: { active, error, submitFailed } }) => (
        <BirthdatePicker
          {...input}
          className={className}
          id="birthdate"
          label={`What's ${name}'s birthdate?`}
          errorMessage={!active && error && submitFailed ? error : ''}
        />
      )}
    </Field>
  )
}

BirthdateField.defaultProps = {
  name: 'the student',
}

BirthdateField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
}

export default BirthdateField
