import React from 'react'
import { Form } from 'react-final-form'
import { action } from '@storybook/addon-actions'

import InternalInfoSection from './InternalInfoSection'

export default {
  title: 'Leevo UI/DisclosureForm/InternalInfoSection',
  component: InternalInfoSection,
  args: {
    className: 'm-4',
  },
}

export function internalInfoSection(args) {
  return (
    <Form
      onSubmit={action('Submitted')}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <InternalInfoSection {...args} />
        </form>
      )}
    />
  )
}
