import React from 'react'
import { MemoryRouter } from 'react-router'
import MockAuthProvider from '../../providers/MockAuthProvider'

import AdminLTSReportsNew from './AdminLTSReportsNew'

export default {
  title: 'AdminLTSReportsNew',
  component: AdminLTSReportsNew,
}

export function adminLTSReportsNew() {
  return (
    <MemoryRouter initialEntries={['/']}>
      <MockAuthProvider>
        <AdminLTSReportsNew />
      </MockAuthProvider>
    </MemoryRouter>
  )
}
