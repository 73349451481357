import React from 'react'
import PropTypes from 'prop-types'

export default function InitialsAvatar({
  fullName,
  color,
  size,
  className,
  onClick,
}) {
  let sizeClasses
  switch (size) {
    case 24:
      sizeClasses = 'h-24 w-24'
      break
    case 12:
      sizeClasses = 'h-12 w-12'
      break
    case 10:
      sizeClasses = 'h-10 w-10'
      break
    case 8:
      sizeClasses = 'h-8 w-8'
      break
    case 5:
      sizeClasses = 'h-5 w-5'
      break
    default:
      sizeClasses = 'h-16 w-16'
  }

  const allInitials = fullName.split(' ').map((name) => name.charAt())

  const colors = {
    blue: 'bg-blue-100 text-blue-600',
    indigo: 'bg-indigo-100 text-indigo-600',
    red: 'bg-red-100 text-red-600',
    orange: 'bg-orange-100 text-orange-600',
    green: 'bg-green-100 text-green-600',
    teal: 'bg-teal-100 text-teal-600',
    pink: 'bg-pink-100 text-pink-600',
    yellow: 'bg-yellow-100 text-yellow-700',
    purple: 'bg-purple-100 text-purple-600',
  }

  let firstAndLastInitials = allInitials.shift()
  if (allInitials.length > 0) {
    firstAndLastInitials += allInitials.pop()
  }

  return (
    <div className={className}>
      <div
        className="group relative focus:outline-none"
        onClick={onClick}
        tabIndex={onClick ? '0' : null}
      >
        {onClick && (
          <div className="absolute cursor-pointer inset-0 w-full h-full transparent rounded-full hover:bg-black group-focus:bg-black opacity-5"></div>
        )}
        <svg
          className={`${sizeClasses} ${colors[color]} text-4xl fill-current rounded-full shadow-inner`}
          viewBox="0 0 100 100"
        >
          <text
            x="50%"
            y="50%"
            alignmentBaseline="central"
            textAnchor="middle"
            className="font-semibold tracking-widest uppercase"
          >
            {firstAndLastInitials}
          </text>
        </svg>
      </div>
    </div>
  )
}

InitialsAvatar.colors = [
  'red',
  'purple',
  'yellow',
  'teal',
  'pink',
  'blue',
  'green',
  'orange',
]

InitialsAvatar.sizes = [24, 16, 12, 10, 8, 5]

InitialsAvatar.defaultProps = {
  className: '',
  color: 'purple',
  size: 24,
}

InitialsAvatar.propTypes = {
  fullName: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(InitialsAvatar.colors),
  size: PropTypes.oneOf(InitialsAvatar.sizes),
  onClick: PropTypes.func,
}
