import React from 'react'

import MockFacilityProvider from '../../../providers/MockFacilityProvider'
import DisclosuresListItem from './DisclosuresListItem'

export default {
  title: 'FacilityDisclosuresIndex/DisclosuresListItem',
  component: DisclosuresListItem,
  args: {
    name: 'Some title',
    id: 1,
  },
  argTypes: {
    id: { table: { disable: true } },
  },
}

export function disclosuresListItem(args) {
  return (
    <MockFacilityProvider>
      <ul>
        <DisclosuresListItem {...args} />
      </ul>
    </MockFacilityProvider>
  )
}
