import React from 'react'
import PropTypes from 'prop-types'

import CheckButton from '../CheckButton/CheckButton'

function CheckboxInput({
  className,
  label,
  id,
  onClick,
  checked,
  value,
  small,
  helpText,
  disabled,
  'data-cy': dataCy,
}) {
  const buttonRef = React.useRef()

  function onClickLabel(e) {
    buttonRef.current.focus()
    onClick({ ...e, target: buttonRef.current })
  }

  return (
    <div className={className}>
      <div
        className={`${
          small ? 'text-sm py-3' : 'py-5'
        } flex justify-between w-full font-medium`}
      >
        <div>
          <label
            onClick={onClickLabel}
            className="flex items-center w-full"
            htmlFor={id}
          >
            {label}
          </label>
          {helpText && <p className="text-xs text-gray-500">{helpText}</p>}
        </div>
        <CheckButton
          ref={buttonRef}
          onClick={onClick}
          id={id}
          checked={checked}
          disabled={disabled}
          size={small ? 6 : 8}
          value={value}
          data-cy={dataCy || `${id}-checkbox-input`}
        />
      </div>
    </div>
  )
}

CheckboxInput.defaultProps = {
  checked: false,
  small: false,
}

CheckboxInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  helpText: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  'data-cy': PropTypes.string,
}

export default CheckboxInput
