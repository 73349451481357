import React from 'react'
import TestimonialWithLargeAvatar from './TestimonialWithLargeAvatar'

export default {
  title: 'Home Feature/Components/TestimonialWithLargeAvatar',
  component: TestimonialWithLargeAvatar,
}

export function testimonialWithLargeAvatar(args) {
  return <TestimonialWithLargeAvatar {...args} />
}
