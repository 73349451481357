import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'

import GuardianInfo from '../GuardianInfo/GuardianInfo'
import Card from '../../../leevo_ui/Card/Card'
import Input from '../../../leevo_ui/Input/Input'
import { isEmail } from '../../../../utilities/validators'
import { requestCreator } from '../../../../utilities/requests'

const {
  post: addGuardian,
  delete: removeGuardian,
  cancel: cancelGuardianRequests,
} = requestCreator()

function GuardianManager({
  className,
  guardians,
  studentId,
  onChange,
  ...rest
}) {
  const [guardianList, setGuardianList] = useState(guardians)
  const history = useHistory()

  useEffect(() => cancelGuardianRequests, [])

  function handleError(error) {
    const message = get(error, 'response.data.error') || 'Something went wrong.'
    history.replace({
      state: { notification: { message, color: 'red' } },
    })
  }

  return (
    <div {...rest} className={className}>
      <Form
        onSubmit={({ newGuardianEmail }) => {
          let promise = Promise.resolve({ email: newGuardianEmail })

          if (studentId) {
            promise = addGuardian(`/api/students/${studentId}/guardians`, {
              guardian: { email: newGuardianEmail },
            })
          }

          return promise
            .then((newGuardian) => {
              setGuardianList([...guardianList, newGuardian])
              onChange([...guardianList, newGuardian])
            })
            .catch(handleError)
        }}
        render={({ handleSubmit, submitting, form }) => (
          <form onSubmit={(e) => handleSubmit(e).finally(() => form.reset())}>
            <Field name="newGuardianEmail" validate={isEmail}>
              {({
                input,
                meta: { error, touched, submitError, dirty, active },
              }) => {
                const errorMessage = error || submitError || ''
                return (
                  <header>
                    <Input
                      {...input}
                      id="newGuardianEmail"
                      type="text"
                      label="Enter the email of the new guardian"
                      helpText="If they don't have an account, they will get an invite."
                      autoComplete="off"
                      placeholder="Enter the guardian's email address."
                      buttonLabel="Add"
                      onButtonClick={(e) => form.submit(e)}
                      isButtonDisabled={!!error || submitting}
                      errorMessage={
                        dirty && !active && touched && errorMessage
                          ? errorMessage
                          : ''
                      }
                    />
                  </header>
                )
              }}
            </Field>
          </form>
        )}
      />
      <section className="flex flex-wrap">
        {guardianList.map((guardian) => (
          <Card
            key={guardian.id}
            className="relative inline-block px-4 py-2 m-1 sm:min-w-xs"
          >
            <GuardianInfo
              {...guardian}
              onRemove={async (id) => {
                const url = `/api/students/${studentId}/guardians/${id}`

                try {
                  if (id) await removeGuardian(url)
                  setGuardianList(
                    guardianList.filter((guardian) => guardian.id != id)
                  )
                } catch (error) {
                  handleError(error)
                }
              }}
              className="space-y-2"
            />
          </Card>
        ))}
      </section>
    </div>
  )
}

GuardianManager.defaultProps = {
  guardians: [],
}

GuardianManager.propTypes = {
  guardians: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      fullName: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      cloudinaryPhotoPublicId: PropTypes.string,
      pending: PropTypes.bool,
      emailBounced: PropTypes.bool,
    })
  ),
  studentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  className: PropTypes.string,
}

export default GuardianManager
