import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { get } from 'lodash'

import SelectInput from '../../leevo_ui/SelectInput/SelectInput'
import Input from '../../leevo_ui/Input/Input'
import { presence } from '../../../utilities/validators'

function RentalSkatesFields({ className }) {
  const typeOptions = [
    { label: 'Youth Figure', value: 'youth_figure' },
    { label: "Men's Figure", value: 'men_figure' },
    { label: "Women's Figure", value: 'women_figure' },
    { label: "Men's Hockey", value: 'men_hockey' },
    { label: "Women's Hockey", value: 'women_hockey' },
    { label: 'Youth Hockey', value: 'youth_hockey' },
  ]

  return (
    <div className={className}>
      <div className="space-y-6">
        <Field
          name="skateRental.skateType"
          validate={(value, allValues) => {
            if (get(allValues, 'skateRental.active')) return presence(value)
          }}
        >
          {({ input, meta: { active, error, submitFailed } }) => (
            <SelectInput
              {...input}
              id="skateRental.type"
              label="What type of skate?"
              options={typeOptions}
              placeholder="Select a type"
              errorMessage={!active && error && submitFailed ? error : ''}
            />
          )}
        </Field>
        <Field
          name="skateRental.size"
          validate={(value, allValues) => {
            if (get(allValues, 'skateRental.active')) return presence(value)
          }}
        >
          {({ input, meta: { active, error, submitFailed } }) => (
            <Input
              {...input}
              type="number"
              step="0.5"
              max="20"
              min="1"
              id="skateRental.size"
              label="What size skate?"
              helpText="You can specify half sizes. e.g. 10.5"
              errorMessage={!active && error && submitFailed ? error : ''}
            />
          )}
        </Field>
      </div>
    </div>
  )
}

RentalSkatesFields.defaultProps = {
  className: '',
}

RentalSkatesFields.propTypes = {
  className: PropTypes.string,
}

export default RentalSkatesFields
