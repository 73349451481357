import React from 'react'
import { mount } from 'enzyme'
import { Form } from 'react-final-form'

import PasswordField from './PasswordField'

describe('PasswordField', () => {
  let wrapper

  test('renders className on the topmost div', () => {
    wrapper = mount(
      <Form
        onSubmit={jest.fn()}
        render={() => <PasswordField className="foo" />}
      />
    )
    expect(wrapper.find('div').first()).toHaveClassName('foo')
  })

  describe('autoComplete', () => {
    test('renders new-password by default', () => {
      wrapper = mount(
        <Form onSubmit={jest.fn()} render={() => <PasswordField />} />
      )
      expect(wrapper.find('input').prop('autoComplete')).toBe('new-password')
    })

    test('renders current-password if specified', () => {
      wrapper = mount(
        <Form
          onSubmit={jest.fn()}
          render={() => <PasswordField autoComplete="current-password" />}
        />
      )
      expect(wrapper.find('input').prop('autoComplete')).toBe(
        'current-password'
      )
    })

    test('must be new-password or current-password', () => {
      function renderWrapper() {
        mount(
          <Form
            onSubmit={jest.fn()}
            render={() => <PasswordField autoComplete="foo" />}
          />
        )
      }

      expect(renderWrapper).toThrowError(
        'expected one of ["new-password","current-password"]'
      )
    })
  })
})
