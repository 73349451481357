import React, { useState, useEffect } from 'react'
import { get } from 'lodash'

import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import IndexHeader from '../leevo_ui/IndexHeader/IndexHeader'
import useFacility from '../../utilities/hooks/useFacility'
import CardFormSection from '../leevo_ui/CardFormSection/CardFormSection'
import Badge from '../leevo_ui/Badge/Badge'
import Button from '../leevo_ui/Button/Button'
import MultiStudentDiscount from './MultiStudentDiscount/MultiStudentDiscount'
import MultiKlassDiscount from './MultiKlassDiscount/MultiKlassDiscount'
import CouponCodeModal from './CouponCodeModal/CouponCodeModal'
import LoadingSpinner from '../leevo_ui/LoadingSpinner/LoadingSpinner'
import { requestCreator } from '../../utilities/requests'

const { get: getDiscounts, cancel: cancelDiscountsRequests } = requestCreator()

const MODAL_STATES = { closed: '', new: 'new', edit: 'edit' }

function FacilitiesDiscountsIndex() {
  const { facility, isLoading: isFacilityLoading } = useFacility()
  const [couponCodes, setCouponCodes] = useState([])
  const [multiStudentDiscount, setMultiStudentDiscount] = useState(null)
  const [multiKlassDiscount, setMultiKlassDiscount] = useState(null)
  const [isLoadingDiscounts, setIsLoadingDiscounts] = useState(true)
  const [couponCodeModal, setCouponCodeModal] = useState(MODAL_STATES.closed)
  const [couponCodeForEdit, setCouponCodeForEdit] = useState(null)

  useEffect(() => {
    if (!isFacilityLoading) {
      getDiscounts(`/api/facilities/${facility.id}/discounts`).then(
        (retrievedDiscounts) => {
          setCouponCodes(
            retrievedDiscounts.filter(
              (discount) => discount.discountableType === 'CouponCode'
            )
          )
          setMultiStudentDiscount(
            retrievedDiscounts.find(
              (discount) => discount.description === 'Multi-student discount'
            )
          )
          setMultiKlassDiscount(
            retrievedDiscounts.find(
              (discount) => discount.description === 'Multi-class discount'
            )
          )
          setIsLoadingDiscounts(false)
        }
      )
    }

    return cancelDiscountsRequests
  }, [isFacilityLoading, facility.id])

  function renderCouponCodes() {
    if (couponCodes.length === 0) {
      return (
        <p className="font-light text-center">
          No codes have been made...yet. 💌
        </p>
      )
    } else {
      return couponCodes.map((couponCode) => {
        const {
          id,
          reductionMethod,
          amount,
          description,
          code,
          active,
          maxRedemptions,
          numRedemptions,
        } = couponCode

        return (
          <li
            key={id}
            className="py-4 leading-snug border-t border-gray-200 first:border-t-0 first:pt-0 last:pb-0"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <button
                  className="text-lg font-medium text-purple-500 cursor-pointer hover:text-purple-400"
                  onClick={() => {
                    setCouponCodeModal(MODAL_STATES.edit)
                    setCouponCodeForEdit(couponCode)
                  }}
                >
                  {code}
                </button>
                <Badge
                  text={active ? 'Active' : 'Disabled'}
                  color={active ? 'green' : 'gray'}
                />
                <Badge
                  text={
                    maxRedemptions
                      ? `${numRedemptions} of ${maxRedemptions} redemptions`
                      : `${numRedemptions} redemptions`
                  }
                  color={
                    !maxRedemptions || maxRedemptions !== numRedemptions
                      ? 'green'
                      : 'gray'
                  }
                />
              </div>
              <p className="text-gray-700">
                {reductionMethod === 'fixed' && '$'}
                {reductionMethod === 'percentage' ? amount : amount / 100}
                {reductionMethod === 'percentage' && '%'} off
              </p>
            </div>
            <p className="w-3/4 text-gray-700">{description}</p>
          </li>
        )
      })
    }
  }

  return (
    <ApplicationLayout>
      <IndexHeader title="Discounts" />
      <div className="flex justify-center h-full p-5 bg-gray-100">
        <div className="max-w-2xl">
          <CardFormSection
            title="Automatic Discounts"
            description="Discounts applied automatically at checkout. Only the greater of the two is applied."
            showSubmit={false}
          >
            {isLoadingDiscounts ? (
              <LoadingSpinner className="flex items-center justify-center m-10" />
            ) : (
              <>
                <MultiStudentDiscount
                  amount={get(multiStudentDiscount, 'amount')}
                  active={get(multiStudentDiscount, 'active')}
                  reductionMethod={get(multiStudentDiscount, 'reductionMethod')}
                />

                <MultiKlassDiscount
                  amount={get(multiKlassDiscount, 'amount')}
                  active={get(multiKlassDiscount, 'active')}
                  reductionMethod={get(multiKlassDiscount, 'reductionMethod')}
                />
              </>
            )}
          </CardFormSection>
          <CardFormSection
            title="Coupon Codes"
            description="Create codes that can be applied at checkout."
            showSubmit={false}
          >
            <div>
              <div className="flex justify-center pb-5">
                <Button
                  label="Create a New Code"
                  className="flex-shrink-0 px-3 rounded-full"
                  small
                  outline
                  onClick={() => setCouponCodeModal(MODAL_STATES.new)}
                  data-cy="add-coupon-code-button"
                />
                <CouponCodeModal
                  discount={couponCodeForEdit}
                  open={couponCodeModal !== MODAL_STATES.closed}
                  header={`${MODAL_STATES.new ? 'New' : 'Edit'} Coupon Code`}
                  onClose={(discount) => {
                    setCouponCodeModal(MODAL_STATES.closed)
                    setCouponCodeForEdit(null)
                    if (discount) {
                      setCouponCodes((previousCodes) => {
                        const existingIndex = previousCodes
                          .map(({ id }) => id)
                          .indexOf(discount.id)
                        if (existingIndex !== -1) {
                          const newCodes = [...previousCodes]
                          newCodes[existingIndex] = discount
                          return newCodes
                        } else {
                          return [discount, ...previousCodes]
                        }
                      })
                    }
                  }}
                />
              </div>
              <ul>
                {isLoadingDiscounts ? (
                  <LoadingSpinner className="flex items-center justify-center m-10" />
                ) : (
                  renderCouponCodes()
                )}
              </ul>
            </div>
          </CardFormSection>
        </div>
      </div>
    </ApplicationLayout>
  )
}

FacilitiesDiscountsIndex.defaultProps = {}

FacilitiesDiscountsIndex.propTypes = {}

export default FacilitiesDiscountsIndex
