import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Avatar from '../../leevo_ui/Avatar/Avatar'
import Badge from '../../leevo_ui/Badge/Badge'

function TeamMemberListItem({
  id,
  fullName,
  cloudinaryPhotoPublicId,
  email,
  pending,
  permissionLevel,
}) {
  function badgeColor() {
    if (!permissionLevel) return 'gray'
    if (permissionLevel === 'director') return 'green'
    if (permissionLevel === 'coach') return 'orange'
  }

  function badgeText() {
    if (!permissionLevel) return 'Pending'
    if (permissionLevel === 'director') return 'Director'
    if (permissionLevel === 'coach') return 'Coach'
  }

  return (
    <li
      data-cy={`${pending ? 'pending' : 'active'}-team-member-list-item`}
      className={`p-4 border-b border-gray-300 ${classNames({
        'text-gray-800': !pending,
        'text-gray-500': pending,
      })}`}
    >
      <div className="flex items-center justify-start">
        <Avatar
          fullName={fullName}
          size={12}
          cloudinaryPhotoPublicId={cloudinaryPhotoPublicId}
          userId={id}
        />
        <div className="w-full ml-3">
          <div className="flex items-center justify-between">
            <p className="font-semibold">{pending ? '' : fullName}</p>
            <Badge
              className="mb-1 ml-2"
              color={badgeColor()}
              text={badgeText()}
            />
          </div>
          <p className="text-sm text-gray-600">
            {email} {pending && '(pending)'}
          </p>
        </div>
      </div>
    </li>
  )
}

TeamMemberListItem.defaultProps = {
  fullName: '',
  cloudinaryPhotoPublicId: '',
  email: '',
  pending: false,
}

TeamMemberListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fullName: PropTypes.string,
  cloudinaryPhotoPublicId: PropTypes.string,
  email: PropTypes.string,
  pending: PropTypes.bool,
  permissionLevel: PropTypes.string,
}

export default TeamMemberListItem
