import React from 'react'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

function LoadingPage() {
  /* Content from: https://tailwindcomponents.com/component/full-page-overlay-loading-screen */
  return (
    <div
      className="fixed inset-0 z-40 block w-full h-full bg-white opacity-75"
      data-cy="loading-page"
    >
      <LoadingSpinner
        size="1/5"
        className="flex items-center justify-center w-full h-full"
      />
    </div>
  )
}

export default LoadingPage
