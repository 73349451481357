import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'
import classNames from 'classnames'
import { Image, Transformation, CloudinaryContext } from 'cloudinary-react'

import defaultPhotoSVG from '../../../../images/user-default.svg'

export default function CirclePhoto({
  alt,
  className,
  size,
  cloudinaryPhotoPublicId,
  onClick,
}) {
  const [showDefaultImage, setShowDefaultImage] = useState(
    !cloudinaryPhotoPublicId
  )
  let sizeClasses, sizeInPixels
  switch (size) {
    case 24:
      sizeClasses = 'h-24 w-24'
      sizeInPixels = 96
      break
    case 12:
      sizeClasses = 'h-12 w-12'
      sizeInPixels = 48
      break
    case 10:
      sizeClasses = 'h-10 w-10'
      sizeInPixels = 40
      break
    case 5:
      sizeClasses = 'h-5 w-5'
      sizeInPixels = 20
      break
    case 8:
      sizeClasses = 'h-8 w-8'
      sizeInPixels = 32
      break
    default:
      sizeClasses = 'h-16 w-16'
      sizeInPixels = 64
  }

  useEffect(() => {
    setShowDefaultImage(!cloudinaryPhotoPublicId)
  }, [cloudinaryPhotoPublicId])

  function image() {
    const classes = ['fill-current text-purple-300 bg-purple-100 object-cover']
    const overlayClasses = classNames(
      'absolute',
      'inset-0',
      'w-full',
      'h-full',
      'shadow-inner',
      {
        'cursor-pointer hover:bg-black group-focus:bg-black opacity-5': onClick,
      }
    )
    classes.push(sizeClasses)

    if (!showDefaultImage) {
      return (
        <CloudinaryContext
          cloudName={process.env.CLOUDINARY_CLOUD_NAME}
          className="group focus:outline-none"
          onClick={onClick}
          tabIndex={onClick ? '0' : null}
        >
          <Image
            onError={() => setShowDefaultImage(true)}
            className={classNames(classes)}
            publicId={cloudinaryPhotoPublicId}
            alt={alt}
            secure="true"
            dpr="2.0"
          >
            <Transformation
              width={sizeInPixels}
              height={sizeInPixels}
              gravity="face"
              crop="thumb"
            />
          </Image>
          <div className={overlayClasses}></div>
        </CloudinaryContext>
      )
    } else {
      return (
        <div
          className="group"
          onClick={onClick}
          tabIndex={onClick ? '0' : null}
        >
          <SVG
            className={`shadow-inner ${classNames(classes)}`}
            src={defaultPhotoSVG}
          />
          <div className={overlayClasses}></div>
        </div>
      )
    }
  }

  return (
    <div
      className={`${className} ${classNames(
        sizeClasses
      )} flex-shrink-0 rounded-full border overflow-hidden relative`}
    >
      {image()}
    </div>
  )
}

CirclePhoto.sizes = [5, 8, 10, 12, 16, 24]

CirclePhoto.defaultProps = {
  alt: 'Photo',
  size: 24,
}

CirclePhoto.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(CirclePhoto.sizes),
  cloudinaryPhotoPublicId: PropTypes.string,
  onClick: PropTypes.func,
}
