import React from 'react'
import ExpandDownTransition from './ExpandDownTransition'
import { mount } from 'enzyme'

describe('ExpandDownTransition', () => {
  let wrapper, child

  beforeEach(() => {
    child = <div>I am a child.</div>
    wrapper = mount(<ExpandDownTransition>{child}</ExpandDownTransition>)
  })

  describe('when show is true', () => {
    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('renders children', () => {
      expect(wrapper).toHaveText('I am a child.')
    })
  })
})
