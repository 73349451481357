import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const VALID_FIRST = /^[1-9]{1}$/
const VALID_NEXT = /^[0-9]{1}$/
const DELETE_KEY_CODE = 8

function CurrencyInput({
  className,
  label,
  helpText,
  errorMessage,
  id,
  onChange,
  value,
  max,
  required,
  disabled,
  ...rest
}) {
  const handleKeyDown = useCallback(
    (e) => {
      const { key, keyCode } = e
      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return
      }
      const valueString = value.toString()
      let nextValue
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString = value === 0 ? key : `${valueString}${key}`
        nextValue = Number.parseInt(nextValueString, 10)
      } else {
        const nextValueString = valueString.slice(0, -1)
        nextValue =
          nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10)
      }
      if (nextValue > max) {
        return
      }
      onChange(nextValue)
    },
    [max, onChange, value]
  )

  const handleChange = useCallback(() => {
    // DUMMY TO AVOID REACT WARNING
  }, [])

  const valueDisplay = value
    ? (value / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    : null

  return (
    <div className={className}>
      {label && (
        <label
          className={`${classNames({
            'border-red-500': errorMessage,
            'text-red-500': errorMessage,
          })} block text-gray-800 text-sm font-medium ml-1`}
          htmlFor={id}
        >
          {label}
          {required && '*'}
        </label>
      )}
      {helpText && <p className="text-gray-500 ml-1 text-xs">{helpText}</p>}
      <input
        {...rest}
        className={`${classNames({
          'text-red-500 border-red-500 placeholder-red-300 ring ring-red-500 ring-opacity-50 error-shake': errorMessage,
          'text-gray-800 placeholder-gray-500': !errorMessage,
          'shadow-inner': !disabled,
          'bg-gray-100': disabled,
        })} text-sm border rounded w-full py-2 px-3 leading-tight focus:ring focus:border-purple-500 focus:ring-purple-300 focus:ring-opacity-50 border-gray-300 mt-2`}
        inputMode="numeric"
        id={id}
        disabled={disabled}
        required={required}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        data-cy={`${id}-currency-input`}
        value={valueDisplay}
        style={{ caretColor: 'transparent' }}
      />
      <p className="mt-1 mb-3 text-xs italic text-red-500">{errorMessage}</p>
    </div>
  )
}

CurrencyInput.defaultProps = {
  max: Number.MAX_SAFE_INTEGER,
}

CurrencyInput.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
  label: PropTypes.string,
  helpText: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  max: PropTypes.number,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default CurrencyInput
