import React from 'react'
import { shallow, mount } from 'enzyme'
import { Form } from 'react-final-form'
import { ReactMultiEmail } from 'react-multi-email'

import MultiEmailField from './MultiEmailField'

describe('MultiEmailField', () => {
  let wrapper

  test('renders', () => {
    wrapper = shallow(<MultiEmailField />)
    expect(wrapper).toExist()
  })

  test('renders in a form', () => {
    wrapper = mount(
      <Form onSubmit={jest.fn()} render={() => <MultiEmailField />} />
    )
    expect(wrapper.find(MultiEmailField)).toExist()
  })

  test('renders with the label', () => {
    wrapper = mount(
      <Form
        onSubmit={jest.fn()}
        render={() => <MultiEmailField label="foo" />}
      />
    )
    expect(wrapper).toIncludeText('foo')
  })

  test('renders with the class name applied to topmost div', () => {
    wrapper = mount(
      <Form
        onSubmit={jest.fn()}
        render={() => <MultiEmailField className="foo" />}
      />
    )

    expect(wrapper.find('div').at(0)).toHaveClassName('foo')
  })

  it('renders a ReactMultiEmail (just test it is there, we do not need to test its behavior)', () => {
    wrapper = mount(
      <Form
        onSubmit={jest.fn()}
        render={() => <MultiEmailField className="foo" />}
      />
    )

    expect(wrapper.find(ReactMultiEmail)).toExist()
  })
})
