import React, { useState, useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { get as _get } from 'lodash'

import Button from '../leevo_ui/Button/Button'
import DropToUploadInput from '../leevo_ui/DropToUploadInput/DropToUploadInput'
import { requestCreator } from '../../utilities/requests'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import Notification from '../leevo_ui/Notification/Notification'
import useFacility from '../../utilities/hooks/useFacility'

const {
  get: newImport,
  post: createImport,
  cancel: cancelImportRequests,
} = requestCreator()

function StudentImportsNew() {
  const { facility, isLoading: isFacilityLoading } = useFacility()
  const [isLoading, setIsLoading] = useState(false)
  const [isImportPending, setIsImportPending] = useState(false)
  const { replace: redirect } = useHistory()
  const [notification, setNotification] = useState({
    success: null,
    message: null,
  })

  async function onSubmit(values) {
    const formData = new FormData()

    Object.entries(values).forEach(([key, value]) =>
      formData.append(key, value)
    )

    setIsImportPending(true)
    return createImport(
      `/api/facilities/${facility.id}/student_imports`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'multipart/form-data',
        },
      }
    )
      .then(() => {
        setNotification({
          success: true,
          message: 'Import processed successfully',
        })
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          let message = _get(error, 'response.data.error')
          if (!message) {
            message = _get(error, 'response.data.errors')
              ? renderedErrors(_get(error, 'response.data.errors'))
              : 'Import failed'
          }
          setNotification({ success: false, message })
        }
      })
      .finally(() => setIsImportPending(false))
  }

  function renderedErrors(errors) {
    return Object.keys(errors).map((key) => (
      <p key={key}>
        Row {key}: {errors[key]}
      </p>
    ))
  }

  useEffect(() => {
    if (!isFacilityLoading) {
      setIsLoading(true)
      newImport(`/api/facilities/${facility.id}/student_imports/new`)
        .then(() => setIsLoading(false))
        .catch((error) => {
          if (!axios.isCancel(error))
            redirect({
              pathname: '/',
              state: {
                notification: {
                  message: "You're not allowed to import students.",
                  color: 'red',
                },
              },
            })
        })
    }
    return cancelImportRequests
  }, [redirect, facility.id, isFacilityLoading])

  return (
    <ApplicationLayout isContentLoading={isFacilityLoading || isLoading}>
      <div className="flex justify-center">
        <div className="w-full max-w-2xl">
          <Form
            initialValues={{ file: null }}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field name="file" allowNull>
                  {({ input }) => (
                    <DropToUploadInput {...input} id="upload" className="m-4" />
                  )}
                </Field>
                <div className="flex justify-end m-4">
                  <Button
                    label="Submit"
                    type="submit"
                    loading={isImportPending}
                    disabled={isImportPending}
                  />
                </div>
              </form>
            )}
          />
          <Notification
            show={notification.message}
            color={notification.success ? 'green' : 'red'}
          >
            {notification.message}
          </Notification>
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default StudentImportsNew
