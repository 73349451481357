import React from 'react'
import { Form } from 'react-final-form'
import { action } from '@storybook/addon-actions'

import PresentedInfoSection from './PresentedInfoSection'

export default {
  title: 'Leevo UI/DisclosureForm/PresentedInfoSection',
  component: PresentedInfoSection,
  args: {
    className: 'm-4',
  },
}

export function presentedInfoSection(args) {
  return (
    <Form
      onSubmit={action('Submitted')}
      initialValues={{ type: 'one_liner' }}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <PresentedInfoSection {...args} values={values} />
        </form>
      )}
    />
  )
}
