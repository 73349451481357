import React from 'react'
import StudentImportsNew from './StudentImportsNew'

export default {
  title: 'Leevo UI/StudentImportsNew',
  component: StudentImportsNew,
  args: {
    className: 'm-4',
  },
}

export function studentImportsNew(args) {
  return <StudentImportsNew {...args} />
}
