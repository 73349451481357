import React from 'react'
import { mount } from 'enzyme'

import ConfirmationModal from './ConfirmationModal'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

describe('ConfirmationModal', () => {
  let wrapper, mockOnClose, mockOnNoClick, mockOnYesClick, mockChild
  beforeEach(() => {
    mockOnClose = jest.fn()
    mockOnNoClick = jest.fn()
    mockOnYesClick = jest.fn()
    mockChild = <div>I am a child.</div>
    wrapper = mount(
      <ConfirmationModal
        open
        pending
        header="fake-header"
        onClose={mockOnClose}
        onNoClick={mockOnNoClick}
        onYesClick={mockOnYesClick}
      >
        {mockChild}
      </ConfirmationModal>
    )
  })

  it('does not render when not opened', () => {
    wrapper = mount(
      <ConfirmationModal
        open={false}
        pending
        header="fake-header"
        onClose={mockOnClose}
        onNoClick={mockOnNoClick}
        onYesClick={mockOnYesClick}
      >
        {mockChild}
      </ConfirmationModal>
    )
    expect(wrapper.find('div')).not.toExist()
  })

  it('renders when opened', () => {
    wrapper.setProps({ open: true })
    expect(wrapper.find('div')).toExist()
  })

  it('renders the header', () => {
    expect(wrapper).toIncludeText('fake-header')
  })

  it('calls onClose when modal close button clicked', () => {
    wrapper.find('[data-cy="modal-close-button"]').simulate('click')
    expect(mockOnClose).toHaveBeenCalled()
  })

  it('calls onClose when modal background clicked', () => {
    // Using hostNodes() as described here: https://github.com/enzymejs/enzyme/issues/836#issuecomment-374899433
    wrapper.find('[data-cy="modal-background"]').hostNodes().simulate('click')
    expect(mockOnClose).toHaveBeenCalled()
  })

  it('calls onNoClick when yes button clicked', () => {
    wrapper.find('[data-cy="modal-background"]').hostNodes().simulate('click')
    expect(mockOnClose).toHaveBeenCalled()
  })

  it('calls onYesClick when yes button clicked', () => {
    wrapper.find('[data-cy="yes-button"]').hostNodes().simulate('click')
    expect(mockOnYesClick).toHaveBeenCalled()
  })

  it('calls onNoClick when no button clicked', () => {
    wrapper.find('[data-cy="no-button"]').hostNodes().simulate('click')
    expect(mockOnNoClick).toHaveBeenCalled()
  })

  it('renders a loading spinner when in pending state', () => {
    expect(wrapper.find(LoadingSpinner)).toExist()
  })

  it('renders children', () => {
    expect(wrapper).toIncludeText('I am a child.')
  })
})
