import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const TextArea = React.forwardRef(
  (
    {
      className,
      label,
      helpText,
      errorMessage,
      id,
      required,
      disabled,
      maxLength,
      value,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={className}>
        {label && (
          <label
            className={`${classNames({
              'border-red-500': errorMessage,
              'text-red-500': errorMessage,
            })} block text-gray-800 text-sm font-medium ml-1`}
            htmlFor={id}
          >
            {label}
            {required && '*'}
          </label>
        )}
        {helpText && <p className="text-gray-500 ml-1 text-xs">{helpText}</p>}
        <textarea
          {...rest}
          ref={ref}
          className={`${classNames({
            'text-red-500 border-red-500 placeholder-red-300 ring ring-red-500 ring-opacity-50 error-shake': errorMessage,
            'text-gray-800 placeholder-gray-500': !errorMessage,
            'shadow-inner': !disabled,
            'bg-gray-100': disabled,
          })} text-sm border rounded w-full py-2 px-3 focus:ring focus:border-purple-500 focus:ring-purple-300 focus:ring-opacity-50  border-gray-300 mt-2`}
          id={id}
          disabled={disabled}
          required={required}
          maxLength={maxLength}
          value={value}
          data-cy={`${id}-textarea`}
        />

        {maxLength && (
          <span
            className={`${classNames({
              'text-gray-600': maxLength - value.length > 10,
              'text-red-500': maxLength - value.length <= 10,
            })} inline-block w-full text-right text-xs`}
          >
            {maxLength - value.length} characters remaining
          </span>
        )}
        <p
          data-cy="error-message"
          className="mt-1 mb-3 text-xs italic text-red-500"
        >
          {errorMessage}
        </p>
      </div>
    )
  }
)

TextArea.displayName = 'TextArea'

TextArea.defaultProps = {
  value: '',
}

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  value: PropTypes.string,
}

export default TextArea
