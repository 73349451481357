import React, { useState, useEffect } from 'react'
import { get as _get } from 'lodash'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import FacilityEditForm from '../leevo_ui/FacilityEditForm/FacilityEditForm'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import Notification from '../leevo_ui/Notification/Notification'
import { requestCreator } from '../../utilities/requests'

const {
  get: newFacility,
  post: createFacility,
  cancel: cancelFacilityRequests,
} = requestCreator()

function FacilitiesNewFeature() {
  const { replace: redirect } = useHistory()
  const [notification, setNotification] = useState({
    success: null,
    message: null,
  })
  const [curriculums, setCurriculums] = useState([])

  useEffect(() => {
    newFacility('/api/facilities/new', { params: { include: 'curriculums' } })
      .then((facility) => setCurriculums(facility.curriculums))
      .catch((error) => {
        if (!axios.isCancel(error)) redirect('/admin/facilities')
      })
    return cancelFacilityRequests
  }, [redirect])

  function onSubmit(values) {
    return createFacility('/api/facilities', { facility: values })
      .then(() => {
        redirect({
          pathname: '/admin/facilities',
          state: { message: 'Facility Created!' },
        })
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setNotification({
            success: false,
            message:
              _get(error, 'response.data.error') ||
              'We were unable to save your changes.',
          })
        }
      })
  }

  return (
    <ApplicationLayout>
      <Notification
        show={notification.message}
        color={notification.success ? 'green' : 'red'}
      >
        {notification.message}
      </Notification>
      <FacilityEditForm
        newRecord
        curriculums={curriculums}
        onSubmit={onSubmit}
      />
    </ApplicationLayout>
  )
}

export default FacilitiesNewFeature
