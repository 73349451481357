import React from 'react'
import PropTypes from 'prop-types'

function ProgressBar({ percentage, size, className }) {
  let borderClass
  switch (size) {
    case 16:
      borderClass = 'border-t-8 border-b-8'
      break
    case 8:
      borderClass = 'border-t-8'
      break
    case 4:
      borderClass = 'border-t-4'
      break
    case 2:
      borderClass = 'border-t-2'
      break
    default:
      borderClass = 'border-t'
  }

  return (
    <div className={className}>
      <div className={`${borderClass} border-gray-400`} />
      <div
        className={`${borderClass} relative border-purple-500 transition-width ease-in duration-300`}
        style={{ top: `-${size}px`, width: `${percentage}%` }}
      />
    </div>
  )
}

ProgressBar.defaultProps = {
  size: 2,
  percentage: 0,
}

ProgressBar.sizes = [1, 2, 4, 8, 16]

ProgressBar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(ProgressBar.sizes),
  percentage: PropTypes.number,
}

export default ProgressBar
