import React from 'react'
import { shallow, mount } from 'enzyme'
import { Form } from 'react-final-form'

import PhoneField from './PhoneField'

describe('PhoneField', () => {
  let wrapper

  test('renders', () => {
    wrapper = shallow(<PhoneField />)
    expect(wrapper).toExist()
  })

  test('renders className on topmost div', () => {
    wrapper = mount(
      <Form
        onSubmit={jest.fn()}
        render={() => <PhoneField className="foo" />}
      />
    )
    expect(wrapper.find('div')).toHaveClassName('foo')
  })
})
