import React from 'react'
import FacilitiesDiscountsIndex from './FacilitiesDiscountsIndex'
import MockFacilityProvider from '../../providers/MockFacilityProvider'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
// import faker from 'faker'
// const mockDiscounts = [...Array(10)].map((_, index) => ({
//   id: index,
//   type: Math.random() > 0.5 ? 'percentage' : 'cash',
//   amount: Math.ceil(Math.random() * 10000) / 100,
//   description: faker.lorem.sentences(Math.random() > 0.5 ? 1 : 2),
//   code: Math.random().toString(16).substr(2, 7).toUpperCase(),
//   active: Math.random() > 0.5 ? true : false,
// }))

export default {
  title: 'FacilitiesDiscountsIndex',
  component: FacilitiesDiscountsIndex,
  args: {
    className: 'm-4',
  },
}

export function facilitiesDiscountsIndex(args) {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onGet().reply(200, [])

  return (
    <MockFacilityProvider facility={{ name: "Snoopy's Home Ice" }}>
      <FacilitiesDiscountsIndex {...args} />
    </MockFacilityProvider>
  )
}
