import React from 'react'

import TeamMemberListItem from './TeamMemberListItem'

export default {
  title: 'Facilities Coaches Index/TeamMemberListItem',
  component: TeamMemberListItem,
  args: {
    id: 1,
    fullName: 'Jane Doe',
    cloudinaryPhotoPublicId: null,
    email: 'jane@example.com',
    pending: false,
  },
}

export function teamMemberListItem(args) {
  return <TeamMemberListItem {...args} />
}
