import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { MemoryRouter, Route } from 'react-router-dom'

import MockFacilityProvider from '../../providers/MockFacilityProvider'
import FacilitiesDisclosuresNew from './FacilitiesDisclosuresNew'

export default {
  title: 'FacilitiesDisclosuresNew',
  component: FacilitiesDisclosuresNew,
  args: {
    className: 'm-4',
  },
}

export function facilitiesDisclosuresNew(args) {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onGet(/disclosures/).reply(200)

  return (
    <MemoryRouter initialEntries={['/facilities/1/disclosures/1/edit']}>
      <MockFacilityProvider>
        <Route path="/facilities/1/disclosures/:disclosure_id/edit">
          <FacilitiesDisclosuresNew {...args} />
        </Route>
      </MockFacilityProvider>
    </MemoryRouter>
  )
}
