import React from 'react'
import { text, number } from '@storybook/addon-knobs'

import StudentListItem from './StudentListItem'

export default {
  title: 'Facilities Students Index/StudentListItem',
  component: StudentListItem,
}

export function studentListItem() {
  const id = number('ID', 1)
  const fullName = text('Full Name', 'Jane Doe')
  const cloudinaryPhotoPublicId = text('Photo URL', null)

  return (
    <StudentListItem
      id={id}
      fullName={fullName}
      cloudinaryPhotoPublicId={cloudinaryPhotoPublicId}
    />
  )
}
