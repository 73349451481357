import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import Input from '../../leevo_ui/Input/Input'
import { presence } from '../../../utilities/validators'

function FullNameField({ label, className }) {
  return (
    <Field name="fullName" validate={presence}>
      {({ input, meta: { active, submitFailed, error } }) => (
        <Input
          {...input}
          className={className}
          id="fullName"
          type="text"
          label={label}
          placeholder="Robert Button"
          errorMessage={!active && error && submitFailed ? error : ''}
        />
      )}
    </Field>
  )
}

FullNameField.defaultProps = {
  label: "What's your full name?",
}

FullNameField.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
}

export default FullNameField
