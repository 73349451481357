import React, { useState, useEffect } from 'react'
import { debounce, capitalize } from 'lodash'
import { Form, Field } from 'react-final-form'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import CopyField from './CopyField/CopyField'
import LoadingSpinner from '../leevo_ui/LoadingSpinner/LoadingSpinner'
import EmptyList from '../leevo_ui/EmptyList/EmptyList'
import Button from '../leevo_ui/Button/Button'
import Notification from '../leevo_ui/Notification/Notification'
import Input from '../leevo_ui/Input/Input'
import { isEmail } from '../../utilities/validators'
import { requestCreator } from '../../utilities/requests'

const { get: getGuardian, cancel: cancelGuardianRequests } = requestCreator()

const { put: updateStudent, cancel: cancelStudentRequests } = requestCreator()

function AdminGuardiansIndex() {
  const [query, setQuery] = useState('')
  const [isLoadingEmail, setIsLoadingEmail] = useState(false)
  const [isLoadingContent, setIsLoadingContent] = useState(true)
  const [guardian, setGuardian] = useState(null)
  const { replace: redirect } = useHistory()
  const [notification, setNotification] = useState({
    show: false,
    message: '',
    color: 'green',
  })

  const handleEmailChange = debounce(
    (email) => {
      if (email && isEmail(email) !== 'Email must be valid.') {
        setIsLoadingEmail(true)
        getGuardian('/api/admin/guardians', {
          params: { guardian: { email } },
        })
          .then(setGuardian)
          .then(() => setIsLoadingEmail(false))
      }
    },
    300,
    { leading: true }
  )

  useEffect(() => {
    // Ping API to authenticate this route.
    getGuardian('/api/admin/guardians', {
      params: { guardian: { email: null } },
    })
      .then(() => setIsLoadingContent(false))
      .catch((error) => {
        if (axios.isCancel(error)) {
          return
        } else if (error.response.status == 401) {
          redirect('/login')
        } else {
          throw error
        }
      })
    return () => {
      cancelGuardianRequests()
      cancelStudentRequests()
    }
  }, [redirect])

  return (
    <ApplicationLayout isContentLoading={isLoadingContent}>
      <div className="flex flex-col items-center">
        <div className="w-full max-w-2xl mx-2 my-4 bg-white rounded-lg shadow">
          <Input
            id="search"
            type="search"
            className="m-3"
            autoComplete="off"
            placeholder="Search by email..."
            onChange={({ target: { value } }) => {
              setQuery(value)
              handleEmailChange(value)
            }}
            value={query}
          />
        </div>
        {isLoadingEmail && (
          <div className="flex flex-col justify-center m-24">
            <LoadingSpinner />
          </div>
        )}
        {!isLoadingEmail && query && !guardian && (
          <EmptyList message="There is no guardian with that email." />
        )}
        {!isLoadingEmail && guardian && (
          <div className="w-full max-w-2xl mx-2 my-1 bg-white rounded-lg shadow">
            <div className="flex justify-between p-4">
              <div>
                <p className="font-semibold">{guardian.fullName}</p>
                <p className="text-xs">{guardian.email}</p>
                <p className="text-xs">{guardian.phone}</p>
              </div>
              <Notification
                show={notification.show}
                color={notification.color}
                className="sticky top-0 z-50"
                onDisappearTimeout={() =>
                  setNotification({
                    show: false,
                    message: '',
                    color: 'red',
                  })
                }
              >
                {notification.message}
              </Notification>
            </div>
            <Form
              initialValues={guardian.students.reduce(
                (result, student) => ({
                  ...result,
                  [`${student.id}-ltsNumber`]: student.ltsNumber,
                }),
                {}
              )}
              onSubmit={(values) => {
                const promises = guardian.students.map((student) => {
                  const ltsNumber = values[`${student.id}-ltsNumber`] || null
                  const url = `/api/users/${student.id}`
                  return updateStudent(url, { user: { ltsNumber } })
                })

                Promise.all(promises)
                  .then(() => {
                    setNotification({
                      show: true,
                      color: 'green',
                      message: 'Saved!',
                    })
                  })
                  .catch(() => {
                    setNotification({
                      show: true,
                      color: 'red',
                      message: 'There was a problem.',
                    })
                  })
              }}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <ul>
                    {guardian.students &&
                      guardian.students.map((student) => (
                        <li
                          className="flex justify-between my-2 text-sm border-b border-gray-200 last:border-0 first:border-t first"
                          key={student.id}
                        >
                          <div className="p-4 ">
                            <p className="font-medium">{student.fullName}</p>
                            <p>{capitalize(student.gender)}</p>
                            {student.birthdate && (
                              <CopyField
                                text={moment(student.birthdate).format(
                                  'MM/DD/YY'
                                )}
                              />
                            )}
                            {student.address && (
                              <>
                                <CopyField
                                  className="mt-1"
                                  text={student.address.line1}
                                />
                                {student.address.line2 && (
                                  <CopyField text={student.address.line2} />
                                )}
                                <p>
                                  <CopyField
                                    className="inline-block"
                                    text={student.address.city}
                                  />
                                  {','} {student.address.state}{' '}
                                  <CopyField
                                    className="inline-block"
                                    text={student.address.postCode}
                                  />
                                </p>
                              </>
                            )}
                          </div>
                          <div className="flex items-center mx-5">
                            <p className="mr-2">LTS Number:</p>
                            <Field name={`${student.id}-ltsNumber`}>
                              {({ input }) => (
                                <Input
                                  {...input}
                                  id="ltsNumber"
                                  type="text"
                                  placeholder="Enter number"
                                />
                              )}
                            </Field>
                          </div>
                        </li>
                      ))}
                  </ul>
                  <footer className="flex justify-end mt-5">
                    <Button
                      type="submit"
                      label="Save"
                      className="mb-3 mr-5"
                      disabled={submitting}
                      loading={submitting}
                    />
                  </footer>
                </form>
              )}
            />
          </div>
        )}
      </div>
    </ApplicationLayout>
  )
}

export default AdminGuardiansIndex
