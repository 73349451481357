import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'

function Table({ headers, children }) {
  const ref = useRef(null)
  const [scrollCueClass, setScrollCueClass] = useState(
    Cookies.get('shown-scroll-cue') === 'true' ? '' : 'scroll-bounce-right'
  )

  useEffect(() => {
    const element = ref.current

    function disableScrollCue() {
      Cookies.set('shown-scroll-cue', 'true')
      setScrollCueClass('')
    }

    function handleScroll(e) {
      if (e.deltaX > 0) disableScrollCue()
    }

    function handleKeydown(e) {
      if (e.code === 'ArrowRight') disableScrollCue()
    }

    element.addEventListener('wheel', handleScroll)
    element.addEventListener('keydown', handleKeydown)

    return () => {
      element.removeEventListener('wheel', handleScroll)
      element.removeEventListener('keydown', handleKeydown)
    }
  })

  return (
    <div className="flex flex-col" ref={ref} tabIndex="0">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table
              className={`min-w-full divide-y divide-gray-200 ${scrollCueClass}`}
            >
              {headers.length > 0 && (
                <thead className="text-xs font-light leading-4 tracking-wider text-left text-gray-600 uppercase bg-gray-100">
                  <tr>
                    {headers.map((header) => (
                      <th key={header} className="px-5 py-3">
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
              )}
              <tbody className="bg-white divide-y divide-gray-200">
                {children}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

Table.defaultProps = {
  headers: [],
}

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
}

export default Table
