import React from 'react'
import { Helmet } from 'react-helmet'

import MarketingNavBar from '../leevo_ui/NavBar/MarketingNavBar/MarketingNavBar'
import MarketingPageFooter from '../leevo_ui/MarketingPageFooter/MarketingPageFooter'

function RefundPolicyFeature() {
  return (
    <div className="text-gray-900 bg-gray-100">
      <Helmet>
        <title>Refund Policy | Leevo</title>
      </Helmet>
      <header className="relative flex flex-col items-center h-auto">
        <MarketingNavBar
          links={[
            { label: 'Home', to: '/' },
            { label: 'About', to: '/about' },
            { label: 'Pricing', to: '/pricing' },
            { label: 'My Classes', to: '/login' },
            { label: 'Log In', to: '/login' },
          ]}
        />
      </header>
      <section className="relative flex flex-col items-center min-h-screen pb-10 text-gray-700 space-y-4 font-arial pt-10">
        <div className="container max-w-3xl">
          <header className="space-y-1">
            <h1 className="text-2xl text-gray-900 font-semibold uppercase">
              Refund Policy
            </h1>
            <p className="font-semibold text-gray-600 text-sm">
              Last updated <time dateTime="2021-08-21">August 22, 2021</time>
            </p>
          </header>
          <main className="space-y-2 text-gray-700 text-sm pt-10">
            <p>
              Thank you for your purchase. We hope you are happy with your
              purchase. However, if you are not completely satisfied with your
              purchase for any reason, you may be entitled to a refund. Please
              see below for more information on our return policy.
            </p>
            <h2 className="font-semibold text-lg uppercase pt-5 text-gray-900">
              Class Registrations
            </h2>
            <p>
              When you register at a facility and pay through Leevo, all refunds
              are subject to the refund policy of the facility or organization
              with which you&apos;ve registered.
            </p>
            <p>
              If you are entitled to a refund as per their policy, that
              organization must handle and process the refund.
            </p>
            <p>
              Leevo is not responsible for handling refunds for any of our
              facility&apos;s programs.
            </p>
            <p>
              You can contact Leevo support at support@goleevo.com to obtain the
              contact information of the program director to request a refund.
            </p>
            <h2 className="font-semibold text-lg uppercase pt-5 text-gray-900">
              Payment Processing Fees
            </h2>
            <p>
              We use Stripe to process payments into your account. Payment
              processing fees for all transactions are subject to{' '}
              <a
                href="https://stripe.com/docs/refunds"
                className="text-purple-600 hover:text-purple-500"
              >
                Stripe&apos;s refunding policies
              </a>
              . See Stripe&apos;s article on{' '}
              <a
                href="https://support.stripe.com/questions/understanding-fees-for-refunded-payments"
                className="text-purple-600 hover:text-purple-500"
              >
                understanding refunded payments
              </a>{' '}
              for more information.
            </p>
            <h2 className="font-semibold text-lg uppercase pt-5 text-gray-900">
              Learn to Skate USA (LTSUSA) Registration Fees
            </h2>
            <p>
              When registering with a skating facility that is using both Leevo
              and the LTSUSA curriculum, we first process your payment on Leevo,
              then register you and your students with LTSUSA within 1-3
              business days.{' '}
            </p>
            <p>
              If you request a refund from the facility before we process this
              fee with LTSUSA, we will issue a refund. Once the fees are
              processed with LTSUSA, the registration fees are non-refundable.
            </p>
            <p>
              To request a refund from the facility, you must contact a director
              or owner directly. You can contact Leevo support at
              support@goleevo.com to obtain their contact information.
            </p>
            <h2 className="font-semibold text-lg uppercase pt-5 text-gray-900">
              Facility Access Fees
            </h2>
            <p>
              If you are dissatisfied with Leevo, you can cancel at any time,
              and will no longer be subject to additional fees. Payments for
              usage in months past are non-refundable, and payments due for the
              current month&apos;s usage will be charged at the end of your
              billing cycle.
            </p>
            <h2 className="font-semibold text-lg uppercase pt-5 text-gray-900">
              Questions
            </h2>
            <p>
              If you have any questions about our return policy, please contact
              us at support@goleevo.com.
            </p>
          </main>
        </div>
      </section>
      <MarketingPageFooter />
    </div>
  )
}

export default RefundPolicyFeature
