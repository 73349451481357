import React from 'react'
import TermlyEmbed from './TermlyEmbed'

export default {
  title: 'Leevo UI/TermlyEmbed',
  component: TermlyEmbed,
  args: {
    title: 'Terms and Conditions',
    termlyDocumentId: '9536c4f6-3e5b-4fa8-b448-c2a5a804d89c',
  },
}

export function termlyEmbed(args) {
  return <TermlyEmbed {...args} />
}
