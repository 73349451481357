import React from 'react'
import SkillSelector from './SkillSelector'
import { shallow } from 'enzyme'

describe('SkillSelector', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(
        <SkillSelector
          skillCategories={['Foo', 'Bar', null]}
          onCategorySelected={jest.fn()}
        />
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
