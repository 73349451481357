import React from 'react'
import RouteChangeNotification from './RouteChangeNotification'

export default {
  title: 'RouteChangeNotification',
  component: RouteChangeNotification,
}

export function routeChangeNotification() {
  return <RouteChangeNotification />
}
