import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { Form } from 'react-final-form'
import { useHistory, useLocation } from 'react-router-dom'
import { get as _get } from 'lodash'
import queryString from 'query-string'
import mixpanel from '../../utilities/mixpanel'

import { requestCreator } from '../../utilities/requests'
import Modal from '../leevo_ui/Modal/Modal'
import Button from '../leevo_ui/Button/Button'
import PasswordFields from '../leevo_ui/PasswordFields/PasswordFields'
import LoadingPage from '../leevo_ui/LoadingPage/LoadingPage'
import Notification from '../leevo_ui/Notification/Notification'
import NameFields from '../leevo_ui/NameFields/NameFields'
import { isValidPasswordAndPasswordConfirmation } from '../../utilities/validators'
import useAuth from '../../utilities/hooks/useAuth'

const { put: acceptInvitation } = requestCreator()

export default function AcceptInvitationFeature() {
  const [notification, setNotification] = useState({ show: false, message: '' })
  const { isSignedIn, isLoading } = useAuth()
  const history = useHistory()
  const { search } = useLocation()
  const { invitation_token: invitationToken } = queryString.parse(search)

  if (!invitationToken) history.push('/')

  useEffect(() => {
    if (isSignedIn)
      history.push('/', {
        notification: {
          color: 'red',
          message:
            'You cannot accept this invitation because you are already logged in.',
        },
      })
  }, [isSignedIn]) /* eslint-disable-line */ // FIXME

  function newPasswordSubmission(values) {
    return acceptInvitation('/auth/invitation', {
      ...values,
      invitationToken,
    })
      .then(() => {
        mixpanel.track('Accepted invitation')
        history.push({
          pathname: '/login',
          state: {
            message:
              'You have successfully accepted your invite! Now you can log in.',
          },
        })
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setNotification({
            show: true,
            message:
              _get(error, 'response.data.errors').join(', ') ||
              'We were unable to process this invitation.',
          })
          mixpanel.track('Invitation Acceptance Failed')
          return Promise.reject()
        }
      })
  }

  return (
    <>
      <Helmet>
        <title>Accept Invitation | Leevo</title>
      </Helmet>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <Modal open={true} header="Accept your invitation">
          <Form
            validate={isValidPasswordAndPasswordConfirmation}
            onSubmit={newPasswordSubmission}
            render={({ handleSubmit, submitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <NameFields />
                  <PasswordFields />
                  <div className="flex items-center justify-end">
                    <Notification
                      show={notification.show}
                      color="red"
                      className="mx-1 my-2"
                      onDisappearTimeout={() =>
                        setNotification({ ...notification, show: false })
                      }
                    >
                      {notification.message}
                    </Notification>
                    <Button
                      data-cy="submit"
                      label="Submit"
                      type="submit"
                      disabled={submitting}
                      loading={submitting}
                    />
                  </div>
                </form>
              )
            }}
          />
        </Modal>
      )}
    </>
  )
}
