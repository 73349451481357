import React from 'react'

import EmptyList from './EmptyList'
import svgSrc from '../../../images/empty-calendar.svg'

export default {
  title: 'Leevo UI/EmptyList',
  component: EmptyList,
}

function Template(args) {
  return <EmptyList {...args} />
}

export const WithSvg = Template.bind({})
WithSvg.args = {
  message: "There's nothing here",
  svgSrc,
  buttonText: 'Add One +',
}

export const WithEmoji = Template.bind({})
WithEmoji.args = {
  message: "There's nothing here",
  svgSrc: null,
  emoji: '🦗',
  buttonText: 'Add One +',
}
