import React, { useEffect, useState, useMemo, useCallback } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'

import mixpanel from '../utilities/mixpanel'
import { requestCreator } from '../utilities/requests'

const { get: validateToken, cancel: cancelValidationRequest } = requestCreator()
const { post: signIn } = requestCreator()
const { post: register, cancel: cancelRegistrationRequest } = requestCreator()

export const AuthContext = React.createContext()

export const authHeaderKeys = [
  'access-token',
  'token-type',
  'client',
  'expiry',
  'uid',
]

export function setAuthHeaders(config) {
  authHeaderKeys.forEach((key) => {
    config.headers[key] = window.localStorage.getItem(key) || Cookies.get(key)
  })

  return config
}

export function storeAuthHeaders(response) {
  authHeaderKeys.forEach((key) => {
    const value =
      response.headers[key] ||
      window.localStorage.getItem(key) ||
      Cookies.get(key)
    if (value) {
      window.localStorage.setItem(key, value)
      Cookies.set(key, value)
    } else {
      window.localStorage.removeItem(key)
      Cookies.remove(key)
    }
  })
  return response
}

export default function AuthProvider(props) {
  const [authState, setAuthState] = useState({
    isLoading: true,
    isSignedIn: false,
    currentUser: { facilitiesUsers: [] },
  })
  const value = useMemo(() => authState, [authState])

  function signOutUser() {
    authHeaderKeys.forEach((key) => {
      window.localStorage.removeItem(key)
      Cookies.remove(key)
    })
    setAuthState({
      isLoading: false,
      isSignedIn: false,
      currentUser: { facilitiesUsers: [] },
    })
  }

  const verifyCredentials = useCallback(() => {
    const storage = window.localStorage
    const token = storage.getItem('access-token') || Cookies.get('access-token')
    const client = storage.getItem('client') || Cookies.get('client')
    const uid = storage.getItem('uid') || Cookies.get('uid')
    if (token && client && uid) {
      setAuthState((previousState) => ({ ...previousState, isLoading: true }))
      return validateToken('/auth/validate_token', {
        headers: { 'access-token': token, client, uid },
      })
        .then((currentUser) => {
          setAuthState((previousState) => ({
            ...previousState,
            currentUser,
            isLoading: false,
            isSignedIn: true,
          }))
          mixpanel.trackAuthEvent('Signed In', currentUser, {
            type: 'credentials verification',
          })
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            signOutUser()
            mixpanel.track('Credential Verification Failed')
            throw error
          }
        })
    } else {
      setAuthState((previousState) => ({
        ...previousState,
        isLoading: false,
      }))
      signOutUser()
      return Promise.resolve()
    }
  }, [])

  function signInUser({ email, password }) {
    setAuthState((previousState) => ({ ...previousState, isLoading: true }))
    return signIn('/auth/sign_in', {
      email,
      password,
    })
      .then((currentUser) => {
        setAuthState((previousState) => ({
          ...previousState,
          currentUser,
          isLoading: false,
          isSignedIn: true,
        }))
        mixpanel.trackAuthEvent('Signed In', currentUser, {
          type: 'log in',
        })
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setAuthState((previousState) => ({
            ...previousState,
            isLoading: false,
          }))
          signOutUser()
          mixpanel.track('Sign In Failed')
          throw error
        }
      })
  }

  function registerUser({ passwordConfirmation, ...rest }) {
    setAuthState((previousState) => ({ ...previousState, isLoading: true }))
    // FIXME: I don't think this is necessary anymore since we should snake case in an interceptor
    const registrationInfo = {
      password_confirmation: passwordConfirmation,
      ...rest,
    }
    return register('/auth', registrationInfo)
      .then((currentUser) => {
        setAuthState((previousState) => ({
          ...previousState,
          currentUser,
          isLoading: false,
          isSignedIn: true,
        }))
        mixpanel.trackAuthEvent('Registered', currentUser)
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setAuthState((previousState) => ({
            ...previousState,
            isLoading: false,
          }))
          signOutUser()
          mixpanel.track('Sign In Failed')
          throw error
        }
      })
  }

  useEffect(() => {
    if (!authState.isSignedIn) verifyCredentials()

    return () => {
      cancelValidationRequest()
      cancelRegistrationRequest()
    }
  }, [authState.isSignedIn, verifyCredentials])

  return (
    <AuthContext.Provider
      value={{
        ...value,
        signInUser,
        registerUser,
        signOutUser,
        verifyCredentials,
      }}
      {...props}
    />
  )
}

export { AuthProvider }
