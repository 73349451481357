import React from 'react'
import faker from 'faker'

import ResponsesSection from './ResponsesSection'
import MockFacilityProvider from '../../../providers/MockFacilityProvider'

export default {
  title: 'Leevo UI/ResponsesSection',
  component: ResponsesSection,
  args: {
    className: 'm-4',
    responseCount: 1,
  },
  argTypes: {
    responseCount: { control: { type: 'range', min: 0, max: 20 } },
  },
}

function responses(count) {
  return Array.from({ length: count }, (_, index) => ({
    id: index,
    student: { id: 1, fullName: faker.name.findName() },
    respondent: { id: 2, fullName: faker.name.findName() },
    agreed: true,
    updatedAt: faker.date.past().toISOString(),
  }))
}

export function responsesSection(args) {
  return (
    <MockFacilityProvider>
      <ResponsesSection {...args} responses={responses(args.responseCount)} />
    </MockFacilityProvider>
  )
}
