import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import Transition from '../Transitions/Transition/Transition'
import closeButton from '../../../images/times-solid.svg'

function Modal({
  open,
  onClickBackground,
  onClickClose,
  header,
  headerColor,
  children,
  'data-cy': dataCy,
}) {
  let headerColorClasses
  const handleKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 27) onClickClose()
    },
    [onClickClose]
  )

  useEffect(() => {
    if (open) {
      document.documentElement.style.overflow = 'hidden'
      document.body.scroll = 'no'
    } else {
      document.documentElement.style.overflow = 'scroll'
      document.body.scroll = 'yes'
    }

    window && window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress, open])

  switch (headerColor) {
    case 'red':
      headerColorClasses = 'bg-red-700 border-red-500'
      break
    case 'yellow':
      headerColorClasses = 'bg-yellow-600 border-yellow-400'
      break
    case 'teal':
      headerColorClasses = 'bg-teal-700 border-teal-500'
      break
    case 'pink':
      headerColorClasses = 'bg-pink-700 border-pink-500'
      break
    case 'blue':
      headerColorClasses = 'bg-blue-700 border-blue-500'
      break
    case 'green':
      headerColorClasses = 'bg-green-700 border-green-500'
      break
    case 'orange':
      headerColorClasses = 'bg-orange-700 border-orange-500'
      break
    case 'gray':
      headerColorClasses = 'bg-gray-500 border-gray-300'
      break
    default:
      headerColorClasses = 'bg-purple-700 border-purple-500'
  }

  return (
    <Transition
      show={open}
      className="fixed inset-0 inset-x-0 bottom-0 z-50 flex items-center justify-center px-4 pb-4 overflow-auto"
      data-cy={dataCy}
    >
      <Transition
        show={open}
        onClick={onClickBackground}
        enter="ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed inset-0 transition-opacity"
        data-cy="modal-background"
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </Transition>

      <Transition
        show={open}
        enter="ease-out duration-200"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        className="relative w-full max-w-lg overflow-auto transition-all transform bg-white rounded-lg shadow-xl max-h-3/4"
      >
        {header && (
          <header
            className={`${headerColorClasses} sticky top-0 px-6 py-4 text-lg font-semibold text-white border-b-2 z-10`}
          >
            {onClickClose && (
              <button
                type="button"
                role="button"
                title="close button"
                desc="closes the list of skills"
                onClick={() => onClickClose()}
                data-cy="modal-close-button"
                className="absolute top-0 right-0 w-8 h-8 pl-2 m-4 rounded-full cursor-pointer fill-current hover:bg-purple-100 hover:text-purple-600 focus:outline-none focus:ring focus:ring-purple-500"
                tabIndex="0"
              >
                <SVG className="w-4 h-4" src={closeButton} />
              </button>
            )}
            {header}
          </header>
        )}
        <div className="px-4 pt-2 pb-4 bg-white sm:px-6 sm:py-4">
          {children}
        </div>
      </Transition>
    </Transition>
  )
}

Modal.defaultProps = {
  headerColor: 'purple',
}

Modal.headerColors = [
  'red',
  'purple',
  'yellow',
  'teal',
  'pink',
  'blue',
  'green',
  'orange',
  'gray',
]

Modal.defaultProps = {
  'data-cy': 'modal',
}

Modal.propTypes = {
  onClickClose: PropTypes.func,
  onClickBackground: PropTypes.func,
  children: PropTypes.element.isRequired,
  header: PropTypes.string,
  open: PropTypes.bool,
  headerColor: PropTypes.oneOf(Modal.headerColors),
  'data-cy': PropTypes.string,
}

export default Modal
