import React from 'react'
import Card from './Card'
import { action } from '@storybook/addon-actions'

export default {
  title: 'Leevo UI/Card',
  component: Card,
  args: {
    title: 'Some Title',
    className: 'm-4 p-2',
    children: 'Some Content (which can also be React Nodes)',
  },
  argTypes: {
    children: { type: 'string' },
  },
}

export function card(args) {
  return <Card {...args} onClick={action('Clicked')} />
}
