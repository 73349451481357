import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { uniq } from 'lodash'

import Avatar from '../../leevo_ui/Avatar/Avatar'
import SkillButton from '../../leevo_ui/SkillButton/SkillButton'
import SkillSelector from '../../leevo_ui/SkillSelector/SkillSelector'

import { requestCreator } from '../../../utilities/requests'

const { put: updateUser, cancel: cancelUserRequests } = requestCreator()

function StudentCard({
  id,
  fullName,
  cloudinaryPhotoPublicId,
  skills,
  currentSkillLevel,
}) {
  const [mostRecentPublicId, setMostRecentPublicId] = useState(
    cloudinaryPhotoPublicId
  )
  const [selectedCategory, setSelectedCategory] = useState(skillCategories()[0])
  const showSkillSelector = skillCategories() && skillCategories().length > 1

  useEffect(() => {
    setMostRecentPublicId(cloudinaryPhotoPublicId)
    return cancelUserRequests
  }, [cloudinaryPhotoPublicId])

  function skillCategories() {
    return uniq(skills.map((s) => s.category))
  }

  return (
    <div
      className="relative w-full max-w-2xl mx-1 my-2 rounded-lg shadow"
      data-cy="student-card"
    >
      <header
        className="sticky bg-white border-b border-gray-200 rounded-t-lg"
        style={{ top: '45px' }}
      >
        <div className="flex justify-between p-3">
          <div className="flex flex-col justify-between">
            <div className="flex flex-col items-start justify-start">
              <p className="inline-block font-medium">{fullName}</p>
              <p className="inline-block text-sm font-medium text-gray-600">
                {currentSkillLevel}
              </p>
            </div>
          </div>
          <Avatar
            fullName={fullName}
            isStudent
            cloudinaryPhotoPublicId={mostRecentPublicId}
            alt={`Photo of ${fullName}`}
            userId={id}
            size={12}
            onPhotoUpload={(newPublicId) => {
              updateUser(`/api/users/${id}`, {
                user: { cloudinaryPhotoPublicId: newPublicId },
              }).then(() => {
                setMostRecentPublicId(newPublicId)
              })
            }}
          />
        </div>
      </header>
      <section className="bg-white rounded-b-lg">
        {showSkillSelector && (
          <div className="flex justify-center items-center mb-2 bg-white border-b border-gray-200">
            <SkillSelector
              skillCategories={skillCategories()}
              selectedCategory={selectedCategory}
              onCategorySelected={setSelectedCategory}
            />
          </div>
        )}
        {/* FIXME: Should we pull this out and share it between SkillsModal and this component? */}
        {skills
          .sort((a, b) => a.ordinal - b.ordinal)
          .filter((skill) => skill.category === selectedCategory)
          .map(({ id, shortLabel, name, status }) => (
            <div
              key={id}
              className="flex items-center py-2 px-3"
              data-cy="skill"
            >
              <SkillButton label={shortLabel} state={status} />
              <div className="pl-2">{name}</div>
            </div>
          ))}
      </section>
    </div>
  )
}

StudentCard.defaultProps = {
  cloudinaryPhotoPublicId: '',
  skills: [],
}

StudentCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fullName: PropTypes.string.isRequired,
  currentSkillLevel: PropTypes.string.isRequired,
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      shortLabel: PropTypes.string.isRequired,
      name: PropTypes.string,
    })
  ),
  cloudinaryPhotoPublicId: PropTypes.string,
}

export default StudentCard
