import React from 'react'
import LoggingIn from './LoggingIn'

export default {
  title: 'Leevo UI/LoggingIn',
  component: LoggingIn,
  args: {},
}

export function loggingIn(args) {
  return <LoggingIn {...args} />
}
