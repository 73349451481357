import React, { useState } from 'react'
import FilterTabs from './FilterTabs'

export default {
  title: 'Leevo UI/FilterTabs',
  component: FilterTabs,
  args: {
    className: 'm-4',
    options: ['Registered', 'Unregistered', 'All'],
  },
}

export function FilterTabsComponent(args) {
  const { options } = args
  const [value, setValue] = useState(options[0])

  return <FilterTabs {...args} value={value} onChange={setValue} />
}
