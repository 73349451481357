import React from 'react'
import { Helmet } from 'react-helmet'

import PricingSection from './PricingSection/PricingSection'
import FaqSection from './FaqSection/FaqSection'

import MarketingNavBar from '../leevo_ui/NavBar/MarketingNavBar/MarketingNavBar'
import SimpleJustifiedCTA from '../leevo_ui/SimpleJustifiedCTA/SimpleJustifiedCTA'
import MarketingPageFooter from '../leevo_ui/MarketingPageFooter/MarketingPageFooter'

const fscOfLaLogo = 'https://leevo-public.s3.amazonaws.com/images/fsc-of-la.png'
const groverIceLogo =
  'https://leevo-public.s3.amazonaws.com/images/grover-ice.png'

function PricingFeature() {
  return (
    <>
      <Helmet>
        <title>Pricing | Leevo</title>
      </Helmet>
      <MarketingNavBar />
      <main>
        <div className="bg-purple-800 dark:bg-purple-900">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="text-center">
              <p className="mt-1 text-4xl font-extrabold sm:text-5xl sm:tracking-tight lg:text-6xl text-white dark:text-gray-300">
                Pricing for any size program
              </p>
              <p className="max-w-xl mt-5 mx-auto text-xl text-purple-200 dark:text-purple-300">
                Whether you have 5 students or 5000, there&apos;s a plan for
                your budget.
              </p>
            </div>
          </div>
          <PricingSection />
          <section className="bg-purple-800 dark:bg-purple-900">
            <div className="mx-auto max-w-7xl md:grid md:grid-cols-2 md:px-6 lg:px-8">
              <div className="px-4 py-12 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-purple-900 md:dark:border-purple-800 lg:pr-16">
                <div className="md:flex-shrink-0">
                  <img
                    className="h-12 filter brightness-150"
                    src={fscOfLaLogo}
                    alt="Lake City Figure Skating Club Logo"
                  />
                </div>
                <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
                  <div className="relative text-lg font-medium text-white dark:text-gray-300 md:flex-grow">
                    <svg
                      className="absolute top-0 left-0 w-8 h-8 text-purple-600 dark:text-purple-700 transform -translate-x-3 -translate-y-2"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <p className="relative">
                      Our retention has been amazing since we started using
                      Leevo.
                    </p>
                  </div>
                  <footer className="mt-8">
                    <div className="flex items-start">
                      <div>
                        <div className="text-base font-medium text-white dark:text-gray-300">
                          Pam Kaminsky
                        </div>
                        <div className="text-base font-medium text-purple-200 dark:text-purple-300">
                          Director, Figure Skating Club of Louisiana
                        </div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
              <div className="px-4 py-12 border-t-2 border-purple-900 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
                <div className="md:flex-shrink-0">
                  <img
                    className="h-12 filter brightness-150"
                    src={groverIceLogo}
                    alt="Grover Ice Logo"
                  />
                </div>
                <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
                  <div className="relative text-lg font-medium text-white dark:text-gray-300 md:flex-grow">
                    <svg
                      className="absolute top-0 left-0 w-8 h-8 text-purple-600 dark:text-purple-700 transform -translate-x-3 -translate-y-2"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <p className="relative">
                      We are loving the system! We&apos;re looking forward to
                      expanding our class offerings.
                    </p>
                  </div>
                  <footer className="mt-8">
                    <div className="flex items-start">
                      <div>
                        <div className="text-base font-medium text-white dark:text-gray-300">
                          Liyen Vong
                        </div>
                        <div className="text-base font-medium text-purple-200 dark:text-purple-300">
                          Director, Grover Ice Rink
                        </div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </section>
          <FaqSection />
          <SimpleJustifiedCTA showTrialButton />
        </div>
      </main>
      <MarketingPageFooter />
    </>
  )
}

export default PricingFeature
