import React from 'react'
import MainFeaturesSection from './MainFeaturesSection'

export default {
  title: 'Home Feature/Components/MainFeaturesSection',
  component: MainFeaturesSection,
}

export function mainFeaturesSection(args) {
  return <MainFeaturesSection {...args} />
}
