import React from 'react'
import { Form } from 'react-final-form'
import { shallow, mount } from 'enzyme'

import BirthdateField from './BirthdateField'

describe('BirthdateField', () => {
  let wrapper

  test('renders', () => {
    wrapper = shallow(<BirthdateField />)
    expect(wrapper).toExist()
  })

  test('renders className on the topmost div', () => {
    wrapper = mount(
      <Form
        onSubmit={jest.fn()}
        render={() => <BirthdateField className="foo" />}
      />
    )
    expect(wrapper.find('div').at(0)).toHaveClassName('foo')
  })

  describe('name', () => {
    describe('when not present', () => {
      test("renders 'the student' in the question", () => {
        expect(wrapper.first('label')).toIncludeText('the student')
      })

      test("renders 'the student' in the help text", () => {
        expect(wrapper.first('p')).toIncludeText('the student')
      })
    })

    describe('when present', () => {
      beforeEach(() => {
        wrapper = mount(
          <Form
            onSubmit={jest.fn()}
            render={() => <BirthdateField name="Meg" />}
          />
        )
      })

      test('renders the name in the question', () => {
        expect(wrapper.first('label')).toIncludeText('Meg')
      })

      test('renders the name in the help text', () => {
        expect(wrapper.first('p')).toIncludeText('Meg')
      })
    })
  })
})
