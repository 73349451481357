import React from 'react'
import PropTypes from 'prop-types'
import DisclosuresListItem from '../DisclosuresListItem/DisclosuresListItem'

function DisclosuresList({ heading, disclosures, className, ...rest }) {
  return (
    <div {...rest} className={className}>
      <h1 className="text-gray-800 text-lg font-medium pb-5">{heading}</h1>
      <ul className="pl-4">
        {disclosures.map(({ id, name, active }) => (
          <DisclosuresListItem key={id} id={id} name={name} active={active} />
        ))}
      </ul>
    </div>
  )
}

DisclosuresList.defaultProps = {
  disclosures: [],
}

DisclosuresList.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  disclosures: PropTypes.arrayOf(PropTypes.object),
}

export default DisclosuresList
