import React from 'react'
import GuardianInfo from './GuardianInfo'

export default {
  title: 'Leevo UI/Student Edit Form/GuardianInfo',
  component: GuardianInfo,
  args: {
    className: 'm-4',
    fullName: 'Sandy Clarence',
    email: 'serenasalvato1@gmail.com',
    phone: '+13478038847',
    pending: false,
  },
}

export function guardianInfo(args) {
  return <GuardianInfo {...args} />
}
