import React from 'react'
import RefundPolicyFeature from './RefundPolicyFeature'

export default {
  title: 'RefundPolicyFeature',
  component: RefundPolicyFeature,
  args: {
    className: 'm-4',
  },
}

export function refundPolicyFeature(args) {
  return <RefundPolicyFeature {...args} />
}
