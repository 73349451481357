import React from 'react'
import { shallow } from 'enzyme'
import MarketingNavBar from './MarketingNavBar'

// Mock out the useLocation hook
jest.mock('react-router-dom', () => ({
  ...jest.requireActual('react-router-dom'),
  useLocation: () => ({
    state: { message: 'foobar' },
  }),
}))

describe('MarketingNavBar', () => {
  let wrapper, links, color
  beforeEach(() => {
    links = [
      { label: 'foo', to: '/some/path' },
      { label: 'bar', to: '/some/other/path' },
    ]
    color = 'purple'
    wrapper = shallow(<MarketingNavBar links={links} titleColor={color} />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
