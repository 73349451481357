import React from 'react'

import Recommendation from './Recommendation'

export default {
  title: 'Coach Feature/Components/Recommendation',
  component: Recommendation,
  args: {
    shortLabel: 'A',
    name: 'Forward one foot glides – R and L',
    studentCount: 1,
    status: Recommendation.statuses[0],
  },
  argTypes: {
    studentCount: {
      control: {
        type: 'number',
        step: 5,
      },
    },
    status: { control: { type: 'select', options: Recommendation.statuses } },
  },
}

export function recommendation(args) {
  return (
    <div className="m-4">
      <Recommendation {...args} />
    </div>
  )
}
