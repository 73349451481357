import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CheckboxInput from '../CheckboxInput/CheckboxInput'

function LargeRadioInput({
  id,
  label,
  className,
  errorMessage,
  value,
  onChange,
  name,
  helpText,
  options,
}) {
  return (
    <div
      name={name}
      className={className}
      value={value}
      data-cy="large-radio-input"
    >
      {label && (
        <label
          className={`${classNames({
            'text-red-500': errorMessage,
          })} block text-lg mb-3`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      {helpText && <p className="mb-6 text-xs">{helpText}</p>}
      <p className="mb-3 text-xs italic text-red-500">{errorMessage}</p>
      <div>
        {options.map((option) => (
          <CheckboxInput
            className="last:border-b-0 border-b border-gray-400"
            key={option.value}
            id={id}
            label={option.label}
            value={option.value}
            onClick={(e) => onChange(e.target.value)}
            checked={value === option.value}
            data-cy={`${option.value}-checkbox-input`}
          />
        ))}
      </div>
    </div>
  )
}

LargeRadioInput.default = {
  className: '',
  errorMessage: '',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
}

LargeRadioInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  helpText: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      checked: PropTypes.string.bool,
    })
  ).isRequired,
}

export default LargeRadioInput
