import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import SlideInTransition from '../../Transitions/SlideInTransition/SlideInTransition'

// FIXME: This component is probably not necessary, overkill and inadequate.
// For example, what if SlideInRoute is called with Component, instead of Render?
// This component, as implemented, is brittle.
// Instead, consider an approach to animations as laid out here, possibly refactored
// to use hooks: https://medium.com/onfido-tech/animations-with-react-router-8e97222e25e1#25cd
function SlideInRoute({ render, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => <SlideInTransition>{render()}</SlideInTransition>}
    />
  )
}
SlideInRoute.defaultProps = {}

SlideInRoute.propTypes = {
  render: PropTypes.func.isRequired,
}

export default SlideInRoute
