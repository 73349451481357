import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useParams, useLocation } from 'react-router-dom'
import axios from 'axios'
import { get } from 'lodash'

import { requestCreator } from '../utilities/requests'
import FacilitiesInactive from '../components/FacilitiesInactive/FacilitiesInactive'

export const FacilityContext = React.createContext()

const { get: getFacility, cancel: cancelFacilityRequest } = requestCreator()

function throwRouteError() {
  throw new Error(
    'FacilityProvider must be in a route that includes a facility_id param'
  )
}

export default function FacilityProvider({ children, ...rest }) {
  function update(facility) {
    setFacilityState((state) => ({ ...state, facility }))
  }

  const { facility_id: facilityId } = useParams()
  const { state } = useLocation()
  const [facilityState, setFacilityState] = useState({
    facility: {},
    isLoading: true,
  })
  const facilityValues = useMemo(() => {
    return facilityState
  }, [facilityState])

  if (!facilityId) throwRouteError()

  useEffect(() => {
    async function getFacilityData() {
      try {
        setFacilityState((state) => ({ ...state, isLoading: true }))
        const facility = await getFacility(`/api/facilities/${facilityId}`, {
          params: { include: 'curriculums,billing_contact,address' },
        })
        setFacilityState({ facility, isLoading: false })
      } catch (error) {
        setFacilityState((state) => ({ ...state, isLoading: false }))
        if (!axios.isCancel(error)) throw error
      }
    }

    getFacilityData()

    return cancelFacilityRequest
  }, [facilityId])

  return (
    <FacilityContext.Provider value={{ ...facilityValues, update }} {...rest}>
      {get(state, 'isGated') ? <FacilitiesInactive /> : children}
    </FacilityContext.Provider>
  )
}

FacilityProvider.propTypes = {
  children: PropTypes.node,
}

export { FacilityProvider }
