import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import useFacility from '../../../utilities/hooks/useFacility'

function DisclosuresListItem({ name, id: disclosureId }) {
  const { facility } = useFacility()

  return (
    <li className="py-1 leading-snug border-gray-200 first:pt-0 last:pb-0">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <Link
            data-cy="disclosure-list-item-link"
            className="text-lg font-medium text-purple-500 cursor-pointer hover:text-purple-400"
            to={`/facilities/${facility.id}/disclosures/${disclosureId}/edit`}
          >
            {name}
          </Link>
        </div>
      </div>
    </li>
  )
}

DisclosuresListItem.defaultProps = {}

DisclosuresListItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  active: PropTypes.bool,
}

export default DisclosuresListItem
