import React, { useState } from 'react'
import { PlayIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet'

import YouTubeEmbedModal from '../leevo_ui/YouTubeEmbedModal/YouTubeEmbedModal'
import AnnouncementBanner from '../leevo_ui/AnnouncementBanner/AnnouncementBanner'
import TestimonialWithLargeAvatar from './TestimonialWithLargeAvatar/TestimonialWithLargeAvatar'
import MainFeaturesSection from './MainFeaturesSection/MainFeaturesSection'
import SideBySideTestimonial from './SideBySideTestimonial/SideBySideTestimonial'
import FeatureGrid from './FeatureGrid/FeatureGrid'
import SimpleJustifiedCTA from '../leevo_ui/SimpleJustifiedCTA/SimpleJustifiedCTA'
import MarketingPageFooter from '../leevo_ui/MarketingPageFooter/MarketingPageFooter'
import MarketingNavBar from '../leevo_ui/NavBar/MarketingNavBar/MarketingNavBar'
import mixpanel from '../../utilities/mixpanel'
import { sendCrispMessage } from '../../utilities/crisp'

const binghamtonLogo =
  'https://leevo-public.s3.amazonaws.com/images/binghamton-fsc.png'
const lakeCityLogo =
  'https://leevo-public.s3.amazonaws.com/images/lake-city.png'
const eauClaireLogo =
  'https://leevo-public.s3.amazonaws.com/images/eau-claire.png'
const fscOfLaLogo = 'https://leevo-public.s3.amazonaws.com/images/fsc-of-la.png'
const groverIceLogo =
  'https://leevo-public.s3.amazonaws.com/images/grover-ice.png'
const lilacSkateLogo =
  'https://leevo-public.s3.amazonaws.com/images/lilac-skate.png'
const mountainViewLogo =
  'https://leevo-public.s3.amazonaws.com/images/mountain-view-ice-arena.png'

export default function HomeFeature() {
  const [isPromoVideoOpen, setIsPromoVideoOpen] = useState(false)
  const [showAnnouncementBanner, setShowAnnouncementBanner] = useState(true)

  return (
    <>
      <Helmet>
        <title>Leevo - Delightful skating software</title>
      </Helmet>
      <AnnouncementBanner
        show={showAnnouncementBanner}
        longMessage="Use LTSUSA? Leevo now handles LTS registrations for you."
        message="Leevo now handles LTS registrations for you."
        onClose={() => setShowAnnouncementBanner(false)}
      />
      <div className="bg-white dark:bg-gray-800">
        <MarketingNavBar />
        <main className="pt-4">
          <div>
            <div className="relative">
              <div className="absolute inset-x-0 bottom-0 bg-gray-100 dark:bg-gray-700 h-1/2" />
              <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                  <div className="absolute inset-0">
                    <img
                      className="object-cover w-full h-full filter grayscale contrast-[.33]"
                      src="https://leevo-public.s3.amazonaws.com/images/hero-background.jpeg"
                      alt="Figure skating family"
                    />
                    <div className="absolute inset-0 bg-purple-700 dark:bg-purple-800 mix-blend-multiply" />
                  </div>
                  <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                    <h1 className="text-4xl font-extrabold tracking-tight text-center sm:text-5xl lg:text-6xl">
                      <span className="block text-white dark:text-gray-300">
                        The smarter way to run
                      </span>
                      <span className="block text-purple-200 dark:text-purple-400">
                        your skating program
                      </span>
                    </h1>
                    <p className="max-w-lg mx-auto mt-6 text-xl text-center text-purple-200 sm:max-w-3xl">
                      Everything that&apos;s important. Nothing that&apos;s not.
                      Perfect for any size program (so long as it&apos;s
                      skating).
                    </p>
                    <div className="max-w-sm mx-auto mt-10 sm:max-w-none sm:flex sm:justify-center">
                      <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                        <button
                          type="button"
                          onClick={() => {
                            sendCrispMessage(
                              "I'm ready to start my free trial. What are the next steps?"
                            )
                            mixpanel.track('Clicked Start Free Trial')
                          }}
                          className="flex items-center justify-center px-4 py-3 text-base font-medium text-purple-700 bg-white dark:bg-gray-300 border border-transparent rounded-md shadow-sm hover:bg-purple-50 sm:px-8 w-full"
                        >
                          Start your 30-day free trial
                        </button>
                        <button
                          onClick={() => setIsPromoVideoOpen(true)}
                          type="button"
                          className="flex items-center justify-center w-full px-4 py-3 space-x-2 text-base font-medium text-white dark:text-gray-300 bg-purple-500 rounded-md shadow-sm bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                        >
                          <PlayIcon className="w-6 h-6" aria-hidden="true" />
                          <span>See our video</span>
                          <YouTubeEmbedModal
                            open={isPromoVideoOpen}
                            onClose={() => setIsPromoVideoOpen(false)}
                            embedUrl="https://www.youtube.com/embed/sb7vQEBsX_w?modestbranding=1&playsinline=0&rel=0&autoplay=1"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gray-100 dark:bg-gray-700">
              <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <p className="text-sm font-semibold tracking-wide text-center text-gray-500 dark:text-gray-400 uppercase">
                  Trusted by programs throughout the United States
                </p>
                <div className="grid grid-cols-4 gap-8 mt-6 md:grid-cols-8">
                  <div className="flex justify-center col-span-2">
                    <img
                      className="h-24 filter brightness-125"
                      src={lakeCityLogo}
                      alt="Lake City"
                    />
                  </div>
                  <div className="flex justify-center col-span-2">
                    <img
                      className="h-24 filter brightness-125"
                      src={binghamtonLogo}
                      alt="Binghamton FSC"
                    />
                  </div>
                  <div className="flex justify-center col-span-2">
                    <img
                      className="h-24 filter brightness-125"
                      src={eauClaireLogo}
                      alt="Eau Claire FSC"
                    />
                  </div>
                  <div className="flex justify-center col-span-2">
                    <img
                      className="h-24 filter brightness-125"
                      src={fscOfLaLogo}
                      alt="FSC of LA"
                    />
                  </div>
                  <div className="flex justify-center col-span-2 md:col-start-2">
                    <img
                      className="h-24 filter brightness-125"
                      src={groverIceLogo}
                      alt="Grover Ice"
                    />
                  </div>
                  <div className="flex justify-center col-span-2">
                    <img
                      className="h-24 filter brightness-125"
                      src={mountainViewLogo}
                      alt="Grover Ice"
                    />
                  </div>
                  <div className="flex justify-center col-span-2 col-start-2 md:col-start-auto">
                    <img
                      className="h-24 filter brightness-125"
                      src={lilacSkateLogo}
                      alt="Lilac Skate"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <TestimonialWithLargeAvatar />
          <SideBySideTestimonial />
          <MainFeaturesSection />
          <FeatureGrid />
          <SimpleJustifiedCTA showTrialButton />
        </main>
        <MarketingPageFooter />
      </div>
    </>
  )
}
