import React from 'react'
import NavBarMenu from './NavBarMenu'
import { shallow } from 'enzyme'

describe('NavBarMenu', () => {
  let wrapper
  beforeEach(() => {
    wrapper = shallow(
      <NavBarMenu opened links={[{ label: 'foo', to: '/bar' }]} />
    )
  })

  // FIXME: We need to add more specs to make sure the NavBarMenu is working
  // This will be much easier when we refactor out of `Motion`. Right now,
  // testing this component is possible, but probably not worth the effort
  // to figure out how to navigate testing around Motion.
  test('renders', () => {
    expect(wrapper).toExist()
  })
})
