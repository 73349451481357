import React from 'react'
import classNames from 'classnames'

import CheckboxInput from './CheckboxInput'

export default {
  title: 'Forms/CheckboxInput',
  component: CheckboxInput,
  args: {
    label: 'Female',
    id: 'checkbox-id',
    onClick: () => console.log('Clicked'), // eslint-disable-line no-console
    checked: false,
    value: 'female',
    small: false,
    helpText: 'This should be some helpful text',
    disabled: false,
    withMargins: true,
  },
}

export function checkboxInput(args) {
  return (
    <div
      className={classNames(
        { 'm-10': args.withMargins },
        'flex justify-center'
      )}
    >
      <CheckboxInput {...args} />
    </div>
  )
}
