import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button/Button'

function IndexHeader({ title, onAddClick }) {
  return (
    <header className="flex justify-center px-4 text-gray-900 bg-white shadow">
      <div className="flex items-center justify-between w-full max-w-2xl px-2">
        <h1 className="w-full pt-10 pb-10 text-xl font-semibold">{title}</h1>
        {onAddClick && (
          <Button
            label="Add +"
            className="flex-shrink-0 px-3 rounded-full"
            small
            outline
            onClick={onAddClick}
            data-cy="add-button"
          />
        )}
      </div>
    </header>
  )
}

IndexHeader.propTypes = {
  title: PropTypes.string,
  onAddClick: PropTypes.func,
}

export default IndexHeader
