import React from 'react'
import { shallow } from 'enzyme'
import { BrowserRouter } from 'react-router-dom'

import RegistrationFeature from './RegistrationFeature'

describe('RegistrationFeature', () => {
  let wrapper

  // FIXME: Write more specs for this component.
  test('renders', () => {
    wrapper = shallow(
      <BrowserRouter>
        <RegistrationFeature />
      </BrowserRouter>
    )
    expect(wrapper).toExist()
  })
})
