import React from 'react'
import KlassCard from './KlassCard'
import { mount } from 'enzyme'
import { MemoryRouter } from 'react-router-dom'

describe('KlassCard', () => {
  let wrapper
  beforeEach(() => {
    wrapper = mount(
      <MemoryRouter initialEntries={['/']}>
        <KlassCard id="1" />
      </MemoryRouter>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })
})
