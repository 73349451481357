import React from 'react'
import PropTypes from 'prop-types'

import { FacilityContext } from '../providers/FacilityProvider'
import { MockAuthProvider } from '../providers/MockAuthProvider'

export default function MockFacilityProvider({
  facility,
  isLoading,
  authOptions,
  ...rest
}) {
  return (
    <MockAuthProvider {...authOptions}>
      <FacilityContext.Provider value={{ facility, isLoading }} {...rest} />
    </MockAuthProvider>
  )
}

MockFacilityProvider.defaultProps = {
  isLoading: false,
  authOptions: {},
  facility: {
    id: 1,
    name: 'fake facility',
    slug: 'fake-facility',
    stripeUserId: 'acct_1031Pz2bLd160UrL',
    stripeCreditCardBrand: 'Visa',
    stripeCreditCardLast4: '4242',
    subscriptionStatus: 'trialing',
    trialEnd: 1615683390,
    isStripeConnected: true,
    upcomingInvoiceStart: 1615683390,
    upcomingInvoiceEnd: 1615683390,
    upcomingInvoiceTotal: 0,
    upcomingInvoiceStudentsCount: 0,
    isSubscriptionCancelledAtPeriodEnd: true,
    timeZone: 'America/New_York',
    curriculums: [],
    billing_contact: {},
    address: {},
  },
}

MockFacilityProvider.propTypes = {
  isLoading: PropTypes.bool,
  facility: PropTypes.object,
  authOptions: PropTypes.object,
}

export { MockFacilityProvider }
