import React from 'react'
import { mount } from 'enzyme'

import FeatureInterestBanner from './FeatureInterestBanner'
import MockAuthProvider from '../../../providers/MockAuthProvider'

describe('FeatureInterestBanner', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MockAuthProvider currentUser={{ coach: true }}>
          <FeatureInterestBanner />
        </MockAuthProvider>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
