import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CheckButton from '../../leevo_ui/CheckButton/CheckButton'
import { useHistory } from 'react-router-dom'

export default function StudentAttendanceCheckButton({
  klassDaysStudent: { id, attended },
  toggleAttendance,
}) {
  const history = useHistory()
  const [updatingAttendance, setUpdatingAttendance] = useState(false)
  const [hasAttended, setHasAttended] = useState(attended)

  function handleToggleAttendance() {
    const originalState = hasAttended

    setUpdatingAttendance(true)
    setHasAttended(!originalState)

    return Promise.resolve(toggleAttendance(id))
      .then(() => setUpdatingAttendance(false))
      .catch(() => {
        setHasAttended(originalState)
        setUpdatingAttendance(false)
        const message = 'Oops! Something went wrong while recording attendance.'
        history.replace({
          state: { notification: { message, color: 'red' } },
        })
      })
  }

  return (
    <>
      <CheckButton
        ariaProps={{ 'aria-pressed': hasAttended, 'aria-label': 'attendance' }}
        disabled={updatingAttendance}
        size={6}
        onClick={handleToggleAttendance}
        checked={hasAttended}
        className="inline-block mr-2"
        data-cy="attendance-button"
        loading={updatingAttendance}
      />
    </>
  )
}

StudentAttendanceCheckButton.propTypes = {
  klassDaysStudent: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    attended: PropTypes.bool.isRequired,
  }).isRequired,
  toggleAttendance: PropTypes.func.isRequired,
}
