import React, { useState } from 'react'
import SkillSelector from './SkillSelector'

export default {
  title: 'Leevo UI/SkillSelector',
  component: SkillSelector,
}

export function SkillSelectorComponent() {
  const [selected, setSelected] = useState('Floor')
  return (
    <SkillSelector
      skillCategories={['Floor', 'Vault', null]}
      selectedCategory={selected}
      onCategorySelected={setSelected}
    />
  )
}
