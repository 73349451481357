import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'

import {
  ArrowRightIcon,
  ChatAlt2Icon,
  CheckIcon,
  HandIcon,
  LightningBoltIcon,
  PhoneIcon,
  UserGroupIcon,
} from '@heroicons/react/outline'

import mixpanel from '../../../utilities/mixpanel'
import { sendCrispMessage } from '../../../utilities/crisp'

const features = [
  {
    name: 'White Glove Setup',
    icon: HandIcon,
    description:
      'When you get started with Leevo, we do all the heavy lifting. Just take 5 minutes to send over your student and class info, and we do the rest.',
  },
  {
    name: 'Direct access to founders',
    icon: PhoneIcon,
    description: (
      <span>
        Everyone who uses Leevo gets the direct contact info for{' '}
        <Link
          className="text-purple-600 hover:text-purple-400 dark:text-purple-400 dark:hover:text-purple-600"
          to="/about"
        >
          our founders
        </Link>
        , who have written the software. If you have a question, you get an
        answer fast. If you have a problem, it&apos;s resolved, fast.
      </span>
    ),
  },
  {
    name: "We're there for your team (or board)",
    icon: UserGroupIcon,
    description:
      "Have a board or team meeting and want someone who knows the software inside and out to answer questions? We'll be there.",
  },
  {
    name: 'Register through Leevo, or something else',
    icon: LightningBoltIcon,
    description:
      "We help you set up Leevo's registration system to process all registrations, including LTS. Or, use another registration system. Either way, we help get you set up for the best teaching experience possible.",
  },
]
const checklist = [
  'White glove setup',
  'Integrated Registration',
  'Automated LTS Registrations',
  '24/7 support',
  'Cancel any time',
  '30-day free trial',
]

function PricingSection() {
  const [numStudents, setNumStudents] = useState(0)

  const price = useMemo(() => {
    if (numStudents === 0) {
      return '$0'
    } else if (numStudents < 200) {
      return '$49'
    } else if (numStudents < 500) {
      return '$79'
    } else if (numStudents < 1000) {
      return '$99'
    } else {
      return null
    }
  }, [numStudents])

  return (
    <div className="relative bg-white dark:bg-gray-800">
      <div className="absolute inset-0" aria-hidden="true">
        <div className="absolute inset-y-0 right-0 w-1/2 bg-purple-800 dark:bg-purple-900" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:px-8 lg:grid lg:grid-cols-2">
        <div className="bg-white dark:bg-gray-800 py-16 px-4 sm:py-24 sm:px-6 lg:px-0 lg:pr-8">
          <div className="max-w-lg mx-auto lg:mx-0">
            <h2 className="text-base font-semibold tracking-wide text-purple-600 dark:text-purple-500 uppercase">
              We&apos;re here to help
            </h2>
            <p className="mt-2 text-2xl font-extrabold text-gray-900 dark:text-gray-300 sm:text-3xl">
              You&apos;re buying teammates, not software.
            </p>
            <dl className="mt-12 space-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute h-12 w-12 flex items-center justify-center bg-purple-700 dark:bg-purple-800 rounded-md">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500 dark:text-gray-400">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className="bg-purple-800 dark:bg-purple-900 py-16 px-4 sm:py-24 sm:px-6 lg:bg-none lg:px-0 lg:pl-8 lg:flex lg:items-center lg:justify-end">
          <div className="max-w-lg mx-auto w-full space-y-8 lg:mx-0">
            <div>
              <p className="mt-2 text-2xl font-extrabold text-white dark:text-gray-300 sm:text-3xl text-center">
                How many students?
              </p>
              <p className="text-lg text-purple-200 dark:text-purple-300 sm:text-xl text-center">
                You&apos;re only charged for students enrolled in an active
                class.
              </p>
            </div>
            <div>
              <h2 className="sr-only">Price</h2>
              <p className="relative grid grid-cols-2">
                <span>
                  <span className="flex flex-col text-center">
                    <span className="text-5xl font-extrabold text-white dark:text-gray-300 tracking-tight">
                      {numStudents === 1000 ? '1000+' : numStudents}
                    </span>
                    <span className="mt-2 text-base font-medium text-purple-200">
                      Students
                    </span>
                  </span>
                </span>
                <span
                  className="pointer-events-none absolute h-12 w-full flex items-center justify-center"
                  aria-hidden="true"
                >
                  <ArrowRightIcon
                    className="h-6 w-6 text-purple-300 dark:text-purple-400"
                    aria-hidden="true"
                  />
                </span>
                {price && (
                  <span className="flex flex-col text-center">
                    <span className="text-5xl font-extrabold text-white dark:text-gray-300 tracking-tight">
                      {price}
                    </span>
                    <span className="mt-2 text-base font-medium text-purple-200">
                      Monthly Fee
                    </span>
                  </span>
                )}
                {!price && (
                  <span className="text-center">
                    <button
                      onClick={() => {
                        sendCrispMessage('I have more than 1000 students.')
                        mixpanel.track(
                          'Click Contact with more than 100 students'
                        )
                      }}
                      type="button"
                      className="inline-flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md text-purple-700 bg-white dark:bg-gray-200 hover:bg-purple-50"
                    >
                      <ChatAlt2Icon className="w-6 h-6" aria-hidden="true" />
                      <span>Contact Us</span>
                    </button>
                  </span>
                )}
              </p>
            </div>
            <div className="flex justify-center">
              <input
                className="rounded-lg slider-rounded-full slider-cursor-resize slider-appearance-none slider-25px slider-bg-purple-200 slider-shadow-hover appearance-none bg-purple-50 dark:bg-purple-200 h-3 w-full"
                type="range"
                min="0"
                max="1000"
                step="10"
                value={numStudents}
                onChange={(e) => setNumStudents(parseInt(e.target.value))}
              />
            </div>
            <ul
              role="list"
              className="rounded overflow-hidden grid gap-px sm:grid-cols-2"
            >
              {checklist.map((item) => (
                <li
                  key={item}
                  className="bg-purple-800 bg-opacity-50 py-4 px-4 flex items-center space-x-3 text-base text-white dark:text-gray-300"
                >
                  <CheckIcon
                    className="h-6 w-6 text-purple-300 dark:test-purple-400"
                    aria-hidden="true"
                  />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
            <button
              type="button"
              onClick={() => {
                sendCrispMessage(
                  "I'm ready to start my free trial. What are the next steps?"
                )
                mixpanel.track('Clicked Start Free Trial')
              }}
              className="bg-white dark:bg-gray-200 border border-transparent rounded-md w-full px-8 py-4 flex items-center justify-center text-lg leading-6 font-medium text-purple-600 hover:bg-purple-50 md:px-10"
            >
              Start your 30-day free trial
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingSection
