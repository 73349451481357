import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SVG from 'react-inlinesvg'

import Button from '../../leevo_ui/Button/Button'
import checkmark from '../../../images/checkmark.svg'
import arrow from '../../../images/arrow-thin.svg'

function RegistrationDashboardSection({
  children,
  disabled,
  completed,
  title,
  nextStepAction,
  headerAction,
}) {
  return (
    <section
      className={`last:border-0 border-b pt-6 pb-4 ${classNames({
        'border-gray-600 text-gray-800': !disabled,
        'border-gray-400 text-gray-400': disabled,
      })}`}
      data-cy={title}
    >
      <header className="flex items-center justify-between h-8">
        <h2 className="font-medium">{title}</h2>
        <div className="flex items-center space-x-2 space-y-0">
          {!disabled && completed && (
            <SVG
              data-cy="checkmark"
              className="w-4 h-4 m-auto text-green-600 fill-current stroke-current"
              src={checkmark}
            />
          )}
          {!disabled && headerAction && (
            <Button
              small
              outline
              data-cy="header-action-button"
              label={headerAction.label}
              onClick={headerAction.onClick}
            />
          )}
        </div>
      </header>
      <section className="my-3">{children}</section>
      {!disabled && nextStepAction && (
        <footer className="flex items-center mt-6">
          <Button
            data-cy="next-action-button"
            label={nextStepAction.label}
            onClick={nextStepAction.onClick}
          />
          <div className="transform rotate-180">
            <SVG
              src={arrow}
              className="w-auto h-10 text-purple-600 fill-current stroke-current right-left"
            />
          </div>
        </footer>
      )}
    </section>
  )
}

RegistrationDashboardSection.defaultProps = {
  disabled: false,
  completed: false,
  children: null,
  headerAction: null,
  nextStepAction: null,
}

RegistrationDashboardSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  completed: PropTypes.bool,
  nextStepAction: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
  }),
  headerAction: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
  }),
}

export default RegistrationDashboardSection
