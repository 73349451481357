import React from 'react'
import { mount } from 'enzyme'
import { Form } from 'react-final-form'

import GenderField from './GenderField'

describe('GenderField', () => {
  let wrapper
  beforeEach(() => {
    wrapper = mount(
      <Form onSubmit={jest.fn()} render={() => <GenderField />} />
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders className on the LargeRadioInput', () => {
    wrapper = mount(
      <Form
        onSubmit={jest.fn()}
        render={() => <GenderField className="foo" />}
      />
    )
    expect(wrapper.find('[data-cy="large-radio-input"]')).toHaveClassName('foo')
  })

  describe('name', () => {
    describe('when not present', () => {
      test("renders 'the student' in the question", () => {
        expect(wrapper.first('label')).toIncludeText('the student')
      })

      test("renders 'the student' in the help text", () => {
        expect(wrapper.first('p')).toIncludeText('the student')
      })
    })

    describe('when present', () => {
      beforeEach(() => {
        wrapper = mount(
          <Form
            onSubmit={jest.fn()}
            render={() => <GenderField name="Meg" />}
          />
        )
      })

      test('renders the name in the question', () => {
        expect(wrapper.first('label')).toIncludeText('Meg')
      })

      test('renders the name in the help text', () => {
        expect(wrapper.first('p')).toIncludeText('Meg')
      })
    })
  })
})
