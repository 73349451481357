import React from 'react'
import { shallow } from 'enzyme'
import { BrowserRouter } from 'react-router-dom'

import SelectKlasses from './SelectKlasses'

describe('SelectKlasses', () => {
  let wrapper

  // FIXME: Write more specs for this.
  test('renders', () => {
    wrapper = shallow(
      <BrowserRouter>
        <SelectKlasses nextStepUrl="foo" onKlassesSelected={jest.fn()} />
      </BrowserRouter>
    )
    expect(wrapper).toExist()
  })
})
