import React from 'react'
import { shallow, mount } from 'enzyme'
import { Form } from 'react-final-form'

import LTSNumberField from './LTSNumberField'

describe('LTSNumberField', () => {
  let wrapper

  test('renders', () => {
    wrapper = shallow(<LTSNumberField />)
    expect(wrapper).toExist()
  })

  test('renders className on topmost div', () => {
    wrapper = mount(
      <Form
        onSubmit={jest.fn()}
        render={() => <LTSNumberField className="foo" />}
      />
    )
    expect(wrapper.find('div').first()).toHaveClassName('foo')
  })

  describe('name', () => {
    describe('when not present', () => {
      test("renders 'the skater' in the question", () => {
        expect(wrapper.first('label')).toIncludeText('the skater')
      })
    })

    describe('when present', () => {
      beforeEach(() => {
        wrapper = mount(
          <Form
            onSubmit={jest.fn()}
            render={() => <LTSNumberField name="Meg" />}
          />
        )
      })

      test('renders the name in the question', () => {
        expect(wrapper.first('label')).toIncludeText('Meg')
      })
    })
  })
})
