import React from 'react'
import { MemoryRouter, Route } from 'react-router-dom'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

import AcceptInvitationFeature from './AcceptInvitationFeature'

export default {
  title: 'AcceptInvitationFeature',
  component: AcceptInvitationFeature,
}

export function acceptInvitationFeatureWithSuccess() {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onPut().reply(200)

  return (
    <MemoryRouter
      initialEntries={['/auth/invitation/accept?invitation_token=foobar']}
    >
      <Route path="/login" render={() => <div>Invitation accepted 🎉</div>} />
      <Route
        path="/auth/invitation/accept"
        component={AcceptInvitationFeature}
      />
    </MemoryRouter>
  )
}

export function acceptInvitationFeatureWithFailure() {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onPut().reply(422)

  return (
    <MemoryRouter
      initialEntries={['/auth/invitation/accept?invitation_token=foobar']}
    >
      <Route
        path="/auth/invitation/accept"
        component={AcceptInvitationFeature}
      />
    </MemoryRouter>
  )
}
