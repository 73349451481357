import React from 'react'
import FacilitiesNewFeature from './FacilitiesNewFeature'
import { MemoryRouter } from 'react-router-dom'
import { mount } from 'enzyme'
import MockAuthProvider from '../../providers/MockAuthProvider'

describe('FacilitiesNewFeature', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter>
          <MockAuthProvider>
            <FacilitiesNewFeature />
          </MockAuthProvider>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
