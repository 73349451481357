import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

function classesForColor(color) {
  let activeColor, baseColor, hoverColor

  if (color === 'white') {
    baseColor = 'text-white'
    activeColor = 'text-gray-400'
    hoverColor = 'hover:text-gray-200'
  } else if (color === 'black') {
    baseColor = 'text-black'
    activeColor = 'text-gray-800'
    hoverColor = 'hover:text-gray-600'
  } else if (color === 'red') {
    activeColor = 'text-red-700'
    baseColor = 'text-red-500'
    hoverColor = 'hover:text-red-300'
  } else if (color === 'purple') {
    activeColor = 'text-purple-700'
    baseColor = 'text-purple-500'
    hoverColor = 'hover:text-purple-300'
  } else if (color === 'yellow') {
    activeColor = 'text-yellow-700'
    baseColor = 'text-yellow-500'
    hoverColor = 'hover:text-yellow-300'
  } else if (color === 'teal') {
    activeColor = 'text-teal-700'
    baseColor = 'text-teal-500'
    hoverColor = 'hover:text-teal-300'
  } else if (color === 'pink') {
    activeColor = 'text-pink-700'
    baseColor = 'text-pink-500'
    hoverColor = 'hover:text-pink-300'
  } else if (color === 'blue') {
    activeColor = 'text-blue-700'
    baseColor = 'text-blue-500'
    hoverColor = 'hover:text-blue-300'
  } else if (color === 'orange') {
    activeColor = 'text-orange-700'
    baseColor = 'text-orange-500'
    hoverColor = 'hover:text-orange-300'
  } else if (color === 'gray') {
    activeColor = 'text-gray-700'
    baseColor = 'text-gray-500'
    hoverColor = 'hover:text-gray-300'
  } else if (color === 'green') {
    activeColor = 'text-green-700'
    baseColor = 'text-green-500'
    hoverColor = 'hover:text-green-300'
  }

  return { activeColor, baseColor, hoverColor }
}

function NavBarLink({ to, children, color }) {
  const { activeColor, baseColor, hoverColor } = classesForColor(color)
  return (
    <NavLink
      exact
      activeClassName={`${activeColor}`}
      className={`mr-6 last:mr-0 font-medium ${baseColor} ${hoverColor}`}
      to={to}
    >
      {children}
    </NavLink>
  )
}

NavBarLink.colors = [
  'red',
  'purple',
  'yellow',
  'teal',
  'pink',
  'blue',
  'orange',
  'gray',
  'white',
  'black',
  'green',
]

NavBarLink.defaultProps = {
  color: 'white',
}

NavBarLink.propTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  color: PropTypes.oneOf(NavBarLink.colors),
}

export default NavBarLink
