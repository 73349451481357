import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

import FeatureInterestBanner from './FeatureInterestBanner'
import MockAuthProvider from '../../../providers/MockAuthProvider'

export default {
  title: 'Leevo UI/FeatureInterestBanner',
  component: FeatureInterestBanner,
}

export function featureInterestBanner() {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onGet().reply(404)
  mockAxios.onPost().reply(200)

  return (
    <MockAuthProvider currentUser={{ coach: true }}>
      <FeatureInterestBanner />
    </MockAuthProvider>
  )
}
