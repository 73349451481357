import React from 'react'
import AddressInput from './AddressInput'
import { shallow, mount } from 'enzyme'

describe('AddressInput', () => {
  let wrapper, mockOnChange
  beforeEach(() => {
    mockOnChange = jest.fn()
    wrapper = shallow(
      <AddressInput id="id-foo" type="text" onChange={mockOnChange} />
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders className on topmost div', () => {
    wrapper.setProps({ className: 'bar' })
    expect(wrapper.first('div')).toHaveClassName('bar')
  })

  describe('helpText', () => {
    test('is rendered when provided', () => {
      wrapper.setProps({ helpText: 'foo' })
      expect(wrapper).toIncludeText('foo')
    })
  })

  describe('label', () => {
    test('is rendered within a label element', () => {
      wrapper.setProps({ label: 'foo' })
      expect(wrapper.find('label')).toHaveText('foo')
    })

    test('does not render a label element if not provided', () => {
      expect(wrapper.find('label')).not.toExist()
    })
  })

  describe('errorMessage', () => {
    test('is present when provided', () => {
      wrapper.setProps({ errorMessage: 'foo' })
      expect(wrapper).toIncludeText('foo')
    })

    test('changes color of label when provided', () => {
      wrapper.setProps({ errorMessage: 'foo', label: 'some label' })
      expect(wrapper.find('label')).toHaveClassName('text-red-500')
    })
  })

  describe('value', () => {})

  describe('onChange', () => {
    beforeEach(() => {
      wrapper = mount(
        <AddressInput id="id-foo" type="text" onChange={mockOnChange} />
      )
    })

    it('is called when line1 changes', () => {
      wrapper
        .find('input[data-cy="address-line1-input"]')
        .hostNodes()
        .simulate('change', { target: { value: 'foo' } })
      expect(mockOnChange).toHaveBeenCalled()
    })

    it('is called when line2 changes', () => {
      wrapper
        .find('input[data-cy="address-line2-input"]')
        .hostNodes()
        .simulate('change', { target: { value: 'foo' } })
      expect(mockOnChange).toHaveBeenCalled()
    })

    it('is called when city changes', () => {
      wrapper
        .find('input[data-cy="city-input"]')
        .hostNodes()
        .simulate('change', { target: { value: 'foo' } })
      expect(mockOnChange).toHaveBeenCalled()
    })

    it('is called when post code changes', () => {
      wrapper
        .find('input[data-cy="postal-code-input"]')
        .hostNodes()
        .simulate('change', { target: { value: 'foo' } })
      expect(mockOnChange).toHaveBeenCalled()
    })
  })
})
