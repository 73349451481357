import React from 'react'
import FaqSection from './FaqSection'

export default {
  title: 'Pricing Feature/Components/FaqSection',
  component: FaqSection,
}

export function faqSection(args) {
  return <FaqSection {...args} />
}
