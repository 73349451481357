import React from 'react'
import { MemoryRouter } from 'react-router-dom'
import KlassDatePicker from './KlassDatePicker'
import { mount } from 'enzyme'
import moment from 'moment'
import { act } from 'react-dom/test-utils'
import MockDate from 'mockdate'

describe('KlassDatePicker', () => {
  let wrapper
  MockDate.set('2019-12-30T00:00:00-0000')
  const monday = moment().day(1)
  const onDateSelected = jest.fn()

  beforeEach(async () => {
    await act(async () => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/facilities/1/calendar']}>
          <KlassDatePicker
            selectedDate={monday}
            availableDays={['Sunday', 'Monday', 'Tuesday']}
            onDateSelected={onDateSelected}
          />
        </MemoryRouter>
      )
    })
    wrapper.update()
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('Initial State - Selected Date shown with purple bg', () => {
    expect(wrapper.find('button.bg-purple-600').text()).toEqual(
      monday.format('D')
    )
  })

  test('State Management - Available dates are loaded initially based on selected date passed', () => {
    // non available dates are greyed out (but are still clickable)
    expect(wrapper.find('.text-gray-400').length).toEqual(4)
    expect(wrapper.find('button[disabled=true]').length).toEqual(0)
  })

  test('State Management - When day changes, onDateSelected fires', () => {
    onDateSelected.mockClear
    wrapper.find('[data-cy="tuesday-date"] > button').simulate('click')

    expect(onDateSelected).toHaveBeenCalled()
  })

  test('State Management - When week changes, onDateSelected fires', () => {
    onDateSelected.mockClear
    wrapper.find('[data-cy="next-week-button"]').last().simulate('click')

    expect(onDateSelected).toHaveBeenCalled()
  })
})
