import React from 'react'
import { mount } from 'enzyme'

import ProfileForm from './ProfileForm'

describe('ProfileForm', () => {
  let wrapper, mockOnSubmit

  beforeEach(() => {
    mockOnSubmit = jest.fn()
    wrapper = mount(<ProfileForm id={1} onSubmit={mockOnSubmit} />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders a form', () => {
    expect(wrapper.find('form').length).toEqual(1)
  })

  describe('email', () => {
    test('produces an error when entered and not valid', () => {
      wrapper.find('input[name="email"]').simulate('change', {
        target: { value: 'foo' },
      })
      wrapper.find('form').simulate('submit')
      expect(wrapper).toIncludeText('must be valid')
    })

    test('produces no error when valid', () => {
      wrapper
        .find('input[name="email"]')
        .simulate('focus')
        .simulate('change', {
          target: { value: 'foo@bar.com' },
        })
        .simulate('blur')
      expect(wrapper).not.toIncludeText('must be valid')
    })
  })

  describe('password', () => {
    test('produces an error when password and password confirmation do not match', () => {
      wrapper
        .find('input[name="password"]')
        .simulate('focus')
        .simulate('change', {
          target: { value: 'password' },
        })
        .simulate('blur')
      wrapper
        .find('input[name="passwordConfirmation"]')
        .simulate('focus')
        .simulate('change', {
          target: { value: 'password1' },
        })
        .simulate('blur')
      expect(wrapper).toIncludeText('Passwords do not match')
    })

    test('does not produce an error when password and password confirmation match', () => {
      wrapper
        .find('input[name="password"]')
        .simulate('focus')
        .simulate('change', {
          target: { value: 'password' },
        })
        .simulate('blur')
      wrapper
        .find('input[name="passwordConfirmation"]')
        .simulate('focus')
        .simulate('change', {
          target: { value: 'password' },
        })
        .simulate('blur')
      expect(wrapper).not.toIncludeText('Passwords do not match')
    })
  })

  describe('submission', () => {
    beforeEach(async () => {
      wrapper.find('input[name="fullName"]').simulate('change', {
        target: { value: 'foo' },
      })
      wrapper.find('input[name="preferredName"]').simulate('change', {
        target: { value: 'bar' },
      })
      wrapper.find('input[name="email"]').simulate('change', {
        target: { value: 'foo@bar.com' },
      })
      wrapper.find('input[name="password"]').simulate('change', {
        target: { value: 'password' },
      })
      wrapper.find('input[name="passwordConfirmation"]').simulate('change', {
        target: { value: 'password' },
      })
      wrapper.find('form').simulate('submit')
    })

    // Could not figure out a way to update phone input, so couldn't get this test to work.
    test.todo('calls the onSubmit function')
    // () => {
    //     expect(mockOnSubmit).toHaveBeenCalled()
    //   })
  })
})
