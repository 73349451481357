import React from 'react'
import { Form, Field } from 'react-final-form'
import PropTypes from 'prop-types'

import { isEmail } from '../../../utilities/validators'

import Modal from '../Modal/Modal'
import Button from '../Button/Button'
import Input from '../Input/Input'

function ForgotPasswordModal({
  errorMessage,
  onForgotPasswordSubmit,
  successMessage,
  open,
  initialEmail: email,
  onClickBackground,
  onClickClose,
}) {
  return (
    <Modal
      open={open}
      onClickBackground={onClickBackground}
      onClickClose={onClickClose}
      header="Forgot password?"
    >
      <Form
        initialValues={{ email }}
        onSubmit={onForgotPasswordSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="pb-4 text-sm text-gray-700">
                No problem! We will send you a password reset link
              </div>
              <Field name="email" validate={isEmail}>
                {({ input, meta: { active, error, submitFailed } }) => (
                  <Input
                    {...input}
                    id="email"
                    className="mb-4"
                    autoFocus
                    type="text"
                    label="Your email"
                    placeholder="Email"
                    errorMessage={!active && error && submitFailed ? error : ''}
                  />
                )}
              </Field>
              {errorMessage && (
                <div
                  data-cy="error-message"
                  className="mb-4 text-sm font-medium text-red-600"
                >
                  {errorMessage}
                </div>
              )}
              {/* FIXME: Should be using notification component here, which will disappear. */}
              {successMessage && (
                <div
                  data-cy="success-message"
                  className="mb-4 text-sm font-medium text-purple-800"
                >
                  {successMessage}
                </div>
              )}
              <div className="flex items-center justify-end">
                {!successMessage && (
                  <Button
                    data-cy="submit"
                    label="Submit"
                    disabled={submitting}
                    type="submit"
                    loading={submitting}
                  />
                )}
              </div>
            </form>
          )
        }}
      />
    </Modal>
  )
}

ForgotPasswordModal.defaultProps = {
  open: true,
}

ForgotPasswordModal.propTypes = {
  errorMessage: PropTypes.string,
  onForgotPasswordSubmit: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
  open: PropTypes.bool,
  onClickBackground: PropTypes.func,
  onClickClose: PropTypes.func,
  initialEmail: PropTypes.string,
}

export default ForgotPasswordModal
