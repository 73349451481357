import React from 'react'

import TermlyEmbed from '../leevo_ui/TermlyEmbed/TermlyEmbed'

function DisclaimerFeature() {
  return (
    <TermlyEmbed
      title="Disclaimer"
      termlyDocumentId="c01d44af-d382-4453-9b23-2a90ef4d92c0"
    />
  )
}

export default DisclaimerFeature
