import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { Field } from 'react-final-form'
import axios from 'axios'
import normalize from 'json-api-normalizer'
import Jsona from 'jsona'

import SelectInput from '../../leevo_ui/SelectInput/SelectInput'
import { presence } from '../../../utilities/validators'

const jsonApiDataFormatter = new Jsona()

function SkillLevelField({
  className,
  name,
  helpText = `If you don't know, we will auto-place ${name} into a level based on their age.`,
}) {
  const [options, setOptions] = useState([])
  const params = useParams()
  const { facility_id } = params

  useEffect(() => {
    let mounted = true

    axios
      .get(`/api/facilities/${facility_id}/skill_levels`)
      .then((response) => {
        if (!mounted) return

        const skillLevels = jsonApiDataFormatter.denormalizeReduxObject({
          reduxObject: normalize(response.data),
          entityType: 'skillLevel',
        })
        const options = skillLevels.map(({ name, id }) => ({
          label: name,
          value: id,
        }))
        options.unshift({ label: "I don't know", value: '-1' })
        setOptions(options)
      })

    return () => (mounted = false)
  }, []) /* eslint-disable-line */ // FIXME

  return (
    <Field name="skillLevel" validate={presence}>
      {({ input, meta: { active, error, submitFailed } }) => (
        <SelectInput
          {...input}
          className={className}
          id="skillLevel"
          label={`What is the level ${name} is working on?`}
          helpText={helpText}
          placeholder="Select Level"
          options={options}
          errorMessage={!active && error && submitFailed ? error : ''}
        />
      )}
    </Field>
  )
}

SkillLevelField.defaultProps = {
  name: 'the student',
}

SkillLevelField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  helpText: PropTypes.string,
}

export default SkillLevelField
