import React, { useState } from 'react'
import { Switch } from '@headlessui/react'

import ApplicationLayout from './ApplicationLayout'
import MockFacilityProvider from '../../../providers/MockFacilityProvider'

export default {
  title: 'Leevo UI/Layouts/ApplicationLayout',
  component: ApplicationLayout,
  args: {
    className: 'm-4',
    isAuthLoading: true,
    superuser: false,
    onClose: () => console.log('closing') /* eslint-disable-line no-console */,
  },
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const tabs = [
  { name: 'General', href: '#', current: true },
  { name: 'Password', href: '#', current: false },
  { name: 'Notifications', href: '#', current: false },
  { name: 'Plan', href: '#', current: false },
  { name: 'Billing', href: '#', current: false },
  { name: 'Team Members', href: '#', current: false },
]

export function ApplicationLayoutStory(args) {
  const { isAuthLoading, superuser } = args
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] = useState(true)
  const [
    autoUpdateApplicantDataEnabled,
    setAutoUpdateApplicantDataEnabled,
  ] = useState(false)

  return (
    <MockFacilityProvider
      authOptions={{
        isLoading: isAuthLoading,
        currentUser: { superuser: superuser, facilitiesUsers: [] },
      }}
    >
      <ApplicationLayout>
        {/* {Array.from({ length: 3 }, () => (
        <p className="m-4">{faker.lorem.paragraphs(1)}</p>
      ))} */}
        <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
          <div className="pt-10 pb-16">
            <div className="px-4 sm:px-6 md:px-0">
              <h1 className="text-3xl font-extrabold text-gray-900">
                Settings
              </h1>
            </div>
            <div className="px-4 sm:px-6 md:px-0">
              <div className="py-6">
                {/* Tabs */}
                <div className="lg:hidden">
                  <label htmlFor="selected-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="selected-tab"
                    name="selected-tab"
                    className="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                    defaultValue={tabs.find((tab) => tab.current).name}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden lg:block">
                  <div className="border-b border-gray-200">
                    <nav className="flex -mb-px space-x-8">
                      {tabs.map((tab) => (
                        <a
                          key={tab.name}
                          href={tab.href}
                          className={classNames(
                            tab.current
                              ? 'border-purple-500 text-purple-600'
                              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                          )}
                        >
                          {tab.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>

                {/* Description list with inline editing */}
                <div className="mt-10 divide-y divide-gray-200">
                  <div className="space-y-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Profile
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                      This information will be displayed publicly so be careful
                      what you share.
                    </p>
                  </div>
                  <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Name
                        </dt>
                        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow">Chelsea Hagon</span>
                          <span className="flex-shrink-0 ml-4">
                            <button
                              type="button"
                              className="font-medium text-purple-600 bg-white rounded-md hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                            >
                              Update
                            </button>
                          </span>
                        </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt className="text-sm font-medium text-gray-500">
                          Photo
                        </dt>
                        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow">
                            <img
                              className="w-8 h-8 rounded-full"
                              src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                              alt=""
                            />
                          </span>
                          <span className="flex items-start flex-shrink-0 ml-4 space-x-4">
                            <button
                              type="button"
                              className="font-medium text-purple-600 bg-white rounded-md hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                            >
                              Update
                            </button>
                            <span className="text-gray-300" aria-hidden="true">
                              |
                            </span>
                            <button
                              type="button"
                              className="font-medium text-purple-600 bg-white rounded-md hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                            >
                              Remove
                            </button>
                          </span>
                        </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt className="text-sm font-medium text-gray-500">
                          Email
                        </dt>
                        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow">
                            chelsea.hagon@example.com
                          </span>
                          <span className="flex-shrink-0 ml-4">
                            <button
                              type="button"
                              className="font-medium text-purple-600 bg-white rounded-md hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                            >
                              Update
                            </button>
                          </span>
                        </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                        <dt className="text-sm font-medium text-gray-500">
                          Job title
                        </dt>
                        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow">
                            Human Resources Manager
                          </span>
                          <span className="flex-shrink-0 ml-4">
                            <button
                              type="button"
                              className="font-medium text-purple-600 bg-white rounded-md hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                            >
                              Update
                            </button>
                          </span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>

                <div className="mt-10 divide-y divide-gray-200">
                  <div className="space-y-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Account
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                      Manage how information is displayed on your account.
                    </p>
                  </div>
                  <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Language
                        </dt>
                        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow">English</span>
                          <span className="flex-shrink-0 ml-4">
                            <button
                              type="button"
                              className="font-medium text-purple-600 bg-white rounded-md hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                            >
                              Update
                            </button>
                          </span>
                        </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt className="text-sm font-medium text-gray-500">
                          Date format
                        </dt>
                        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow">DD-MM-YYYY</span>
                          <span className="flex items-start flex-shrink-0 ml-4 space-x-4">
                            <button
                              type="button"
                              className="font-medium text-purple-600 bg-white rounded-md hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                            >
                              Update
                            </button>
                            <span className="text-gray-300" aria-hidden="true">
                              |
                            </span>
                            <button
                              type="button"
                              className="font-medium text-purple-600 bg-white rounded-md hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                            >
                              Remove
                            </button>
                          </span>
                        </dd>
                      </div>
                      <Switch.Group
                        as="div"
                        className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5"
                      >
                        <Switch.Label
                          as="dt"
                          className="text-sm font-medium text-gray-500"
                          passive
                        >
                          Automatic timezone
                        </Switch.Label>
                        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <Switch
                            checked={automaticTimezoneEnabled}
                            onChange={setAutomaticTimezoneEnabled}
                            className={classNames(
                              automaticTimezoneEnabled
                                ? 'bg-purple-600'
                                : 'bg-gray-200',
                              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-auto'
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                automaticTimezoneEnabled
                                  ? 'translate-x-5'
                                  : 'translate-x-0',
                                'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                              )}
                            />
                          </Switch>
                        </dd>
                      </Switch.Group>
                      <Switch.Group
                        as="div"
                        className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200"
                      >
                        <Switch.Label
                          as="dt"
                          className="text-sm font-medium text-gray-500"
                          passive
                        >
                          Auto-update applicant data
                        </Switch.Label>
                        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <Switch
                            checked={autoUpdateApplicantDataEnabled}
                            onChange={setAutoUpdateApplicantDataEnabled}
                            className={classNames(
                              autoUpdateApplicantDataEnabled
                                ? 'bg-purple-600'
                                : 'bg-gray-200',
                              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-auto'
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                autoUpdateApplicantDataEnabled
                                  ? 'translate-x-5'
                                  : 'translate-x-0',
                                'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                              )}
                            />
                          </Switch>
                        </dd>
                      </Switch.Group>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ApplicationLayout>
    </MockFacilityProvider>
  )
}
