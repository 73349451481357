import React from 'react'
import PropTypes from 'prop-types'
import RegistrationLayout from '../RegistrationLayout/RegistrationLayout'
import RegistrationDashboardSection from '../RegistrationDashboardSection/RegistrationDashboardSection'

function RegistrationDashboard({ sections, title }) {
  return (
    <RegistrationLayout headerColor="purple" title={title} className="m-4">
      <div className="max-w-md pt-4 m-auto">
        {sections.map((sectionProps) => (
          <RegistrationDashboardSection
            key={sectionProps.title}
            {...sectionProps}
          />
        ))}
      </div>
    </RegistrationLayout>
  )
}

RegistrationDashboard.defaultProps = {
  title: 'Next steps...',
}

RegistrationDashboard.propTypes = {
  title: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape(RegistrationDashboardSection.propTypes)
  ).isRequired,
}

export default RegistrationDashboard
