import React, { useState } from 'react'
import Transition from './Transition'
import Button from '../../Button/Button'

export default {
  title: 'Leevo UI/Transitions',
  component: Transition,
}

export function Opacity() {
  const [show, setShow] = useState(true)
  return (
    <div className="flex flex-col items-center justify-center m-5">
      <Button label="Toggle" onClick={() => setShow(!show)} />
      <Transition
        show={show}
        onClick={() => setShow(false)}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="p-5 transition-opacity"
      >
        <div className="w-20 h-20 m-5 bg-purple-600 border-2 border-purple-900 rounded-full"></div>
      </Transition>
    </div>
  )
}

export function Scale() {
  const [show, setShow] = useState(false)
  return (
    <div className="flex flex-col items-center justify-center m-5">
      <Button label="Toggle" onClick={() => setShow(!show)} />
      <Transition
        show={show}
        onClick={() => setShow(false)}
        enter="ease-out duration-300"
        enterFrom="scale-y-0 scale-x-0"
        enterTo="scale-y-100 scale-x-100"
        leave="ease-in duration-200"
        leaveFrom="scale-y-100 scale-x-100"
        leaveTo="scale-y-0 scale-x-0"
        className="transition-transform transform"
      >
        <div className="w-20 h-20 m-5 bg-purple-600 border-2 border-purple-900 rounded-full"></div>
      </Transition>
    </div>
  )
}

export function Position() {
  const [show, setShow] = useState(false)
  return (
    <div className="flex flex-col items-center justify-center w-full h-full m-5">
      <Button label="Toggle" onClick={() => setShow(!show)} />
      <Transition
        show={show}
        onClick={() => setShow(false)}
        enter="ease-out duration-300"
        enterFrom="-translate-x-1/2"
        enterTo="translate-x-0"
        leave="ease-in duration-200"
        leaveFrom="translate-x-0"
        leaveTo="-translate-y-full"
        className="transition-transform transform"
      >
        <div className="w-20 h-20 m-5 bg-purple-600 border-2 border-purple-900 rounded-full"></div>
      </Transition>
    </div>
  )
}

export function Skew() {
  const [show, setShow] = useState(false)
  return (
    <div className="flex flex-col items-center justify-center m-5">
      <Button label="Toggle" onClick={() => setShow(!show)} />
      <Transition
        show={show}
        onClick={() => setShow(false)}
        enter="ease-out duration-300"
        enterFrom="skew-x-0"
        enterTo="skew-x-6"
        leave="ease-in duration-200"
        leaveFrom="skew-x-0"
        leaveTo="-skew-x-6"
        className="transition-transform transform"
      >
        <div className="w-20 h-20 m-5 bg-purple-600 border-2 border-purple-900 rounded-sm"></div>
      </Transition>
    </div>
  )
}

export function Rotate() {
  const [show, setShow] = useState(false)
  return (
    <div className="flex flex-col items-center justify-center m-5">
      <Button label="Toggle" onClick={() => setShow(!show)} />
      <Transition
        show={show}
        onClick={() => setShow(false)}
        enter="ease-out duration-300"
        enterFrom="rotate-0"
        enterTo="rotate-90"
        leave="ease-in duration-200"
        leaveFrom="rotate-0"
        leaveTo="rotate-180"
        className="transition-transform transform"
      >
        <div className="w-20 h-20 m-5 bg-purple-600 border-2 border-purple-900 rounded-sm"></div>
      </Transition>
    </div>
  )
}
