import React from 'react'
import SkillLegend from './SkillLegend'

export default {
  title: 'Leevo UI/SkillLegend',
  component: SkillLegend,
  args: {
    className: '',
  },
}

export function skillLegend({ className }) {
  return (
    <div className={className}>
      <SkillLegend />
    </div>
  )
}
