import React from 'react'
import faker from 'faker'

import GuardianManager from './GuardianManager'

export default {
  title: 'Leevo UI/Student Edit Form/GuardianManager',
  component: GuardianManager,
  args: {
    className: 'm-4',
    guardiansCount: 1,
  },
  argTypes: {
    guardiansCount: { control: { type: 'range', min: 0, max: 20 } },
  },
}

export function guardianManager({ guardiansCount, ...rest }) {
  function guardians(numGuardians) {
    const guardians = []
    for (let i = 0; i < numGuardians; i++) {
      guardians.push({
        id: i + 1,
        preferredName: faker.name.firstName(),
        fullName: faker.name.findName(),
        email: faker.internet.email(),
        phone: '+13478038847',
        pending: Math.random() >= 0.5,
        cloudinaryPhotoPublicId:
          Math.random() >= 0.5 ? 'qrz0yfenrunmvcb0epwa' : '',
      })
    }
    return guardians
  }

  return <GuardianManager {...rest} guardians={guardians(guardiansCount)} />
}
