import React from 'react'
import { mount } from 'enzyme'

import MockFacilityProvider from '../../providers/MockFacilityProvider'
import { MemoryRouter, Route } from 'react-router-dom'

import FacilitiesSkateRentalsIndex from './FacilitiesSkateRentalsIndex'

describe('FacilitiesSkateRentalsIndex', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MockFacilityProvider>
          <MemoryRouter initialEntries={['/FacilitiesSkateRentalsIndex']}>
            <Route path="/FacilitiesSkateRentalsIndex">
              <FacilitiesSkateRentalsIndex />
            </Route>
          </MemoryRouter>
        </MockFacilityProvider>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
