import React from 'react'

import PaymentConfirmation from './PaymentConfirmation'

export default {
  title: 'Registration Feature/PaymentConfirmation',
  component: PaymentConfirmation,
}

export function paymentConfirmation() {
  return (
    <PaymentConfirmation
      lineItems={[{ id: 1, price: 1000, description: 'Foobar - Barbaz' }]}
      total={1000}
      couponCodeAmount={10}
      couponCodePromotionAmount="10%"
    />
  )
}
