import React from 'react'
import { shallow, mount } from 'enzyme'

import TeamMemberListItem from './TeamMemberListItem'

describe('TeamMemberListItem', () => {
  let wrapper
  beforeEach(() => {
    wrapper = shallow(<TeamMemberListItem id={1} />)
  })

  it('renders', () => {
    expect(wrapper).toExist()
  })

  describe('id', () => {
    test('is required', () => {
      function renderWrapper() {
        shallow(<TeamMemberListItem />)
      }
      expect(renderWrapper).toThrowError('`id` is marked as required')
    })
  })

  describe('when pending', () => {
    beforeEach(() => {
      wrapper = mount(
        <TeamMemberListItem
          id={1}
          fullName="Jane Doe"
          email="jane@doe.com"
          pending
        />
      )
    })

    it('renders the email with pending notice', () => {
      expect(wrapper.html()).toContain('jane@doe.com (pending)')
    })

    it('renders with subdued text', () => {
      expect(wrapper.find('.text-gray-500')).toExist()
    })
  })

  describe('when not pending', () => {
    beforeEach(() => {
      wrapper = mount(
        <TeamMemberListItem id={1} fullName="Jane Doe" email="jane@doe.com" />
      )
    })

    it('renders the full name', () => {
      expect(wrapper.html()).toContain('Jane Doe')
    })

    it('renders with unsubdued text', () => {
      expect(wrapper.find('.text-gray-800')).toExist()
    })
  })
})
