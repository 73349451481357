import React from 'react'
import { action } from '@storybook/addon-actions'

import RegistrationDashboardSection from './RegistrationDashboardSection'
import { boolean, text } from '@storybook/addon-knobs'

export default {
  title:
    'Registration Feature/RegistrationDashboard/RegistrationDashboardSection',
  component: RegistrationDashboardSection,
}

export function registrationDashboardSection() {
  const isShowingGroup = boolean('Show group of sections?', true)
  const isDisabled = boolean('Disabled?', false)
  const isShowingHeaderAction = boolean('Show Header Action?', true)
  const isShowingNextStepAction = boolean('Show Next Step Action?', true)
  const isCompleted = boolean('Completed?', true)
  const isShowingChildren = boolean('Show children?', true)
  const headerAction = {
    label: text('Header Action Label', 'Edit'),
    onClick: action('Clicked Header Action'),
  }
  const nextStepAction = {
    label: text('Next Step Action Label', 'Add a student'),
    onClick: action('Clicked Next Step Action'),
  }

  return (
    <div className="m-6">
      <RegistrationDashboardSection
        title={text('Section 1 Title', 'Section 1')}
        completed={isCompleted}
        headerAction={isShowingHeaderAction ? headerAction : undefined}
        nextStepAction={isShowingNextStepAction ? nextStepAction : undefined}
        disabled={isDisabled}
      >
        {isShowingChildren && (
          <div>
            <div>Some Child</div>
            <div>Some Child</div>
            <div>Some Child</div>
            <div>Some Child</div>
          </div>
        )}
      </RegistrationDashboardSection>
      {isShowingGroup && (
        <>
          <RegistrationDashboardSection
            title="Section 2"
            completed
            headerAction={{
              label: 'Edit',
              onClick: action('Clicked Second Header Action'),
            }}
          />
          <RegistrationDashboardSection title="Section 3" disabled />
        </>
      )}
    </div>
  )
}
