import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { get as _get } from 'lodash'

import StudentEditForm from '../leevo_ui/StudentEditForm/StudentEditForm'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import useFacility from '../../utilities/hooks/useFacility'
import { requestCreator } from '../../utilities/requests'

function StudentsNewFeature() {
  const history = useHistory()
  const { facility } = useFacility()
  const { facility_id: facilityId } = useParams()
  const { post: saveStudent, cancel: cancelStudentRequests } = requestCreator()

  useEffect(() => cancelStudentRequests)

  function onSubmit(user) {
    let message = 'Changes saved!'
    return saveStudent(`/api/facilities/${facilityId}/users`, { user })
      .then(() =>
        history.push({
          pathname: `/facilities/${facilityId}/students`,
          state: {
            notification: {
              message: 'Student created successfully!',
              color: 'green',
            },
          },
        })
      )
      .catch((error) => {
        if (!axios.isCancel(error)) {
          message =
            _get(error, 'response.data.error') ||
            'We were unable to save your changes.'
          history.replace({
            state: {
              notification: {
                message,
                color: 'red',
              },
            },
          })
        }
      })
  }

  return (
    <ApplicationLayout>
      <StudentEditForm
        {...facility.curriculums[0]}
        id={null}
        facilitySupportsSkating={facility.supportsSkating}
        facilityProvidesFreeRentalSkates={facility.providesFreeRentalSkates}
        facilityId={facility.id}
        onSubmit={onSubmit}
      />
    </ApplicationLayout>
  )
}

export default StudentsNewFeature
