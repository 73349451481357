import React from 'react'
import CardFormSection from './CardFormSection'
import { shallow } from 'enzyme'

describe('CardFormSection', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(<CardFormSection />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
