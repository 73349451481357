import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Switch, useRouteMatch, useHistory } from 'react-router-dom'
import { Form } from 'react-final-form'
import { get } from 'lodash'

import GenderField from '../GenderField/GenderField'
import BirthdateField from '../BirthdateField/BirthdateField'
import AddressField from '../AddressField/AddressField'
import HasLTSNumberField from '../HasLTSNumberField/HasLTSNumberField'
import LTSNumberField from '../LTSNumberField/LTSNumberField'
import RentalSkatesFields from '../RentalSkatesFields/RentalSkatesFields'
import NeedsSkateRentalField from '../NeedsSkateRentalField/NeedsSkateRentalField'
import SkillLevelField from '../SkillLevelField/SkillLevelField'
import RegistrationLayout from '../RegistrationLayout/RegistrationLayout'
import NameFields from '../../leevo_ui/NameFields/NameFields'
import Notification from '../../leevo_ui/Notification/Notification'
import LoadingPage from '../../leevo_ui/LoadingPage/LoadingPage'
import SlideInRoute from '../../leevo_ui/Routes/SlideInRoute/SlideInRoute'
import { requestCreator } from '../../../utilities/requests'
import useAuth from '../../../utilities/hooks/useAuth'
import useFacility from '../../../utilities/hooks/useFacility'

// FIXME: Setting autoFocus on any of the fields causes an issue where
// the slide in animation does not work. Perhaps this can be fixed with a
// technique like this ? http://medium.com/onfido-tech/animations-with-react-router-8e97222e25e1#25cd

const { post: saveStudent } = requestCreator()
function AddStudents({ onStudentAdded }) {
  let { url, path, params } = useRouteMatch()
  let { facility } = useFacility()
  const [submitting, setSubmitting] = useState(false)
  const history = useHistory()
  const [fields, setFields] = useState({
    fullName: null,
    preferredName: null,
    gender: null,
    birthdate: null,
    hasLTSNumber: null,
    ltsNumber: null,
    address: {},
    skateRental: {},
  })
  const [notification, setNotification] = useState({
    show: false,
    message: null,
  })
  const [progressPercentage, setProgressPercentage] = useState(0)
  const { isSignedIn, isLoading, currentUser } = useAuth()
  let stepCount = 0

  function onSubmit(values) {
    setFields({ ...fields, ...values })
    return Promise.resolve()
  }

  function handleUpdatedPath() {
    if (!isSignedIn)
      history.replace(`/facilities/${params.facility_id}/register`)
  }

  function step(url, completed) {
    return { url, completed, ordinal: ++stepCount }
  }

  function handleUpdatedFields() {
    const {
      fullName,
      preferredName,
      gender,
      birthdate,
      address,
      skateRental: { skateType, size, active: needsSkates },
      hasLTSNumber,
      ltsNumber,
      skillLevel,
    } = fields
    const providesFreeSkateRentals = get(facility, 'providesFreeRentalSkates')
    const requiresAddress = get(facility, 'curriculums[0].requiresAddress')
    const requiresBirthdate = get(facility, 'curriculums[0].requiresBirthdate')
    const requiresGender = get(facility, 'curriculums[0].requiresGender')
    const requiresLtsNumber = get(facility, 'curriculums[0].requiresLtsNumber')

    const steps = []
    steps.push(step(`${url}`, fullName && preferredName))
    if (requiresGender) steps.push(step(`${url}/gender`, !!gender))
    if (requiresBirthdate) steps.push(step(`${url}/birthdate`, !!birthdate))

    if (requiresAddress) {
      steps.push(step(`${url}/address`, Object.keys(address).length > 0))
    }

    if (providesFreeSkateRentals) {
      steps.push(step(`${url}/check-rental-skates`, needsSkates))
      if (needsSkates === 'yes') {
        steps.push(step(`${url}/select-rental-skates`, skateType && size))
      }
    }

    if (requiresLtsNumber) {
      steps.push(step(`${url}/check-lts`, hasLTSNumber))
      if (hasLTSNumber === 'yes') {
        steps.push(step(`${url}/enter-lts`, !!ltsNumber))
        steps.push(step(`${url}/select-skill-level`, !!skillLevel))
      }
    }

    const activeStep = steps.find((s) => !s.completed)
    const hasAllInfo = !activeStep

    setNotification({ ...notification, show: false })

    if (hasAllInfo) {
      setSubmitting(true)
      const createParams = {
        user: {
          ...fields,
          guardians: [{ email: currentUser.email }],
        },
      }
      if (Object.keys(fields.skateRental).length > 0) {
        createParams.user.skateRentalAttributes = {
          ...fields.skateRental,
          active: fields.skateRental.active === 'yes',
        }
      }

      saveStudent(
        `/api/facilities/${params.facility_id}/users`,
        createParams
      ).then(onStudentAdded)
    } else if (!history.location.pathname.includes('students')) {
      history.push(url)
    } else if (activeStep) {
      history.push(activeStep.url)
      setProgressPercentage(activeStep.ordinal / steps.length)
    } else {
      history.push(`/facilities/${params.facility_id}/register`)
    }
  }

  //FIXME
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(handleUpdatedPath, [history.location.pathname, isSignedIn])
  useEffect(handleUpdatedFields, [fields])
  /* eslint-enable */

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <RegistrationLayout
          title="Register Your Students"
          progressPercentage={progressPercentage}
          onClickNext={handleSubmit}
          isNextDisabled={submitting}
          className="m-4"
        >
          <form onSubmit={handleSubmit} className="max-w-md pt-4 m-auto">
            {isLoading && <LoadingPage />}
            <Switch>
              <SlideInRoute
                path={`${path}/gender`}
                render={() => <GenderField />}
              />
              <SlideInRoute
                path={`${path}/birthdate`}
                render={() => <BirthdateField />}
              />
              <SlideInRoute
                path={`${path}/address`}
                render={() => <AddressField />}
              />
              <SlideInRoute
                path={`${path}/check-rental-skates`}
                render={() => <NeedsSkateRentalField />}
              />
              <SlideInRoute
                path={`${path}/select-rental-skates`}
                render={() => <RentalSkatesFields />}
              />
              <SlideInRoute
                path={`${path}/check-lts`}
                render={() => <HasLTSNumberField />}
              />
              <SlideInRoute
                path={`${path}/enter-lts`}
                render={() => <LTSNumberField />}
              />
              <SlideInRoute
                path={`${path}/select-skill-level`}
                render={() => <SkillLevelField />}
              />
              <SlideInRoute
                path={`${path}`}
                render={() => (
                  <NameFields
                    fullNameLabel="What is this student's full name?"
                    preferredNameLabel="What does this student prefer to be called?"
                  />
                )}
              />
              <Redirect to={`${path}`} />
            </Switch>
            <input type="submit" className="hidden" />
          </form>
          <Notification
            show={notification.show}
            onDisappearTimeout={() =>
              setNotification({ ...notification, show: false })
            }
            color="red"
          >
            {notification.message}
          </Notification>
        </RegistrationLayout>
      )}
    />
  )
}

AddStudents.propTypes = {
  onStudentAdded: PropTypes.func.isRequired,
}

export default AddStudents
