import React from 'react'
import { action } from '@storybook/addon-actions'
import faker from 'faker'

import MultiSelectKlassPackage from './MultiSelectKlassPackage'

export default {
  title: 'Leevo UI/Klass Edit Form/MultiSelectKlassPackage',
  component: MultiSelectKlassPackage,
  args: {
    studentCount: 10,
    className: 'm-6',
  },
  argTypes: {
    studentCount: { control: { type: 'range', min: 0, max: 200 } },
  },
}

function generateStudents(numToGenerate) {
  return [...Array(numToGenerate)].map((_value, index) => ({
    id: index,
    fullName: faker.name.findName(),
    currentSkillLevel: { name: faker.company.bsBuzz() },
    selected: Math.random() > 0.5,
  }))
}

export function multiSelectKlassPackage({ studentCount, ...args }) {
  return (
    <MultiSelectKlassPackage
      {...args}
      value={generateStudents(studentCount)}
      onChange={action('Changed')}
    />
  )
}
