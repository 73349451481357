import React from 'react'
import { shallow } from 'enzyme'
import AboutFeature from './AboutFeature'
import MarketingNavBar from '../leevo_ui/NavBar/MarketingNavBar/MarketingNavBar'
import MarketingPageFooter from '../leevo_ui/MarketingPageFooter/MarketingPageFooter'

describe('AboutFeature', () => {
  let wrapper
  beforeEach(async () => {
    wrapper = shallow(<AboutFeature />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('properly sets the page title', () => {
    expect(wrapper.find('title')).toHaveText('About | Leevo')
  })

  test('renders a MarketingNavBar', () => {
    expect(wrapper.find(MarketingNavBar)).toExist()
  })

  test('renders the marketing page footer', () => {
    expect(wrapper.find(MarketingPageFooter)).toExist()
  })
})
