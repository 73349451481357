import React from 'react'
import AdminGuardiansIndex from './AdminGuardiansIndex'
import { MemoryRouter } from 'react-router-dom'

export default {
  title: 'Admin/AdminGuardiansIndex',
  component: AdminGuardiansIndex,
}

export function adminGuardiansIndex() {
  return (
    <MemoryRouter>
      <AdminGuardiansIndex />
    </MemoryRouter>
  )
}
