import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function Card({
  children,
  className,
  borderColor,
  title,
  onClick,
  interactive,
  active,
  ...rest
}) {
  const cardClassNames = classNames(
    className,
    'text-gray-800',
    'rounded-lg',
    'shadow',
    'bg-gray-50',
    'focus:outline-none',
    'focus:ring',
    'focus:ring-opacity-50',
    {
      'border-t-2': borderColor && borderColor !== 'none',
      'border-gray-400': borderColor === 'gray',
      'border-green-400': borderColor === 'green',
      'border-orange-400': borderColor === 'orange',
      'border-red-400': borderColor === 'red',
      'border-purple-400': borderColor === 'purple',
      'border-blue-400': borderColor === 'blue',
      'border-gray-700 ring ring-opacity-100 focus:ring-opacity-100 ring-gray-400':
        borderColor === 'gray' && active,
      'border-green-700 ring ring-opacity-100 focus:ring-opacity-100 ring-green-400':
        borderColor === 'green' && active,
      'border-orange-700 ring ring-opacity-100 focus:ring-opacity-100 ring-orange-400':
        borderColor === 'orange' && active,
      'border-red-700 ring ring-opacity-100 focus:ring-opacity-100 ring-red-400':
        borderColor === 'red' && active,
      'border-purple-700 ring ring-opacity-100 focus:ring-opacity-100 ring-purple-400':
        borderColor === 'purple' && active,
      'border-blue-700 ring ring-opacity-100 focus:ring-opacity-100 ring-blue-400':
        borderColor === 'blue' && active,
      'focus:ring-gray-400 hover:border-gray-700':
        borderColor === 'gray' && interactive,
      'focus:ring-green-400 hover:border-green-700':
        borderColor === 'green' && interactive,
      'focus:ring-orange-400 hover:border-orange-700':
        borderColor === 'orange' && interactive,
      'focus:ring-red-400 hover:border-red-700':
        borderColor === 'red' && interactive,
      'focus:ring-purple-400 hover:border-purple-700 active:border-purple-700 ':
        borderColor === 'purple' && interactive,
      'focus:ring-blue-400 hover:border-blue-700 active:border-blue-700 ':
        borderColor === 'blue' && interactive,
      'transition duration-200 ease-in-out hover:shadow-lg focus:shadow-lg hover:bg-white focus:bg-white cursor-pointer': interactive,
    }
  )

  const Tag = interactive ? 'button' : 'div'

  return (
    <Tag {...rest} className={cardClassNames} onClick={onClick}>
      {title && <h1 className="mb-2 font-medium">{title}</h1>}
      {children}
    </Tag>
  )
}

Card.defaultProps = {
  onClick: () => {},
  borderColor: 'none',
  interactive: false,
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  borderColor: PropTypes.oneOf([
    'none',
    'gray',
    'green',
    'orange',
    'red',
    'purple',
    'blue',
  ]),
  active: PropTypes.bool,
  onClick: PropTypes.func,
  interactive: PropTypes.bool,
}

export default Card
