import React, { useState } from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

import UploadButton from './UploadButton'

export default {
  title: 'Leevo UI/UploadButton',
  component: UploadButton,
}

export function UploadButtonComponent() {
  const [publicId, setPublicId] = useState('qrz0yfenrunmvcb0epwa')

  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios.onPut().reply(200)

  return (
    <UploadButton
      cloudinaryPhotoPublicId={publicId}
      onPhotoUpload={(newPublicId) => setPublicId(newPublicId)}
    />
  )
}
