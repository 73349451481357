import React from 'react'
import MultiStudentDiscount from './MultiStudentDiscount'

export default {
  title: 'FacilitiesDiscountsIndex/MultiStudentDiscount',
  component: MultiStudentDiscount,
  args: {
    className: 'm-4',
  },
}

export function multiStudentDiscount(args) {
  return <MultiStudentDiscount {...args} />
}
