import React from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

import mixpanel from '../../../utilities/mixpanel'
import { sendCrispMessage } from '../../../utilities/crisp'

let idCount = 0
const faqs = [
  {
    id: idCount++,
    question:
      "I already have a registration software I'm happy with. Can I still use Leevo?",
    answer: (
      <span>
        Absolutely! You can use your existing system for registrations, and
        Leevo to streamline your classes and engage your parents through
        attendance tracking, skill tracking, auto-rostering, parent
        notifications and more. We are able to work with your existing
        registration behind the scenes. Reach out to us with specific questions
        by{' '}
        <button
          onClick={() => {
            sendCrispMessage(
              'I have a question about using an existing registration software.'
            )
            mixpanel.track('Clicked FAQ link', {
              category: 'Registration Software',
            })
          }}
          type="button"
          className="text-purple-600 hover:text-purple-400 dark:text-purple-400 dark:hover:text-purple-500"
        >
          starting a chat.
        </button>
      </span>
    ),
  },
  {
    id: idCount++,
    question:
      "I don't have time to set up Leevo. Is there any way to get help?",
    answer: (
      <span>
        We have a free white-glove onboarding service. To get set up with Leevo
        will only take about 5 minutes of your time. All you need to do is send
        us your current student and class list, and we do the rest. You can{' '}
        <button
          onClick={() => {
            sendCrispMessage('I have a question about getting set up.')
            mixpanel.track('Clicked FAQ link', {
              category: 'Setting up',
            })
          }}
          type="button"
          className="text-purple-600 hover:text-purple-400 dark:text-purple-400 dark:hover:text-purple-500"
        >
          start a chat to learn more.
        </button>
      </span>
    ),
  },
  {
    id: idCount++,
    question: 'Will my coaches be stuck on their cell phones during classes?',
    answer:
      'Leevo is designed for coaches to quickly take attendance and figure out what needs to be taught in class. Then, the phone is put away until class is over. Once the class finishes, the coach can enter the information about which students have made progress in less than 20 seconds. Most coaches are on their phones for less than 1 minute of the total class time, with 95%+ of coaches entering skill tracking data every class.',
  },
  {
    id: idCount++,
    question: 'Is my program too small for Leevo?',
    answer: (
      <span>
        Programs with as few as 5 students, and with well over 1000 students can
        use Leevo to streamline the program, keep parents in the loop, and
        improve the coaching experience. For small programs, raising your price
        by 50¢ - $2.00 is enough to cover the cost of Leevo. If you have under
        20 students, you can always inquire about{' '}
        <button
          onClick={() => {
            sendCrispMessage(
              'I have a question about the rising stars program.'
            )
            mixpanel.track('Clicked FAQ link', {
              category: 'Program too small',
            })
          }}
          type="button"
          className="text-purple-600 hover:text-purple-400 dark:text-purple-400 dark:hover:text-purple-500"
        >
          our rising stars program
        </button>{' '}
        to use Leevo as you grow.
      </span>
    ),
  },
  {
    id: idCount++,
    question:
      'Leevo is too expensive for our small/new program. How can you help?',
    answer: (
      <span>
        For small programs, raising your price by 50¢ - $2.00 is enough to cover
        the cost of Leevo. If you have under 20 students, we offer a rising
        stars program to use Leevo as you grow. You can inquire about that by{' '}
        <button
          onClick={() => {
            sendCrispMessage('I have a question about the rising stars program')
            mixpanel.track('Clicked FAQ link', {
              category: 'Too expensive.',
            })
          }}
          type="button"
          className="text-purple-600 hover:text-purple-400 dark:text-purple-400 dark:hover:text-purple-500"
        >
          starting a chat.
        </button>{' '}
      </span>
    ),
  },
  {
    id: idCount++,
    question: 'Is there a contract?',
    answer:
      "Leevo doesn't lock you in with a commitment or any other contracts. You can cancel at any time, and if you simply stop running classes through Leevo, you won't be charged.",
  },
  {
    id: idCount++,
    question: 'Is it easy to get started?',
    answer: (
      <span>
        To get set up with Leevo will only take about 5 minutes of your time.
        All you need to do is send us your current student and class list, and
        we do the rest. You can{' '}
        <button
          onClick={() => {
            sendCrispMessage('I have a question about getting set up.')
            mixpanel.track('Clicked FAQ link', {
              category: 'Getting started',
            })
          }}
          type="button"
          className="text-purple-600 hover:text-purple-400 dark:text-purple-400 dark:hover:text-purple-500"
        >
          start a chat to start to learn more.
        </button>
      </span>
    ),
  },
  {
    id: idCount++,
    question: 'Is there a free plan?',
    answer: (
      <span>
        Leevo does not offer a free plan, but for small programs expecting to
        grow, we do provide a rising stars program that allows you to use Leevo
        as you grow. You can inquire about that by{' '}
        <button
          onClick={() => {
            sendCrispMessage('I have a question about the rising stars program')
            mixpanel.track('Clicked FAQ link', {
              category: 'Free plan.',
            })
          }}
          type="button"
          className="text-purple-600 hover:text-purple-400 dark:text-purple-400 dark:hover:text-purple-500"
        >
          starting a chat with us.
        </button>
      </span>
    ),
  },
  {
    id: idCount++,
    question: 'What happens if I exceed the number of students on my plan?',
    answer:
      "Don't worry, we won't cancel your account. If you go over the number of students for your plan at any point for the month, you will automatically be billed for the next tier up.",
  },
  {
    id: idCount++,
    question: "What if we don't have any students enrolled?",
    answer:
      "If you don't have student's enrolled in any given month, for any reason (e.g. your program is on a break, you closed for the season, etc.), you will not be charged and will still have access to all your data. As soon as students enroll again, we will charge you at the end of your billing cycle.",
  },
  {
    id: idCount++,
    question: 'Do we get charged if our enrollment numbers drop dramatically?',
    answer:
      'To save you money, your plan will flex down if you reduce the number of students by the time your next billing cycle starts. You never pay for more than you need.',
  },
  {
    id: idCount++,
    question: 'How can I access the financial data my accountant needs?',
    answer:
      'Leevo provides all the transaction data through our payments partner, Stripe. Getting access to this data is quick, easy and intuitive, and can easily be imported into accounting software like Xero or Quickbooks.',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function FaqSection() {
  return (
    <div className="bg-gray-50 dark:bg-gray-700">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200 dark:divide-gray-500">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 dark:text-gray-200 sm:text-4xl">
            Frequently asked questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200 dark:divide-gray-500">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400 dark:text-gray-200">
                        <span className="font-medium text-gray-900 dark:text-gray-200">
                          {faq.question}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Transition
                      enter="transition duration-300 ease-out"
                      enterFrom="transform -translate-y-1 opacity-0"
                      enterTo="transform opacity-100"
                      leave="transition duration-100 ease-out"
                      leaveFrom="transform opacity-100"
                      leaveTo="transform -translate-y-1 opacity-0"
                    >
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500 dark:text-gray-300">
                          {faq.answer}
                        </p>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default FaqSection
