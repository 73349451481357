import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
function Transition({
  show,
  enter,
  enterFrom,
  enterTo,
  leave,
  leaveFrom,
  leaveTo,
  children,
  onClick,
  className,
  mountOnEnter,
  unmountOnExit,
  appear,
  style,
  shouldEnter,
  shouldExit,
  'data-cy': dataCy,
  onExit,
  onEnter,
  ...rest
}) {
  const enterClasses = enter && enter.split(' ')
  const enterFromClasses = enterFrom && enterFrom.split(' ')
  const enterToClasses = enterTo && enterTo.split(' ')
  const leaveClasses = leave && leave.split(' ')
  const leaveFromClasses = leaveFrom && leaveFrom.split(' ')
  const leaveToClasses = leaveTo && leaveTo.split(' ')
  return (
    <CSSTransition
      {...rest}
      enter={shouldEnter}
      exit={shouldExit}
      onClick={onClick}
      appear={appear}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
      in={show}
      data-cy={dataCy}
      className={className}
      style={style}
      addEndListener={(node, done) => {
        node.addEventListener('transitionend', done, false)
      }}
      onEnter={(node) => {
        onEnter(node)
        if (leaveToClasses) node.classList.remove(...leaveToClasses)
        if (enterClasses) node.classList.add(...enterClasses)
        if (enterFromClasses) node.classList.add(...enterFromClasses)
      }}
      onEntering={(node) => {
        if (enterFromClasses) node.classList.remove(...enterFromClasses)
        if (enterToClasses) node.classList.add(...enterToClasses)
      }}
      onEntered={(node) => {
        if (enterClasses) node.classList.remove(...enterClasses)
      }}
      onExit={(node) => {
        onExit(node)
        if (enterToClasses) node.classList.remove(...enterToClasses)
        if (leaveClasses) node.classList.add(...leaveClasses)
        if (leaveFromClasses) node.classList.add(...leaveFromClasses)
      }}
      onExiting={(node) => {
        if (leaveFromClasses) node.classList.remove(...leaveFromClasses)
        if (leaveToClasses) node.classList.add(...leaveToClasses)
      }}
      onExited={(node) => {
        if (leaveClasses) node.classList.remove(...leaveClasses)
      }}
    >
      <div>{children}</div>
    </CSSTransition>
  )
}

Transition.defaultProps = {
  show: true,
  enter: null,
  enterFrom: null,
  enterTo: null,
  leave: null,
  leaveFrom: null,
  leaveTo: null,
  children: null,
  onClick: () => {},
  className: '',
  unmountOnExit: true,
  mountOnEnter: false,
  appear: true,
  style: {},
  shouldEnter: true,
  shouldExit: true,
  onEnter: () => {},
  onExit: () => {},
}

Transition.propTypes = {
  show: PropTypes.bool,
  enter: PropTypes.string,
  enterFrom: PropTypes.string,
  enterTo: PropTypes.string,
  leave: PropTypes.string,
  leaveFrom: PropTypes.string,
  leaveTo: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  'data-cy': PropTypes.string,
  unmountOnExit: PropTypes.bool,
  mountOnEnter: PropTypes.bool,
  appear: PropTypes.bool,
  style: PropTypes.object,
  shouldEnter: PropTypes.bool,
  shouldExit: PropTypes.bool,
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
}

export default Transition
