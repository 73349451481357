import React from 'react'
import { mount } from 'enzyme'

import YouTubeEmbedModal from './YouTubeEmbedModal'

describe('YouTubeEmbedModal', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(<YouTubeEmbedModal embedUrl="" />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
