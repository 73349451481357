import React, { useState } from 'react'
import { optionsKnob as options } from '@storybook/addon-knobs'
import KlassDatePicker from './KlassDatePicker'
import moment from 'moment'

export default {
  title: 'Facilities Calendar/Components/KlassDatePicker',
  component: KlassDatePicker,
}

const days = {
  Sunday: 'Sunday',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
}

export function KlassDatePickerStory() {
  const [selectedDate, setSelectedDate] = useState(moment())
  return (
    <KlassDatePicker
      selectedDate={selectedDate}
      availableDays={options('Available Days', days, [], {
        display: 'inline-check',
      })}
      onUpdateWeek={() => {}}
      onDateSelected={(newDate) => setSelectedDate(newDate)}
    />
  )
}
