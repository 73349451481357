import React from 'react'
import PropTypes from 'prop-types'

import Avatar from '../Avatar/Avatar'
import { createUploadWidget } from '../../../utilities/cloudinary'

function UploadButton({ cloudinaryPhotoPublicId, onPhotoUpload }) {
  const uploadWidget = createUploadWidget((_error, result) => {
    if (result.event == 'success') onPhotoUpload(result.info.public_id)
  })

  return (
    <div className="flex flex-col w-32 space-y-2">
      <Avatar
        cloudinaryPhotoPublicId={cloudinaryPhotoPublicId}
        size={24}
        className="m-auto"
      />
      <button
        type="button"
        className="m-auto text-purple-600 hover:text-purple-900"
        onClick={() => uploadWidget.open()}
      >
        Set New Photo
      </button>
    </div>
  )
}

UploadButton.propTypes = {
  cloudinaryPhotoPublicId: PropTypes.string,
  onPhotoUpload: PropTypes.func,
}

export default UploadButton
