import React from 'react'
import { shallow } from 'enzyme'
import { BrowserRouter } from 'react-router-dom'

import Checkout from './Checkout'

// FIXME: Write specs for this (currently only a smoke test)
describe('Checkout', () => {
  let wrapper

  test('renders', () => {
    wrapper = shallow(
      <BrowserRouter>
        <Checkout
          nextStepUrl="foo"
          students={[]}
          lineItems={[]}
          total={50}
          couponCodeAmount={1}
          couponCodePromotionAmount="$1.00"
          onCouponCodeChange={jest.fn()}
          onCompletedCheckout={jest.fn()}
        />
      </BrowserRouter>
    )
    expect(wrapper).toExist()
  })
})
