import React, { useState, useEffect } from 'react'
import {
  Route,
  Switch,
  useRouteMatch,
  useHistory,
  useParams,
} from 'react-router-dom'
import { Form } from 'react-final-form'
import { get as _get } from 'lodash'

import EmailInfo from '../EmailInfo/EmailInfo'
import PhoneField from '../PhoneField/PhoneField'
import PasswordField from '../PasswordField/PasswordField'
import RegistrationLayout from '../RegistrationLayout/RegistrationLayout'
import NameFields from '../../leevo_ui/NameFields/NameFields'
import Notification from '../../leevo_ui/Notification/Notification'
import LoadingPage from '../../leevo_ui/LoadingPage/LoadingPage'
import SlideInRoute from '../../leevo_ui/Routes/SlideInRoute/SlideInRoute'
import useAuth from '../../../utilities/hooks/useAuth'

// FIXME: Setting autoFocus on any of the fields causes an issue where
// the slide in animation does not work. Perhaps this can be fixed with a
// technique like this ? http://medium.com/onfido-tech/animations-with-react-router-8e97222e25e1#25cd
function CreateAccount() {
  let { path, url } = useRouteMatch()
  const { facility_id } = useParams()
  const dashboardUrl = `/facilities/${facility_id}/register`
  const history = useHistory()
  const [fields, setFields] = useState({})
  const [notification, setNotification] = useState({
    show: false,
    message: null,
  })
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const [progressPercentage, setProgressPercentage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const {
    signInUser,
    registerUser,
    verifyCredentials,
    isSignedIn,
    isLoading: isAuthLoading,
  } = useAuth()

  function onSubmit(values) {
    setFields({ ...fields, ...values })
  }

  function setErrorNotification(error) {
    const errorMsg = _get(
      error,
      'response.data.errors[0]',
      'Something went wrong. Please try again.'
    )
    setNotification({ message: errorMsg, show: true })
  }

  function handleUpdatedPath() {
    if (!fields.email) history.replace(`${url}`)
    if (isSignedIn) history.replace(dashboardUrl)
  }

  function handleUpdatedFields() {
    const { email, password, fullName, preferredName, phone } = fields
    const hasRegistrationInfo = fullName && preferredName && phone
    const isRegistering = email && password && hasRegistrationInfo
    const isLoggingIn = email && password && !hasRegistrationInfo

    setNotification({ ...notification, show: false })

    if (isLoggingIn) {
      signInUser({ email, password })
        .then(() => {
          history.push(dashboardUrl)
          setProgressPercentage(0)
        })
        .catch(setErrorNotification)
    } else if (isRegistering) {
      registerUser(fields)
        .then(() => {
          history.replace(dashboardUrl)
          setProgressPercentage(0)
        })
        .catch(setErrorNotification)
    } else if (phone) {
      history.push(`${url}/password`)
      setProgressPercentage(75)
    } else if (fullName && preferredName) {
      history.push(`${url}/phone`)
      setProgressPercentage(50)
    } else if (email) {
      history.push(`${url}/name`)
      setProgressPercentage(25)
    }
  }

  //FIXME
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setIsLoading(true)
    if (!isSignedIn) verifyCredentials().then(() => setIsLoading(false))
  }, [])
  useEffect(handleUpdatedPath, [history.location.pathname, isSignedIn])
  useEffect(handleUpdatedFields, [fields])
  /* eslint-enable */

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <RegistrationLayout
          title="Create Account or Log In"
          progressPercentage={progressPercentage}
          onClickNext={handleSubmit}
          isNextDisabled={submitting || nextButtonDisabled}
          className="m-4"
        >
          <form onSubmit={handleSubmit} className="max-w-md pt-4 m-auto">
            {(isLoading || isAuthLoading) && <LoadingPage />}
            <Switch>
              <SlideInRoute
                path={`${path}/name`}
                render={() => <NameFields />}
              />
              <SlideInRoute
                path={`${path}/phone`}
                render={() => <PhoneField />}
              />
              <SlideInRoute
                path={`${path}/password`}
                render={() => <PasswordField />}
              />
              <Route
                path={path}
                render={() => (
                  <EmailInfo setNextButtonDisabled={setNextButtonDisabled} />
                )}
              />
            </Switch>
            <input type="submit" className="hidden" />
          </form>
          <Notification
            show={notification.show}
            onDisappearTimeout={() =>
              setNotification({
                ...notification,
                show: false,
              })
            }
            color="red"
          >
            {notification.message}
          </Notification>
        </RegistrationLayout>
      )}
    />
  )
}

export default CreateAccount
