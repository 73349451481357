import React from 'react'
import Notification from './Notification'
import { mount } from 'enzyme'

describe('Notification', () => {
  let wrapper, emptyDiv
  beforeEach(() => {
    emptyDiv = <div>Empty</div>
    wrapper = mount(<Notification>{emptyDiv}</Notification>)
  })

  describe('show', () => {
    describe('when false', () => {
      test('does not render children', () => {
        expect(wrapper).not.toHaveText('Empty')
      })
    })

    describe('when true', () => {
      test('renders children', () => {
        wrapper = mount(<Notification show>{emptyDiv}</Notification>)
        expect(wrapper).toHaveText('Empty')
      })
    })
  })

  describe('color', () => {
    describe('when purple', () => {
      test('has purple classNames', () => {
        wrapper = mount(
          <Notification show color="purple">
            {emptyDiv}
          </Notification>
        )
        expect(wrapper.find('.bg-purple-100')).toExist()
        expect(wrapper.find('.text-purple-800')).toExist()
      })
    })
    describe('when blue', () => {
      test('has blue classNames', () => {
        wrapper = mount(
          <Notification show color="blue">
            {emptyDiv}
          </Notification>
        )
        expect(wrapper.find('.bg-blue-100')).toExist()
        expect(wrapper.find('.text-blue-800')).toExist()
      })
    })
    describe('when red', () => {
      test('has red classNames', () => {
        wrapper = mount(
          <Notification show color="red">
            {emptyDiv}
          </Notification>
        )
        expect(wrapper.find('.bg-red-100')).toExist()
        expect(wrapper.find('.text-red-800')).toExist()
      })
    })
    describe('when green', () => {
      test('has green classNames', () => {
        wrapper = mount(
          <Notification show color="green">
            {emptyDiv}
          </Notification>
        )
        expect(wrapper.find('.bg-green-100')).toExist()
        expect(wrapper.find('.text-green-800')).toExist()
      })
    })
    describe('when gray', () => {
      test('has gray classNames', () => {
        wrapper = mount(
          <Notification show color="gray">
            {emptyDiv}
          </Notification>
        )
        expect(wrapper.find('.bg-gray-100')).toExist()
        expect(wrapper.find('.text-gray-800')).toExist()
      })
    })
  })

  describe('className', () => {
    test('is applied to the rendered div', () => {
      wrapper = mount(
        <Notification show className="foo">
          {emptyDiv}
        </Notification>
      )
      expect(wrapper.find('div').at(0)).toHaveClassName('foo')
    })
  })

  describe('disappearing', () => {
    let mockOnDisappearTimeout
    beforeEach(() => {
      jest.useFakeTimers()
      mockOnDisappearTimeout = jest.fn()
      wrapper = mount(
        <Notification
          show
          disappearIn={1000}
          onDisappearTimeout={mockOnDisappearTimeout}
        >
          {emptyDiv}
        </Notification>
      )
    })

    test('calls mockOnDisappearTimeout in disappearIn time', () => {
      jest.runAllTimers()
      expect(setTimeout).toHaveBeenLastCalledWith(mockOnDisappearTimeout, 1000)
    })
  })
})
