import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import Input from '../../leevo_ui/Input/Input'
import { presence } from '../../../utilities/validators'

function PreferredNameField({ label, className }) {
  return (
    <Field name="preferredName" validate={presence}>
      {({ input, meta: { active, submitFailed, error } }) => (
        <Input
          {...input}
          className={className}
          id="preferredName"
          type="text"
          label={label}
          placeholder="Robbie"
          errorMessage={!active && error && submitFailed ? error : ''}
        />
      )}
    </Field>
  )
}

PreferredNameField.defaultProps = {
  label: 'What do you prefer to be called?',
}

PreferredNameField.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
}

export default PreferredNameField
