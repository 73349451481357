import React from 'react'
import StudentImportsNew from './StudentImportsNew'
import { mount } from 'enzyme'
import MockFacilityProvider from '../../providers/MockFacilityProvider'
import { MemoryRouter } from 'react-router-dom'

describe('StudentImportsNew', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/']}>
          <MockFacilityProvider>
            <StudentImportsNew />
          </MockFacilityProvider>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
