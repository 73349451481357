import React from 'react'
import { mount } from 'enzyme'
import { Router } from 'react-router'
import { createBrowserHistory } from 'history'
import flushPromises from 'flush-promises'

import CheckButton from '../../leevo_ui/CheckButton/CheckButton'
import StudentAttendanceCheckButton from '../StudentAttendanceCheckButton/StudentAttendanceCheckButton'

const history = createBrowserHistory()

describe('StudentAttendanceCheckButton', () => {
  describe('when attended', () => {
    let wrapper

    beforeEach(() => {
      wrapper = mount(
        <Router history={history}>
          <StudentAttendanceCheckButton
            klassDaysStudent={{ attended: false, id: 3 }}
            toggleAttendance={jest.fn().mockReturnValue(Promise.resolve())}
          />
        </Router>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('renders a Card', () => {
      expect(wrapper.find(CheckButton).length).toEqual(1)
    })

    describe('clicking attendance check button with success', () => {
      beforeEach(() => {
        wrapper.find('button').simulate('click')
      })

      test('changes button state before network call has returned', () => {
        expect(wrapper.find('button').props()['aria-pressed']).toEqual(true)
      })

      test('changes button to disabled while waiting for network call to go and return', () => {
        expect(wrapper.find('button').props().disabled).toEqual(true)
      })

      test('when network call finishes check that button is not disabled', async () => {
        await flushPromises()
        wrapper.update()
        expect(wrapper.find('button').props().disabled).toEqual(false)
      })
    })

    describe('clicking attendance check button with failure', () => {
      beforeEach(() => {
        wrapper = mount(
          <Router history={history}>
            <StudentAttendanceCheckButton
              klassDaysStudent={{ attended: false, id: 3 }}
              toggleAttendance={jest.fn().mockReturnValue(Promise.reject())}
            />
          </Router>
        )
        jest.spyOn(history, 'replace')
        wrapper.find('button').simulate('click')
      })

      test('changes button state before network call has returned', () => {
        expect(wrapper.find('button').props()['aria-pressed']).toEqual(true)
      })

      test('changes button to disabled while waiting for network call to go and return', () => {
        expect(wrapper.find('button').props().disabled).toEqual(true)
      })

      test('when network call finishes check that button is not disabled', async () => {
        await flushPromises()
        wrapper.update()
        expect(wrapper.find('button').props().disabled).toEqual(false)
      })

      test('displays error message once request is finished', async () => {
        await flushPromises()
        expect(history.replace).toHaveBeenCalledWith({
          state: {
            notification: {
              message: 'Oops! Something went wrong while recording attendance.',
              color: 'red',
            },
          },
        })
      })
    })
  })
})
