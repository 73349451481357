import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import LargeRadioInput from '../../leevo_ui/LargeRadioInput/LargeRadioInput'
import { presence } from '../../../utilities/validators'

const options = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]

function HasLTSNumberField({ className, name }) {
  return (
    <Field name="hasLTSNumber" validate={presence}>
      {({ input, meta: { active, error, submitFailed } }) => (
        <LargeRadioInput
          {...input}
          id="hasLTSNumber"
          options={options}
          className={className}
          helpText="For new skaters, we charge $20.00 to get LTS Number from USFSA. If you provide a valid number, you'll be fully refunded when it's verified."
          label={`Does ${name} have a valid LTS or USFSA number?`}
          errorMessage={!active && error && submitFailed ? error : ''}
        />
      )}
    </Field>
  )
}

HasLTSNumberField.defaultProps = {
  name: 'this skater',
}

HasLTSNumberField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
}

export default HasLTSNumberField
