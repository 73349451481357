import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import { get as _get } from 'lodash'
import moment from 'moment-timezone'
import queryString from 'query-string'

import KlassForm from '../leevo_ui/KlassForm/KlassForm'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import { requestCreator } from '../../utilities/requests'
import useFacility from '../../utilities/hooks/useFacility'

function FacilitiesKlassesNew() {
  const history = useHistory()
  const { facility, isLoading: isFacilityLoading } = useFacility()
  const [isLoading, setIsLoading] = useState(true)
  const [dates, setDates] = useState([])
  const [coaches, setCoaches] = useState([])
  const [klassPackages, setKlassPackages] = useState([])
  const [supportedSkillLevels, setSupportedSkillLevels] = useState([])
  const [students, setStudents] = useState([])
  const [maxStudents, setMaxStudents] = useState(null)
  const { search } = useLocation()
  const {
    get: getSkillLevels,
    cancel: cancelSkillLevelsRequests,
  } = requestCreator()
  const { get: getCoaches, cancel: cancelCoachesRequests } = requestCreator()
  const {
    get: getKlass,
    post: createKlass,
    cancel: cancelKlassRequests,
  } = requestCreator()
  const {
    get: getKlassPackages,
    cancel: cancelKlassPackagesRequests,
  } = requestCreator()

  const { copy_id: copyId } = queryString.parse(search)

  function generateEmptyKlassValues() {
    getSkillLevels(`/api/facilities/${facility.id}/skill_levels`)
      .then((skillLevels) =>
        setSupportedSkillLevels(
          skillLevels.map((skillLevel) => ({
            ...skillLevel,
            label: skillLevel.name, // set a label for compatibility with multi-select searching
            value: skillLevel.id,
          }))
        )
      )
      .then(() => getCoaches(`/api/facilities/${facility.id}/coaches`))
      .then((coachList) =>
        setCoaches(
          coachList.map((coach) => ({
            ...coach,
            label: coach.fullName || `${coach.email} (pending)`, // set a label for compatibility with multi-select searching
            value: coach.id,
          }))
        )
      )
      .then(() =>
        getKlassPackages(`/api/facilities/${facility.id}/class_packages`)
      )
      .then((klassPackages) =>
        setKlassPackages(
          klassPackages.map((klassPackage) => ({
            ...klassPackage,
            value: klassPackage.id,
            selected: false,
          }))
        )
      )
      .then(() => setIsLoading(false))
      .catch((error) => {
        if (!axios.isCancel(error)) {
          history.push('/500')
        }
      })
  }

  function generatePopulatedKlassValues() {
    let klass
    getKlass(`/api/facilities/${facility.id}/klasses/${copyId}`, {
      params: { include: 'klass_days,students,students.current_skill_level' },
    })
      .then((retrievedKlass) => {
        klass = retrievedKlass
        setDates(
          klass.dates.map((date) => ({
            date: moment(date).tz(facility.timeZone),
            isTimeSet: true,
          }))
        )
        setStudents(
          klass.students.map((student) => ({ ...student, selected: true }))
        )
        setMaxStudents(klass.maxStudents)
      })
      .then(() => getSkillLevels(`/api/facilities/${facility.id}/skill_levels`))
      .then((skillLevels) =>
        setSupportedSkillLevels(
          skillLevels.map((skillLevel) => ({
            ...skillLevel,
            label: skillLevel.name,
            value: skillLevel.id,
            selected: klass.supportedSkillLevels
              .map((s) => s.id)
              .includes(skillLevel.id),
          }))
        )
      )
      .then(() => getCoaches(`/api/facilities/${facility.id}/coaches`))
      .then((coachList) =>
        setCoaches(
          coachList.map((coach) => ({
            ...coach,
            label: coach.fullName || `${coach.email} (pending)`, // set a label for compatibility with multi-select searching
            value: coach.id,
            selected: klass.coaches.map((c) => c.id).includes(coach.id),
          }))
        )
      )
      .then(() =>
        getKlassPackages(`/api/facilities/${facility.id}/class_packages`)
      )
      .then((klassPackages) =>
        setKlassPackages(
          klassPackages.map((klassPackage) => ({
            ...klassPackage,
            value: klassPackage.id,
            selected: klass.klassPackages
              .map((s) => s.id)
              .includes(klassPackage.id),
          }))
        )
      )
      .then(() => setIsLoading(false))
      .catch((error) => {
        if (!axios.isCancel(error)) {
          history.push('/500')
        }
      })
  }

  useEffect(() => {
    if (!isFacilityLoading) {
      copyId ? generatePopulatedKlassValues() : generateEmptyKlassValues()
    }

    return () => {
      cancelSkillLevelsRequests()
      cancelCoachesRequests()
      cancelKlassRequests()
      cancelKlassPackagesRequests()
    }
  }, []) /* eslint-disable-line */ // FIXME

  function onSubmit(values) {
    setDates(values.dates)
    setCoaches(
      coaches.map((coach) => ({
        ...coach,
        selected: values.coaches.includes(coach),
      }))
    )
    setSupportedSkillLevels(
      supportedSkillLevels.map((skillLevel) => ({
        ...skillLevel,
        selected: values.supportedSkillLevels.includes(skillLevel),
      }))
    )
    setMaxStudents(values.maxStudents)
    return createKlass(`/api/facilities/${facility.id}/klasses`, {
      klass: {
        ...values,
        coaches: values.coaches.map((coach) => coach.id),
        supportedSkillLevels: values.supportedSkillLevels.map((sl) => sl.id),
        klassPackages: values.klassPackages
          .filter((klassPackage) => klassPackage.selected)
          .map((klassPackage) => klassPackage.id),
        dates: values.dates
          .filter(({ isTimeSet }) => isTimeSet)
          .map(({ date }) => date.toString()),
        students: values.students
          .filter((student) => student.selected)
          .map((student) => student.id),
      },
    })
      .then(() =>
        history.push({
          pathname: `/facilities/${facility.id}/classes`,
          state: {
            notification: {
              message: 'Class created successfully!',
              color: 'green',
            },
          },
        })
      )
      .catch((error) => {
        if (!axios.isCancel(error)) {
          const message =
            _get(error, 'response.data.error') ||
            'We were unable to save your changes.'
          history.replace({
            state: {
              notification: {
                message,
                color: 'red',
              },
            },
          })
        }
      })
  }

  return (
    <ApplicationLayout isContentLoading={isLoading}>
      <KlassForm
        dates={dates}
        coaches={coaches}
        students={students}
        klassPackages={klassPackages}
        supportedSkillLevels={supportedSkillLevels}
        maxStudents={maxStudents}
        onSubmit={onSubmit}
      />
    </ApplicationLayout>
  )
}

export default FacilitiesKlassesNew
