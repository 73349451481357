import React from 'react'
import CurrencyInput from './CurrencyInput'
import { shallow } from 'enzyme'

describe('CurrencyInput', () => {
  let wrapper
  beforeEach(() => {
    wrapper = shallow(<CurrencyInput id="id-foo" />)
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders rest of provided props on the CurrencyInput element', () => {
    wrapper.setProps({ foo: 'bar' })
    expect(wrapper.find('input').prop('foo')).toBe('bar')
  })

  test('renders className on topmost div', () => {
    wrapper.setProps({ className: 'bar' })
    expect(wrapper.first('div')).toHaveClassName('bar')
  })

  describe('id', () => {
    test('is required', () => {
      function renderWrapper() {
        shallow(<CurrencyInput />)
      }
      expect(renderWrapper).toThrowError('`id` is marked as required')
    })

    test('is rendered on the input element', () => {
      expect(wrapper.find('input').prop('id')).toBe('id-foo')
    })

    test('renders in the htmlFor of the label', () => {
      wrapper.setProps({ label: 'some label' }) // label must be specified to appear
      expect(wrapper.find('label').prop('htmlFor')).toBe('id-foo')
    })
  })

  describe('label', () => {
    test('is rendered within a label element', () => {
      wrapper.setProps({ label: 'foo' })
      expect(wrapper.find('label')).toHaveText('foo')
    })

    test('does not render a label element if not provided', () => {
      expect(wrapper.find('label')).not.toExist()
    })
  })

  describe('errorMessage', () => {
    test('is present when provided', () => {
      wrapper.setProps({ errorMessage: 'foo' })
      expect(wrapper).toIncludeText('foo')
    })

    test('changes color of label when provided', () => {
      wrapper.setProps({ errorMessage: 'foo', label: 'some label' })
      expect(wrapper.find('label')).toHaveClassName('text-red-500')
    })

    test('changes color of input border when provided', () => {
      wrapper.setProps({ errorMessage: 'foo' })
      expect(wrapper.find('input')).toHaveClassName('border-red-500')
    })
  })

  describe('required', () => {
    beforeEach(() => {
      wrapper.setProps({ required: true })
    })

    test('renders a star at the end of the label', () => {
      wrapper.setProps({ label: 'some label' })
      expect(wrapper.find('label')).toIncludeText('*')
    })

    test('renders required on the input', () => {
      expect(wrapper.find('input')).toHaveProp('required')
    })
  })

  describe('disabled', () => {
    beforeEach(() => {
      wrapper.setProps({ disabled: true })
    })

    test('grays out the input', () => {
      expect(wrapper.find('input')).toHaveClassName('bg-gray-100')
    })

    test('renders disabled on the input', () => {
      expect(wrapper.find('input')).toHaveProp('disabled')
    })
  })

  test.todo('input behavior')
})
