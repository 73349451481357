import React from 'react'
import { text, boolean } from '@storybook/addon-knobs'

import StudentCard from './StudentCard'

export default {
  title: 'GuardiansStudentsIndex/StudentCard',
  component: StudentCard,
}

const student = {
  type: 'student',
  id: '2',
  fullName: 'Jane Doe',
  preferredName: 'Janey',
  email: 'user2@example.com',
  cloudinaryPhotoPublicId: 'qrz0yfenrunmvcb0epwa',
  gender: 'unspecified',
  birthdate: null,
  ltsNumber: null,
  studentsSkills: [
    {
      type: 'studentsSkill',
      id: '1',
      name: 'Stand up on the ice',
      shortLabel: 'A',
      status: 'unintroduced',
      ordinal: 1,
    },
    {
      type: 'studentsSkill',
      id: '2',
      name: 'Stand up on the ice',
      shortLabel: 'B',
      status: 'introduced',
      ordinal: 2,
    },
    {
      type: 'studentsSkill',
      id: '3',
      name: 'Stand up on the ice',
      shortLabel: 'C',
      status: 'completed',
      ordinal: 3,
    },
  ],
  klassDaysStudents: [],
  currentSkillLevel: {
    type: 'skillLevel',
    id: '1',
    name: 'Basic 1',
    relationshipNames: ['skills'],
  },
  relationshipNames: [
    'studentsSkills',
    'klassDaysStudents',
    'currentSkillLevel',
  ],
}

export function studentCard() {
  return (
    <StudentCard
      id={1}
      fullName={text('Name', 'John Doe')}
      cloudinaryPhotoPublicId={
        boolean('Show Initials?', true) ? null : 'qrz0yfenrunmvcb0epwa'
      }
      skills={student.studentsSkills}
      currentSkillLevel={student.currentSkillLevel.name}
    />
  )
}
