import React from 'react'
import { shallow } from 'enzyme'

import SkillButton from './SkillButton'
import CircleButton from '../../leevo_ui/CircleButton/CircleButton'

describe('SkillButton', () => {
  describe('neutral button', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(<SkillButton state="neutral" label="A" />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('renders as white button', () => {
      expect(wrapper.find(CircleButton).props().color).toEqual('white')
    })
  })

  describe('unintroduced button', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(<SkillButton state="unintroduced" label="A" />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('renders as white button', () => {
      expect(wrapper.find(CircleButton).props().color).toEqual('white')
    })
  })

  describe('introduced button', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(<SkillButton state="introduced" label="A" />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('renders as yellow button', () => {
      expect(wrapper.find(CircleButton).props().color).toEqual('yellow')
    })
  })

  describe('completed button', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(<SkillButton state="completed" label="A" />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('renders as green button', () => {
      expect(wrapper.find(CircleButton).props().color).toEqual('green')
    })
  })
  describe('fires onClick prop when clicked', () => {
    let wrapper
    const onClickFn = jest.fn()

    beforeEach(() => {
      wrapper = shallow(
        <SkillButton
          id="1"
          onClick={onClickFn}
          state="unintroduced"
          label="A"
        />
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('onClick fires', () => {
      wrapper.find(CircleButton).simulate('click')
      expect(onClickFn).toHaveBeenCalled()
    })
  })

  describe('fires default onClick when clicked', () => {
    let wrapper
    beforeEach(() => {
      wrapper = shallow(<SkillButton id="1" state="unintroduced" label="A" />)
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })

    test('onClick to be default function', () => {
      const defaultFn = SkillButton.defaultProps.onClick

      expect(wrapper.find(CircleButton).props().onClick).toEqual(defaultFn)
    })
  })
})
