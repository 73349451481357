import React from 'react'
import EmptyList from './EmptyList'
import { shallow, mount } from 'enzyme'
import SVG from 'react-inlinesvg'
import Button from '../Button/Button'

describe('EmptyList', () => {
  describe('message', () => {
    test('renders with a message', () => {
      const wrapper = shallow(<EmptyList message="Required label" />)
      expect(wrapper).toIncludeText('Required label')
    })

    test('renders without a message', () => {
      const wrapper = shallow(<EmptyList />)
      expect(wrapper.find('p')).toHaveText('')
    })
  })

  describe('SVG icon', () => {
    test('renders with an SVG', () => {
      const wrapper = shallow(<EmptyList svgSrc="fake" />)
      expect(wrapper.find(SVG)).toExist()
    })

    test('renders without an SVG', () => {
      const wrapper = shallow(<EmptyList />)
      expect(wrapper.find(SVG)).not.toExist()
    })
  })

  describe('button', () => {
    describe('with button text', () => {
      let wrapper, mockOnButtonClick
      beforeEach(() => {
        mockOnButtonClick = jest.fn()
        wrapper = mount(
          <EmptyList onButtonClick={mockOnButtonClick} buttonText="foo" />
        )
      })
      test('renders with a button with provided button text', () => {
        expect(wrapper.find(Button)).toHaveText('foo')
      })

      test('uses the provided onButtonClick method when clicked', () => {
        wrapper.find(Button).simulate('click')
        expect(mockOnButtonClick).toHaveBeenCalled()
      })
    })

    describe('without button text', () => {
      test('renders without a button', () => {
        const wrapper = shallow(<EmptyList />)
        expect(wrapper.find(Button)).not.toExist()
      })
    })
  })
})
