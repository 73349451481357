import React, { useState } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from 'react-router-dom'
import { get as _get } from 'lodash'
import queryString from 'query-string'
import mixpanel from '../../utilities/mixpanel'

import NewPasswordModal from '../leevo_ui/NewPasswordModal/NewPasswordModal'

function newPasswordUrlIsMissingQueryStringParams(parsedParams) {
  // https://devise-token-auth.gitbook.io/devise-token-auth/usage/reset_password
  const requiredParamsByDevise = ['access-token', 'client', 'expiry', 'uid']

  return requiredParamsByDevise.some(
    (requiredParam) => !parsedParams[requiredParam]
  )
}

export default function NewPasswordFeature() {
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory()
  const location = useLocation()

  const parsedQueryStringParams = queryString.parse(location.search)
  parsedQueryStringParams['token-type'] = 'Bearer'
  if (newPasswordUrlIsMissingQueryStringParams(parsedQueryStringParams)) {
    history.push('/')
  }

  function newPasswordSubmission({ password, passwordConfirmation }) {
    setSuccessMessage('')
    setErrorMessage('')
    const headers = {
      'access-token': parsedQueryStringParams['access-token'],
      client: parsedQueryStringParams.client,
      expiry: parsedQueryStringParams.expiry,
      'token-type': 'Bearer',
      uid: parsedQueryStringParams.uid,
    }

    return axios
      .create({ headers })
      .put('/auth/password', {
        password,
        password_confirmation: passwordConfirmation,
      })
      .then(({ data }) => {
        mixpanel.track('Password Reset Successful')
        setSuccessMessage(data.message)
        history.push({
          pathname: '/login',
          state: {
            message: 'You have successfully created a new password!',
          },
        })
      })
      .catch((error) => {
        mixpanel.track('Password Reset Failed')
        if (_get(error, 'response.data.errors.full_messages')) {
          setErrorMessage(error.response.data.errors.full_messages.join(' '))
        } else {
          setErrorMessage(error.toString())
        }
      })
  }

  return (
    <>
      <Helmet>
        <title>New Password | Leevo</title>
      </Helmet>
      <NewPasswordModal
        errorMessage={errorMessage}
        newPasswordSubmission={newPasswordSubmission}
        successMessage={successMessage}
      />
    </>
  )
}
