import React from 'react'
import PropTypes from 'prop-types'

import MarketingNavBar from '../NavBar/MarketingNavBar/MarketingNavBar'
import MarketingPageFooter from '../MarketingPageFooter/MarketingPageFooter'
import SimpleJustifiedCTA from '../SimpleJustifiedCTA/SimpleJustifiedCTA'
import AccentDots from './AccentDots/AccentDots'

function ContentPageLayout({ children, category, title, intro }) {
  return (
    <div>
      <MarketingNavBar />
      <div className="relative pt-12 pb-24 overflow-hidden bg-white dark:bg-gray-700 dark:text-gray-100">
        <AccentDots />
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto text-lg max-w-prose">
            <h1>
              {category && (
                <span className="block text-base font-semibold tracking-wide text-center text-purple-700 uppercase dark:text-purple-500">
                  {category}
                </span>
              )}
              <span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 dark:text-gray-300 sm:text-4xl">
                {title}
              </span>
            </h1>
            {intro && (
              <p className="mt-8 text-xl leading-8 text-gray-500 dark:text-gray-400">
                {intro}
              </p>
            )}
          </div>
          <div className="mx-auto mt-6 space-y-3 prose prose-lg text-gray-500 dark:prose-dark dark:text-gray-400">
            {children}
          </div>
        </div>
      </div>
      <SimpleJustifiedCTA
        headline="Still have questions?"
        subheadline="We're here if you need us."
      />
      <MarketingPageFooter />
    </div>
  )
}

ContentPageLayout.defaultProps = {
  category: '',
  intro: '',
}

ContentPageLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  category: PropTypes.string,
  intro: PropTypes.node,
}

export default ContentPageLayout
