import React from 'react'
import { mount } from 'enzyme'

import { MemoryRouter, Route } from 'react-router-dom'

import MockAuthProvider from '../../../../providers/MockAuthProvider'
import ApplicationSidebar from './ApplicationSidebar'

describe('ApplicationSidebar', () => {
  describe('renders with default and required props', () => {
    let wrapper
    beforeEach(() => {
      wrapper = mount(
        <MemoryRouter initialEntries={['/ApplicationSidebar']}>
          <MockAuthProvider>
            <Route path="/ApplicationSidebar">
              <ApplicationSidebar />
            </Route>
          </MockAuthProvider>
        </MemoryRouter>
      )
    })

    test('renders', () => {
      expect(wrapper).toExist()
    })
  })
})
