import { startCase as _startCase } from 'lodash'
import { RRule, RRuleSet } from 'rrule'
import moment from 'moment'

export function levelArrayToString(skillLevelsArray, options = {}) {
  const { maxLength } = options
  if (skillLevelsArray.length === 0) return ''

  return skillLevelsArray
    .sort()
    .map((s) => _startCase(s))
    .reduce((result, skillLevel) => {
      const [_, levelName, levelNumber] = skillLevel.match(/([^0-9]+)(\d*)/)
      let output
      if (result.includes(levelName)) {
        output = [result, levelNumber].join(', ')
      } else if (!result.includes(levelName)) {
        output = [result, skillLevel].join(' – ')
      }
      return output.length > maxLength
        ? `${skillLevelsArray.length} Levels`
        : output
    })
}

export function alphabetizeByFullName(a, b) {
  if (a.fullName < b.fullName) return -1
  if (a.fullName > b.fullName) return 1
  return 0
}

export function selectedToTop(a, b) {
  if ((a.selected && b.selected) || (!a.selected && !b.selected)) return 0
  if (a.selected && !b.selected) return -1
  if (!a.selected && b.selected) return 1
}

export function requiredToTop(a, b) {
  if ((a.required && b.required) || (!a.required && !b.required)) return 0
  if (a.required && !b.required) return -1
  if (!a.required && b.required) return 1
}

export function priceInDollars(priceInCents) {
  return (priceInCents / 100.0).toFixed(2)
}

export function RRuleFromIceCubeClass(iceCubeClass) {
  switch (iceCubeClass) {
    case 'IceCube::SecondlyRule':
      return RRule.SECONDLY

    case 'IceCube::MinutelyRule':
      return RRule.MINUTELY

    case 'IceCube::HourlyRule':
      return RRule.HOURLY

    case 'IceCube::DailyRule':
      return RRule.DAILY

    case 'IceCube::WeeklyRule':
      return RRule.WEEKLY

    case 'IceCube::MonthlyRule':
      return RRule.MONTHLY

    case 'IceCube::YearlyRule':
      return RRule.YEARLY

    default:
      throw Error('Unknown IceCube class type')
  }
}

// Can provide a ruleSet as optional second arg to combine ruleSets
export function ruleSetFromRecurrenceRule(
  recurrenceRule,
  ruleSet = new RRuleSet()
) {
  recurrenceRule.rrules.forEach((rrule) => {
    // Create npm rrule rules based on rrules from server
    ruleSet.rrule(
      new RRule({
        freq: RRuleFromIceCubeClass(rrule.ruleType),
        interval: rrule.interval,
        count: rrule.count,
        dtstart: moment(recurrenceRule.startTime.time).toDate(),
      })
    )
  })

  // Create npm rrule extimes based on rrules from server
  recurrenceRule.extimes.forEach((extime) => {
    ruleSet.exdate(moment(extime.time || extime).toDate())
  })

  // Create npm rrule rtimes/rdates based on rrules from server
  recurrenceRule.rtimes.forEach((rtime) => {
    ruleSet.rdate(moment(rtime.time || rtime).toDate())
  })

  return ruleSet
}

export function readableSkateType(skateType) {
  const readableSkateTypes = {
    men_figure: { label: "Men's Figure", color: 'blue' },
    women_figure: { label: "Women's Figure", color: 'red' },
    youth_figure: { label: 'Youth Figure', color: 'purple' },
    men_hockey: { label: "Men's Hockey", color: 'gray' },
    women_hockey: { label: "Women's Hockey", color: 'green' },
    youth_hockey: { label: 'Youth Hockey', color: 'orange' },
  }

  return readableSkateTypes[skateType]
}
