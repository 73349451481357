import React from 'react'
import { mount } from 'enzyme'
import ApplicationLayout from './ApplicationLayout'
import { MemoryRouter } from 'react-router-dom'
import MockAuthProvider from '../../../providers/MockAuthProvider'

describe('ApplicationLayout', () => {
  let wrapper
  beforeEach(async () => {
    wrapper = mount(
      <MemoryRouter>
        <MockAuthProvider>
          <ApplicationLayout>
            <div>Some Content</div>
          </ApplicationLayout>
        </MockAuthProvider>
      </MemoryRouter>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('renders children', () => {
    expect(wrapper).toIncludeText('Some Content')
  })
})
