import React from 'react'
import { action } from '@storybook/addon-actions'
import { Form, Field } from 'react-final-form'

import CardFormSection from './CardFormSection'
import Input from '../../leevo_ui/Input/Input'
import { presence } from '../../../utilities/validators'

export default {
  title: 'Leevo UI/CardFormSection',
  component: CardFormSection,
  args: {
    title: 'Some title',
    description:
      'A rather long description, well, maybe not that long, I guess.',
  },
}

export function cardFormSection(args) {
  return (
    <Form
      onSubmit={action('Submitted')}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <CardFormSection {...args}>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                <Field name="fullName" validate={presence}>
                  {({ input, meta }) => (
                    <Input
                      {...input}
                      id="full_name"
                      autoFocus
                      type="text"
                      label="Full Name"
                      placeholder="Richard Button"
                      errorMessage={
                        !meta.active && meta.error && meta.submitFailed
                          ? meta.error
                          : ''
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                <Field name="preferredName" validate={presence}>
                  {({ input, meta }) => (
                    <Input
                      {...input}
                      id="preferred_name"
                      type="text"
                      label="Preferred Name"
                      placeholder="Richard"
                      errorMessage={
                        !meta.active && meta.error && meta.submitFailed
                          ? meta.error
                          : ''
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
          </CardFormSection>
          <CardFormSection {...args}>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                <Field name="fullName2" validate={presence}>
                  {({ input, meta }) => (
                    <Input
                      {...input}
                      id="full_name2"
                      autoFocus
                      type="text"
                      label="Full Name"
                      placeholder="Richard Button"
                      errorMessage={
                        !meta.active && meta.error && meta.submitFailed
                          ? meta.error
                          : ''
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                <Field name="preferredName2" validate={presence}>
                  {({ input, meta }) => (
                    <Input
                      {...input}
                      id="preferred_name2"
                      type="text"
                      label="Preferred Name"
                      placeholder="Richard"
                      errorMessage={
                        !meta.active && meta.error && meta.submitFailed
                          ? meta.error
                          : ''
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
          </CardFormSection>
        </form>
      )}
    />
  )
}
