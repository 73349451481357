import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import { get } from 'lodash'
import mixpanel from '../../utilities/mixpanel'

import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import LoadingSpinner from '../leevo_ui/LoadingSpinner/LoadingSpinner'
import SkillLegend from '../leevo_ui/SkillLegend/SkillLegend'
import { requestCreator } from '../../utilities/requests'
import StudentCard from './StudentCard/StudentCard'

const { get: getStudents, cancel: cancelStudentsRequests } = requestCreator()

function GuardiansStudentsIndex() {
  const history = useHistory()
  const { guardian_id: GuardianId } = useParams()
  const [isFetchingData, setIsFetchingData] = useState(true)
  const [students, setStudents] = useState([])

  useEffect(() => {
    mixpanel.track('Viewed student dashboard')

    getStudents(`/api/guardians/${GuardianId}/students`, {
      params: {
        include: 'current_skill_level,students_skills',
        all: true,
      },
    })
      .then((students) => {
        setStudents(students)
        setIsFetchingData(false)
      })
      .catch((error) => {
        const status = get(error, 'response.status')
        if (axios.isCancel(error)) {
          return
        } else if (status === 401) {
          history.push('/')
        } else {
          history.replace('/404')
        }
      })

    return cancelStudentsRequests
  }, []) /* eslint-disable-line */ // FIXME

  return (
    <ApplicationLayout>
      <div className="sticky top-0 z-20 flex flex-col items-center h-12 px-3 pb-1 bg-gray-100">
        <SkillLegend className="w-full max-w-2xl overflow-hidden rounded-lg shadow " />
      </div>
      {isFetchingData ? (
        <LoadingSpinner className="flex items-center justify-center my-10" />
      ) : (
        <section className="flex flex-col items-center h-full px-3">
          {students.map(
            ({
              id,
              fullName,
              cloudinaryPhotoPublicId,
              studentsSkills,
              currentSkillLevel,
            }) => (
              <StudentCard
                key={id}
                id={id}
                fullName={fullName}
                cloudinaryPhotoPublicId={cloudinaryPhotoPublicId}
                skills={studentsSkills}
                currentSkillLevel={currentSkillLevel.name}
              />
            )
          )}
        </section>
      )}
    </ApplicationLayout>
  )
}

export default GuardiansStudentsIndex
