import React, { useState } from 'react'
import PropTypes from 'prop-types'

function ExposableContent({
  className,
  contentLocation,
  children,
  hiddenLabel,
  exposedLabel,
  onHide,
  isInitiallyExposed,
}) {
  const [isExposed, setIsExposed] = useState(isInitiallyExposed)

  return (
    <div className={className}>
      {isExposed && contentLocation === 'top' && children}
      <button
        className="block text-sm font-medium text-purple-600 hover:text-purple-400 focus:outline-none focus:ring focus:border-purple-500 focus:ring-purple-300 focus:ring-opacity-50"
        type="button"
        data-cy={`expose-button-${hiddenLabel}`}
        onClick={() => {
          if (isExposed) onHide()
          setIsExposed(!isExposed)
        }}
      >
        {isExposed ? exposedLabel : hiddenLabel}
      </button>
      {isExposed && contentLocation === 'bottom' && children}
    </div>
  )
}

ExposableContent.defaultProps = {
  contentLocation: 'top',
}

ExposableContent.propTypes = {
  className: PropTypes.string,
  hiddenLabel: PropTypes.string.isRequired,
  exposedLabel: PropTypes.string,
  contentLocation: PropTypes.oneOf(['top', 'bottom']),
  children: PropTypes.node,
  onHide: PropTypes.func,
  isInitiallyExposed: PropTypes.bool,
}

export default ExposableContent
