import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { TimePicker } from 'antd'
import classNames from 'classnames'
import { result, get } from 'lodash'

import syncIcon from '../../../../images/sync.svg'
import CheckButton from '../../CheckButton/CheckButton'
import CircleButton from '../../CircleButton/CircleButton'
import SelectInput from '../../SelectInput/SelectInput'

function MultiSelectDayDateTime({ className, value: dates, onChange, error }) {
  const originalDay = result(dates, '[0].date.clone')
  const [firstDayInList, setFirstDayInList] = useState(originalDay)
  const [classDays, setClassDays] = useState(dates || [])

  function handleSelectedDayOfWeek(e) {
    const selected = parseInt(e.target.value)

    if (originalDay && originalDay.day() === selected) {
      setClassDays(dates)
      setFirstDayInList(originalDay)
    } else {
      const isSunday = moment().day() === 0
      let weekdayValue
      if (isSunday) {
        weekdayValue = selected + 7
      } else {
        weekdayValue = moment().day() > selected ? selected + 7 : selected
      }

      setClassDays([])
      setFirstDayInList(moment().isoWeekday(weekdayValue))
    }
  }

  function isDateSelected(date) {
    return !!classDays.find((classDay) => date.isSame(classDay.date, 'day'))
  }

  function toggleDate(date) {
    if (isDateSelected(date)) {
      const newClassDays = classDays.filter(
        (classDay) => !date.isSame(classDay.date, 'day')
      )
      setClassDays(newClassDays)
      onChange(newClassDays)
    } else {
      let newClassDays = [...classDays, { date: date, isTimeSet: false }]
      newClassDays = newClassDays.sort((a, b) => a.date.isAfter(b.date))
      setClassDays(newClassDays)
      onChange(newClassDays)
    }
  }

  function syncAllTimesTo(classDay) {
    if (classDay.isTimeSet) {
      const newClassDays = classDays.map((cd) => ({
        date: cd.date.set({
          hour: classDay.date.hour(),
          minute: classDay.date.minute(),
        }),
        isTimeSet: true,
      }))
      setClassDays(newClassDays)
      onChange(newClassDays)
    }
  }

  return (
    <div className={className}>
      <SelectInput
        options={[
          { label: 'Sunday', value: '0' },
          { label: 'Monday', value: '1' },
          { label: 'Tuesday', value: '2' },
          { label: 'Wednesday', value: '3' },
          { label: 'Thursday', value: '4' },
          { label: 'Friday', value: '5' },
          { label: 'Saturday', value: '6' },
        ]}
        className="w-full mr-3"
        id="day_of_week"
        label="What day of the week will these classes run?"
        placeholder="Select a Day"
        value={firstDayInList ? firstDayInList.day() : ''}
        onChange={handleSelectedDayOfWeek}
      />
      <div className="flex justify-between mt-1 font-medium text-gray-600">
        <p className="mb-1">{classDays.length} class days selected</p>
        {classDays.length == 1 && (
          <p className="mb-1">{moment(classDays[0].date).format('MMM D')}</p>
        )}
        {classDays.length > 1 && (
          <p className="mb-1">
            {moment(classDays[0].date).format('MMM D')} -{' '}
            {moment(classDays[classDays.length - 1].date).format('MMM D')}
          </p>
        )}
      </div>
      <div
        className={`${classNames({
          'text-red-500 border-red-500': get(error, 'message'),
          'border-gray-200': !!get(error, 'message'),
        })} h-64 mb-2 overflow-scroll border rounded-md shadow-inner`}
      >
        {firstDayInList && (
          <ul>
            {[...Array(dates.length + 50)].map((_value, index) => {
              const date = firstDayInList.clone().add(index, 'w')
              //FIXME: This should be it's own component
              return (
                <li
                  key={date}
                  className="flex items-center justify-between h-12 p-1 text-gray-800 border-b border-gray-300 xs:p-3 "
                >
                  <div
                    className="flex items-center justify-start cursor-pointer"
                    onClick={() => toggleDate(date)}
                    data-cy={date.format('MMM D YYYY')}
                  >
                    <CheckButton
                      className="mr-1"
                      ariaProps={{
                        'aria-pressed': isDateSelected(date),
                        'aria-label': 'select date',
                      }}
                      checked={isDateSelected(date)}
                      data-cy={`${date}-check-button`}
                      size={5}
                    />
                    <p
                      className={`${classNames({
                        'text-gray-500': !isDateSelected(date),
                      })} font-medium`}
                    >
                      {date.format('MMM D YYYY')}
                    </p>
                  </div>
                  <div className="flex items-center justify-between ml-1">
                    {isDateSelected(date) && (
                      <>
                        <TimePicker
                          use12Hours
                          showNow={false}
                          className={classNames({
                            'bg-red-100':
                              get(error, 'dates') &&
                              error.dates.find((errorDate) =>
                                date.isSame(errorDate)
                              ),
                            'border-red-500':
                              get(error, 'dates') &&
                              error.dates.find((errorDate) =>
                                date.isSame(errorDate)
                              ),
                          })}
                          allowClear={false}
                          placeholder="Pick time"
                          format="h:mm A"
                          onChange={(time, _timeString) => {
                            const newClassDays = classDays.map((classDay) =>
                              date.isSame(classDay.date, 'day')
                                ? {
                                    isTimeSet: true,
                                    date: classDay.date.set({
                                      hour: time.hour(),
                                      minute: time.minute(),
                                    }),
                                  }
                                : classDay
                            )
                            setClassDays(newClassDays)
                            onChange(newClassDays)
                          }}
                          value={
                            classDays.find((classDay) => {
                              return date.isSame(classDay.date, 'day')
                            }).isTimeSet
                              ? moment(
                                  classDays.find((classDay) =>
                                    date.isSame(classDay.date, 'day')
                                  ).date
                                )
                              : null
                          }
                          data-cy={`${date.format('MMM D YYYY')}-time`}
                        />
                        <CircleButton
                          className="m-1"
                          disabled={
                            !classDays.find((classDay) =>
                              date.isSame(classDay.date, 'day')
                            ).isTimeSet
                          }
                          onClick={() =>
                            syncAllTimesTo(
                              classDays.find((classDay) =>
                                date.isSame(classDay.date, 'day')
                              )
                            )
                          }
                          data-cy="sync-time-button"
                          size={6}
                          color="white"
                          svgSrc={syncIcon}
                        />
                      </>
                    )}
                  </div>
                </li>
              )
            })}
          </ul>
        )}
      </div>
      {get(error, 'message') && (
        <p className="mb-3 text-xs italic text-red-500">
          {get(error, 'message')}
        </p>
      )}
    </div>
  )
}

MultiSelectDayDateTime.defaultProps = {
  value: [],
}

MultiSelectDayDateTime.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  className: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
    dates: PropTypes.array,
  }),
}

export default MultiSelectDayDateTime
