import React from 'react'
import { mount } from 'enzyme'
import { MemoryRouter, Route } from 'react-router-dom'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import flushPromises from 'flush-promises'
import { act } from 'react-dom/test-utils'

import CoachFeature from './CoachFeature'
import StudentAttendanceCheckButton from './StudentAttendanceCheckButton/StudentAttendanceCheckButton'
import MockFacilityProvider from '../../providers/MockFacilityProvider'

describe('CoachFeature', () => {
  let wrapper
  const mockAxios = new MockAdapter(axios, { onNoMatch: 'throwException' })
  const mockKlassDayResponse = {
    data: {
      id: '846',
      type: 'klassDay',
      attributes: {
        skillCategories: ['Floor', 'Beam', 'Vault', 'Bars'],
        startDatetime: '2021-01-06T01:00:00.000Z',
        skillLevels: ['Basic 1'],
      },
      relationships: {
        coaches: { data: [{ id: '154', type: 'coach' }] },
        klassDaysStudents: {
          data: [
            { id: '25206', type: 'klassDaysStudent' },
            { id: '26314', type: 'klassDaysStudent' },
            { id: '26588', type: 'klassDaysStudent' },
            { id: '26282', type: 'klassDaysStudent' },
          ],
        },
        students: {
          data: [
            { id: '639', type: 'student' },
            { id: '738', type: 'student' },
            { id: '741', type: 'student' },
            { id: '734', type: 'student' },
          ],
        },
      },
      links: { self: 'http://localhost:5000/api/class_days/846' },
    },
    included: [
      {
        id: '154',
        type: 'coach',
        attributes: {
          fullName: 'Karen Thorn',
          preferredName: 'Karen',
          email: 'krthornbrugh24@gmail.com',
          cloudinaryPhotoPublicId: null,
          'invitedToSignUp?': false,
          'invitationAccepted?': false,
        },
        relationships: {
          facilities: { data: [{ id: '6', type: 'facility' }] },
          klassDays: {
            data: [
              { id: '788', type: 'klassDay' },
              { id: '712', type: 'klassDay' },
              { id: '786', type: 'klassDay' },
              { id: '828', type: 'klassDay' },
              { id: '780', type: 'klassDay' },
              { id: '826', type: 'klassDay' },
              { id: '782', type: 'klassDay' },
              { id: '823', type: 'klassDay' },
              { id: '830', type: 'klassDay' },
              { id: '792', type: 'klassDay' },
              { id: '829', type: 'klassDay' },
              { id: '824', type: 'klassDay' },
              { id: '784', type: 'klassDay' },
              { id: '825', type: 'klassDay' },
              { id: '846', type: 'klassDay' },
              { id: '847', type: 'klassDay' },
              { id: '848', type: 'klassDay' },
              { id: '849', type: 'klassDay' },
              { id: '850', type: 'klassDay' },
              { id: '851', type: 'klassDay' },
              { id: '852', type: 'klassDay' },
              { id: '853', type: 'klassDay' },
              { id: '5616', type: 'klassDay' },
              { id: '5617', type: 'klassDay' },
              { id: '5618', type: 'klassDay' },
              { id: '5619', type: 'klassDay' },
              { id: '5620', type: 'klassDay' },
              { id: '5621', type: 'klassDay' },
              { id: '827', type: 'klassDay' },
              { id: '5622', type: 'klassDay' },
              { id: '5623', type: 'klassDay' },
              { id: '5434', type: 'klassDay' },
              { id: '5436', type: 'klassDay' },
              { id: '5438', type: 'klassDay' },
              { id: '5440', type: 'klassDay' },
              { id: '5442', type: 'klassDay' },
              { id: '5444', type: 'klassDay' },
              { id: '5446', type: 'klassDay' },
              { id: '5448', type: 'klassDay' },
              { id: '790', type: 'klassDay' },
            ],
          },
        },
      },
      {
        id: '1',
        type: 'skill',
        attributes: {
          name: 'Sit on ice and stand up',
          shortLabel: 'A',
          ordinal: 1,
          optional: false,
        },
      },
      {
        id: '2',
        type: 'skill',
        attributes: {
          name: 'March forward across the ice',
          shortLabel: 'B',
          ordinal: 2,
          optional: false,
        },
      },
      {
        id: '4',
        type: 'skill',
        attributes: {
          name: 'Dip',
          shortLabel: 'D',
          ordinal: 4,
          optional: false,
        },
      },
      {
        id: '6',
        type: 'skill',
        attributes: {
          name: 'Backward wiggles (6-8 in a row)',
          shortLabel: 'F',
          ordinal: 6,
          optional: false,
        },
      },
      {
        id: '7',
        type: 'skill',
        attributes: {
          name: 'Beginning snowplow stop (one or two feet)',
          shortLabel: 'G',
          ordinal: 7,
          optional: false,
        },
      },
      {
        id: '8',
        type: 'skill',
        attributes: {
          name: 'Two-foot hop in place',
          shortLabel: '*',
          ordinal: 8,
          optional: true,
        },
      },
      {
        id: '3',
        type: 'skill',
        attributes: {
          name: 'Forward two-foot glide',
          shortLabel: 'C',
          ordinal: 3,
          optional: false,
        },
      },
      {
        id: '5',
        type: 'skill',
        attributes: {
          name: 'Forward swizzles (6-8 in a row)',
          shortLabel: 'E',
          ordinal: 5,
          optional: false,
        },
      },
      {
        id: '2',
        type: 'skillLevel',
        attributes: { name: 'Basic 2' },
        relationships: {
          nextSkillLevel: { data: { id: '3', type: 'skillLevel' } },
          skills: {
            data: [
              { id: '9', type: 'skill' },
              { id: '10', type: 'skill' },
              { id: '11', type: 'skill' },
              { id: '12', type: 'skill' },
              { id: '13', type: 'skill' },
              { id: '14', type: 'skill' },
              { id: '15', type: 'skill' },
            ],
          },
        },
      },
      {
        id: '1',
        type: 'skillLevel',
        attributes: { name: 'Basic 1' },
        relationships: {
          nextSkillLevel: { data: { id: '2', type: 'skillLevel' } },
          skills: {
            data: [
              { id: '1', type: 'skill' },
              { id: '2', type: 'skill' },
              { id: '4', type: 'skill' },
              { id: '6', type: 'skill' },
              { id: '7', type: 'skill' },
              { id: '8', type: 'skill' },
              { id: '3', type: 'skill' },
              { id: '5', type: 'skill' },
            ],
          },
        },
      },
      {
        id: '3942',
        type: 'studentsSkill',
        attributes: {
          name: 'Sit on ice and stand up',
          shortLabel: 'A',
          status: 'unintroduced',
          ordinal: 1,
        },
        relationships: { skill: { data: { id: '1', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/3942' },
      },
      {
        id: '3943',
        type: 'studentsSkill',
        attributes: {
          name: 'March forward across the ice',
          shortLabel: 'B',
          status: 'unintroduced',
          ordinal: 2,
        },
        relationships: { skill: { data: { id: '2', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/3943' },
      },
      {
        id: '3944',
        type: 'studentsSkill',
        attributes: {
          name: 'Dip',
          shortLabel: 'D',
          status: 'unintroduced',
          ordinal: 4,
        },
        relationships: { skill: { data: { id: '4', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/3944' },
      },
      {
        id: '3945',
        type: 'studentsSkill',
        attributes: {
          name: 'Backward wiggles (6-8 in a row)',
          shortLabel: 'F',
          status: 'unintroduced',
          ordinal: 6,
        },
        relationships: { skill: { data: { id: '6', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/3945' },
      },
      {
        id: '3946',
        type: 'studentsSkill',
        attributes: {
          name: 'Beginning snowplow stop (one or two feet)',
          shortLabel: 'G',
          status: 'unintroduced',
          ordinal: 7,
        },
        relationships: { skill: { data: { id: '7', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/3946' },
      },
      {
        id: '3947',
        type: 'studentsSkill',
        attributes: {
          name: 'Two-foot hop in place',
          shortLabel: '*',
          status: 'unintroduced',
          ordinal: 8,
        },
        relationships: { skill: { data: { id: '8', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/3947' },
      },
      {
        id: '3948',
        type: 'studentsSkill',
        attributes: {
          name: 'Forward two-foot glide',
          shortLabel: 'C',
          status: 'unintroduced',
          ordinal: 3,
        },
        relationships: { skill: { data: { id: '3', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/3948' },
      },
      {
        id: '3949',
        type: 'studentsSkill',
        attributes: {
          name: 'Forward swizzles (6-8 in a row)',
          shortLabel: 'E',
          status: 'unintroduced',
          ordinal: 5,
        },
        relationships: { skill: { data: { id: '5', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/3949' },
      },
      {
        id: '25923',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '1037', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25923',
        },
      },
      {
        id: '25925',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '1034', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25925',
        },
      },
      {
        id: '25927',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '1031', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25927',
        },
      },
      {
        id: '25929',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '1032', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25929',
        },
      },
      {
        id: '25931',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '1033', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25931',
        },
      },
      {
        id: '25933',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '1036', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25933',
        },
      },
      {
        id: '25935',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '1038', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25935',
        },
      },
      {
        id: '25937',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '1035', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25937',
        },
      },
      {
        id: '25206',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '846', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25206',
        },
      },
      {
        id: '25207',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '847', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25207',
        },
      },
      {
        id: '25208',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '848', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25208',
        },
      },
      {
        id: '25209',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '849', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25209',
        },
      },
      {
        id: '25210',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '850', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25210',
        },
      },
      {
        id: '25211',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '851', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25211',
        },
      },
      {
        id: '25212',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '852', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25212',
        },
      },
      {
        id: '25213',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '639', type: 'user' } },
          klassDay: { data: { id: '853', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/25213',
        },
      },
      {
        id: '639',
        type: 'student',
        attributes: {
          fullName: 'Abby Riley',
          preferredName: 'Abby',
          email: null,
          cloudinaryPhotoPublicId: null,
          gender: 'unspecified',
          birthdate: null,
          ltsNumber: '2045830',
        },
        relationships: {
          studentsSkills: {
            data: [
              { id: '3942', type: 'studentsSkill' },
              { id: '3943', type: 'studentsSkill' },
              { id: '3944', type: 'studentsSkill' },
              { id: '3945', type: 'studentsSkill' },
              { id: '3946', type: 'studentsSkill' },
              { id: '3947', type: 'studentsSkill' },
              { id: '3948', type: 'studentsSkill' },
              { id: '3949', type: 'studentsSkill' },
            ],
          },
          klassDaysStudents: {
            data: [
              { id: '25923', type: 'klassDaysStudent' },
              { id: '25925', type: 'klassDaysStudent' },
              { id: '25927', type: 'klassDaysStudent' },
              { id: '25929', type: 'klassDaysStudent' },
              { id: '25931', type: 'klassDaysStudent' },
              { id: '25933', type: 'klassDaysStudent' },
              { id: '25935', type: 'klassDaysStudent' },
              { id: '25937', type: 'klassDaysStudent' },
              { id: '25206', type: 'klassDaysStudent' },
              { id: '25207', type: 'klassDaysStudent' },
              { id: '25208', type: 'klassDaysStudent' },
              { id: '25209', type: 'klassDaysStudent' },
              { id: '25210', type: 'klassDaysStudent' },
              { id: '25211', type: 'klassDaysStudent' },
              { id: '25212', type: 'klassDaysStudent' },
              { id: '25213', type: 'klassDaysStudent' },
            ],
          },
          currentSkillLevel: { data: { id: '1', type: 'skillLevel' } },
          guardian: { data: { id: '640', type: 'user' } },
        },
      },
      {
        id: '4612',
        type: 'studentsSkill',
        attributes: {
          name: 'Sit on ice and stand up',
          shortLabel: 'A',
          status: 'unintroduced',
          ordinal: 1,
        },
        relationships: { skill: { data: { id: '1', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4612' },
      },
      {
        id: '4613',
        type: 'studentsSkill',
        attributes: {
          name: 'March forward across the ice',
          shortLabel: 'B',
          status: 'unintroduced',
          ordinal: 2,
        },
        relationships: { skill: { data: { id: '2', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4613' },
      },
      {
        id: '4614',
        type: 'studentsSkill',
        attributes: {
          name: 'Dip',
          shortLabel: 'D',
          status: 'unintroduced',
          ordinal: 4,
        },
        relationships: { skill: { data: { id: '4', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4614' },
      },
      {
        id: '4615',
        type: 'studentsSkill',
        attributes: {
          name: 'Backward wiggles (6-8 in a row)',
          shortLabel: 'F',
          status: 'unintroduced',
          ordinal: 6,
        },
        relationships: { skill: { data: { id: '6', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4615' },
      },
      {
        id: '4616',
        type: 'studentsSkill',
        attributes: {
          name: 'Beginning snowplow stop (one or two feet)',
          shortLabel: 'G',
          status: 'unintroduced',
          ordinal: 7,
        },
        relationships: { skill: { data: { id: '7', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4616' },
      },
      {
        id: '4617',
        type: 'studentsSkill',
        attributes: {
          name: 'Two-foot hop in place',
          shortLabel: '*',
          status: 'unintroduced',
          ordinal: 8,
        },
        relationships: { skill: { data: { id: '8', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4617' },
      },
      {
        id: '4618',
        type: 'studentsSkill',
        attributes: {
          name: 'Forward two-foot glide',
          shortLabel: 'C',
          status: 'unintroduced',
          ordinal: 3,
        },
        relationships: { skill: { data: { id: '3', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4618' },
      },
      {
        id: '4619',
        type: 'studentsSkill',
        attributes: {
          name: 'Forward swizzles (6-8 in a row)',
          shortLabel: 'E',
          status: 'unintroduced',
          ordinal: 5,
        },
        relationships: { skill: { data: { id: '5', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4619' },
      },
      {
        id: '26314',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '738', type: 'user' } },
          klassDay: { data: { id: '846', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26314',
        },
      },
      {
        id: '26315',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '738', type: 'user' } },
          klassDay: { data: { id: '847', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26315',
        },
      },
      {
        id: '26316',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '738', type: 'user' } },
          klassDay: { data: { id: '848', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26316',
        },
      },
      {
        id: '26317',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '738', type: 'user' } },
          klassDay: { data: { id: '849', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26317',
        },
      },
      {
        id: '26318',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '738', type: 'user' } },
          klassDay: { data: { id: '850', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26318',
        },
      },
      {
        id: '26319',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '738', type: 'user' } },
          klassDay: { data: { id: '851', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26319',
        },
      },
      {
        id: '26320',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '738', type: 'user' } },
          klassDay: { data: { id: '852', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26320',
        },
      },
      {
        id: '26321',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '738', type: 'user' } },
          klassDay: { data: { id: '853', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26321',
        },
      },
      {
        id: '738',
        type: 'student',
        attributes: {
          fullName: 'Elizabeth Crime',
          preferredName: 'Elizabeth',
          email: null,
          cloudinaryPhotoPublicId: null,
          gender: 'female',
          birthdate: '2006-01-04T08:00:00.000Z',
          ltsNumber: '2060248',
        },
        relationships: {
          studentsSkills: {
            data: [
              { id: '4612', type: 'studentsSkill' },
              { id: '4613', type: 'studentsSkill' },
              { id: '4614', type: 'studentsSkill' },
              { id: '4615', type: 'studentsSkill' },
              { id: '4616', type: 'studentsSkill' },
              { id: '4617', type: 'studentsSkill' },
              { id: '4618', type: 'studentsSkill' },
              { id: '4619', type: 'studentsSkill' },
            ],
          },
          klassDaysStudents: {
            data: [
              { id: '26314', type: 'klassDaysStudent' },
              { id: '26315', type: 'klassDaysStudent' },
              { id: '26316', type: 'klassDaysStudent' },
              { id: '26317', type: 'klassDaysStudent' },
              { id: '26318', type: 'klassDaysStudent' },
              { id: '26319', type: 'klassDaysStudent' },
              { id: '26320', type: 'klassDaysStudent' },
              { id: '26321', type: 'klassDaysStudent' },
            ],
          },
          currentSkillLevel: { data: { id: '1', type: 'skillLevel' } },
          guardian: { data: { id: '737', type: 'user' } },
        },
      },
      {
        id: '4707',
        type: 'studentsSkill',
        attributes: {
          name: 'Sit on ice and stand up',
          shortLabel: 'A',
          status: 'unintroduced',
          ordinal: 1,
        },
        relationships: { skill: { data: { id: '1', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4707' },
      },
      {
        id: '4708',
        type: 'studentsSkill',
        attributes: {
          name: 'March forward across the ice',
          shortLabel: 'B',
          status: 'unintroduced',
          ordinal: 2,
        },
        relationships: { skill: { data: { id: '2', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4708' },
      },
      {
        id: '4709',
        type: 'studentsSkill',
        attributes: {
          name: 'Dip',
          shortLabel: 'D',
          status: 'unintroduced',
          ordinal: 4,
        },
        relationships: { skill: { data: { id: '4', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4709' },
      },
      {
        id: '4710',
        type: 'studentsSkill',
        attributes: {
          name: 'Backward wiggles (6-8 in a row)',
          shortLabel: 'F',
          status: 'unintroduced',
          ordinal: 6,
        },
        relationships: { skill: { data: { id: '6', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4710' },
      },
      {
        id: '4711',
        type: 'studentsSkill',
        attributes: {
          name: 'Beginning snowplow stop (one or two feet)',
          shortLabel: 'G',
          status: 'unintroduced',
          ordinal: 7,
        },
        relationships: { skill: { data: { id: '7', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4711' },
      },
      {
        id: '4712',
        type: 'studentsSkill',
        attributes: {
          name: 'Two-foot hop in place',
          shortLabel: '*',
          status: 'unintroduced',
          ordinal: 8,
        },
        relationships: { skill: { data: { id: '8', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4712' },
      },
      {
        id: '4713',
        type: 'studentsSkill',
        attributes: {
          name: 'Forward two-foot glide',
          shortLabel: 'C',
          status: 'unintroduced',
          ordinal: 3,
        },
        relationships: { skill: { data: { id: '3', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4713' },
      },
      {
        id: '4714',
        type: 'studentsSkill',
        attributes: {
          name: 'Forward swizzles (6-8 in a row)',
          shortLabel: 'E',
          status: 'unintroduced',
          ordinal: 5,
        },
        relationships: { skill: { data: { id: '5', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4714' },
      },
      {
        id: '26588',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '741', type: 'user' } },
          klassDay: { data: { id: '846', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26588',
        },
      },
      {
        id: '26589',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '741', type: 'user' } },
          klassDay: { data: { id: '847', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26589',
        },
      },
      {
        id: '26590',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '741', type: 'user' } },
          klassDay: { data: { id: '848', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26590',
        },
      },
      {
        id: '26591',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '741', type: 'user' } },
          klassDay: { data: { id: '849', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26591',
        },
      },
      {
        id: '26592',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '741', type: 'user' } },
          klassDay: { data: { id: '850', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26592',
        },
      },
      {
        id: '26593',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '741', type: 'user' } },
          klassDay: { data: { id: '851', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26593',
        },
      },
      {
        id: '26594',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '741', type: 'user' } },
          klassDay: { data: { id: '852', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26594',
        },
      },
      {
        id: '26595',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '741', type: 'user' } },
          klassDay: { data: { id: '853', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26595',
        },
      },
      {
        id: '741',
        type: 'student',
        attributes: {
          fullName: 'Lucy Ross',
          preferredName: 'Lucy',
          email: null,
          cloudinaryPhotoPublicId: null,
          gender: 'female',
          birthdate: '2006-10-03T07:00:00.000Z',
          ltsNumber: null,
        },
        relationships: {
          studentsSkills: {
            data: [
              { id: '4707', type: 'studentsSkill' },
              { id: '4708', type: 'studentsSkill' },
              { id: '4709', type: 'studentsSkill' },
              { id: '4710', type: 'studentsSkill' },
              { id: '4711', type: 'studentsSkill' },
              { id: '4712', type: 'studentsSkill' },
              { id: '4713', type: 'studentsSkill' },
              { id: '4714', type: 'studentsSkill' },
            ],
          },
          klassDaysStudents: {
            data: [
              { id: '26588', type: 'klassDaysStudent' },
              { id: '26589', type: 'klassDaysStudent' },
              { id: '26590', type: 'klassDaysStudent' },
              { id: '26591', type: 'klassDaysStudent' },
              { id: '26592', type: 'klassDaysStudent' },
              { id: '26593', type: 'klassDaysStudent' },
              { id: '26594', type: 'klassDaysStudent' },
              { id: '26595', type: 'klassDaysStudent' },
            ],
          },
          currentSkillLevel: { data: { id: '1', type: 'skillLevel' } },
          guardian: { data: { id: '740', type: 'user' } },
        },
      },
      {
        id: '4596',
        type: 'studentsSkill',
        attributes: {
          name: 'Sit on ice and stand up',
          shortLabel: 'A',
          status: 'unintroduced',
          ordinal: 1,
        },
        relationships: { skill: { data: { id: '1', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4596' },
      },
      {
        id: '4597',
        type: 'studentsSkill',
        attributes: {
          name: 'March forward across the ice',
          shortLabel: 'B',
          status: 'unintroduced',
          ordinal: 2,
        },
        relationships: { skill: { data: { id: '2', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4597' },
      },
      {
        id: '4598',
        type: 'studentsSkill',
        attributes: {
          name: 'Dip',
          shortLabel: 'D',
          status: 'unintroduced',
          ordinal: 4,
        },
        relationships: { skill: { data: { id: '4', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4598' },
      },
      {
        id: '4599',
        type: 'studentsSkill',
        attributes: {
          name: 'Backward wiggles (6-8 in a row)',
          shortLabel: 'F',
          status: 'unintroduced',
          ordinal: 6,
        },
        relationships: { skill: { data: { id: '6', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4599' },
      },
      {
        id: '4600',
        type: 'studentsSkill',
        attributes: {
          name: 'Beginning snowplow stop (one or two feet)',
          shortLabel: 'G',
          status: 'unintroduced',
          ordinal: 7,
        },
        relationships: { skill: { data: { id: '7', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4600' },
      },
      {
        id: '4601',
        type: 'studentsSkill',
        attributes: {
          name: 'Two-foot hop in place',
          shortLabel: '*',
          status: 'unintroduced',
          ordinal: 8,
        },
        relationships: { skill: { data: { id: '8', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4601' },
      },
      {
        id: '4602',
        type: 'studentsSkill',
        attributes: {
          name: 'Forward two-foot glide',
          shortLabel: 'C',
          status: 'unintroduced',
          ordinal: 3,
        },
        relationships: { skill: { data: { id: '3', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4602' },
      },
      {
        id: '4603',
        type: 'studentsSkill',
        attributes: {
          name: 'Forward swizzles (6-8 in a row)',
          shortLabel: 'E',
          status: 'unintroduced',
          ordinal: 5,
        },
        relationships: { skill: { data: { id: '5', type: 'skill' } } },
        links: { self: 'http://localhost:5000/api/students_skills/4603' },
      },
      {
        id: '26282',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '734', type: 'user' } },
          klassDay: { data: { id: '846', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26282',
        },
      },
      {
        id: '26283',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '734', type: 'user' } },
          klassDay: { data: { id: '847', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26283',
        },
      },
      {
        id: '26284',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '734', type: 'user' } },
          klassDay: { data: { id: '848', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26284',
        },
      },
      {
        id: '26285',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '734', type: 'user' } },
          klassDay: { data: { id: '849', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26285',
        },
      },
      {
        id: '26286',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '734', type: 'user' } },
          klassDay: { data: { id: '850', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26286',
        },
      },
      {
        id: '26287',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '734', type: 'user' } },
          klassDay: { data: { id: '851', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26287',
        },
      },
      {
        id: '26288',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '734', type: 'user' } },
          klassDay: { data: { id: '852', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26288',
        },
      },
      {
        id: '26289',
        type: 'klassDaysStudent',
        attributes: { attended: false },
        relationships: {
          student: { data: { id: '734', type: 'user' } },
          klassDay: { data: { id: '853', type: 'klassDay' } },
        },
        links: {
          self: 'http://localhost:5000/api/klass_days_students/26289',
        },
      },
      {
        id: '734',
        type: 'student',
        attributes: {
          fullName: 'Hawke Warren',
          preferredName: 'Hawke',
          email: null,
          cloudinaryPhotoPublicId: null,
          gender: 'male',
          birthdate: '2012-07-13T07:00:00.000Z',
          ltsNumber: '2059966',
        },
        relationships: {
          studentsSkills: {
            data: [
              { id: '4596', type: 'studentsSkill' },
              { id: '4597', type: 'studentsSkill' },
              { id: '4598', type: 'studentsSkill' },
              { id: '4599', type: 'studentsSkill' },
              { id: '4600', type: 'studentsSkill' },
              { id: '4601', type: 'studentsSkill' },
              { id: '4602', type: 'studentsSkill' },
              { id: '4603', type: 'studentsSkill' },
            ],
          },
          klassDaysStudents: {
            data: [
              { id: '26282', type: 'klassDaysStudent' },
              { id: '26283', type: 'klassDaysStudent' },
              { id: '26284', type: 'klassDaysStudent' },
              { id: '26285', type: 'klassDaysStudent' },
              { id: '26286', type: 'klassDaysStudent' },
              { id: '26287', type: 'klassDaysStudent' },
              { id: '26288', type: 'klassDaysStudent' },
              { id: '26289', type: 'klassDaysStudent' },
            ],
          },
          currentSkillLevel: { data: { id: '1', type: 'skillLevel' } },
          guardian: { data: { id: '733', type: 'user' } },
        },
      },
    ],
  }

  beforeEach(async () => {
    mockAxios.reset()
    mockAxios.onGet(/class_days/).reply(200, mockKlassDayResponse)
    mockAxios.onGet(/recommendations/).reply(200, {})
    mockAxios.onPatch().reply(200, {})

    await act(async () => {
      wrapper = mount(
        <MemoryRouter
          initialEntries={[
            '/facilities/1/classes/1/class_days/2021-03-12/20:00',
          ]}
        >
          <Route path="/facilities/:facility_id/classes/:klass_id/class_days/:klass_day_date/:klass_day_time">
            <MockFacilityProvider>
              <CoachFeature />
            </MockFacilityProvider>
          </Route>
        </MemoryRouter>
      )
      await flushPromises()
      wrapper.update()
    })
  })

  describe('rendering', () => {
    beforeEach(async () => {
      await act(async () => {
        await flushPromises()
        wrapper.update()
      })
    })
    test('works', () => {
      expect(wrapper).toExist()
    })

    test('renders Student count', () => {
      expect(wrapper).toIncludeText('4 students')
    })
  })

  describe('recommendations', () => {
    test('fetches facility name and recommendations on load', () => {
      expect(mockAxios.history.get.length).toBe(2)
      expect(mockAxios.history.get[0].url).toBe(
        '/api/classes/1/class_days/2021-03-12/20:00'
      )
      expect(mockAxios.history.get[1].url).toBe('/api/recommendations/846')
    })

    test('fetches recommendations when attendance changes', async () => {
      mockAxios.resetHistory()
      await flushPromises()
      act(() => {
        wrapper.update()
      })
      wrapper.find(StudentAttendanceCheckButton).first().simulate('click')
      await flushPromises()

      expect(mockAxios.history.get.length).toBe(1)
      expect(mockAxios.history.get[0].url).toBe('/api/recommendations/846')
    })
  })
})
