import React from 'react'
import NavBarMenu from './NavBarMenu'
import faker from 'faker'
import { boolean, object } from '@storybook/addon-knobs'

export default {
  title: 'Leevo UI/NavBar/MarketingNavBar/NavBarMenu',
  component: NavBarMenu,
}

const links = [
  { to: '/', label: 'Home' },
  { to: '/some/path', label: faker.lorem.word() },
  { to: '/some/other/path', label: faker.lorem.word() },
]

export function navBarMenu() {
  return (
    <NavBarMenu
      opened={boolean('Opened?', true)}
      links={object('Links', links)}
    />
  )
}
