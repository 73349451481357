import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { get } from 'lodash'
import Fuse from 'fuse.js'

import LoadingSpinner from '../leevo_ui/LoadingSpinner/LoadingSpinner'
import Card from '../leevo_ui/Card/Card'
import IndexHeader from '../leevo_ui/IndexHeader/IndexHeader'
import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import Input from '../leevo_ui/Input/Input'
import StudentListItem from './StudentListItem/StudentListItem'
import { requestCreator } from '../../utilities/requests'
import { alphabetizeByFullName } from '../../utilities/helpers'
import useFacility from '../../utilities/hooks/useFacility'
import useAuth from '../../utilities/hooks/useAuth'

const { get: getStudents, cancel: cancelStudentsRequest } = requestCreator()

const fuzzySearchOptions = {
  findAllMatches: true,
  keys: ['fullName', 'preferredName'],
}

const REGISTRATION_FILTER_OPTIONS = ['all', 'registered', 'unregistered']

function FacilitiesStudentsIndex() {
  const { facility, isLoading: isFacilityLoading } = useFacility()
  const { replace: redirect, push: navigate } = useHistory()
  const [registrationFilter, setRegistrationFilter] = useState(
    REGISTRATION_FILTER_OPTIONS[0]
  )
  const [query, setQuery] = useState('')
  const [students, setStudents] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [registeredStudentsCount, setRegisteredStudentsCount] = useState(0)
  const [registeredStudentIds, setRegisteredStudentIds] = useState([])
  const { isSignedIn } = useAuth()

  useEffect(() => {
    setIsLoading(true)
    if (!isFacilityLoading) {
      getStudents(`/api/facilities/${facility.id}/students`, {
        params: {
          fields: {
            student: 'fullName,cloudinaryPhotoId',
          },
        },
      })
        .then(({ records: students, meta }) => {
          setRegisteredStudentsCount(meta.registered)
          setRegisteredStudentIds(meta.registered_ids)
          setStudents(students.sort(alphabetizeByFullName))
        })
        .then(() => setIsLoading(false))
        .catch((error) => {
          if (!axios.isCancel(error)) {
            if (get(error, 'response.status') === 401 && !isSignedIn) {
              redirect('/login')
            } else {
              redirect('/500')
              throw error
            }
          }
        })
    }

    return cancelStudentsRequest
  }, [facility, isSignedIn, isFacilityLoading, redirect])

  function filteredStudents() {
    let filteredStudents = students
    if (registrationFilter !== 'all') {
      filteredStudents = filteredStudents.filter((student) => {
        const studentId = parseInt(student.id)
        return registrationFilter === 'registered'
          ? registeredStudentIds.includes(studentId)
          : !registeredStudentIds.includes(studentId)
      })
    }

    if (!query) return filteredStudents

    const fuzzySearch = new Fuse(filteredStudents, fuzzySearchOptions)
    return fuzzySearch.search(query).map(({ item }) => item)
  }

  return (
    <ApplicationLayout>
      <IndexHeader
        title="Students"
        onAddClick={() => navigate(`/facilities/${facility.id}/students/new`)}
      />
      {isLoading ? (
        <LoadingSpinner
          size="1/5"
          className="flex items-center justify-center h-full"
        />
      ) : (
        <>
          <div className="flex justify-center">
            <div className="flex items-center justify-between w-full max-w-2xl mx-2 space-x-2">
              <Card
                interactive
                active={registrationFilter === 'registered'}
                onClick={() => setRegistrationFilter('registered')}
                borderColor="purple"
                className="w-1/3 p-3 mt-2 text-left"
                data-cy="registered-students-button"
              >
                <span className="block text-xl font-semibold text-gray-900">
                  {registeredStudentsCount}
                </span>
                <h2 className="block font-medium text-gray-900">Registered</h2>
              </Card>
              <Card
                interactive
                active={registrationFilter === 'unregistered'}
                onClick={() => setRegistrationFilter('unregistered')}
                borderColor="purple"
                className="w-1/3 p-3 mt-2 text-left"
                data-cy="unregistered-students-button"
              >
                <span className="block text-xl font-semibold text-gray-900">
                  {students.length - registeredStudentsCount}
                </span>
                <h2 className="block font-medium text-gray-900">
                  Unregistered
                </h2>
              </Card>
              <Card
                interactive
                active={registrationFilter === 'all'}
                onClick={() => setRegistrationFilter('all')}
                borderColor="purple"
                className="w-1/3 p-3 mt-2 text-left"
                data-cy="all-students-button"
              >
                <span className="block text-xl font-semibold text-gray-900">
                  {students.length}
                </span>
                <h2 className="block font-medium text-gray-900">Total</h2>
              </Card>
            </div>
          </div>
          <div className="flex justify-center">
            <ul className="w-full max-w-2xl pt-2 mx-2 my-2 bg-white rounded-lg shadow">
              <Input
                id="search"
                type="search"
                className="m-3"
                placeholder={`Search ${registrationFilter} students by name...`}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              {filteredStudents().map(
                ({ id, fullName, cloudinaryPhotoPublicId }) => (
                  <StudentListItem
                    key={id}
                    id={id}
                    fullName={fullName}
                    cloudinaryPhotoPublicId={cloudinaryPhotoPublicId}
                  />
                )
              )}
            </ul>
          </div>
        </>
      )}
    </ApplicationLayout>
  )
}

export default FacilitiesStudentsIndex
