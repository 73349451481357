import React from 'react'
import AboutFeature from './AboutFeature'

export default {
  title: 'About Feature',
  component: AboutFeature,
}

export function aboutFeature() {
  return <AboutFeature />
}
