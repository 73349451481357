import React from 'react'
import { mount } from 'enzyme'
import moment from 'moment'
import { Router } from 'react-router-dom'
import { createMemoryHistory } from 'history'

import CoachClassCard from './CoachClassCard'

const mockHistory = createMemoryHistory()
mockHistory.push = jest.fn()

describe('CoachClassCard', () => {
  let wrapper, coaches, skillLevels

  beforeEach(() => {
    coaches = [
      { id: 1, preferredName: 'Foo', cloudinaryPhotoPublicId: 'fake' },
      { id: 2, preferredName: 'Bar', cloudinaryPhotoPublicId: 'fake' },
    ]
    skillLevels = ['Basic 1', 'Snowplow Sam 2']
    wrapper = mount(
      <Router history={mockHistory}>
        <CoachClassCard
          classId={1}
          date={moment('2019-12-28T19:43:25-07:00')}
          coaches={coaches}
          skillLevels={skillLevels}
        />
      </Router>
    )
  })

  test('renders', () => {
    expect(wrapper).toExist()
  })

  test('displays all coach names', () => {
    const coachNames = wrapper.find('[data-cy="coach-name"]').hostNodes()
    expect(coachNames.length).toBe(2)
    expect(coachNames.at(0)).toIncludeText('Foo')
    expect(coachNames.at(1)).toIncludeText('Bar')
  })

  test('displays all levels', () => {
    const classLevels = wrapper.find('[data-cy="class-levels"]').hostNodes()
    expect(classLevels).toIncludeText('Basic 1')
    expect(classLevels).toIncludeText('Snowplow Sam 2')
  })

  test('selectedClass is initially undefined', () => {
    expect(wrapper.find(CoachClassCard).props.selectedClass).toBeUndefined()
  })
})
