import React from 'react'

import ContentPageLayout from '../../../ContentPageLayout/ContentPageLayout'

const baseURL =
  'https://leevo-public.s3.amazonaws.com/images/support/linking_stripe'
let id = 0
const steps = [
  {
    id: id++,
    description: <p>When logged into Leevo, go to Menu &#10132; Settings</p>,
    img: `${baseURL}/settings-on-menu.png`,
    alt: 'Settings on Menu',
    caption:
      'Find the menu on the left as a sidebar on desktop, or by pushing the menu button on mobile.',
  },
  {
    id: id++,
    description: <p>Click on “Connect with Stripe”</p>,
    img: `${baseURL}/unconnected-stripe.png`,
    alt: 'Connect with Stripe button',
  },
  {
    id: id++,
    description: (
      <p>
        If you have a Stripe account, log in with your Stripe username and
        password, then accept the connection with Leevo. If you don’t have a
        Stripe account, fill out the form to create one (it takes less than 10
        minutes to complete), and accept the connection with Leevo
      </p>
    ),
    img: `${baseURL}/connecting-stripe.png`,
    alt: 'Connecting Stripe',
  },
  {
    id: id++,
    description: <p>You should see that your account is now connected</p>,
    img: `${baseURL}/connected-stripe.png`,
    alt: 'Connected Stripe Option',
  },
  {
    id: id++,
    description: (
      <p>
        Once Stripe is connected, you can click on Menu &#10132; Register
        Students to see the page that parents see when registering. Copy the
        address in from your browser, and use this link to direct parents to
        register.
      </p>
    ),
    img: `${baseURL}/register-students-on-menu.png`,
    alt: 'Registering Students option on menu',
    caption:
      'Find the menu on the left as a sidebar on desktop, or by pushing the menu button on mobile.',
  },
]

function LinkingStripe() {
  return (
    <ContentPageLayout
      category="Getting Started"
      title="Linking Leevo to Stripe"
      intro="Leevo processes all payments through Stripe. If you want to use Leevo for registration, you must link Leevo to your Stripe account. Here's how to do it."
    >
      <ol>
        {steps.map(({ id, description, img, alt, caption }) => (
          <li key={id}>
            {description}
            {img && (
              <figure>
                <img
                  className="max-w-full rounded-lg max-h-72"
                  src={img}
                  alt={alt}
                />
                {caption && (
                  <figcaption className="text-gray-400">{caption}</figcaption>
                )}
              </figure>
            )}
          </li>
        ))}
      </ol>
      <p>
        🎉 Congratulations! You’re now ready to accept registration payments
        through Leevo.
      </p>
    </ContentPageLayout>
  )
}

LinkingStripe.defaultProps = {}

LinkingStripe.propTypes = {}

export default LinkingStripe
