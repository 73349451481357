import React from 'react'
import PropTypes from 'prop-types'

import FullNameField from '../FullNameField/FullNameField'
import PreferredNameField from '../PreferredNameField/PreferredNameField'

function NameFields({ fullNameLabel, preferredNameLabel }) {
  return (
    <>
      <FullNameField label={fullNameLabel} />
      <PreferredNameField label={preferredNameLabel} />
    </>
  )
}

NameFields.propTypes = {
  fullNameLabel: PropTypes.string,
  preferredNameLabel: PropTypes.string,
}

export default NameFields
