import React from 'react'
import DisclaimerFeature from './DisclaimerFeature'

export default {
  title: 'DisclaimerFeature',
  component: DisclaimerFeature,
  args: {
    className: 'm-4',
  },
}

export function disclaimerFeature(args) {
  return <DisclaimerFeature {...args} />
}
