import React from 'react'
import { boolean, select, text } from '@storybook/addon-knobs'

import Avatar from './Avatar'

export default {
  title: 'Leevo UI/Avatar',
  component: Avatar,
}

export function avatarWithPic() {
  return (
    <div className="flex items-center justify-center">
      <Avatar
        fullName={text('Full Name', '')}
        isStudent={boolean('isStudent', false)}
        cloudinaryPhotoPublicId={
          boolean('Default Image?', false) ? undefined : 'qrz0yfenrunmvcb0epwa'
        }
        size={select('Size', Avatar.sizes, Avatar.defaultProps.size)}
        userId={1}
      />
    </div>
  )
}

export function avatarWithInitials() {
  return (
    <div className="flex items-center justify-center">
      <Avatar
        fullName={text('Full Name', 'Arya Stark')}
        isStudent={boolean('isStudent', false)}
        cloudinaryPhotoPublicId={
          boolean('Default Image?', true) ? undefined : 'qrz0yfenrunmvcb0epwa'
        }
        size={select('Size', Avatar.sizes, Avatar.defaultProps.size)}
        userId={1}
      />
    </div>
  )
}

export function avatarForStudents() {
  return (
    <div className="flex items-center justify-center">
      <Avatar
        fullName={text('Full Name', 'Arya Stark')}
        isStudent={boolean('isStudent', true)}
        cloudinaryPhotoPublicId={
          boolean('Default Image?', true) ? undefined : 'qrz0yfenrunmvcb0epwa'
        }
        size={select('Size', Avatar.sizes, Avatar.defaultProps.size)}
        userId={1}
      />
    </div>
  )
}

export function fallbackAvatar() {
  return (
    <div className="flex items-center justify-center">
      <Avatar
        fullName={text('Full Name', '')}
        isStudent={boolean('isStudent', false)}
        cloudinaryPhotoPublicId={
          boolean('Default Image?', true) ? undefined : 'qrz0yfenrunmvcb0epwa'
        }
        size={select('Size', Avatar.sizes, Avatar.defaultProps.size)}
        userId={1}
      />
    </div>
  )
}
