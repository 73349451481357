import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

import RegistrationFeature from './RegistrationFeature'

export default {
  title: 'Registration Feature',
  component: RegistrationFeature,
}

// FIXME: This story breaks when creating a new account.
// Should we even bother with having this working fully ?
export function registrationFeature() {
  const mockAxios = new MockAdapter(axios)
  mockAxios.reset()
  mockAxios
    .onGet('/api/users', { params: { email: 'test@example.com' } })
    .reply(200)
  mockAxios.onGet('/api/users').reply(204)
  mockAxios
    .onPost('/auth/sign_in', {
      email: 'test@example.com',
      password: 'password',
    })
    .reply(200, { data: { data: { id: 1, type: 'user' } } })
  mockAxios.onPost('/auth/sign_in').reply(401)
  mockAxios
    .onPost('/auth')
    .reply(200, { data: { data: { id: 1, type: 'user' } } })

  return <RegistrationFeature />
}
