import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import mixpanel from '../../../utilities/mixpanel'

import Transition from '../Transitions/Transition/Transition'
import CircleButton from '../CircleButton/CircleButton'

import caret from '../../../images/caret-down.svg'

function RightDrawer({ children, className, buttonIcon }) {
  const [open, setOpen] = useState(false)

  const handleKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 27) setOpen(false)
    },
    [setOpen]
  )

  useEffect(() => {
    if (open) {
      document.documentElement.style.overflow = 'hidden'
      document.body.scroll = 'no'
    } else {
      document.documentElement.style.overflow = 'scroll'
      document.body.scroll = 'yes'
    }

    window && window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress, open])

  return (
    <div className={className}>
      <Transition
        show={open}
        enter="ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed inset-0 z-30 transition-opacity"
        data-cy="drawer-overlay"
      >
        <div
          onClick={() => setOpen(false)}
          className="absolute inset-0 bg-black opacity-50"
        ></div>
      </Transition>
      <Transition
        show={open}
        unmountOnExit={false}
        mountOnEnter={false}
        enterTo="translate-x-0"
        leaveTo="translate-x-full"
        className="fixed top-0 bottom-0 right-0 z-30 w-10/12 h-screen max-w-xl transition duration-500 ease-in-out transform translate-x-full bg-white border-l border-gray-400"
      >
        <div
          className="relative h-full overflow-y-scroll"
          data-cy="drawer-content"
        >
          {children}
        </div>
        <CircleButton
          data-cy="right-drawer-button"
          onClick={() => {
            mixpanel.track(`${open ? 'Closed' : 'Opened'} skill drawer`)
            setOpen(!open)
          }}
          color="purple"
          size={14}
          className={`shadow-md absolute bottom-0 -left-16 mb-4 transform ${classNames(
            {
              '-rotate-90': open && !buttonIcon,
              'rotate-90': !open && !buttonIcon,
            }
          )}`}
          svgSrc={buttonIcon || caret}
        />
      </Transition>
    </div>
  )
}

RightDrawer.defaultProps = {
  children: [],
  className: '',
}

RightDrawer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  buttonIcon: PropTypes.string,
}
export default RightDrawer
