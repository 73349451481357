import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import classNames from 'classnames'
import { RRuleSet } from 'rrule'
import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import momentPropTypes from 'react-moment-proptypes'

import useFacility from '../../../utilities/hooks/useFacility'
import CircleButton from '../CircleButton/CircleButton'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import {
  ruleSetFromRecurrenceRule,
  levelArrayToString,
} from '../../../utilities/helpers'
import angleLeft from '../../../images/angle-left.svg'
import angleRight from '../../../images/angle-right.svg'

function Calendar({
  className,
  klasses,
  timeZone,
  isLoading,
  onMonthChange,
  providedMonth,
  onDateSelected,
  ...rest
}) {
  const { facility } = useFacility()
  const [currentMonth, setCurrentMonth] = useState(
    moment(providedMonth).tz(timeZone)
  )
  const [selectedDate, setSelectedDate] = useState(null)
  let completeRRule

  useEffect(() => {
    setCurrentMonth(providedMonth)
  }, [providedMonth])

  useEffect(() => {
    function handler(event) {
      event.preventDefault()

      const NEXT_KEY = [34, 39]
      const PREVIOUS_KEY = [33, 37]
      const key = event.which

      if (NEXT_KEY.indexOf(key) > -1) {
        goToNextMonth()
        return false
      } else if (PREVIOUS_KEY.indexOf(key) > -1) {
        goToPreviousMonth()
        return false
      }
    }

    document.addEventListener('keydown', handler)

    return () => document.removeEventListener('keydown', handler)
  })

  function combinedRRule() {
    if (completeRRule) return completeRRule

    completeRRule = klasses.reduce(
      (newRuleSet, { recurrenceRule }) =>
        ruleSetFromRecurrenceRule(recurrenceRule, newRuleSet),
      new RRuleSet()
    )

    return completeRRule
  }

  function goToPreviousMonth() {
    const newMonth = moment(currentMonth).subtract(1, 'month')
    setCurrentMonth(newMonth)
    onMonthChange(newMonth)
  }

  function goToNextMonth() {
    const newMonth = moment(currentMonth).add(1, 'month')
    setCurrentMonth(newMonth)
    onMonthChange(newMonth)
  }

  function renderHeader() {
    return (
      <>
        <p className="hidden col-span-3 mx-3 mb-3 lg:text-2xl lg:block">
          <span className="font-semibold">
            {`${moment(currentMonth).format('MMMM')} `}
          </span>
          <span className="font-normal">
            {moment(currentMonth).format('YYYY')}
          </span>
        </p>
        <div className="items-center justify-end hidden col-span-4 mx-3 mb-3 lg:text-2xl lg:flex">
          {isLoading && (
            <LoadingSpinner className="hidden w-5 h-5 mr-5 text-purple-800 lg:inline" />
          )}
          <button
            className="flex-shrink-0 w-8 h-8 text-xs font-medium text-center text-white bg-purple-600 border border-r-0 border-purple-900 rounded rounded-r-none focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-purple-400 hover:bg-purple-700 active:bg-purple-700"
            onClick={goToPreviousMonth}
          >
            <SVG
              className="w-1/4 h-auto m-auto fill-current stroke-current"
              src={angleLeft}
            />
          </button>
          <button
            className="flex-shrink-0 w-8 h-8 text-xs font-medium text-center text-white bg-purple-600 border border-purple-900 rounded rounded-l-none focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-purple-400 hover:bg-purple-700 active:bg-purple-700"
            onClick={goToNextMonth}
          >
            <SVG
              className="w-1/4 h-auto m-auto fill-current stroke-current"
              src={angleRight}
            />
          </button>
        </div>
        <p className="col-span-4 text-xl font-semibold align-middle lg:hidden">
          <span className="pl-4 font-semibold">
            {`${moment(currentMonth).format('MMMM')} `}
          </span>
          <span className="font-normal">
            {moment(currentMonth).format('YYYY')}
          </span>
        </p>
        {isLoading ? (
          <div className="flex justify-end col-span-1 lg:hidden">
            <LoadingSpinner className="block w-5 h-5 text-purple-800" />
          </div>
        ) : (
          <div className="col-span-1" />
        )}
        <div className="col-span-1 text-center lg:hidden">
          <CircleButton
            size={8}
            color="gray"
            className="text-purple-600"
            svgSrc={angleLeft}
            onClick={goToPreviousMonth}
          />
        </div>
        <div className="col-span-1 text-center lg:hidden">
          <CircleButton
            size={8}
            color="gray"
            data-cy="next-month-button"
            className="text-purple-600"
            svgSrc={angleRight}
            onClick={goToNextMonth}
          />
        </div>
      </>
    )
  }

  function renderHeaderRow() {
    return [...Array(7)].map((_value, index) => {
      const day = moment()
        .tz(timeZone)
        .startOf('week')
        .add(index, 'days')
        .format('ddd')
      return (
        <div
          key={day}
          className="col-span-1 mx-1 text-center select-none lg:text-right"
        >
          {day[0]}
          <span className="hidden lg:inline">{day.substring(1, 3)}</span>
        </div>
      )
    })
  }

  function renderDays() {
    const monthStart = moment(currentMonth).tz(timeZone).startOf('month')
    const days = []
    let paddingClass
    switch (monthStart.weekday()) {
      case 1:
        paddingClass = 'col-span-1'
        break
      case 2:
        paddingClass = 'col-span-2'
        break
      case 3:
        paddingClass = 'col-span-3'
        break
      case 4:
        paddingClass = 'col-span-4'
        break
      case 5:
        paddingClass = 'col-span-5'
        break
      case 6:
        paddingClass = 'col-span-6'
        break
      default:
        paddingClass = null
    }

    if (paddingClass) {
      days.push(<div key="padding" className={paddingClass}></div>)
    }
    ;[...Array(monthStart.daysInMonth())].forEach((_value, index) => {
      const date = moment(monthStart).tz(timeZone).add(index, 'days')
      const hasEvents =
        combinedRRule().between(
          date.startOf('day').toDate(),
          date.endOf('day').toDate()
        ).length > 0
      let klassesForDay = []
      if (hasEvents) {
        klassesForDay = klasses.filter(({ recurrenceRule }) => {
          const ruleSet = ruleSetFromRecurrenceRule(recurrenceRule)

          return (
            ruleSet.between(
              moment(date).startOf('day').toDate(),
              moment(date).endOf('day').toDate(),
              true
            ).length > 0
          )
        })
      }

      return days.push(
        <div
          key={date.toString()}
          className="flex flex-col items-center col-span-1 lg:block lg:text-right"
          data-cy={date.format('YYYY-MM-DD')}
        >
          <CircleButton
            size={10}
            className={classNames('lg:hidden mb-1', {
              'border-none': !date.isSame(selectedDate, 'day'),
              'ring ring-purple-500': date.isSame(moment(), 'day'),
            })}
            label={(index + 1).toString()}
            color={date.isSame(selectedDate, 'day') ? 'purple' : 'transparent'}
            onClick={() => {
              onDateSelected(date)
              setSelectedDate(date)
            }}
          />
          <p className="hidden px-1 py-2 lg:block">{index + 1}</p>
          <div
            className="hidden h-20 mx-1 space-y-1 text-sm lg:block"
            data-cy="events"
          >
            {hasEvents &&
              klassesForDay
                .slice(0, 3)
                .map(({ id, recurrenceRule, supportedSkillLevels }) => {
                  const ruleSet = ruleSetFromRecurrenceRule(recurrenceRule)

                  return ruleSet
                    .between(
                      moment(date).startOf('day').toDate(),
                      moment(date).endOf('day').toDate(),
                      true
                    )
                    .map((klassDate) => (
                      <Link
                        key={id}
                        to={`/facilities/${
                          facility.id
                        }/classes/${id}/class_days/${moment(klassDate)
                          .tz(timeZone)
                          .format('YYYY-MM-DD')}/${moment(klassDate)
                          .tz(timeZone)
                          .format('kk:mm')}`}
                        className="block pl-1 text-xs text-left truncate bg-purple-100 border-l-2 border-purple-700 rounded-sm"
                      >
                        {moment(klassDate)
                          .tz(timeZone)
                          .format('h:mma')
                          .replace(':00', '')
                          .slice(0, -1)}{' '}
                        {levelArrayToString(
                          supportedSkillLevels.map((sl) => sl.name)
                        ) || 'None'}
                      </Link>
                    ))
                })}
            {hasEvents && klassesForDay.length > 4 && (
              <Link
                className="text-xs font-medium text-right text-purple-600 cursor-pointer hover:text-purple-500"
                to={`/facilities/${facility.id}/calendar?date=${date.format(
                  'YYYY-MM-DD'
                )}`}
              >
                +{klassesForDay.length - 3} more
              </Link>
            )}
          </div>

          {hasEvents && (
            <div className="block lg:hidden" data-cy="event-indicator">
              <div className="w-2 h-2 bg-purple-500 rounded-full"></div>
            </div>
          )}
        </div>
      )
    })
    return days
  }

  return (
    <div {...rest} className={className}>
      <div className="w-full h-full min-h-full">
        <div className="grid items-center grid-cols-7 mb-4">
          {renderHeader()}
        </div>
        <div className="grid grid-cols-7 pb-5">{renderHeaderRow()}</div>
        <div className="grid grid-cols-7 lg:h-full lg:divide-x lg:divide-gray-400 gap-y-6">
          {renderDays()}
        </div>
      </div>
    </div>
  )
}

Calendar.defaultProps = {
  klasses: [],
}

Calendar.propTypes = {
  className: PropTypes.string,
  klasses: PropTypes.array,
  timeZone: PropTypes.string,
  isLoading: PropTypes.bool,
  onMonthChange: PropTypes.func,
  onDateSelected: PropTypes.func,
  providedMonth: momentPropTypes.momentObj,
}

export default Calendar
