import React from 'react'

import TermlyEmbed from '../leevo_ui/TermlyEmbed/TermlyEmbed'

function TermsAndConditionsFeature() {
  return (
    <TermlyEmbed
      title="Terms and Conditions"
      termlyDocumentId="9536c4f6-3e5b-4fa8-b448-c2a5a804d89c"
    />
  )
}

export default TermsAndConditionsFeature
