import React from 'react'
import NoMatch from './NoMatch'

export default {
  title: 'NoMatch Page',
  component: NoMatch,
}

export function NoMatchPage() {
  return <NoMatch />
}
