import React from 'react'
import { shallow } from 'enzyme'
import ProgressBar from './ProgressBar'

describe('ProgressBar', () => {
  let wrapper

  test('renders', () => {
    wrapper = shallow(<ProgressBar />)
    expect(wrapper).toExist()
  })

  describe('className', () => {
    test('does not set a className on top-most div if one is not provided', () => {
      wrapper = shallow(<ProgressBar />)
      expect(wrapper.className).toBe(undefined)
    })

    test('sets className on top-most div', () => {
      wrapper = shallow(<ProgressBar className="foobar" />)
      expect(wrapper).toHaveClassName('foobar')
    })
  })

  describe('percentage', () => {
    test('sets width of the progress bar to 0 if not present', () => {
      wrapper = shallow(<ProgressBar />)
      const visualBar = wrapper.find('div').at(2)
      expect(visualBar.props().style.width).toBe('0%')
    })

    test('sets width of the progress bar to be the percentage', () => {
      wrapper = shallow(<ProgressBar percentage={90} />)
      const visualBar = wrapper.find('div').at(2)
      expect(visualBar.props().style.width).toBe('90%')
    })
  })

  describe('size', () => {
    test('sets border-t-2 when size is unspecified', () => {
      wrapper = shallow(<ProgressBar />)
      expect(wrapper.find('div').at(1)).toHaveClassName('border-t-2')
    })

    test('sets border-t when size 1', () => {
      wrapper = shallow(<ProgressBar size={1} />)
      expect(wrapper.find('div').at(1)).toHaveClassName('border-t')
    })

    test('sets border-t-2 when size is 2', () => {
      wrapper = shallow(<ProgressBar size={2} />)
      expect(wrapper.find('div').at(1)).toHaveClassName('border-t-2')
    })

    test('sets border-t-2 when size is 4', () => {
      wrapper = shallow(<ProgressBar size={4} />)
      expect(wrapper.find('div').at(1)).toHaveClassName('border-t-4')
    })

    test('sets border-t-2 when size is 8', () => {
      wrapper = shallow(<ProgressBar size={8} />)
      expect(wrapper.find('div').at(1)).toHaveClassName('border-t-8')
    })

    test('sets border-t-2 when size is 16', () => {
      wrapper = shallow(<ProgressBar size={16} />)
      expect(wrapper.find('div').at(1)).toHaveClassName('border-t-8 border-b-8')
    })
  })
})
