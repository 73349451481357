import React from 'react'
import { shallow } from 'enzyme'

import BirthdatePicker from './BirthdatePicker'
import DatePicker from 'react-date-picker'

describe('BirthdatePicker', () => {
  let wrapper
  test('renders', () => {
    wrapper = shallow(<BirthdatePicker id="id" />)
    expect(wrapper).toExist()
  })

  test('applies id to the input div', () => {
    wrapper = shallow(<BirthdatePicker id="foo" />)
    expect(wrapper.find(DatePicker).parent().props().id).toBe('foo')
  })

  test('applies className to the top-most div', () => {
    wrapper = shallow(<BirthdatePicker id="id" className="foo" />)
    expect(wrapper).toHaveClassName('foo')
  })

  test('passes the value to DatePicker', () => {
    const date = new Date()
    wrapper = shallow(<BirthdatePicker id="id" value={date.toString()} />)
    expect(wrapper.find(DatePicker).props().value).toBe(date.toString())
  })

  describe('label', () => {
    test('renders no label if one is not provided', () => {
      wrapper = shallow(<BirthdatePicker id="id" />)
      expect(wrapper.find('label')).not.toExist()
    })

    test('renders a label if one is provided', () => {
      wrapper = shallow(<BirthdatePicker id="id" label="foo" />)
      expect(wrapper.find('label')).toHaveText('foo')
    })
  })

  describe('errorMessage', () => {
    test('renders an error message if one is provided', () => {
      wrapper = shallow(<BirthdatePicker id="id" errorMessage="foo" />)
      expect(wrapper).toIncludeText('foo')
    })
  })
})
