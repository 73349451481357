import React from 'react'
import { shallow, mount } from 'enzyme'
import { Form } from 'react-final-form'

import AddAnotherStudent from './AddAnotherStudent'

describe('AddAnotherStudent', () => {
  let wrapper

  test('renders', () => {
    wrapper = shallow(<AddAnotherStudent />)
    expect(wrapper).toExist()
  })

  test('renders className on the LargeRadioInput', () => {
    wrapper = mount(
      <Form
        onSubmit={jest.fn()}
        render={() => <AddAnotherStudent className="foo" />}
      />
    )
    expect(wrapper.find('[data-cy="large-radio-input"]')).toHaveClassName('foo')
  })
})
