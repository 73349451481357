import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Transition from '../Transitions/Transition/Transition'
import classNames from 'classnames'

function Notification({
  show,
  children,
  className,
  color,
  disappearIn,
  onDisappearTimeout,
  'data-cy': dataCy,
}) {
  const [runningTimeout, setRunningTimeout] = useState(null)

  useEffect(() => {
    clearTimeout(runningTimeout)
    if (show && disappearIn && onDisappearTimeout)
      setRunningTimeout(setTimeout(onDisappearTimeout, disappearIn))
    return () => clearTimeout(runningTimeout)
  }, [show, disappearIn, onDisappearTimeout]) /* eslint-disable-line */ // FIXME

  function classes() {
    let classes = [
      'px-4',
      'py-5',
      'rounded',
      'font-semibold',
      'flex',
      'justify-center',
    ]
    switch (color) {
      case 'purple':
        classes.push('bg-purple-100 text-purple-800')
        break
      case 'blue':
        classes.push('bg-blue-100 text-blue-800')
        break
      case 'gray':
        classes.push('bg-gray-100 text-gray-800')
        break
      case 'red':
        classes.push('bg-red-100 text-red-800')
        break
      case 'green':
        classes.push('bg-green-100 text-green-800')
        break
      default:
        break
    }
    classes.push('shadow-sm transform transition rounded')
    classes.push(className)
    return classNames(classes)
  }
  return (
    <Transition
      show={show}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={classes()}
      data-cy={dataCy}
    >
      <div className="max-w-2xl w-full">{children}</div>
    </Transition>
  )
}

Notification.colors = ['purple', 'green', 'red', 'blue', 'gray']

Notification.defaultProps = {
  show: false,
  color: 'purple',
  disappearIn: 2000,
  className: '',
  'data-cy': 'notification',
}

Notification.propTypes = {
  show: PropTypes.bool,
  disappearIn: PropTypes.number,
  onDisappearTimeout: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  'data-cy': PropTypes.string,
  color: PropTypes.oneOf(Notification.colors),
}

export default Notification
