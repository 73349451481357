import React from 'react'
import { Form } from 'react-final-form'
import { text, boolean } from '@storybook/addon-knobs'
import { action } from '@storybook/addon-actions'

import NameFields from './NameFields'

export default {
  title: 'Registration Feature/NameFields',
  component: NameFields,
}

export function nameFields() {
  const fullNameLabelField = text(
    'Full name label',
    'This is an alternative full name label.'
  )
  const fullNameLabelToggle = boolean('Default full name label?', false)
    ? undefined
    : fullNameLabelField

  const preferredNameLabelField = text(
    'Preferred name label',
    'This is an alternative preferred name label.'
  )
  const preferredNameLabelToggle = boolean(
    'Default preferred name label?',
    false
  )
    ? undefined
    : preferredNameLabelField
  return (
    <Form
      onSubmit={action('Submit')}
      render={() => (
        <NameFields
          fullNameLabel={fullNameLabelToggle}
          preferredNameLabel={preferredNameLabelToggle}
        />
      )}
    />
  )
}
