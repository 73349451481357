import React from 'react'
import { Helmet } from 'react-helmet'

import MarketingNavBar from '../leevo_ui/NavBar/MarketingNavBar/MarketingNavBar'
import MarketingPageFooter from '../leevo_ui/MarketingPageFooter/MarketingPageFooter'
import SimpleJustifiedCTA from '../leevo_ui/SimpleJustifiedCTA/SimpleJustifiedCTA'

const people = [
  {
    name: 'Josh Carlson',
    role: 'Technology',
    imageUrl: 'https://leevo-public.s3.amazonaws.com/images/josh-headshot.png',
    bio:
      'Josh loves making technology help people. In addition to working on Leevo he helps non-profits be more effective by making technology work for them. He also enjoys hanging out with his family and staying fit.',
    linkedinUrl: 'https://www.linkedin.com/in/joshucar',
  },
  {
    name: 'Beth Carlson',
    role: 'Operations',
    imageUrl: 'https://leevo-public.s3.amazonaws.com/images/beth-headshot.png',
    bio:
      'Beth enjoys helping people embrace and fully utilize technology. Outside of Leevo she corrals a team of consultants to provide quality service to non-profits. She enjoys nerding out about non-fictional and fictional worlds alike and spending time with family.',
    linkedinUrl: 'https://www.linkedin.com/in/beth-carlson-956993203/',
  },
]

function AboutFeature() {
  return (
    <>
      <Helmet>
        <title>About | Leevo</title>
      </Helmet>
      <MarketingNavBar />
      <div className="bg-white dark:bg-gray-800">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 bg-purple-800 dark:bg-purple-900">
          <div className="text-center">
            <p className="mt-1 text-4xl font-extrabold sm:text-5xl sm:tracking-tight lg:text-6xl text-white dark:text-gray-300">
              Leevo&apos;s Mission
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-purple-200 dark:text-purple-300">
              Make it as simple as possible to run sports classes that maximize
              learning.
            </p>
          </div>
        </div>
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
            <div className="space-y-5 sm:space-y-4">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl dark:text-gray-300">
                Our Team
              </h2>
              <p className="text-xl text-gray-500 dark:text-gray-400">
                Leevo is led by Josh and Beth. We are 100% bootstrapped with no
                outside funding.
              </p>
              <p className="text-xl text-gray-500 dark:text-gray-400">
                By supporting Leevo, you&apos;re not paying money to a faceless
                corporation. You are supporting our livelihood and advancing the
                sports you love.
              </p>
            </div>
            <div className="lg:col-span-2">
              <ul
                role="list"
                className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8"
              >
                {people.map((person) => (
                  <li key={person.name}>
                    <div className="space-y-4">
                      <div className="aspect-w-3 aspect-h-2">
                        <img
                          className="object-cover shadow-lg rounded-lg"
                          src={person.imageUrl}
                          alt={person.name + ' headshot'}
                        />
                      </div>
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3 className="dark:text-gray-300">{person.name}</h3>
                        <p className="text-purple-600">{person.role}</p>
                      </div>
                      <div className="text-lg">
                        <p className="text-gray-500 dark:text-gray-400">
                          {person.bio}
                        </p>
                      </div>

                      <ul role="list" className="flex space-x-5">
                        <li>
                          <a
                            href={person.linkedinUrl}
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <span className="sr-only">LinkedIn</span>
                            <svg
                              className="w-5 h-5"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <SimpleJustifiedCTA
        headline="Want to get in touch?"
        subheadline="We're here if you need us."
        showTrialButton
      />
      <MarketingPageFooter />
    </>
  )
}

export default AboutFeature
