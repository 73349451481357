import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Field, Form } from 'react-final-form'
import moment from 'moment'
import Flatpickr from 'react-flatpickr'
import axios from 'axios'

import ApplicationLayout from '../leevo_ui/ApplicationLayout/ApplicationLayout'
import Card from '../leevo_ui/Card/Card'
import Button from '../leevo_ui/Button/Button'
import { requestCreator } from '../../utilities/requests'

import '../../css/flatpickr.css'

const {
  get: getReports,
  post: triggerReport,
  cancel: cancelReportRequests,
} = requestCreator()

function AdminLTSReportsNew() {
  const [isLoading, setIsLoading] = useState(false)
  const { replace: redirect } = useHistory()

  function onSubmit({ created_after }) {
    let notification = {}
    triggerReport('/api/admin/lts_reports', {
      created_after: parseInt(created_after / 1000),
    })
      .then(() => {
        notification.message =
          'Request successfully sent. Check your email for the report.'
        notification.color = 'green'
      })
      .catch((error) => {
        notification.message = error.message
        notification.color = 'red'
        throw error
      })
      .finally(() => {
        redirect({ state: { notification } })
      })
  }

  useEffect(() => {
    // Ping API to authenticate this route.
    getReports('/api/admin/lts_reports')
      .then(() => setIsLoading(false))
      .catch((error) => {
        if (axios.isCancel(error)) {
          return
        } else if (error.response.status == 401) {
          redirect('/login')
        } else {
          throw error
        }
      })
    return cancelReportRequests
  }, [redirect])

  return (
    <ApplicationLayout isContentLoading={isLoading}>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          created_after: moment().subtract(1, 'week').valueOf(),
        }}
        render={({ submitting, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="py-5">
            <Card className="container flex flex-col items-center py-3 mx-auto">
              <label
                htmlFor="created_after_input"
                className="pb-5 text-gray-700"
              >
                Generate spreadsheet for all registrations after:
              </label>
              <Field name="created_after">
                {({ input }) => (
                  <Flatpickr
                    id="created_after_input"
                    options={{
                      enableTime: true,
                      altInput: true,
                      altFormat: 'Y-m-d @ h:i K',
                      dateFormat: 'U',
                    }}
                    className="px-1 py-0 text-xl leading-tight text-purple-600 placeholder-gray-500 bg-current border-0 appearance-none bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:border-purple-500 focus:ring-purple-300"
                    placeholder="Select..."
                    value={input.value}
                    onChange={(_selectedDates, dateStr, _instance) => {
                      input.onChange(parseInt(dateStr) * 1000)
                    }}
                  />
                )}
              </Field>
              <Button
                outline
                type="submit"
                label={submitting ? 'Processing...' : 'Export'}
                className="m-5 text-base"
                small
                disabled={submitting}
                loading={submitting}
              />
            </Card>
          </form>
        )}
      />
    </ApplicationLayout>
  )
}

export default AdminLTSReportsNew
