import React from 'react'
import PrivacyPolicyFeature from './PrivacyPolicyFeature'

export default {
  title: 'PrivacyPolicyFeature',
  component: PrivacyPolicyFeature,
  args: {
    className: 'm-4',
  },
}

export function privacyPolicyFeature(args) {
  return <PrivacyPolicyFeature {...args} />
}
