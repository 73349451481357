import React, { useState } from 'react'
import { text } from '@storybook/addon-knobs'
import htmlElements from 'he'

import PhoneInput from './PhoneInput'

export default {
  title: 'Forms/PhoneInput',
  component: PhoneInput,
}

// Using with Final Form: https://stackoverflow.com/questions/55687357/how-to-add-react-phone-number-input-to-react-final-form
export function PhoneInputStory() {
  const [value, setValue] = useState()
  return (
    <>
      <PhoneInput
        id="phone"
        label={htmlElements.decode(text('Label', "What's your phone number?"))}
        className="m-6"
        placeholder={htmlElements.decode(
          text('Placeholder', 'Your phone number')
        )}
        value={value}
        onChange={setValue}
        maxlength="14"
      />
      <div className="m-8 font-thin text-gray-700">{value}</div>
    </>
  )
}
