import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { action } from '@storybook/addon-actions'

import StudentCard from './StudentCard'

export default {
  title: 'Coach Feature/Components/StudentCard',
  component: StudentCard,
  args: {
    attendanceEnabled: false,
  },
}

export function studentCard({ attendanceEnabled }) {
  const mockAttendanceApi = new MockAdapter(axios)

  mockAttendanceApi.reset()
  mockAttendanceApi.onPatch('/api/klass_days_students/3').reply(200, {})

  const student = {
    id: 1,
    fullName: 'Cercei Lannister',
    preferredName: 'Cercei',
    cloudinaryPhotoPublicId: 'qrz0yfenrunmvcb0epwa',
    currentSkillLevel: {
      id: 1,
      name: 'Basic 1',
      skills: [
        { id: 1, shortLabel: 'A', name: 'name' },
        { id: 2, shortLabel: 'B', name: 'name' },
        { id: 3, shortLabel: 'C', name: 'name' },
        { id: 4, shortLabel: 'D', name: 'name' },
        { id: 5, shortLabel: 'E', name: 'name' },
        { id: 6, shortLabel: 'F', name: 'name' },
      ],
      nextSkillLevel: { id: 2, name: 'Basic 2' },
    },
    klassDaysStudent: [{ id: 3, attended: false, klassDay: { id: 1 } }],
    studentsSkills: [
      {
        id: 1,
        shortLabel: 'A',
        status: 'introduced',
        name: 'name',
        skill: { id: 1 },
      },
      {
        id: 2,
        shortLabel: 'B',
        status: 'neutral',
        name: 'name',
        skill: { id: 2 },
      },
      {
        id: 3,
        shortLabel: 'C',
        status: 'completed',
        name: 'name',
        skill: { id: 3 },
      },
      {
        id: 4,
        shortLabel: 'D',
        status: 'introduced',
        name: 'name',
        skill: { id: 4 },
      },
      {
        id: 5,
        shortLabel: 'E',
        status: 'completed',
        name: 'name',
        skill: { id: 5 },
      },
      {
        id: 6,
        shortLabel: 'F',
        status: 'completed',
        name: 'name',
        skill: { id: 6 },
      },
    ],
  }

  return (
    <div className="m-3">
      <StudentCard
        student={student}
        toggleAttendance={
          attendanceEnabled ? action('Attendance Toggled') : null
        }
        klassDaysStudent={student.klassDaysStudent[0]}
      />
    </div>
  )
}

export function completedStudentCard({ attendanceEnabled }) {
  const mockAttendanceApi = new MockAdapter(axios)

  mockAttendanceApi.reset()
  mockAttendanceApi.onPatch('/api/klass_days_students/3').reply(200, {})

  const student = {
    id: 1,
    fullName: 'Cercei Lannister',
    preferredName: 'Cercei',
    cloudinaryPhotoPublicId: 'qrz0yfenrunmvcb0epwa',
    currentSkillLevel: {
      id: 1,
      name: 'Basic 1',
      skills: [
        { id: 1, shortLabel: 'A', name: 'name' },
        { id: 2, shortLabel: 'B', name: 'name' },
        { id: 3, shortLabel: 'C', name: 'name' },
        { id: 4, shortLabel: 'D', name: 'name' },
        { id: 5, shortLabel: 'E', name: 'name' },
        { id: 6, shortLabel: 'F', name: 'name' },
      ],
      nextSkillLevel: { id: 2, name: 'Basic 2' },
    },
    klassDaysStudent: [{ id: 3, attended: true, klassDay: { id: 1 } }],
    studentsSkills: [
      {
        id: 1,
        shortLabel: 'A',
        status: 'completed',
        name: 'name',
        skill: { id: 1 },
      },
      {
        id: 2,
        shortLabel: 'B',
        status: 'completed',
        name: 'name',
        skill: { id: 2 },
      },
      {
        id: 3,
        shortLabel: 'C',
        status: 'completed',
        name: 'name',
        skill: { id: 3 },
      },
      {
        id: 4,
        shortLabel: 'D',
        status: 'completed',
        name: 'name',
        skill: { id: 4 },
      },
      {
        id: 5,
        shortLabel: 'E',
        status: 'completed',
        name: 'name',
        skill: { id: 5 },
      },
      {
        id: 6,
        shortLabel: 'F',
        status: 'completed',
        name: 'name',
        skill: { id: 6 },
      },
    ],
    nextSkillLevel: { id: 2, name: 'Basic 2' },
  }

  return (
    <div className="m-3">
      <StudentCard
        student={student}
        toggleAttendance={
          attendanceEnabled ? action('Attendance Toggled') : null
        }
        klassDaysStudent={student.klassDaysStudent[0]}
      />
    </div>
  )
}
