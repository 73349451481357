import React from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

import ProfileFeature from './ProfileFeature'
import MockAuthProvider from '../../providers/MockAuthProvider'

export default {
  title: 'ProfileFeature',
  component: ProfileFeature,
}

export function withSuccessfulRequest() {
  const mockAxios = new MockAdapter(axios)
  const currentUser = { id: 1 }

  const attributes = {
    email: 'foo@bar.com',
    preferredName: 'Foo',
    fullName: 'Foo Bar',
  }
  mockAxios.reset()
  mockAxios.onGet().reply(200, { data: { attributes } })
  mockAxios.onPut().reply(200)

  return (
    <MockAuthProvider currentUser={currentUser}>
      <ProfileFeature />
    </MockAuthProvider>
  )
}

export function withUnsuccessfulRequest() {
  const mockAxios = new MockAdapter(axios)
  const currentUser = { id: 1 }

  const attributes = {
    email: 'foo@bar.com',
    preferredName: 'Foo',
    fullName: 'Foo Bar',
  }
  mockAxios.reset()
  mockAxios.onGet().reply(200, { data: { attributes } })
  mockAxios.onPut().reply(500, { error: 'Something went wrong.' })

  return (
    <MockAuthProvider currentUser={currentUser}>
      <ProfileFeature />
    </MockAuthProvider>
  )
}
