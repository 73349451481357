import React from 'react'
import { Form } from 'react-final-form'
import { shallow, mount } from 'enzyme'

import AddressField from './AddressField'

describe('AddressField', () => {
  let wrapper

  test('renders', () => {
    wrapper = shallow(<AddressField />)
    expect(wrapper).toExist()
  })

  test('renders className on the topmost div', () => {
    wrapper = mount(
      <Form
        onSubmit={jest.fn()}
        render={() => <AddressField className="foo" />}
      />
    )
    expect(wrapper.find('div').at(0)).toHaveClassName('foo')
  })

  describe('when name present', () => {
    beforeEach(() => {
      wrapper = mount(
        <Form
          onSubmit={jest.fn()}
          render={() => <AddressField name="Meg" address={{}} />}
        />
      )
    })

    test('renders the name in the question', () => {
      expect(wrapper.first('label')).toIncludeText('Meg')
    })

    test('renders the name in the help text', () => {
      expect(wrapper.first('p')).toIncludeText('Meg')
    })
  })
})
